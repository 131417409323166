export const GiftIcon = ({ color }) => {
 const generalColor = color ? color : "#A98D4B"

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="14"
      viewBox="0 0 16 14"
      fill="none"
    >
      <path
        d="M9.66667 12.0881V4.65248H6.33333V12.0881C6.33333 12.2612 6.39583 12.3945 6.52083 12.488C6.64583 12.5814 6.80556 12.6282 7 12.6282H9C9.19444 12.6282 9.35417 12.5814 9.47917 12.488C9.60417 12.3945 9.66667 12.2612 9.66667 12.0881ZM4.91667 3.3232H6.94792L5.63542 1.65122C5.45486 1.43659 5.21528 1.32928 4.91667 1.32928C4.63889 1.32928 4.40278 1.42621 4.20833 1.62006C4.01389 1.81391 3.91667 2.04931 3.91667 2.32624C3.91667 2.60317 4.01389 2.83857 4.20833 3.03242C4.40278 3.22627 4.63889 3.3232 4.91667 3.3232ZM12.0833 2.32624C12.0833 2.04931 11.9861 1.81391 11.7917 1.62006C11.5972 1.42621 11.3611 1.32928 11.0833 1.32928C10.7847 1.32928 10.5451 1.43659 10.3646 1.65122L9.0625 3.3232H11.0833C11.3611 3.3232 11.5972 3.22627 11.7917 3.03242C11.9861 2.83857 12.0833 2.60317 12.0833 2.32624ZM16 4.9848V8.308C16 8.40493 15.9688 8.48455 15.9062 8.54686C15.8438 8.60917 15.7639 8.64032 15.6667 8.64032H14.6667V12.9605C14.6667 13.2374 14.5694 13.4728 14.375 13.6667C14.1806 13.8605 13.9444 13.9574 13.6667 13.9574H2.33333C2.05556 13.9574 1.81944 13.8605 1.625 13.6667C1.43056 13.4728 1.33333 13.2374 1.33333 12.9605V8.64032H0.333333C0.236111 8.64032 0.15625 8.60917 0.09375 8.54686C0.03125 8.48455 0 8.40493 0 8.308V4.9848C0 4.88788 0.03125 4.80826 0.09375 4.74595C0.15625 4.68364 0.236111 4.65248 0.333333 4.65248H4.91667C4.27083 4.65248 3.72049 4.42574 3.26562 3.97226C2.81076 3.51879 2.58333 2.97011 2.58333 2.32624C2.58333 1.68237 2.81076 1.1337 3.26562 0.680218C3.72049 0.226739 4.27083 0 4.91667 0C5.65972 0 6.24306 0.266548 6.66667 0.799645L8 2.51317L9.33333 0.799645C9.75694 0.266548 10.3403 0 11.0833 0C11.7292 0 12.2795 0.226739 12.7344 0.680218C13.1892 1.1337 13.4167 1.68237 13.4167 2.32624C13.4167 2.97011 13.1892 3.51879 12.7344 3.97226C12.2795 4.42574 11.7292 4.65248 11.0833 4.65248H15.6667C15.7639 4.65248 15.8438 4.68364 15.9062 4.74595C15.9688 4.80826 16 4.88788 16 4.9848Z"
        fill={generalColor}
      />
    </svg>
  );
};
