import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { api } from "../../../urls";
import { ProfessionChange } from "../../common/ProfessionChange";
import { JobPostParentsDetail } from "../JobPostParentsDetail";
import { RaffleIcon } from "../../../assets/image/RaffleIcon";
import { RebootIcon } from "../../../assets/image/RebootIcon";
import { ArrowLeftIcon } from "../../../assets/image/ArrowLeftIcon";
import { CrossIcon } from "../../../assets/image/CrossIcon";
import { OPTIONS } from "../../../utils/variables";
import { JobRow } from "./JobRow";

export function JobPostParents() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [check, setcheck] = useState(true);
  const [jobsData, setJobsData] = useState([]);
  const [isActive, setIsActive] = useState(false);
  const [view, setView] = useState("");
  const [deleteList, setDeleteList] = useState([]);
  const [refine, setRefine] = useState("");
  const [generalRefine, setGeneralRefine] = useState("");

  const slugData = useParams();
  const data = slugData.name;

  const handleJobData = (a) => {
    setJobsData([]);
    const myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      api +
        "/api/v1/postedjobslist?job_search=" +
        (a != undefined ? a : generalRefine),
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => setJobsData(result.data.allJobs))
      .catch((error) => console.log("error", error));
  };

  useEffect(() => {
    if (check) {
      handleJobData();
      setcheck(false);
    }
    console.log(jobsData);
    setView(data ? data : "");
  }, [check, jobsData, data, slugData]);

  const handleSelectElement = () => {
    const ele = document.getElementsByName("chk");
    jobsData.map((data) => {
      deleteList.push(data.id);
    });
    for (let i = 0; i < ele.length; i++) {
      if (ele[i].type == "checkbox") ele[i].checked = true;
    }
  };

  useEffect(() => {
    if (window.innerWidth < 768) {
      setRefine("text-refine");
    }
  }, []);

  const handleDeselectElement = () => {
    const ele = document.getElementsByName("chk");
    for (let i = 0; i < ele.length; i++) {
      if (ele[i].type == "checkbox") ele[i].checked = false;
    }
    setDeleteList([]);
  };

  const handleDeleteElement = (a) => {
    let sum = false;
    deleteList.map((e) => {
      if (e == a) {
        sum = true;
        const index = deleteList.indexOf(a);
        if (index > -1) {
          deleteList.splice(index, 1);
        }
      }
    });
    if (sum == false) {
      deleteList.push(a);
    }
  };

  const handleDeleteList = () => {
    const myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      ids: deleteList,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(api + "/api/v1/deletejobs", requestOptions)
      .then((response) => response.text())
      .then((result) => {
        handleJobData();
      })
      .catch((error) => console.log("error", error));
  };

  const handleRefine = (e) => {
    setRefine(e);
    setcheck(true);
  };

  return (
    <div>
      {view == "" ? (
        <>
          <div class="main-header">
            <h2 className="border">{t("job-posts")}</h2>
            <div class="mail-header-bar">
              <p>
                {jobsData.length} {t("text-posts")}
              </p>
              <div class="btn-group flex">
                {t("text-refine")}
                <div className="select">
                  <label onClick={(e) => setIsActive(!isActive)}>
                    {t(refine)}
                    <span>
                      <img src="/images/done_a.svg" />
                    </span>
                  </label>
                  {isActive && (
                    <ul>
                      {OPTIONS.map((filter, index) => (
                        <li
                          key={index}
                          onClick={() => {
                            handleJobData(filter.value);
                            handleRefine(t(filter.label));
                            setGeneralRefine(filter.value);
                            setIsActive(!isActive);
                          }}
                        >
                          {t(filter.label)}
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              </div>
            </div>
            <div class="mail-option">
              <div class="chk-all">
                <input
                  type="checkbox"
                  class="mail-radio mail-group-radio"
                  name="chk"
                  id="checkbox_id"
                  onClick={(e) => {
                    if (e.target.checked) {
                      handleSelectElement();
                    } else {
                      handleDeselectElement();
                    }
                  }}
                />
                <span className="circle"></span>
                <label for="checkbox_id">{t("select-all")}</label>
              </div>
              <div class="right-side-btn">
                <div class="delete-btn" onClick={(e) => handleDeleteList()}>
                  <a href="#">
                    <RaffleIcon />
                    <span> {t("text-delete")}</span>
                  </a>
                </div>
                <div class="refresh-btn" onClick={(e) => handleJobData()}>
                  <a href="#">
                    <RebootIcon />
                  </a>
                  <span>{t("text-refresh")}</span>
                </div>
                <div class="refresh-btn">
                  <div className="addjob">
                    <ProfessionChange post={<CrossIcon />} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <table class="table table-inbox table-hover hovertable">
            <tbody>
              {jobsData.map((data) => {
                if (data.status == 0) {
                  return (
                    <JobRow
                      key={data.id}
                      data={data}
                      handleDeleteElement={handleDeleteElement}
                      setView={setView}
                      slugData={slugData}
                    />
                  );
                }

                return null;
              })}
              {jobsData.map((data) => {
                if (data.status != 0) {
                  return (
                    <JobRow
                      key={data.id}
                      data={data}
                      handleDeleteElement={handleDeleteElement}
                      setView={setView}
                      slugData={slugData}
                    />
                  );
                }
                return null;
              })}
            </tbody>
          </table>
        </>
      ) : (
        <div class="main-header">
          <button
            onClick={(e) => {
              handleJobData();
              setView("");
              navigate("/search-parents/" + slugData.id);
            }}
          >
            <ArrowLeftIcon />
            {t("text-back")}
          </button>
          <JobPostParentsDetail id={view} />
        </div>
      )}
    </div>
  );
}
