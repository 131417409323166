import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import ReCAPTCHA from "react-google-recaptcha";

import { usePersonalInfoStore } from "../../store/usePersonalInfoStore";
import {
  CAPITAL_LETTERS,
  NUMBERS,
  SPECIAL_CHARACTERS,
} from "../../utils/variables";

export const ChangePasswordModal = ({
  showModal,
  handleCloseModal,
  handleChangePassword,
  handleChangeVerify,
  validateLoginFields,
  isVerified,
}) => {
  const { t } = useTranslation();

  const [isPassword, setIsPassword] = useState(false);
  const [isNewPassword, setIsNewPassword] = useState(false);
  const [isOldPassowrd, setIsOldPassowrd] = useState(false);

  const { password, errorPassword } = usePersonalInfoStore();

  return (
    <Modal show={showModal} onHide={handleCloseModal}>
      <Modal.Body>
        <div
          className="promocode_content younger reset reset2"
          style={{ paddingBottom: "40px" }}
        >
          <Link to="" onClick={handleCloseModal}>
            +
          </Link>
          <div className="pdng">
            <h2>{t("Change Password")}</h2>
            <form>
              <div className="form_group">
                <label>{t("Old password")}</label>
                <input
                  type={isNewPassword ? "text" : "password"}
                  placeholder=". . .  . . . . . . ."
                  name="oldpassword"
                  onChange={(e) => validateLoginFields(e)}
                />
                <div className="eyes">
                  <input
                    type={"checkbox"}
                    onClick={(e) => {
                      setIsNewPassword(!isNewPassword);
                    }}
                  />
                  <i></i>
                </div>
                <span className="errorfield">{errorPassword.oldpassword}</span>
              </div>
              <div className="form_group">
                <label>{t("New password")}</label>
                <input
                  type={isOldPassowrd ? "text" : "password"}
                  placeholder=". . .  . . . . . . ."
                  name="n_password"
                  onChange={(e) => validateLoginFields(e)}
                />
                <div className="eyes">
                  <input
                    type={"checkbox"}
                    onClick={(e) => {
                      setIsOldPassowrd(!isOldPassowrd);
                    }}
                  />
                  <i></i>
                </div>
              </div>
              <div className="form_group">
                <label>{t("Confirm new password")}</label>
                <input
                  type={isPassword ? "text" : "password"}
                  placeholder=". . .  . . . . . . . "
                  name="c_password"
                  onChange={(e) => validateLoginFields(e)}
                />
                <div className="eyes">
                  <input
                    type={"checkbox"}
                    onClick={(e) => {
                      setIsPassword(!isPassword);
                    }}
                  />
                  <i></i>
                </div>
                <span className="errorfield">{errorPassword.c_password}</span>
                {errorPassword.n_password || errorPassword.n_password ? (
                  <div className="password_rule errorfield ">
                    <p>
                      {t(
                        "password-btw-8-15-char-contain"
                      )}
                      <br />
                      {CAPITAL_LETTERS.test(password.n_password)
                        ? ""
                        : t("- at least one uppercase letter")}
                      <br />
                      {NUMBERS.test(password.n_password)
                        ? ""
                        : t("- at least one number digit")}
                      <br />
                      {SPECIAL_CHARACTERS.test(password.n_password)
                        ? ""
                        : t(
                            "- at least one special character -for example:  #, @, !"
                          )}
                    </p>
                  </div>
                ) : (
                  ""
                )}
              </div>

              <div className="captch">
                <ReCAPTCHA
                  sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"
                  onChange={handleChangeVerify}
                />
              </div>
            </form>
            <div className="buttons reset3">
              <button
                className="rest"
                disabled={!isVerified}
                onClick={handleChangePassword}
              >
                {t("Change")}
              </button>
              <button className="rest" onClick={handleCloseModal}>
                {t("text-cancel")}
              </button>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
