import React from "react";

export const FaqCategory = ({
  data,
  data2,
  index2,
  index,
  preparedArray,
  record,
}) => {
  return (
    <div className="faqans">
      {(
        data.id == 17
          ? (data2.category && index == 0) ||
            index == 4 ||
            index == 16 ||
            index == 30 ||
            index == 32 ||
            index == 36
          : data.id == 58
          ? (data2.category && index == 0) || index == 5 || index == 13
          : data2.category
      ) ? (
        <h3>{data2.category}</h3>
      ) : (
        ""
      )}
      {(
        data.id == 17
          ? (data2.sub_category && index == 4) ||
            index == 13 ||
            index == 16 ||
            index == 26
          : data2.sub_category
      ) ? (
        <h5>{data2.sub_category}</h5>
      ) : (
        ""
      )}
      <h4
        id={"tag" + index2 + index}
        onClick={(e) =>
          preparedArray(
            "tab" + index2 + index,
            "icon" + index2 + index,
            "tag" + index2 + index
          )
        }
      >
        <span>{data2.question}</span>
        <img src="./images/done_a.svg" id={"icon" + index2 + index} />
      </h4>
      <div
        dangerouslySetInnerHTML={{ __html: data2.answer }}
        className={`workus ${record !== "" ? "answeropn" : "answer"}`}
        id={`tab${index2}${index}`}
      />
    </div>
  );
};
