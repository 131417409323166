import React, { useState } from "react";
import { ToastContainer } from "react-toastify";
import { useTranslation } from "react-i18next";

import { api } from "../../../urls";
import { EMAIL_REGEX, SMALL_LETTERS } from "../../../utils/variables";

import styles from "./Form.module.css";

export function Form() {
  const { t } = useTranslation();
  const [service, setService] = useState("");
  const [isCheck, setIsCheck] = useState(false);
  const [formLogin, setFormLogin] = useState({
    email: "",
    first_name: "",
    city: "",
    zip: "",
    service: "",
  });
  const [errorField, setErrorField] = useState({
    email: "",
    first_name: "",
    city: "",
    zip: "",
    service: "",
  });

  const validationField = (e) => {
    const { name, value } = e.target;
    switch (name) {
      case "email":
        errorField.email = EMAIL_REGEX.test(value) ? "" : "Email not valid.";
        break;
      case "first_name":
        errorField.first_name = SMALL_LETTERS.test(value)
          ? (errorField.first_name =
              value.length < 2 ? "Minimum 2 characters required" : "")
          : " Only letters are allowed";
        break;
      case "city":
        errorField.city = SMALL_LETTERS.test(value)
          ? (errorField.city =
              value.length < 2 ? "Minimum 2 characters required" : "")
          : " Only letters are allowed";
        break;
      case "zip":
        errorField.zip = value.length < 2 ? "required" : "";
        break;
      default:
        break;
    }

    setFormLogin({ ...formLogin, [name]: value });
    setErrorField(errorField);
  };

  const handleSubmitForm = () => {
    if (
      (errorField.first_name == "") &
      (errorField.email == "") &
      (service != "") &
      (errorField.city == "") &
      (errorField.zip == "")
    ) {
      setIsCheck(true);
      const formdata = new FormData();
      formdata.append("name", formLogin.first_name);
      formdata.append("email", formLogin.email.toLowerCase());
      formdata.append("service_type", service);
      formdata.append("city", formLogin.city);
      formdata.append("zip", formLogin.zip);

      const requestOptions = {
        method: "POST",
        body: formdata,
        redirect: "follow",
      };

      fetch(api + "/api/contacts", requestOptions)
        .then((response) => response.json())
        .then((result) => {
          if (result.success) {
            setTimeout(() => {
              setIsCheck(false);
              {
                service == "parents"
                  ? (window.location.href = "/thanks_parents")
                  : (window.location.href = "/thanks_Providers");
              }
            }, 0);
          } else {
          }
        })
        .catch((error) => {
          setIsCheck(false);
        });
    } else {
      setErrorField({
        email: formLogin.email == "" ? "required" : errorField.email,
        first_name:
          formLogin.first_name == "" ? "required" : errorField.first_name,
        city: formLogin.city == "" ? "required" : errorField.city,
        zip: formLogin.zip == "" ? "required" : errorField.zip,
        service: service == "" ? "required" : "",
      });
    }
  };
  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable={false}
        pauseOnHover
      />
      <form>
        <div className={styles.detail}>
          <h3>{t("access-services")}</h3>
          <div className={styles.form_group}>
            <input
              type={"text"}
              placeholder={t("text-name")}
              name="first_name"
              onChange={(e) => validationField(e)}
              className={errorField.first_name == "" ? "" : styles.bordererror}
            />
            <span className={styles.errorfield}>{errorField.first_name}</span>
          </div>
          <div className={styles.form_group}>
            <input
              type={"email"}
              placeholder="example@mailclient.com"
              name="email"
              onChange={(e) => validationField(e)}
              className={errorField.email == "" ? "" : styles.bordererror}
            />
            <span className={styles.errorfield}>{errorField.email}</span>
          </div>
          <div className={styles.form_group}>
            <input
              type={"number"}
              placeholder={t("zip-code")}
              name="zip"
              onChange={(e) => validationField(e)}
              className={errorField.zip == "" ? "" : styles.bordererror}
            />
            <span className={styles.errorfield}>{errorField.zip}</span>
          </div>
          <div className={styles.form_group}>
            <input
              type={"text"}
              placeholder={t("text-city")}
              name="city"
              onChange={(e) => validationField(e)}
              className={errorField.city == "" ? "" : styles.bordererror}
            />
            <span className={styles.errorfield}>{errorField.city}</span>
          </div>
        </div>
        <div className={styles.choose}>
          <label>{t("text-choose")}</label>
          <span>
            <input
              type="radio"
              id="job"
              name="job"
              onChange={(e) => setService("parents")}
            />
            <label htmlFor="job"> {t("title-service")}</label>
          </span>
          <span>
            <input
              type="radio"
              id="job2"
              name="job"
              onChange={(e) => setService("provider")}
            />
            <label htmlFor="job2">{t("title-job")}</label>
          </span>
        </div>
      </form>
      <button
        onClick={handleSubmitForm}
        className={styles.join}
        disabled={isCheck ? true : false}
        style={isCheck ? { opacity: "0.5" } : {}}
      >
        {t("join-senscare")}
      </button>
    </>
  );
}
