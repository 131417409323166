import React from 'react'
import { Link } from 'react-router-dom'

import styles from "./Header.module.css"

export function Header() {
    return (
        <div className={styles.container_fluid}>
            <div className={`${styles.logo} ${styles.border}`}>
                <Link to='/'><img src='./images/logo.svg' alt='logo' /> </Link>
            </div>
        </div>
    )
}
