import React from 'react'

export function FormGroup({ className, isError, required, children, label }) {
    return (
      <div className={`form_group ${className}`}>
        <label>
          {label}
          {required && <span className={isError != "" ? "starred" : ""}>*</span>}
        </label>
        {children}
      </div>
    );
  }