import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { jsPDF } from "jspdf";
import Modal from "react-bootstrap/Modal";

import { PageIcon } from "../../../assets/image/PageIcon";
import { DocumentIcon } from "../../../assets/image/DocumentIcon";
import { MONTH } from "../../../utils/variables";
import { fetchRecommendationsData } from "../../../api/recommendationApi";

export function RecommendationsProfileContent(props) {
  const { t } = useTranslation();

  const [recommendationsData, setRecommendationsData] = useState([]);
  const [message, setMessage] = useState("");
  const [isRequestModal, setIsRequestModal] = useState(false);

  const handleRecommendationsData = async () => {
    try {
      const result = await fetchRecommendationsData(props.id);

      setRecommendationsData([result.data.data]);
      setMessage(result.data.currentUserName);
    } catch (error) {
      console.error("Error fetching recommendations data:", error);
    }
  };

  useEffect(() => {
    handleRecommendationsData();
  }, []);

  const handleGeneratePDF = () => {
    const doc = new jsPDF("p", "pt", "a4");
    doc.html(document.querySelector("#recipt"), {
      callback: function (pdf) {
        pdf.save(
          recommendationsData &&
            recommendationsData[0] &&
            recommendationsData[0].ParentName + ".pdf"
        );
      },
    });
  };

  return (
    <>
      {recommendationsData && recommendationsData[0] ? (
        <>
          <h2 className="border"></h2>
          <div className="detail_invit recomend">
            <p>
              <span className="date">
                {new Date(
                  recommendationsData &&
                    recommendationsData[0] &&
                    recommendationsData[0].created_at
                ).toLocaleDateString("en-US", { weekday: "short" })}
                ,
                {MONTH[
                  new Date(
                    recommendationsData &&
                      recommendationsData[0] &&
                      recommendationsData[0].created_at
                  ).getMonth()
                ] +
                  " " +
                  new Date(
                    recommendationsData &&
                      recommendationsData[0] &&
                      recommendationsData[0].created_at
                  ).getDate()}
                ,
                {new Date(
                  recommendationsData &&
                    recommendationsData[0] &&
                    recommendationsData[0].created_at
                ).getFullYear()}
                ,
                {new Date(
                  recommendationsData &&
                    recommendationsData[0] &&
                    recommendationsData[0].created_at
                ).toLocaleTimeString([], {
                  hour: "2-digit",
                  minute: "2-digit",
                })}
              </span>
            </p>
            <h2 className="first">
              {t("text-dear")} <span>{message}</span>,
            </h2>
            <h2 className="last">
              <Link
                to={
                  "/profile-parents/" +
                  recommendationsData[0]?.recommendation_userid
                }
              >
                {recommendationsData &&
                  recommendationsData[0] &&
                  recommendationsData[0].ParentName}
              </Link>
              {t("has just sent you a recommendation file.")}
            </h2>
          </div>
          <div className="doc">
            <p onClick={handleGeneratePDF}>
              <PageIcon />
              {t("recommendation-underline")}
              {recommendationsData &&
                recommendationsData[0] &&
                recommendationsData[0].ParentName}
              .doc
            </p>
            <Link to="" onClick={(e) => setIsRequestModal(true)}>
              {t("Show preview")}
            </Link>
          </div>
        </>
      ) : (
        ""
      )}

      {isRequestModal && (
        <Modal show={isRequestModal} onHide={(e) => setIsRequestModal(false)}>
          <Modal.Body>
            <div className="promocode_content signout invite request recomandationdwn">
              <h2>
                {recommendationsData &&
                  recommendationsData[0] &&
                  recommendationsData[0].ParentName}
                .doc
              </h2>
              <Link to="" onClick={(e) => setIsRequestModal(false)}>
                +
              </Link>
              <br />
              <span>
                <a
                  href={
                    "https://admin.stage.mysenscare.com/assets/images/users/" +
                    recommendationsData[0]?.recommendation_response
                  }
                  download
                  target="_blank"
                  id="btn_pdf"
                >
                  <DocumentIcon />
                  {t("Download")}
                </a>
              </span>
              <p>
                <br />
                <iframe
                  style={{ border: "none" }}
                  width="100%"
                  height="450px"
                  src={
                    "https://view.officeapps.live.com/op/embed.aspx?src=" +
                    "https://admin.stage.mysenscare.com" +
                    "/assets/images/users/" +
                    recommendationsData[0]?.recommendation_response +
                    "&embedded=true"
                  }
                ></iframe>
              </p>
            </div>
          </Modal.Body>
        </Modal>
      )}
    </>
  );
}
