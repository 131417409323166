export const MarkRoundIcon = ({ color, width, height }) => {
  const generalColor = color ? color : "#A98D4B";
  const generalWidth = width ? width : "16";
  const generalHeight = height ? height : "16";

  return (
    <svg
      width={generalWidth}
      height={generalHeight}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8 0C3.584 0 0 3.584 0 8C0 12.416 3.584 16 8 16C12.416 16 16 12.416 16 8C16 3.584 12.416 0 8 0ZM8.80221 11.9999H7.20221V10.3999H8.80221V11.9999ZM8.80221 8.8H7.20221V4H8.80221V8.8Z"
        fill={generalColor}
      />
    </svg>
  );
};
