import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Modal from "react-bootstrap/Modal";

import { Footer } from "../../components/common/Footer";
import { Header } from "../../components/common/Header";
import { fetchInvestorForm, fetchInvestorRelations } from "../../api";
import { EMAIL_REGEX } from "../../utils/variables";

export function InvestorRelationsPage() {
  const { t } = useTranslation();

  const [investor, setInvestor] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState({ email: "", message: "" });
  const [formErrors, setFormErrors] = useState({ email: "", message: "" });

  useEffect(() => {
    const loadData = async () => {
      try {
        const result = await fetchInvestorRelations();
        setInvestor(result);
      } catch (error) {
        console.error("Error loading investor data:", error);
      }
    };

    loadData();
  }, []);

  const handleCloseModal = () => {
    setShowModal(false);
    window.location.reload();
  };

  const handleShowModal = () => setShowModal(true);

  const validateFormData = () => {
    const errors = {};
    if (!EMAIL_REGEX.test(formData.email)) {
      errors.email = "Email not valid.";
    }
    if (formData.message.trim().length < 2) {
      errors.message = "Message is required.";
    } else if (formData.message.length > 300) {
      errors.message = "Maximum 300 characters allowed.";
    }
    return errors;
  };

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setFormErrors({ ...formErrors, [name]: "" });
  };

  const handleSubmit = async () => {
    const errors = validateFormData();
    if (Object.keys(errors).length === 0) {
      const formdata = new FormData();
      formdata.append("email", formData.email);
      formdata.append("message", formData.message);

      try {
        const result = await fetchInvestorForm(formdata);

        if (result.success) {
          handleShowModal();
        } else {
          handleCloseModal();
        }
      } catch (error) {
        console.log("error", error);
      }
    } else {
      setFormErrors(errors);
    }
  };

  return (
    <>
      <Header />
      <div className="container-fluid">
        <div className="container">
          <div className="investor">
            <h2>{t("investor-relation.title-page", { defaultValue: investor.title })}</h2>
            <p>{t("investor-relation.description", { defaultValue: investor.description })}</p>
            <br />
            <h3>{t("greeting")}</h3>
            <div className="form_group">
              <label>
                {t("email-address")}
                <span>*</span>
              </label>
              <input
                type="email"
                placeholder={t("type-placeholder")}
                name="email"
                value={formData.email}
                onChange={handleFormChange}
                className={formErrors.email ? "bordererror" : ""}
              />
              <div className="errorfield">{formErrors.email}</div>
            </div>
            <div className="form_group full">
              <label>
                {t("your-message")}
                <span>*</span>
              </label>
              <textarea
                rows={2}
                maxLength={300}
                placeholder={t("type-placeholder")}
                name="message"
                value={formData.message}
                onChange={handleFormChange}
                className={formErrors.message ? "bordererror" : ""}
              ></textarea>
              <div className="errorfield">{formErrors.message}</div>
              <span>{t("characters-300")}</span>
            </div>
            <button onClick={handleSubmit}>{t("text-submit")}</button>
          </div>
        </div>
      </div>

      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Body>
          <div className="promocode_content younger contactpop">
            <Link to="" onClick={handleCloseModal}>
              +
            </Link>
            <h5>{t("Thank you for contacting us.")}</h5>
            <p>
              {t("SensCare has received your message.")}
              <br />
              {t("Please allow us up to 24hrs to get back to you.")}
            </p>
            <button onClick={handleCloseModal}>{t("OK")}</button>
          </div>
        </Modal.Body>
      </Modal>
      <Footer />
    </>
  );
}
