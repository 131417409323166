import { useTranslation } from "react-i18next";

import { useProfileStore } from "../../store/useProfileStore";
import { useControlsStore } from "../../store/useControlsStore";
import { useAdditionalDetailsStore } from "../../store/useAdditionalDetailsStore";
import { useErrorFieldStore } from "../../store/useErrorsFieldsStore";
import { useDetailParentsStore } from "../../store/useDetailParentsStore";

import { ArrowUpIcon } from "../../assets/image/ArrowUpIcon";

import { CHILD_FIELDS } from "../../utils/variables";
import { saveToLocalStorage } from "../../utils/helperFunctions";

export function StepTwoContent({ updateProfile, afterLoginsField }) {
  const { t } = useTranslation();

  const { errorField, setErrorField } = useErrorFieldStore();
  const { detailParents, setDetailParents } = useDetailParentsStore();

  const { detail } = useProfileStore();

  console.log(localStorage.getItem("controls"), "CONTROLS");

  const { children, setChildren, setStep, setMobile, setProcess } =
    useControlsStore();

 console.log(localStorage.getItem("controls"))

  const {
    selectCat,
    childrenAge,
    catOpen,
    setSelectCat,
    setChildrenAge,
    setCatOpen,
  } = useAdditionalDetailsStore();

  const toggleCustomElements = (e, x) => {
    const displayValue = catOpen ? "block" : "none";
    document.getElementById(e).style.display = displayValue;
    document.getElementById(x).style.display = displayValue;
    setCatOpen(catOpen ? false : true);
  };

  const toggleSelectCat = (data) => {
    let sum = false;
    selectCat.forEach((e, index) => {
      if (e.name === data) {
        sum = true;
        selectCat.splice(index, 1);
      }
    });
    if (
      (children === "twins" ? "1" : children) > selectCat.length ||
      children === "3"
    ) {
      if (sum === false) {
        selectCat.push({ name: data });
      }
    }
    setTimeout(() => {
      setSelectCat([...selectCat]);
    }, 500);
  };

  const toggleChildrenAge = (data) => {
    let sum = false;
    childrenAge.forEach((e, index) => {
      if (e.name === data) {
        sum = true;
        childrenAge.splice(index, 1);
      }
    });
    if (sum === false) {
      childrenAge.push({ name: data });
    }
    setTimeout(() => {
      setChildrenAge([...childrenAge]);
    }, 500);
  };
  return (
    <div className="detail job_performance your_kids stap2">
      <div className="left2">
        <div className="form_group full">
          <label>
            {t("children-question")}
            <span
              className={errorField.childneedcareno && errorField.childneedcareno !== "" ? "starred" : ""}
            >
              *
            </span>
          </label>
          <div className="children_number">
            <ul
              onClick={(e) =>
                setErrorField({ childneedcareno: "" })
              }
            >
              <li
                onClick={(e) => setChildren("1")}
                className={children == "1" ? "active" : ""}
              >
                1
              </li>
              <li
                onClick={(e) => setChildren("2")}
                className={children == "2" ? "active" : ""}
              >
                2
              </li>
              <li
                onClick={(e) => setChildren("twins")}
                className={children == "twins" ? "active" : ""}
              >
                {t("text-twins")}
              </li>
              <li
                onClick={(e) => setChildren("3")}
                className={children == "3" ? "active" : ""}
              >
                3+
              </li>
            </ul>
          </div>
        </div>
        <div className="form_group full">
          <label>
            {t("child-age")}
            <span className={errorField.childage && errorField.childage !== "" ? "starred" : ""}>
              *
            </span>
          </label>
          <div className="customselect inp">
            <input
              className="keyword"
              type="text"
              placeholder={t("choose-list")}
              value={selectCat?.map((e) => {
                return t(e.name);
              })}
            />
            <div
              className="overflow"
              id="over"
              onClick={(e) => toggleCustomElements("cate", "over")}
            ></div>
            <div
              className="option"
              id="cate"
              onClick={(e) => setErrorField({ childage: "" })}
            >
              <p>
                <input
                  type="checkbox"
                  checked={
                    selectCat.filter((e) => e.name === t("years-0-1"))[0]
                      ? true
                      : false
                  }
                  onClick={(a) => toggleSelectCat(t("years-0-1"))}
                />
                <h3>{t("years-0-1")} </h3>
                <span></span>
              </p>
              <p>
                <input
                  type="checkbox"
                  checked={
                    selectCat.filter((e) => e.name === t("years-1-3"))[0]
                      ? true
                      : false
                  }
                  onClick={(a) => toggleSelectCat(t("years-1-3"))}
                />
                <h3>{t("years-1-3")} </h3>
                <span></span>
              </p>
              <p>
                <input
                  type="checkbox"
                  checked={
                    selectCat.filter((e) => e.name === t("years-4-7"))[0]
                      ? true
                      : false
                  }
                  onClick={(a) => toggleSelectCat(t("years-4-7"))}
                />
                <h3>{t("years-4-7")} </h3>
                <span></span>
              </p>
              <p>
                <input
                  type="checkbox"
                  checked={
                    selectCat.filter((e) => e.name === t("years-8-10"))[0]
                      ? true
                      : false
                  }
                  onClick={(a) => toggleSelectCat(t("years-8-10"))}
                />
                <h3>{t("years-8-10")} </h3>
                <span></span>
              </p>
              <p>
                <input
                  type="checkbox"
                  checked={
                    selectCat.filter((e) => e.name === t("years-11-15"))[0]
                      ? true
                      : false
                  }
                  onClick={(a) => toggleSelectCat(t("years-11-15"))}
                />
                <h3>{t("years-11-15")} </h3>
                <span></span>
              </p>
              <p>
                <input
                  type="checkbox"
                  checked={
                    selectCat.filter((e) => e.name === t("years-16"))[0]
                      ? true
                      : false
                  }
                  onClick={(a) => toggleSelectCat(t("years-16"))}
                />
                <h3>{t("years-16")} </h3>
                <span></span>
              </p>
            </div>
            <span onClick={(e) => toggleCustomElements("cate", "over")}>
              <ArrowUpIcon />
            </span>
          </div>
        </div>
        <div className="form_group full">
          <label>
            {t("child-question")}
            <span
              className={errorField.childsocialneed && errorField.childsocialneed !== "" ? "starred" : ""}
            >
              *
            </span>
          </label>
          <div className="checkbox create">
            <ul
              onClick={(e) =>
                setErrorField({ childsocialneed: "" })
              }
            >
              <li>
                <input
                  type="radio"
                  name=""
                  onClick={(e) =>
                    setDetailParents({
                      childsocialneed: "Yes",
                    })
                  }
                  checked={
                    detailParents.childsocialneed === "Yes" ? true : false
                  }
                />
                <span> {t("confirm")}</span>
              </li>
              <li>
                <input
                  type="radio"
                  name=""
                  onClick={(e) =>
                    setDetailParents({
                      childsocialneed: "No",
                    })
                  }
                  checked={
                    detailParents.childsocialneed === "No" ? true : false
                  }
                />
                <span> {t("objection")}</span>
              </li>
            </ul>
          </div>
        </div>
        {detailParents.childsocialneed === "Yes" && (
          <div className="form_group full">
            <label>
              {t("select-applicable")}
              <span
                className={
                  errorField.yourkidallapplicable && errorField.yourkidallapplicable !== "" ? "starred" : ""
                }
              >
                *
              </span>
            </label>
            <div className="customselect inp">
              <div className="detl">
                {childrenAge.map((e) => {
                  return t(e.name) + " , ";
                })}
              </div>

              <div
                className="overflow"
                id="over2"
                onClick={(e) => toggleCustomElements("cate2", "over2")}
              ></div>

              <div
                className="option"
                id="cate2"
                onClick={(e) =>
                  setErrorField({
                    yourkidallapplicable: "",
                  })
                }
              >
                <p>
                  <input
                    type="checkbox"
                    checked={
                      childrenAge.filter((e) => e.name === t("text-adhd"))[0]
                        ? true
                        : false
                    }
                    onClick={(a) => toggleChildrenAge(t("text-adhd"))}
                  />
                  <h3>{t("text-adhd")} </h3>
                  <span></span>
                </p>
                <p>
                  <input
                    type="checkbox"
                    checked={
                      childrenAge.filter(
                        (e) => e.name === t("auditory-impairment")
                      )[0]
                        ? true
                        : false
                    }
                    onClick={(a) => toggleChildrenAge(t("auditory-impairment"))}
                  />
                  <h3>{t("auditory-impairment")} </h3>
                  <span></span>
                </p>
                <p>
                  <input
                    type="checkbox"
                    checked={
                      childrenAge.filter((e) => e.name === t("autism"))[0]
                        ? true
                        : false
                    }
                    onClick={(a) => toggleChildrenAge(t("autism"))}
                  />
                  <h3>{t("autism")} </h3>
                  <span></span>
                </p>
                <p>
                  <input
                    type="checkbox"
                    checked={
                      childrenAge.filter(
                        (e) => e.name === t("cerebral-palsy")
                      )[0]
                        ? true
                        : false
                    }
                    onClick={(a) => toggleChildrenAge(t("cerebral-palsy"))}
                  />
                  <h3>{t("cerebral-palsy")} </h3>
                  <span></span>
                </p>
                <p>
                  <input
                    type="checkbox"
                    checked={
                      childrenAge.filter(
                        (e) => e.name === t("down-syndrome")
                      )[0]
                        ? true
                        : false
                    }
                    onClick={(a) => toggleChildrenAge(t("down-syndrome"))}
                  />
                  <h3>{t("down-syndrome")} </h3>
                  <span></span>
                </p>
                <p>
                  <input
                    type="checkbox"
                    checked={
                      childrenAge.filter((e) => e.name === t("dyslexia"))[0]
                        ? true
                        : false
                    }
                    onClick={(a) => toggleChildrenAge(t("dyslexia"))}
                  />
                  <h3>{t("dyslexia")} </h3>
                  <span></span>
                </p>
                <p>
                  <input
                    type="checkbox"
                    checked={
                      childrenAge.filter(
                        (e) => e.name === t("intellectual-disability")
                      )[0]
                        ? true
                        : false
                    }
                    onClick={(a) =>
                      toggleChildrenAge(t("intellectual-disability"))
                    }
                  />
                  <h3>{t("intellectual-disability")} </h3>
                  <span></span>
                </p>
                <p>
                  <input
                    type="checkbox"
                    checked={
                      childrenAge.filter(
                        (e) => e.name === t("moderate-disability")
                      )[0]
                        ? true
                        : false
                    }
                    onClick={(a) => toggleChildrenAge(t("moderate-disability"))}
                  />
                  <h3>{t("moderate-disability")} </h3>
                  <span></span>
                </p>
                <p>
                  <input
                    type="checkbox"
                    checked={
                      childrenAge.filter(
                        (e) => e.name === t("orthopedic-impairment")
                      )[0]
                        ? true
                        : false
                    }
                    onClick={(a) =>
                      toggleChildrenAge(t("orthopedic-impairment"))
                    }
                  />
                  <h3>{t("orthopedic-impairment")} </h3>
                  <span></span>
                </p>
                <p>
                  <input
                    type="checkbox"
                    checked={
                      childrenAge.filter(
                        (e) => e.name === t("learn-impairment")
                      )[0]
                        ? true
                        : false
                    }
                    onClick={(a) => toggleChildrenAge(t("learn-impairment"))}
                  />
                  <h3>{t("learn-impairment")} </h3>
                  <span></span>
                </p>
                <p>
                  <input
                    type="checkbox"
                    checked={
                      childrenAge.filter(
                        (e) => e.name === t("learn-disabilities")
                      )[0]
                        ? true
                        : false
                    }
                    onClick={(a) => toggleChildrenAge(t("learn-disabilities"))}
                  />
                  <h3>{t("learn-disabilities")} </h3>
                  <span></span>
                </p>
                <p>
                  <input
                    type="checkbox"
                    checked={
                      childrenAge.filter(
                        (e) => e.name === t("visual-impairment")
                      )[0]
                        ? true
                        : false
                    }
                    onClick={(a) => toggleChildrenAge(t("visual-impairment"))}
                  />
                  <h3>{t("visual-impairment")} </h3>
                  <span></span>
                </p>
                <p>
                  <input
                    type="checkbox"
                    checked={
                      childrenAge.filter((e) => e.name === t("text-other"))[0]
                        ? true
                        : false
                    }
                    onClick={(a) => toggleChildrenAge(t("text-other"))}
                  />
                  <h3>{t("text-other")} </h3>
                  <span></span>
                </p>
              </div>

              <span onClick={(e) => toggleCustomElements("cate2", "over2")}>
                <ArrowUpIcon />
              </span>
            </div>
          </div>
        )}

        {detail.service_type && detail.service_type.tab2 && (
          <div class="form_group full">
            <label>
              {t("text-child-need")} <a href="#blank">{t("text-iep")} </a>
              <span class="smallpop">{t("iep-program")}</span> ?
              <span className={errorField.childneediep && errorField.childneediep !== "" ? "starred" : ""}>
                *
              </span>
            </label>
            <div class="checkbox create">
              <ul
                onClick={(e) =>
                  setErrorField({ childneediep: "" })
                }
              >
                <li>
                  <input
                    type="radio"
                    name=""
                    onClick={(e) =>
                      setDetailParents({
                        childneediep: "Yes",
                      })
                    }
                    checked={
                      detailParents.childneediep === "Yes" ? true : false
                    }
                  />
                  <span> {t("confirm")}</span>
                </li>
                <li>
                  <input
                    type="radio"
                    name=""
                    onClick={(e) =>
                      setDetailParents({
                        childneediep: "No",
                      })
                    }
                    checked={detailParents.childneediep === "No" ? true : false}
                  />
                  <span> {t("objection")}</span>
                </li>
              </ul>
            </div>
          </div>
        )}
      </div>
      {/* Hidden for now childmedicalcondition. Will be enabled later on! @TODO*/}
      {/*<div className="right2">*/}
      {/*  <div className="form_group full">*/}
      {/*    <label>*/}
      {/*      {t("medical-conditions")}*/}
      {/*      <span*/}
      {/*        className={*/}
      {/*          errorField.childmedicalcondition && errorField.childmedicalcondition !== "" ? "starred" : ""*/}
      {/*        }*/}
      {/*      >*/}
      {/*        **/}
      {/*      </span>*/}
      {/*    </label>*/}
      {/*    <div className="checkbox create">*/}
      {/*      <ul*/}
      {/*        onClick={(e) =>*/}
      {/*          setErrorField({*/}
      {/*            childmedicalcondition: "",*/}
      {/*          })*/}
      {/*        }*/}
      {/*      >*/}
      {/*        <li>*/}
      {/*          <input*/}
      {/*            type="radio"*/}
      {/*            name="c2"*/}
      {/*            onClick={(e) =>*/}
      {/*              setDetailParents({*/}
      {/*                ...detailParents,*/}
      {/*                childmedicalcondition: "Yes",*/}
      {/*              })*/}
      {/*            }*/}
      {/*            checked={*/}
      {/*              detailParents.childmedicalcondition === "Yes" ? true : false*/}
      {/*            }*/}
      {/*          />*/}
      {/*          <span> {t("confirm")}</span>*/}
      {/*        </li>*/}
      {/*        <li>*/}
      {/*          <input*/}
      {/*            type="radio"*/}
      {/*            name="c2"*/}
      {/*            onClick={(e) =>*/}
      {/*              setDetailParents({*/}
      {/*                ...detailParents,*/}
      {/*                childmedicalcondition: "No",*/}
      {/*              })*/}
      {/*            }*/}
      {/*            checked={*/}
      {/*              detailParents.childmedicalcondition === "No" ? true : false*/}
      {/*            }*/}
      {/*          />*/}
      {/*          <span> {t("objection")}</span>*/}
      {/*        </li>*/}
      {/*      </ul>*/}

      {/*      {detailParents.childmedicalcondition === "Yes" && (*/}
      {/*        <>*/}
      {/*          <textarea*/}
      {/*            rows="2"*/}
      {/*            placeholder={t("short-description")}*/}
      {/*            maxlength="300"*/}
      {/*            name="message"*/}
      {/*            onChange={(e) => {*/}
      {/*              setErrorField({*/}
      {/*                childmedicaldescription: "",*/}
      {/*              });*/}
      {/*              setDetailParents({*/}
      {/*                ...detailParents,*/}
      {/*                childmedicaldescription: e.target.value,*/}
      {/*              });*/}
      {/*            }}*/}
      {/*            defaultValue={detailParents.childmedicaldescription}*/}
      {/*            className={*/}
      {/*              errorField.childmedicaldescription && errorField.childmedicaldescription !== ""*/}
      {/*                ? "bordererror"*/}
      {/*                : ""*/}
      {/*            }*/}
      {/*          ></textarea>*/}
      {/*          <span>*/}
      {/*            {t("number-characters")}*/}
      {/*            {300 - detailParents.childmedicaldescription.length}*/}
      {/*          </span>*/}
      {/*        </>*/}
      {/*      )}*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*  <div className="form_group full">*/}
      {/*    <label>*/}
      {/*      {t("allergies-question")}*/}
      {/*      <span*/}
      {/*        className={errorField.childanyallergies && errorField.childanyallergies !== "" ? "starred" : ""}*/}
      {/*      >*/}
      {/*        **/}
      {/*      </span>*/}
      {/*    </label>*/}
      {/*    <div className="checkbox create">*/}
      {/*      <ul*/}
      {/*        onClick={(e) =>*/}
      {/*          setErrorField({*/}
      {/*            childanyallergies: "",*/}
      {/*          })*/}
      {/*        }*/}
      {/*      >*/}
      {/*        <li>*/}
      {/*          <input*/}
      {/*            type="radio"*/}
      {/*            name="b2"*/}
      {/*            onClick={(e) =>*/}
      {/*              setDetailParents({*/}
      {/*                ...detailParents,*/}
      {/*                childanyallergies: "Yes",*/}
      {/*              })*/}
      {/*            }*/}
      {/*            checked={*/}
      {/*              detailParents.childanyallergies === "Yes" ? true : false*/}
      {/*            }*/}
      {/*          />*/}
      {/*          <span> {t("confirm")}</span>*/}
      {/*        </li>*/}
      {/*        <li>*/}
      {/*          <input*/}
      {/*            type="radio"*/}
      {/*            name="b2"*/}
      {/*            onClick={(e) =>*/}
      {/*              setDetailParents({*/}
      {/*                ...detailParents,*/}
      {/*                childanyallergies: "No",*/}
      {/*              })*/}
      {/*            }*/}
      {/*            checked={*/}
      {/*              detailParents.childanyallergies === "No" ? true : false*/}
      {/*            }*/}
      {/*          />*/}
      {/*          <span> {t("objection")}</span>*/}
      {/*        </li>*/}
      {/*      </ul>*/}
      {/*      {detailParents.childanyallergies === "Yes" && (*/}
      {/*        <>*/}
      {/*          <textarea*/}
      {/*            rows="2"*/}
      {/*            placeholder={t("short-description")}*/}
      {/*            maxlength="300"*/}
      {/*            name="message"*/}
      {/*            onChange={(e) => {*/}
      {/*              setErrorField({*/}
      {/*                childallergiesdescription: "",*/}
      {/*              });*/}
      {/*              setDetailParents({*/}
      {/*                ...detailParents,*/}
      {/*                childallergiesdescription: e.target.value,*/}
      {/*              });*/}
      {/*            }}*/}
      {/*            defaultValue={detailParents.childallergiesdescription}*/}
      {/*            className={*/}
      {/*              errorField.childallergiesdescription && errorField.childallergiesdescription !== ""*/}
      {/*                ? "bordererror"*/}
      {/*                : ""*/}
      {/*            }*/}
      {/*          ></textarea>*/}
      {/*          <span>*/}
      {/*            {t("number-characters")}*/}
      {/*            {300 - detailParents.childallergiesdescription.length}*/}
      {/*          </span>*/}
      {/*        </>*/}
      {/*      )}*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*</div>*/}
      <div className="button">
        <button
          onClick={(e) => {
            setMobile(0);
            setStep(1);
            window.scrollTo({ top: 0 });
          }}
        >
          {t("text-back")}
        </button>
        <button
          onClick={(e) => {
            if (
              (children > 0 || children === "twins") &&
              selectCat[0] &&
              detailParents.childsocialneed !== "" &&
              (detail.service_type && detail.service_type.tab2
                ? detailParents.childneediep !== ""
                : selectCat[0]) &&
              (detailParents.childsocialneed === "Yes"
                ? childrenAge[0]
                : detailParents.childsocialneed !== "")
            ) {
              saveToLocalStorage("parentsDetail", detailParents);
              setMobile(-20);
              setStep(3);
              setProcess(43);
              window.scrollTo({ top: 0 });
              updateProfile(false);
              saveToLocalStorage("controls", {
                children: children,
                selectCat: selectCat,
              });
            } else {
              CHILD_FIELDS.forEach((e) => {
                afterLoginsField(e.name);
              });
            }
          }}
        >
          {t("text-next")}
        </button>
      </div>
    </div>
  );
}
