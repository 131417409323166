export const BoldArrowDown = () => {
  return (
    <svg
      width="14"
      height="17"
      viewBox="0 0 14 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10 6H11.59C12.48 6 12.92 7.08 12.29 7.71L7.70002 12.3C7.31002 12.69 6.68002 12.69 6.29002 12.3L1.70002 7.71C1.07002 7.08 1.52002 6 2.41002 6H4.00002V1C4.00002 0.45 4.45002 0 5.00002 0H9.00002C9.55002 0 10 0.45 10 1V6ZM1 17C0.45 17 0 16.55 0 16C0 15.45 0.45 15 1 15H13C13.55 15 14 15.45 14 16C14 16.55 13.55 17 13 17H1Z"
        fill="#A98D4B"
      />
    </svg>
  );
};
