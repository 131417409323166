import React from "react";
import { useCategoryStore } from "../../store/useCategoryStore";
import { useTranslation } from "react-i18next";
import { NannyIcon } from "../../assets/image/NannyIcon";
import { TeacherIcon } from "../../assets/image/TeacherIcon";
import { ParaprofessionalIcon } from "../../assets/image/ParaprofessionalIcon";
import { TutorIcon } from "../../assets/image/TutorIcon";
import { ArrowUpIcon } from "../../assets/image/ArrowUpIcon";
import { MainDandruffIcon } from "../../assets/image/MainDandruffIcon";
import { useSearchStore } from "../../store/useSearchStore";
import { RATE_NUMBERS, RATE_NUMBERS_SECOND } from "../../utils/variables";

export const ProviderModalComponent = ({
  rateNumber,
  setRateNumber,
  setChildren,
  profile,
  handleChildrenAge,
  handleElementById,
  setJobData,
  jobData,
  handleCloseProviderModal,
  fetchRateData,
  handleSelectOption,
  category,
  isCatOpen,
  setSubTab,
  childrenAge,
}) => {
  const { t } = useTranslation;

  const { selectCategory, jobCategory, setJobCategory, setSelectCategory } =
    useCategoryStore();

  const { search, languageSpeak, setSearch, setLanguageSpeak } =
    useSearchStore();

  return (
    <div className="container">
      <h2>{t("Advanced Search")}</h2>
      <div
        className="filter_box"
        onKeyPress={(e) => {
          if (
            e.key == "Enter" &&
            localStorage.getItem("token") &&
            localStorage.getItem("id")
          ) {
            localStorage.removeItem("searchdata");
            fetchRateData();
          }
        }}
      >
        <div className="filter_search">
          <div className="filter_category Profile_complete ">
            <div className="filter_category_select detail work-experience">
              <div className="job_performance">
                <div className="form_group   full">
                  <div className="customselect inp">
                    <input
                      id="my-fil-cat"
                      className="keyword"
                      type="text"
                      placeholder={t("Select job category")}
                      value={category.map((e) => {
                        return e.name;
                      })}
                    />
                    <div
                      className="overflow"
                      id="over119"
                      onClick={(e) => handleElementById("cate8", "over119")}
                    ></div>
                    <div className="option" id="cate8">
                      <p>
                        <input
                          type="checkbox"
                          onClick={(a) => {
                            handleSelectOption(t("text-nanny"));
                            if (a.target.checked) {
                              setJobData({
                                ...jobData,
                                data1: "Nanny",
                              });
                            } else {
                              setJobData({
                                ...jobData,
                                data1: "",
                              });
                            }
                          }}
                        />
                        <h3>
                          <NannyIcon />
                          {t("text-nanny")}
                        </h3>
                        <span></span>
                      </p>
                      <p>
                        <input
                          type="checkbox"
                          onClick={(a) => {
                            handleSelectOption(t("spec-education-teacher"));
                            if (a.target.checked) {
                              setJobData({
                                ...jobData,
                                data2: "Special education teacher",
                              });
                            } else {
                              setJobData({
                                ...jobData,
                                data2: "",
                              });
                            }
                          }}
                        />
                        <h3>
                          <TeacherIcon />
                          {t("spec-education-teacher")}
                        </h3>
                        <span></span>
                      </p>
                      <p>
                        <input
                          type="checkbox"
                          onClick={(a) => {
                            handleSelectOption(
                              t("Special education paraprofessional")
                            );
                            if (a.target.checked) {
                              setJobData({
                                ...jobData,
                                data3: "Special education paraprofessional",
                              });
                            } else {
                              setJobData({
                                ...jobData,
                                data3: "",
                              });
                            }
                          }}
                        />
                        <h3>
                          <ParaprofessionalIcon />
                          {t("Special education paraprofessional")}
                        </h3>
                        <span></span>
                      </p>
                      <p>
                        <input
                          type="checkbox"
                          onClick={(a) => {
                            handleSelectOption(t("text-tutor"));
                            if (a.target.checked) {
                              setJobData({
                                ...jobData,
                                data4: t("text-tutor"),
                              });
                            } else {
                              setJobData({
                                ...jobData,
                                data4: "",
                              });
                            }
                          }}
                        />
                        <h3>
                          <TutorIcon />
                          {t("text-tutor")}
                        </h3>
                        <span></span>
                      </p>
                    </div>
                    <span
                      onClick={(e) => handleElementById("cate8", "over119")}
                    >
                      <ArrowUpIcon color={"#7D2B8B"} />
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="city keyword">
            <input
              type="text"
              placeholder={t("text-city")}
              id="my-fil-city"
              onChange={(e) => setSearch({ ...search, city: e.target.value })}
            />
          </div>
          <div className="zip code">
            <input
              type="text"
              placeholder={t("Zip cod")}
              id="my-fil-zip"
              onChange={(e) => setSearch({ ...search, zip: e.target.value })}
            />
          </div>
          <div className="distance">
            <div className="distance_input">
              <select
                name=""
                id="my-fil-dis"
                onChange={(e) =>
                  setSearch({
                    ...search,
                    distance: e.target.value,
                  })
                }
              >
                <option value="" selected>
                  {t("Distance")}
                </option>
                <option value={5}>5 {t("Miles")}</option>
                <option value={10}>10 {t("Miles")}</option>
                <option value={15}>15 {t("Miles")}</option>
                <option value={20}>20 {t("Miles")}</option>
                <option value={25}>25 {t("Miles")}</option>
                <option value={30}>30 {t("Miles")}</option>
                <option value={35}>35 {t("Miles")}</option>
                <option value={40}>40 {t("Miles")}</option>
                <option value={45}>45 {t("Miles")}</option>
                <option value={50}>50 {t("Miles")}</option>
              </select>
            </div>
          </div>
        </div>
        <div className="advance_search_filter">
          <div
            className={
              "advance_search_filter_input Profile_complete " +
              (!isCatOpen ? "op" : "")
            }
          >
            <div
              className="filters_search detail work-experience"
              id="filterdata"
            >
              <div className="form_group full border qualification selectbt">
                <label>{t("text-frequency")}</label>
                <div className="checkbox create">
                  <ul>
                    <li>
                      <input
                        type="checkbox"
                        name=""
                        onClick={(e) => {
                          if (e.target.checked) {
                            setSearch({
                              ...search,
                              tutorintrestedin: "Full time",
                            });
                          } else {
                            setSearch({
                              ...search,
                              tutorintrestedin: "",
                            });
                          }
                        }}
                      />
                      <span> {t("full-time")}</span>
                    </li>
                    <li>
                      <input
                        type="checkbox"
                        name=""
                        onClick={(e) => {
                          if (e.target.checked) {
                            setSearch({
                              ...search,
                              tutorintrestedin1: "Part time",
                            });
                          } else {
                            setSearch({
                              ...search,
                              tutorintrestedin1: "",
                            });
                          }
                        }}
                      />
                      <span> {t("part-time")}</span>
                    </li>
                    <li>
                      <input
                        type="checkbox"
                        name=""
                        onClick={(e) => {
                          if (e.target.checked) {
                            setSearch({
                              ...search,
                              tutorintrestedin2: "Occasionally",
                            });
                          } else {
                            setSearch({
                              ...search,
                              tutorintrestedin2: "",
                            });
                          }
                        }}
                      />
                      <span> {t("occasionally")}</span>
                    </li>
                  </ul>
                </div>
                {/* <div className='errorfield'>{error.message}</div> */}
              </div>
              <div className="form_group full border">
                <label>
                  {t("Hourly rate")}
                  {(profile && profile.country == "Serbia") || "Srbija"
                    ? "(RSD)"
                    : "(USD)"}
                </label>
                {(profile && profile.country == "Serbia") || "Srbija" ? (
                  <div className="wrapper">
                    <div className="container_slide">
                      <div className="slider-track">
                        <ul>
                          {RATE_NUMBERS.map((value, index) => {
                            const style =
                              rateNumber.min >= value ||
                              (rateNumber.max < value && rateNumber.max > 0)
                                ? { backgroundColor: "#E5E5E5" }
                                : {};
                            return <li key={index} style={style}></li>;
                          })}
                        </ul>
                      </div>
                      <div className="bggray_slider">
                        <ul>
                          {RATE_NUMBERS_SECOND.map((value, index) => {
                            const style =
                              rateNumber.min >= value ||
                              (rateNumber.max < value && rateNumber.max > 0)
                                ? { backgroundColor: "#E5E5E5" }
                                : {};
                            return <li key={index} style={style}></li>;
                          })}
                        </ul>
                      </div>
                      <input
                        type="range"
                        min="0"
                        max="5"
                        id="slider-1"
                        value={rateNumber.min / 10}
                        onChange={(e) => {
                          if (
                            (rateNumber.max > 0 ? rateNumber.max : 60) >
                            (e.target.value == 5 ? 60 : e.target.value * 10)
                          ) {
                            setRateNumber({
                              ...rateNumber,
                              min:
                                e.target.value == 5 ? 60 : e.target.value * 10,
                            });
                          }
                        }}
                      />
                      <input
                        type="range"
                        min="0"
                        max="5"
                        id="slider-2"
                        value={
                          rateNumber.max == 0 ? 50 / 10 : rateNumber.max / 10
                        }
                        onChange={(e) => {
                          if (
                            rateNumber.min <
                            (e.target.value == 5 ? 60 : e.target.value * 10)
                          ) {
                            setRateNumber({
                              ...rateNumber,
                              max:
                                e.target.value == 5 ? 60 : e.target.value * 10,
                            });
                          }
                        }}
                      />
                    </div>
                    <div className="slider two">
                      <ul>
                        <li>0</li>
                        <li>1000</li>
                        <li>2000</li>
                        <li>3000</li>
                        <li>4000</li>
                        <li>6000+</li>
                      </ul>
                    </div>
                  </div>
                ) : (
                  <div className="wrapper">
                    <div className="container_slide">
                      <div className="slider-track">
                        <ul>
                          {RATE_NUMBERS.map((value, index) => {
                            const style =
                              rateNumber.min >= value ||
                              (rateNumber.max < value && rateNumber.max > 0)
                                ? { backgroundColor: "#E5E5E5" }
                                : {};
                            return <li key={index} style={style}></li>;
                          })}
                        </ul>
                      </div>
                      <div className="bggray_slider">
                        <ul>
                          {RATE_NUMBERS_SECOND.map((value, index) => {
                            const style =
                              rateNumber.min >= value ||
                              (rateNumber.max < value && rateNumber.max > 0)
                                ? { backgroundColor: "#E5E5E5" }
                                : {};
                            return <li key={index} style={style}></li>;
                          })}
                        </ul>
                      </div>
                      <input
                        type="range"
                        min="0"
                        max="5"
                        id="slider-1"
                        value={rateNumber.min / 10}
                        onChange={(e) => {
                          if (
                            (rateNumber.max > 0 ? rateNumber.max : 60) >
                            (e.target.value == 5 ? 60 : e.target.value * 10)
                          ) {
                            setRateNumber({
                              ...rateNumber,
                              min:
                                e.target.value == 5 ? 60 : e.target.value * 10,
                            });
                          }
                        }}
                      />
                      <input
                        type="range"
                        min="0"
                        max="5"
                        id="slider-2"
                        value={
                          rateNumber.max == 0 ? 50 / 10 : rateNumber.max / 10
                        }
                        onChange={(e) => {
                          if (
                            rateNumber.min <
                            (e.target.value == 5 ? 60 : e.target.value * 10)
                          ) {
                            setRateNumber({
                              ...rateNumber,
                              max:
                                e.target.value == 5 ? 60 : e.target.value * 10,
                            });
                          }
                        }}
                      />
                    </div>
                    <div className="slider two">
                      <ul>
                        <li>0</li>
                        <li>10</li>
                        <li>20</li>
                        <li>30</li>
                        <li>40</li>
                        <li>60+</li>
                      </ul>
                    </div>
                  </div>
                )}
                <span className="price">
                  {(profile && profile.country == "Serbia") || "Srbija"
                    ? rateNumber.min * 100 + " - " + rateNumber.max * 100
                    : rateNumber.min +
                      " - " +
                      (rateNumber.max > 0
                        ? rateNumber.max == 60
                          ? rateNumber.max + "+"
                          : rateNumber.max
                        : rateNumber.max == 60
                        ? 60 + "+"
                        : 60 + "+")}
                </span>
                {/* <div className='errorfield'>{error.message}</div> */}
              </div>
              <div className="form_group full border">
                <label>{t("Expected work experience")}</label>
                <div className="select">
                  <select
                    onChange={(e) =>
                      setSearch({
                        ...search,
                        tutorexp: e.target.value,
                      })
                    }
                  >
                    <option disabled="">{t("choose-list")}</option>
                    <option value={t(0)}>{t("years-0-1")}</option>
                    <option value={t(1)}>{t("years-1-2")}</option>
                    <option value={t(2)}>{t("years-2-4")}</option>
                    <option value={t(3)}>{t("years-4-more")}</option>
                  </select>
                </div>
              </div>
              <div className="form_group full border">
                <label>{t("Number of children")}</label>
                <div className="children_number">
                  <ul>
                    <li
                      onClick={(e) => {
                        if (selectCategory.data1 == "") {
                          setSelectCategory({
                            ...selectCategory,
                            data1: 1,
                          });
                          setChildren(1);
                        } else {
                          setSelectCategory({
                            ...selectCategory,
                            data1: "",
                          });
                          setChildren("");
                        }
                      }}
                      className={selectCategory.data1 == 1 ? "active" : ""}
                    >
                      1
                    </li>
                    <li
                      onClick={(e) => {
                        if (selectCategory.data2 == "") {
                          setSelectCategory({
                            ...selectCategory,
                            data2: 2,
                          });
                          setChildren(2);
                        } else {
                          setSelectCategory({
                            ...selectCategory,
                            data2: "",
                          });
                          setChildren("");
                        }
                      }}
                      className={selectCategory.data2 == 2 ? "active" : ""}
                    >
                      2
                    </li>
                    <li
                      onClick={(e) => {
                        if (selectCategory.data3 == "") {
                          setSelectCategory({
                            ...selectCategory,
                            data3: t("twins"),
                          });
                          setChildren(t("twins"));
                        } else {
                          setSelectCategory({
                            ...selectCategory,
                            data3: "",
                          });
                          setChildren("");
                        }
                      }}
                      className={
                        selectCategory.data3 == "twins" ? "active" : ""
                      }
                    >
                      {t("text-twins")}
                    </li>
                    <li
                      onClick={(e) => {
                        if (selectCategory.data4 == "") {
                          setSelectCategory({
                            ...selectCategory,
                            data4: "3",
                          });
                          setChildren(3);
                        } else {
                          setSelectCategory({
                            ...selectCategory,
                            data4: "",
                          });
                          setChildren("");
                        }
                      }}
                      className={selectCategory.data4 == 3 ? "active" : ""}
                    >
                      3+
                    </li>
                  </ul>
                </div>
                {/* <div className='errorfield'>{error.message}</div> */}
              </div>
              <div className="job_performance">
                <div className="form_group   full border">
                  <label>{t("Provider's preferred age")}</label>
                  <div className="customselect inp">
                    <input
                      className="keyword"
                      type="text"
                      placeholder={t("choose-list")}
                      value={childrenAge.map((e) => {
                        return e.name;
                      })}
                    />
                    <div
                      className="overflow"
                      id="over9"
                      onClick={(e) => handleElementById("cate9", "over9")}
                    ></div>
                    <div className="option" id="cate9">
                      <p>
                        <input
                          type="checkbox"
                          onClick={(a) => {
                            handleChildrenAge(t("years-18-23"));
                            if (a.target.checked) {
                              setJobCategory({
                                ...jobCategory,
                                data1: t(0),
                              });
                            } else {
                              setJobCategory({
                                ...jobCategory,
                                data1: "",
                              });
                            }
                          }}
                        />
                        <h3>{t("years-18-23")} </h3>
                        <span></span>
                      </p>
                      <p>
                        <input
                          type="checkbox"
                          onClick={(a) => {
                            handleChildrenAge(t("years-24-30"));
                            if (a.target.checked) {
                              setJobCategory({
                                ...jobCategory,
                                data1: t(1),
                              });
                            } else {
                              setJobCategory({
                                ...jobCategory,
                                data1: "",
                              });
                            }
                          }}
                        />
                        <h3>{t("years-24-30")} </h3>
                        <span></span>
                      </p>
                      <p>
                        <input
                          type="checkbox"
                          onClick={(a) => {
                            handleChildrenAge(t("years-31-40"));
                            if (a.target.checked) {
                              setJobCategory({
                                ...jobCategory,
                                data1: t(2),
                              });
                            } else {
                              setJobCategory({
                                ...jobCategory,
                                data1: "",
                              });
                            }
                          }}
                        />
                        <h3>{t("years-31-40")} </h3>
                        <span></span>
                      </p>
                      <p>
                        <input
                          type="checkbox"
                          onClick={(a) => {
                            handleChildrenAge(t("years-40"));
                            if (a.target.checked) {
                              setJobCategory({
                                ...jobCategory,
                                data1: t(3),
                              });
                            } else {
                              setJobCategory({
                                ...jobCategory,
                                data1: "",
                              });
                            }
                          }}
                        />
                        <h3>{t("years-40")} </h3>
                        <span></span>
                      </p>
                    </div>
                    <span onClick={(e) => handleElementById("cate9", "over9")}>
                      <ArrowUpIcon />
                    </span>
                  </div>
                  {/* <div className='errorfield'>{error.message}</div> */}
                </div>
              </div>
              <div className="form_group full border qualification">
                <label>{t("start-date")}</label>
                <div className="checkbox create">
                  <ul>
                    <li>
                      <input
                        type="checkbox"
                        name="b"
                        onClick={(e) => {
                          setSearch({
                            ...search,
                            withinweek: e.target.checked,
                          });
                        }}
                      />
                      <span> {t("Within a week")}</span>
                    </li>
                    <li>
                      <input
                        type="checkbox"
                        name="b"
                        onClick={(e) => {
                          setSearch({
                            ...search,
                            withinmonth: e.target.checked,
                          });
                        }}
                      />
                      <span> {t("Within a month")}</span>
                    </li>
                  </ul>
                </div>
                {/* <div className='errorfield'>{error.message}</div> */}
              </div>
              <div className="form_group full border qualification selectbt">
                <label>{t("Other options")}</label>
                <div className="checkbox create">
                  <ul>
                    <li>
                      <input type="checkbox" name="" />
                      <span> {t("Has car or valid driver licence")}</span>
                    </li>
                    <li>
                      <input type="checkbox" name="" />
                      <span> {t("Has a background check")}</span>
                    </li>
                    <li>
                      <input
                        type="checkbox"
                        name=""
                        onClick={(e) => {
                          if (search.workingabroad == "") {
                            setSearch({
                              ...search,
                              workingabroad: "Yes",
                            });
                          } else {
                            setSearch({
                              ...search,
                              workingabroad: "",
                            });
                          }
                        }}
                      />
                      <span> {t("Willing to work abroad")} </span>
                    </li>
                  </ul>
                </div>
                {/* <div className='errorfield'>{error.message}</div> */}
              </div>
              <div className="form_group full qualification selectbt">
                <label>{t("Experience with")}</label>
                <div className="checkbox create">
                  <ul>
                    <li>
                      <input type="checkbox" name="" />
                      <span> {t("Newborns")}</span>
                    </li>
                    <li>
                      <input
                        type="checkbox"
                        name=""
                        onClick={(e) => {
                          if (search.childsocialneed == "") {
                            setSearch({
                              ...search,
                              childsocialneed: "Yes",
                            });
                          } else {
                            setSearch({
                              ...search,
                              childsocialneed: "",
                            });
                          }
                        }}
                      />
                      <span> {t("Kids with special needs")}</span>
                    </li>
                  </ul>
                </div>
                {/* <div className='errorfield'>{error.message}</div> */}
              </div>
              {jobData.data4 ? (
                <div className="form_group full">
                  <label>{t("I need a tutor for")}</label>
                  <div className="checkbox create">
                    <ul>
                      <li>
                        <input
                          type="radio"
                          name=""
                          onClick={(e) => {
                            if (languageSpeak.English == "") {
                              setLanguageSpeak({
                                ...languageSpeak,
                                English: "English",
                              });
                            } else {
                              setLanguageSpeak({
                                ...languageSpeak,
                                English: "",
                              });
                            }
                          }}
                        />
                        <span> {t("english")}</span>
                      </li>
                      <li>
                        <input
                          type="radio"
                          name=""
                          onClick={(e) => {
                            if (languageSpeak.Serbian == "") {
                              setLanguageSpeak({
                                ...languageSpeak,
                                Serbian: "Serbian",
                              });
                            } else {
                              setLanguageSpeak({
                                ...languageSpeak,
                                Serbian: "",
                              });
                            }
                          }}
                        />
                        <span> {t("serbian")}</span>
                      </li>
                      <li>
                        <input
                          type="radio"
                          name=""
                          onClick={(e) => {
                            if (languageSpeak.Mathematics == "") {
                              setLanguageSpeak({
                                ...languageSpeak,
                                Mathematics: "Mathematics",
                              });
                            } else {
                              setLanguageSpeak({
                                ...languageSpeak,
                                Mathematics: "",
                              });
                            }
                          }}
                        />
                        <span> {t("mathematics")}</span>
                      </li>
                      <li>
                        <input
                          type="radio"
                          name=""
                          onClick={(e) => {
                            if (languageSpeak.Physics == "") {
                              setLanguageSpeak({
                                ...languageSpeak,
                                Physics: "Physics",
                              });
                            } else {
                              setLanguageSpeak({
                                ...languageSpeak,
                                Physics: "",
                              });
                            }
                          }}
                        />
                        <span> {t("physics")}</span>
                      </li>
                      <li>
                        <input
                          type="radio"
                          name=""
                          onClick={(e) => {
                            if (languageSpeak.Chemistry == "") {
                              setLanguageSpeak({
                                ...languageSpeak,
                                Chemistry: "Chemistry",
                              });
                            } else {
                              setLanguageSpeak({
                                ...languageSpeak,
                                Chemistry: "",
                              });
                            }
                          }}
                        />
                        <span> {t("chemistry")}</span>
                      </li>
                      <li>
                        <input
                          type="radio"
                          name=""
                          onClick={(e) => {
                            if (languageSpeak.Online == "") {
                              setLanguageSpeak({
                                ...languageSpeak,
                                Online: "Online classes",
                              });
                            } else {
                              setLanguageSpeak({
                                ...languageSpeak,
                                Online: "",
                              });
                            }
                          }}
                        />
                        <span> {t("Online classes")}</span>
                      </li>
                    </ul>
                  </div>
                </div>
              ) : (
                ""
              )}
              <button
                onClick={(e) => {
                  setSubTab("");
                  localStorage.removeItem("searchdata");
                  fetchRateData();
                  window.scrollTo({ top: 0, behavior: "smooth" });
                  handleCloseProviderModal();
                }}
              >
                <MainDandruffIcon />
                {t("Refine result")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
