export const AutoIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="11"
      viewBox="0 0 16 11"
      fill="none"
    >
      <path
        d="M15.7761 4.99744L12.8403 0.593722C12.7185 0.411089 12.5535 0.261341 12.36 0.157768C12.1665 0.0541962 11.9504 3.4718e-06 11.7309 0H4.26914C4.04964 7.03035e-07 3.83353 0.0541924 3.64 0.157765C3.44647 0.261338 3.28151 0.411087 3.15975 0.593722L0.223944 4.99744C0.0779189 5.21646 -3.21479e-06 5.4738 0 5.73703L0 9.33333C0 10.0697 0.596944 10.6667 1.33333 10.6667H14.6667C15.4031 10.6667 16 10.0697 16 9.33333V5.73703C16 5.4738 15.9221 5.21646 15.7761 4.99744ZM4.507 1.77778H11.493L13.8634 5.33333H10.4444L9.55556 7.11111H6.44444L5.55556 5.33333H2.13661L4.507 1.77778Z"
        fill="#636363"
      />
    </svg>
  );
};
