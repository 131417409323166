import { create } from "zustand";

export const useModalStore = create((set) => ({
  isEditCardModal: false,
  isNewCardModal: false,
  isMembershipModal: false,
  isWarningModal: false,
  isAutoDeleteModal: false,
  isApologizeModal: false,
  isSignoutModal: false,
  isSurveyModal: false,
  setIsEditCardModal: (value) => set({ isEditCardModal: value }),
  setIsNewCardModal: (value) => set({ isNewCardModal: value }),
  setIsMembershipModal: (value) => set({ isMembershipModal: value }),
  setIsWarningModal: (value) => set({ isWarningModal: value }),
  setIsAutoDeleteModal: (value) => set({ isAutoDeleteModal: value }),
  setIsApologizeModal: (value) => set({ isApologizeModal: value }),
  setIsSignoutModal: (value) => set({ isSignoutModal: value }),
  setIsSurveyModal: (value) => set({ isSurveyModal: value }),
}));
