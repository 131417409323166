import React from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { CaseIcon } from "../../../assets/image/CaseIcon";
import { CommentIcon } from "../../../assets/image/CommentIcon";
import { PageListIcon } from "../../../assets/image/PageListIcon";
import { JobListIcon } from "../../../assets/image/JobListIcon";

export function JobHistory(props) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <>
      <ul>
        <li
          className={props.subtab == "job-applications" ? "active" : ""}
          onClick={(e) => {
            window.scrollTo({ top: 0, behavior: "smooth" });
            props.setsubtab("job-applications");
            navigate("/search-providers/job-applications");
          }}
        >
          <CaseIcon />
          {t("job-applications")}
        </li>
        <li
          className={props.subtab == "interview-invites" ? "active" : ""}
          onClick={(e) => {
            window.scrollTo({ top: 0, behavior: "smooth" });
            props.setsubtab("interview-invites");
            navigate("/search-providers/interview-invites");
          }}
        >
          <CommentIcon />
          {t("interview-invites")}
        </li>
        <li
          className={props.subtab == "my-document" ? "active" : ""}
          onClick={(e) => {
            window.scrollTo({ top: 0, behavior: "smooth" });
            props.setsubtab("my-document");
            navigate("/search-providers/my-document");
          }}
        >
          <JobListIcon />
          {t("title-my-documents")}
        </li>
        <li
          className={props.subtab == "document-requests" ? "active" : ""}
          onClick={(e) => {
            window.scrollTo({ top: 0, behavior: "smooth" });
            props.setsubtab("document-requests");
            navigate("/search-providers/document-requests");
          }}
        >
          <PageListIcon />
          {t("document-requests")}
        </li>
      </ul>
    </>
  );
}
