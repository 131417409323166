import React, {useEffect} from "react";
import { useTranslation } from "react-i18next";

import { Testimonials } from "../../components/common/Testimonials";
import { Footer } from "../../components/common/Footer";
import { Header } from "../../components/common/Header";
import {useLocation} from "react-router-dom";

export function ThankParentsPage() {
  const { t } = useTranslation();
  const useQuery = () => new URLSearchParams(useLocation().search);

  return (
    <>
      <Header />
      <div className="container-fluid ">
        <div className="container">
          <div className="thank_page ">
            <h2>{t("post-thanks-join")} </h2>
            <div className="thanks over">
              <h4>{t("together-create-future")}</h4>
              <p>
                {t(
                  "post-register-senscare"
                )}
              </p>
              <ul>
                <li>
                  <img src="./images/thanks_right.svg" />
                  <span>{t("children-services-place")}</span>
                </li>
                <li>
                  <img src="./images/thanks_right.svg" />
                  <span>{t("quality-safe-services")}</span>
                </li>
                <li>
                  <img src="./images/thanks_right.svg" />
                  <span>
                    {t("wide-selection-providers")} <br />(
                    {t("domestically-internationally")}).
                  </span>
                </li>
              </ul>
            </div>
            <img className="flow" src="./images/thank_banner.png" />
          </div>
        </div>
      </div>
      <Testimonials />
      <Footer />
    </>
  );
}