import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import {
  ENG_LANGUAGE,
  MONTH_ENG_LANGUAGE,
  MONTH_SRB_LANGUAGE,
} from "../../../utils/variables";

export const InterviewDataRow = ({
  data,
  hadnleDeleteSelect,
  setView,
  slugdata,
}) => {
  const { t, i18n } = useTranslation();
  const language = i18n.language;

  const navigate = useNavigate();

  const month =
    language === ENG_LANGUAGE ? MONTH_ENG_LANGUAGE : MONTH_SRB_LANGUAGE;

  return (
    <tr
      class={
        data.jobStatus != 1
          ? data.parent_readstatus == 0
            ? t("text-unread")
            : ""
          : t("text-close")
      }
    >
      <td class="view-message  dont-show">
        <input
          type="checkbox"
          class="mail-radio"
          name="chk"
          onChange={(e) => hadnleDeleteSelect(data.id)}
        />
        <span className="circle"></span>
        <span
          onClick={(e) => {
            navigate("/search-parents/" + slugdata.id + "/" + data.id);
            setView(data.id);
          }}
        >
          {data.jobStatus != 1 ? (
            data.parent_readstatus == 0 ? (
              <span class="new-tag"> {t("text-new-upper")}</span>
            ) : (
              ""
            )
          ) : (
            <span class="new-tag"> {t("text-closed")}</span>
          )}
          <span
            class="username-text"
            onClick={(e) => {
              navigate("/search-parents/" + slugdata.id + "/" + data.id);
              setView(data.id);
            }}
          >
            {data.ProviderName}
          </span>
          {t("has-just")}
          {data.status == 1
            ? t("text-accepted")
            : data.status == 2
            ? t("text-declined")
            : t("text-pending")}
          {t("person-interview-invite")}
        </span>
      </td>
      <td class="view-message  text-right">
        {data.created_at ? month[new Date(data.created_at).getMonth()] : ""}
        {" " +
          (parseInt(new Date(data.created_at).getDate()) < 10 ? "0" : "") +
          new Date(data.created_at).getDate()}
      </td>
    </tr>
  );
};
