import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { PromoCode } from "../../common/PromoCode";
import { SocialFacebook } from "../../common/SocialFacebook";
import { fetchReferralLink, fetchReferralUser } from "../../../api";

export function LoyaltyProgram() {
  const { t } = useTranslation();

  const [referralData, setReferralData] = useState({});
  const [isReferralLink, setIsReferralLink] = useState(true);
  const [isFullPrice, setIsFullPrice] = useState(true);
  const [isDiscount, setIsDiscount] = useState(true);
  const [loyality, setLoyalty] = useState({});

  const fetchReferralData = async () => {
    try {
      const referralLinkResult = await fetchReferralLink();
      setReferralData(referralLinkResult.data);

      const loyaltyResult = await fetchReferralUser();
      setLoyalty(loyaltyResult.data);
    } catch (error) {
      console.error("Error fetching referral data:", error);
    }
  };

  useEffect(() => {
    fetchReferralData();
  }, [loyality]);

  const handleCopy = (text) => {
    const el = document.createElement("textarea");
    el.value = text;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    const tooltip = document.getElementById("myTooltip2");
    tooltip.innerHTML = t("text-copied");
  };

  return (
    <>
      <div class="senscare_wrapper">
        <h2>{t("senscare-loyalty-program")}</h2>
        <div className="border"></div>
        <br />
        <div class="tab row justify_content-between">
          <div class="column">
            <h2>{t("text-invite-friends")}</h2>
          </div>
          <div
            class="column align-center"
            onClick={(e) => setIsReferralLink(!isReferralLink)}
          >
            <i class="fa fa-chevron-up" aria-hidden="true"></i>
          </div>
        </div>
        
        {isReferralLink ? (
          <div class="inner_wrapper">
            <div class="column_left">
              <p>{t("personal-referral-link")}</p>
              <div class="link_wrap row justify_content-between align-center">
                <a style={{ width: "calc(100% - 120px)" }}>
                  <span>{referralData.referralLink}</span>...
                </a>
                <div class="btn btn-secondary">
                  <a
                    href="#"
                    onClick={(e) =>
                      handleCopy(referralData.referralLink.toString())
                    }
                    class="tooltiptext"
                    id="myTooltip2"
                  >
                    {t("text-copy")}
                  </a>
                </div>
              </div>
              <br />
              <div class="icon_wrap flex align-center">
                {localStorage.getItem("user_type") == "parents" ? (
                  <SocialFacebook
                    link={referralData.referralLink}
                    icon={"yes"}
                  />
                ) : (
                  <SocialFacebook
                    link={referralData.referralLink}
                    icon={"yes"}
                  />
                )}
                <h4>{t("share-friends-family")}</h4>
              </div>
            </div>
            <div class="column_right ">
              <img
                src={window.location.origin + "/images/loylty_right.jpg"}
                alt=""
              />
            </div>
            <div class="column_left left2">
              <br />
              <div class="tab row align-center justify_content-between">
                <div class="column">
                  <h2>{t("find-out-more")}</h2>
                </div>
                <div class="column">
                  <i class="fa fa-chevron-up" aria-hidden="true"></i>
                </div>
                <div className="border" style={{ width: "100%" }}></div>
                <p style={{ marginBottom: "-20px" }}>
                  {t(
                    "bring-friends-platform"
                  )}
                  <span style={{
                    marginRight: "5px",
                    marginLeft: "5px",
                  }}>
                    <PromoCode
                      title={t(
                        "discount-membership-free"
                      )}
                    />
                  </span>
                  {t(
                    "remain-active-member"
                  )}
                </p>
                <p>
                  {t(
                    "post-create-account-member"
                  )}
                </p>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
        <div class="tab tab_2 row justify_content-between">
          <div class="column">
            <h2>{t("senscare-loyality")}</h2>
          </div>
          <div
            class="column  align-center"
            onClick={(e) => setIsFullPrice(!isFullPrice)}
          >
            <i class="fa fa-chevron-up" aria-hidden="true"></i>
          </div>
        </div>
        {isFullPrice ? (
          <div class="walk_section">
            {loyality.referraluserchekfordiscount < 5 ||
            !loyality.referraluserchekfordiscount ? (
              <div class="column">
                <img src={window.location.origin + "/images/walk.svg"} alt="" />
                <ul class="row">
                  <li>
                    <h4>
                      <strong>{t("full-price")}</strong>
                      <br />
                      <span>{t("rsd-1500")}</span>
                      <p>{t("walk-together")}</p>
                    </h4>
                  </li>
                  <li>
                    <h4>
                      {t("discount-1st")} <br />
                      <span>{t("rsd-1000")}</span>
                    </h4>
                  </li>
                  <li>
                    <h4>
                      {t("discount-2st")}
                      <br />
                      <span>{t("rsd-500")}</span>
                      <p style={{ color: "#BE95C5" }}>
                        {t("senscare-rewards")}
                      </p>
                    </h4>
                  </li>
                </ul>
              </div>
            ) : (
              <div class="column"></div>
            )}
            {loyality.referraluserchekfordiscount >= 5 &&
            loyality.referraluserchekfordiscount < 12 ? (
              <div class="column">
                <img src={window.location.origin + "/images/walk.jpg"} alt="" />
                <ul class="row">
                  <li>
                    <h4>
                      {t("full-price")}
                      <br />
                      <span>{t("rsd-1500")}</span>
                      <p>{t("walk-together")}</p>
                    </h4>
                  </li>
                  <li>
                    <h4>
                      <strong>{t("discount-1st")}</strong> <br />
                      <span>{t("rsd-1000")}</span>
                    </h4>
                  </li>
                  <li>
                    <h4>
                      {t("discount-2st")} <br />
                      <span>{t("rsd-500")}</span>
                      <p style={{ color: "#BE95C5" }}>
                        {t("senscare-rewards")}
                      </p>
                    </h4>
                  </li>
                </ul>
              </div>
            ) : (
              <div class="column"></div>
            )}
            {loyality.referraluserchekfordiscount >= 12 ? (
              <div class="column">
                <img src={window.location.origin + "/images/walk.jpg"} alt="" />
                <ul class="row">
                  <li>
                    <h4>
                      {t("full-price")}
                      <br />
                      <span>{t("rsd-1500")}</span>
                      <p>{t("walk-together")}</p>
                    </h4>
                  </li>
                  <li>
                    <h4>
                      {t("discount-1st")}
                      <br />
                      <span>{t("rsd-1000")}</span>
                    </h4>
                  </li>
                  <li>
                    <h4>
                      <strong>{t("discount-2st")}</strong>
                      <br />
                      <span>{t("rsd-500")}</span>
                      <p>{t("senscare-rewards")}</p>
                    </h4>
                  </li>
                </ul>
              </div>
            ) : (
              <div class="column"></div>
            )}
          </div>
        ) : (
          ""
        )}
        <div class="tab tab_3 row justify_content-between">
          <div class="column">
            <h2>{t("find-out-more")}</h2>
          </div>
          <div
            class="column  align-center"
            onClick={(e) => setIsDiscount(!isDiscount)}
          >
            <i class="fa fa-chevron-up" aria-hidden="true"></i>
          </div>
          {isDiscount ? (
            <>
              <div className="border" style={{ width: "100%" }}></div>

              <p style={{ width: "100%" }}>
                {t(
                  "gratitude-loyal-members"
                )}
                <span style={{
                  marginRight: "5px",
                  marginLeft: "5px",
                }}>{t("discount-500-twice-year")}</span>
                {t("every-month")}
              </p>
              <br />
            </>
          ) : (
            ""
          )}
        </div>
        <br />
        <br />
        <p className="lastline">
          {t("sencare-information-promotions")}
          <Link to="/terms-of-use"> {t("text-here-with")}</Link>
        </p>
      </div>
    </>
  );
}
