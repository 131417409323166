export const OptionIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="4"
      height="16"
      viewBox="0 0 4 16"
      fill="none"
    >
      <circle cx="1.84617" cy="1.84617" r="1.84617" fill="#636363" />
      <circle cx="1.84617" cy="8.00046" r="1.84617" fill="#636363" />
      <circle cx="1.84617" cy="14.1528" r="1.84617" fill="#636363" />
    </svg>
  );
};
