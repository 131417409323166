export const ServiceMarkIcon = () => {
  return (
    <svg
      width="16"
      height="18"
      viewBox="0 0 16 18"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.5067 1.77778H14.2222C15.2 1.77778 16 2.57778 16 3.55556V16C16 16.9778 15.2 17.7778 14.2222 17.7778H1.77778C0.8 17.7778 0 16.9778 0 16V3.55556C0 2.57778 0.8 1.77778 1.77778 1.77778H5.49333C5.86667 0.746667 6.84444 0 8 0C9.15556 0 10.1333 0.746667 10.5067 1.77778ZM8.88932 2.66623C8.88932 2.17734 8.48932 1.77734 8.00043 1.77734C7.51154 1.77734 7.11154 2.17734 7.11154 2.66623C7.11154 3.15512 7.51154 3.55512 8.00043 3.55512C8.48932 3.55512 8.88932 3.15512 8.88932 2.66623ZM7.9998 5.33313C9.47535 5.33313 10.6665 6.52424 10.6665 7.9998C10.6665 9.47535 9.47535 10.6665 7.9998 10.6665C6.52424 10.6665 5.33313 9.47535 5.33313 7.9998C5.33313 6.52424 6.52424 5.33313 7.9998 5.33313ZM2.66711 14.7553V15.9998H13.3338V14.7553C13.3338 12.9775 9.77822 11.9998 8.00045 11.9998C6.22267 11.9998 2.66711 12.9775 2.66711 14.7553Z"
        fill="#636363"
      />
    </svg>
  );
};
