import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { api } from "../../../urls";

import {
  ENG_LANGUAGE,
  MONTH_ENG_LANGUAGE,
  MONTH_SRB_LANGUAGE,
} from "../../../utils/variables";

import { JobPostModal } from "./Modals/JobPostModal";
import { DeletePostModal } from "./Modals/DeletePostModal";
import { JobProfileCard } from "./JobProfileCard";
import { JobProfileMobileCard } from "./JobProfileMobileCard";
import { JobPostCard } from "./JobPostCard";
import { JobPostMobileCard } from "./JobPostMobileCard";

export function JobPostParentsDetail(props) {
  const { t, i18n } = useTranslation();
  const language = i18n.language;

  const [jobData, setJobData] = useState([]);
  const [isCheck, setIsCheck] = useState(true);
  const [profile] = useState();
  const [isDelete, setIsDelete] = useState(false);
  const [isElement, setIsElement] = useState(true);
  const [isJobPost, setIsJobPost] = useState(false);
  const [editPost, setEditPost] = useState({
    plateformonsocialmedia: "",
    job_type: "",
    title: "",
    description: "",
  });

  const month =
    language === ENG_LANGUAGE ? MONTH_ENG_LANGUAGE : MONTH_SRB_LANGUAGE;

  const handleElementById = (e, x, z) => {
    console.log(e, x);
    document.getElementById(e).style.display = "none";
    document.getElementById(x).style.display = "block";
    if (isElement) {
      document.getElementById(z).style.display = "block";
      setIsElement(false);
    } else {
      setIsElement(true);
      document.getElementById(z).style.display = "none";
    }
  };

  const handleCloseJobPost = () => {
    setIsJobPost(false);
  };

  const handleProfileData = () => {
    const myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );
    myHeaders.append("Content-Type", "application/json");
    const raw = JSON.stringify({
      job_id: JSON.parse(props.id),
    });
    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(api + "/api/v1/jobdetailsview", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setJobData([result.data.jobData]);
        setEditPost({
          plateformonsocialmedia: result.data.jobData.plateformonsocialmedia,
          job_type: result.data.jobData.job_type,
          title: result.data.jobData.title,
          description: result.data.jobData.description,
        });
        console.log(result, JSON.parse(props.id));
      })
      .catch((error) => console.log("error", error));
  };

  useEffect(() => {
    if (isCheck) {
      handleProfileData();
      setIsCheck(false);
    }
    console.log(editPost);
  }, [isCheck, editPost]);

  const handleAccept = () => {
    const myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      job_id: JSON.parse(props.id),
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(api + "/api/v1/jobstatusupdate", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setIsDelete(false);
        handleProfileData();
        console.log(result);
      })
      .catch((error) => console.log("error", error));
  };

  const handleEditPost = () => {
    const myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      job_id: JSON.parse(props.id),
      plateformonsocialmedia: editPost.plateformonsocialmedia,
      job_type: jobData[0].job_type,
      title: editPost.title,
      description: editPost.description,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(api + "/api/v1/updatejob", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        handleCloseJobPost();
        window.location.reload();
        handleProfileData();
        console.log(result);
      })
      .catch((error) => console.log("error", error));
  };

  return (
    <>
      {jobData && jobData[0] ? (
        <>
          <h2 className="border"></h2>
          <div className="detail_invit bbnone">
            <p>
              {jobData && jobData[0] && jobData[0].read_status == 0 ? (
                <span className="new">{t("text-new-up")}</span>
              ) : (
                ""
              )}
              {t("you-job-post")}
              <span
                className="date"
                style={{
                  display: "inherit",
                  width: "auto",
                  position: "inherit",
                }}
              >
                {t("created-on")}
                {month[
                  new Date(
                    jobData && jobData[0] && jobData[0].jobcreated_at
                  ).getMonth()
                ] +
                  " " +
                  new Date(
                    jobData && jobData[0] && jobData[0].jobcreated_at
                  ).getDate()}
                ,
                {new Date(
                  jobData && jobData[0] && jobData[0].jobcreated_at
                ).getFullYear()}
                ,
                {new Date(
                  jobData && jobData[0] && jobData[0].jobcreated_at
                ).toLocaleTimeString([], {
                  hour: "2-digit",
                  minute: "2-digit",
                })}
              </span>
            </p>
          </div>
          <div class="right_side_section">
            <div class="looking_for_candidate">
              {jobData.map((data, index) => {
                return data.jobStatus == 0 ? (
                  <JobProfileCard
                    data={data}
                    jobData={jobData}
                    profile={profile}
                    setIsElement={setIsElement}
                    handleElementById={handleElementById}
                    setIsDelete={setIsDelete}
                    setIsJobPost={setIsJobPost}
                    index={index}
                  />
                ) : (
                  <JobProfileMobileCard
                    data={data}
                    jobData={jobData}
                    profile={profile}
                    setIsElement={setIsElement}
                    handleElementById={handleElementById}
                    index={index}
                  />
                );
              })}

              <div className="mobile">
                {jobData.map((data, index) => {
                  return data.jobStatus == 0 ? (
                    <JobPostCard
                      handleElementById={handleElementById}
                      setIsDelete={setIsDelete}
                      setIsJobPost={setIsJobPost}
                      data={data}
                      jobData={jobData}
                      profile={profile}
                      index={index}
                    />
                  ) : (
                    <JobPostMobileCard
                      data={data}
                      profile={profile}
                      index={index}
                      handleElementById={handleElementById}
                      jobData={jobData}
                    />
                  );
                })}
              </div>
            </div>
          </div>
        </>
      ) : (
        ""
      )}

      {isDelete && (
        <DeletePostModal
          isDelete={isDelete}
          setIsDelete={setIsDelete}
          handleAccept={handleAccept}
        />
      )}

      {isJobPost && (
        <JobPostModal
          isJobPost={isJobPost}
          handleCloseJobPost={handleCloseJobPost}
          editPost={editPost}
          setEditPost={setEditPost}
          handleEditPost={handleEditPost}
          jobData={jobData}
        />
      )}
    </>
  );
}
