export const FacebookIcon = ({
  className,
  width,
  height,
  color,
  textColor,
  fillColor,
}) => {
  const generalWidth = width || "40";
  const generalHeight = height || "40";
  const generalFillColor = fillColor || "none";
  const generalColor = color || "#A98D4B";
  const generalTextColor = textColor || "#A98D4B";

  return (
    <svg
      className={className}
      width={generalWidth}
      height={generalHeight}
      viewBox="0 0 40 40"
      fill={generalFillColor}
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.5"
        y="0.5"
        width="39"
        height="39"
        rx="19.5"
        stroke={generalColor}
      />
      <path
        d="M21.0677 28.9998V20.7891H23.8237L24.2363 17.5893H21.0677V15.5463C21.0677 14.6199 21.325 13.9885 22.6536 13.9885L24.348 13.9877V11.1258C24.0549 11.087 23.0491 10.9999 21.879 10.9999C19.436 10.9999 17.7634 12.491 17.7634 15.2296V17.5894H15.0003V20.7892H17.7633V28.9999L21.0677 28.9998Z"
        fill={generalTextColor}
      />
    </svg>
  );
};
