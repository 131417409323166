import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Modal from "react-bootstrap/Modal";

export const SurveyModal = ({
  isSurvey,
  surveyData,
  setSurveyData,
  handleEmailSurvey,
  handleSaveSurvey,
}) => {
  const { t } = useTranslation();

  return (
    <Modal show={isSurvey} onHide={(e) => handleEmailSurvey()}>
      <Modal.Body>
        <div className="promocode_content cancelmembership">
          <Link to="" onClick={(e) => handleEmailSurvey()}>
            +
          </Link>
          <h2>{t("Sorry to see you go")}</h2>
          <div className="cancelmembershipp">
            <p>
              <b>
                {t(
                  "order-service-client"
                )}
              </b>
            </p>
            <div className="onepxline"></div>
            <p className="second"></p>
            <br />
            <ul>
              <li>
                <input
                  type={"radio"}
                  id="survay"
                  name="survay"
                  onClick={(e) =>
                    setSurveyData({
                      ...surveyData,
                      resion: "It’s too expensive",
                    })
                  }
                />
                <span> {t("It’s too expensive")}</span>
              </li>
              <li>
                <input
                  type={"radio"}
                  id="survay"
                  name="survay"
                  onClick={(e) =>
                    setSurveyData({
                      ...surveyData,
                      resion: "I didn't find a candidate",
                    })
                  }
                />
                <span> {t("I didn't find a candidate")}</span>
              </li>
              <li>
                <input
                  type={"radio"}
                  id="survay"
                  name="survay"
                  onClick={(e) =>
                    setSurveyData({
                      ...surveyData,
                      resion: "I found a candidate somewhere else",
                    })
                  }
                />
                <span> {t("I found a candidate somewhere else")}</span>
              </li>
              <li>
                <input
                  type={"radio"}
                  id="survay"
                  name="survay"
                  onClick={(e) =>
                    setSurveyData({ ...surveyData, resion: "other" })
                  }
                />
                <span> {t("text-other")}</span>
              </li>
            </ul>
            <div className="comment">
              <textarea
                className={
                  surveyData.resion == "other" && surveyData.other == ""
                    ? "bordererror"
                    : ""
                }
                maxLength="70"
                placeholder={t("text-type")}
                onChange={(e) =>
                  setSurveyData({ ...surveyData, other: e.target.value })
                }
              ></textarea>
              <span>
                {t("number-characters")} {70 - surveyData.other.length}
              </span>
            </div>
            <div className="button text-center">
              <div className="pull-right">
                <button className="btn" onClick={(e) => handleEmailSurvey()}>
                  {t("text-cancel")}
                </button>
              </div>
              <div className="pull-right">
                <button
                  className="btn confirm"
                  onClick={(e) => handleSaveSurvey()}
                >
                  {t("text-submit")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
