import React from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import {
  ENG_LANGUAGE,
  MONTH_ENG_LANGUAGE,
  MONTH_SRB_LANGUAGE,
} from "../../../utils/variables";

export const InterviewRow = ({
  data,
  slugdata,
  handleDeleteSelect,
  setView,
}) => {
  const { t, i18n } = useTranslation();
  const language = i18n.language;

  const month =
    language === ENG_LANGUAGE ? MONTH_ENG_LANGUAGE : MONTH_SRB_LANGUAGE;

  const navigate = useNavigate();
  const jobStatusClass =
    data.jobStatus == 0
      ? data.parent_readstatus == 0
        ? t("text-unread")
        : ""
      : t("text-close");
  const handleRowClick = () => {
    navigate("/search-parents/" + slugdata.id + "/" + data.id);
    setView(data.id);
  };

  return (
    <tr className={jobStatusClass}>
      <td className="view-message dont-show">
        <input
          type="checkbox"
          className="mail-radio"
          name="chk"
          onChange={() => handleDeleteSelect(data.id)}
        />
        <span className="circle"></span>
        <span onClick={handleRowClick}>
          {data.jobStatus == 0 && data.parent_readstatus == 0 ? (
            <span className="new-tag">{t("text-new-upper")}</span>
          ) : (
            data.jobStatus != 0 && (
              <span className="new-tag">{t("text-closed")}</span>
            )
          )}
          <b>
            {data.status == 1 ? t("text-congratulations") : t("we-sorry")}
            <span
              className={data.status == 1 ? "username-text" : ""}
              style={data.status == 1 ? {} : { fontWeight: "500" }}
              onClick={handleRowClick}
            >
              {data.ProviderName}
            </span>
            {data.status == 1 ? t("accepted-with") : t("declined-with")}
            {t("person-interview-invite")}
          </b>
          <p>- {data.title != null ? data.title.substr(0, 50) : ""}</p>
        </span>
      </td>
      <td className="view-message text-right">
        {`${month[new Date(data.invite_responsedate).getMonth()]} ${
          new Date(data.invite_responsedate).getDate() < 10 ? "0" : ""
        }${new Date(data.invite_responsedate).getDate()}`}
      </td>
    </tr>
  );
};
