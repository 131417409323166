export const MessageIcon = ({ width, height, color}) => {
  const generalWidth = width ? width : "190";
  const generalHeight = height ? height : "142";
  const generalColor = color ? color : "#F7F7F7";

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={generalWidth}
      height={generalHeight}
      viewBox="0 0 190 142"
      fill="none"
    >
      <path
        d="M185.746 46.8896C187.189 45.7432 189.333 46.8156 189.333 48.6276V124.25C189.333 134.049 181.383 142 171.583 142H17.75C7.95052 142 0 134.049 0 124.25V48.6646C0 46.8156 2.10781 45.7802 3.58698 46.9266C11.8703 53.3609 22.8531 61.5333 60.5719 88.9349C68.3745 94.6297 81.5391 106.611 94.6667 106.537C107.868 106.648 121.292 94.4078 128.798 88.9349C166.517 61.5333 177.463 53.324 185.746 46.8896ZM94.6667 94.6667C103.246 94.8146 115.597 83.8688 121.809 79.3573C170.881 43.7464 174.616 40.6401 185.931 31.7651C188.076 30.101 189.333 27.5125 189.333 24.776V17.75C189.333 7.95052 181.383 0 171.583 0H17.75C7.95052 0 0 7.95052 0 17.75V24.776C0 27.5125 1.25729 30.0641 3.40208 31.7651C14.7177 40.6031 18.4526 43.7464 67.524 79.3573C73.7365 83.8688 86.0875 94.8146 94.6667 94.6667Z"
        fill={generalColor}
      />
    </svg>
  );
};
