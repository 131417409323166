import React from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";

import {
  ENG_LANGUAGE,
  MONTH_ENG_LANGUAGE,
  MONTH_SRB_LANGUAGE,
} from "../../../utils/variables";
import { useInterviewStore } from "../../../store/useInterviewStore";

export const ProfileVisitorMessage = ({
  data,
  handleDeleteElement,
  slugData,
}) => {
  const { t, i18n } = useTranslation();
  const language = i18n.language;
  const navigate = useNavigate();

  const month =
    language === ENG_LANGUAGE ? MONTH_ENG_LANGUAGE : MONTH_SRB_LANGUAGE;

  const { setView } = useInterviewStore();

  return (
    <tr class={data.read_status == 0 ? "unread" : ""}>
      <td class="view-message  dont-show recentshow">
        <input
          type="checkbox"
          class="mail-radio"
          name="chk"
          onChange={(e) => handleDeleteElement(data.id)}
        />
        <span className="circle"></span>
        <span
          onClick={(e) => {
            navigate("/search-providers/" + slugData.id + "/" + data.id);
            setView(data.id);
          }}
        >
          <Link to={""}>
            {data.read_status == 0 && (
              <span class="new-tag"> {t("text-new-up")}</span>
            )}
            <span
              class="username-text"
              onClick={(e) => {
                navigate("/search-providers/" + slugData.id + "/" + data.id);
                setView(data.id);
              }}
            >
              {data.ParentsName}
            </span>
            <span className="statuspg">
              {data.read_status == 0
                ? t("- Looking for awesome super friendly ...")
                : t("- We need warm and reliable person  ...")}
            </span>
          </Link>
        </span>
      </td>
      <td class="view-message  text-right">
        {month[new Date(data.created_at).getMonth()] +
          " " +
          (parseInt(new Date(data.created_at).getDate()) < 10 ? "0" : "") +
          new Date(data.created_at).getDate()}
      </td>
    </tr>
  );
};
