export const StarRoundIcon = () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0 7C0 3.136 3.129 0 6.993 0C10.864 0 14 3.136 14 7C14 10.864 10.864 14 6.993 14C3.129 14 0 10.864 0 7ZM7.00022 9.41321L9.96122 11.1982L9.17722 7.82421L11.7882 5.56321L8.34422 5.26921L7.00022 2.09821L5.65622 5.27621L2.21222 5.57021L4.82322 7.83121L4.03922 11.1982L7.00022 9.41321Z"
        fill="#636363"
      />
    </svg>
  );
};
