import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { useInterviewStore } from "../../../store/useInterviewStore";

import {
  ENG_LANGUAGE,
  MONTH_ENG_LANGUAGE,
  MONTH_SRB_LANGUAGE,
} from "../../../utils/variables";

export const MessageRow = ({ data, handleDeleteSelect, slugData }) => {
  const { i18n } = useTranslation();
  const language = i18n.language;
  const navigate = useNavigate();

  const { setView } = useInterviewStore();

  const month =
    language === ENG_LANGUAGE ? MONTH_ENG_LANGUAGE : MONTH_SRB_LANGUAGE;

  return (
    <tr className={data.read_status === 1 ? "unread" : ""}>
      <td className="view-message dont-show">
        <input
          type="checkbox"
          className="mail-radio"
          name="chk"
          onChange={() => handleDeleteSelect(data.id)}
        />
        <span className="circle"></span>
        <b
          onClick={() => {
            navigate("/search-providers/" + slugData.id + "/" + data.id);
            setView(data.id);
          }}
        >
          {data.title != null ? data.title : ""}
        </b>
        {data.description != null ? data.description : ""}
      </td>
      <td className="view-message text-right">
        {month[new Date(data.created_at).getMonth()] +
          " " +
          new Date(data.created_at).getDate()}
      </td>
    </tr>
  );
};
