import { useAdditionalDetailsStore } from "../../store/useAdditionalDetailsStore";
import { useContactJobStore } from "../../store/useContactJobStore";
import { useControlsStore } from "../../store/useControlsStore";
import { useDetailParentsStore } from "../../store/useDetailParentsStore";
import { useProfileDetailsStore } from "../../store/useProfileDetailsStore";
import { useProfileStore } from "../../store/useProfileStore";
import { useRatesStore } from "../../store/useRatesStore";
import { api } from "../../urls";



export const updateProfile = (a) => {
    const { detailParents } = useDetailParentsStore.getState();
    const { contactCode, job } =
      useContactJobStore.getState();

    const {calendarType} =
        useControlsStore.getState();
  
    const {
      detail,
      setIsButton,
    } = useProfileStore.getState();
  
    const {
      nanyRate,
      generalRate,
      secondaryRate,
      tutorRate,
    } = useRatesStore.getState();
  
    const {
      children,
      step,
      setShowModal,
    } = useControlsStore.getState();
  
    const {
      qualifications,
      oralSpeak,
      habit,
    } = useProfileDetailsStore.getState();
  
    const {
      selectCat,
      childrenAge,
    } = useAdditionalDetailsStore.getState();
    setIsButton(true);
    const myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );

    const formdata = new FormData();
    formdata.append("user_id", localStorage.getItem("id"));
    formdata.append("about", detailParents.about);
    formdata.append('calanderlastupdate', calendarType);
    if (detailParents.vide && detailParents.video.name) {
        formdata.append("video", detailParents.video);
    }

    if (detailParents.file_path.name) {
        formdata.append("file_path", detailParents.file_path);
    }

    formdata.append("childneedcareno", children);
    formdata.append("childage", JSON.stringify(selectCat));
    formdata.append("childsocialneed", detailParents.childsocialneed);
    formdata.append("yourkidallapplicable", JSON.stringify(childrenAge));
    formdata.append("childneediep", detailParents.childneediep);
    formdata.append(
      "childmedicalcondition",
      "No"
    );
    formdata.append(
      "childmedicaldescription",
      ""
    );
    formdata.append("childanyallergies", "No");
    formdata.append(
      "childallergiesdescription",
      "No"
    );
    formdata.append("liveinnany", detailParents.liveinnany);
    formdata.append("nanyperhrrate", nanyRate.min + "-" + nanyRate.max);
    formdata.append("tutorliketoteach", JSON.stringify(qualifications));
    formdata.append(
      "tutorintrestedonlinecls",
      detailParents.tutorintrestedonlinecls
    );
    formdata.append("tutorperhrrate", tutorRate.min + "-" + tutorRate.max);
    formdata.append("tutorexp", detailParents.tutorexp);
    formdata.append("tutorstartdate", detailParents.tutorstartdate);
    formdata.append("tutorintrestedin", detailParents.tutorintrestedin);
    formdata.append(
      "preferredageofprovider",
      detailParents.preferredageofprovider
    );
    formdata.append("fulltime", JSON.stringify(detailParents.fulltime));
    formdata.append("beforeschool", JSON.stringify(detailParents.beforeschool));
    formdata.append("afterschool", JSON.stringify(detailParents.afterschool));
    formdata.append("weekends", JSON.stringify(detailParents.weekends));
    formdata.append("overnight", JSON.stringify(detailParents.overnight));
    formdata.append("candidatespeaks", JSON.stringify(oralSpeak));
    formdata.append("occasionaltraveling", habit.family);
    formdata.append("workingabroad", detailParents.workingabroad);
    formdata.append("cookingforkids", habit.kids);
    formdata.append(
      "countrycode",
      contactCode.code != "" ? contactCode.code : detailParents.countrycode
    );
    formdata.append("lighthousework", habit.housework);
    formdata.append("childtransportation", habit.licence);
    formdata.append("preferredcountry", detailParents.preferredcountry);
    formdata.append("preferredcity", detailParents.preferredcity);
    formdata.append("facebookverify", detailParents.facebookverify);
    formdata.append("twitterverify", detailParents.twitterverify);
    formdata.append("linkdinverify", detailParents.linkdinverify);
    formdata.append("instaverify", detailParents.instaverify);
    if (detailParents.jobs != "") {
        formdata.append("jobs[0][title]", detailParents.jobs);
        formdata.append("jobs[0][description]", detailParents.jobs_description);
        formdata.append(
            "jobs[0][plateformonsocialmedia]",
            job.plateformonsocialmedia
        );
        formdata.append(
            "jobs[0][job_type]",
            Object.values(detail.service_type)[0]
        );
        formdata.append("jobs[0][job_id]", job.id);
    }

    if (detailParents.jobs2 != "") {
        formdata.append("jobs[1][title]", detailParents.jobs2);
        formdata.append(
            "jobs[1][job_type]",
            Object.values(detail.service_type)[1]
        );
        formdata.append(
            "jobs[1][plateformonsocialmedia]",
            job.plateformonsocialmedia2
        );
    }

    if (detailParents.jobs_description2 != "") {
        formdata.append("jobs[1][description]", detailParents.jobs_description2)
    }

    if (job.id2 != "") {
        formdata.append("jobs[1][job_id]", job.id2);
    }

    formdata.append("step", step + 1);
    if (job && job.plateformonsocialmedia) {
        formdata.append("plateformsocialmedia", job.plateformonsocialmedia);
    } else if (job && job.plateformonsocialmedia2) {
        formdata.append("plateformsocialmedia", job.plateformonsocialmedia2);
    }
    formdata.append("seterhrrate", generalRate.min + "-" + generalRate.max);
    formdata.append("seperhrrate", secondaryRate.min + "-" + secondaryRate.max);
    if (a == true) {
      formdata.append("profilecompletestatus", "1");
    }

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch(api + "/api/v1/updateparents", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.success == true && step == 7) {
          setShowModal(true);
        }
        setIsButton(false);
      })
      .catch((error) => {
        setIsButton(false);
      });
  };