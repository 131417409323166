import React, { useEffect } from "react";

import { InterviewInvite } from "../InterviewInvite";
import { InterviewInviteJob } from "../InterviewInviteJob";
import { ProfileAll } from "../ProfileAll";
import { JobApplication } from "../../Job/JobApplication";
import { JobApplicationParents } from "../../Job/JobApplicationParents";
import { JobPostParents } from "../../Job/JobPostParents";
import { LoyaltyNotification } from "../../Loyalty/LoyaltyNotification";
import { LoyaltyProgram } from "../../Loyalty/LoyaltyProgram";
import { MessageList } from "../../Message/MessageList";
import { ProfileVisitor } from "../ProfileVisitor";
import { RecommendationsJobRequest } from "../../Recommendations/RecommendationsJobRequest";
import { RecommendationsProfile } from "../../Recommendations/RecommendationsProfile";
import { ReviewProvider } from "../ReviewProvider";
import { Reviews } from "../Reviews";
import { ShareDocumentJob } from "../../ShareDocument/ShareDocumentJob";
import { ShareDocumentContent } from "../../ShareDocument/ShareDocumentContent/ShareDocumentContent";
import { TrashList } from "../TrashList";
import { WhoVisitedContent } from "../WhoVisitedContent";
import { WhoVisitedProfile } from "../WhoVisitedProfile";

export function ParentsNotificationTabs(props) {
  useEffect(() => {
    localStorage.setItem("back", props.subtab);
    localStorage.setItem("side", props.profilesection);
  }, [props.subtab]);

  return (
    <>
      {props.subtab == "interview-invite" && (
        <div class="interview">
          <InterviewInvite />
        </div>
      )}

      {props.subtab == "job-application" && (
        <div class="interview">
          <JobApplication />
        </div>
      )}

      {props.subtab == "new-recommendation" && (
        <div class="interview">
          <RecommendationsProfile />
        </div>
      )}

      {props.subtab == "shared-document" && (
        <div class="interview">
          <ShareDocumentContent />
        </div>
      )}

      {props.subtab == "new-reviews" && (
        <div class="interview">
          <ReviewProvider />
        </div>
      )}

      {props.subtab == "Reviews" && (
        <div class="interview">
          <Reviews />
        </div>
      )}

      {props.subtab == "recent-profile-visitor" && (
        <div class="interview">
          <ProfileVisitor />
        </div>
      )}

      {props.subtab == "all-profile" && (
        <div class="interview">
          <ProfileAll />
        </div>
      )}

      {props.subtab == "who-i-visited" && (
        <div class="interview">
          <WhoVisitedContent />
        </div>
      )}

      {props.subtab == "who-visited-me" && (
        <div class="interview">
          <WhoVisitedProfile profile={props.profile} />
        </div>
      )}

      {props.subtab == "job-applications" && (
        <div class="interview">
          <JobApplicationParents />
        </div>
      )}

      {props.subtab == "interview-invites" && (
        <div class="interview">
          <InterviewInviteJob />
        </div>
      )}

      {props.subtab == "recommendation-request" && (
        <div class="interview">
          <RecommendationsJobRequest />
        </div>
      )}

      {props.subtab == "shared-documents" && (
        <div class="interview">
          <ShareDocumentJob />
        </div>
      )}

      {props.subtab == "job-post" && (
        <div class="interview">
          <JobPostParents />
        </div>
      )}

      {props.subtab == "message-inbox" && (
        <div class="interview mbin">
          <MessageList />
        </div>
      )}

      {props.subtab == "Trash" && (
        <div class="interview">
          <TrashList />
        </div>
      )}

      {props.subtab == "Loyalty-notification" && (
        <div class="interview">
          <LoyaltyNotification />
        </div>
      )}

      {props.subtab == "Loyalty" && (
        <div class="interview" style={{ width: "calc(100% - 22.5%)" }}>
          <LoyaltyProgram />
        </div>
      )}
    </>
  );
}
