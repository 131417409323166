import React from "react";
import { Link } from "react-router-dom";

import { FavoriteProfile } from "../../common/FavoriteProfile";
import { SocialFacebook } from "../../common/SocialFacebook";
import { PhoneIcon } from "../../../assets/image/PhoneIcon";
import { ServiceIcon } from "../../../assets/image/ServiceIcon";
import { StarIcon } from "../../../assets/image/StarIcon";
import { WhiteStarIcon } from "../../../assets/image/WhiteStarIcon";
import { SearchIcon } from "../../../assets/image/SearchIcon";
import { MarkRoundIcon } from "../../../assets/image/MarkRoundIcon";
import { useTranslation } from "react-i18next";
import { ConfirmIcon } from "../../../assets/image/ConfirmIcon";

export const JobCard = ({
  data,
  jobData,
  hired,
  handleAcceptData,
  setView,
  handleElementById,
  profile,
  index,
  providerData,
}) => {
  const { t } = useTranslation();

  const renderServiceIcon = (serviceType) => {
    switch (serviceType) {
      case "Nanny":
        return (
          <img src={`${window.location.origin}/images/nany_post.svg`} alt="" />
        );
      case "tab2":
        return (
          <img
            src={`${window.location.origin}/images/teacher_post.svg`}
            alt=""
          />
        );
      case "tab3":
        return (
          <img
            src={`${window.location.origin}/images/education_post.svg`}
            alt=""
          />
        );
      case "tab4":
        return (
          <img src={`${window.location.origin}/images/tutor_post.svg`} alt="" />
        );
      default:
        return null;
    }
  };

  return (
    <div className="looking_for_candidate_boxs">
      <div className="looking_for_candidate_box">
        <div className="first_sec">
          <div className="image_sec">
            <img
              src={
                data.file_path != null
                  ? `https://admin.stage.mysenscare.com/assets/images/users/${data.file_path}`
                  : "img/nany_img.png"
              }
              alt=""
            />
            <div className="heart_sec">
              <FavoriteProfile id={data.user_id} username={data.first_name} />
            </div>
          </div>
          <div className="nany_social">
            {data.phoneVerifiedStatus == 1 ? (
              <img
                src={`${window.location.origin}/images/nany_phone.svg`}
                alt=""
              />
            ) : (
              <PhoneIcon />
            )}
            <img src={`${window.location.origin}/images/nany_msg.svg`} alt="" />
            {data.facebookverify != null || data.linkdinverify != null ? (
              <img
                src={`${window.location.origin}/images/nany_cont.svg`}
                alt=""
              />
            ) : (
              <ServiceIcon />
            )}
            {jobData.docsStatus == "Yes" ? (
              <img src={`${window.location.origin}/images/ok.svg`} alt="" />
            ) : (
              <ConfirmIcon />
            )}
          </div>
        </div>
        <div className="second_sec">
          <div className="heading">
            <h3>
              {jobData.jobstatus?.JobStatus != 1 ? (
                ""
              ) : (
                <span className="new-tag3"> {t("text-closed")}</span>
              )}
              {data.profile_headline != null ? data.profile_headline : ""}
            </h3>
            {jobData.jobstatus?.JobStatus != 1 ? (
              <button
                style={
                  hired != "" || data.applyjob_hiredstatus == 1
                    ? { opacity: "0.5", width: "75px", fontSize: "11px" }
                    : {}
                }
                onClick={(e) => handleAcceptData(data.Appjob_ID)}
                disabled={
                  hired != "" || data.applyjob_hiredstatus == 1 ? true : false
                }
              >
                {hired != "" || data.applyjob_hiredstatus == 1
                  ? t("text-hired")
                  : t("text-hire")}
              </button>
            ) : (
              ""
            )}
            {data.plateformsocialmedia == "Yes" ? (
              <SocialFacebook
                link={`${window.location.origin}/profile-provider/${data.user_id}`}
              />
            ) : (
              ""
            )}
          </div>
          <div className="post_general">
            <h6>
              <Link to={`/profile-provider/${providerData[0]?.user_id}`}>
                {data.first_name != null
                  ? `${data.first_name} ${data.last_name}`
                  : ""}
              </Link>
              (
              {data.dob != undefined
                ? new Date().getFullYear() - parseInt(data.dob.split("-")[0])
                : ""}
              )
            </h6>
            <p>
              {jobData.reviewAvg >= 0 ? (
                <>
                  {[...Array(jobData.reviewAvg)].map((star, index) => (
                    <StarIcon key={index} />
                  ))}
                  {[...Array(5 - jobData.reviewAvg)].map((star, index) => (
                    <WhiteStarIcon key={index} />
                  ))}
                </>
              ) : (
                ""
              )}
              <span> ({jobData.reviewcount})</span>
            </p>
          </div>
          <div className="post_detail">
            <div className="post">
              {data.service_type != null
                ? renderServiceIcon(
                    Object.values(JSON.parse(data.service_type))[0]
                  )
                : ""}
              <h5>
                {data.service_type != null
                  ? t(Object.values(JSON.parse(data.service_type))[0])
                  : ""}
              </h5>
            </div>
            <div className="vi"></div>
            <div className="post_pay">
              <img src={`${window.location.origin}/img/post_pay.png`} alt="" />
              {profile && profile.country == "Serbia" ? (
                <h5>
                  {data.nanyperhrrate != null
                    ? `${data.nanyperhrrate.split("-")[0] * 100} - ${
                        data.nanyperhrrate.split("-")[1] * 100
                      }`
                    : data.tutorperhrrate
                    ? `${data.tutorperhrrate.split("-")[0] * 100} - ${
                        data.tutorperhrrate.split("-")[1] * 100
                      }`
                    : ""}
                  {t("time-hour")}
                </h5>
              ) : (
                <h5>
                  $
                  {data.nanyperhrrate != null
                    ? data.nanyperhrrate
                    : data.tutorperhrrate
                    ? data.tutorperhrrate
                    : ""}
                  {t("time-hour")}
                </h5>
              )}
            </div>
            <div className="vi"></div>
            {data.service_type != null &&
            Object.values(JSON.parse(data.service_type))[1] ? (
              <>
                <div className="post">
                  {data.service_type != null
                    ? renderServiceIcon(
                        Object.values(JSON.parse(data.service_type))[1]
                      )
                    : ""}
                  <h5>
                    {data.service_type != null
                      ? t(Object.values(JSON.parse(data.service_type))[1])
                      : ""}
                  </h5>
                </div>
                <div className="vi"></div>
                <div className="post_pay">
                  <img
                    src={`${window.location.origin}/img/post_pay.png`}
                    alt=""
                  />
                  {profile && profile.country == "Serbia" ? (
                    <h5>
                      {data.tutorperhrrate
                        ? `${data.tutorperhrrate.split("-")[0] * 100} - ${
                            data.tutorperhrrate.split("-")[1] * 100
                          }`
                        : ""}
                      {t("time-hour")}
                    </h5>
                  ) : (
                    <h5>
                      ${data.tutorperhrrate ? data.tutorperhrrate : ""}
                      {t("time-hour")}
                    </h5>
                  )}
                </div>
                <div className="vi"></div>
              </>
            ) : (
              ""
            )}
            <div className="post_fet">
              <img
                src={`${window.location.origin}/images/post_fet.svg`}
                alt=""
              />
              <h5>
                {data.tutorintrestedin != null
                  ? t(data.tutorintrestedin)
                  : t(data.nanyintrestedin)}
              </h5>
            </div>
            <div className="vi"></div>
            <div className="post_cal">
              <img
                src={`${window.location.origin}/images/post_cal.svg`}
                alt=""
              />
              <h5>
                {data.tutorstartdate != null
                  ? data.tutorstartdate
                  : data.nanystartdate}
              </h5>
            </div>
            <div className="vi"></div>
            <div className="post_loc">
              <img
                src={`${window.location.origin}/images/post_loc.svg`}
                alt=""
              />
              <h5>
                {data.country != null ? data.country : ""} ,
                {data.city != null ? data.city : ""}
              </h5>
            </div>
          </div>
          <p id={`half${index}`}>
            {data.about != null ? data.about.substr(0, 100) : ""}
            {data.about != null && data.about.length > 100 ? (
              <span
                onClick={(e) =>
                  handleElementById(
                    `half${index}`,
                    `full${index}`,
                    `plink${index}`
                  )
                }
              >
                {t("text-more")}
              </span>
            ) : (
              ""
            )}
          </p>
          <p id={`full${index}`} className="hide">
            {data.about}
            <span
              onClick={(e) =>
                handleElementById(
                  `full${index}`,
                  `half${index}`,
                  `plink${index}`
                )
              }
            >
              {t("text-less")}
            </span>
          </p>
        </div>
        <div className="find_search">
          <Link to="/search-providers">
            <SearchIcon />
            {t("search-similar-candidate")}
          </Link>
        </div>
        {jobData.jobstatus?.JobStatus != 0 ? (
          <div className="view_profile_btn">
            <Link to="" onClick={(e) => setView(data.user_id)}>
              {t("send-message")}
            </Link>
            <Link to={`/profile-provider/${data.user_id}`}>
              {t("visit-profile")}
            </Link>
          </div>
        ) : (
          ""
        )}
        {(data.childmedicalcondition != null &&
          data.childmedicalcondition == "Yes") ||
        (data.childanyallergies != null && data.childanyallergies == "Yes") ? (
          <>
            <p>
              <MarkRoundIcon />
              <strong>{t("note-with")}</strong>
              {t(
                "record-indicates"
              )}
              <br />
            </p>
          </>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};
