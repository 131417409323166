import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { Footer } from "../../components/common/Footer";
import { Header } from "../../components/common/Header";

import styles from "./ErrorPage.module.css"

export function ErrorPage() {
    const { t } = useTranslation();

    return (
      <>
        <Header />
        <div className={styles.container_fluid}>
          <div className={styles.container}>
            <div className={`${styles.thank_page} ${styles.error}`}>
              <h2>{t("error-404")}</h2>
              <div className={styles.thanks}>
                <p>{t("not-found")}</p>
                <Link to={"/"}>
                  <img
                    src={window.location.origin + "/images/left_arrow_fill.svg"}
                  />
                  <span>{t("return-page")}</span>
                </Link>
              </div>
              <img
                src={window.location.origin + "/images/Error-404-animation.gif"}
              />
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
