import React from "react";
import { useTranslation } from "react-i18next";
import Modal from "react-bootstrap/Modal";

import { useProviderFieldStore } from "../../../../store/useProviderFieldStore";
import { RATE_NUMBERS, RATE_NUMBERS_SECOND, STEP_EXP } from "../../../../utils/variables";
import { ArrowUpIcon } from "../../../../assets/image/ArrowUpIcon";

export const TeacherModal = ({
  showTeacherModal,
  handleCloseTeacherModal,
  rate,
  setRate,
  detail,
  setMethods,
  methods,
  diseasesList,
  handleChangeDiseasesList,
  range,
  setRange,
  handleElementById,
  countp,
  validateAfterLoginField,
  handleSubmitProvider,
}) => {
  const { t } = useTranslation();

  const { errorField, setErrorField, detailProvider, setDetailProvider } =
    useProviderFieldStore();

  return (
    <Modal
      show={showTeacherModal}
      onHide={handleCloseTeacherModal}
      className="question_new"
    >
      <Modal.Body>
        <div className="younger">
          <img src="/images/sign_logo.svg" />
          <h2>{t("Complete the Profile")}</h2>
          <p>{t("You can edit your info in settings section later")}</p>
          <div className="Profile_complete">
            <div className="detail work-experience your_kids  setp3">
              <div className="special_education">
                <h2 className="border">
                  <img src="/images/special_education.svg" />
                  {t("education-teacher")}
                </h2>
                <div className="col-6">
                  <div className="iconsec">
                    <div className="form_group full">
                      <label>
                        {t("Years of work experience")}
                        <span
                          className={
                            errorField.setyearexp != "" ? "starred" : ""
                          }
                        >
                          *
                        </span>
                      </label>
                      <div className="rang" style={{ minWidth: "375px" }}>
                        <div
                          class="slider"
                          onClick={(w) =>
                            setErrorField({ ...errorField, setyearexp: "" })
                          }
                        >
                          <input
                            type="range"
                            min="0"
                            max="60"
                            onChange={(e) => setRange(e.target.value)}
                            style={{
                              "background-size": (range * 100) / 60 + "% 100% ",
                            }}
                            value={range}
                          />
                          <ul>
                            <li>0</li>
                            <li>15</li>
                            <li>30</li>
                            <li>45</li>
                            <li>60</li>
                          </ul>
                        </div>
                        <span id="rangeValue">{range}</span>
                      </div>
                    </div>
                  </div>
                  <div className="rightsec">
                    <div className="form_group full">
                      <label>
                        {t("Rate per hour")}
                        {detail.country == "Serbia" ? "(RSD)" : "(USD)"}
                        <span
                          className={
                            errorField.nanyperhrrate != "" ? "starred" : ""
                          }
                        >
                          *
                        </span>
                      </label>
                      {detail && detail.country == "Serbia" ? (
                        <div class="wrapper rang">
                          <div class="container_slide">
                            <div class="slider-track">
                              <ul>
                              {RATE_NUMBERS.map((value, index) => {
                                    const style =
                                      rate.min >= value ||
                                      (rate.max < value && rate.max > 0)
                                        ? { backgroundColor: "#E5E5E5" }
                                        : {};
                                    return <li key={index} style={style}></li>;
                                  })}
                              </ul>
                            </div>
                            <div class="bggray_slider">
                              <ul>
                              {RATE_NUMBERS_SECOND.map((value, index) => {
                                    const style =
                                      rate.min >= value ||
                                      (rate.max < value && rate.max > 0)
                                        ? { backgroundColor: "#E5E5E5" }
                                        : {};
                                    return <li key={index} style={style}></li>;
                                  })}
                              </ul>
                            </div>
                            <input
                              type="range"
                              min="0"
                              max="5"
                              id="slider-1"
                              value={rate.min / 10}
                              onChange={(e) => {
                                if (
                                  (rate.max > 0 ? rate.max : 60) >
                                  (e.target.value == 5
                                    ? 60
                                    : e.target.value * 10)
                                ) {
                                  setRate({
                                    ...rate,
                                    min:
                                      e.target.value == 5
                                        ? 60
                                        : e.target.value * 10,
                                  });
                                }
                              }}
                            />
                            <input
                              type="range"
                              min="0"
                              max="5"
                              id="slider-2"
                              value={rate.max == 0 ? 50 / 10 : rate.max / 10}
                              onChange={(e) => {
                                if (
                                  rate.min <
                                  (e.target.value == 5
                                    ? 60
                                    : e.target.value * 10)
                                ) {
                                  setRate({
                                    ...rate,
                                    max:
                                      e.target.value == 5
                                        ? 60
                                        : e.target.value * 10,
                                  });
                                }
                              }}
                            />
                          </div>
                          <div class="slider two">
                            <ul>
                              <li>0</li>
                              <li>1000</li>
                              <li>2000</li>
                              <li>3000</li>
                              <li>4000</li>
                              <li>6000+</li>
                            </ul>
                          </div>
                        </div>
                      ) : (
                        <div class="wrapper rang">
                          <div class="container_slide">
                            <div class="slider-track">
                              <ul>
                              {RATE_NUMBERS.map((value, index) => {
                                    const style =
                                      rate.min >= value ||
                                      (rate.max < value && rate.max > 0)
                                        ? { backgroundColor: "#E5E5E5" }
                                        : {};
                                    return <li key={index} style={style}></li>;
                                  })}
                              </ul>
                            </div>
                            <div class="bggray_slider">
                              <ul>
                              {RATE_NUMBERS_SECOND.map((value, index) => {
                                    const style =
                                      rate.min >= value ||
                                      (rate.max < value && rate.max > 0)
                                        ? { backgroundColor: "#E5E5E5" }
                                        : {};
                                    return <li key={index} style={style}></li>;
                                  })}
                              </ul>
                            </div>
                            <input
                              type="range"
                              min="0"
                              max="5"
                              id="slider-1"
                              value={rate.min / 10}
                              onChange={(e) => {
                                if (
                                  (rate.max > 0 ? rate.max : 60) >
                                  (e.target.value == 5
                                    ? 60
                                    : e.target.value * 10)
                                ) {
                                  setRate({
                                    ...rate,
                                    min:
                                      e.target.value == 5
                                        ? 60
                                        : e.target.value * 10,
                                  });
                                }
                              }}
                            />
                            <input
                              type="range"
                              min="0"
                              max="5"
                              id="slider-2"
                              value={rate.max == 0 ? 50 / 10 : rate.max / 10}
                              onChange={(e) => {
                                if (
                                  rate.min <
                                  (e.target.value == 5
                                    ? 60
                                    : e.target.value * 10)
                                ) {
                                  setRate({
                                    ...rate,
                                    max:
                                      e.target.value == 5
                                        ? 60
                                        : e.target.value * 10,
                                  });
                                }
                              }}
                            />
                          </div>
                          <div class="slider two">
                            <ul>
                              <li>0</li>
                              <li>10</li>
                              <li>20</li>
                              <li>30</li>
                              <li>40</li>
                              <li>60+</li>
                            </ul>
                          </div>
                        </div>
                      )}
                      <span class="price">
                        {detail && detail.country == "Serbia"
                          ? rate.min * 100 + " - " + rate.max * 100
                          : rate.min + " - " + rate.max}
                      </span>
                    </div>
                  </div>
                  <div className="iconsec">
                    <div className="form_group full qualification methad">
                      <label>
                        {t("Experience with following methods")}
                        <span
                          className={
                            errorField.setexpmethods != "" ? "starred" : ""
                          }
                        >
                          *
                        </span>
                      </label>
                      <div className="checkbox">
                        <ul
                          onClick={(e) =>
                            setErrorField({
                              ...errorField,
                              setexpmethods: "",
                            })
                          }
                        >
                          <li>
                            <input
                              type="checkbox"
                              name=""
                              checked={
                                methods.setexpmethods &&
                                methods.setexpmethods == "ABA"
                                  ? true
                                  : false
                              }
                              onClick={(e) => {
                                if (e.target.checked) {
                                  setMethods({
                                    ...methods,
                                    setexpmethods: "ABA",
                                  });
                                } else {
                                  setMethods({
                                    ...methods,
                                    setexpmethods: "",
                                  });
                                }
                              }}
                            />
                            <span> {t("ABA")}</span>
                          </li>
                          <li>
                            <input
                              type="checkbox"
                              name=""
                              checked={
                                methods.setexpmethods2 &&
                                methods.setexpmethods2 == "PECS"
                                  ? true
                                  : false
                              }
                              onClick={(e) => {
                                if (e.target.checked) {
                                  setMethods({
                                    ...methods,
                                    setexpmethods2: "PECS",
                                  });
                                } else {
                                  setMethods({
                                    ...methods,
                                    setexpmethods2: "",
                                  });
                                }
                              }}
                            />
                            <span> {t("PECS")}</span>
                          </li>
                          <li>
                            <input
                              type="checkbox"
                              name=""
                              checked={
                                methods.setexpmethods3 &&
                                methods.setexpmethods3 == "Floortime"
                                  ? true
                                  : false
                              }
                              onClick={(e) => {
                                if (e.target.checked) {
                                  setMethods({
                                    ...methods,
                                    setexpmethods3: "Floortime",
                                  });
                                } else {
                                  setMethods({
                                    ...methods,
                                    setexpmethods3: "",
                                  });
                                }
                              }}
                            />
                            <span> {t("Floortime")}</span>
                          </li>
                          <li>
                            <input
                              type="checkbox"
                              name=""
                              checked={
                                methods.setexpmethods4 &&
                                methods.setexpmethods4 == "None"
                                  ? true
                                  : false
                              }
                              onClick={(e) => {
                                if (e.target.checked) {
                                  setMethods({
                                    ...methods,
                                    setexpmethods4: "None",
                                  });
                                } else {
                                  setMethods({
                                    ...methods,
                                    setexpmethods4: "",
                                  });
                                }
                              }}
                            />
                            <span> {t("None")}</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="rightsec">
                    <div className="form_group full">
                      <label>
                        {t("Do you have an experience in")}
                        <a>{t("text-iep")} </a>
                        <span className="smallpop">
                          {t("individual-education")}
                        </span>
                        {t("development?")}
                        <span
                          className={
                            errorField.setexpiep != "" ? "starred" : ""
                          }
                        >
                          *
                        </span>
                      </label>
                      <div className="checkbox">
                        <ul
                          onClick={(e) =>
                            setErrorField({ ...errorField, setexpiep: "" })
                          }
                        >
                          <li>
                            <input
                              type="radio"
                              name="quality6"
                              checked={
                                detailProvider.setexpiep == "Yes" ? true : false
                              }
                              onClick={(e) =>
                                setDetailProvider({
                                  ...detailProvider,
                                  setexpiep: "Yes",
                                })
                              }
                            />
                            <span> {t("confirm")}</span>
                          </li>
                          <li>
                            <input
                              type="radio"
                              name="quality6"
                              checked={
                                detailProvider.setexpiep == "No" ? true : false
                              }
                              onClick={(e) =>
                                setDetailProvider({
                                  ...detailProvider,
                                  setexpiep: "No",
                                })
                              }
                            />
                            <span> {t("objection")}</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="iconsec" style={{ clear: "both" }}>
                    <div className="job_performance">
                      <div className="form_group" style={{ width: "100%" }}>
                        <label>
                          {t("Area of expertise (select all applicable)")}
                          <span
                            className={
                              errorField.setallapplicable != "" ? "starred" : ""
                            }
                          >
                            *
                          </span>
                        </label>
                        <div className="customselect inp">
                          <input
                            className="keyword"
                            type="text"
                            placeholder={t("choose-list")}
                            value={diseasesList.map((e) => {
                              return e.name;
                            })}
                          />
                          <div
                            className="overflow"
                            id="over22"
                            onClick={(e) =>
                              handleElementById("cate88", "over22")
                            }
                            style={{
                              bottom: "100%",
                              top: "auto",
                            }}
                          ></div>

                          <div
                            className="option"
                            id="cate88"
                            onClick={(e) =>
                              setErrorField({
                                ...errorField,
                                setallapplicable: "",
                              })
                            }
                            style={{
                              bottom: "100%",
                              top: "auto",
                            }}
                          >
                            <p>
                              <input
                                type="checkbox"
                                checked={
                                  diseasesList.filter(
                                    (e) => e.name == t("text-adhd")
                                  )[0]
                                    ? true
                                    : false
                                }
                                onClick={(a) =>
                                  handleChangeDiseasesList(t("text-adhd"))
                                }
                              />
                              <h3>{t("text-adhd")} </h3>
                              <span></span>
                            </p>
                            <p>
                              <input
                                type="checkbox"
                                checked={
                                  diseasesList.filter(
                                    (e) => e.name == t("auditory-impairment")
                                  )[0]
                                    ? true
                                    : false
                                }
                                onClick={(a) =>
                                  handleChangeDiseasesList(
                                    t("auditory-impairment")
                                  )
                                }
                              />
                              <h3>{t("auditory-impairment")} </h3>
                              <span></span>
                            </p>
                            <p>
                              <input
                                type="checkbox"
                                checked={
                                  diseasesList.filter(
                                    (e) => e.name == t("autism")
                                  )[0]
                                    ? true
                                    : false
                                }
                                onClick={(a) =>
                                  handleChangeDiseasesList(t("autism"))
                                }
                              />
                              <h3>{t("autism")} </h3>
                              <span></span>
                            </p>
                            <p>
                              <input
                                type="checkbox"
                                checked={
                                  diseasesList.filter(
                                    (e) => e.name == t("cerebral-palsy")
                                  )[0]
                                    ? true
                                    : false
                                }
                                onClick={(a) =>
                                  handleChangeDiseasesList(t("cerebral-palsy"))
                                }
                              />
                              <h3>{t("cerebral-palsy")} </h3>
                              <span></span>
                            </p>
                            <p>
                              <input
                                type="checkbox"
                                checked={
                                  diseasesList.filter(
                                    (e) => e.name == t("down-syndrome")
                                  )[0]
                                    ? true
                                    : false
                                }
                                onClick={(a) =>
                                  handleChangeDiseasesList(t("down-syndrome"))
                                }
                              />
                              <h3>{t("down-syndrome")} </h3>
                              <span></span>
                            </p>
                            <p>
                              <input
                                type="checkbox"
                                checked={
                                  diseasesList.filter(
                                    (e) => e.name == t("dyslexia")
                                  )[0]
                                    ? true
                                    : false
                                }
                                onClick={(a) =>
                                  handleChangeDiseasesList(t("dyslexia"))
                                }
                              />
                              <h3>{t("dyslexia")} </h3>
                              <span></span>
                            </p>
                            <p>
                              <input
                                type="checkbox"
                                checked={
                                  diseasesList.filter(
                                    (e) =>
                                      e.name == t("intellectual-disability")
                                  )[0]
                                    ? true
                                    : false
                                }
                                onClick={(a) =>
                                  handleChangeDiseasesList(
                                    t("intellectual-disability")
                                  )
                                }
                              />
                              <h3>{t("intellectual-disability")} </h3>
                              <span></span>
                            </p>
                            <p>
                              <input
                                type="checkbox"
                                checked={
                                  diseasesList.filter(
                                    (e) => e.name == t("moderate-disability")
                                  )[0]
                                    ? true
                                    : false
                                }
                                onClick={(a) =>
                                  handleChangeDiseasesList(
                                    t("moderate-disability")
                                  )
                                }
                              />
                              <h3>{t("moderate-disability")}</h3>
                              <span></span>
                            </p>
                            <p>
                              <input
                                type="checkbox"
                                checked={
                                  diseasesList.filter(
                                    (e) => e.name == t("orthopedic-impairment")
                                  )[0]
                                    ? true
                                    : false
                                }
                                onClick={(a) =>
                                  handleChangeDiseasesList(
                                    t("orthopedic-impairment")
                                  )
                                }
                              />
                              <h3>{t("orthopedic-impairment")} </h3>
                              <span></span>
                            </p>
                            <p>
                              <input
                                type="checkbox"
                                checked={
                                  diseasesList.filter(
                                    (e) => e.name == t("learn-impairment")
                                  )[0]
                                    ? true
                                    : false
                                }
                                onClick={(a) =>
                                  handleChangeDiseasesList(
                                    t("learn-impairment")
                                  )
                                }
                              />
                              <h3>{t("learn-impairment")} </h3>
                              <span></span>
                            </p>
                            <p>
                              <input
                                type="checkbox"
                                checked={
                                  diseasesList.filter(
                                    (e) => e.name == t("learn-disabilities")
                                  )[0]
                                    ? true
                                    : false
                                }
                                onClick={(a) =>
                                  handleChangeDiseasesList(
                                    t("learn-disabilities")
                                  )
                                }
                              />
                              <h3>{t("learn-disabilities")} </h3>
                              <span></span>
                            </p>
                            <p>
                              <input
                                type="checkbox"
                                checked={
                                  diseasesList.filter(
                                    (e) => e.name == t("visual-impairment")
                                  )[0]
                                    ? true
                                    : false
                                }
                                onClick={(a) =>
                                  handleChangeDiseasesList(
                                    t("visual-impairment")
                                  )
                                }
                              />
                              <h3>{t("visual-impairment")} </h3>
                              <span></span>
                            </p>
                            <p>
                              <input
                                type="checkbox"
                                checked={
                                  diseasesList.filter(
                                    (e) => e.name == t("text-other")
                                  )[0]
                                    ? true
                                    : false
                                }
                                onClick={(a) =>
                                  handleChangeDiseasesList(t("text-other"))
                                }
                              />
                              <h3>{t("text-other")} </h3>
                              <span></span>
                            </p>
                          </div>

                          <span
                            onClick={(e) =>
                              handleElementById("cate88", "over22")
                            }
                          >
                            <ArrowUpIcon />
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <br />
                <span>{t("required-fields")}</span>
              </div>
            </div>
          </div>
          <div className="button">
            <button
              onClick={(e) => {
                handleCloseTeacherModal();
                window.scrollTo({ top: 0 });
              }}
            >
              {t("text-back")}
            </button>
            <button
              onClick={(e) => {
                if (
                  range > 0 &&
                  rate.max > 0 &&
                  (methods.setexpmethods != "" ||
                    methods.setexpmethods2 != "" ||
                    methods.setexpmethods3 != "" ||
                    methods.setexpmethods4 != "") &&
                  detailProvider.setexpiep != "" &&
                  diseasesList[0] &&
                  countp <= 2
                ) {
                  window.scrollTo({ top: 0 });
                  handleCloseTeacherModal();
                  handleSubmitProvider(true);
                } else {
                  STEP_EXP.map((e) => {
                    validateAfterLoginField(e.name);
                  });
                }
              }}
            >
              {t("text-finish")}
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
