import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Modal from "react-bootstrap/Modal";

import { LaptopIcon } from "../../../assets/image/LaptopIcon";
import { MONTH } from "../../../utils/variables";
import {
  fetchRecommendationResponse,
  fetchRecommendationView,
} from "../../../api/recommendationApi";
import { DocumentModal } from "./DocumentModal";

export function RecommendationsParentsProfile(props) {
  const { t } = useTranslation();

  const [recommendationData, setRecommendationData] = useState([]);
  const [isCheck, setIsCheck] = useState(true);
  const [isRecommendation, setIsRecommendation] = useState(false);
  const [message, setMessage] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [documentField, setDocumentField] = useState("");
  const [stepCondition, setStepCondition] = useState("");

  const handleRecommendationData = async () => {
    try {
      const result = await fetchRecommendationView(props.id);
      setRecommendationData(result.data.data);
      setMessage(result.data.currentUserName);
    } catch (error) {
      console.error("Error fetching recommendation data:", error);
    }
  };

  useEffect(() => {
    if (isCheck) {
      handleRecommendationData();
      setIsCheck(false);
    }
    console.log(recommendationData);
  }, [isCheck]);

  const handleSubmitRecommendation = async () => {
    try {
      await fetchRecommendationResponse(props.id, documentField);

      setShowModal(false);
      setDocumentField("");
      setStepCondition("1");
      setIsRecommendation(true);
      setTimeout(() => {
        setStepCondition("");
      }, 2000);
    } catch (error) {
      console.error("Error submitting recommendation response:", error);
    }
  };

  return (
    <>
      {stepCondition == "1" ? (
        <div className="popup_status" id="popup_status">
          {t("post-sent-recommendation")}
        </div>
      ) : (
        ""
      )}
      {recommendationData && recommendationData[0] ? (
        <>
          <h2 className="border"></h2>
          <div className="detail_invit">
            <p style={{ fontSize: "16px", fontWeight: "600" }}>
              <Link to={"/profile-provider/" + recommendationData[0]?.user_id}>
                {recommendationData &&
                  recommendationData[0] &&
                  recommendationData[0].ProviderName}
              </Link>
              {t("requested a recommendation from you")}
              <span className="date">
                {new Date(
                  recommendationData &&
                    recommendationData[0] &&
                    recommendationData[0].created_at
                ).toLocaleDateString("en-US", { weekday: "short" })}
                ,
                {MONTH[
                  new Date(
                    recommendationData &&
                      recommendationData[0] &&
                      recommendationData[0].created_at
                  ).getMonth()
                ] +
                  " " +
                  new Date(
                    recommendationData &&
                      recommendationData[0] &&
                      recommendationData[0].created_at
                  ).getDate()}
                ,
                {new Date(
                  recommendationData &&
                    recommendationData[0] &&
                    recommendationData[0].created_at
                ).getFullYear()}
                ,
                {new Date(
                  recommendationData &&
                    recommendationData[0] &&
                    recommendationData[0].created_at
                ).toLocaleTimeString([], {
                  hour: "2-digit",
                  minute: "2-digit",
                })}
              </span>
            </p>
            <h2 style={{ padding: "15px 0 0" }}>
              {t("text-dear")}
              <span style={{ textTransform: "capitalize", marginLeft: "0" }}>
                <span>{message}</span>
              </span>
              ,
            </h2>
            <h2>{t("request-give-recommendation")}</h2>
            <p>
              {t(
                "recommendation-increase-user"
              )}
            </p>
          </div>
          <div class="right_side_section">
            <div class="looking_for_candidate">
              {recommendationData.map((data, index) => {
                return (
                  <div className="recommend recomendbig">
                    <div className="image">
                      <img
                        src={
                          data.ProviderImage != null
                            ? "https://admin.stage.mysenscare.com/assets/images/users/" +
                              data.ProviderImage
                            : window.location.origin +
                              window.location.origin +
                              "/img/nany_img.png"
                        }
                        alt=""
                      />
                    </div>
                    <div className="detail">
                      <h3>{data.ProviderName}</h3>
                      <p>{data.recommendation_message}</p>
                      <button
                        disabled={
                          data.recommendation_response != null ||
                          isRecommendation
                            ? true
                            : false
                        }
                        onClick={(e) => setShowModal(true)}
                        style={
                          data.recommendation_response != null ||
                          isRecommendation
                            ? { opacity: "0.5" }
                            : {}
                        }
                      >
                        {t("Leave Recommendation")}
                      </button>
                    </div>
                    <p>
                      {t(
                        "recommendation-letter-feedback"
                      )}
                    </p>
                  </div>
                );
              })}
            </div>
          </div>
        </>
      ) : (
        ""
      )}
      {showModal && (
        <DocumentModal
          showModal={showModal}
          setShowModal={setShowModal}
          documentField={documentField}
          setDocumentField={setDocumentField}
          handleSubmitRecommendation={handleSubmitRecommendation}
        />
      )}
    </>
  );
}
