import React from "react";
import { useTranslation } from "react-i18next";
import { SocialFacebook } from "../../common/SocialFacebook";
import { Link } from "react-router-dom";

import { StarIcon } from "../../../assets/image/StarIcon";
import { WhiteStarIcon } from "../../../assets/image/WhiteStarIcon";
import { FavoriteProfile } from "../../common/FavoriteProfile";
import { PhoneIcon } from "../../../assets/image/PhoneIcon";
import { ConfirmIcon } from "../../../assets/image/ConfirmIcon";
import { ServiceIcon } from "../../../assets/image/ServiceIcon";
import { SearchIcon } from "../../../assets/image/SearchIcon";
import { MarkRoundIcon } from "../../../assets/image/MarkRoundIcon";

export const InterviewItem = ({
  data,
  index,
  profile,
  providerData,
  hire,
  handleSubmit,
  setView,
  rate,
  handleElementById,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <div class="looking_for_candidate_boxs">
        <div class="looking_for_candidate_box">
          <div class="second_sec">
            <div class="heading">
              <h3>
                {data.profile_headline != null ? data.profile_headline : ""}
              </h3>
              {providerData &&
              providerData[0] &&
              providerData[0].status == 1 ? (
                <button
                  style={
                    hire != "" || data.hire_status == 1
                      ? { opacity: "0.5" }
                      : {}
                  }
                  onClick={(e) => handleSubmit(data.provider_id)}
                  disabled={hire != "" || data.hire_status == 1 ? true : false}
                >
                  {hire != "" || data.hire_status == 1 ? t("text-hired") : t("text-hire")}
                </button>
              ) : (
                ""
              )}
              {data.plateformsocialmedia == "Yes" ? (
                <SocialFacebook
                  link={
                    window.location.origin +
                    "/profile-provider/" +
                    data.provider_id
                  }
                />
              ) : (
                ""
              )}
            </div>
            <div class="post_general">
              <h6>
                <Link to={"/profile-provider/" + providerData[0]?.UID}>
                  {data.first_name != null
                    ? data.first_name + " " + data.last_name + " "
                    : ""}
                </Link>
                (
                {data.dob != undefined
                  ? new Date().getFullYear() -
                    parseInt(
                      data.dob
                        .substr(data.dob.lastIndexOf("\\") + 1)
                        .split("-")[0]
                    )
                  : ""}
                )
              </h6>
              <p>
                {rate.reviewAvg >= 0 ? (
                  <>
                    {[...Array(rate.reviewAvg)].map((star, index) => {
                      index += 1;
                      return <StarIcon />;
                    })}
                    {[...Array(5 - rate.reviewAvg)].map((star, index) => {
                      index += 1;
                      return <WhiteStarIcon />;
                    })}
                  </>
                ) : (
                  ""
                )}
                <span> ({rate.reviewcount})</span>
              </p>
            </div>
            <div class="post_detail">
              <div class="post">
                {JSON.parse(data.service_type) != null &&
                  (Object.values(JSON.parse(data.service_type))[0] ==
                  "Nanny" ? (
                    <img
                      src={window.location.origin + "/images/nany_post.svg"}
                      alt=""
                    />
                  ) : Object.keys(JSON.parse(data.service_type))[0] ==
                    "tab2" ? (
                    <img
                      src={window.location.origin + "/images/teacher_post.svg"}
                      alt=""
                    />
                  ) : Object.keys(JSON.parse(data.service_type))[0] ==
                    "tab3" ? (
                    <img
                      src={
                        window.location.origin + "/images/education_post.svg"
                      }
                      alt=""
                    />
                  ) : Object.keys(JSON.parse(data.service_type))[0] ==
                    "tab4" ? (
                    <img
                      src={window.location.origin + "/images/tutor_post.svg"}
                      alt=""
                    />
                  ) : (
                    ""
                  ))}
                <h5>
                  {JSON.parse(data.service_type) != null
                    ? Object.values(JSON.parse(data.service_type))[0] ==
                      "Special Education Paraprofessional"
                      ? t("sped-paraprofessional")
                      : Object.values(JSON.parse(data.service_type))[0] ==
                        "Special Education Teacher"
                      ? t("sped-teacher")
                      : Object.values(JSON.parse(data.service_type))[0]
                    : ""}
                </h5>
              </div>
              <div class="vi"></div>
              <div class="post_pay">
                <img
                  src={window.location.origin + "/img/post_pay.png"}
                  alt=""
                />
                {profile && profile.country == "Serbia" ? (
                  <h5>
                    {data.nanyperhrrate != null
                      ? data.nanyperhrrate
                          .substr(data.nanyperhrrate.lastIndexOf("\\") + 1)
                          .split("-")[0] *
                          100 +
                        " - " +
                        data.nanyperhrrate
                          .substr(data.nanyperhrrate.lastIndexOf("\\") + 1)
                          .split("-")[1] *
                          100
                      : data.tutorperhrrate
                      ? data.tutorperhrrate
                          .substr(data.tutorperhrrate.lastIndexOf("\\") + 1)
                          .split("-")[0] *
                          100 +
                        " - " +
                        data.tutorperhrrate
                          .substr(data.tutorperhrrate.lastIndexOf("\\") + 1)
                          .split("-")[1] *
                          100
                      : ""}
                    {t("time-hour")}
                  </h5>
                ) : (
                  <h5>
                    $
                    {data.nanyperhrrate != null
                      ? data.nanyperhrrate
                      : data.tutorperhrrate
                      ? data.tutorperhrrate
                      : ""}
                    {t("time-hour")}
                  </h5>
                )}
              </div>
              <div class="vi"></div>
              {data.service_type != null &&
              Object.values(JSON.parse(data.service_type))[1] ? (
                <>
                  <div class="post">
                    {data.service_type != null ? (
                      Object.values(JSON.parse(data.service_type))[1] ==
                      "Nanny" ? (
                        <img
                          src={window.location.origin + "/images/nany_post.svg"}
                          alt=""
                        />
                      ) : Object.keys(JSON.parse(data.service_type))[1] ==
                        "tab2" ? (
                        <img
                          src={
                            window.location.origin + "/images/teacher_post.svg"
                          }
                          alt=""
                        />
                      ) : Object.keys(JSON.parse(data.service_type))[1] ==
                        "tab3" ? (
                        <img
                          src={
                            window.location.origin +
                            "/images/education_post.svg"
                          }
                          alt=""
                        />
                      ) : Object.keys(JSON.parse(data.service_type))[1] ==
                        "tab4" ? (
                        <img
                          src={
                            window.location.origin + "/images/tutor_post.svg"
                          }
                          alt=""
                        />
                      ) : (
                        ""
                      )
                    ) : (
                      ""
                    )}
                    <h5>
                      {JSON.parse(data.service_type) != null
                        ? Object.values(JSON.parse(data.service_type))[1] ==
                          "Special Education Paraprofessional"
                          ? t("sped-paraprofessional")
                          : Object.values(JSON.parse(data.service_type))[1] ==
                            "Special Education Teacher"
                          ? t("sped-teacher")
                          : Object.values(JSON.parse(data.service_type))[1]
                        : ""}
                    </h5>
                  </div>
                  <div class="vi"></div>

                  <div class="post_pay">
                    <img
                      src={window.location.origin + "/img/post_pay.png"}
                      alt=""
                    />
                    {profile && profile.country == "Serbia" ? (
                      <h5>
                        {data.tutorperhrrate
                          ? data.tutorperhrrate
                              .substr(data.tutorperhrrate.lastIndexOf("\\") + 1)
                              .split("-")[0] *
                              100 +
                            " - " +
                            data.tutorperhrrate
                              .substr(data.tutorperhrrate.lastIndexOf("\\") + 1)
                              .split("-")[1] *
                              100
                          : ""}
                        {t("time-hour")}
                      </h5>
                    ) : (
                      <h5>
                        ${data.tutorperhrrate ? data.tutorperhrrate : ""}
                        {t("time-hour")}
                      </h5>
                    )}
                  </div>
                  <div class="vi"></div>
                </>
              ) : (
                ""
              )}
              <div class="vi"></div>
              <div class="post_fet">
                <img
                  src={window.location.origin + "/images/post_fet.svg"}
                  alt=""
                />
                <h5>
                  {data.tutorintrestedin != null
                    ? t(data.tutorintrestedin)
                    : t(data.nanyintrestedin)}
                </h5>
              </div>
              <div class="vi"></div>
              <div class="post_cal">
                <img
                  src={window.location.origin + "/images/post_cal.svg"}
                  alt=""
                />
                <h5>
                  {data.tutorstartdate != null
                    ? data.tutorstartdate
                    : data.nanystartdate}
                </h5>
              </div>
              <div class="vi"></div>
              <div class="post_loc">
                <img
                  src={window.location.origin + "/images/post_loc.svg"}
                  alt=""
                />
                <h5>
                  {data.country != null ? data.country : ""} ,
                  {data.city != null ? data.city : ""}
                </h5>
              </div>
            </div>
          </div>
          <div class="first_sec">
            <div class="image_sec">
              <img
                src={
                  data.file_path != null
                    ? "https://admin.stage.mysenscare.com/assets/images/users/" +
                      data.file_path
                    : "img/nany_img.png"
                }
                alt=""
              />
              <div class="heart_sec">
                <FavoriteProfile id={data.id} username={data.username} />
              </div>
            </div>
            <div class="nany_social">
              {data.phoneVerifiedStatus == 1 ? (
                <img
                  src={window.location.origin + "/images/nany_phone.svg"}
                  alt=""
                />
              ) : (
                <PhoneIcon />
              )}
              <img
                src={window.location.origin + "/images/nany_msg.svg"}
                alt=""
              />
              {data.facebookverify != null || data.linkdinverify != null ? (
                <img
                  src={window.location.origin + "/images/nany_cont.svg"}
                  alt=""
                />
              ) : (
                <ServiceIcon />
              )}
              {rate.docsStatus == "Yes" ? (
                <img src={window.location.origin + "/images/ok.svg"} alt="" />
              ) : (
                <ConfirmIcon />
              )}
            </div>
          </div>
          <div class="second_sec">
            <p id={"half" + index}>
              {data.about != null ? data.about.substr(0, 100) : ""}
              {data.about != null && data.about.length > 100 ? (
                <span
                  onClick={(e) =>
                    handleElementById(
                      "half" + index,
                      "full" + index,
                      "plink" + index
                    )
                  }
                >
                  {t("text-more")}
                </span>
              ) : (
                ""
              )}
            </p>
            <p id={"full" + index} className="hide">
              {data.about}
              <span
                onClick={(e) =>
                  handleElementById(
                    "full" + index,
                    "half" + index,
                    "plink" + index
                  )
                }
              >
                less
              </span>
            </p>
          </div>
          <div class="view_profile_btn visiterbtn">
            <Link to={"/profile-provider/" + data.provider_id}>
              {t("visit-profile")}
            </Link>
            {data.status == 1 && (
              <Link
                to=""
                onClick={(e) => setView(data.provider_id)}
                className={"brown"}
              >
                {t("send-message")}
              </Link>
            )}
            <Link
              to="/search-providers"
              style={{
                border: "none",
                background: "transparent",
                color: "#7D2B8B",
                fontSize: "17px",
                width: "100%",
                marginLeft: "0",
                padding: "5px 0",
              }}
            >
              <SearchIcon />
              {t("search-similar-candidate")}
            </Link>
          </div>
        </div>
        <p>
          {(data.medicalcondition != null && data.medicalcondition == "Yes") ||
          (data.anyallergies != null && data.anyallergies == "Yes") ? (
            <>
              <MarkRoundIcon />
              <strong>{t("note-with")}</strong>
              {t(
                "record-indicates"
              )}
              <br />
            </>
          ) : (
            ""
          )}
        </p>
      </div>
    </>
  );
};
