import {create} from 'zustand';

export const usePersonalInfoStore = create(set => ({
  personalInfo: {
    personal: "",
    payment: "",
    delete: ""
  },
  setPersonalInfo: (newPersonalInfo) => set(state => ({
    personalInfo: { ...state.personalInfo, ...newPersonalInfo }
  })),

  password: {
    oldpassword: "",
    c_password: "",
    n_password: ""
  },
  setPassword: (newPassword) => set(state => ({
    password: { ...state.password, ...newPassword }
  })),

  errorPassword: {
    oldpassword: "",
    c_password: "",
    n_password: ""
  },
  setErrorPassword: (newErrorPassword) => set(state => ({
    errorPassword: { ...state.errorPassword, ...newErrorPassword }
  }))
}));
