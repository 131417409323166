import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Modal from "react-bootstrap/Modal";

export const StatusModal = ({
  showModal,
  handleCloseModal,
  disabled,
  handleDescribeSelect,
  handleDescribeAdd,
}) => {
  const { t } = useTranslation();

  return (
    <Modal show={showModal} onHide={handleCloseModal}>
      <Modal.Body>
        <div className="promocode_content younger statuschange">
          <Link to="" onClick={handleCloseModal}>
            +
          </Link>
          <h5>{t("Change the status of profession")}</h5>
          <p style={{ marginBottom: "0" }}>
            <b>
              {t(
                "selected-profession-active"
              )}
            </b>
          </p>
          <div className="graybg">
            <p>
              {t(
                "post-senscare-profession"
              )}
            </p>
            <div class="button text-center">
              <div class="pull-right">
                <button
                  class="btn"
                  onClick={(e) => {
                    handleCloseModal();
                  }}
                >
                  {t("text-cancel")}
                </button>
              </div>
              <div class="pull-right">
                <button
                  class="btn confirm"
                  onClick={(e) => {
                    if (disabled == 1) {
                      handleDescribeSelect("tab1", "Nanny");
                      handleDescribeAdd("tab1", "Nanny");
                    } else if (disabled == 2) {
                      handleDescribeSelect("tab2", "Special Education Teacher");
                      handleDescribeAdd("tab2", "Special Education Teacher");
                    } else if (disabled == 3) {
                      handleDescribeSelect(
                        "tab3",
                        "Special Education Paraprofessional"
                      );
                      handleDescribeAdd(
                        "tab3",
                        "Special Education Paraprofessional"
                      );
                    } else if (disabled == 4) {
                      handleDescribeSelect("tab4", "Tutor");
                      handleDescribeAdd("tab4", "Tutor");
                    }
                    handleCloseModal();
                  }}
                >
                  {t("Change status")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
