import React, { useEffect } from "react";
import useDrivePicker from "react-google-drive-picker";

export function GoogleDrive() {
  const [openPicker, data] = useDrivePicker();

  const handleOpenPicker = () => {
    openPicker({
      clientId:
        "578304758815-d8ugctkr2gt2h5esjvdvpatmshe7ghfk.apps.googleusercontent.com",
      developerKey: "AIzaSyCaHx2_BcFzuT15eT4Ky0ga65wwCfxGfU4",
      viewId: "DOCS",
      showUploadView: true,
      showUploadFolders: true,
      supportDrives: true,
      multiselect: true,
      callbackFunction: (data) => {
        if (data.action === "cancel") {
          console.log("User clicked cancel/close button");
        }
        console.log(data);
      },
    });
  };

  return (
    <div>
      <input
        type="radio"
        class="mail-radio"
        name="chk"
        onClick={() => handleOpenPicker()}
      />
    </div>
  );
}
