import { DEFAULT_API } from "../utils/variables";

export const getCountryDetails = async () => {
  const myHeaders = new Headers();
  myHeaders.append("Authorization", "Bearer " + localStorage.getItem("token"));

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  try {
    const response = await fetch(
      `${DEFAULT_API}/api/v1/details`,
      requestOptions
    );

    if (!response.ok) {
      const error = await response.json();
      throw new Error(error.message || "Something went wrong");
    }

    return response.json();
  } catch (error) {
    console.error("Error fetching country details:", error);
    throw error;
  }
};

export const fetchWorkWithUs = async () => {
  const requestOptions = {
    method: "GET",
    redirect: "follow",
  };

  try {
    const response = await fetch(
      `${DEFAULT_API}/api/workwithus`,
      requestOptions
    );
    if (!response.ok) {
      const error = await response.json();
      throw new Error(error.message || "Something went wrong");
    }
    return response.json();
  } catch (error) {
    console.error("Error fetching work with us data:", error);
    throw error;
  }
};

export const fetchSubmitEnquiry = async (formFields, contactCode) => {
  const formData = new FormData();
  formData.append("first_name", formFields.firstName);
  formData.append("last_name", formFields.lastName);
  formData.append("email", formFields.email);
  formData.append("phone", contactCode.code + formFields.phone);
  formData.append("message", formFields.message);

  const requestOptions = {
    method: "POST",
    body: formData,
    redirect: "follow",
  };

  try {
    const response = await fetch(
      `${DEFAULT_API}/api/workwithusenquiry`,
      requestOptions
    );
    if (!response.ok) {
      const error = await response.json();
      throw new Error(error.message || "Something went wrong");
    }
    return response.json();
  } catch (error) {
    console.error("Error submitting enquiry:", error);
    throw error;
  }
};
