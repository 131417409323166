import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { VideoModal } from "../../components/common/VideoModal";
import { HireBecome } from "../../components/common/HireBecome";
import { ExploreLinks } from "../../components/common/ExploreLinks/ExploreLinks";
import { exploreClickCounts } from "../../api";
import { Form } from "../../components/landingPage/form";
import { Header } from "../../components/landingPage/header";
import { Footer } from "../../components/landingPage/footer";

import styles from "./LandingStepPage.module.css";

export function LandingStepPage() {
  const { t } = useTranslation();

  const [showModal, setShowModal] = useState(false);

  const handleExploreClick = () => {
    exploreClickCounts();
  };

  return (
    <>
      <Header />

      <div className={`${styles.container_fluid} ${styles.border}`}>
        <div className={styles.container}>
          <div className={styles.landing_page}>
            <h2>{t("post-services-marketplace")}</h2>
            <div className={styles.left_form}>
              <p>{t("post-education-professionals")}</p>

              <HireBecome />

              <div className={`${styles.dask} ${styles.bgnon}`}>
                <Form />
              </div>
            </div>
            <div className={styles.right_section}>
              <ExploreLinks
                handleExploreClick={handleExploreClick}
                setShowModal={setShowModal}
              />

              <img src='./images/landing.png' alt='banner' />
            </div>
            <div className={styles.left_form}>
              <div className={styles.mobile}>
                <Form />
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />

      <VideoModal showModal={showModal} setShowModal={setShowModal} />
    </>
  );
}
