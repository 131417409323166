import { useTranslation } from "react-i18next";
import { useErrorFieldStore } from "../../store/useErrorsFieldsStore";
import { useDetailParentsStore } from "../../store/useDetailParentsStore";
import { useProfileStore } from "../../store/useProfileStore";
import { useControlsStore } from "../../store/useControlsStore";
import { useContactJobStore } from "../../store/useContactJobStore";
import { JOB_FIELDS } from "../../utils/variables";
import {useState} from "react";

export function StepSevenContent({ updateProfile, afterLoginsField }) {
  const { t, i18n } = useTranslation();
  const language = i18n.language;
  const [setStep, setShowModal] = useControlsStore((state) => [
    state.setStep,
    state.setShowModal,
  ]);
  const [errorField, setErrorField] = useErrorFieldStore((state) => [
    state.errorField,
    state.setErrorField,
  ]);
  const [detailParents, setDetailParents] = useDetailParentsStore((state) => [
    state.detailParents,
    state.setDetailParents,
  ]);
  const { job, setJob } = useContactJobStore();
  const { isButton, detail } = useProfileStore();
  const [isProcessing, setIsProcessing] = useState(false);

  return (
    <div className="detail work-experience job_performance job_post setp4">
      <div className="form_group full">
        <label>
          <strong>
            {t("details-for")}
            <span style={{
              marginLeft: "2px",
              marginRight: "2px"
            }}>
              {detail.service_type
                ? t(Object.values(detail.service_type)[0])
                : ""}
            </span>
          </strong>
        </label>
        <br />
        <label>
          {t("post-job-title")}
          <span className={errorField.jobs && errorField.jobs !== "" ? "starred" : ""}>*</span>
        </label>
        <textarea
          rows={2}
          placeholder={
            t('help-with-children')
          }
          maxlength="70"
          name="message"
          onChange={(e) => {
            setErrorField({ jobs: "" });
            setDetailParents({
              jobs: e.target.value,
            });
            setJob({
              ...job,
              job_type: Object.values(detail.service_type)[0],
            });
          }}
          defaultValue={
          (detailParents.jobs && detailParents.jobs.length > 0 && detailParents.jobs.length !== '0') ?
              t(detailParents.jobs) : ""}
        ></textarea>
        <span>
          {t("number-characters")} {70 - detailParents.jobs.length}
        </span>
      </div>
      <div className="form_group full sec">
        <label>
          {t("job-description")}
          <span className={errorField.jobs_description && errorField.jobs_description !== "" ? "starred" : ""}>
            *
          </span>
        </label>
        <textarea
          rows={4}
          placeholder={
            detail.service_type
              ? Object.keys(detail.service_type)[0] == "tab1"
                ? t("post-jelena-nanny")
                : Object.keys(detail.service_type)[0] == "tab2"
                ? t("post-jelena-teacher")
                : Object.keys(detail.service_type)[0] == "tab3"
                ? t("post-jelena-paraprofessional")
                : Object.keys(detail.service_type)[0] == "tab4"
                ? t("post-jelena-english")
                : ""
              : ""
          }
          maxlength="300"
          name="message"
          onChange={(e) => {
            setErrorField({ jobs_description: "" });
            setDetailParents({
              jobs_description: e.target.value,
            });
          }}
          defaultValue={t(detailParents.jobs_description)}
        ></textarea>

        <span>
          {t("number-characters")} {300 - detailParents.jobs_description.length}
        </span>
      </div>
      <div class="form_group full socialpost border">
        <label>
          {t("consent-platform")}
          <span className={job.plateformonsocialmedia == "" ? "starred" : ""}>
            *
          </span>
        </label>
        <div class="checkbox create">
          <ul>
            <li style={{ width: "25% !important" }}>
              <input
                type="radio"
                name="e"
                onClick={(e) =>
                  setJob({
                    ...job,
                    plateformonsocialmedia: "Yes",
                  })
                }
                checked={job.plateformonsocialmedia == "Yes" ? true : false}
              />
              <span> {t("confirm")}</span>
            </li>
            <li>
              <input
                type="radio"
                name="e"
                onClick={(e) =>
                  setJob({
                    ...job,
                    plateformonsocialmedia: "No",
                  })
                }
                checked={job.plateformonsocialmedia == "No" ? true : false}
              />
              <span> {t("objection")}</span>
            </li>
          </ul>
        </div>
      </div>
      {detail.service_type && Object.keys(detail.service_type).length == 2 ? (
        <>
          <div className="form_group full">
            <label>
              <strong>
                {t("details-for")}
                <span style={{
                  marginLeft: "2px",
                  marginRight: "2px"
                }}>
                  {t(Object.values(detail.service_type)[1])}
                </span>
              </strong>
            </label>
            <br />
            <label>
              {t("post-job-title")}
              <span className={errorField.jobs2 && errorField.jobs2 !== "" ? "starred" : ""}>*</span>
            </label>
            <textarea
              rows={2}
              placeholder={
                t('help-with-children')
              }
              maxlength="70"
              name="message"
              onChange={(e) => {
                setErrorField({ jobs2: "" });
                setJob({
                  ...job,
                  job_type2: Object.values(detail.service_type)[1],
                });
                setDetailParents({
                  jobs2: e.target.value,
                });
              }}
              defaultValue={detailParents.jobs2}
            ></textarea>

            <span>
              {t("number-characters")}
              {70 - detailParents.jobs2.length}
            </span>
          </div>
          <div className="form_group full sec">
            <label>
              {t("job-description")}
              <span
                className={errorField.jobs_description2 && errorField.jobs_description2 !== "" ? "starred" : ""}
              >
                *
              </span>
            </label>
            <textarea
              rows={4}
              placeholder={
                detail.service_type
                  ? Object.keys(detail.service_type)[1] == "tab1"
                    ? t("post-jelena-nanny")
                    : Object.keys(detail.service_type)[1] == "tab2"
                    ? t("post-jelena-teacher")
                    : Object.keys(detail.service_type)[1] == "tab3"
                    ? t("post-jelena-paraprofessional")
                    : Object.keys(detail.service_type)[1] == "tab4"
                    ? t("post-jelena-english")
                    : ""
                  : ""
              }
              maxlength="300"
              name="message"
              onChange={(e) => {
                setErrorField({
                  jobs_description2: "",
                });
                setDetailParents({
                  jobs_description2: e.target.value,
                });
              }}
              defaultValue={detailParents.jobs_description2}
            ></textarea>

            <span>
              {t("number-characters")}
              {300 - detailParents.jobs_description2.length}
            </span>
          </div>
          <div class="form_group full socialpost">
            <label>
              {t("consent-platform")}
              <span
                className={job.plateformonsocialmedia2 == "" ? "starred" : ""}
              >
                *
              </span>
            </label>
            <div class="checkbox create">
              <ul>
                <li style={{ width: "25% !important" }}>
                  <input
                    type="radio"
                    name="ke"
                    onClick={(e) =>
                      setJob({
                        ...job,
                        plateformonsocialmedia2: "Yes",
                      })
                    }
                    checked={
                      job.plateformonsocialmedia2 == "Yes" ? true : false
                    }
                  />
                  <span> {t("confirm")}</span>
                </li>
                <li>
                  <input
                    type="radio"
                    name="ke"
                    onClick={(e) =>
                      setJob({
                        ...job,
                        plateformonsocialmedia2: "No",
                      })
                    }
                    checked={job.plateformonsocialmedia2 == "No" ? true : false}
                  />
                  <span> {t("objection")}</span>
                </li>
              </ul>
            </div>
          </div>
        </>
      ) : (
        ""
      )}

      <div className="button">
        <button
          onClick={(e) => {
            setStep(6);
            window.scrollTo({ top: 0 });
          }}
        >
          {t("text-back")}
        </button>
        <button
          disabled={isButton || isProcessing}
          onClick={(e) => {
            if (
              detailParents.jobs != "" &&
              detailParents.jobs_description != "" &&
              (detail.service_type &&
              Object.keys(detail.service_type).length == 2
                ? detailParents.jobs2 != "" &&
                  detailParents.jobs_description2 != "" &&
                  job.plateformonsocialmedia2 &&
                  job.plateformonsocialmedia
                : detailParents.jobs != "")
            ) {
              setIsProcessing(true);
              updateProfile(true);
              setTimeout(() => {
                setShowModal(true);
                setIsProcessing(false);
              }, 1000);
            } else {
              JOB_FIELDS.map((e) => {
                afterLoginsField(e.name);
              });
            }
          }}
        >
          {t("text-finish")}
        </button>
      </div>
    </div>
  );
}
