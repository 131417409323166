import { DEFAULT_API } from "../utils/variables";

export const fetchSurveyData = async () => {
  const requestOptions = {
    method: "GET",
    redirect: "follow",
  };

  try {
    const response = await fetch(
      `${DEFAULT_API}/api/getallsurvey`,
      requestOptions
    );
    if (!response.ok) {
      const error = await response.text();
      throw new Error(error || "Something went wrong");
    }
    return response.json();
  } catch (error) {
    console.error("Error fetching survey data:", error);
    throw error;
  }
};

export const fethSaveSurvey = async (token, formLogin) => {
  const myHeaders = new Headers();
  myHeaders.append("Authorization", "Bearer " + token);

  const formdata = new FormData();
  formdata.append("userId", formLogin.userId);
  formdata.append("recommendValue", formLogin.recommendValue);
  formdata.append("navigationValue", formLogin.navigationValue);
  formdata.append("seeService", formLogin.seeService);
  formdata.append("overallValue", formLogin.overallValue);
  formdata.append("userReview", formLogin.userReview);
  formdata.append("otherService", formLogin.otherService);

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: formdata,
    redirect: "follow",
  };

  try {
    const response = await fetch(
      `${DEFAULT_API}/api/v1/savesurvey`,
      requestOptions
    );
    if (!response.ok) {
      const error = await response.json();
      throw new Error(error.message || "Something went wrong");
    }
    return response.json();
  } catch (error) {
    console.error("Error saving survey data:", error);
    throw error;
  }
};
