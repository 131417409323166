import React from "react";
import { useTranslation } from "react-i18next";
import Modal from "react-bootstrap/Modal";

import { MarkRoundIcon } from "../../../assets/image/MarkRoundIcon";

export const JobApplicationModal = ({
  isJobApplicationModal,
  userId,
  profile,
  handleJobApply,
  setIsJobApplicationModal,
}) => {
  const { t } = useTranslation();

  return (
    <Modal
      show={isJobApplicationModal}
      onHide={(e) => setIsJobApplicationModal(false)}
    >
      <Modal.Body>
        <div className="promocode_content upload_document_delete sure">
          <h2 style={{ color: "#636363" }}>{t("Job application")}</h2>

          {profile.childmedicalcondition == "Yes" ||
          profile.childanyallergies == "Yes" ? (
            <p style={{ borderBottom: "1px solid #B7B7B7" }}>
              <MarkRoundIcon />
              <b> {t("Note")}</b> {t("record-indicates-provider")}
            </p>
          ) : (
            ""
          )}
          <p>{t("Are you sure you want to apply for this job post")}</p>
          <button onClick={(e) => setIsJobApplicationModal(false)}>
            {t("text-cancel")}
          </button>
          <button
            onClick={(e) => {
              setIsJobApplicationModal(false);
              handleJobApply(userId.id, userId.userId, userId.index);
            }}
          >
            {t("Apply")}
          </button>
          <br />
          <br />
        </div>
      </Modal.Body>
    </Modal>
  );
};
