import React from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { FavoriteProvider } from "../../components/common/FavoriteProvider";
import { NotificationTabProvider } from "../../components/common/NotificationTabProvider";
import { NotificationTabParents } from "../../components/common/NotificationTabParents";
import { MessageTab } from "../../components/Message/MessageTab";
import { JobHistoryParents } from "../../components/Job/JobHistoryParents";
import { JobHistory } from "../../components/Job/JobHistory";
import { SquareServiceIcon } from "../../assets/image/SquareServiceIcon";
import { NotificationCount } from "../../components/common/NotificationCount";

export const ProfileMobileMenu = ({
  setProfileSection,
  profileSection,
  tab,
  setTab,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div className="mobilemenubottom">
      <div
        className="side_drop_collapse_box_content"
        onClick={(e) => {
          localStorage.removeItem("side");
          setProfileSection("");
        }}
        style={
          profileSection == "notifications"
            ? { display: "block" }
            : { display: "none" }
        }
      >
        <span
          onClick={(e) => {
            localStorage.removeItem("side");
            setProfileSection("");
          }}
        ></span>
        {localStorage.getItem("user_type") == "parents" ? (
          <NotificationTabParents
            setsubtab={setTab}
            subtab={tab}
            profilesection={profileSection}
          />
        ) : (
          <NotificationTabProvider
            setsubtab={setTab}
            subtab={tab}
            profilesection={profileSection}
          />
        )}
      </div>
      <div
        className="side_drop_collapse_box_content Document_main "
        onClick={(e) => {
          localStorage.removeItem("side");
          setProfileSection("");
        }}
        style={
          profileSection == "fav" ? { display: "block" } : { display: "none" }
        }
      >
        <span
          onClick={(e) => {
            localStorage.removeItem("side");
            setProfileSection("");
          }}
        ></span>
        <ul>
          <li
            onClick={(e) => {
              navigate(
                localStorage.getItem("user_type") == "parents"
                  ? "/search-parents/who-i-visited"
                  : "/search-providers/who-i-visited"
              );
              window.scrollTo({ top: 0, behavior: "smooth" });
              setTab("who-i-visited");
            }}
            className={tab == "who-i-visited" ? "active " : ""}
          >
            {t("Who I Visited")}
          </li>
          <li
            onClick={(e) => {
              navigate(
                localStorage.getItem("user_type") == "parents"
                  ? "/search-parents/who-visited-me"
                  : "/search-providers/who-visited-me"
              );
              window.scrollTo({ top: 0, behavior: "smooth" });
              setTab("who-visited-me");
            }}
            className={tab == "who-visited-me" ? "active upgrade" : "upgrade"}
          >
            {t("Who Visited Me")}
            <span>
              <Link to={""}>{t("upgrade")}</Link>
            </span>
          </li>
        </ul>
      </div>
      <div
        className="side_drop_collapse_box_content"
        style={
          profileSection == "Favorites"
            ? { display: "block" }
            : { display: "none" }
        }
      >
        <span
          onClick={(e) => {
            localStorage.removeItem("side");
            setProfileSection("");
          }}
        ></span>
        {localStorage.getItem("user_type") == "parents" ? (
          <FavoriteProvider
            setsubtab={setTab}
            subtab={tab}
            setprofilesection={setProfileSection}
          />
        ) : (
          <FavoriteProvider
            setsubtab={setTab}
            subtab={tab}
            setprofilesection={setProfileSection}
          />
        )}
      </div>
      <div
        className="side_drop_collapse_box_content"
        style={
          profileSection == "Job_history"
            ? { display: "block" }
            : { display: "none" }
        }
        onClick={(e) => {
          localStorage.removeItem("side");
          setProfileSection("");
        }}
      >
        <span
          onClick={(e) =>
            setProfileSection(
              profileSection != "Job_history" ? "Job_history" : ""
            )
          }
        ></span>
        {localStorage.getItem("user_type") == "parents" ? (
          <JobHistoryParents setsubtab={setTab} subtab={tab} />
        ) : (
          <JobHistory setsubtab={setTab} subtab={tab} />
        )}
      </div>
      <div
        className="side_drop_collapse_box_content"
        style={
          profileSection == "message"
            ? { display: "block" }
            : { display: "none" }
        }
        onClick={(e) => {
          localStorage.removeItem("side");
          setProfileSection("");
        }}
      >
        <span
          onClick={(e) => {
            localStorage.removeItem("side");
            setProfileSection("");
          }}
        ></span>
        <MessageTab setsubtab={setTab} subtab={tab} />
      </div>

      <ul>
        <li
          onClick={(e) => {
            window.scrollTo({ top: 0, behavior: "smooth" });
            setProfileSection(
              profileSection != "Job_history" ? "Job_history" : ""
            );
          }}
        >
          <img
            src={window.location.origin + "/images/jobnhistory.svg"}
            alt=""
          />
          <span>{t("Jobs & Doc")}</span>
        </li>
        <li
          onClick={(e) => {
            window.scrollTo({ top: 0, behavior: "smooth" });
            setProfileSection(profileSection != "fav" ? "fav" : "");
          }}
        >
          <SquareServiceIcon width={20} height={22} />
          <span> {t("Recent visit")}</span>
        </li>
        <li
          onClick={(e) =>
            setProfileSection(profileSection != "message" ? "message" : "")
          }
        >
          <img src={window.location.origin + "/images/message.svg"} alt="" />
          <span>{t("text-messages")}</span>
        </li>
        {localStorage.getItem("user_type") == "parents" ? (
          <li
            onClick={(e) => {
              window.scrollTo({ top: 0, behavior: "smooth" });
              setTab("all-profile");
              navigate("/search-providers/all-profile");
            }}
          >
            <img src={window.location.origin + "/images/fav.svg"} alt="" />
            <span>{t("Favorites")}</span>
          </li>
        ) : (
          <li
            onClick={(e) => {
              window.scrollTo({ top: 0, behavior: "smooth" });
              setProfileSection(
                profileSection != "Favorites" ? "Favorites" : ""
              );
            }}
          >
            <img src={window.location.origin + "/images/fav.svg"} alt="" />
            <span>{t("Favorites")}</span>
          </li>
        )}
        <li
          onClick={(e) => {
            window.scrollTo({ top: 0, behavior: "smooth" });
            setTab("Reviews");
            setProfileSection("");
            navigate(
              localStorage.getItem("user_type") == "parents"
                ? "/search-parents/Reviews"
                : "/search-providers/Reviews"
            );
          }}
          className={
            tab == "Reviews"
              ? "active side_drop_collapse_none"
              : "side_drop_collapse_none"
          }
        >
          <img src={window.location.origin + "/images/reviewi.svg"} alt="" />
          <span>{t("text-reviews")}</span>
        </li>
        <li
          onClick={(e) => {
            window.scrollTo({ top: 0, behavior: "smooth" });
            setProfileSection(
              profileSection != "notifications" ? "notifications" : ""
            );
          }}
        >
          <span className="count">
            <NotificationCount />
          </span>
          <img
            src={window.location.origin + "/images/notification.svg"}
            alt=""
          />
          <span>{t("Notifications")}</span>
        </li>
      </ul>
    </div>
  );
};
