import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useParams } from "react-router-dom";

import { StarIcon } from "../../../assets/image/StarIcon";
import { WhiteStarIcon } from "../../../assets/image/WhiteStarIcon";
import { OptionIcon } from "../../../assets/image/OptionIcon";
import { DumpSecondaryIcon } from "../../../assets/image/DumpSecondaryIcon";
import { BanIcon } from "../../../assets/image/BanIcon";
import { HandIcon } from "../../../assets/image/HandIcon";
import { MailIcon } from "../../../assets/image/MailIcon";
import {
  DEFAULT_API,
  ENG_LANGUAGE,
  MONTH_ENG_LANGUAGE,
  MONTH_SRB_LANGUAGE,
} from "../../../utils/variables";
import {
  fetchBlockUser,
  fetchInboxMessages,
  fetchReportUser,
  fetchSendMessage,
  fetchSendMessageEmail,
} from "../../../api";
import { fetchDeleteMessageNotification } from "../../../api/notificationApi";
import { SurveyModal } from "./Modals/SurveyModal";
import { LoginModal } from "./Modals/LoginModal";
import { ReviewModal } from "./Modals/ReviewModal";

export function MessageContent(props) {
  const { t, i18n } = useTranslation();
  const language = i18n.language;
  const navigate = useNavigate();

  const [messageData, setMessageData] = useState([]);
  const [isCheck, setIsCheck] = useState(true);

  const [showReviewModal, setShowReviewModal] = useState(false);
  const [userId] = useState(localStorage.getItem("id"));
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [isSurvey, setIsSurvey] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [message, setMessage] = useState("");
  const [isCheckMessage, setIsCheckMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [report, setReport] = useState({
    resion: "",
    other: "",
    error: "",
  });

  const slugData = useParams();
  const month =
    language === ENG_LANGUAGE ? MONTH_ENG_LANGUAGE : MONTH_SRB_LANGUAGE;

  const handleSubmitMessage = async () => {
    localStorage.removeItem("message");
    try {
      const result = await fetchInboxMessages(props.id);
      setMessageData([result.data]);
      console.log(result);
    } catch (error) {
      console.error("Error fetching inbox messages:", error);
    }
  };

  useEffect(() => {
    if (isCheck) {
      handleSubmitMessage();
      setIsCheck(false);
    }
    const elementById = document.getElementById("parentDiv");

    if (elementById != null) {
      elementById.scrollTop = elementById.scrollHeight;
    }
  }, [isCheck, messageData, errorMessage]);

  const handleSendMessage = async () => {
    setIsCheckMessage(true);
    if (message !== "") {
      try {
        const result = await fetchSendMessage(props.id, message);
        handleSubmitMessage();
        setMessage("");
        setIsCheckMessage(false);
        if (result.message === "The user is blocked.Cannot send a message") {
          setErrorMessage("The user is blocked. Cannot send a message");
        }
        await fetchSendMessageEmail(props.id);
      } catch (error) {
        console.error("Error sending message:", error);
      }
    }
  };

  const handleSubmitUser = async () => {
    try {
      await fetchBlockUser(messageData[0].userdetails?.id);
      navigate(`/search-providers/${slugData.id}`);
    } catch (error) {
      console.error("Error blocking user:", error);
    }
  };

  const handleReportUser = async () => {
    if (report.resion !== "") {
      setReport({ ...report, error: "" });
      try {
        await fetchReportUser(messageData[0].userdetails?.id, report.resion);
        setShowLoginModal(true);
      } catch (error) {
        console.error("Error reporting user:", error);
      }
    } else {
      setReport({ ...report, error: "error" });
    }
  };

  const handleDeleteMessageNotification = async () => {
    try {
      const msgIds = [messageData && messageData[0]?.getmessages[0]?.msg_id];
      await fetchDeleteMessageNotification(msgIds);
      navigate(`/search-providers/${slugData.id}`);
      props.list();
    } catch (error) {
      console.error("Error deleting message notification:", error);
    }
  };

  return (
    <>
      {messageData && messageData[0] ? (
        <>
          <h2 className="border"></h2>
          <div className="message_chet">
            {messageData && messageData[0].userdetails ? (
              <div className="top_profile">
                <div className="left_p" onClick={(e) => setIsActive(false)}>
                  <img
                    src={
                      DEFAULT_API +
                      "/assets/images/users/" +
                      (messageData && messageData[0].userdetails?.file_path)
                    }
                  />
                  <span>
                    <h2>
                      {messageData && messageData[0].userdetails?.first_name}
                      {messageData && messageData[0].userdetails?.last_name}
                    </h2>
                    <p>
                      {messageData &&
                      messageData[0].userdetails?.reviewAvg >= 0 ? (
                        <>
                          {[
                            ...Array(
                              messageData &&
                                messageData[0].userdetails?.reviewAvg
                            ),
                          ].map((star, index) => {
                            index += 1;
                            return <StarIcon />;
                          })}
                          {[
                            ...Array(
                              5 -
                                (messageData &&
                                  messageData[0].userdetails?.reviewAvg)
                            ),
                          ].map((star, index) => {
                            index += 1;
                            return <WhiteStarIcon />;
                          })}
                        </>
                      ) : (
                        ""
                      )}
                      <span>
                        
                        (
                        {messageData && messageData[0].userdetails?.reviewcount}
                        )
                      </span>
                    </p>
                    <p>{messageData && messageData[0].userdetails?.address}</p>
                  </span>
                </div>
                {isActive ? (
                  <div
                    className="bg_off"
                    onClick={(e) => setIsActive(false)}
                  ></div>
                ) : (
                  ""
                )}

                <div className="right_p">
                  <Link
                    to={
                      messageData &&
                      messageData[0] &&
                      messageData[0]?.userdetails?.user_type == "provider"
                        ? "/profile-provider/" +
                          (messageData &&
                            messageData[0] &&
                            messageData[0]?.userdetails?.id)
                        : "/profile-parents/" +
                          (messageData &&
                            messageData[0] &&
                            messageData[0]?.userdetails?.id)
                    }
                  >
                    {t("view-profile")}
                  </Link>
                  <div className="select message_select">
                    <button onClick={(e) => setIsActive(!isActive)}>
                      <OptionIcon />
                    </button>
                    {isActive ? (
                      <>
                        <ul>
                          <li
                            onClick={(e) => {
                              setShowReviewModal(true);
                            }}
                          >
                            {t("text-delete")}
                            <DumpSecondaryIcon />
                          </li>

                          <li
                            onClick={(e) => {
                              setIsActive(!isActive);
                              handleSubmitUser();
                            }}
                          >
                            {messageData &&
                            messageData[0].userdetails?.blockUser == true
                              ? t("unblock-user")
                              : t("block-user")}
                            <BanIcon />
                          </li>
                          <li
                            onClick={(e) => {
                              setIsActive(!isActive);
                              setIsSurvey(true);
                            }}
                          >
                            <HandIcon />
                          </li>
                        </ul>
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
            <h2 className="border" style={{ width: "100%" }}></h2>
            <div
              className="chet_message"
              id="parentDiv"
              onClick={(e) => setIsActive(false)}
            >
              {messageData && messageData[0].getmessages
                ? messageData &&
                  messageData[0].getmessages.map((data) => {
                    return (
                      <div
                        className={data.sender_id == userId ? "send" : "recive"}
                      >
                        <img
                          src={
                            DEFAULT_API +
                            "/assets/images/users/" +
                            (data.sender_id == userId
                              ? data.senderImage
                              : messageData &&
                                messageData[0].userdetails?.file_path)
                          }
                        />
                        <div className="message_view">{data.message}</div>
                        <span>
                          {new Date(data.created_at).toLocaleDateString(
                            "en-US",
                            { weekday: "short" }
                          )}
                          ,
                          {month[new Date(data.created_at).getMonth()] +
                            " " +
                            new Date(data.created_at).getDate()}
                          , {new Date(data.created_at).getFullYear()},
                          {new Date(data.created_at).toLocaleTimeString([], {
                            hour: "2-digit",
                            minute: "2-digit",
                          })}
                        </span>
                      </div>
                    );
                  })
                : ""}
            </div>
            {messageData && messageData[0].userdetails?.blockUser == false ? (
              messageData &&
              messageData[0]?.userdetails?.blockbycurrentuser == false ? (
                <div className="send_input">
                  <p className="blocked">{errorMessage}</p>
                  <textarea
                    type="text"
                    id="textarea"
                    placeholder={t("text-type")}
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                  />
                  <button
                    onClick={handleSendMessage}
                    style={isCheckMessage == true ? { opacity: "0.5" } : {}}
                    disabled={isCheckMessage}
                  >
                    <MailIcon />
                    {t("text-send")}
                  </button>
                </div>
              ) : (
                ""
              )
            ) : (
              ""
            )}
            {messageData && messageData[0].userdetails?.blockUser == true ? (
              <p className="blocked" onClick={handleSubmitUser}>
                {t("text-blocked")}
              </p>
            ) : messageData &&
              messageData[0]?.userdetails?.blockbycurrentuser == true ? (
              <p className="blocked">{t("title-block-you")}</p>
            ) : (
              ""
            )}
          </div>
        </>
      ) : (
        ""
      )}

      {isSurvey && (
        <SurveyModal
          isSurvey={isSurvey}
          setIsSurvey={setIsSurvey}
          report={report}
          setReport={setReport}
          handleReportUser={handleReportUser}
        />
      )}

      {showLoginModal && (
        <LoginModal
          showLoginModal={showLoginModal}
          setShowLoginModal={setShowLoginModal}
          slugData={slugData}
          setIsSurvey={setIsSurvey}
        />
      )}

      {showReviewModal && (
        <ReviewModal
          showReviewModal={showReviewModal}
          setShowReviewModal={setShowReviewModal}
          handleDeleteMessageNotification={handleDeleteMessageNotification}
          setIsActive={setIsActive}
          isActive={isActive}
        />
      )}
    </>
  );
}
