export const TutorIcon = () => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="15" cy="15" r="15" fill="#fff" />
      <circle cx="15" cy="15" r="15" stroke="#fff" />
      <path
        d="M21.0659 13.008L15.209 10.3386C15.0556 10.268 14.8883 10.268 14.7489 10.3386L8.89202 13.008C8.69679 13.0928 8.57129 13.2905 8.57129 13.5024C8.57129 13.7143 8.71074 13.912 8.90596 14.0109L9.40798 14.251V16.2283C9.40798 16.4261 9.56137 16.5814 9.7566 16.5814C9.95183 16.5814 10.1052 16.4261 10.1052 16.2283V14.6323L10.9419 15.0843V17.4854C10.9419 17.5842 10.9698 17.669 11.0256 17.7537C11.0814 17.8385 12.3503 19.7593 14.9162 19.7593C17.5518 19.7593 18.765 17.8244 18.8207 17.7537C18.8626 17.6831 18.9044 17.5842 18.9044 17.4995V15.1832L21.1216 14.0391C21.3169 13.9402 21.4284 13.7425 21.4284 13.5306C21.4005 13.2905 21.2611 13.1069 21.0659 13.008ZM17.9143 17.33C17.6354 17.669 16.6872 18.7707 14.9162 18.7707C13.187 18.7707 12.1969 17.6831 11.9181 17.33V15.5928L14.7349 17.0475C14.8186 17.0899 14.9022 17.104 14.9859 17.104C15.0696 17.104 15.1672 17.0899 15.2509 17.0475L17.9143 15.6775V17.33ZM14.9859 15.9035L10.412 13.5589L14.9859 11.4685L19.5598 13.5589L14.9859 15.9035ZM10.1052 17.0758C10.2028 17.1746 10.2726 17.33 10.2726 17.4713C10.2726 17.6125 10.2168 17.7678 10.1052 17.8667C10.0076 17.9656 9.85421 18.0362 9.71477 18.0362C9.57532 18.0362 9.42192 17.9797 9.32431 17.8667C9.2267 17.7678 9.15697 17.6125 9.15697 17.4713C9.15697 17.33 9.21275 17.1746 9.32431 17.0758C9.42192 16.9769 9.57532 16.9063 9.71477 16.9063C9.85421 16.9063 9.99366 16.9628 10.1052 17.0758Z"
        fill="#7D2B8B"
      />
    </svg>
  );
};
