import React from "react";
import { useTranslation } from "react-i18next";
import Modal from "react-bootstrap/Modal";

import { SendReview } from "../../../components/common/SendReview";

export const ReviewModal = ({
  isReviewModal,
  setIsReviewModal,
  slugData,
  profile,
}) => {
  const { t } = useTranslation();

  return (
    <Modal show={isReviewModal} onHide={(e) => setIsReviewModal(false)}>
      <Modal.Body>
        <SendReview
          setrequest={setIsReviewModal}
          slugdata={slugData.id}
          username={profile.first_name + " " + profile.last_name}
        />
      </Modal.Body>
    </Modal>
  );
};
