import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import {
  ENG_LANGUAGE,
  MONTH_ENG_LANGUAGE,
  MONTH_SRB_LANGUAGE,
} from "../../../utils/variables";

export const JobRow = ({ data, handleDeleteElement, setView, slugData }) => {
  const { t, i18n } = useTranslation();
  const language = i18n.language;
  const navigate = useNavigate();

  const month =
    language === ENG_LANGUAGE ? MONTH_ENG_LANGUAGE : MONTH_SRB_LANGUAGE;

  const getRowClass = () => {
    if (data.read_status === 0) {
      return "unread";
    } else if (data.status === 0) {
      return "unread2";
    } else {
      return "close";
    }
  };

  return (
    <tr className={getRowClass()}>
      <td className="view-message dont-show">
        {data.status !== 0 ? (
          <>
            <input
              type="checkbox"
              className="mail-radio"
              name="chk"
              onChange={() => handleDeleteElement(data.id)}
            />
            <span className="circle"></span>
          </>
        ) : (
          <>
            <input type="checkbox" className="mail-radio" />
            <span className=""></span>
          </>
        )}
        <span
          onClick={() => {
            navigate(`/search-parents/${slugData.id}/${data.id}`);
            setView(data.id);
          }}
        >
          {data.status === 0 ? (
            <span className="new-tag"> {t("text-active")}</span>
          ) : data.status >= 0 ? (
            <span className="new-tag"> {t("text-closed")}</span>
          ) : (
            ""
          )}
          <b
            onClick={() => {
              navigate(`/search-parents/${slugData.id}/${data.id}`);
              setView(data.id);
            }}
          >
            {data.title.substr(0, 40)}
          </b>
          {" - " + data.description.substr(0, 40)}
        </span>
      </td>
      <td className="view-message text-right">
        {month[new Date(data.created_at).getMonth()] +
          " " +
          new Date(data.created_at).getDate()}
      </td>
    </tr>
  );
};

