import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Modal from "react-bootstrap/Modal";

import { SendReview } from "../SendReview";
import { ReviewAll } from "../ReviewAll";
import { ArrowLeftIcon } from "../../../assets/image/ArrowLeftIcon";
import { MONTH } from "../../../utils/variables";
import { getReviewNotification } from "../../../api/reviewApi";
import { ReviewNotificationItem } from "./ReviewNotificationItem";

export function ReviewDetail(props) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [reviewNotification, setReviewNotification] = useState([]);
  const [isCheck, setIsCheck] = useState(false);
  const [message, setMessage] = useState("");
  const [username, setUsername] = useState("");
  const [useId, setUserId] = useState(0);
  const [reviewModal, setShowReviewModal] = useState(false);

  const fetchReviewData = async () => {
    try {
      const result = await getReviewNotification(props.id);
      setReviewNotification([result.data.reviewview]);
      setMessage(result.data.currentUserName);
    } catch (error) {
      console.error("Error fetching review data:", error);
    }
  };

  useEffect(() => {
    fetchReviewData();
  }, []);

  return (
    <>
      {isCheck ? (
        <ReviewAll />
      ) : (
        <div class="main-header revumain">
          <button
            onClick={(e) => {
              navigate("/search-providers/new-reviews");
              props.setview("");
            }}
          >
            <ArrowLeftIcon />
            {t("text-back")}
          </button>

          {reviewNotification && reviewNotification[0] ? (
            <>
              <h2 className="border"></h2>
              <div className="detail_invit">
                <p style={{ fontSize: "16px", fontWeight: "600" }}>
                  <span className="date">
                    {new Date(
                      reviewNotification &&
                        reviewNotification[0] &&
                        reviewNotification[0].created_at
                    ).toLocaleDateString("en-US", { weekday: "short" })}
                    ,
                    {MONTH[
                      new Date(
                        reviewNotification &&
                          reviewNotification[0] &&
                          reviewNotification[0].created_at
                      ).getMonth()
                    ] +
                      " " +
                      new Date(
                        reviewNotification &&
                          reviewNotification[0] &&
                          reviewNotification[0].created_at
                      ).getDate()}
                    ,
                    {new Date(
                      reviewNotification &&
                        reviewNotification[0] &&
                        reviewNotification[0].created_at
                    ).getFullYear()}
                    ,
                    {new Date(
                      reviewNotification &&
                        reviewNotification[0] &&
                        reviewNotification[0].created_at
                    ).toLocaleTimeString([], {
                      hour: "2-digit",
                      minute: "2-digit",
                    })}
                  </span>
                  <Link
                    to={
                      reviewNotification[0].SenderUsertype == "parents"
                        ? "/profile-parents/" +
                          reviewNotification[0]?.sender_userid
                        : "/profile-provider/" +
                          reviewNotification[0]?.sender_userid
                    }
                  >
                    {reviewNotification &&
                      reviewNotification[0] &&
                      reviewNotification[0].SenderName}
                  </Link>
                  {t("left-review")}
                </p>
                <h2 style={{ marginTop: "8px" }}>
                  {t("text-dear")} {message + " "},
                </h2>
                <h2>{t("review-receive")}</h2>
              </div>
              <div class="right_side_section">
                <div class="looking_for_candidate">
                  {reviewNotification.map((data, index) => {
                    if (index <= 5) {
                      return (
                        <ReviewNotificationItem
                          key={data.id}
                          data={data}
                          setIsCheck={setIsCheck}
                          setUserId={setUserId}
                          setShowReviewModal={setShowReviewModal}
                          setUsername={setUsername}
                          t={t}
                        />
                      );
                    }
                  })}
                </div>
              </div>
            </>
          ) : (
            ""
          )}
        </div>
      )}
      {reviewModal ? (
        <Modal show={reviewModal} onHide={(e) => setShowReviewModal(false)}>
          <Modal.Body>
            <SendReview
              setrequest={setShowReviewModal}
              slugdata={useId}
              username={username}
            />
          </Modal.Body>
        </Modal>
      ) : (
        ""
      )}
    </>
  );
}
