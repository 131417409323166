import { create } from "zustand";

export const useSearchStore = create((set) => ({
  filter: JSON.parse(localStorage.getItem("filter")) || {},
  search: {
    jobcategory: "",
    keyword: "",
    city: JSON.parse(localStorage.getItem("filter"))?.city || "",
    zip: JSON.parse(localStorage.getItem("filter"))?.zip || "",
    candidatespeaks: "",
    withinweek: "",
    withinmonth: "",
    tutorintrestedin: "",
    tutorintrestedin1: "",
    tutorintrestedin2: "",
    rates: "",
    tutorperhrrate: "",
    tutorexp: "",
    childneedcareno: "",
    preferredageofprovider: "",
    workingabroad: "",
    childsocialneed: "",
    distance: JSON.parse(localStorage.getItem("filter"))?.distance || "",
    carorlicence: "",
    backgroundstatus: "",
    nanynewbornexp: "",
  },
  languageSpeak: {
    English: "",
    Serbian: "",
    Mathematics: "",
    Physics: "",
    Chemistry: "",
    Online: "",
  },
  setFilter: (newFilter) =>
    set((state) => ({ filter: { ...state.filter, ...newFilter } })),
  setSearch: (newSearch) =>
    set((state) => ({ search: { ...state.search, ...newSearch } })),
  setLanguageSpeak: (newLanguageSpeak) =>
    set((state) => ({
      languageSpeak: { ...state.languageSpeak, ...newLanguageSpeak },
    })),
}));
