import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { getReceivedLanguage } from "../../../utils/helperFunctions";
import {
  deleteVisitMyProfile,
  getVisitDataParams,
} from "../../../api/visitApi";
import { getCountryDetails } from "../../../api/countryApi";
import VisitItem from "./VisitItem";

export function WhoVisitedContent() {
  const handleReceivedType = () => {
    return localStorage.getItem("user_type") === "provider"
        ? "Parents"
        : "Provider";
  };

  const { t, i18n } = useTranslation();
  const language = i18n.language;

  const [visitData, setVisitData] = useState([]);
  const [prevPage, setPrevPage] = useState(10);
  const [page, setPage] = useState(prevPage);
  const [isActive, setIsActive] = useState(false);
  const [received, setReceived] = useState(handleReceivedType());
  const [profile, setProfile] = useState({});
  const [pages, setPages] = useState(0);

  const dataShow = (page / prevPage - 1) * prevPage;
  const receivedLand = getReceivedLanguage(received, language);

  const fetchWhoIVisitData = async (profileSearch) => {
    setVisitData([]);
    try {
      const result = await getVisitDataParams(profileSearch || received);
      setVisitData(result.data.whoivisited);
    } catch (error) {
      console.error("Error fetching visit data:", error);
    }
  };

  const fetchDeleteVisitMyProfile = async (id) => {
    try {
      const result = await deleteVisitMyProfile(id);
      console.log(result);
      fetchWhoIVisitData();
      setVisitData([]);
    } catch (error) {
      console.error("Error deleting visit:", error);
    }
  };

  const fetchProfileDetails = async () => {
    try {
      const result = await getCountryDetails();
      setProfile(result.data);
    } catch (error) {
      console.error("Error fetching details:", error);
    }
  };

  useEffect(() => {
    fetchWhoIVisitData();
    fetchProfileDetails();

    window.scrollTo({ top: 200, behavior: "smooth" });
  }, [page, prevPage]);

  return (
    <>
      <div class="main-header">
        <h2 className="border">{t("Who I Visited")}</h2>
        <div class="mail-header-bar">
          <p>
            {visitData.length} {t("profiles")}
          </p>
          <div class="btn-group flex">
            {t("text-refine")}
            <div className="select">
              <label onClick={(e) => setIsActive(!isActive)}>
                {t(receivedLand)}
                <span>
                  <img src="/images/done_a.svg" />
                </span>
              </label>
              {isActive ? (
                <ul>
                  <li
                    onClick={(e) => {
                      fetchWhoIVisitData("Parents");
                      setReceived(t("Parents"));
                      setIsActive(!isActive);
                    }}
                  >
                    {t("Parents")}
                  </li>
                  <li
                    onClick={(e) => {
                      fetchWhoIVisitData("Provider");
                      setReceived(t("Provider"));
                      setIsActive(!isActive);
                    }}
                  >
                    {t("Provider")}
                  </li>
                </ul>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
        <div class="right_side_section">
          <div class="looking_for_candidate">
            <div class="interested_fam">
              <div class="interested_fam_boxs">
                {visitData?.map((data, index2) => {
                  console.log(data);
                  if (index2 < page && dataShow <= index2) {
                    return (
                      <VisitItem
                        key={data.visitor_userid}
                        data={data}
                        fetchDeleteVisitMyProfile={fetchDeleteVisitMyProfile}
                        received={received}
                        profile={profile}
                      />
                    );
                  }
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer_pagination pagi_post">
        <div class="btn-group">
          
          {t("result-page")}
          <select
            onChange={(e) => {
              setPrevPage(parseInt(e.target.value));
              setPage(parseInt(e.target.value));
            }}
          >
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="30">30</option>
            <option value="40">40</option>
            <option value="50">50</option>
          </select>
        </div>
        <div className="pagination">
          <span className="curserpage" onClick={(e) => setPage(prevPage)}>
            {"<<"}
          </span>
          <span
            className="curserpage"
            onClick={(e) =>
              setPage(
                visitData.length > 0 && page > prevPage
                  ? page - prevPage
                  : prevPage
              )
            }
          >
            {"<"}
          </span>

          {visitData?.map((_, index) => {
            if (visitData.length > pages) {
              setPages((prevState) => prevState + prevPage);

              return (
                <span
                  onClick={(e) => setPage((index + 1) * prevPage)}
                  className={page == pages ? "active" : ""}
                >
                  {index + 1}
                </span>
              );
            }
          })}
          <span
            className="curserpage"
            onClick={(e) =>
              setPage(visitData.length > page ? page + prevPage : page)
            }
          >
            {">"}
          </span>
          <span className="curserpage" onClick={(e) => setPage(pages)}>
            {">>"}
          </span>
        </div>
      </div>
    </>
  );
}
