import React from "react";

export const PlusIcon = ({ color }) => {
  const generalColor = color ? color : "#B7B7B7";

  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill={generalColor}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.99 0C4.47 0 0 4.48 0 10C0 15.52 4.47 20 9.99 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 9.99 0Z"
        fill={generalColor}
      />
      <rect x="9" y="4" width="2" height="12" rx="1" fill="#fff" />
      <rect
        x="16"
        y="9"
        width="2"
        height="12"
        rx="1"
        transform="rotate(90 16 9)"
        fill="#fff"
      />
    </svg>
  );
};
