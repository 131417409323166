import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { Footer } from "../../components/common/Footer";
import { Header } from "../../components/common/Header";

import { api } from "../../urls";
import { fetchCareerData } from "../../api";

export function CareerPage() {
  const { t } = useTranslation();

  const [careerData, setCareerData] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadCareerData = async () => {
      try {
        const data = await fetchCareerData();
        setCareerData({
          title: data.title,
          description: data.description,
          image: data.image,
        });
      } catch (error) {
        console.error("Error loading career data:", error);
      } finally {
        setLoading(false);
      }
    };

    if (loading) {
      loadCareerData();
    }
  }, [loading]);

  return (
    <>
      <Header />
      <div className="container-fluid">
        <div className="container">
          <div className="career">
            <h2>{t("career.title", { defaultValue: careerData.title })}</h2>
            <p>{t("career.description", { defaultValue: careerData.description })}</p>
            <img src={api + "/assets/cms/" + careerData.image} alt="Career" />
          </div>
        </div>
      </div>
      <Footer
       />
    </>
  );
}
