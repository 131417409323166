import React from 'react';
import { MONTH } from '../../../utils/variables';

export const TrashTableRow = ({ data, handleDeleteSelect }) => {
  return (
    <tr className={data.read_status === 1 ? "unread" : ""}>
      <td className="view-message dont-show">
        <input
          type="checkbox"
          className="mail-radio"
          name="chk"
          onChange={() => handleDeleteSelect(data.msg_id)}
        />
        <span className="circle"></span>
        <img
          className="dask_img"
          src={"https://admin.stage.mysenscare.com/assets/images/users/" + data.Image}
          alt="User"
        />
        <p>
          <strong style={{ marginBottom: "8px", display: "block" }}>
            <img
              className="mob_img"
              src={"https://admin.stage.mysenscare.com/assets/images/users/" + data.Image}
              alt="User"
            />
            {data.SenderUserName ? data.SenderUserName : ""}
            <span>
              {new Date(data.created_at).toLocaleDateString("en-US", { weekday: "short" })},
              {MONTH[new Date(data.created_at).getMonth()] + " " + new Date(data.created_at).getDate()}
              , {new Date(data.created_at).getFullYear()},
              {new Date(data.created_at).toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" })}
            </span>
          </strong>
          {data.message}
        </p>
      </td>
    </tr>
  );
};
