import React from "react";
import { useTranslation } from "react-i18next";

import { BanCarIcon } from "../../assets/image/BanCarIcon";
import { CarIcon } from "../../assets/image/CarIcon";
import { BanPlaneIcon } from "../../assets/image/BanPlaneIcon";
import { PlaneIcon } from "../../assets/image/PlaneIcon";
import { BanHouseIcon } from "../../assets/image/BanHouseIcon";
import { HouseIcon } from "../../assets/image/HouseIcon";
import { BanCookIcon } from "../../assets/image/BanCookIcon";
import { CookIcon } from "../../assets/image/CookIcon";
import { DoneIcon } from "../../assets/image/DoneIcon";
import { ThingIcon } from "../../assets/image/ThingIcon";

export const ProfileLocalInfo = ({ oralSpeak, profile, habit }) => {
  const { t } = useTranslation();

  return (
    <div className="editkids editabout editinfo">
      <div className="nannyediy">
        {oralSpeak.English ||
        oralSpeak.Serbian ||
        oralSpeak.Spanish ||
        oralSpeak.French ||
        oralSpeak.Chinese ||
        oralSpeak.German ||
        oralSpeak.Italian ||
        oralSpeak.Other ? (
          <h4>{t("Preferred foreign language")}</h4>
        ) : (
          ""
        )}

        <label>
          {oralSpeak.English && oralSpeak.English != "" ? (
            <span>
              <strong>
                <DoneIcon />
                {t("english")}
              </strong>
            </span>
          ) : (
            ""
          )}
          {oralSpeak.Spanish && oralSpeak.Spanish != "" ? (
            <span>
              <strong>
                <DoneIcon />
                {t("spanish")}
              </strong>
            </span>
          ) : (
            ""
          )}
          {oralSpeak.French && oralSpeak.French != "" ? (
            <span>
              <strong>
                <DoneIcon />
                {t("french")}
              </strong>
            </span>
          ) : (
            ""
          )}
          {oralSpeak.Chinese && oralSpeak.Chinese != "" ? (
            <span>
              <strong>
                <DoneIcon />
                {t("chinese")}
              </strong>
            </span>
          ) : (
            ""
          )}
          {oralSpeak.German && oralSpeak.German != "" ? (
            <span>
              <strong>
                <DoneIcon />
                {t("german")}
              </strong>
            </span>
          ) : (
            ""
          )}
          {oralSpeak.Italian && oralSpeak.Italian != "" ? (
            <span>
              <strong>
                <DoneIcon />
                {t("italian")}
              </strong>
            </span>
          ) : (
            ""
          )}
          {oralSpeak.Other && oralSpeak.Other != "" ? (
            <span>
              <strong>
                <DoneIcon />
                {t("text-other")}
              </strong>
            </span>
          ) : (
            ""
          )}
        </label>

        <br />
        {profile.service_type &&
          profile.service_type.tab1 &&
          Object.keys(profile.service_type).length == 1 && (
            <div className="icon">
              <ul>
                <li>
                  {t("child-transportation")}
                  <div className="icons">
                    {habit.licence == "false" ? (
                      <BanCarIcon
                        color={"#7D2B8B"}
                        colorCircle={"#7D2B8B"}
                        colorPath={"#fff"}
                      />
                    ) : (
                      <CarIcon
                        color={"#7D2B8B"}
                        colorCircle={"#7D2B8B"}
                        colorPath={"#fff"}
                      />
                    )}
                  </div>
                </li>
                <li>
                  {t("occasional-traveling")}
                  <div className="icons">
                    {habit.family == "false" ? (
                      <BanPlaneIcon
                        color={"#7D2B8B"}
                        colorCircle={"#7D2B8B"}
                        colorPath={"#fff"}
                      />
                    ) : (
                      <PlaneIcon
                        color={"#7D2B8B"}
                        colorCircle={"#7D2B8B"}
                        colorPath={"#fff"}
                      />
                    )}
                  </div>
                </li>
                <li>
                  {t("light-housework")}
                  <div className="icons">
                    {habit.housework == "false" ? (
                      <BanHouseIcon
                        color={"#7D2B8B"}
                        colorPath={"#fff"}
                        colorCircle={"#7D2B8B"}
                      />
                    ) : (
                      <HouseIcon
                        color={"#7D2B8B"}
                        colorPath={"#fff"}
                        colorCircle={"#7D2B8B"}
                      />
                    )}
                  </div>
                </li>
                <li>
                  {t("cooking-kids")}
                  <div className="icons">
                    {habit.kids == "false" ? (
                      <BanCookIcon
                        color={"#7D2B8B"}
                        colorCircle={"#7D2B8B"}
                        colorPath={"#fff"}
                      />
                    ) : (
                      <CookIcon
                        color={"#7D2B8B"}
                        colorCircle={"#7D2B8B"}
                        colorPath={"#fff"}
                      />
                    )}
                  </div>
                </li>
              </ul>
            </div>
          )}
        <br />
        <div className="abrod">
          <label className="abrodd">
            <ThingIcon />
            {t("Need a service abroad")}
            <span>{t(profile.workingabroad)}</span>
          </label>
          <br />
          {profile.workingabroad == "Yes" && (
            <>
              <label>
                <span className="half">{t("preffer-country")} </span>
                <span>
                  <strong>{profile.preferredcountry}</strong>
                </span>
              </label>
              <label>
                <span className="half">{t("preffer-city")} </span>
                <span>
                  <strong>{profile.preferredcity}</strong>
                </span>
              </label>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
