import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { PersonIcon } from "../../../assets/image/PersonIcon";
import { DandruffIcon } from "../../../assets/image/DandruffIcon";

export const ServiceSection = () => {
  const { t } = useTranslation();

  return (
    <>
      <div className="need_service">
        <Link to="/search-providers">
          <PersonIcon />
          <span>{t("service")}</span>
        </Link>
        <p>{t("start")}</p>
      </div>
      <div className="need_service need_job">
        <Link to="/search-parents">
          <DandruffIcon />
          <span>{t("job")}</span>
        </Link>
        <p>{t("create-profile-free")}</p>
      </div>
    </>
  );
};
