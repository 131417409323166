import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { useInterviewStore } from "../../../store/useInterviewStore";
import {
  ENG_LANGUAGE,
  MONTH_ENG_LANGUAGE,
  MONTH_SRB_LANGUAGE,
} from "../../../utils/variables";

export const JobApplicationRow = ({
  data,
  handleDeleteSelect,
  slugData,
}) => {
  const { t, i18n } = useTranslation();
  const language = i18n.language;
  const navigate = useNavigate();

  const month =
    language === ENG_LANGUAGE ? MONTH_ENG_LANGUAGE : MONTH_SRB_LANGUAGE;

  const { setView } = useInterviewStore();

  const isUnread =
    data.read_status == 0 ||
    (new Date(data.created_at).getDate() > new Date().getDate()
      ? new Date(data.created_at).getMonth() > new Date().getMonth()
      : new Date(data.created_at).getDate() >= new Date().getDate() - 7);
  const rowClass =
    data.jobStatus != 1
      ? isUnread
        ? "unread"
        : data.status == 0
        ? "unread2"
        : t("text-close")
      : t("text-close");

  return (
    <tr className={rowClass}>
      <td className="view-message dont-show">
        <input
          type="checkbox"
          className="mail-radio"
          name="chk"
          onChange={() => handleDeleteSelect(data.id)}
        />
        <span className="circle"></span>
        <span
          onClick={() => {
            navigate("/search-parents/" + slugData.id + "/" + data.id);
            setView(data.id);
          }}
        >
          {data.jobStatus != 1 ? (
            isUnread ? (
              <span className="new-tag"> {t("text-new-upper")}</span>
            ) : data.status == 0 ? (
              <span className="new-tag"> {t("text-active")}</span>
            ) : data.status >= 0 ? (
              <span className="new-tag"> {t("text-closed")}</span>
            ) : (
              ""
            )
          ) : (
            <span className="new-tag"> {t("text-closed")}</span>
          )}
          <span
            className="username-text"
            onClick={() => {
              navigate("/search-parents/" + slugData.id + "/" + data.id);
              setView(data.id);
            }}
          >
            {data.Appliedusername}
          </span>
          {t("just-applied-you")}
          <span className="username-text"> {t("job-post-under")} </span>
        </span>
      </td>
      <td className="view-message text-right">
        {month[new Date(data.created_at).getMonth()] +
          " " +
          new Date(data.created_at).getDate()}
      </td>
    </tr>
  );
};
