import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { AGENSY_CONTACTS, TOOLS_ITEMS } from "../../../utils/variables";


export const SafetySection = () => {
  const { t } = useTranslation();

  return (
    <div className="make_safe">
      <h3>{t("make")}</h3>
      <div className="left">
        <h4>
          {t("tools")} <span>({t("recommended")})</span>
        </h4>
        <ul className="first">
          {TOOLS_ITEMS.map((tool, index) => (
            <li key={index}>{t(tool.text)}</li>
          ))}
        </ul>
        <ul className="second">
          {TOOLS_ITEMS.map((tool, index) => (
            <li key={index}>
              <img src={tool.img} alt={t(tool.text)} />
            </li>
          ))}
        </ul>
      </div>
      <div className="middel">
        <img src="./images/make_banner.svg" alt="Make Safe" />
      </div>
      <div className="right left">
        <h4>{t("title-agency")}</h4>
        <ul className="second">
          {AGENSY_CONTACTS.map((contact, index) => (
            <li key={index}>
              <img src={contact.img} alt={t(contact.text)} />
            </li>
          ))}
        </ul>
        <ul className="first">
          {AGENSY_CONTACTS.map((contact, index) => (
            <li key={index}>{t(contact.text)}</li>
          ))}
        </ul>
      </div>
      <p>
        {t("detail")} <Link to="/safety-center">{t("safety-center")}</Link>
      </p>
    </div>
  );
};
