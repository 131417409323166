import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { fetchCookiePolicy } from "../../api";
import { Footer } from "../../components/common/Footer";
import { Header } from "../../components/common/Header";

export function CookiePolicyPage() {
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(true);
  const [dataCookie, setDataCookie] = useState({});

  useEffect(() => {
    const loadData = async () => {
      try {
        const data = await fetchCookiePolicy();
        setDataCookie(data);
      } catch (error) {
        console.error("Error loading data:", error);
      }
    };

    if (isLoading) {
      loadData();
      setIsLoading(false);
    }
  }, [isLoading]);

  return (
    <>
      <Header />
      <div className="container-fluid">
        <div className="container">
          <div className="contact privacy">
            <h2>{t("cookie-policy.title", { defaultValue: dataCookie.title })}</h2>
            <p>
              <em>{t("title-last-version")}</em>
            </p>
            <div
              dangerouslySetInnerHTML={{
                __html: t("cookie-policy.description", {
                  defaultValue: dataCookie.description,
                }),
              }}
              className="privacycon"
            />

            <br />
            <br />
            <br />
            <div className="category_use">
              <ul>
                <li>{t("category-use")}</li>
                <li>{t("text-example")}</li>
              </ul>
              <ul>
                <li>
                  <h4>{t("text-preferences")}</h4>
                  <p>{t("preference-cookie")}</p>
                </li>
                <li>
                  <h4>{t("authentication-security")}</h4>
                  <p>
                    <strong>{t("text-authentication")}</strong>
                    <br />
                    {t("text-account-have")}
                    <br />
                    <br />
                    <br />
                    <strong>{t("text-security")}</strong>
                    <br />
                    {t("post-security-help")}
                  </p>
                </li>
                <li>
                  <h4>{t("text-performance")}</h4>
                  <p>{t("performance-cookie")}</p>
                </li>
                <li>
                  <h4>{t("text-analytics")}</h4>
                  <p>{t("cookies-information")}</p>
                </li>
              </ul>
            </div>
            <div
              dangerouslySetInnerHTML={{
                __html: t("cookie-policy.description-second", {
                  defaultValue: dataCookie.description2,
                }),
              }}
              className="privacycon"
            />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}