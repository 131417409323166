import { create } from "zustand";

export const useCategoryStore = create((set) => ({
  selectCategory: {
    data1: "",
    data2: "",
    data3: "",
    data4: "",
  },
  setSelectCategory: (selectCategory) =>
    set((state) => ({
      selectCategory: { ...state.selectCategory, ...selectCategory },
    })),
  jobCategory: {
    data1: "",
    data2: "",
    data3: "",
    data4: "",
  },
  category: [],
  setCategory: (category) => set({ category }),
  setJobCategory: (jobCategory) =>
    set((state) => ({ jobCategory: { ...state.jobCategory, ...jobCategory } })),
  isCheck: true,
  setIsCheck: (isCheck) => set({ isCheck }),
}));
