export const ToggleIcon = () => {
  return (
    <svg
      width="32"
      height="16"
      viewBox="0 0 32 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24 0H8C3.584 0 0 3.584 0 8C0 12.416 3.584 16 8 16H24C28.416 16 32 12.416 32 8C32 3.584 28.416 0 24 0Z"
        fill="#A98D4B"
      />
      <path
        d="M24.7995 13.5984C21.7008 13.5984 19.1995 11.0971 19.1995 7.99844C19.1995 4.89977 21.7008 2.39844 24.7995 2.39844C27.8981 2.39844 30.3995 4.89977 30.3995 7.99844C30.3995 11.0971 27.8981 13.5984 24.7995 13.5984Z"
        fill="white"
      />
    </svg>
  );
};
