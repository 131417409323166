export const CardPersonIcon = () => {
  return (
    <svg
      width="30"
      height="24"
      viewBox="0 0 30 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M27 3H15L12.885 0.885C12.315 0.315 11.55 0 10.755 0H3C1.35 0 0.015 1.35 0.015 3L0 21C0 22.65 1.35 24 3 24H27C28.65 24 30 22.65 30 21V6C30 4.35 28.65 3 27 3ZM19.5 7.5C21.15 7.5 22.5 8.85 22.5 10.5C22.5 12.15 21.15 13.5 19.5 13.5C17.85 13.5 16.5 12.15 16.5 10.5C16.5 8.85 17.85 7.5 19.5 7.5ZM13.5 18V19.5H25.5V18C25.5 16.005 21.495 15 19.5 15C17.505 15 13.5 16.005 13.5 18Z"
        fill="#B7B7B7"
      />
    </svg>
  );
};
