import React from "react";
import { useTranslation } from "react-i18next";

export function LandingListItem({ text, secondaryText  }) {
  const { t } = useTranslation();
  
  return (
    <li>
      <img src="./images/thanks_right.svg" alt="right arrow" />
      <span>{t(text)} < br/>
      {secondaryText  && `(${t(secondaryText)}`}
      </span>
    </li>
  );
}