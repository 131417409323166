import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { Footer } from "../../components/common/Footer";
import { Header } from "../../components/common/Header";

import { fetchPrivacyPolicy } from "../../api";

export function PrivacyPolicyPage() {
  const { t } = useTranslation();

  const [policyData, setPolicyData] = useState({});

  useEffect(() => {
    const fetchPolicyData = async () => {
      try {
        const result = await fetchPrivacyPolicy();
        setPolicyData(result.data);
      } catch (error) {
        console.error("Error fetching privacy policy:", error);
      }
    };

    fetchPolicyData();
  }, []);

  return (
    <>
      <Header />
      <div className="container-fluid">
        <div className="container">
          <div className="contact privacy">
            {policyData.title && (
              <h2>{t("privacy-policy.title", { defaultValue: policyData.title })}</h2>
            )}

            {policyData.description && (
              <div
                dangerouslySetInnerHTML={{
                  __html: t("privacy-policy.description", {
                    defaultValue: policyData.description,
                  }),
                }}
                className="privacycon"
              />
            )}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
