import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { StarIcon } from "../../../assets/image/StarIcon";
import { WhiteStarIcon } from "../../../assets/image/WhiteStarIcon";
import { SocialFacebook } from "../../common/SocialFacebook";
import { InterviewItem } from "./InterviewItem";

export const InterviewSection = ({
  rate,
  data,
  providerData,
  hire,
  handleSubmit,
  setShowReviewModal,
  profile,
  setIsElement,
  handleElementById,
  index,
}) => {
  const { t } = useTranslation();

  const getAge = (dob) => {
    if (!dob) return "";
    const birthYear = parseInt(
      dob.substr(dob.lastIndexOf("\\") + 1).split("-")[0]
    );
    return new Date().getFullYear() - birthYear;
  };

  const renderStars = (avg) => {
    const filledStars = Array.from({ length: avg }, (_, index) => (
      <StarIcon key={index} />
    ));
    const emptyStars = Array.from({ length: 5 - avg }, (_, index) => (
      <WhiteStarIcon key={index} />
    ));
    return [...filledStars, ...emptyStars];
  };

  return (
    <div className="second_sec">
      <div className="heading">
        <h3>
          {rate?.jobStatus?.JobStatus === 1 && (
            <span className="new-tag3">{t("text-closed")}</span>
          )}
          {data.profile_headline || ""}
        </h3>

        {providerData &&
          providerData[0]?.status === 1 &&
          rate?.jobStatus?.JobStatus !== 1 && (
            <button
              style={
                hire !== "" || data.hire_status === 1 ? { opacity: "0.5" } : {}
              }
              onClick={() => handleSubmit(data.provider_id)}
              disabled={hire !== "" || data.hire_status === 1}
            >
              {hire !== "" || data.hire_status === 1 ? t("text-hired") : t("text-hire")}
            </button>
          )}
        {data.plateformsocialmedia === "Yes" && (
          <SocialFacebook
            link={
              window.location.origin + "/profile-provider/" + data.provider_id
            }
          />
        )}
      </div>
      <div className="post_general">
        <h6>
          <Link to={"/profile-provider/" + providerData[0]?.UID}>
            {data.first_name ? `${data.first_name} ${data.last_name} ` : ""}
          </Link>
          ({getAge(data.dob)})
        </h6>
        <p
          onClick={() => {
            if (!rate.checkreviewstatus) {
              setShowReviewModal(true);
            }
          }}
        >
          {rate.reviewAvg >= 0 && (
            <>
              {renderStars(rate.reviewAvg)}
              <span> ({rate.reviewcount})</span>
            </>
          )}
        </p>
      </div>

      <InterviewItem data={data} profile={profile} />

      <p id={"half" + index}>
        {data.about ? data.about.substr(0, 100) : ""}
        {data.about && data.about.length > 100 && (
          <span
            onClick={() => {
              setIsElement(true);
              handleElementById(
                "half" + index,
                "full" + index,
                "plink" + index
              );
            }}
          >
            {t("text-more")}
          </span>
        )}
      </p>

      <p id={"full" + index} className="hide">
        {data.about}
        <span
          onClick={() =>
            handleElementById("full" + index, "half" + index, "plink" + index)
          }
        >
          {t("text-less")}
        </span>
      </p>
    </div>
  );
};
