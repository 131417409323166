import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Modal from "react-bootstrap/Modal";

export const SafetyModal = ({
  isSafetyModal,
  setIsSafetyModal,
  setIsMembershipModal,
}) => {
  const { t } = useTranslation();

  return (
    <Modal show={isSafetyModal} onHide={(e) => setIsSafetyModal(false)}>
      <Modal.Body>
        <div className="promocode_content cancelmembership reportthanks">
          <div className="">
            <p>
              <strong>{t("thank-message")}</strong>
            </p>
            <br />
            <p className={"second"}>
              <b>{t("senscare-safety-seriously")} </b>
            </p>
            <br />
            <p className="giveus">
              {t("meantime-visit-our")}
              <Link to="/safety-center">{t("safety-center")}</Link>
              {t("text-and")} <Link to="/faq">{t("text-faq")}</Link>
              {t("for-more-info")}
            </p>

            <div className="button text-center">
              <div className="pull-right">
                <button
                  className="btn"
                  onClick={(e) => {
                    setIsMembershipModal(false);
                    setIsSafetyModal();
                  }}
                >
                  {t("text-close-up")}
                </button>
              </div>
            </div>
            <br />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
