import create from 'zustand';

export const useInterviewStore = create((set) => ({
  interview: [],
  recived: "",
  view: "",
  refine: {
    threedays: "",
    withinweek: "",
    twofourday: "",
    withinmonth: "",
  },

  
  setInterview: (newInterview) => set({ interview: newInterview }),
  setRecived: (newRecived) => set({ recived: newRecived }),
  setView: (newView) => set({ view: newView }),
  setRefine: (newRefine) => set({ refine: newRefine }),

  resetInterviewState: () =>
  set({
    interview: [],
    recived: "",
    view: "",
    refine: {
      threedays: "",
      withinweek: "",
      twofourday: "",
      withinmonth: "",
    },
  }),
}));
