import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useParams } from "react-router-dom";
import Modal from "react-bootstrap/Modal";

import { api } from "../../../urls";

import { MessageContent } from "../MessageContent";
import { RaffleIcon } from "../../../assets/image/RaffleIcon";
import { RebootIcon } from "../../../assets/image/RebootIcon";
import { ArrowLeftIcon } from "../../../assets/image/ArrowLeftIcon";
import { CrownIcon } from "../../../assets/image/CrownIcon";
import { MessageIcon } from "../../../assets/image/MessageIcon";
import {
  ENG_LANGUAGE,
  MONTH_ENG_LANGUAGE,
  MONTH_SRB_LANGUAGE,
} from "../../../utils/variables";
import {
  fetchDeleteMessageNotifications,
  fetchInboxNotification,
} from "../../../api/notificationApi";

export function MessageList() {
  const { t, i18n } = useTranslation();
  const language = i18n.language;
  const navigate = useNavigate();

  const [isCheck, setIsCheck] = useState(true);
  const [messageData, setMessageData] = useState([]);

  const [view, setView] = useState("");
  const [deleteList, setDeleteList] = useState([]);
  const [recived, setRecived] = useState("");
  const [isActive, setIsActive] = useState(false);
  const [update, setUpdate] = useState("");
  const [showReviewModal, setShowReviewModal] = useState(false);
  const [refine, setRefine] = useState({
    weekly: "",
    monthly: "",
    unread: "",
  });

  const slugData = useParams();
  let data = slugData.name;
  const month =
    language === ENG_LANGUAGE ? MONTH_ENG_LANGUAGE : MONTH_SRB_LANGUAGE;

  const fetchMessageData = async () => {
    setMessageData([]);
    try {
      const result = await fetchInboxNotification(refine);
      setMessageData(result.data.getallNotifications);
    } catch (error) {
      console.error("Error fetching message data:", error);
    }
  };

  useEffect(() => {
    if (isCheck) {
      fetchMessageData();
      setIsCheck(false);
    }

    console.log();
    setView(data ? data : "");
  }, [isCheck, messageData, deleteList, refine, data, slugData]);
  useEffect(() => {
    if (window.innerWidth < 768) {
      setRecived("text-refine");
    }
  }, []);

  useEffect(() => {
    if (
      localStorage.getItem("message") &&
      localStorage.getItem("message") != ""
    ) {
      setView(localStorage.getItem("message"));
    }
  }, []);

  const handleSelectElement = () => {
    const ele = document.getElementsByName("chk");
    messageData.map((data) => {
      console.log(data, deleteList);
      deleteList.push(data.id);
    });
    for (let i = 0; i < ele.length; i++) {
      if (ele[i].type == "checkbox") ele[i].checked = true;
    }
  };

  const handleDeselectElement = () => {
    const ele = document.getElementsByName("chk");
    for (let i = 0; i < ele.length; i++) {
      if (ele[i].type == "checkbox") ele[i].checked = false;
    }
    setDeleteList([]);
  };

  const handleDeleteSelect = (a) => {
    let sum = false;
    deleteList.map((e) => {
      if (e == a) {
        sum = true;
        const index = deleteList.indexOf(a);
        if (index > -1) {
          deleteList.splice(index, 1);
        }
      }
    });
    if (sum == false) {
      deleteList.push(a);
    }
    setMessageData(messageData);
  };

  const handleDeleteMessage = async () => {
    try {
      await fetchDeleteMessageNotifications(deleteList);
      fetchMessageData();
      setDeleteList([]);
    } catch (error) {
      console.error("Error deleting message notifications:", error);
    }
  };

  return (
    <div>
      {view == "" ? (
        <>
          <div class="main-header">
            <h2 className="border">{t("text-inbox-up")}</h2>
            <div class="mail-header-bar">
              <p>
                {messageData.length} {t("text-messages")}
              </p>
              <div class="btn-group flex">
                {t("text-refine")}
                <div className="select">
                  <label onClick={(e) => setIsActive(!isActive)}>
                    {t(recived)}
                    <span>
                      <img src="/images/done_a.svg" />
                    </span>
                  </label>
                  {isActive && (
                    <ul>
                      <li
                        onClick={(e) => {
                          setRecived(t("text-all"));
                          setRefine({
                            weekly: "",
                            monthly: "",
                            unread: "",
                          });
                          setIsCheck(true);
                          setIsActive(!isActive);
                        }}
                      >
                        {t("text-all")}
                      </li>
                      <li
                        onClick={(e) => {
                          setRecived(t("days-7"));
                          setRefine({
                            weekly: true,
                            monthly: "",
                            unread: "",
                          });
                          setIsCheck(true);
                          setIsActive(!isActive);
                        }}
                      >
                        {t("days-7")}
                      </li>
                      <li
                        onClick={(e) => {
                          setRecived(t("days-30"));
                          setRefine({
                            weekly: "",
                            monthly: true,
                            unread: "",
                          });
                          setIsCheck(true);
                          setIsActive(!isActive);
                        }}
                      >
                        {t("days-30")}
                      </li>
                      <li
                        onClick={(e) => {
                          setRecived("Unread");
                          setRefine({
                            weekly: "",
                            monthly: "",
                            unread: true,
                          });
                          setIsActive(!isActive);
                          setIsCheck(true);
                        }}
                      >
                        {t("text-unread-up")}
                      </li>
                    </ul>
                  )}
                </div>
              </div>
            </div>
            <div class="mail-option">
              <div class="chk-all">
                <input
                  type="checkbox"
                  class="mail-radio mail-group-radio"
                  name="chk"
                  id="checkbox_id"
                  onClick={(e) => {
                    if (e.target.checked) {
                      handleSelectElement();
                    } else {
                      handleDeselectElement();
                    }
                  }}
                />
                <span className="circle"></span>
                <label for="checkbox_id">{t("select-all")}</label>
              </div>
              <div class="right-side-btn">
                <div
                  class="delete-btn"
                  onClick={(e) => setShowReviewModal(true)}
                >
                  <a href="#">
                    <RaffleIcon />
                    <span> {t("text-delete")}</span>
                  </a>
                </div>
                <div class="refresh-btn" onClick={(e) => fetchMessageData()}>
                  <a href="#">
                    <RebootIcon />
                  </a>
                  <span>{t("text-refresh")}</span>
                </div>
              </div>
            </div>
          </div>
          {messageData[0] ? (
            <table class="table table-inbox table-hover hovertable message_list">
              <tbody>
                {messageData.map((data) => {
                  return (
                    <tr class={data.readStatuss == 0 ? "unread" : ""}>
                      <td class="view-message  dont-show">
                        <input
                          type="checkbox"
                          class="mail-radio"
                          name="chk"
                          onChange={(e) => handleDeleteSelect(data.msg_id)}
                        />
                        <span className="circle"></span>
                        <img
                          className="dask_img"
                          src={api + "/assets/images/users/" + data.Image}
                        />
                        <p
                          onClick={(e) => {
                            navigate(
                              "/search-providers/" +
                                slugData.id +
                                "/" +
                                data.sender_id
                            );
                            setView(data.sender_id);
                          }}
                        >
                          <strong
                            style={{ marginBottom: "8px", display: "block" }}
                          >
                            <img
                              className="mob_img"
                              src={api + "/assets/images/users/" + data.Image}
                            />
                            {data.SenderUserName != null
                              ? data.SenderUserName
                              : ""}
                            <span>
                              {new Date(data.updated_at).toLocaleDateString(
                                "en-US",
                                { weekday: "short" }
                              )}
                              ,
                              {month[new Date(data.updated_at).getMonth()] +
                                " " +
                                new Date(data.updated_at).getDate()}
                              , {new Date(data.updated_at).getFullYear()},
                              {new Date(data.updated_at).toLocaleTimeString(
                                [],
                                { hour: "2-digit", minute: "2-digit" }
                              )}
                            </span>
                          </strong>
               
                          {data.message}
                        </p>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          ) : (
            <div className="noresult">
              <h3>{t("messages-display")}</h3>
              <MessageIcon />
            </div>
          )}
          {update != "" ? (
            ""
          ) : messageData[0] ? (
            <div className="upgared">
              <p>{t("messages-upgrade-connect")}</p>
              <Link to={""}>
                <CrownIcon />
                {t(" Upgrade now")}
              </Link>
            </div>
          ) : (
            ""
          )}
        </>
      ) : (
        <div class="main-header">
          <button
            onClick={(e) => {
              localStorage.removeItem("message");
              navigate("/search-providers/" + slugData.id);
              setView("");
            }}
          >
            <ArrowLeftIcon />
            {t("text-back")}
          </button>
          <MessageContent id={view} setview={setView} list={fetchMessageData} />
        </div>
      )}

      {showReviewModal && (
        <Modal show={showReviewModal} onHide={(e) => setShowReviewModal(false)}>
          <Modal.Body>
            <div className="promocode_content signout invite">
              <p>
                <b>{t("delete-message-question")}</b>
              </p>
              <div class="button text-center ">
                <div class="pull-right">
                  <button
                    class="btn"
                    onClick={(e) => {
                      handleDeselectElement();
                      setShowReviewModal(false);
                    }}
                  >
                    {t("objection")}
                  </button>
                  <button
                    class="btn"
                    onClick={(e) => {
                      setShowReviewModal(false);
                      handleDeleteMessage();
                    }}
                    style={{ marginLeft: "20px" }}
                  >
                    {t("confirm")}
                  </button>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      )}
    </div>
  );
}
