import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Modal from "react-bootstrap/Modal";

export const ReactivateAccountModal = ({
  reactivateAccountModal,
  setReactivateAccountModal,
  setIsReactivateModal
}) => {
  const { t } = useTranslation();

  return (
    <Modal
      show={reactivateAccountModal}
      onHide={(e) => setReactivateAccountModal(false)}
    >
      <Modal.Body>
        <div className="promocode_content login_first reactivatefirst ">
          <Link to="" onClick={(e) => setReactivateAccountModal(false)}>
            +
          </Link>
          <h2>{t("glad-back")}</h2>
          <img src={window.location.origin + "/images/activate.png"} />
          <p>
            {t(
              "reactivate-account-members"
            )}
          </p>
          <button>
            <Link
              to={""}
              onClick={(e) => {
                setReactivateAccountModal(false);
                setIsReactivateModal(true);
              }}
            >
              {t("reactivate-my-account")}
            </Link>
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};
