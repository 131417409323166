import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import { RaffleIcon } from "../../../assets/image/RaffleIcon";
import { PageIcon } from "../../../assets/image/PageIcon";
import { BoldArrowDown } from "../../../assets/image/BoldArrowDown";
import {
  fetcDeleteDocumentView,
  fetchViewDocument,
} from "../../../api/documentApi";
import { REFINE_OPTIONS } from "../../../utils/variables";

export function ShareDocumentDetail(props) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [documentData, setDocumentData] = useState([]);
  const [isCheck, setIsCheck] = useState(true);
  const [isActive, setIsActive] = useState(false);
  const [recived, setRecived] = useState("");
  const [refine, setRefine] = useState({
    threedays: "",
    withinweek: "",
    twofourday: "",
    withinmonth: "",
  });

  const slugData = useParams();

  const handleRefineDay = (e) => {
    setRefine({
      threedays: e == "3" ? true : "",
      withinweek: e == "7" ? true : "",
      twofourday: e == "24" ? true : "",
      withinmonth: e == "30" ? true : "",
    });

    setIsCheck(true);
  };

  const handleDocumentData = async () => {
    try {
      const result = await fetchViewDocument(props.id, refine);
      setDocumentData(result.data.viewdocument);
      console.log(result);
    } catch (error) {
      console.error("Error fetching document data:", error);
    }
  };
  useEffect(() => {
    if (isCheck) {
      handleDocumentData();
      setIsCheck(false);
    }
    console.log(documentData, slugData, slugData);
  }, [isCheck]);

  const handleDeleteDocument = async (documentIds) => {
    try {
      await fetcDeleteDocumentView(documentIds);
      props.list();
      props.setview("");
      navigate(`/search-parents/${slugData.id}/${slugData.name}`);
    } catch (error) {
      console.error("Error deleting document:", error);
    }
  };

  return (
    <>
      <div class="main-header share_doc">
        <h2 className="border">{t("shared-documents")}</h2>
        <div class="mail-header-bar">
          <p>
            {documentData.length} {t("text-documents")}
          </p>
          <div class="btn-group flex">
            {t("text-refine")}
            <div className="select">
              <label onClick={(e) => setIsActive(!isActive)}>
                {t(recived)}
                <span>
                  <img src="/images/done_a.svg" />
                </span>
              </label>
              {isActive && (
                <ul>
                  {REFINE_OPTIONS.map((option) => (
                    <li
                      key={option.label}
                      onClick={() => {
                        setRecived(t(option.label));
                        handleRefineDay(option.value);
                        setIsActive(!isActive);
                      }}
                    >
                      {t(option.label)}
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </div>
        </div>
        <ul>
          {documentData &&
            documentData.map((data, index) => {
              return (
                <>
                  {data.back_doc != null ? (
                    <li>
                      <span className="docic">
                        <PageIcon />
                      </span>
                      <span>
                        {data.UserName != null
                          ? data.UserName + "_background check"
                          : ""}
                      </span>
                      <span className="date">
                        
                        {new Date(data.documentsend_date).getDate()}/
                        {new Date(data.documentsend_date).getMonth() + 1}/
                        {new Date(data.documentsend_date).getFullYear()}
                      </span>

                      <button onClick={(e) => {}}>
                        <a
                          href={
                            "https://admin.stage.mysenscare.com/assets/images/users/" +
                            data.back_doc
                          }
                          download
                          target="_blank"
                        >
                          <BoldArrowDown />
                        </a>
                      </button>
                      <button
                        onClick={(e) => handleDeleteDocument([data.DOC_ID])}
                      >
                        <RaffleIcon />
                      </button>
                    </li>
                  ) : (
                    ""
                  )}
                  {data.cert_doc != null ? (
                    <li>
                      <span className="docic">
                        <PageIcon />
                      </span>
                      <span>
                        {data.UserName != null
                          ? data.UserName + "_Certificate"
                          : ""}
                      </span>
                      <span className="date">
                        
                        {new Date(data.documentsend_date).getDate()}/
                        {new Date(data.documentsend_date).getMonth() + 1}/
                        {new Date(data.documentsend_date).getFullYear()}
                      </span>

                      <button onClick={(e) => {}}>
                        <a
                          href={
                            "https://admin.stage.mysenscare.com/assets/images/users/" +
                            data.cert_doc
                          }
                          download
                          target="_blank"
                        >
                          <BoldArrowDown />
                        </a>
                      </button>
                      <button
                        onClick={(e) => handleDeleteDocument([data.DOC_ID])}
                      >
                        <RaffleIcon />
                      </button>
                    </li>
                  ) : (
                    ""
                  )}
                  {data.cv_doc != null ? (
                    <li>
                      <span className="docic">
                        <PageIcon />
                      </span>
                      <span>
                        {data.UserName != null ? data.UserName + "_CV" : ""}
                      </span>
                      <span className="date">
                        
                        {new Date(data.documentsend_date).getDate()}/
                        {new Date(data.documentsend_date).getMonth() + 1}/
                        {new Date(data.documentsend_date).getFullYear()}
                      </span>

                      <button onClick={(e) => {}}>
                        <a
                          href={
                            "https://admin.stage.mysenscare.com/assets/images/users/" +
                            data.cv_doc
                          }
                          download
                          target="_blank"
                        >
                          <BoldArrowDown />
                        </a>
                      </button>
                      <button
                        onClick={(e) => handleDeleteDocument([data.DOC_ID])}
                      >
                        <RaffleIcon />
                      </button>
                    </li>
                  ) : (
                    ""
                  )}
                  {data.recom_doc != null ? (
                    <li>
                      <span className="docic">
                        <PageIcon />
                      </span>
                      <span>
                        {data.UserName != null
                          ? data.UserName + "_Recommendation"
                          : ""}
                      </span>
                      <span className="date">
                        
                        {new Date(data.documentsend_date).getDate()}/
                        {new Date(data.documentsend_date).getMonth() + 1}/
                        {new Date(data.documentsend_date).getFullYear()}
                      </span>

                      <button onClick={(e) => {}}>
                        <a
                          href={
                            "https://admin.stage.mysenscare.com/assets/images/users/" +
                            data.recom_doc
                          }
                          download
                          target="_blank"
                        >
                          <BoldArrowDown />
                        </a>
                      </button>
                      <button
                        onClick={(e) => handleDeleteDocument([data.DOC_ID])}
                      >
                        <RaffleIcon />
                      </button>
                    </li>
                  ) : (
                    ""
                  )}
                </>
              );
            })}
        </ul>
      </div>
    </>
  );
}
