import { create } from "zustand";

export const useVerifyStore = create((set) => ({
  phoneVerify: {
    phone: "",
    otp: "",
  },
  setPhoneVerify: (newPhoneVerify) =>
    set((state) => ({
      phoneVerify: { ...state.phoneVerify, ...newPhoneVerify },
    })),

  emailVerify: {
    email: "",
    alemail: "",
  },
  setEmailVerify: (newEmailVerify) =>
    set((state) => ({
      emailVerify: { ...state.emailVerify, ...newEmailVerify },
    })),
}));
