export const SearchIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.6942 12.8252C10.4625 13.8106 8.9 14.3998 7.19991 14.3998C3.22351 14.3998 0 11.1763 0 7.19991C0 3.22351 3.22351 0 7.19991 0C11.1763 0 14.3998 3.22351 14.3998 7.19991C14.3998 8.8998 13.8107 10.4621 12.8255 11.6938L15.7661 14.6343C16.0785 14.9467 16.0785 15.4533 15.7661 15.7657C15.4537 16.0781 14.9471 16.0781 14.6347 15.7657L11.6942 12.8252ZM1.59998 7.19991C1.59998 4.10715 4.10715 1.59998 7.19991 1.59998C10.2927 1.59998 12.7998 4.10715 12.7998 7.19991C12.7998 8.70993 12.2022 10.0804 11.2305 11.0875C11.2042 11.108 11.1789 11.1302 11.1548 11.1544C11.1306 11.1785 11.1083 11.2039 11.0879 11.2302C10.0807 12.202 8.7101 12.7998 7.19991 12.7998C4.10715 12.7998 1.59998 10.2927 1.59998 7.19991Z"
        fill="#7D2B8B"
      />
      <circle cx="7.19948" cy="7.20144" r="5.59987" fill="white" />
    </svg>
  );
};
