import React, { useState } from "react";
import { Link } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { useTranslation } from "react-i18next";
import Modal from "react-bootstrap/Modal";

import { SERVICE } from "../../../utils/variables";
import { fethSaveSurvey } from "../../../api/surveyApi";

export function TestmonialModal(props) {
  const { t } = useTranslation();

  const [showModal, setShowModal] = useState(false);
  const [rating, setRating] = useState(0);
  const [token, setToken] = useState(localStorage.getItem("token"));
  const [recommendHover, setRecommendHover] = useState(0);
  const [navigationHover, setNavigationHover] = useState(0);
  const [rateHover, setRateHover] = useState(0);

  const [formLogin, setFormLogin] = useState({
    userId: parseInt(localStorage.getItem("id")),
    recommendValue: "",
    navigationValue: "",
    seeService: "",
    overallValue: "",
    userReview: "",
    otherService: "jb",
  });
  const [errorField, setErrorField] = useState({
    userId: "",
    recommendValue: "",
    navigationValue: "",
    seeService: "",
    overallValue: "",
    userReview: "",
    otherService: "",
  });

  const handleCloseModal = () => setShowModal(false);
  const handleShowModal = () => setShowModal(false);

  const validationForm = (name) => {
    switch (name) {
      case "userId":
        errorField.userId = formLogin.userId == "" ? "required" : "";
        break;
      case "recommendValue":
        errorField.recommendValue =
          formLogin.recommendValue != "" ? "" : "required";

        break;
      case "navigationValue":
        errorField.navigationValue =
          formLogin.navigationValue != "" ? "" : "required";
        break;
      case "seeService":
        errorField.seeService = formLogin.seeService == "" ? "required" : "";
        break;
      case "overallValue":
        errorField.overallValue =
          formLogin.overallValue == "" ? "required" : "";
        break;
      case "userReview":
        errorField.userReview = formLogin.userReview == "" ? "required" : "";
        break;
      case "otherService":
        errorField.otherService =
          formLogin.otherService == "" ? "required" : "";
        break;
      default:
        break;
    }
    setErrorField(errorField);
  };

  const handleSubmitForm = async () => {
    if (
      (formLogin.recommendValue,
      formLogin.navigationValue,
      formLogin.seeService,
      formLogin.overallValue,
      formLogin.userReview)
    ) {
      if (token && formLogin.userId) {
        try {
          const result = await fethSaveSurvey(token, formLogin);
          if (result.success) {
            handleCloseModal();
          }
        } catch (error) {
          console.error("Error saving survey:", error);
        }
      }
    } else {
      SERVICE.map((e) => {
        validationForm(e.name);
      });
    }
  };
  return (
    <>
      <div className="testimonial_add">
        <Link to="#" onClick={handleShowModal}>
          {props.name}
        </Link>
        <Modal show={showModal} onHide={handleCloseModal}>
          <Modal.Body>
            <ToastContainer
              position="top-center"
              autoClose={2000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable={false}
              pauseOnHover
            />
            <div className="promocode_content testimonial_adds">
              <Link to="" onClick={handleCloseModal}>
                +
              </Link>
              <h2> {t("Testimonials and Survey")}</h2>

              <div className="add_test">
                <h3 className="border">
                  {t(
                    "thank-senscare-community"
                  )}
                </h3>
                <h5>{t("about-your-experiunce")} </h5>
                <div className="que1">
                  <p>
                    {t("recommend-senscare-friends")}
                  </p>
                  <span className="star">
                    <div className="star-rating">
                      {[...Array(5)].map((star, index) => {
                        index += 1;
                        return (
                          <i
                            type="button"
                            key={index}
                            className={
                              index <= (recommendHover || formLogin.recommendValue)
                                ? "on fa-solid fa-star"
                                : "off fa-regular fa-star"
                            }
                            onClick={() =>
                              setFormLogin({
                                ...formLogin,
                                recommendValue: index,
                              })
                            }
                            onMouseEnter={() => setRecommendHover(index)}
                            onMouseLeave={() => setRecommendHover(rating)}
                          ></i>
                        );
                      })}
                    </div>
                  </span>
                  <span className="errorfield">
                    {errorField.recommendValue}
                  </span>
                </div>
                <div className="que1">
                  <p>{t("How will you rate the site navigation?")} </p>
                  <span className="star">
                    {[...Array(5)].map((star, index) => {
                      index += 1;
                      return (
                        <i
                          type="button"
                          key={index}
                          className={
                            index <= (navigationHover || formLogin.navigationValue)
                              ? "on fa-solid fa-star"
                              : "off fa-regular fa-star"
                          }
                          onClick={() =>
                            setFormLogin({
                              ...formLogin,
                              navigationValue: index,
                            })
                          }
                          onMouseEnter={() => setNavigationHover(index)}
                          onMouseLeave={() => setNavigationHover(rating)}
                        ></i>
                      );
                    })}
                  </span>
                  <span className="errorfield">
                    {errorField.navigationValue}
                  </span>
                </div>
                <div className="que1 border">
                  <p>
                    {t(
                      "additional-servises-website"
                    )}
                  </p>
                  <div className="option">
                    <label>
                      <input
                        type={"radio"}
                        name="service"
                        onClick={(e) =>
                          setFormLogin({
                            ...formLogin,
                            seeService: "Cleaning",
                          })
                        }
                      />
                      <span>{t("Cleaning")}</span>
                    </label>
                    <label>
                      <input
                        type={"radio"}
                        name="service"
                        onClick={(e) =>
                          setFormLogin({
                            ...formLogin,
                            seeService: "Elderly care",
                          })
                        }
                      />
                      <span>{t("Elderly care")}</span>
                    </label>
                    <label>
                      <input
                        type={"radio"}
                        name="service"
                        onClick={(e) =>
                          setFormLogin({
                            ...formLogin,
                            seeService: "Pet care",
                          })
                        }
                      />
                      <span>{t("Pet care")}</span>
                    </label>
                    <label>
                      <input
                        type={"radio"}
                        name="service"
                        onClick={(e) =>
                          setFormLogin({
                            ...formLogin,
                            seeService: "Other",
                          })
                        }
                      />
                      <span>{t("text-other")}</span>
                      <div className="comment">
                        <textarea
                          rows={2}
                          cols={5}
                          placeholder={t("text-type")}
                          onChange={(e) => {
                            setFormLogin({
                              ...formLogin,
                              otherService: e.target.value,
                            });
                          }}
                        />
                        <span>{t("Number of characters 70")} </span>
                      </div>
                    </label>
                  </div>
                  <span className="errorfield">{errorField.seeService}</span>
                </div>
                <div className="que1">
                  <p>
                    {t("rate-overall-impression")}
                  </p>
                  <span className="star">
                    {[...Array(5)].map((star, index) => {
                      index += 1;
                      return (
                        <i
                          type="button"
                          key={index}
                          className={
                            index <= (rateHover || formLogin.overallValue)
                              ? "on fa-solid fa-star"
                              : "off fa-regular fa-star"
                          }
                          onClick={() =>
                            setFormLogin({
                              ...formLogin,
                              overallValue: index,
                            })
                          }
                          onMouseEnter={() => setRateHover(index)}
                          onMouseLeave={() => setRateHover(rating)}
                        ></i>
                      );
                    })}
                  </span>
                  <span className="errorfield">{errorField.overallValue}</span>
                </div>
                <div className="que1 border sec2">
                  <p>{t("Write your review")} </p>
                  <div className="comment">
                    <textarea
                      rows={2}
                      cols={5}
                      onChange={(e) => {
                        setFormLogin({
                          ...formLogin,
                          userReview: e.target.value,
                        });
                      }}
                    />
                    <span>{t("Number of characters 70")} </span>
                    <div className="errorfield" style={{ bottom: "5px" }}>
                      {errorField.userReview}
                    </div>
                  </div>
                </div>
                <div className="buttons">
                  <button className="rest" onClick={handleSubmitForm}>
                    {t("text-send")}
                  </button>
                  <button>{t("text-cancel")} </button>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
}
