import { DEFAULT_API } from "../utils/variables";

export const fetchRecommendationsData = async (id) => {
  const token = localStorage.getItem("token");
  const myHeaders = new Headers();
  myHeaders.append("Authorization", "Bearer " + token);
  myHeaders.append("Content-Type", "application/json");

  const raw = JSON.stringify({
    recomm_id: id,
  });

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  try {
    const response = await fetch(
      `${DEFAULT_API}/api/v1/providerrecommendation_notifyview`,
      requestOptions
    );
    if (!response.ok) {
      const error = await response.json();
      throw new Error(error.message || "Something went wrong");
    }
    return response.json();
  } catch (error) {
    console.error("Error fetching recommendations data:", error);
    throw error;
  }
};

export const fetchRecommendationsNotification = async (refine) => {
  const token = localStorage.getItem("token");
  const myHeaders = new Headers();
  myHeaders.append("Authorization", "Bearer " + token);

  const queryParams = refine.threedays
    ? `threedays=${refine.threedays}`
    : refine.withinweek
    ? `withinweek=${refine.withinweek}`
    : refine.twofourday
    ? `twofourday=${refine.twofourday}`
    : refine.withinmonth
    ? `withinmonth=${refine.withinmonth}`
    : "";

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  try {
    const response = await fetch(
      `${DEFAULT_API}/api/v1/providerrecommendation_notifications?${queryParams}`,
      requestOptions
    );
    if (!response.ok) {
      const error = await response.text();
      throw new Error(error || "Something went wrong");
    }
    return response.json();
  } catch (error) {
    console.error("Error fetching recommendations data:", error);
    throw error;
  }
};

export const fetchDeleteRecommendations = async (deleteList) => {
  const token = localStorage.getItem("token");
  const myHeaders = new Headers();
  myHeaders.append("Authorization", "Bearer " + token);
  myHeaders.append("Content-Type", "application/json");

  const raw = JSON.stringify({ ids: deleteList });

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  try {
    const response = await fetch(
      `${DEFAULT_API}/api/v1/providerrecommendation_delete`,
      requestOptions
    );
    if (!response.ok) {
      const error = await response.text();
      throw new Error(error || "Something went wrong");
    }
    return response.text();
  } catch (error) {
    console.error("Error deleting recommendations:", error);
    throw error;
  }
};

export const fetchRecommendationRequest = async (refine) => {
  const token = localStorage.getItem("token");
  const myHeaders = new Headers();
  myHeaders.append("Authorization", "Bearer " + token);

  const queryParams = refine.threedays
    ? `threedays=${refine.threedays}`
    : refine.withinweek
    ? `withinweek=${refine.withinweek}`
    : refine.twofourday
    ? `twofourday=${refine.twofourday}`
    : refine.withinmonth
    ? `withinmonth=${refine.withinmonth}`
    : "";

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  try {
    const response = await fetch(
      `${DEFAULT_API}/api/v1/recommendationrequest_notification?${queryParams}`,
      requestOptions
    );
    if (!response.ok) {
      const error = await response.text();
      throw new Error(error || "Something went wrong");
    }
    return response.json();
  } catch (error) {
    console.error("Error fetching recommendations data:", error);
    throw error;
  }
};

export const fetchDeleteMultiplyRecommendations = async (deleteList) => {
  const token = localStorage.getItem("token");
  const myHeaders = new Headers();
  myHeaders.append("Authorization", "Bearer " + token);
  myHeaders.append("Content-Type", "application/json");

  const raw = JSON.stringify({ ids: deleteList });

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  try {
    const response = await fetch(
      `${DEFAULT_API}/api/v1/multideleterecommendation`,
      requestOptions
    );
    if (!response.ok) {
      const error = await response.text();
      throw new Error(error || "Something went wrong");
    }
    return response.text();
  } catch (error) {
    console.error("Error deleting recommendations:", error);
    throw error;
  }
};

export const fetchRecommendationView = async (id) => {
  const token = localStorage.getItem("token");
  const myHeaders = new Headers();
  myHeaders.append("Authorization", "Bearer " + token);
  myHeaders.append("Content-Type", "application/json");

  const raw = JSON.stringify({
    recommendation_id: id,
  });

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  try {
    const response = await fetch(
      `${DEFAULT_API}/api/v1/recommendationrequestview`,
      requestOptions
    );
    if (!response.ok) {
      const error = await response.json();
      throw new Error(error.message || "Something went wrong");
    }
    return response.json();
  } catch (error) {
    console.error("Error fetching recommendation data:", error);
    throw error;
  }
};

export const fetchRecommendationResponse = async (id, documentField) => {
  const token = localStorage.getItem("token");
  const myHeaders = new Headers();
  myHeaders.append("Authorization", "Bearer " + token);

  const formdata = new FormData();
  formdata.append("recommendation_id", id);
  formdata.append("recommendation_response", documentField);

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: formdata,
    redirect: "follow",
  };

  try {
    const response = await fetch(
      `${DEFAULT_API}/api/v1/parentsrecommendationrequestresponsesend`,
      requestOptions
    );
    if (!response.ok) {
      const error = await response.json();
      throw new Error(error.message || "Something went wrong");
    }
    return response.json();
  } catch (error) {
    console.error("Error submitting recommendation response:", error);
    throw error;
  }
};
