import { useTranslation } from "react-i18next";

import { useProfileStore } from "../../../store/useProfileStore";
import { useRatesStore } from "../../../store/useRatesStore";
import { useErrorFieldStore } from "../../../store/useErrorsFieldsStore";

import React from "react";
import OldSlider from "../../../components/common/Slider/OldSlider";

export function ServiceTypeThree() {
    const { t, i18n } = useTranslation();
    const language = i18n.language;

    const [errorField, setErrorField] = useErrorFieldStore((state) => [
        state.errorField,
        state.setErrorField,
    ]);
    const { detail } = useProfileStore();

  const { secondaryRate, setSecondaryRate } = useRatesStore();
  return (
    <div className="special_education big">
      <h2 className="border">
        <img src="./images/professional.svg" />
        {t("education-paraprofessional")}
      </h2>
      <div className="form_group full">
        <label>
          {t("Rate per hour")} {detail.country == "Serbia" ? "(RSD)" : "(USD)"}
          <span className={errorField.nanyperhrrate && errorField.nanyperhrrate !== "" ? "starred" : ""}>
            *
          </span>
        </label>
          <OldSlider rate={secondaryRate} setRate={setSecondaryRate} serbian={language === 'sr'}/>
          <span className="price">
          {language === 'sr'
              ? secondaryRate.min * 100 + " - " + secondaryRate.max * 100 + (secondaryRate.max === 60 ? "+" : "")
              : secondaryRate.min + " - " + secondaryRate.max + (secondaryRate.max === 60 ? "+" : "")}
        </span>
      </div>
    </div>
  );
}
