import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Modal from "react-bootstrap/Modal";


export const MembershipModal = ({
  isMembershipModal,
  setIsMembershipModal,
}) => {
  const { t } = useTranslation();

  return (
    <Modal show={isMembershipModal} onHide={(e) => setIsMembershipModal(false)}>
      <Modal.Body>
        <div className="promocode_content cancelmembership">
          <Link to="" onClick={(e) => setIsMembershipModal(false)}>
            +
          </Link>
          <h2>
            <img src="/images/warning.svg" alt="warning-icon" />
            <br />
            {t("Cancel membership")}
          </h2>
          <div className="cancelmembershipp">
            <p>
              <span>{t("text-warning")}</span> {t("cancel-loyalty-program")}
            </p>
            <div className="button text-center">
              <div className="pull-right">
                <button
                  className="btn"
                  onClick={(e) => setIsMembershipModal(false)}
                >
                  {t("Confirm")}
                </button>
              </div>
              <div className="pull-right">
                <button
                  className="btn confirm"
                  onClick={(e) => setIsMembershipModal(false)}
                >
                  {t("text-close-up")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
