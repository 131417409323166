import React, { useState, useEffect } from "react";

import { useTranslation } from "react-i18next";
import Modal from "react-bootstrap/Modal";
import "react-toastify/dist/ReactToastify.css";
import "react-datepicker/dist/react-datepicker.css";

import { api } from "../../../urls";

import { ArrowUpIcon } from "../../../assets/image/ArrowUpIcon";
import { useProviderFieldStore } from "../../../store/useProviderFieldStore";
import { LoginModal } from "./Modals/LoginModal";
import { STEP_EXP, STEP_RATE, STEP_TUTOR } from "../../../utils/variables";
import { useRatesStore } from "../../../store/useRatesStore";
import { useProfileDetailsStore } from "../../../store/useProfileDetailsStore";
import { StatusModal } from "./Modals/StatusModal";
import { NannyModal } from "./Modals/NannyModal";
import { TeacherModal } from "./Modals/TeacherModal";
import { ProfessionalModal } from "./Modals/ProfessionalModal";
import { TutorModal } from "./Modals/TutorModal";

export function ProviderProfession(props) {
  const { t } = useTranslation();

  const [showLoginModal, setShowLoginModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [diseasesList, setDiseasesList] = useState([]);
  const [showNannyModal, setShowNannyModal] = useState(false);
  const [showProfessionalModal, setShowProfessionalModal] = useState(false);
  const [showTeacherModal, setShowTeacherModal] = useState(false);
  const [showTutorModal, setShowTutorModal] = useState(false);
  const [count, setCount] = useState(0);
  const [disabled, setDisabled] = useState(0);

  const [profession, setProfession] = useState({});
  const [detail, setDetail] = useState({});
  const [describe, setDescribe] = useState({});
  const [newProfession, setNewProfession] = useState({});
  const [inActiveServiceType, setInActiveSecriveType] = useState({});
  const [range, setRange] = useState(0);
  const [experience, setExperience] = useState(0);
  const [workExperience, setWorkExperience] = useState(0);
  const [tutorExperience, setTutorExprerience] = useState(0);

  const [methods, setMethods] = useState({
    setexpmethods: "",
    setexpmethods2: "",
    setexpmethods3: "",
    setexpmethods4: "",
  });

  const [rate, setRate] = useState({
    min: 0,
    max: 0,
  });

  const [isLoad, setIsLoad] = useState(true);
  const [refresh, setRefresh] = useState("");

  const { errorField, setErrorField, detailProvider, setDetailProvider } =
    useProviderFieldStore();

  const {
    generalRate,
    tutorRate,
    nanyRate,
    setNanyRate,
    setGeneralRate,
    setTutorRate,
  } = useRatesStore();

  const { qualifications, habit, setQualifications, setHabit } =
    useProfileDetailsStore();

  const handleCloseLoginModal = () => {
    setShowLoginModal(false);
  };
  const handleCloseNannyModal = () => {
    setShowNannyModal(false);
  };
  const handleCloseProfessionModal = () => {
    setShowProfessionalModal(false);
  };
  const handleCloseTeacherModal = () => {
    setShowTeacherModal(false);
  };
  const handleCloseTutorModal = () => {
    setShowTutorModal(false);
  };

  const handleCloseModal = () => setShowModal(false);

  const handleDescribeSelect = (a, b) => {
    if (Object.keys(describe).length < 2) {
      setDescribe({ ...describe, [a]: b });
      Object.keys(detail.service_type || {}).filter((e) => e == a)[0]
        ? setNewProfession({})
        : setNewProfession({ ...newProfession, [a]: b });
      setDetail({
        ...detail,
        service_type: { ...detail.service_type || {}, [a]: b },
      });
      delete inActiveServiceType[a];
      setInActiveSecriveType({ ...inActiveServiceType });
    } else {
      delete describe[a];
      setDescribe({ ...describe });

      delete detail.service_type[a];
      setDetail({ ...detail });
      delete newProfession[a];
      setNewProfession({ ...newProfession });
    }
  };

  const handleChangeDiseasesList = (data) => {
    let sum = false;
    diseasesList.map((e, index) => {
      if (e.name == data) {
        sum = true;
        diseasesList.splice(index, 1);
      }
    });
    if (sum == false) {
      diseasesList.push({ name: data });
    }
    setTimeout(() => {
      setDiseasesList([...diseasesList]);
    }, 500);
  };

  const handleElementById = (e, x) => {
    if (isLoad) {
      document.getElementById(e).style.display = "block";
      document.getElementById(x).style.display = "block";
      setIsLoad(false);
    } else {
      document.getElementById(e).style.display = "none";
      document.getElementById(x).style.display = "none";
      setIsLoad(true);
    }
  };

  const handleDescribeAdd = () => {
    console.log(detail.service_type);
  };

  const fetchProfessionData = () => {
    const myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(api + "/api/v1/details", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({
          userId: result.data.id,
        });

        const requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: raw,
          redirect: "follow",
        };

        fetch(api + "/api/professionCount", requestOptions)
          .then((response) => response.json())
          .then((result) => {
            console.log(result);
            setProfession(result);
          })
          .catch((error) => {
            console.log("error", error);
          });

        setDetail(result.data);
        setDescribe(result.data.service_type || {});
        setInActiveSecriveType(
          result.data.inactive_service != null
            ? result.data.inactive_service
            : {}
        );
        setTimeout(() => {
          const x = Object.keys(detailProvider).forEach(function (key) {
            detailProvider[key] =
              result.data[key] != null ? result.data[key] : "";
            setDetailProvider({ ...detailProvider });
          });
        }, 500);
        setHabit({
          smoke: result.data.smoke ? result.data.smoke : "",
          licence: result.data.carorlicence ? result.data.carorlicence : "",
          kids: result.data.cooking ? result.data.cooking : "",
          housework: result.data.lighthousework
            ? result.data.lighthousework
            : "",
          family: result.data.traveling ? result.data.traveling : "",
        });
        setMethods(
          result.data.setexpmethods
            ? JSON.parse(result.data.setexpmethods)
            : methods
        );
        setQualifications(
          result.data.tutorliketoteach != null
            ? result.data.tutorliketoteach
            : {}
        );
        console.log(result.data);
        setExperience(
          result.data.nanyyearexp != null ? result.data.nanyyearexp : 0
        );
        setRange(result.data.setyearexp != null ? result.data.setyearexp : 0);
        setWorkExperience(
          result.data.sepworkexp != null ? result.data.sepworkexp : 0
        );
        setTutorExprerience(
          result.data.tutorexp != null ? result.data.tutorexp : 0
        );
        setNanyRate({
          min:
            result.data.nanyperhrrate != null
              ? result.data.nanyperhrrate
                  .substr(result.data.nanyperhrrate.lastIndexOf("\\") + 1)
                  .split("-")[0]
              : 0,
          max:
            result.data.nanyperhrrate != null
              ? result.data.nanyperhrrate
                  .substr(result.data.nanyperhrrate.lastIndexOf("\\") + 1)
                  .split("-")[1]
              : 0,
        });
        setTutorRate({
          min:
            result.data.tutorperhrrate != null
              ? result.data.tutorperhrrate
                  .substr(result.data.tutorperhrrate.lastIndexOf("\\") + 1)
                  .split("-")[0]
              : 0,
          max:
            result.data.tutorperhrrate != null
              ? result.data.tutorperhrrate
                  .substr(result.data.tutorperhrrate.lastIndexOf("\\") + 1)
                  .split("-")[1]
              : 0,
        });
        setRate({
          min:
            result.data.seterhrrate != null
              ? result.data.seterhrrate
                  .substr(result.data.seterhrrate.lastIndexOf("\\") + 1)
                  .split("-")[0]
              : 0,
          max:
            result.data.seterhrrate != null
              ? result.data.seterhrrate
                  .substr(result.data.seterhrrate.lastIndexOf("\\") + 1)
                  .split("-")[1]
              : 0,
        });
        setGeneralRate({
          min:
            result.data.seperhrrate != null
              ? result.data.seperhrrate
                  .substr(result.data.seperhrrate.lastIndexOf("\\") + 1)
                  .split("-")[0]
              : 0,
          max:
            result.data.seperhrrate != null
              ? result.data.seperhrrate
                  .substr(result.data.seperhrrate.lastIndexOf("\\") + 1)
                  .split("-")[1]
              : 0,
        });
      })
      .catch((error) => console.log("error", error));
  };

  const initialCountp = parseInt(localStorage.getItem("countp")) || 0;
  const initialCountedit = parseInt(localStorage.getItem("countedit")) || 0;

  const [countp, setCountp] = useState(initialCountp);
  const [countEdit, setCountEdit] = useState(initialCountedit);

  const lastSetDate = new Date(
    parseInt(localStorage.getItem("counteditDate")) || 0
  );
  const currentDate = new Date();

  if (currentDate.getFullYear() > lastSetDate.getFullYear()) {
    localStorage.setItem("countedit", 0);
    localStorage.setItem("counteditDate", currentDate.getTime());
  }

  useEffect(() => {
    localStorage.setItem("countp", countp.toString());
  }, [countp]);

  const handleSubmitProvider = (status) => {
    localStorage.setItem("search", "Profile");

    sessionStorage.clear();
    const myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );
    const formdata = new FormData();
    formdata.append("user_id", localStorage.getItem("id"));
    formdata.append("nanyyearexp", experience);
    formdata.append("smoke", habit.smoke);
    formdata.append("carorlicence", habit.licence);
    formdata.append("cooking", habit.kids);
    formdata.append("lighthousework", habit.housework);
    formdata.append("traveling", habit.family);
    formdata.append("workingabroad", detailProvider.workingabroad);
    formdata.append("livewithfamily", detailProvider.livewithfamily);
    formdata.append("nanyperhrrate", nanyRate.min + "-" + nanyRate.max);
    formdata.append("nanynewbornexp", detailProvider.nanynewbornexp);
    formdata.append("setexpmethods[]", JSON.stringify(methods));
    formdata.append("setexpiep", detailProvider.setexpiep);
    formdata.append("setyearexp", range);
    formdata.append("seterhrrate", rate.min + "-" + rate.max);
    formdata.append("seperhrrate", generalRate.min + "-" + generalRate.max);
    formdata.append("sepexpmethods", detailProvider.sepexpmethods);
    formdata.append("sepworkexp", workExperience);
    formdata.append("tutorexp", tutorExperience);
    formdata.append("tutorperhrrate", tutorRate.min + "-" + tutorRate.max);
    formdata.append("tutorliketoteach", JSON.stringify(qualifications));
    formdata.append(
      "tutorintrestedonlinecls",
      detailProvider.tutorintrestedonlinecls
    );
    formdata.append("active_service", JSON.stringify(describe));
    formdata.append("inactive_service", JSON.stringify(inActiveServiceType));
    formdata.append("nanyexpwithkid", detailProvider.nanyexpwithkid);
    formdata.append("sepexpwithkid", detailProvider.sepexpwithkid);

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch(api + "/api/v1/updateprovider", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        handleCloseModal();
        fetchProfessionData();

        setCountEdit(countEdit + 1);
        localStorage.setItem("countedit", countEdit);

        setTimeout(() => {
          if (Object.keys(newProfession).length == 0) {
            window.location.reload();
          }
          if (Object.keys(newProfession).length == count && status) {
            window.location.reload();
          } else {
            setCount(count + 1);
          }
        }, 200);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const validateAfterLoginField = (name) => {
    setRefresh();
    switch (name) {
      case "nanyyearexp":
        errorField.nanyyearexp = experience == 0 ? "required" : "";
        break;
      case "smoke":
        errorField.smoke = habit.smoke == "" ? "required" : "";
        break;
      case "setallapplicable":
        errorField.setallapplicable = diseasesList[0] ? "" : "required";
        break;
      case "carorlicence":
        errorField.carorlicence = habit.licence == "" ? "required" : "";
        break;
      case "cooking":
        errorField.cooking = habit.kids == "" ? "required" : "";
        break;
      case "lighthousework":
        errorField.lighthousework = habit.housework == "" ? "required" : "";
        break;
      case "traveling":
        errorField.traveling = habit.family == "" ? "required" : "";
        break;
      case "workingabroad":
        errorField.workingabroad =
          detailProvider.workingabroad == "" ? "required" : "";
        break;
      case "livewithfamily":
        errorField.livewithfamily =
          detailProvider.livewithfamily == "" ? "required" : "";
        break;
      case "nanyperhrrate":
        errorField.nanyperhrrate = nanyRate.max == 0 ? "required" : "";
        break;
      case "seterhrrate":
        errorField.seterhrrate = rate.max == 0 ? "required" : "";
        break;
      case "seperhrrate":
        errorField.seperhrrate = generalRate.max == 0 ? "required" : "";
        break;
      case "tutorintrestedonlinecls":
        errorField.tutorintrestedonlinecls =
          detailProvider.tutorintrestedonlinecls != "" ? "" : "required";
        break;
      case "nanynewbornexp":
        errorField.nanynewbornexp =
          detailProvider.nanynewbornexp != "" ? "" : "required";
        break;
      case "setyearexp":
        errorField.setyearexp = range > 0 ? "" : "required";
        break;
      case "setexpmethods":
        errorField.setexpmethods =
          methods.setexpmethods != "" ||
          methods.setexpmethods2 != "" ||
          methods.setexpmethods3 != "" ||
          methods.setexpmethods4 != ""
            ? ""
            : "required";
        break;
      case "setexpiep":
        errorField.setexpiep = detailProvider.setexpiep != "" ? "" : "required";
        break;
      case "sepexpmethods":
        errorField.sepexpmethods =
          detailProvider.sepexpmethods != "" ? "" : "required";
        break;
      case "sepworkexp":
        errorField.sepworkexp = workExperience == 0 ? "required" : "";
        break;
      case "tutorexp":
        errorField.tutorexp = tutorExperience > 0 ? "" : "required";
        break;
      case "tutorperhrrate":
        errorField.tutorperhrrate = tutorRate.max == 0 ? "required" : "";
        break;
      case "tutorliketoteach":
        errorField.tutorliketoteach =
          qualifications.English ||
          qualifications.Serbian ||
          qualifications.Mathematics ||
          qualifications.Physics ||
          qualifications.Chemistry ||
          qualifications.Other
            ? ""
            : "required";
        break;
      case "tutorintrestedonlinecls":
        errorField.tutorintrestedonlinecls =
          detailProvider.tutorintrestedonlinecls != "" ? "" : "required";
        break;
      case "nanyexpwithkid":
        errorField.nanyexpwithkid =
          detailProvider.nanyexpwithkid != "" ? "" : "required";
        break;
      case "sepexpwithkid":
        errorField.sepexpwithkid =
          detailProvider.sepexpwithkid != "" ? "" : "required";
        break;
      default:
        break;
    }
    setErrorField(errorField);
    setTimeout(() => {
      setDetail(detail);
      setRefresh(name);
      window.scrollTo({ top: 0 });
    }, 1000);
  };

  useEffect(() => {
    fetchProfessionData();
  }, [refresh]);

  return (
    <>
      {profession.message != "no update" && (
        <button onClick={(e) => setShowLoginModal(true)}>
          {t("Change Profession")}
        </button>
      )}

      <LoginModal
        showLoginModal={showLoginModal}
        handleCloseLoginModal={handleCloseLoginModal}
        setShowModal={setShowModal}
        setDisabled={setDisabled}
        handleDescribeSelect={handleDescribeSelect}
        handleSubmitProvider={handleSubmitProvider}
        setShowTutorModal={setShowTutorModal}
        setShowTeacherModal={setShowTeacherModal}
        setShowProfessionalModal={setShowProfessionalModal}
        setShowNannyModal={setShowNannyModal}
        newProfession={newProfession}
        initialCountedit={initialCountedit}
        detail={detail}
      />

      <StatusModal
        showModal={showModal}
        handleCloseModal={handleCloseModal}
        disabled={disabled}
        handleDescribeSelect={handleDescribeSelect}
        handleDescribeAdd={handleDescribeAdd}
      />

      <NannyModal
        showNannyModal={showNannyModal}
        handleCloseNannyModal={handleCloseNannyModal}
        experience={experience}
        detail={detail}
        setExperience={setExperience}
        setCountp={setCountp}
        countp={countp}
        handleSubmitProvider={handleSubmitProvider}
        validateAfterLoginField={validateAfterLoginField}
      />

      <TeacherModal
        showTeacherModal={showTeacherModal}
        handleCloseTeacherModal={handleCloseTeacherModal}
        rate={rate}
        setRate={setRate}
        detail={detail}
        setMethods={setMethods}
        methods={methods}
        diseasesList={diseasesList}
        handleChangeDiseasesList={handleChangeDiseasesList}
        range={range}
        setRange={setRange}
        handleElementById={handleElementById}
        countp={countp}
        validateAfterLoginField={validateAfterLoginField}
        handleSubmitProvider={handleSubmitProvider}
      />

      <ProfessionalModal
        showProfessionalModal={showProfessionalModal}
        handleCloseProfessionModal={handleCloseProfessionModal}
        workExperience={workExperience}
        detail={detail}
        handleSubmitProvider={handleSubmitProvider}
        validateAfterLoginField={validateAfterLoginField}
        countp={countp}
        setWorkExperience={setWorkExperience}
      />

      <TutorModal
        showTutorModal={showTutorModal}
        handleCloseTutorModal={handleCloseTutorModal}
        detail={detail}
        countp={countp}
        tutorExperience={tutorExperience}
        handleSubmitProvider={handleSubmitProvider}
        setTutorExprerience={setTutorExprerience}
        validateAfterLoginField={validateAfterLoginField}
      />
    </>
  );
}
