export const NannyIcon = () => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="15" cy="15" r="15" fill="#fff" />
      <circle cx="15" cy="15" r="15" fill="#fff" />
      <circle cx="15.0009" cy="14.9968" r="12.3571" stroke="white" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16.0158 13.5094V8.82664C16.0158 8.41385 16.3812 8.08904 16.7872 8.14994C19.4128 8.52212 21.4293 10.7823 21.4293 13.5094H16.0158ZM21.4287 14.1872C21.4287 15.4324 21.0024 16.5827 20.2918 17.4963C20.9144 18.0511 21.246 18.9241 21.0091 19.8647C20.7926 20.7173 20.0888 21.394 19.2294 21.5767C17.8219 21.8745 16.5565 20.9136 16.3738 19.6008H14.9663C14.7836 20.9203 13.5114 21.8812 12.0903 21.5699C11.2377 21.3805 10.5339 20.697 10.3309 19.8444C10.0603 18.7143 10.6084 17.6587 11.5084 17.1782C11.3527 16.9414 10.0738 14.1872 10.0738 14.1872H9.24822C8.87604 14.1872 8.57153 13.8827 8.57153 13.5105C8.57153 13.1384 8.87604 12.8339 9.24822 12.8339H10.5069C10.764 12.8339 11.0076 12.9827 11.1159 13.2196L11.576 14.1872H21.4287ZM12.6306 20.281C12.069 20.281 11.6156 19.8276 11.6156 19.266C11.6156 18.7043 12.069 18.2509 12.6306 18.2509C13.1923 18.2509 13.6456 18.7043 13.6456 19.266C13.6456 19.8276 13.1923 20.281 12.6306 20.281ZM17.7055 19.266C17.7055 19.8276 18.1589 20.281 18.7206 20.281C19.2822 20.281 19.7356 19.8276 19.7356 19.266C19.7356 18.7043 19.2822 18.2509 18.7206 18.2509C18.1589 18.2509 17.7055 18.7043 17.7055 19.266Z"
        fill="#7D2B8B"
      />
    </svg>
  );
};
