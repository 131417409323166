import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { SafetySection } from "../../components/homePage/SafetySection";
import { GuidSection } from "../../components/homePage/GuidSection";
import { PromoCode } from "../../components/common/PromoCode";
import { Testimonials } from "../../components/common/Testimonials";
import { Footer } from "../../components/common/Footer";
import { Header } from "../../components/common/Header";
import { LoginModal } from "../ProviderProfilePage/Modals/LoginModal";
import { ProviderModal } from "../ProviderProfilePage/Modals/ProviderModal";
import {api} from "../../urls";
import {PerfectJobModal} from "../ProfilePage/Modals/PerfectJobModal";

export function HomePage() {
  const { t } = useTranslation();
  const [isPromo, setIsPromo] = useState(false);
  const [isLoginModal, setIsLoginModal] = useState(false);
  const [isProviderModal, setIsProviderModal] = useState(false);
  const [isPerfectJobModal, setIsPerfectJobModal] = useState(false);
  const [logincheck, setLoginCheck] = useState({
    id: localStorage.getItem("id"),
    token: localStorage.getItem("token"),
    namep: localStorage.getItem("name"),
    type: localStorage.getItem("user_type"),
  });

  useEffect(() => {

    if (logincheck.id && logincheck.token) {
      const myHeaders = new Headers();
      myHeaders.append(
          "Authorization",
          "Bearer " + localStorage.getItem("token")
      );

      const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };
      fetch(api + "/api/v1/details", requestOptions)
          .then((response) => response.json())
          .then((result) => {
            const user = result.data;
            if(user){
              if(logincheck.type === "provider" && user.backgroundstatus === null){
                setIsPerfectJobModal(true);
              }
              else if(logincheck.type === "parents" && !user.job[0]){
                setIsProviderModal(true);
              }
            }
          })
          .catch((error) => console.log("error", error));
    }
  }, [logincheck]);
  return (
    <>
      {isProviderModal && (
        <ProviderModal
          showProviderModal={isProviderModal}
          setIsProviderModal={setIsProviderModal}
        />
      )}
      {isPerfectJobModal && (
          <PerfectJobModal
                isPerfectJobModal={isPerfectJobModal}
                setIsPerfectJobModal={setIsPerfectJobModal}
          />
      )}
      {isLoginModal && (
        <LoginModal
          isLoginModal={isLoginModal}
          setIsLoginModal={setIsLoginModal}
        />
      )}
      <Header page={"home"} />
      {!isPromo && (
        <div className="promocode">
          <p>
            <PromoCode />
            <button onClick={(a) => setIsPromo(true)}>+</button>
          </p>
        </div>
      )}

      <div className="container-fluid">
        <div className="container">
          <div className="better_care">
            <h2>{t("title-better")} </h2>
            <div className="right_banner">
              <img src="./images/home_right.svg" />
            </div>
            <div className="service_gurid">
              <GuidSection />
            </div>
          </div>
          <SafetySection />
        </div>
      </div>
      <Testimonials />
      <Footer />
    </>
  );
}
