import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import { Footer } from "../../components/common/Footer";
import { Header } from "../../components/common/Header";
import { SafetyFirstTab } from "./SafetyFirstTab";
import { SafetySecondTab } from "./SafetySecondTab";
import { SafetyThirdTab } from "./SafetyThirdTab";

import { fetchSafetyCenterData } from "../../api";

export function SafetyCenterPage() {
  const { t } = useTranslation();

  const [isCheck, setIsCheck] = useState(true);
  const [safetyData, setSafetyData] = useState({});
  const [selectTab, setSelectTab] = useState("tab1");

  const location = useLocation();

  useEffect(() => {
    const fetchData = async () => {
      if (isCheck) {
        if (location.hash === "#parents") {
          setSelectTab("tab2");
          window.scrollTo({ top: 330, behavior: "smooth" });
        } else if (location.hash === "#provider") {
          setSelectTab("tab1");
          window.scrollTo({ top: 330, behavior: "smooth" });
        } else if (location.hash === "#school") {
          setSelectTab("tab3");
          window.scrollTo({ top: 330, behavior: "smooth" });
        } else if (location.hash === "#safety_tips") {
          const section = document.querySelector( '#safety_tips' );
          section.scrollIntoView( { behavior: 'smooth', block: 'start' } );
        }
  
        try {
          const data = await fetchSafetyCenterData();
          setSafetyData(data);
          console.log(data);
        } catch (error) {
          console.error("Failed to fetch safety center data", error);
        }
  
        setIsCheck(false);
      }
    };
  
    fetchData();
  
    const question1 = document.getElementById("question1");
    const question2 = document.getElementById("question2");
    const question3 = document.getElementById("question3");
  
    if (question1) {
      question1.onclick = () =>
        localStorage.setItem(
          "faq",
          t("request-member-conduct")
        );
      window.location.hash = "provider";
    }
  
    if (question2) {
      question2.onclick = () =>
        localStorage.setItem(
          "faq",
          t("request-member-conduct")
        );
      window.location.hash = "parents";
    }
  
    if (question3) {
      question3.onclick = () =>
        localStorage.setItem(
          "faq",
          t("request-member-conduct")
        );
      window.location.hash = "school";
    }
  }, [isCheck]);

  return (
    <>
      <Header />
      <div className="container-fluid">
        <div className="container">
          <div className="contact privacy safety">
            <div className="left">
              <h2>{t("Safety Center")}</h2>
              {selectTab == "tab1" ? (
                <div
                  dangerouslySetInnerHTML={{
                    __html: t("description_for_providers", {
                      defaultValue: safetyData.description_for_providers,
                    }),
                  }}
                  className="privacycon safe_center"
                />
              ) : selectTab == "tab2" ? (
                <div
                  dangerouslySetInnerHTML={{
                    __html: t("description_for_parents", {
                      defaultValue: safetyData.description_for_parents,
                    }),
                  }}
                  className="privacycon safe_center"
                />
              ) : selectTab == "tab3" ? (
                <div
                  dangerouslySetInnerHTML={{
                    __html: t("description_for_school", {
                      defaultValue: safetyData.description_for_school,
                    }),
                  }}
                  className="privacycon safe_center"
                />
              ) : (
                ""
              )}
            </div>
            <div className="right">
              <img
                src={
                  "https://admin.stage.mysenscare.com/assets/cms/" +
                  safetyData.image
                }
              />
              <h3>
                {t(
                  "safety-important-us"
                )}
              </h3>
            </div>
          </div>
          <div className="contact safety" id="safety_tips">
            <h2>{t("Choose safety tips for")}</h2>
            <div className="tabing">
              <button
                className={selectTab == "tab1" ? "active" : ""}
                onClick={(e) => {
                  window.location.hash = "provider";
                  setSelectTab("tab1");
                }}
              >
                {t("For providers")}
              </button>
              <button
                className={selectTab == "tab2" ? "active" : ""}
                onClick={(e) => {
                  window.location.hash = "parents";
                  setSelectTab("tab2");
                }}
              >
                {t("For parents")}
              </button>
              <button
                className={selectTab == "tab3" ? "active" : ""}
                onClick={(e) => {
                  window.location.hash = "school";
                  setSelectTab("tab3");
                }}
              >
                {t("For schools")}
              </button>
            </div>
          </div>
        </div>

        <SafetyFirstTab selectTab={selectTab} />

        <SafetySecondTab selectTab={selectTab} />

        <SafetyThirdTab selectTab={selectTab} />
      </div>
      <Footer />
    </>
  );
}
