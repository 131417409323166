import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Modal from "react-bootstrap/Modal";

export const DeleteAccountModal = ({
  isDeleteAccountModal,
  setIsDeleteAccountModal,
  handleDeleteAccount,
}) => {
  const { t } = useTranslation();

  return (
    <Modal
      show={isDeleteAccountModal}
      onHide={(e) => setIsDeleteAccountModal(false)}
    >
      <Modal.Body>
        <div className="promocode_content cancelmembership deletemodel">
          <Link to="" onClick={(e) => setIsDeleteAccountModal(false)}>
            +
          </Link>
          <h2>
            <img src="/images/warning.svg" />
            <br />
            {t("Delete Account")}
          </h2>
          <div className="cancelmembershipp">
            <p>{t("Are you sure you want to delete your account?")} </p>
            <div className="button text-center">
              <div className="pull-right">
                <button className="btn" onClick={(e) => handleDeleteAccount()}>
                  {t("text-delete")}
                </button>
              </div>
              <div className="pull-right">
                <button
                  className="btn confirm"
                  onClick={(e) => setIsDeleteAccountModal(false)}
                >
                  {t("text-cancel")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
