import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  DAYS,
  TIMES_OF_DAYS,
  TIME_12_3_PM,
  TIME_12_6_AM,
  TIME_3_6_PM,
  TIME_6_9_AM,
  TIME_6_9_PM,
  TIME_9_12_AM,
  TIME_9_12_PM,
} from "../../../utils/variables";

export function Calendar({ profile, data1, data2, data }) {
  const { t } = useTranslation();

  const [isCheck, setIsCheck] = useState(true);
  const [calendarData, setCalendarData] = useState({});

  const handleClickCalendar = (x, e) => {
    if (profile == "profile") {
    } else {
      if (calendarData[x]) {
        delete calendarData[x];
        setCalendarData({ ...calendarData });
      } else {
        setCalendarData({ ...calendarData, [x]: e });
      }
    }
  };

  useEffect(() => {
    if (isCheck) {
      data1 !== null ? setCalendarData(data1) : setCalendarData({});
      setIsCheck(false);
    } else {
      data(data2, calendarData);
    }
  }, [calendarData, data2, data1]);

  return (
    <>
      <div className="custom_calender">
        <div className="leftc">
          <ul>
            {TIMES_OF_DAYS.map((time) => (
              <li key={time}>
                <img
                  src={`${window.location.origin}/images/${time}.svg`}
                  alt={time}
                />
              </li>
            ))}
          </ul>
        </div>
        <div className="middel">
          <ul>
            <li>
              <span></span>
              <ul>
                {DAYS.map((day) => (
                  <li key={day}>{t(`day-${day}`)}</li>
                ))}
              </ul>
            </li>
            <li>
              <span>{t("time-6-9-am")}</span>
              <ul>
                {TIME_6_9_AM.map((day) => (
                  <li
                    key={day.name}
                    onClick={(e) => handleClickCalendar(day.name, day.time)}
                    className={
                      calendarData[day.name] === day.time ? "active" : ""
                    }
                  ></li>
                ))}
              </ul>
            </li>
            <li>
              <span>{t("time-9-12-pm")}</span>
              <ul>
                {TIME_9_12_PM.map((day) => (
                  <li
                    key={day.name}
                    onClick={(e) => handleClickCalendar(day.name, day.time)}
                    className={
                      calendarData[day.name] === day.time ? "active" : ""
                    }
                  ></li>
                ))}
              </ul>
            </li>
            <li>
              <span>{t("time-12-3-pm")}</span>
              <ul>
                {TIME_12_3_PM.map((day) => (
                  <li
                    key={day.name}
                    onClick={(e) => handleClickCalendar(day.name, day.time)}
                    className={
                      calendarData[day.name] === day.time ? "active" : ""
                    }
                  ></li>
                ))}
              </ul>
            </li>
            <li>
              <span>{t("time-3-6-pm")}</span>
              <ul>
                {TIME_3_6_PM.map((day) => (
                  <li
                    key={day.name}
                    onClick={(e) => handleClickCalendar(day.name, day.time)}
                    className={
                      calendarData[day.name] === day.time ? "active" : ""
                    }
                  ></li>
                ))}
              </ul>
            </li>
            <li>
              <span>{t("time-6-9-pm")}</span>
              <ul>
                {TIME_6_9_PM.map((day) => (
                  <li
                    key={day.name}
                    onClick={(e) => handleClickCalendar(day.name, day.time)}
                    className={
                      calendarData[day.name] === day.time ? "active" : ""
                    }
                  ></li>
                ))}
              </ul>
            </li>
            <li>
              <span>{t("time-9-12-am")}</span>
              <ul>
                {TIME_9_12_AM.map((day) => (
                  <li
                    key={day.name}
                    onClick={(e) => handleClickCalendar(day.name, day.time)}
                    className={
                      calendarData[day.name] === day.time ? "active" : ""
                    }
                  ></li>
                ))}
              </ul>
            </li>
            <li>
              <span>{t("time-12-6-am")}</span>
              <ul>
                {TIME_12_6_AM.map((day) => (
                  <li
                    key={day.name}
                    onClick={(e) => handleClickCalendar(day.name, day.time)}
                    className={
                      calendarData[day.name] === day.time ? "active" : ""
                    }
                  ></li>
                ))}
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
}
