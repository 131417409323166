import React from "react";
import { useTranslation } from "react-i18next";

import { HIRE_OPTIONS } from "../../../utils/variables";

import styles from "./HireBecome.module.css";

export function HireBecome() {
  const { t } = useTranslation();

  return (
    <div className={styles.hire_because}>
      <h4>{t("title-hire-become")}</h4>
      <ul>
        {HIRE_OPTIONS.map(({ imgSrc, altText, translationKey }) => (
          <li key={translationKey}>
            <img src={imgSrc} alt={altText} />
            <span>{t(translationKey)}</span>
          </li>
        ))}
      </ul>
    </div>
  );
}
