import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Modal from "react-bootstrap/Modal";

export const ApologizeModal = ({ isApologizeModal, setIsApologizeModal }) => {
  const { t } = useTranslation();

  return (
    <Modal
      show={isApologizeModal}
      className="sorryto"
      onHide={(e) => window.location.replace("/")}
    >
      <Modal.Body>
        <div className="promocode_content cancelmembership ">
          <Link to="/" onClick={(e) => setIsApologizeModal(false)}>
            +
          </Link>
          <h2>
            <img src="/images/return.png" />
            <br />
            {t("We are sorry to see you go!")}
          </h2>
          <p>
            {t("You can")}
            <Link to={"/signup"}>{t("come back")}</Link> {t("anytime.")}
          </p>
        </div>
      </Modal.Body>
    </Modal>
  );
};
