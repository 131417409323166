import { useTranslation } from "react-i18next";

import { useRatesStore } from "../../../store/useRatesStore";
import { useErrorFieldStore } from "../../../store/useErrorsFieldsStore";
import { useDetailParentsStore } from "../../../store/useDetailParentsStore";
import { useProfileDetailsStore } from "../../../store/useProfileDetailsStore";
import { useProfileStore } from "../../../store/useProfileStore";
import React from "react";
import OldSlider from "../../../components/common/Slider/OldSlider";

export function ServiceTypeFour() {
  const { t, i18n } = useTranslation();
  const language = i18n.language;

  const [errorField, setErrorField] = useErrorFieldStore((state) => [
    state.errorField,
    state.setErrorField,
  ]);
  const { detailParents, setDetailParents } = useDetailParentsStore();

  const { detail } = useProfileStore();

  const { tutorRate, setTutorRate } = useRatesStore();

  const { qualifications, setQualifications } = useProfileDetailsStore();
  return (
    <div className="tutor parents">
      <h2 className="border">
        <img src="./images/tuter.svg" /> {t("text-tutor")}
      </h2>
      <div className="form_group qualification">
        <label>
          {t("classes-question")}
          <span className={errorField.tutorliketoteach && errorField.tutorliketoteach !== "" ? "starred" : ""}>
            *
          </span>
        </label>
        <div className="checkbox">
          <ul
            onClick={(e) =>
              setErrorField({
                tutorliketoteach: "",
              })
            }
          >
            <li>
              <input
                type="checkbox"
                name=""
                defaultChecked={
                  qualifications.English == "English" ? true : false
                }
                onClick={(e) => {
                  if (e.target.checked) {
                    setQualifications({
                      ...qualifications,
                      English: "English",
                    });
                  } else {
                    setQualifications({
                      ...qualifications,
                      English: "",
                    });
                  }
                }}
              />
              <span> {t("english")}</span>
            </li>
            <li>
              <input
                type="checkbox"
                name=""
                defaultChecked={
                  qualifications.Serbian == "Serbian" ? true : false
                }
                onClick={(e) => {
                  if (e.target.checked) {
                    setQualifications({
                      ...qualifications,
                      Serbian: "Serbian",
                    });
                  } else {
                    setQualifications({
                      ...qualifications,
                      Serbian: "",
                    });
                  }
                }}
              />
              <span> {t("serbian")}</span>
            </li>
            <li>
              <input
                type="checkbox"
                name=""
                defaultChecked={
                  qualifications.Mathematics == "Mathematics" ? true : false
                }
                onClick={(e) => {
                  if (e.target.checked) {
                    setQualifications({
                      ...qualifications,
                      Mathematics: "Mathematics",
                    });
                  } else {
                    setQualifications({
                      ...qualifications,
                      Mathematics: "",
                    });
                  }
                }}
              />
              <span> {t("mathematics")}</span>
            </li>
            <li>
              <input
                type="checkbox"
                name=""
                defaultChecked={
                  qualifications.Physics == "Physics" ? true : false
                }
                onClick={(e) => {
                  if (e.target.checked) {
                    setQualifications({
                      ...qualifications,
                      Physics: "Physics",
                    });
                  } else {
                    setQualifications({
                      ...qualifications,
                      Physics: "",
                    });
                  }
                }}
              />
              <span> {t("physics")}</span>
            </li>
            <li>
              <input
                type="checkbox"
                name=""
                defaultChecked={
                  qualifications.Chemistry == "Chemistry" ? true : false
                }
                onClick={(e) => {
                  if (e.target.checked) {
                    setQualifications({
                      ...qualifications,
                      Chemistry: "Chemistry",
                    });
                  } else {
                    setQualifications({
                      ...qualifications,
                      Chemistry: "",
                    });
                  }
                }}
              />
              <span> {t("chemistry")}</span>
            </li>
            <li className="aline">
              <input
                type="checkbox"
                name=""
                defaultChecked={qualifications.Other ? true : false}
              />
              <span>
                {t("text-other")}
                <input
                  type="text"
                  placeholder={t("text-type")}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setQualifications({
                        ...qualifications,
                        Other: e.target.value,
                      });
                    } else {
                      setQualifications({
                        ...qualifications,
                        Other: "",
                      });
                    }
                  }}
                  defaultValue={
                    qualifications.Other ? qualifications.Other : ""
                  }
                />
              </span>
            </li>
          </ul>
        </div>
      </div>
      <div className="form_group full">
        <label>
          {t("online-services")}
          <span
            className={
              errorField.tutorintrestedonlinecls && errorField.tutorintrestedonlinecls !== "" ? "starred" : ""
            }
          >
            *
          </span>
        </label>
        <div className="checkbox create">
          <ul
            onClick={(e) =>
              setErrorField({
                tutorintrestedonlinecls: "",
              })
            }
          >
            <li>
              <input
                type="radio"
                name="a1"
                checked={
                  detailParents.tutorintrestedonlinecls == "Yes" ? true : false
                }
                onClick={(e) =>
                  setDetailParents({
                    tutorintrestedonlinecls: "Yes",
                  })
                }
              />
              <span> {t("confirm")}</span>
            </li>
            <li>
              <input
                type="radio"
                name="a1"
                checked={
                  detailParents.tutorintrestedonlinecls == "No" ? true : false
                }
                onClick={(e) =>
                  setDetailParents({
                    tutorintrestedonlinecls: "No",
                  })
                }
              />
              <span> {t("objection")}</span>
            </li>
          </ul>
        </div>
      </div>
      <div className="form_group full">
        <label>
          {t("Rate per hour")} {detail.country == "Serbia" ? "(RSD)" : "(USD)"}
          <span className={errorField.nanyperhrrate && errorField.nanyperhrrate !== "" ? "starred" : ""}>
            *
          </span>
        </label>
        <OldSlider rate={tutorRate} setRate={setTutorRate} serbian={language === 'sr'}/>
        <span className="price">
          {language === 'sr'
              ? tutorRate.min * 100 + " - " + tutorRate.max * 100 + (tutorRate.max === 60 ? "+" : "")
              : tutorRate.min + " - " + tutorRate.max + (tutorRate.max === 60 ? "+" : "")}
        </span>
      </div>
    </div>
  );
}
