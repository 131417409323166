import React from "react";
import { Link } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import { useTranslation } from "react-i18next";

import { FlagIcon } from "../../../../assets/image/FlagIcon";

export const JobPostModal = ({
  isJobPost,
  handleCloseJobPost,
  editPost,
  setEditPost,
  handleEditPost,
  jobData,
}) => {
  const { t } = useTranslation();

  return (
    <Modal
      show={isJobPost}
      onHide={(e) => {
        handleCloseJobPost();
      }}
      className="question_new jobs"
    >
      <Modal.Body>
        <div className="younger">
          <Link
            to=""
            onClick={(e) => {
              handleCloseJobPost();
            }}
          >
            +
          </Link>
          <br></br>
          <p>
            <b>{t("edit-post")}</b>
          </p>
          <div className="Profile_complete">
            <div className="detail work-experience job_performance job_post setp4">
              <div className="form_group full">
                <label>
                  <strong>{t("positions-gap")}</strong>
                </label>
                <br />
                <label>
                  {t("post-job-title")}
                  <span>*</span>
                </label>
                <textarea
                  rows={2}
                  placeholder={
                    t("text-experience-gap") +
                    (jobData ? jobData[0].job_type : "") +
                    t("years-experience-gap")
                  }
                  maxlength="70"
                  name="message"
                  onChange={(e) => {
                    setEditPost({ ...editPost, title: e.target.value });
                  }}
                  defaultValue={jobData ? jobData[0].title : ""}
                ></textarea>
                <span>
                  {t("number-characters")} {70 - jobData[0].title.length}
                </span>
              </div>
              <div className="form_group full sec">
                <label>
                  {t("job-description")}
                  <span>*</span>
                </label>
                <textarea
                  rows={4}
                  placeholder={
                    jobData[0].job_type == "Nanny"
                      ? t("post-jelena-nanny")
                      : jobData[0].job_type == "Special Education Teacher"
                      ? t("post-jelena-teacher")
                      : jobData[0].job_type ==
                        "Special Education Paraprofessional"
                      ? t("post-jelena-paraprofessional")
                      : jobData[0].job_type == "Tutor"
                      ? t("post-jelena-english")
                      : ""
                  }
                  maxlength="300"
                  name="message"
                  onChange={(e) => {
                    setEditPost({ ...editPost, description: e.target.value });
                  }}
                  defaultValue={jobData ? jobData[0].description : ""}
                ></textarea>
                <span>
                  {t("number-characters")} {300 - jobData[0].description.length}
                </span>
              </div>
              <div className="job_note" id="job_note">
                <FlagIcon />
                <p>
                  {t(
                    "profile-information-applied"
                  )}
                  <br />
                  {t("please-update-profile")}
                  <Link
                    to="/search-parents"
                    onClick={(e) => {
                      localStorage.setItem("edittime", "editall");
                      localStorage.setItem("search", "Profile");
                    }}
                    target={"_blank"}
                  >
                    {t("text-here")}
                  </Link>
                  {t("changes-meantime")}
                </p>
              </div>
              <div class="form_group full socialpost ">
                <label>
                  {t("consent-platform")}
                  <span>*</span>
                </label>
                <div class="checkbox create">
                  <ul>
                    <li style={{ width: "25% !important" }}>
                      <input
                        type="radio"
                        name="e"
                        onClick={(e) => {
                          setEditPost({
                            ...editPost,
                            plateformonsocialmedia: "Yes",
                          });
                        }}
                        defaultChecked={
                          jobData[0].plateformonsocialmedia == "Yes"
                            ? true
                            : false
                        }
                      />
                      <span> {t("confirm")}</span>
                    </li>
                    <li>
                      <input
                        type="radio"
                        name="e"
                        onClick={(e) => {
                          setEditPost({
                            ...editPost,
                            plateformonsocialmedia: "No",
                          });
                        }}
                        defaultChecked={
                          jobData[0].plateformonsocialmedia == "No"
                            ? true
                            : false
                        }
                      />
                      <span> {t("objection")}</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="button text-center">
            <div class="pull-right">
              <button class="btn" onClick={(e) => handleCloseJobPost()}>
                {t("text-back")}
              </button>
            </div>
            <div class="pull-right">
              <button
                class="btn confirm"
                onClick={(e) => {
                  if (
                    editPost.title != "" &&
                    editPost.description != "" &&
                    editPost.plateformonsocialmedia
                  ) {
                    handleEditPost();
                  }
                }}
              >
                {t("text-publish")}
              </button>
            </div>
          </div>
          <br />
        </div>
      </Modal.Body>
    </Modal>
  );
};
