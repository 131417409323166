import React from "react";
import { useTranslation } from "react-i18next";
import Modal from "react-bootstrap/Modal";

export const ShareModal = ({
  isShareModal,
  setIsShareModal,
  handleSubmitDocument,
  providerData,
  setDocument,
}) => {
  const { t } = useTranslation();

  return (
    <Modal
      show={isShareModal}
      onHide={(e) => setIsShareModal(false)}
      className=""
    >
      <Modal.Body>
        <div className="promocode_content share_profile">
          <h2>{t("text-send-with")}</h2>
          <br />
          <div className="list_prorofile">
            <ul>
              {providerData.recommendationsfile &&
              providerData.recommendationsfile_deleted_at == 0 ? (
                <li>
                  <img src={window.location.origin + "/images/team.svg"} />
                  <p>
                    {t("text-recommendation")}
                    {providerData.recommendationsfile_original
                      ? providerData.recommendationsfile_original.substr(0, 20)
                      : ""}
                  </p>
                  <input
                    type="radio"
                    name=""
                    onClick={(e) => {
                      setDocument({
                        ...document,
                        recommendationsfile: providerData.recommendationsfile,
                      });
                    }}
                  />
                  <span></span>
                </li>
              ) : (
                ""
              )}
              {providerData.cv && providerData.cv_deleted_at == 0 ? (
                <li>
                  <img src={window.location.origin + "/images/team.svg"} />
                  <p>
                    {t("text-cv-underline")}
                    {providerData.cv_original
                      ? providerData.cv_original.substr(0, 20)
                      : ""}
                  </p>
                  <input
                    type="radio"
                    name=""
                    onClick={(e) => {
                      setDocument({ ...document, cv: providerData.cv });
                    }}
                  />
                  <span></span>
                </li>
              ) : (
                ""
              )}
              {providerData.qualificationscertificatesdoc &&
              providerData.qualificationscertificatesdoc_deleted_at == 0 ? (
                <li>
                  <img src={window.location.origin + "/images/team.svg"} />
                  <p>
                    {t("text-certificate")}
                    {providerData.qualificationscertificatesdoc_original
                      ? providerData.qualificationscertificatesdoc_original.substr(
                          0,
                          20
                        )
                      : ""}
                  </p>
                  <input
                    type="radio"
                    name=""
                    onClick={(e) => {
                      setDocument({
                        ...document,
                        qualificationscertificatesdoc:
                          providerData.qualificationscertificatesdoc,
                      });
                    }}
                  />
                  <span></span>
                </li>
              ) : (
                ""
              )}
              {providerData.backgrounddoc &&
              providerData.backgrounddoc_deleted_at == 0 ? (
                <li>
                  <img src={window.location.origin + "/images/team.svg"} />
                  <p>
                    {t("text-background")}
                    {providerData.backgrounddoc_original
                      ? providerData.backgrounddoc_original.substr(0, 20)
                      : ""}
                  </p>
                  <input
                    type="radio"
                    name=""
                    onClick={(e) => {
                      setDocument({
                        ...document,
                        backgrounddoc: providerData.backgrounddoc,
                      });
                    }}
                  />
                  <span></span>
                </li>
              ) : (
                ""
              )}
            </ul>
            <button onClick={(e) => setIsShareModal(false)}>
              {t("text-cancel")}
            </button>
            <button onClick={(e) => handleSubmitDocument()}>
              {t("text-submit")}
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
