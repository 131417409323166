import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Modal from "react-bootstrap/Modal";

export const SuccessModal = ({ setShowSuccessModal, showSuccessModal }) => {
  const { t } = useTranslation();

  return (
    <Modal show={showSuccessModal} onHide={(e) => setShowSuccessModal(false)}>
      <Modal.Body>
        <div className="promocode_content successpass">
          <Link to="" onClick={(e) => setShowSuccessModal(false)}>
            +
          </Link>
          <p>{t("password-updated")}</p>
          <Link className="loginbtn" to={"/login"}>
            {t("Log in")}
          </Link>
        </div>
      </Modal.Body>
    </Modal>
  );
};
