import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Link, NavLink, useNavigate, useParams } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { api } from "../../urls";

import { DATA_COUNTRY } from "../../utils/data";
import { NotificationCount } from "../../components/common/NotificationCount";
import { NotificationTabParents } from "../../components/common/NotificationTabParents";
import { NotificationTabProvider } from "../../components/common/NotificationTabProvider";
import { ParentsNotificationTabs } from "../../components/common/ParentsNotificationTabs";
import { ProviderNotificationTabs } from "../../components/common/ProviderNotificationTabs";
import { ViewEdit } from "../../components/common/ViewEdit";
import { ViewEditProvider } from "../../components/common/ViewEditProvider";
import { ProfessionChange } from "../../components/common/ProfessionChange";
import { FavoriteProvider } from "../../components/common/FavoriteProvider";
import { JobHistoryParents } from "../../components/Job/JobHistoryParents";
import { JobHistory } from "../../components/Job/JobHistory";
import { LocationContent } from "../../components/common/LocationContent";
import { MessageTab } from "../../components/Message/MessageTab";
import { Footer } from "../../components/common/Footer";
import { Header } from "../../components/common/Header";
import { PhoneIcon } from "../../assets/image/PhoneIcon";
import { ServiceIcon } from "../../assets/image/ServiceIcon";
import { StarIcon } from "../../assets/image/StarIcon";
import { WhiteStarIcon } from "../../assets/image/WhiteStarIcon";
import { SquareServiceIcon } from "../../assets/image/SquareServiceIcon";
import { ArrowUpIcon } from "../../assets/image/ArrowUpIcon";
import { ConfirmIcon } from "../../assets/image/ConfirmIcon";
import { NannyIcon } from "../../assets/image/NannyIcon";
import { TeacherIcon } from "../../assets/image/TeacherIcon";
import { ParaprofessionalIcon } from "../../assets/image/ParaprofessionalIcon";
import { TutorIcon } from "../../assets/image/TutorIcon";
import { ArrowRightIcon } from "../../assets/image/ArrowRightIcon";
import { ArrowScrollIcon } from "../../assets/image/ArrowScrollIcon";
import { usePersonalInfoStore } from "../../store/usePersonalInfoStore";
import { useCategoryStore } from "../../store/useCategoryStore";
import { useModalStore } from "../../store/useModalStore";
import { PhotoUploadModal } from "../../components/Modals/PhotoUploadModal";
import { SignoutModal } from "../../components/Modals/SignoutModal";
import { ApologizeModal } from "../../components/Modals/ApologizeModal";
import { DeleteAccountModal } from "../../components/Modals/DeleteAccountModal";
import { SurveyModal } from "../../components/Modals/SurveyModal";
import { WarningModal } from "../../components/Modals/WarningModal";
import { DisableAccountModal } from "../../components/Modals/DisableAccountModal";
import { NewCardModal } from "../../components/Modals/NewCardModal";
import { SearchCandidate } from "./SearchCandidate";
import { SearchLeftSection } from "./SearchLeftSection";
import { SearchRightSection } from "./SearchRightSection";
import { ProviderModalComponent } from "./ProviderModalComponent";
import { MembershipModal } from "./Modals/MembershipModal";
import { SuccessModal } from "./Modals/SuccessModal";
import { ChangePasswordModal } from "./Modals/ChangePasswordModal";
import { SearchProviderFilter } from "./SearchProviderFilter";
import { useSearchStore } from "../../store/useSearchStore";

export function SearchProvidersPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const sliderRef = useRef();
  const slugdata = useParams();
  const data = slugdata.id;

  const { jobCategory } = useCategoryStore();
  const { filter, search, languageSpeak, setSearch, setLanguageSpeak } =
    useSearchStore();

  const {
    personalInfo,
    setPersonalInfo,
    password,
    errorPassword,
    setErrorErrorPassowrd,
  } = usePersonalInfoStore();

  const {
    isNewCardModal,
    isMembershipModal,
    isWarningModal,
    isDeleteAccountModal,
    isApologizeModal,
    isSignoutModal,
    isSurveyModal,
    setIsEditCardModal,
    setIsNewCardModal,
    setIsMembershipModal,
    setIsWarningModal,
    setIsAutoDeleteModal,
    setIsApologizeModal,
    setIsSignoutModal,
    setIsSurveyModal,
  } = useModalStore();

  const [rateData, setRateData] = useState([]);
  const [rateSortData, setRateSortData] = useState([]);
  const [showProviderModal, setShowProviderModal] = useState(false);
  const [rateNumber, setRateNumber] = useState({
    min: 0,
    max: 0,
  });

  const [contactCode, setContactCode] = useState({
    code: "",
    flag: "",
  });
  const [profile, setProfile] = useState({});
  const [interestData, setInterestData] = useState([]);

  const [count, setCount] = useState(true);
  const [result, setResult] = useState("");
  const [filterCheck, setFilterCheck] = useState(true);
  const id = localStorage.getItem('id');
  const [advanceSearch, setAdvanceSearch] = useState(id === null);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [isElement, setIsElement] = useState(true);
  const [photoUpload, setPhotoUpload] = useState(false);
  const [listShow, setListShow] = useState(10);
  const [showModal, setShowModal] = useState(false);
  const [editCountry, setEditCountry] = useState(false);
  const [dataCountry, setDataCountry] = useState("");
  const [isCatOpen, setIsCatOpen] = useState(true);
  const [children, setChildren] = useState("");
  const [childrenAge, setchildrenAge] = useState([]);
  const [category, setCategory] = useState(
    filter.category && filter.category[0] ? filter.category : []
  );
  const [latLong, setLatLong] = useState({
    lat: "",
    lng: "",
  });

  const [jobData, setJobData] = useState(
    filter.cat
      ? filter.cat
      : {
          data1: "",
          data2: "",
          data3: "",
          data4: "",
        }
  );

  const [profileSection, setProfileSection] = useState(
    localStorage.getItem("side") && localStorage.getItem("side") != ""
      ? localStorage.getItem("side")
      : ""
  );
  const [subTab, setSubTab] = useState(data ? data : "");

  const [editProfile, setEditProfile] = useState("");
  const [photo, setPhoto] = useState("");
  const [isDisableAccountModal, setIsDisableAccountModal] = useState(false);

  const [surveyData, setSurveyData] = useState({
    resion: "",
    other: "",
    status: "",
  });
  const [membership, setMembership] = useState("");
  const [image, setImage] = useState(null);
  const [code, setCode] = useState(true);

  const [phoneVerify, setPhoneVerify, emailVerify, setEmailVerify] =
    useCategoryStore((state) => [
      state.phoneVerify,
      state.setPhoneVerify,
      state.emailVerify,
      state.setEmailVerify,
    ]);

  const handleClose = () => setShowModal(false);

  const handleCloseProviderModal = () => {
    setShowProviderModal(false);
  };
  const handleShowProviderModal = () => {
    setShowProviderModal(true);
  };

  const handleSelectOption = (data) => {
    let sum = false;
    category.map((e, index) => {
      if (e.name == data) {
        sum = true;
        category.splice(index, 1);
      }
    });
    if (sum == false) {
      category.push({ name: data });
    }
    setTimeout(() => {
      setCategory([...category]);
    }, 500);
  };

  const handleChildrenAge = (data) => {
    let sum = false;
    childrenAge.map((e, index) => {
      if (e.name == data) {
        sum = true;
        childrenAge.splice(index, 1);
      }
    });
    if (sum == false) {
      childrenAge.push({ name: data });
    }
    setTimeout(() => {
      setchildrenAge([...childrenAge]);
    }, 500);
  };
  const handleElementById = (e, x) => {
    if (isCatOpen) {
      document.getElementById(e).style.display = "block";
      document.getElementById(x).style.display = "block";
      setIsCatOpen(false);
    } else {
      document.getElementById(e).style.display = "none";
      document.getElementById(x).style.display = "none";
      setIsCatOpen(true);
    }
  };
  const handleSearchElement = (e) => {
    setAdvanceSearch(true);
    if (isCatOpen) {
      document.getElementById(e).style.display = "block";
      setIsCatOpen(false);
    } else {
      document.getElementById(e).style.display = "none";
      setIsCatOpen(true);
    }
  };

  const fetchProfileData = async () => {
    const token = localStorage.getItem("token");

    let myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    try {
      const response = await fetch(api + "/api/v1/details", requestOptions);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const result = await response.json();
      setProfile(result.data);
      setAdvanceSearch(false);
    } catch (error) {
      setAdvanceSearch(true);
      console.error("Error fetching profile data:", error);
    }
  };

  const fetchRateData = (distance) => {
    setRateData([]);
    setInterestData([]);
    setResult("");
    const myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      api +
        "/api/" +
        (localStorage.getItem("token") != null
          ? "v1/favproviderlisting"
          : "providerlisting") +
        (localStorage.getItem("searchdata")
          ? JSON.parse(localStorage.getItem("searchdata"))
          : "?keyword=" +
            search.keyword +
            "&distance=" +
            (distance ? distance : search.distance) +
            "&lat=" +
            latLong.lat +
            "&lng=" +
            latLong.lng +
            "&city=" +
            search.city +
            "&zip=" +
            search.zip +
            (jobData.data1 != ""
              ? "&jobcategory[]=" + jobData.data1
              : "&jobcategory[]=") +
            (jobData.data2 != ""
              ? "&jobcategory[]=" + jobData.data2
              : "&jobcategory[]=") +
            (jobData.data3 != ""
              ? "&jobcategory[]=" + jobData.data3
              : "&jobcategory[]=") +
            (jobData.data4 != ""
              ? "&jobcategory[]=" + jobData.data4
              : "&jobcategory[]=") +
            "&withinweek=" +
            search.withinweek +
            "&withinmonth=" +
            search.withinmonth +
            "&nanyintrestedin[]=" +
            search.tutorintrestedin +
            "&nanyintrestedin[]=" +
            search.tutorintrestedin1 +
            "&nanyintrestedin[]=" +
            search.tutorintrestedin2 +
            "&rates=" +
            (rateNumber.max > 0 ? rateNumber.min + "-" + rateNumber.max : "") +
            "&tutorexp=" +
            search.tutorexp +
            "&nanyworkwithnochild=" +
            children +
            "&childsocialneed=" +
            search.childsocialneed +
            "&workingabroad=" +
            search.workingabroad +
            (jobCategory.data1 != ""
              ? "&nanyprefchildage[]=" + jobCategory.data1
              : "&nanyprefchildage[]=") +
            (jobCategory.data2 != ""
              ? "&nanyprefchildage[]=" + jobCategory.data2
              : "&nanyprefchildage[]=") +
            (jobCategory.data3 != ""
              ? "&nanyprefchildage[]=" + jobCategory.data3
              : "&nanyprefchildage[]=") +
            (jobCategory.data4 != ""
              ? "&nanyprefchildage[]=" + jobCategory.data4
              : "&nanyprefchildage[]=") +
            "&carorlicence=" +
            search.carorlicence +
            "&backgroundstatus=" +
            search.backgroundstatus +
            "&nanynewbornexp=" +
            search.nanynewbornexp),
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        let data = result.data.filter((data) => data.nanyperhrrate != null);
        setRateData(data);
        setRateSortData(data);
        localStorage.setItem(
          "searchdata",
          JSON.stringify(
            "?keyword=" +
              search.keyword +
              "&distance=" +
              (distance ? distance : search.distance) +
              "&lat=" +
              latLong.lat +
              "&lng=" +
              latLong.lng +
              "&city=" +
              search.city +
              "&zip=" +
              search.zip +
              (jobData.data1 != ""
                ? "&jobcategory[]=" + jobData.data1
                : "&jobcategory[]=") +
              (jobData.data2 != ""
                ? "&jobcategory[]=" + jobData.data2
                : "&jobcategory[]=") +
              (jobData.data3 != ""
                ? "&jobcategory[]=" + jobData.data3
                : "&jobcategory[]=") +
              (jobData.data4 != ""
                ? "&jobcategory[]=" + jobData.data4
                : "&jobcategory[]=") +
              "&withinweek=" +
              search.withinweek +
              "&withinmonth=" +
              search.withinmonth +
              "&nanyintrestedin[]=" +
              search.tutorintrestedin +
              "&nanyintrestedin[]=" +
              search.tutorintrestedin1 +
              "&nanyintrestedin[]=" +
              search.tutorintrestedin2 +
              "&rates=" +
              (rateNumber.max > 0
                ? rateNumber.min + "-" + rateNumber.max
                : "") +
              "&tutorexp=" +
              search.tutorexp +
              "&nanyworkwithnochild=" +
              children +
              "&childsocialneed=" +
              search.childsocialneed +
              "&workingabroad=" +
              search.workingabroad +
              (jobCategory.data1 != ""
                ? "&nanyprefchildage[]=" + jobCategory.data1
                : "&nanyprefchildage[]=") +
              (jobCategory.data2 != ""
                ? "&nanyprefchildage[]=" + jobCategory.data2
                : "&nanyprefchildage[]=") +
              (jobCategory.data3 != ""
                ? "&nanyprefchildage[]=" + jobCategory.data3
                : "&nanyprefchildage[]=") +
              (jobCategory.data4 != ""
                ? "&nanyprefchildage[]=" + jobCategory.data4
                : "&nanyprefchildage[]=") +
              "&carorlicence=" +
              search.carorlicence +
              "&backgroundstatus=" +
              search.backgroundstatus +
              "&nanynewbornexp=" +
              search.nanynewbornexp
          )
        );
        setTimeout(() => {
          setResult("ubb");
        }, 500);
      })
      .catch((error) => console.log("error", error));
    //  highratedprovider
    fetch(
      api +
        "/api/" +
        (localStorage.getItem("token") == null
          ? "v1/authhighratedprovider"
          : "v1/authhighratedprovider"),
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        setInterestData(result.data);
      })
      .catch((error) => console.log("error", error));
  };

  const handleParseRate = (rate) => {
    const values = rate.substr(rate.lastIndexOf("\\") + 1).split("-");
    return {
      min: parseInt(values[0]),
      max: parseInt(values[1]),
    };
  };

  const handleRate = (e) => {
    setRateData([]);
    if (e === "Reviews") {
      const sortedData = [...rateSortData].sort(
        (a, b) => b.reviewcount - a.reviewcount
      );
      setRateData(sortedData);
    } else if (e === "Low to high") {
      const sortedData = [...rateSortData].sort((a, b) => {
        const rangeA = handleParseRate(a.nanyperhrrate);
        const rangeB = handleParseRate(b.nanyperhrrate);

        return rangeA.min - rangeB.min || rangeA.max - rangeB.max;
      });
      setRateData(sortedData);
    } else if (e === "High to low") {
      const sortedData = [...rateSortData].sort((a, b) => {
        const rangeA = handleParseRate(a.nanyperhrrate);
        const rangeB = handleParseRate(b.nanyperhrrate);
        return rangeB.min - rangeA.min || rangeB.max - rangeA.max;
      });
      setRateData(sortedData);
    } else if (e === "Distance") {
      setSearch({ ...search, distance: 5 });
      localStorage.removeItem("searchdata");
      fetchRateData(10);
    } else if (e === "Relevance") {
      setRateData(rateSortData);
    }
  };

  const handleElementContent = (e, x, z) => {
    console.log(e, x);
    document.getElementById(e).style.display = "none";
    document.getElementById(x).style.display = "block";
    if (isElement) {
      document.getElementById(z).style.display = "block";
      setIsElement(false);
    } else {
      setIsElement(true);
      document.getElementById(z).style.display = "none";
    }
  };

  const handleFilterComponents = () => {
    if (filterCheck) {
      document.getElementById("filteron").style.display = "block";
      setFilterCheck(false);
    } else {
      document.getElementById("filteron").style.display = "none";
      setFilterCheck(true);
    }
  };
  const getLatlong = (latlong2, e, addresss) => {
    e.then(function (result) {
      setLatLong(result);
    });
    let x = "";
    let y = "";
    for (let i = 0; i < latlong2.address_components.length; i++) {
      for (let j = 0; j < latlong2.address_components[i].types.length; j++) {
        if (
          latlong2.address_components[i].types[j] ==
          "administrative_area_level_1"
        ) {
          x = latlong2.address_components[i].long_name;
        }
      }
    }
    for (let i = 0; i < latlong2.address_components.length; i++) {
      for (let j = 0; j < latlong2.address_components[i].types.length; j++) {
        if (
          latlong2.address_components[i].types[j] == "postal_code" ||
          latlong2.address_components[i].types[j] == "street_number"
        ) {
          y = latlong2.address_components[i].long_name;
        }
      }
    }
    latlong2.address_components.map((e) => {
      e.types.map((a) => {
        if (a == "country") {
          setProfile({
            ...profile,
            country: e.long_name,
            address: addresss,
            zip: y,
            city: x,
          });
        }
      });
    });
    if (e != "getLatLng") {
      e.then(function (result) {
        setLatLong(result);
        setSearch({ ...search, zip: parseInt(addresss) });
        console.log(result);
      });
    } else {
      setSearch({ ...search, zip: "" });
    }
  };

  const handleCodeSelect = () => {
    if (code) {
      setCode(false);
    } else {
      setCode(true);
    }
  };

  const handleUpdateProfile = () => {
    if ( emailVerify && emailVerify.alemail == "") {
      const myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Bearer " + localStorage.getItem("token")
      );
      myHeaders.append("Content-Type", "application/json");
      const raw = JSON.stringify({
        address: profile.address,
        last_name: profile.last_name,
        city: profile.city,
        zip: profile.zip,
        country: profile.country,
        lat: latLong.lat ? latLong.lat : profile.lat,
        lng: latLong.lng ? latLong.lng : profile.lng,
        countrycode: contactCode.code ? contactCode.code : profile.countrycode,
        phone: profile.phone,
        email: emailVerify.email ? emailVerify.email : profile.email,
      });
      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(api + "/api/v1/updateprofile", requestOptions)
        .then((response) => response.json())
        .then((result) => {
          if (result.success) {
            setTimeout(() => {
              localStorage.setItem(
                "name",
                profile.first_name + " " + profile.last_name
              );
              setProfile(result.data.user);
              setEditProfile("");
            }, 1000);
          }
          console.log(result);
        })
        .catch((error) => console.log("error", error));
    } else {
    }
  };

  const handleDisableAccount = () => {
    const myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      account_status: "DISABLE",
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(api + "/api/v1/disableaccount", requestOptions)
      .then((response) => response.json())
      .then(() => {
        setIsDisableAccountModal(false);
        setProfile({ ...profile, account_status: "DISABLE" });
        setIsSurveyModal(true);
        setSurveyData({ ...surveyData, status: "Disable" });
      })
      .catch((error) => console.log("error", error));
  };

  const handleDeleteAccount = () => {
    const myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(api + "/api/v1/deleteaccount", requestOptions)
      .then((response) => response.json())
      .then(() => {
        setSurveyData({ ...surveyData, status: "Delete" });
        setIsSurveyModal(true);
      })
      .catch((error) => console.log("error", error));
  };

  const handleSaveSurvey = () => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      username: profile.username,
      role: profile.user_type,
      reason_type: surveyData.resion,
      other_reason: surveyData.other,
      status: surveyData.status,
      user_id: profile.id,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(api + "/api/usersurvey", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        localStorage.removeItem("id");
        localStorage.removeItem("token");
        if (surveyData.status == "Delete") {
          setIsApologizeModal(true);
        } else {
          window.location.replace("/");
        }
        setIsSurveyModal(false);
        console.log(result);
      })
      .catch((error) => console.log("error", error));
  };

  const handleMobileVerify = () => {
    if (phoneVerify.phone.length > 7) {
      const myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Bearer " + localStorage.getItem("token")
      );
      myHeaders.append("Content-Type", "application/json");
      const raw = JSON.stringify({
        phonenoforverify:
          contactCode.code != ""
            ? contactCode.code + phoneVerify.phone
            : "+" + profile.countrycode + phoneVerify.phone,
        phone: phoneVerify.phone,
        countrycode:
          contactCode.code != "" ? contactCode.code : "+" + profile.countrycode,
      });
      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };
      fetch(api + "/api/v1/sendotp", requestOptions)
        .then((response) => response.json())
        .then((result) => {
          console.log(result);
        })
        .catch((error) => {
          console.log("error", error);
        });
    } else {
    }
  };

  const handleVerifyOtp = () => {
    if (phoneVerify.otp.length > 3) {
      const myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Bearer " + localStorage.getItem("token")
      );
      myHeaders.append("Content-Type", "application/json");
      const raw = JSON.stringify({
        phonetoken: phoneVerify.otp,
        phone: phoneVerify.phone,
        countrycode:
          contactCode.code != "" ? contactCode.code : "+" + profile.countrycode,
      });
      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };
      fetch(api + "/api/v1/verifyotp", requestOptions)
        .then((response) => response.json())
        .then((result) => {
          fetchProfileData();
          console.log(result);
        })
        .catch((error) => console.log("error", error));
    } else {
      setErrorErrorPassowrd({
        ...errorPassword,
        otperror: "error",
      });
    }
  };

  const handleEmailCheck = () => {
    const myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      email: emailVerify.email,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(api + "/api/v1/checkemail", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.data.isExist == 1) {
          setEmailVerify({ ...emailVerify, alemail: result.message });
        } else {
          setEmailVerify({ ...emailVerify, alemail: "" });
        }
        console.log(result);
      })
      .catch((error) => console.log("error", error));
  };

  const handleEmailSurvey = () => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    const raw = JSON.stringify({
      id: profile.id,
    });
    const requestOptions = {
      method: "POST",
      body: raw,
      headers: myHeaders,
      redirect: "follow",
    };
    fetch(api + "/api/sendsurveymail", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        localStorage.removeItem("id");
        localStorage.removeItem("token");
        setIsSurveyModal(false);
        if (surveyData.status == "Delete") {
          setIsApologizeModal(true);
        } else {
          window.location.replace("/");
        }
      })
      .catch((error) => console.log("error", error));
  };

  const handleMembership = () => {
    const myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );
    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    fetch(api + "/api/v1/getmembershipplan", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setMembership(result.data.name);
      })
      .catch((error) => console.log("error", error));
  };

  const handleNewProfile = () => {
    setPhotoUpload(false);

    const myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );

    const formdata = new FormData();
    formdata.append("user_id", localStorage.getItem("id"));
    formdata.append("file_path", photo);
    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch(api + "/api/v1/updateparents", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log(result);
        setPhotoUpload(false);
        window.location.reload();
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const handleImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      setImage(URL.createObjectURL(event.target.files[0]));
      setPhoto(event.target.files[0]);
    }
  };

  useEffect(() => {
    setSubTab(data ? data : "");
  }, [data, slugdata, subTab]);

  useEffect(() => {
    if (count) {
      fetchRateData();
      fetchProfileData();
      handleMembership();
      setCount(false);
    }

    localStorage.setItem("side", profileSection);
  }, [
    profile,
    rateData,
    category,
    errorPassword,
    profileSection,
    password,
    editProfile,
  ]);

  const success = (position) => {
    const latitude = position.coords.latitude;
    const longitude = position.coords.longitude;
    setLatLong({
      lat: latitude,
      lng: longitude,
    });
  };

  const error = () => {
    console.log("Unable to retrieve your location");
  };

  useEffect(() => {
    localStorage.setItem(
      "back",
      subTab + (slugdata.name ? "/" + slugdata.name : "")
    );
  }, [data, slugdata, subTab, rateData, category, errorPassword]);

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(success, error);
    } else {
      console.log("Geolocation not supported");
    }
  }, [search]);

  return (
    <>
      <Header fil={handleFilterComponents} />
      <div className="container-fluid">
        <div className="filter_header">
          <div className="container">
            <SearchProviderFilter
              rateNumber={rateNumber}
              profile={profile}
              setRateNumber={setRateNumber}
              setChildren={setChildren}
              handleChildrenAge={handleChildrenAge}
              jobData={jobData}
              setJobData={setJobData}
              category={category}
              setSubTab={setSubTab}
              isCatOpen={isCatOpen}
              fetchRateData={fetchRateData}
              childrenAge={childrenAge}
              advanceSearch={advanceSearch}
              handleElementById={handleElementById}
              handleSearchElement={handleSearchElement}
              handleSelectOption={handleSelectOption}
            />
          </div>
        </div>

        {subTab !== "Profile" && (
          <>
            {profile.about == "" || profile.about == null ? (
              ""
            ) : (
              <>
                <div className="mobile_profile_box">
                  <a
                    href={
                      localStorage.getItem("user_type") == "parents"
                        ? "profile-parents/" + profile.id
                        : "/profile-provider/" + profile.id
                    }
                  >
                    <div className="profile_pic">
                      <img
                        src={api + "/assets/images/users/" + profile.file_path}
                        width="50"
                        height="50"
                        alt=""
                      />
                    </div>
                  </a>
                  <div className="mobile_profile_box_content1">
                    <div className="profile_detail">
                      <div className="profile_box_social">
                        <a
                          href={
                            localStorage.getItem("user_type") == "parents"
                              ? "profile-parents/" + profile.id
                              : "profile-provider/" + profile.id
                          }
                        >
                          <div className="name_iimg">
                            <p>
                              {profile.first_name + " " + profile.last_name} (
                              {profile.dob != undefined
                                ? new Date().getFullYear() -
                                  parseInt(
                                    profile.dob
                                      .substr(profile.dob.lastIndexOf("\\") + 1)
                                      .split("-")[0]
                                  )
                                : ""}
                              )
                            </p>
                            <p>
                              {profile.reviewAvg >= 0 && (
                                <>
                                  {[...Array(profile.reviewAvg)].map(
                                    (star, index) => {
                                      index += 1;
                                      return <StarIcon />;
                                    }
                                  )}
                                  {[...Array(5 - profile.reviewAvg)].map(
                                    (star, index) => {
                                      index += 1;
                                      return <WhiteStarIcon />;
                                    }
                                  )}
                                </>
                              )}
                              <span>({profile.reviewcount})</span>
                            </p>
                          </div>
                        </a>
                        <div className="profile_box_social_sec1">
                          {profile.phoneVerifiedStatus == 1 ? (
                            <img
                              src={
                                window.location.origin +
                                "/images/nany_phone.svg"
                              }
                              alt=""
                            />
                          ) : (
                            <PhoneIcon />
                          )}
                          <img
                            src={
                              window.location.origin + "/images/nany_msg.svg"
                            }
                            alt=""
                          />
                          {profile.facebookverify != null ||
                          profile.linkdinverify != null ? (
                            <img
                              src={
                                window.location.origin + "/images/nany_cont.svg"
                              }
                              alt=""
                            />
                          ) : (
                            <ServiceIcon />
                          )}

                          {localStorage.getItem("user_type") == "parents" ? (
                            ""
                          ) : profile.docsStatus == "Yes" ? (
                            <img
                              src={window.location.origin + "/images/ok.svg"}
                              alt=""
                            />
                          ) : (
                            <ConfirmIcon />
                          )}
                        </div>

                        {localStorage.getItem("user_type") == "parents" ? (
                          <span className="addjob">
                            <ProfessionChange />
                          </span>
                        ) : (
                          <div className="profile_box_social_sec2">
                            {profile.service_type &&
                              profile.service_type.tab1 == "Nanny" && (
                                <img
                                  src={
                                    window.location.origin +
                                    "/images/nany_pur.svg"
                                  }
                                  alt=""
                                />
                              )}
                            {profile.service_type &&
                              profile.service_type.tab2 && (
                                <img
                                  src={
                                    window.location.origin +
                                    "/images/special_education.svg"
                                  }
                                  alt=""
                                />
                              )}
                            {profile.service_type &&
                              profile.service_type.tab3 && (
                                <img
                                  src={
                                    window.location.origin +
                                    "/images/professional.svg"
                                  }
                                  alt=""
                                />
                              )}
                            {profile.service_type &&
                              profile.service_type.tab4 && (
                                <img
                                  src={
                                    window.location.origin +
                                    "/images/tutorform.svg"
                                  }
                                  alt=""
                                />
                              )}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="mobile_profile_box_content2">
                      <div className="profile_functions">
                        <div className="funk">
                          <h5>
                            {new Date().getFullYear() -
                              new Date(profile.created_at).getFullYear() >
                            0
                              ? new Date().getFullYear() -
                                new Date(profile.created_at).getFullYear() +
                                t(" YRS")
                              : 0 + t(" YRS")}
                          </h5>
                          <h6>{t("Member")}</h6>
                        </div>
                        <div className="vi"></div>
                        {localStorage.getItem("user_type") == "parents" ? (
                          <div className="funk">
                            <h5>{profile.jobs}</h5>
                            <h6>{t("Job posts")}</h6>
                          </div>
                        ) : (
                          <div className="funk">
                            <h5>{profile.jobApplicationcount}</h5>
                            <h6>{t("text-applications")}</h6>
                          </div>
                        )}
                        <div className="vi"></div>
                        <div className="funk">
                          <h5>{profile.hiringcount}</h5>
                          <h6>{t("Hirings")}</h6>
                        </div>
                      </div>
                      <button
                        onClick={(e) => {
                          window.scrollTo({ top: 0, behavior: "smooth" });
                        }}
                        style={{ float: "right" }}
                      >
                        <a href={"/search-providers/Profile"}>
                          <ArrowRightIcon />
                        </a>
                      </button>
                    </div>
                  </div>
                </div>
              </>
            )}
          </>
        )}

        <div
          className="filter_header2"
          id="filteron"
          style={
            !localStorage.getItem("token") || !localStorage.getItem("id")
              ? { display: "block" }
              : { display: "none" }
          }
        >
          <div
            className="filter_box"
            onKeyPress={(e) => {
              if (
                e.key == "Enter" &&
                localStorage.getItem("token") &&
                localStorage.getItem("id")
              ) {
                localStorage.removeItem("searchdata");
                fetchRateData();
              }
            }}
          >
            <div className="container">
              <div className="filter_search">
                <div className="monile_search">
                  <h2>{t("Find perfect job")}</h2>
                  <div className="filter_category Profile_complete ">
                    <div className="filter_category_select detail work-experience">
                      <div className="job_performance">
                        <div className="form_group   full">
                          <div className="customselect inp">
                            <input
                              id="my-fil-cat"
                              className="keyword"
                              type="text"
                              placeholder={t("Select job category")}
                              value={category.map((e) => {
                                return e.name;
                              })}
                            />
                            <div
                              className="overflow"
                              id="over4"
                              onClick={(e) =>
                                handleElementById("cate7", "over4")
                              }
                            ></div>
                            <div className="option" id="cate7">
                              <p>
                                <input
                                  type="checkbox"
                                  onClick={(a) => {
                                    handleSelectOption(t("text-nanny"));
                                    if (a.target.checked) {
                                      setJobData({
                                        ...jobData,
                                        data1: "Nanny",
                                      });
                                    } else {
                                      setJobData({ ...jobData, data1: "" });
                                    }
                                  }}
                                />
                                <h3>
                                  <NannyIcon />
                                  {t("text-nanny")}
                                </h3>
                                <span></span>
                              </p>
                              <p>
                                <input
                                  type="checkbox"
                                  onClick={(a) => {
                                    handleSelectOption(
                                      t("spec-education-teacher")
                                    );
                                    if (a.target.checked) {
                                      setJobData({
                                        ...jobData,
                                        data2: "Special education teacher",
                                      });
                                    } else {
                                      setJobData({ ...jobData, data2: "" });
                                    }
                                  }}
                                />
                                <h3>
                                  <TeacherIcon />
                                  {t("spec-education-teacher")}
                                </h3>
                                <span></span>
                              </p>
                              <p>
                                <input
                                  type="checkbox"
                                  onClick={(a) => {
                                    handleSelectOption(
                                      t("spec-education-paraprofessional")
                                    );
                                    if (a.target.checked) {
                                      setJobData({
                                        ...jobData,
                                        data3:
                                          "Special education paraprofessional",
                                      });
                                    } else {
                                      setJobData({ ...jobData, data3: "" });
                                    }
                                  }}
                                />
                                <h3>
                                  <ParaprofessionalIcon />
                                  {t("spec-education-paraprofessional")}
                                </h3>
                                <span></span>
                              </p>
                              <p>
                                <input
                                  type="checkbox"
                                  onClick={(a) => {
                                    handleSelectOption(t("text-tutor"));
                                    if (a.target.checked) {
                                      setJobData({
                                        ...jobData,
                                        data4: "Tutor",
                                      });
                                    } else {
                                      setJobData({ ...jobData, data4: "" });
                                    }
                                  }}
                                />
                                <h3>
                                  <TutorIcon />
                                  {t("text-tutor")}
                                </h3>
                                <span></span>
                              </p>
                            </div>
                            <span
                              onClick={(e) =>
                                handleElementById("cate7", "over4")
                              }
                            >
                              <ArrowUpIcon color={"#7D2B8B"} />
                            </span>
                          </div>
                          {/* <div className='errorfield'>{error.message}</div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="searchbutton">
                    <button onClick={(e) => handleShowProviderModal()}>
                      <img src="/images/filter_icon.svg" />
                      {t("Advanced search")}
                    </button>

                    <button
                      onClick={(e) => {
                        localStorage.getItem("user_type") == "parents"
                          ? navigate("/search-providers")
                          : navigate("/search-parents");
                        setSubTab("");
                        localStorage.removeItem("searchdata");
                        fetchRateData();
                      }}
                    >
                      <img src="/images/filter_search_icon.svg" />
                      {t("text-search")}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {showProviderModal && (
          <Modal show={showProviderModal} onHide={handleCloseProviderModal}>
            <Modal.Body>
              <div className=" advance_search_popup">
                <Link to="" onClick={handleCloseProviderModal}>
                  +
                </Link>

                <div className="" id="filteron">
                  <ProviderModalComponent
                    rateNumber={rateNumber}
                    setRateNumber={setRateNumber}
                    setChildren={setChildren}
                    profile={profile}
                    setSearch={setSearch}
                    search={search}
                    languageSpeak={languageSpeak}
                    setLanguageSpeak={setLanguageSpeak}
                    handleChildrenAge={handleChildrenAge}
                    handleElementById={handleElementById}
                    setJobData={setJobData}
                    jobData={jobData}
                    handleCloseProviderModal={handleCloseProviderModal}
                    fetchRateData={fetchRateData}
                    handleSelectOption={handleSelectOption}
                    category={category}
                    isCatOpen={isCatOpen}
                    setSubTab={setSubTab}
                    childrenAge={childrenAge}
                  />
                </div>
              </div>
            </Modal.Body>
          </Modal>
        )}

        {subTab == "" && (
          <>
            <div className="mobile_looking_for_candidate">
              <h2>{t("These are experienced candidates from your area")}</h2>
            </div>
            <div className="mobile_looking_for_candidate_boxs">
              {rateData.map((data, index) => {
                if (index < listShow) {
                  return (
                    <SearchCandidate
                      handleElementContent={handleElementContent}
                      data={data}
                      index={index}
                      key={index}
                    />
                  );
                }
              })}
            </div>
          </>
        )}

        {subTab == "Account" && (
          <div className="Account mobile">
            <h2>{t("text-account")}</h2>
            <div
              className={
                personalInfo.personal == "active"
                  ? "active personal"
                  : "personal"
              }
            >
              <h3
                onClick={(e) =>
                  setPersonalInfo({
                    ...personalInfo,
                    personal: personalInfo.personal == "" ? "active" : "",
                  })
                }
              >
                {t("Personal Info")}
              </h3>
              {personalInfo.personal == "active" ? (
                <div className="withoutedit">
                  {editProfile == "" ? (
                    <button onClick={(e) => setEditProfile("edit")}>
                      <img
                        src={window.location.origin + "/images/edit.svg"}
                        alt=""
                      />
                    </button>
                  ) : (
                    ""
                  )}
                  {editProfile == "" ? (
                    <div className="detail">
                      <ul>
                        <li className="disabledata">
                          <span className="main">{t("First Name")}</span>
                          <span className="data">{profile.first_name}</span>
                        </li>
                        <li>
                          <span className="main">{t("Last Name")}</span>
                          <span className="data">{profile.last_name}</span>
                        </li>
                        <li className="disabledata">
                          <span className="main">{t("Date of birth")}</span>
                          <span className="data">{profile.dob}</span>
                        </li>
                        <li>
                          <span className="main">{t("Mobile")}</span>
                          <span className="data">
                            {(contactCode.code == ""
                              ? profile.countrycode
                              : contactCode.code) + profile.phone}
                          </span>
                        </li>
                        <li>
                          <span className="main">{t("Email")} </span>
                          <span className="data">{profile.email}</span>
                        </li>
                        <li>
                          <span className="main">{t("Address")}</span>
                          <span className="data">{profile.address}</span>
                        </li>
                        <li>
                          <span className="main">{t("text-city")}</span>
                          <span className="data">{profile.city}</span>
                        </li>
                        <li>
                          <span className="main">{t("zip-code")}</span>
                          <span className="data">{profile.zip}</span>
                        </li>
                        <li>
                          <span className="main">{t("Country")}</span>
                          <span className="data">{profile.country}</span>
                        </li>
                      </ul>
                    </div>
                  ) : (
                    <div className="panel-body">
                      <form action="">
                        <div className="row">
                          <div className="form-group col-md-6">
                            <div className="field-box">
                              <label className="label" for="firstName">
                                {t("First Name")}
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="firstName"
                                disabled
                                value={profile.first_name}
                              />
                            </div>
                            <div className="field-box">
                              <label className="label" for="lastName">
                                {t("Last Name")}
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="lastName"
                                value={profile.last_name}
                                onChange={(e) =>
                                  setProfile({
                                    ...profile,
                                    last_name: e.target.value,
                                  })
                                }
                              />
                            </div>
                            <div className="field-box">
                              <label className="label" for="dob">
                                {t("Date of birth")}
                              </label>
                              <div className="with-icon">
                                <img
                                  className="icons"
                                  src={
                                    window.location.origin +
                                    "/images/calender.svg"
                                  }
                                  alt=""
                                />
                                <input
                                  type="text"
                                  className="form-control"
                                  id="dob"
                                  disabled
                                  value={profile.dob}
                                />
                              </div>
                            </div>
                            <div className="field-box">
                              <label className="label" for="email">
                                {t("email-address")}
                              </label>
                              <input
                                type="email"
                                className="form-control"
                                id="email"
                                data-required
                                defaultValue={profile.email}
                                onChange={(e) =>
                                  setEmailVerify({
                                    ...emailVerify,
                                    email: e.target.value,
                                  })
                                }
                                onBlur={(e) => handleEmailCheck()}
                              />
                              <span>
                                { emailVerify ? emailVerify.alemail
                                  ? emailVerify.alemail
                                  : profile.email_verified_at == null
                                    ? "Please verify your enail"
                                    : "" : ""}
                              </span>
                            </div>
                          </div>

                          <div className="form-group col-md-6">
                            <div className="form_group number field-box">
                              <label className="label" for="mobile">
                                {t("Mobile Phone")}
                              </label>
                              <input
                                type="number"
                                placeholder={t("text-type")}
                                name="phone"
                                onChange={(e) => {
                                  setPhoneVerify({
                                    ...phoneVerify,
                                    phone:
                                      e.target.value.length <= 10
                                        ? e.target.value
                                        : profile.phone,
                                  });
                                }}
                                defaultValue={profile.phone}
                              />
                              <div
                                className="country_flag"
                                onClick={(e) => handleCodeSelect()}
                              >
                                <img
                                  src={
                                    contactCode.flag
                                        ? `${window.location.origin}/${contactCode.flag}`
                                        : `${window.location.origin}/${
                                            DATA_COUNTRY.data.filter((e) => e.dial_code === "+" + profile.countrycode)[0].flag
                                        }`
                                  }
                                />
                                {contactCode.code
                                  ? contactCode.code
                                  : profile.countrycode}
                              </div>
                              <ul style={code ? { display: "none" } : {}}>
                                {DATA_COUNTRY.data.map((e) => {
                                  return (
                                    <li
                                      onClick={(a) => {
                                        handleCodeSelect();
                                        setContactCode({
                                          flag: e.flag,
                                          code: e.dial_code,
                                        });
                                      }}
                                    >
                                      <img src={e.flag} />
                                      {e.country + " " + " " + e.dial_code}
                                    </li>
                                  );
                                })}
                              </ul>
                              {(phoneVerify && phoneVerify.phone != "") &&
                              profile.phone != phoneVerify.phone ? (
                                <button onClick={(e) => handleMobileVerify()}>
                                  {t("get-code")}
                                </button>
                              ) : (
                                ""
                              )}
                            </div>
                            {(phoneVerify && phoneVerify.phone != "") &&
                            profile.phone != phoneVerify.phone ? (
                              <div className="field-box">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="OTP"
                                  onChange={(e) => {
                                    setPhoneVerify({
                                      ...phoneVerify,
                                      otp: e.target.value,
                                    });
                                  }}
                                />
                                <button onClick={(e) => handleVerifyOtp()}>
                                  {t("text-verify")}
                                </button>
                              </div>
                            ) : (
                              ""
                            )}
                            <div className="field-box">
                              <label className="label" for="address1">
                                {t("Address")}
                              </label>
                              <LocationContent
                                let={getLatlong}
                                typedefault={profile.address}
                              />
                            </div>
                            <div className="field-box">
                              <div className="row coln">
                                <div className="col-md-6">
                                  <label className="label" for="city">
                                    {t("text-city")}
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="city"
                                    data-required
                                    value={profile.city}
                                    onChange={(e) =>
                                      setProfile({
                                        ...profile,
                                        city: e.target.value,
                                      })
                                    }
                                  />
                                </div>

                                <div className="col-md-6">
                                  <label className="label" for="zipCode">
                                    {t("ZIP Code")}
                                  </label>
                                  <input
                                    type="number"
                                    className="form-control"
                                    id="zipCode"
                                    onChange={(e) =>
                                      setProfile({
                                        ...profile,
                                        zip: e.target.value,
                                      })
                                    }
                                    value={profile.zip}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="field-box">
                              <label className="label" for="country">
                                {t("Country")}
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                value={profile.country}
                              />
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  )}
                  <div className="resetpassword">
                    <div className="form_group disabledata">
                      <label>{t("Username")}</label>
                      <input type="text" value={profile.username} disabled />
                    </div>
                    <div className="form_group ">
                      <label>{t("text-password")}</label>
                      <input type="password" value="........" />
                    </div>
                    <button onClick={(e) => setShowModal(true)}>
                      {t("Change password")}
                    </button>
                  </div>
                  <div className="panel-body">
                    <div className="button text-center">
                      <div className="pull-right">
                        <button
                          className="btn"
                          onClick={(e) => {
                            setContactCode({
                              ...contactCode,
                              code: "",
                            });
                            fetchProfileData();
                            setEditProfile("");
                          }}
                        >
                          {t("text-cancel")}
                        </button>
                      </div>
                      <div className="pull-right">
                        <button
                          className="btn confirm"
                          onClick={(e) => handleUpdateProfile()}
                        >
                          {t("Save changes")}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
            {false && (
              <div
                className={
                  personalInfo.payment == "active"
                    ? "active personal"
                    : "personal"
                }
              >
                <h3
                  onClick={(e) =>
                    setPersonalInfo({
                      ...personalInfo,
                      payment: personalInfo.payment == "" ? "active" : "",
                    })
                  }
                >
                  {t("Payment and Membership")}
                </h3>
                {personalInfo.payment == "active" ? (
                  <div className="cardstype">
                    <div className="paymenttype"></div>
                    <div className="savecard">
                      <ul>
                        <li>
                          <label for="c">
                            <input type="radio" id="c" name="c" />
                            <span>{t("Set as default")}</span>
                          </label>
                          <span>{t("Card name")}</span>
                          <p>{t("Bankname card ****4589")}</p>
                          <span>{t("expires-on")}</span>
                          <p>{t("04/2022")}</p>
                          <button>{t("text-remove")}</button>
                          <button onClick={(e) => setIsEditCardModal(true)}>
                            {t("Edit")}
                          </button>
                        </li>
                        <li>
                          <label for="d">
                            <input type="radio" id="d" name="c" />
                            <span>{t("Set as default")}</span>
                          </label>
                          <span>{t("Card name")}</span>
                          <p>{t("Bankname card ****4589")}</p>
                          <span>{t("expires-on")}</span>
                          <p>{t("04/2022")}</p>
                          <button>{t("text-remove")}</button>
                          <button onClick={(e) => setIsEditCardModal(true)}>
                            {t("Edit")}
                          </button>
                        </li>
                        <li>
                          <label for="e">
                            <input type="radio" id="e" name="c" />
                            <span>{t("Set as default")}</span>
                          </label>
                          <span>{t("Card name")}</span>
                          <p>{t("Bankname card ****4589")}</p>
                          <span>{t("expires-on")}</span>
                          <p>{t("04/2022")}</p>
                          <button>{t("text-remove")}</button>
                          <button onClick={(e) => setIsEditCardModal(true)}>
                            {t("Edit")}
                          </button>
                        </li>
                      </ul>
                    </div>
                    <div className="activeplan">
                      <ul>
                        <li>
                          <label>
                            {t("Current membership plan")}
                            <span>
                              {membership ? membership : t("Plan not active")}
                            </span>
                          </label>
                          <button onClick={(e) => setIsMembershipModal(true)}>
                            {t("Cancel plan")}
                          </button>
                          <NavLink to={""}>
                            <button>{t("Change plan")}</button>
                          </NavLink>
                        </li>
                        <li>
                          <label>{t("Subscription")}</label>
                          <p>
                            {t(" Auto renew subscription")}
                            <label className="switch">
                              <input type="checkbox" />
                              <span className="slider round"></span>
                            </label>
                          </p>
                        </li>
                      </ul>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
            )}
            <div
              className={
                personalInfo.delete == "active" ? "active personal" : "personal"
              }
            >
              <h3
                onClick={(e) =>
                  setPersonalInfo({
                    ...personalInfo,
                    delete: personalInfo.delete == "" ? "active" : "",
                  })
                }
              >
                {t("Deactivate/ Delete account")}
              </h3>
              {personalInfo.delete == "active" ? (
                <div className="activeplan deletedeactivate">
                  <ul>
                    <li>
                      <label>
                        {t("Current account status plan")}
                        <span>
                          {profile.account_status == "ENABLE"
                            ? t("text-active")
                            : t("Inactive")}
                        </span>
                      </label>
                      <button onClick={(e) => setIsWarningModal(true)}>
                        {t("Delete account")}
                      </button>
                      <button onClick={(e) => setIsDisableAccountModal(true)}>
                        {t("Disable account")}
                      </button>
                    </li>
                  </ul>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        )}
        {subTab == "Profile" && (
          <>
            {localStorage.getItem("user_type") == "parents" ? (
              <div className="mobile profile">
                <ViewEdit />
              </div>
            ) : (
              <div className="mobile profile">
                <ViewEditProvider />
              </div>
            )}
          </>
        )}

        <div className="container">
          <div
            className="body_section searchbody"
            style={
              subTab != ""
                ? {
                    minHeight: "auto",
                    marginBottom: "100px",
                    float: "left",
                    width: "100%",
                  }
                : {}
            }
          >
            <div className="left_side_section" id={!isCatOpen ? "leftdata" : ""}>
              {profile && profile.first_name && (
                  <SearchLeftSection
                      profile={profile}
                      setPhotoUpload={setPhotoUpload}
                      setIsSignoutModal={setIsSignoutModal}
                      setProfileSection={setProfileSection}
                      subTab={subTab}
                      setSubTab={setSubTab}
                      profileSection={profileSection}
                  />
              )  }
            </div>


            {subTab == "" && (
              <SearchRightSection
                rateData={rateData}
                handleRate={handleRate}
                profile={profile}
                interestData={interestData}
                setListShow={setListShow}
                result={result}
                listShow={listShow}
                handleElementContent={handleElementContent}
                sliderRef={sliderRef}
              />
            )}

            {subTab == "Account" && (
              <div className="Account daskshow">
                <h2>{t("text-account")}</h2>
                <div
                  className={
                    personalInfo.personal == "active"
                      ? "active personal"
                      : "personal"
                  }
                >
                  <h3
                    onClick={(e) =>
                      setPersonalInfo({
                        ...personalInfo,
                        personal: personalInfo.personal == "" ? "active" : "",
                      })
                    }
                  >
                    {t("Personal Info")}
                  </h3>
                  {personalInfo.personal == "active" ? (
                    <>
                      <div className="withoutedit">
                        {editProfile == "" ? (
                          <button onClick={(e) => setEditProfile("edit")}>
                            <img
                              src={window.location.origin + "/images/edit.svg"}
                              alt=""
                            />
                          </button>
                        ) : (
                          ""
                        )}
                        {editProfile == "" ? (
                          <div className="detail">
                            <ul>
                              <li className="disabledata">
                                <span className="main">{t("First Name")}</span>
                                <span className="data">
                                  {profile.first_name}
                                </span>
                              </li>
                              <li>
                                <span className="main">{t("Address")}</span>
                                <span className="data">{profile.address}</span>
                              </li>
                              <li>
                                <span className="main">{t("Last Name")}</span>
                                <span className="data">
                                  {profile.last_name}
                                </span>
                              </li>
                              <li>
                                <span className="main">{t("text-city")}</span>
                                <span className="data">{profile.city}</span>
                              </li>
                              <li className="disabledata">
                                <span className="main">
                                  {t("Date of birth")}
                                </span>
                                <span className="data">{profile.dob}</span>
                              </li>
                              <li>
                                <span className="main">{t("zip-code")}</span>
                                <span className="data">{profile.zip}</span>
                              </li>
                              <li>
                                <span className="main">{t("Mobile")}</span>
                                <span className="data">
                                  {(contactCode.code == ""
                                    ? profile.countrycode
                                    : contactCode.code) +
                                    "-" +
                                    profile.phone}
                                </span>
                              </li>
                              <li>
                                <span className="main">{t("Country")}</span>
                                <span className="data">{profile.country}</span>
                              </li>
                              <li>
                                <span className="main">{t("Email")} </span>
                                <span className="data">{profile.email}</span>
                              </li>
                            </ul>
                          </div>
                        ) : (
                          <div className="panel-body">
                            <div className="row">
                              <div className="form-group col-md-6">
                                <div className="field-box">
                                  <label className="label" for="firstName">
                                    {t("First Name")}
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="firstName"
                                    disabled
                                    value={profile.first_name}
                                  />
                                </div>
                                <div className="field-box">
                                  <label className="label" for="lastName">
                                    {t("Last Name")}
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="lastName"
                                    value={profile.last_name}
                                    onChange={(e) =>
                                      setProfile({
                                        ...profile,
                                        last_name: e.target.value,
                                      })
                                    }
                                  />
                                </div>
                                <div className="field-box">
                                  <label className="label" for="dob">
                                    {t("Date of Birth")}
                                  </label>
                                  <div className="with-icon">
                                    <img
                                      className="icons"
                                      src={
                                        window.location.origin +
                                        "/images/calender.svg"
                                      }
                                      alt=""
                                    />
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="dob"
                                      disabled
                                      value={profile.dob}
                                    />
                                  </div>
                                </div>
                                <div className="field-box">
                                  <label className="label" for="email">
                                    {t("email-address")}
                                  </label>
                                  <input
                                    type="email"
                                    className="form-control"
                                    id="email"
                                    data-required
                                    defaultValue={profile.email}
                                    onChange={(e) =>
                                      setEmailVerify({
                                        ...emailVerify,
                                        email: e.target.value,
                                      })
                                    }
                                    onBlur={(e) => handleEmailCheck()}
                                  />
                                  <span>
                                    {emailVerify ? emailVerify.alemail
                                      ? emailVerify.alemail
                                      : profile.email_verified_at == null
                                        ? "Please verify your enail"
                                        : "" : ""}
                                  </span>
                                </div>
                              </div>

                              <div className="form-group col-md-6">
                                <div className="field-box">
                                  <label className="label" for="address1">
                                    {t("Address")}
                                  </label>
                                  <LocationContent
                                    let={getLatlong}
                                    typedefault={profile.address}
                                  />
                                </div>
                                <div className="field-box">
                                  <div className="row coln">
                                    <div className="col-md-6">
                                      <label className="label" for="city">
                                        {t("text-city")}
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        id="city"
                                        data-required
                                        value={profile.city}
                                        onChange={(e) =>
                                          setProfile({
                                            ...profile,
                                            city: e.target.value,
                                          })
                                        }
                                      />
                                    </div>

                                    <div className="col-md-6">
                                      <label className="label" for="zipCode">
                                        {t("ZIP Code")}
                                      </label>
                                      <input
                                        type="number"
                                        className="form-control"
                                        id="zipCode"
                                        onChange={(e) =>
                                          setProfile({
                                            ...profile,
                                            zip: e.target.value,
                                          })
                                        }
                                        value={profile.zip}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="field-box">
                                  <label className="label" for="country">
                                    {t("Country")}
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    value={profile.country}
                                  />
                                </div>
                                <div className="form_group number field-box">
                                  <label className="label" for="mobile">
                                    {t("Mobile Phone")}
                                  </label>
                                  <input
                                    type="number"
                                    placeholder={t("text-type")}
                                    name="phone"
                                    onChange={(e) => {
                                      setPhoneVerify({
                                        ...phoneVerify,
                                        phone:
                                          e.target.value.length <= 8
                                            ? e.target.value
                                            : profile.phone,
                                      });
                                    }}
                                    defaultValue={profile.phone}
                                  />
                                  <div
                                    className="country_flag"
                                    onClick={(e) => handleCodeSelect()}
                                  >
                                    <img
                                      src={
                                        contactCode.flag
                                            ? `${window.location.origin}/${contactCode.flag}`
                                            : `${window.location.origin}/${
                                                DATA_COUNTRY.data.filter((e) => e.dial_code === "+" + profile.countrycode)[0].flag
                                            }`
                                      }
                                    />
                                    {contactCode.code
                                      ? contactCode.code
                                      : profile.countrycode}
                                  </div>
                                  <ul style={code ? { display: "none" } : {}}>
                                    {DATA_COUNTRY.data.map((e) => {
                                      return (
                                        <li
                                          onClick={(a) => {
                                            handleCodeSelect();
                                            setContactCode({
                                              flag: e.flag,
                                              code: e.dial_code,
                                            });
                                          }}
                                        >
                                          <img src={e.flag} />
                                          {e.country + " " + " " + e.dial_code}
                                        </li>
                                      );
                                    })}
                                  </ul>
                                  {(phoneVerify && phoneVerify.phone != "") &&
                                  profile.phone != phoneVerify.phone ? (
                                    <button
                                      onClick={(e) => handleMobileVerify()}
                                    >
                                      {t("get-code")}
                                    </button>
                                  ) : (
                                    ""
                                  )}
                                </div>
                                {(phoneVerify && phoneVerify.phone != "") &&
                                profile.phone != phoneVerify.phone ? (
                                  <div className="field-box">
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="OTP"
                                      onChange={(e) => {
                                        setPhoneVerify({
                                          ...phoneVerify,
                                          otp: e.target.value,
                                        });
                                      }}
                                    />
                                    <button onClick={(e) => handleVerifyOtp()}>
                                      {t("text-verify")}
                                    </button>
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                            <div className="button text-center">
                              <div className="pull-right">
                                <button
                                  className="btn"
                                  onClick={(e) => {
                                    setContactCode({
                                      ...contactCode,
                                      code: "",
                                    });
                                    fetchProfileData();
                                    setEditProfile("");
                                  }}
                                >
                                  {t("text-cancel")}
                                </button>
                              </div>
                              <div className="pull-right">
                                <button
                                  className="btn confirm"
                                  onClick={(e) => handleUpdateProfile()}
                                >
                                  {t("Save changes")}
                                </button>
                              </div>
                            </div>
                          </div>
                        )}
                        <div className="resetpassword">
                          <div className="form_group disabledata">
                            <label>{t("Username")}</label>
                            <input
                              type="text"
                              value={profile.username}
                              disabled
                            />
                          </div>
                          <div className="form_group ">
                            <label>{t("text-password")}</label>
                            <input type="password" value="......." />
                          </div>
                          <button onClick={(e) => setShowModal(true)}>
                            {t("Change password")}
                          </button>
                        </div>
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                </div>
                {false && (
                  <div
                    className={
                      personalInfo.payment == "active"
                        ? "active personal"
                        : "personal"
                    }
                  >
                    <h3
                      onClick={(e) =>
                        setPersonalInfo({
                          ...personalInfo,
                          payment: personalInfo.payment == "" ? "active" : "",
                        })
                      }
                    >
                      {t("Payment and Membership")}
                    </h3>
                    {personalInfo.payment == "active" ? (
                      <div className="cardstype">
                        <div className="paymenttype"></div>
                        <div className="savecard">
                          <ul>
                            <li>
                              <label for="c">
                                <input type="radio" id="c" name="c" />
                                <span>{t("Set as default")}</span>
                              </label>
                              <span>{t("Card name")}</span>
                              <p>{t("Bankname card ****4589")}</p>
                              <span>{t("expires-on")}</span>
                              <p>{t("04/2022")}</p>
                              <button>{t("text-remove")}</button>
                              <button onClick={(e) => setIsEditCardModal(true)}>
                                {t("Edit")}
                              </button>
                            </li>
                            <li>
                              <label for="d">
                                <input type="radio" id="d" name="c" />
                                <span>{t("Set as default")}</span>
                              </label>
                              <span>{t("Card name")}</span>
                              <p>{t("Bankname card ****4589")}</p>
                              <span>{t("expires-on")}</span>
                              <p>{t("04/2022")}</p>
                              <button>{t("text-remove")}</button>
                              <button onClick={(e) => setIsEditCardModal(true)}>
                                {t("Edit")}
                              </button>
                            </li>
                            <li>
                              <label for="e">
                                <input type="radio" id="e" name="c" />
                                <span>{t("Set as default")}</span>
                              </label>
                              <span>{t("Card name")}</span>
                              <p>{t("Bankname card ****4589")}</p>
                              <span>{t("expires-on")}</span>
                              <p>{t("04/2022")}</p>
                              <button>{t("text-remove")}</button>
                              <button onClick={(e) => setIsEditCardModal(true)}>
                                {t("Edit")}
                              </button>
                            </li>
                          </ul>
                        </div>
                        <div className="activeplan">
                          <ul>
                            <li>
                              <label>
                                {t("Current membership plan")}
                                <span>
                                  {membership
                                    ? t(membership)
                                    : t("Plan not active")}
                                </span>
                              </label>
                              <button onClick={(e) => setIsMembershipModal(true)}>
                                {t("Cancel plan")}
                              </button>
                              <NavLink to={""}>
                                <button>{t("Change plan")}</button>
                              </NavLink>
                            </li>
                            <li>
                              <label>{t("Subscription")}</label>
                              <p>
                                {t("Auto renew subscription")}
                                <label className="switch">
                                  <input type="checkbox" />
                                  <span className="slider round"></span>
                                </label>
                              </p>
                            </li>
                          </ul>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                )}
                <div
                  className={
                    personalInfo.delete == "active"
                      ? "active personal"
                      : "personal"
                  }
                >
                  <h3
                    onClick={(e) =>
                      setPersonalInfo({
                        ...personalInfo,
                        delete: personalInfo.delete == "" ? "active" : "",
                      })
                    }
                  >
                    {t("Deactivate/ Delete account")}
                  </h3>
                  {personalInfo.delete == "active" ? (
                    <div className="activeplan deletedeactivate">
                      <ul>
                        <li>
                          <label>
                            {t("Current account status plan")}
                            <span>
                              {profile.account_status == "ENABLE"
                                ? t("text-active")
                                : t("Inactive")}
                            </span>
                          </label>
                          <button onClick={(e) => setIsWarningModal(true)}>
                            {t("Delete account")}
                          </button>
                          <button
                            onClick={(e) => setIsDisableAccountModal(true)}
                          >
                            {t("Disable account")}
                          </button>
                        </li>
                      </ul>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <br />
                <br />
              </div>
            )}
            {subTab == "Profile" ? (
              <>
                {localStorage.getItem("user_type") == "parents" ? (
                  <ViewEdit />
                ) : (
                  <ViewEditProvider />
                )}
              </>
            ) : (
                ""
            )}
            {localStorage.getItem("user_type") == "parents" ? (
              <ParentsNotificationTabs
                subtab={subTab}
                profilesection={profileSection}
                profile={profile}
              />
            ) : (
              <ProviderNotificationTabs
                subtab={subTab}
                profilesection={profileSection}
                profile={profile}
              />
            )}
          </div>
        </div>

        <div className="arrowtop">
          <ArrowScrollIcon
            onClick={(e) => {
              window.scrollTo({ top: 0, behavior: "smooth" });
            }}
          />
        </div>

        <div className="mobilemenubottom">
          <div
            className="side_drop_collapse_box_content"
            style={
              profileSection == "notifications"
                ? { display: "block" }
                : { display: "none" }
            }
            onClick={(e) => {
              localStorage.removeItem("side");
              setProfileSection("");
            }}
          >
            <span
              onClick={(e) => {
                localStorage.removeItem("side");
                setProfileSection("");
              }}
            ></span>
            {localStorage.getItem("user_type") == "parents" ? (
              <NotificationTabParents setsubtab={setSubTab} subtab={subTab} />
            ) : (
              <NotificationTabProvider setsubtab={setSubTab} subtab={subTab} />
            )}
          </div>
          <div
            className="side_drop_collapse_box_content Document_main "
            onClick={(e) => {
              localStorage.removeItem("side");
              setProfileSection("");
            }}
            style={
              profileSection == "fav"
                ? { display: "block" }
                : { display: "none" }
            }
          >
            <span
              onClick={(e) => {
                localStorage.removeItem("side");
                setProfileSection("");
              }}
            ></span>
            <ul>
              <li
                onClick={(e) => {
                  navigate(
                    localStorage.getItem("user_type") == "parents"
                      ? "/search-parents/who-i-visited"
                      : "/search-providers/who-i-visited"
                  );
                  window.scrollTo({ top: 0, behavior: "smooth" });
                  setSubTab("who-i-visited");
                }}
                className={subTab == "who-i-visited" ? "active " : ""}
              >
                {t("Who I Visited")}
              </li>
              <li
                onClick={(e) => {
                  navigate(
                    localStorage.getItem("user_type") == "parents"
                      ? "/search-parents/who-visited-me"
                      : "/search-providers/who-visited-me"
                  );
                  window.scrollTo({ top: 0, behavior: "smooth" });
                  setSubTab("who-visited-me");
                }}
                className={
                  subTab == "who-visited-me" ? "active upgrade" : "upgrade"
                }
              >
                {t("Who Visited Me")}
                <span>
                  <Link to={""}>{t("upgrade")}</Link>
                </span>
              </li>
            </ul>
          </div>
          <div
            className="side_drop_collapse_box_content"
            style={
              profileSection == "Favorites"
                ? { display: "block" }
                : { display: "none" }
            }
          >
            <span
              onClick={(e) => {
                localStorage.removeItem("side");
                setProfileSection("");
              }}
            ></span>
            {localStorage.getItem("user_type") == "parents" ? (
              <FavoriteProvider
                setsubtab={setSubTab}
                subtab={subTab}
                setprofilesection={setProfileSection}
              />
            ) : (
              <FavoriteProvider
                setsubtab={setSubTab}
                subtab={subTab}
                setprofilesection={setProfileSection}
              />
            )}
          </div>
          <div
            className="side_drop_collapse_box_content"
            style={
              profileSection == "Job_history"
                ? { display: "block" }
                : { display: "none" }
            }
            onClick={(e) => {
              localStorage.removeItem("side");
              setProfileSection("");
            }}
          >
            <span
              onClick={(e) => {
                localStorage.removeItem("side");
                setProfileSection("");
              }}
            ></span>
            {localStorage.getItem("user_type") == "parents" ? (
              <JobHistoryParents setsubtab={setSubTab} subtab={subTab} />
            ) : (
              <JobHistory setsubtab={setSubTab} subtab={subTab} />
            )}
          </div>
          <div
            className="side_drop_collapse_box_content"
            style={
              profileSection == "message"
                ? { display: "block" }
                : { display: "none" }
            }
            onClick={(e) => {
              localStorage.removeItem("side");
              setProfileSection("");
            }}
          >
            <span
              onClick={(e) => {
                localStorage.removeItem("side");
                setProfileSection("");
              }}
            ></span>
            <MessageTab setsubtab={setSubTab} subtab={subTab} />
          </div>
          <ul>
            <li
              onClick={(e) => {
                window.scrollTo({ top: 0, behavior: "smooth" });
                setProfileSection(
                  profileSection != "Job_history" ? "Job_history" : ""
                );
              }}
            >
              <img
                src={window.location.origin + "/images/jobnhistory.svg"}
                alt=""
              />
              <span>{t("Jobs & Doc")}</span>
            </li>
            <li
              onClick={(e) => {
                window.scrollTo({ top: 0, behavior: "smooth" });
                setProfileSection(profileSection != "fav" ? "fav" : "");
              }}
            >
              <SquareServiceIcon width={20} height={22} />
              <span> {t("Recent visit")}</span>
            </li>
            <li
              onClick={(e) =>
                setProfileSection(profileSection != "message" ? "message" : "")
              }
            >
              <img
                src={window.location.origin + "/images/message.svg"}
                alt=""
              />
              <span>{t("text-messages")}</span>
            </li>
            {localStorage.getItem("user_type") == "parents" ? (
              <li
                onClick={(e) => {
                  window.scrollTo({ top: 0, behavior: "smooth" });
                  setSubTab("all-profile");
                  navigate("/search-providers/all-profile");
                }}
              >
                <img src={window.location.origin + "/images/fav.svg"} alt="" />
                <span>{t("Favorites")}</span>
              </li>
            ) : (
              <li
                onClick={(e) => {
                  window.scrollTo({ top: 0, behavior: "smooth" });
                  setProfileSection(
                    profileSection != "Favorites" ? "Favorites" : ""
                  );
                }}
              >
                <img src={window.location.origin + "/images/fav.svg"} alt="" />
                <span>{t("Favorites")}</span>
              </li>
            )}
            <li
              onClick={(e) => {
                window.scrollTo({ top: 0, behavior: "smooth" });
                navigate(
                  localStorage.getItem("user_type") == "parents"
                    ? "/search-parents/Reviews"
                    : "/search-providers/Reviews"
                );
                setProfileSection("");
                setSubTab("Reviews");
              }}
              className={
                subTab == "Reviews"
                  ? "active side_drop_collapse_none"
                  : "side_drop_collapse_none"
              }
            >
              <img
                src={window.location.origin + "/images/reviewi.svg"}
                alt=""
              />
              <span>{t("text-reviews")}</span>
            </li>
            <li
              onClick={(e) => {
                window.scrollTo({ top: 0, behavior: "smooth" });
                setProfileSection(
                  profileSection != "notifications" ? "notifications" : ""
                );
              }}
            >
              <span className="count">
                <NotificationCount />
              </span>
              <img
                src={window.location.origin + "/images/notification.svg"}
                alt=""
              />
              <span>{t("Notifications")}</span>
            </li>
          </ul>
        </div>
      </div>

      {showModal && (
        <ChangePasswordModal
          showModal={showModal}
          handleClose={handleClose}
          setShowModal={setShowModal}
          setShowSuccessModal={setShowSuccessModal}
        />
      )}

      {showSuccessModal && (
        <SuccessModal
          showSuccessModal={showSuccessModal}
          setShowSuccessModal={setShowSuccessModal}
        />
      )}

      {isNewCardModal && (
        <NewCardModal
          isNewCardModal={isNewCardModal}
          setIsNewCardModal={setIsNewCardModal}
          editCountry={editCountry}
          dataCountry={dataCountry}
          setEditCountry={setEditCountry}
          setDataCountry={setDataCountry}
        />
      )}

      {isMembershipModal && (
        <MembershipModal
          isMembershipModal={isMembershipModal}
          setIsMembershipModal={setIsMembershipModal}
        />
      )}

      {isDisableAccountModal && (
        <DisableAccountModal
          isDisableAccountModal={isDisableAccountModal}
          setIsDisableAccountModal={setIsDisableAccountModal}
          handleDisableAccount={handleDisableAccount}
        />
      )}

      {isWarningModal && (
        <WarningModal
          isWarningModal={isWarningModal}
          setIsWarningModal={setIsWarningModal}
          setIsAutoDeleteModal={setIsAutoDeleteModal}
        />
      )}

      {isSurveyModal && (
        <SurveyModal
          isSurvey={isSurveyModal}
          surveyData={surveyData}
          setSurveyData={setSurveyData}
          handleEmailSurvey={handleEmailSurvey}
          handleSaveSurvey={handleSaveSurvey}
        />
      )}

      {isDeleteAccountModal && (
        <DeleteAccountModal
          isDeleteAccountModal={isDeleteAccountModal}
          handleDeleteAccount={handleDeleteAccount}
          setIsDeleteAccountModal={setIsAutoDeleteModal}
        />
      )}

      {isApologizeModal && (
        <ApologizeModal
          isApologizeModal={isApologizeModal}
          setIsApologizeModal={setIsApologizeModal}
        />
      )}

      {isSignoutModal && (
        <SignoutModal
          isSignoutModal={isSignoutModal}
          setIsSignoutModal={setIsSignoutModal}
        />
      )}

      {photoUpload && (
        <PhotoUploadModal
          handleImageChange={handleImageChange}
          handleNewProfile={handleNewProfile}
          photoUpload={photoUpload}
          setPhotoUpload={setPhotoUpload}
          photo={photo}
          image={image}
          setPhoto={setPhoto}
          profile={profile}
        />
      )}
      <Footer />
    </>
  );
}
