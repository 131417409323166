export const ProfessionalSecondaryIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 70 70"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M34.85 18C33.1379 18 31.75 19.3879 31.75 21.1C31.75 22.8121 33.1379 24.2 34.85 24.2C36.5621 24.2 37.95 22.8121 37.95 21.1C37.95 19.3879 36.5621 18 34.85 18ZM45.6999 33.3449C45.6999 32.5854 45.1419 31.9654 44.3979 31.8414C42.4604 31.5159 40.6315 30.4774 39.3915 29.1135L37.392 26.897C37.1285 26.6025 36.803 26.37 36.4465 26.1995C36.4387 26.1995 36.4349 26.1956 36.431 26.1917C36.4271 26.1878 36.4232 26.184 36.4155 26.184H36.4C35.8265 25.8585 35.191 25.7035 34.4625 25.7965C32.8815 25.9825 31.75 27.4085 31.75 29.005V38.1499C31.75 39.8549 33.145 41.2499 34.85 41.2499H42.5999V47.4499C42.5999 48.3024 43.2974 48.9999 44.1499 48.9999C45.0024 48.9999 45.6999 48.3024 45.6999 47.4499V40.4749C45.6999 38.7699 44.3049 37.3749 42.5999 37.3749H37.95V32.0274C39.5 33.3139 41.67 34.4144 43.8399 34.8484C44.8009 35.0499 45.6999 34.3214 45.6999 33.3449ZM31.75 45.9C33.7805 45.9 35.5009 44.598 36.1364 42.8H39.3449C38.6319 46.334 35.5009 49 31.75 49C27.472 49 24 45.528 24 41.25C24 37.499 26.666 34.368 30.2 33.655V36.8635C28.402 37.5145 27.1 39.2195 27.1 41.25C27.1 43.823 29.177 45.9 31.75 45.9Z"
        fill="#A98D4B"
      />
    </svg>
  );
};
