import React, { useState, useEffect } from "react";

import { fetchNotificationCount } from "../../../api";

export function NotificationCount() {
  const [notificationData, setNotificationData] = useState({});
  // const [requestCount, setRequestCount] = useState(0);

  useEffect(() => {
    updateNotificationCount();
  }, []);

  const updateNotificationCount = async () => {
    try {
      const data = await fetchNotificationCount(
        localStorage.getItem("user_type")
      );
      setNotificationData(data);
      // setRequestCount((prevCount) => prevCount + 1);
      // setTimeout(updateNotificationCount, 10000);
    } catch (error) {
      console.error("Error fetching notification count:", error);
    }
  };

  return <>{notificationData.totalcount}</>;
}
