import React from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { CaseIcon } from "../../../assets/image/CaseIcon";

export function FavoriteProvider({ subtab, setsubtab, setprofilesection }) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <>
      <ul>
        {localStorage.getItem("user_type") !== "parents" && (
          <li
            className={subtab == "job-post" ? "active" : ""}
            onClick={(e) => {
              navigate("/search-providers/job-post");
              window.scrollTo({ top: 0, behavior: "smooth" });
              setsubtab("job-post");
            }}
          >
            <CaseIcon />
            {t("Job Posts")}
          </li>
        )}
      </ul>
    </>
  );
}
