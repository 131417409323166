import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export function ExploreLinks({ handleExploreClick, setShowModal }) {
  const { t } = useTranslation();

  return (
    <ul>
      <li>
        <Link to="/about-sensCare">
          <img src="./images/arrow.png" alt="Arrow icon" />
          <span onClick={handleExploreClick}>{t("meet-senscare-team")}</span>
        </Link>
      </li>
      <li>
        <Link
          to="#"
          onClick={() => {
            setShowModal(true);
            handleExploreClick();
          }}
        >
          <img src="./images/play.png" alt="Play icon" />
          <span>{t("explore-senscare-platform")}</span>
        </Link>
      </li>
    </ul>
  );
}
