import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import { HeartIcon } from "../../../assets/image/HeartIcon";
import { saveFavoriteJob } from "../../../api/jobApi";
import { RemoveModal } from "../RemoveModal";

export function FavoriteSelect({ heart, heart2, id, username }) {
  const navigate = useNavigate();

  const [isRemove, setIsRemove] = useState(false);
  const [isCheck, setIsCheck] = useState(true);
  const [removeCheck, setRemoveCheck] = useState(
    heart || heart2 == true ? true : false
  );

  const handleSubmitProfile = async (jobId) => {
    try {
      const result = await saveFavoriteJob(jobId);

      heart ? heart() : console.log(result);
    } catch (error) {
      console.error("Error saving favorite job:", error);
    }
  };

  return (
    <>
      <input
        type="checkbox"
        onChange={(e) => {
          if (localStorage.getItem("token") && localStorage.getItem("id")) {
            if (e.target.checked == false) {
              setIsRemove(true);
            } else {
              handleSubmitProfile(id);
              setRemoveCheck(true);
            }
          } else {
            navigate("/login");
          }
        }}
        checked={isCheck == false ? isCheck : removeCheck}
      />

      <HeartIcon heartFill={heart} />

      {isRemove && (
        <RemoveModal
          isRemove={isRemove}
          setIsRemove={setIsRemove}
          username={username}
          handleSubmitProfile={handleSubmitProfile}
          id={id}
          setIsCheck={setIsCheck}
          heart2={heart2}
          setRemoveCheck={setRemoveCheck}
        />
      )}
    </>
  );
}
