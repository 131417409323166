import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Modal from "react-bootstrap/Modal";

import { getPromoData } from "../../../api";

export function PromoCode(props) {
  const { t } = useTranslation();

  const [promoData, setPromoData] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [isCopy, setIsCopy] = useState(false);
  const [count, setCount] = useState(false);

  useEffect(() => {
    if (!count) {
      setCount(true);
      fetchPromoData();
    }
    setIsCopy(isCopy);
  }, [count, isCopy]);

  useEffect(() => {
    setIsCopy(isCopy);
  }, [isCopy]);

  const fetchPromoData = async () => {
    try {
      const result = await getPromoData();
      result.data.forEach((e) => {
        setPromoData((prevData) => [...prevData, e.name]);
      });
    } catch (error) {
      console.error("Error fetching promo data:", error);
    }
  };

  const handleCopy = (text) => {
    setIsCopy(true);
    handleCloseModal();
    setTimeout(() => {
      handleShowModal();
    }, 10);
    const el = document.createElement("textarea");
    el.value = text;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
  };

  const handleCloseModal = () => setShowModal(false);
  const handleShowModal = () => setShowModal(true);

  return (
    <>
      <Link to="#" variant="primary" onClick={handleShowModal}>
        {props.title ? props.title : t("discount")}
      </Link>

      {showModal && (
        <>
          <Modal show={showModal} onHide={handleCloseModal}>
            <Modal.Body>
              <div className="promocode_content">
                <Link to="" onClick={handleCloseModal}>
                  +
                </Link>
                <h2>{t("reward")}</h2>
                <p>
                  {t("rewardFirst")} <span>{t("monthly")}</span>
                  {t("remainsActive")}
                </p>
                <img src={window.location.origin + "/images/promo.png"} />
                <h5>
                  {t("advantages")}
                  <Link to="/signup"> {t("register")} </Link>
                  {t("getDiscount")}
                </h5>
                <p>
                  {t("enter")}
                  <span style={{ textTransform: "uppercase" }}>
                    {promoData}
                  </span>
                  {t("codePayment")}
                </p>
                <button
                  onClick={(e) => handleCopy(promoData.toString())}
                  class="tooltiptext"
                  id="myTooltip"
                >
                  {isCopy == true ? t("text-copied") : t("Copy code")}
                </button>
              </div>
            </Modal.Body>
          </Modal>
        </>
      )}
    </>
  );
}
