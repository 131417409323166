import React, { useRef } from "react";
import Slider from "react-slick";
import { TeamMember } from "./TeamMember";
import { TeamModal } from "./TeamModal";
import styles from "./AboutPage.module.css";

export function TeamSlider({ teamData, showEmail, handleShowEmail, handleCloseEmail }) {
  const sliderRef = useRef();

  const generalSettings = {
    dots: false,
    infinite: true,
    slidesToShow: 5,
    arrows: true,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 2000,
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          initialSlide: 3,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
    ],
  };

  return (
    <ul>
      <Slider ref={sliderRef} {...generalSettings} className={styles.slider_test}>
        {teamData.map((member, index) => (
          <div key={index}>
            <TeamMember member={member} index={index} handleShowEmail={handleShowEmail} />
            <TeamModal
              member={member}
              showEmail={showEmail}
              handleCloseEmail={handleCloseEmail}
              index={index}
            />
          </div>
        ))}
      </Slider>
    </ul>
  );
}
