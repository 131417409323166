import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Modal from "react-bootstrap/Modal";

import { CardPersonIcon } from "../../../../assets/image/CardPersonIcon";
import { GoogleDrive } from "../../GoogleDrive";
import { PowerIcon } from "../../../../assets/image/PowerIcon";
import { LaptopIcon } from "../../../../assets/image/LaptopIcon";

export const SendModal = ({
  showSendModal,
  setDocument,
  setIsShareModal,
  setShowSendModal,
  error,
  setSelectType,
  handleSubmitDocument,
  setShowDocumentModal,
}) => {
  const { t } = useTranslation();

  return (
    <Modal
      show={showSendModal}
      onHide={(e) => {
        setShowSendModal(false);
      }}
    >
      <Modal.Body>
        <div className="promocode_content signout senddoc">
          <Link
            to=""
            onClick={(e) => {
              setShowSendModal(false);
            }}
          >
            +
          </Link>
          <h2>{t("select-documents")}</h2>
          <ul>
            <li>
              <span class="view-message  dont-show">
                <input
                  type="radio"
                  class="mail-radio"
                  name="chk"
                  onChange={(e) => {
                    setSelectType("Mydocument");
                    setIsShareModal(true);
                  }}
                />
                <span className="circle"></span>
              </span>
              <span className="bothtype">
                <CardPersonIcon />
                <p>
                  <h3>{t("title-my-documents")}</h3>
                  <span>{t("select-files-platform")}</span>
                </p>
              </span>
            </li>
            <li>
              <span class="view-message  dont-show">
                <input
                  type="radio"
                  class="mail-radio"
                  name="chk"
                  defaultChecked="true"
                  onChange={(e) => {
                    setSelectType("Mycomputer");
                  }}
                />
                <span className="circle"></span>
              </span>
              <span className="bothtype">
                <LaptopIcon />
                <p>
                  <h3>{t("text-computer")}</h3>
                  <span>{t("select-files-computer")}</span>
                </p>
              </span>
            </li>
            <li>
              <span class="view-message  dont-show">
                <GoogleDrive />
                <span className="circle"></span>
              </span>
              <span className="bothtype">
                <PowerIcon />
                <p>
                  <h3>{t("text-drive")}</h3>
                  <span>{t("select-files-drive")}</span>
                </p>
              </span>
            </li>
          </ul>
          <div className="file">
            <label>{t("file-name")}</label>
            <div className="group_label">
              <span>
                {Object.values(document)[0] ? t("choose-file") : document.name}
              </span>
              <input
                type="file"
                onChange={(e) => {
                  setDocument(e.target.files[0]);
                }}
                onClick={() => {
                  setSelectType("Mycomputer");
                  setShowDocumentModal(false);
                }}
                accept=".doc, .docx,.pdf"
              />
              <button onClick={(e) => handleSubmitDocument()}>
                {t("text-upload")}
              </button>
            </div>
          </div>
          <p style={{ color: "red" }}>
            {error != "" ? "Please select Document!" : ""}
          </p>
          <p class="hide">
            
            {t("text-visit")}
            <Link to="/faq" target="_blank">
              {t("text-faq")}
            </Link>
            {t("text-page")}
            <Link to="/safety-center" target="_blank">
              {t("safety-center")}
            </Link>
            {t("safety-tips")}
          </p>
        </div>
      </Modal.Body>
    </Modal>
  );
};
