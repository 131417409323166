export const JobListIcon = () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.3 0H0.7C0.311111 0 0 0.311111 0 0.7V13.3C0 13.6111 0.311111 14 0.7 14H13.3C13.6111 14 14 13.6111 14 13.3V0.7C14 0.311111 13.6111 0 13.3 0ZM4.66682 3.1099H3.11126V4.66545H4.66682V3.1099ZM10.889 3.1099H6.2223V4.66545H10.889V3.1099ZM10.889 6.21979H6.2223V7.77535H10.889V6.21979ZM6.2223 9.33516H10.889V10.8907H6.2223V9.33516ZM3.11126 6.21979H4.66682V7.77535H3.11126V6.21979ZM4.66682 9.33516H3.11126V10.8907H4.66682V9.33516ZM1.55563 12.4438H12.4445V1.55495H1.55563V12.4438Z"
        fill="#636363"
      />
    </svg>
  );
};
