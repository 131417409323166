import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import { api } from "../../../urls";

import { JobApplicationDetail } from "../JobApplicationDetail";
import { RaffleIcon } from "../../../assets/image/RaffleIcon";
import { RebootIcon } from "../../../assets/image/RebootIcon";
import { ArrowLeftIcon } from "../../../assets/image/ArrowLeftIcon";
import { REFINE_OPTIONS } from "../../../utils/variables";
import { JobApplicationRow } from "./JobApplicationRow";
import { useInterviewStore } from "../../../store/useInterviewStore";

export function JobApplication() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [isCheck, setIsCheck] = useState(true);
  const [jobApplicationData, setJobApplicationData] = useState([]);
  const [isActive, setIsActive] = useState(false);

  const [deleteList, setDeleteList] = useState([]);

  const {
    recived,
    setRecived,
    view,
    setView,
    refine,
    setRefine,
    resetInterviewState,
  } = useInterviewStore();

  const slugData = useParams();
  let data = slugData.name;

  useEffect(() => {
    return () => {
      resetInterviewState();
    };
  }, []);

  const fetchJobApplicationData = () => {
    setJobApplicationData([]);
    const myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      api +
        "/api/v1/jobapplicationsnotification?" +
        (refine.threedays != ""
          ? "threedays=" + refine.threedays
          : refine.withinweek != ""
          ? "withinweek=" + refine.withinweek
          : refine.twofourday != ""
          ? "twofourday=" + refine.twofourday
          : refine.withinmonth != ""
          ? "withinmonth=" + refine.withinmonth
          : ""),
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => setJobApplicationData(result.data.notifications))
      .catch((error) => console.log("error", error));
  };
  useEffect(() => {
    if (isCheck) {
      fetchJobApplicationData();
      setIsCheck(false);
    }
    console.log(jobApplicationData);

    setView(data ? data : "");
  }, [isCheck, jobApplicationData, data, slugData]);
  useEffect(() => {
    if (window.innerWidth < 768) {
      setRecived("text-refine");
    }
  }, []);

  const handleSelectElement = () => {
    const ele = document.getElementsByName("chk");
    jobApplicationData.map((data) => {
      deleteList.push(data.id);
    });
    for (let i = 0; i < ele.length; i++) {
      if (ele[i].type == "checkbox") ele[i].checked = true;
    }
  };
  const handleDeselectElement = () => {
    const ele = document.getElementsByName("chk");
    for (let i = 0; i < ele.length; i++) {
      if (ele[i].type == "checkbox") ele[i].checked = false;
    }
    setDeleteList([]);
  };

  const handleDeleteSelect = (a) => {
    let sum = false;
    deleteList.map((e) => {
      if (e == a) {
        sum = true;
        const index = deleteList.indexOf(a);
        if (index > -1) {
          deleteList.splice(index, 1);
        }
      }
    });
    if (sum == false) {
      deleteList.push(a);
    }
  };

  const handleSubmit = () => {
    const myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      ids: deleteList,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(api + "/api/v1/multideletejobapplication", requestOptions)
      .then((response) => response.text())
      .then((result) => {
        fetchJobApplicationData();
      })
      .catch((error) => console.log("error", error));
  };

  const handleRefineDay = (e) => {
    setRefine({
      threedays: e == "3" ? true : "",
      withinweek: e == "7" ? true : "",
      twofourday: e == "24" ? true : "",
      withinmonth: e == "30" ? true : "",
    });
    setIsCheck(true);
  };

  return (
    <div>
      {view == "" ? (
        <>
          <div class="main-header">
            <h2 className="border">{t("job-applications")}</h2>
            <div class="mail-header-bar">
              <p>
                {jobApplicationData.length} {t("text-applications")}
              </p>
              <div class="btn-group flex">
                {t("text-refine")}
                <div className="select">
                  <label onClick={(e) => setIsActive(!isActive)}>
                    {t(recived)}
                    <span>
                      <img src="/images/done_a.svg" />
                    </span>
                  </label>
                  {isActive && (
                    <ul>
                      {REFINE_OPTIONS.map((option) => (
                        <li
                          key={option.label}
                          onClick={() => {
                            setRecived(t(option.label));
                            handleRefineDay(option.value);
                            setIsActive(!isActive);
                          }}
                        >
                          {t(option.label)}
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              </div>
            </div>
            <div class="mail-option">
              <div class="chk-all">
                <input
                  type="checkbox"
                  class="mail-radio mail-group-radio"
                  name="chk"
                  id="checkbox_id"
                  onClick={(e) => {
                    if (e.target.checked) {
                      handleSelectElement();
                    } else {
                      handleDeselectElement();
                    }
                  }}
                />
                <span className="circle"></span>
                <label for="checkbox_id">{t("select-all")}</label>
              </div>
              <div class="right-side-btn">
                <div class="delete-btn" onClick={(e) => handleSubmit()}>
                  <a href="#">
                    <RaffleIcon />
                    <span> {t("text-delete")}</span>
                  </a>
                </div>
                <div
                  class="refresh-btn"
                  onClick={(e) => fetchJobApplicationData()}
                >
                  <a href="#">
                    <RebootIcon />
                  </a>
                  <span>{t("text-refresh")}</span>
                </div>
              </div>
            </div>
          </div>
          <table class="table table-inbox table-hover  free_text">
            <tbody>
              {jobApplicationData.map((data) => {
                return (
                  <JobApplicationRow
                    key={data.id}
                    data={data}
                    handleDeleteSelect={handleDeleteSelect}
                    slugData={slugData}
                  />
                );
              })}
            </tbody>
          </table>
        </>
      ) : (
        <div class="main-header">
          <button
            onClick={(e) => {
              navigate("/search-parents/" + slugData.id);
              setView("");
            }}
          >
            <ArrowLeftIcon />
            {t("text-back")}
          </button>
          <JobApplicationDetail id={view} />
        </div>
      )}
    </div>
  );
}
