import { create } from "zustand";

export const useQualificationStore = create((set) => ({
  detailProvider: {
    recommendationsfile: "",
    qualificationscertificatesname: "",
    cv: "",
    qualificationscertificatesdoc: "",
    backgrounddoc: "",
  },
  
  setDetailProvider: (newDetailProvider) =>
    set((state) => ({
      detailProvider: { ...state.detailProvider, ...newDetailProvider },
    })),
  certificates: {
    qualificationscertificatesname: "",
    qualificationscertificatesname2: "",
    qualificationscertificatesname3: "",
    qualificationscertificatesname4: "",
  },
  setCertificates: (newCertificates) =>
    set((state) => ({
      certificates: { ...state.certificates, ...newCertificates },
    })),
  errorField: {
    recommendationsfile: "",
    qualificationscertificatesname: "",
    cv: "",
    qualificationscertificatesdoc: "",
    backgrounddoc: "",
  },
  setErrorField: (newErrorField) =>
    set((state) => ({
      errorField: { ...state.errorField, ...newErrorField },
    })),

  resetQualificationState: () =>
    set({
      detailProvider: {
        recommendationsfile: "",
        qualificationscertificatesname: "",
        cv: "",
        qualificationscertificatesdoc: "",
        backgrounddoc: "",
      },
      certificates: {
        qualificationscertificatesname: "",
        qualificationscertificatesname2: "",
        qualificationscertificatesname3: "",
        qualificationscertificatesname4: "",
      },
      errorField: {
        recommendationsfile: "",
        qualificationscertificatesname: "",
        cv: "",
        qualificationscertificatesdoc: "",
        backgrounddoc: "",
      },
    }),
}));
