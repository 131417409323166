import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Modal from "react-bootstrap/Modal";

import { DATA_COUNTRY } from "../../utils/data";

export const NewCardModal = ({
  isNewCardModal,
  setIsNewCardModal,
  editCountry,
  dataCountry,
  setEditCountry,
  setDataCountry,
}) => {
  const { t } = useTranslation();

  return (
    <Modal
      show={isNewCardModal}
      onHide={(e) => setIsNewCardModal(false)}
      className="editcard"
    >
      <Modal.Body>
        <div
          className="promocode_content younger reset reset2 paymentedit"
          style={{ paddingBottom: "40px" }}
        >
          <Link to="" onClick={(e) => setIsNewCardModal(false)}>
            +
          </Link>
          {editCountry ? (
            <div
              className="bgremove"
              onClick={(e) => setEditCountry(!editCountry)}
            ></div>
          ) : (
            ""
          )}
          <div className="panel-body dasktopshow" style={{ padding: "30px" }}>
            <h2>{t("Add new card")}</h2>
            <form>
              <div className="row">
                <div className="form-group col-md-6">
                  <div className="field-box">
                    <label className="label" for="cardname">
                      {t("Name on card")}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="cardname"
                      placeholder={t("Name")}
                      data-required
                    />
                  </div>
                  <div className="field-box">
                    <label className="label" for="billaddress">
                      {t("Billing address")}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="billaddress"
                      placeholder={t("Address*")}
                    />
                  </div>
                  <div className="field-box">
                    <div className="row coln">
                      <div
                        className="col-md-6"
                        style={{ position: "relative" }}
                      >
                        <label className="label" for="city">
                          {t("Country")}
                        </label>
                        <span
                          className="duplicate"
                          onClick={(e) => setEditCountry(!editCountry)}
                        >
                          {dataCountry != "" ? dataCountry : "Select"}
                        </span>
                        {editCountry ? (
                          <ul className="country_custom">
                            {DATA_COUNTRY.data.map((data, i) => {
                              return (
                                <li
                                  onClick={() => {
                                    setEditCountry(!editCountry);
                                    setDataCountry(data.country);
                                  }}
                                >
                                  {data.country}
                                </li>
                              );
                            })}
                          </ul>
                        ) : (
                          ""
                        )}
                      </div>

                      {dataCountry == "United States" ? (
                        <div className="col-md-6">
                          <label className="label" for="city">
                            {t("State")}
                          </label>
                          <input type="text" placeholder={t("State")} />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>

                <div className="form-group col-md-6">
                  <div className="field-box">
                    <label className="label" for="cardnumber">
                      {t("card-number")}
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      id="cardnumber"
                      placeholder="1234 56789"
                      maxLength="16"
                      data-required
                    />
                  </div>
                  <div className="field-box">
                    <div className="row coln">
                      <div className="col-md-6">
                        <label className="label" for="expdate">
                          {t("expiration-date")}
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          id="expdate"
                          placeholder="MM/YY"
                          maxLength="4"
                          data-required
                        />
                      </div>

                      <div className="col-md-6">
                        <label className="label" for="securitycode">
                          {t("Security #")}
                        </label>
                        <input
                          type="password"
                          className="form-control"
                          id="securitycode"
                          maxLength="4"
                          placeholder="xxxxxx"
                          data-required
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row coln field-box">
                    <div className="col-md-6">
                      <label className="label" for="city">
                        {t("text-city")}
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="city"
                        placeholder="New York"
                        data-required
                      />
                    </div>

                    <div className="col-md-6">
                      <label className="label" for="zipCode">
                        {t("ZIP Code")}
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        id="zipCode"
                        placeholder="12533665"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="save-info field-box">
                <input type="checkbox" id="option1" name="option1" value="" />
                <label>
                  <img
                    className="tickicon1"
                    src={window.location.origin + "/images/check.svg"}
                    alt=""
                  />
                  {t("save-card-future-payments")}
                </label>
              </div>
            </form>
            <div className="button text-center">
              <div className="pull-right">
                <button
                  className="btn"
                  onClick={(e) => setIsNewCardModal(false)}
                >
                  {t("text-cancel")}
                </button>
              </div>
              <div className="pull-right">
                <button className="btn confirm">{t("Save")}</button>
              </div>
            </div>
          </div>
          <div className="panel-body mobileshow" style={{ padding: "30px" }}>
            <h2>{t("Add new card")}</h2>
            <form>
              <div className="row">
                <div className="form-group col-md-6">
                  <div className="field-box">
                    <label className="label" for="cardname">
                      {t("Name on card")}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="cardname"
                      placeholder={t("Name")}
                      data-required
                    />
                  </div>
                  <div className="field-box">
                    <label className="label" for="billaddress">
                      {t("Billing address")}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="billaddress"
                      placeholder={t("Address")}
                    />
                  </div>
                  <div className="field-box" style={{ position: "relative" }}>
                    <label className="label" for="city">
                      {t("Country")}
                    </label>
                    <span
                      className="duplicate"
                      onClick={(e) => setEditCountry(!editCountry)}
                    >
                      {dataCountry != "" ? dataCountry : "Select"}
                    </span>
                    {editCountry ? (
                      <ul className="country_custom">
                        {DATA_COUNTRY.data.map((data, i) => {
                          return (
                            <li
                              onClick={() => {
                                setEditCountry(!editCountry);
                                setDataCountry(data.country);
                              }}
                            >
                              {data.country}
                            </li>
                          );
                        })}
                      </ul>
                    ) : (
                      ""
                    )}
                  </div>
                  {dataCountry == "United States" ? (
                    <div className="col-md-6">
                      <label className="label" for="city">
                        {t("State")}
                      </label>
                      <input type="text" placeholder="State" />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="form-group col-md-6">
                  <div className="row coln field-box">
                    <div className="col-md-6">
                      <label className="label" for="city">
                        {t("text-city")}
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="city"
                        placeholder="New York"
                        data-required
                      />
                    </div>
                    <div className="col-md-6">
                      <label className="label" for="zipCode">
                        {t("ZIP Code")}
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        id="zipCode"
                        placeholder="12533665"
                      />
                    </div>
                  </div>
                  <div className="field-box">
                    <label className="label" for="cardnumber">
                      {t("card-number")}
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      id="cardnumber"
                      placeholder="1234 56789"
                      maxLength="16"
                      data-required
                    />
                  </div>
                  <div className="field-box">
                    <div className="row coln">
                      <div className="col-md-6">
                        <label className="label" for="expdate">
                          {t("expiration-date")}
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          id="expdate"
                          placeholder="MM/YY"
                          maxLength="4"
                          data-required
                        />
                      </div>

                      <div className="col-md-6">
                        <label className="label" for="securitycode">
                          {t("Security #")}
                        </label>
                        <input
                          type="password"
                          className="form-control"
                          id="securitycode"
                          maxLength="4"
                          placeholder="xxxxxx"
                          data-required
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="save-info field-box">
                <input type="checkbox" id="option1" name="option1" value="" />
                <label>
                  <img
                    className="tickicon1"
                    src={window.location.origin + "/images/check.svg"}
                    alt=""
                  />
                  {t("save-card-future-payments")}
                </label>
              </div>
            </form>
            <div className="button text-center">
              <div className="pull-right">
                <button
                  className="btn"
                  onClick={(e) => setIsNewCardModal(false)}
                >
                  {t("text-cancel")}
                </button>
              </div>
              <div className="pull-right">
                <button className="btn confirm">Save</button>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
