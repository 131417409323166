export const HomeIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="10" cy="10" r="10" fill="#7D2B8B" />
      <path
        d="M8.4 16V11.4118H11.6V16H15.6V9.88235H18L10 3L2 9.88235H4.4V16H8.4Z"
        fill="white"
      />
    </svg>
  );
};
