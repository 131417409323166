export const SearchSecondaryIcon = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.156 14.4283C11.7703 15.5369 10.0125 16.1998 8.0999 16.1998C3.62645 16.1998 0 12.5733 0 8.0999C0 3.62645 3.62645 0 8.0999 0C12.5733 0 16.1998 3.62645 16.1998 8.0999C16.1998 10.0123 15.5371 11.7699 14.4287 13.1555L17.7368 16.4636C18.0883 16.8151 18.0883 17.3849 17.7368 17.7364C17.3854 18.0879 16.8155 18.0879 16.4641 17.7364L13.156 14.4283ZM1.79998 8.0999C1.79998 4.62055 4.62055 1.79998 8.0999 1.79998C11.5792 1.79998 14.3998 4.62055 14.3998 8.0999C14.3998 9.79867 13.7274 11.3404 12.6343 12.4735C12.6047 12.4964 12.5763 12.5215 12.5491 12.5487C12.5219 12.5759 12.4969 12.6043 12.4739 12.6339C11.3408 13.7273 9.79887 14.3998 8.0999 14.3998C4.62055 14.3998 1.79998 11.5792 1.79998 8.0999Z"
        fill="white"
      />
    </svg>
  );
};
