import React from "react";
import { useTranslation } from "react-i18next";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";

export const LoginModal = ( {isLoginModal, setIsLoginModal, setIsSurveyModal}) => {
  const { t } = useTranslation();

  return (
    <Modal show={isLoginModal} onHide={(e) => setIsLoginModal(false)}>
      <Modal.Body>
        <div className="promocode_content cancelmembership reportthanks">
          <div className="">
            <p>
              <strong>{t("thank-message")}</strong>
            </p>
            <br />
            <p className={"second"}>
              <b>{t("senscare-safety-seriously")} </b>
            </p>
            <br />
            <p className="giveus">
              {t("meantime-visit-our")}
              <Link to="/safety-center" target={"_blank"}>
                {t("safety-center")}
              </Link>
              {t("")}
              <Link to="/faq" target={"_blank"}>
                {t("text-faq")}
              </Link>
              {t("for-more-info")}
            </p>

            <div className="button text-center">
              <div className="pull-right">
                <button
                  className="btn"
                  onClick={(e) => {
                    setIsLoginModal();
                    setIsSurveyModal(false);
                  }}
                >
                  {t("text-close-up")}
                </button>
              </div>
            </div>
            <br />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
