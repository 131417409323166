import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { api } from "../../../urls";
import { useParams, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { JobApplicationHistoryDetail } from "../JobApplicationHistoryDetail";
import { RaffleIcon } from "../../../assets/image/RaffleIcon";
import { RebootIcon } from "../../../assets/image/RebootIcon";
import { ArrowLeftIcon } from "../../../assets/image/ArrowLeftIcon";
import {
  ENG_LANGUAGE,
  MONTH_ENG_LANGUAGE,
  MONTH_SRB_LANGUAGE,
} from "../../../utils/variables";

export function JobApplicationHistory() {
  const { t, i18n } = useTranslation();
  const language = i18n.language;
  const navigate = useNavigate();

  const [isCheck, setIsCheck] = useState(true);
  const [jobData, setJobData] = useState([]);
  const [view, setView] = useState("");
  const [deleteList, setDeleteList] = useState([]);
  const [isActive, setIsActive] = useState(false);
  const [refine, setRefine] = useState("");

  const slugData = useParams();
  const data = slugData.name;

  const month =
    language === ENG_LANGUAGE ? MONTH_ENG_LANGUAGE : MONTH_SRB_LANGUAGE;

  const handleJobData = (a) => {
    setJobData([]);
    const myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      api +
        "/api/v1/providerappliedjobslist?" +
        (a != undefined
          ? a == "New"
            ? "new_search=" + 0
            : "job_search=" + a
          : refine),
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => setJobData(result.data.providerappliedjobslist))
      .catch((error) => console.log("error", error));
  };

  useEffect(() => {
    if (isCheck) {
      handleJobData();
      setIsCheck(false);
    }
    console.log(jobData);
    setView(data ? data : "");
  }, [isCheck, jobData, data, slugData]);

  const handleSelectElement = () => {
    const ele = document.getElementsByName("chk");
    jobData.map((data) => {
      deleteList.push(data.id);
    });
    for (let i = 0; i < ele.length; i++) {
      if (ele[i].type == "checkbox") ele[i].checked = true;
    }
  };
  const handleDeselectElement = () => {
    const ele = document.getElementsByName("chk");
    for (let i = 0; i < ele.length; i++) {
      if (ele[i].type == "checkbox") ele[i].checked = false;
    }
    setDeleteList([]);
  };

  const handleDeleteElement = (a) => {
    let sum = false;
    deleteList.map((e) => {
      if (e == a) {
        sum = true;
        const index = deleteList.indexOf(a);
        if (index > -1) {
          deleteList.splice(index, 1);
        }
      }
    });
    if (sum == false) {
      deleteList.push(a);
    }
  };

  const handleDeleteJobs = () => {
    const myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      ids: deleteList,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(api + "/api/v1/providerappliedjobsdelete", requestOptions)
      .then((response) => response.text())
      .then((result) => {
        handleJobData();
      })
      .catch((error) => console.log("error", error));
  };
  useEffect(() => {
    if (window.innerWidth < 768) {
      setRefine("text-refine");
    }
  }, []);

  const handleClick = (value, label) => {
    handleJobData(value);
    setRefine(t(label));
    setIsActive(!isActive);
  };

  return (
    <div>
      {view == "" ? (
        <>
          <div class="main-header">
            <h2 className="border">{t("job-applications")}</h2>
            <div class="mail-header-bar">
              <p>
                {jobData.length} {t("job-applications")}
              </p>
              <div class="btn-group flex">
                {t("text-refine")}
                <div className="select">
                  <label onClick={(e) => setIsActive(!isActive)}>
                    {t(refine)}
                    <span>
                      <img src="/images/done_a.svg" />
                    </span>
                  </label>
                  {isActive && (
                    <ul>
                      <li onClick={() => handleClick("", "text-all")}>
                        {t("text-all")}
                      </li>
                      <li onClick={() => handleClick("New", "New")}>
                        {t("text-new")}
                      </li>
                      <li onClick={() => handleClick("0", "Active")}>
                        {t("text-active")}
                      </li>
                      <li onClick={() => handleClick("1", "Closed")}>
                        {t("text-closed")}
                      </li>
                    </ul>
                  )}
                </div>
              </div>
            </div>
            <div class="mail-option">
              <div class="chk-all">
                <input
                  type="checkbox"
                  class="mail-radio mail-group-radio"
                  name="chk"
                  id="checkbox_id"
                  onClick={(e) => {
                    if (e.target.checked) {
                      handleSelectElement();
                    } else {
                      handleDeselectElement();
                    }
                  }}
                />
                <span className="circle"></span>
                <label for="checkbox_id">{t("select-all")}</label>
              </div>
              <div class="right-side-btn">
                <div class="delete-btn" onClick={(e) => handleDeleteJobs()}>
                  <a href="#">
                    <RaffleIcon />
                    <span> {t("text-delete")}</span>
                  </a>
                </div>
                <div class="refresh-btn" onClick={(e) => handleJobData()}>
                  <a href="#">
                    <RebootIcon />
                  </a>
                  <span>{t("text-refresh")}</span>
                </div>
              </div>
            </div>
          </div>
          <table class="table table-inbox table-hover hovertable">
            <tbody>
              {jobData.map((data) => {
                return (
                  <tr
                    class={
                      data.jobStatus == 0
                        ? data.provider_readstatus == 0 ||
                          (new Date(data.created_at).getDate() >
                          new Date().getDate()
                            ? new Date(data.created_at).getMonth() >
                              new Date().getMonth()
                            : new Date(data.created_at).getDate() >=
                              new Date().getDate() - 7)
                          ? "unread"
                          : data.jobStatus == 0
                          ? "unread2"
                          : t("text-close")
                        : t("text-close")
                    }
                  >
                    <td class="view-message  dont-show">
                      <input
                        type="checkbox"
                        class="mail-radio"
                        name="chk"
                        onChange={(e) => handleDeleteElement(data.id)}
                      />
                      <span className="circle"></span>
                      <span
                        onClick={(e) => {
                          navigate(
                            "/search-providers/" + slugData.id + "/" + data.id
                          );
                          setView(data.id);
                        }}
                      >
                        
                        {data.jobStatus == 0 ? (
                          data.provider_readstatus == 0 ||
                          (new Date(data.created_at).getDate() >
                          new Date().getDate()
                            ? new Date(data.created_at).getMonth() >
                              new Date().getMonth()
                            : new Date(data.created_at).getDate() >=
                              new Date().getDate() - 7) ? (
                            <span class="new-tag"> {t("text-new-upper")}</span>
                          ) : data.jobStatus == 0 ? (
                            <span class="new-tag"> {t("text-active")}</span>
                          ) : data.jobStatus >= 0 ? (
                            <span class="new-tag"> {t("text-closed")}</span>
                          ) : (
                            ""
                          )
                        ) : (
                          <span class="new-tag"> {t("text-closed")}</span>
                        )}
                        <strong
                          onClick={(e) => {
                            navigate(
                              "/search-providers/" + slugData.id + "/" + data.id
                            );
                            setView(data.id);
                          }}
                        >
                          {data.title.substr(0, 40)}
                        </strong>
                        - {data.description.substr(0, 40)}
                      </span>
                    </td>
                    <td class="view-message  text-right">
                      {month[new Date(data.created_at).getMonth()] +
                        " " +
                        new Date(data.created_at).getDate()}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </>
      ) : (
        <div class="main-header">
          <button
            onClick={(e) => {
              navigate("/search-providers/" + slugData.id);
              setView("");
            }}
          >
            <ArrowLeftIcon />
            {t("text-back")}
          </button>
          <JobApplicationHistoryDetail id={view} />
        </div>
      )}
    </div>
  );
}
