import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { DEFAULT_API } from "../../../utils/variables";
import { PhoneIcon } from "../../../assets/image/PhoneIcon";
import { ServiceIcon } from "../../../assets/image/ServiceIcon";
import { SocialFacebook } from "../../common/SocialFacebook";
import { StarIcon } from "../../../assets/image/StarIcon";
import { WhiteStarIcon } from "../../../assets/image/WhiteStarIcon";

export const JobProfileCard = ({
  data,
  jobData,
  profile,
  setIsElement,
  handleElementById,
  setIsDelete,
  setIsJobPost,
  index,
}) => {
  const { t } = useTranslation();

  const calculateAge = (dob) => {
    return (
      new Date().getFullYear() -
      parseInt(dob.substr(dob.lastIndexOf("\\") + 1).split("-")[0])
    );
  };

  const calculateRate = (rate) => {
    return (
      rate.substr(rate.lastIndexOf("\\") + 1).split("-")[0] * 100 +
      " - " +
      rate.substr(rate.lastIndexOf("\\") + 1).split("-")[1] * 100
    );
  };

  return (
    <div className="looking_for_candidate_boxs">
      <div className="looking_for_candidate_box">
        <div className="first_sec">
          <div className="image_sec">
            <img
              src={
                data.file_path != null
                  ? `${DEFAULT_API}/assets/images/users/${data.file_path}`
                  : `${window.location.origin}/img/nany_img.png`
              }
              alt=""
            />
            <div className="heart_sec">
              <img
                src={`${window.location.origin}/images/img_heart.svg`}
                alt=""
              />
            </div>
          </div>
          <div className="nany_social">
            {data.phoneVerifiedStatus === 1 ? (
              <img
                src={`${window.location.origin}/images/nany_phone.svg`}
                alt=""
              />
            ) : (
              <PhoneIcon />
            )}
            <img src={`${window.location.origin}/images/nany_msg.svg`} alt="" />
            {data.facebookverify != null || data.linkdinverify != null ? (
              <img
                src={`${window.location.origin}/images/nany_cont.svg`}
                alt=""
              />
            ) : (
              <ServiceIcon />
            )}
          </div>
        </div>
        <div className="second_sec">
          <div className="heading">
            <h3>{data.title != null ? data.title : ""}</h3>
            {data.plateformonsocialmedia === "Yes" && (
              <SocialFacebook
                link={`${window.location.origin}/profile-parents/${data.user_id}`}
              />
            )}
          </div>
          <div className="post_general">
            <h6>
              <Link to={`/profile-parents/${jobData[0]?.user_id}`}>
                {data.first_name != null
                  ? `${data.first_name} ${data.last_name} `
                  : ""}
              </Link>
              ({data.dob != undefined ? calculateAge(data.dob) : ""})
            </h6>
            <p className="stars_profile">
              {data.reviewAvg >= 0 && (
                <>
                  {[...Array(data.reviewAvg)].map((_, index) => (
                    <StarIcon key={index} />
                  ))}
                  {[...Array(5 - data.reviewAvg)].map((_, index) => (
                    <WhiteStarIcon key={index} />
                  ))}
                </>
              )}
              <span> ({data.reviewcount})</span>
            </p>
          </div>
          <div className="post_detail">
            <div className="post">
              {data.job_type && (
                <img
                  src={
                    data.job_type === "Nanny"
                      ? `${window.location.origin}/images/nany_post.svg`
                      : data.job_type === "Special Education Teacher"
                      ? `${window.location.origin}/images/teacher_post.svg`
                      : data.job_type === "Special Education Paraprofessional"
                      ? `${window.location.origin}/images/education_post.svg`
                      : data.job_type === "Tutor"
                      ? `${window.location.origin}/images/tutor_post.svg`
                      : ""
                  }
                  alt=""
                />
              )}
              <h5>{data.job_type != null ? t(data.job_type) : ""}</h5>
            </div>
            <div className="vi"></div>
            <div className="post_pay">
              <img src={`${window.location.origin}/img/post_pay.png`} alt="" />
              <h5>
                {profile && profile.country === "Serbia"
                  ? calculateRate(data.nanyperhrrate || data.tutorperhrrate)
                  : `$${data.nanyperhrrate || data.tutorperhrrate}`}
                {t("time-hour")}
              </h5>
            </div>
            <div className="vi"></div>
            <div className="post_fet">
              <img
                src={`${window.location.origin}/images/post_fet.svg`}
                alt=""
              />
              <h5>
                {data.tutorintrestedin != null ? t(data.tutorintrestedin) : ""}
              </h5>
            </div>
            <div className="vi"></div>
            <div className="post_cal">
              <img
                src={`${window.location.origin}/images/post_cal.svg`}
                alt=""
              />
              <h5>{data.tutorstartdate != null ? data.tutorstartdate : ""}</h5>
            </div>
            <div className="vi"></div>
            <div className="post_loc">
              <img
                src={`${window.location.origin}/images/post_loc.svg`}
                alt=""
              />
              <h5>
                {data.country != null ? data.country : ""} ,
                {data.city != null ? data.city : ""}
              </h5>
            </div>
          </div>
          <p id={`half${index}`}>
            {data.description != null ? data.description.substr(0, 100) : ""}
            {data.description != null && data.description.length > 100 && (
              <span
                onClick={() => {
                  setIsElement(true);
                  handleElementById(
                    `half${index}`,
                    `full${index}`,
                    `plink${index}`
                  );
                }}
              >
                {t("text-more")}
              </span>
            )}
          </p>
          <p id={`full${index}`} className="hide">
            {data.description}
            <span
              onClick={() =>
                handleElementById(
                  `full${index}`,
                  `half${index}`,
                  `plink${index}`
                )
              }
            >
              {t("text-less")}
            </span>
          </p>
        </div>
        <div
          className="view_profile_btn brownntn"
          style={{
            float: "right",
            width: "100%",
            textAlign: "right",
            display: "block",
          }}
        >
          <button
            style={{ marginRight: "6px" }}
            onClick={() => setIsDelete(true)}
          >
            {t("close-job")}
          </button>
          <button onClick={() => setIsJobPost(true)}>{t("edit-post")}</button>
        </div>
      </div>
    </div>
  );
};
