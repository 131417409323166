export const TeacherIcon = () => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="15" cy="15" r="15" fill="#fff" />
      <circle cx="15" cy="15" r="15" fill="#fff" />
      <circle cx="14.8574" cy="14.8567" r="12.3571" stroke="white" />
      <path
        d="M22.0269 16.0174C20.9457 16.0174 20.4774 16.8402 19.89 16.8402C18.3281 16.8402 19.7498 12.2505 19.7498 12.2505C19.7498 12.2505 14.6381 14.3338 14.6381 12.1646C14.6381 11.2316 15.5831 10.9602 15.5831 9.92544C15.5831 9.0009 14.8509 8.50049 13.9825 8.50049C13.0801 8.50049 12.2543 8.99244 12.2543 9.96783C12.2543 11.045 13.0801 11.5115 13.0801 12.0967C13.0801 13.9098 8.49976 12.8431 8.49976 12.8431V21.5212C8.49976 21.5212 13.1517 22.5901 13.1517 20.7748C13.1517 20.1896 12.11 19.7303 12.11 18.6531C12.11 17.6777 12.8718 17.1858 13.7657 17.1858C14.6426 17.1858 15.3748 17.6862 15.3748 18.6107C15.3748 19.6455 14.4298 19.9169 14.4298 20.8499C14.4298 22.4333 17.8067 21.52 19.1493 21.52C19.1493 21.52 18.245 18.3923 19.8219 18.3923C20.7584 18.3923 21.0308 19.3338 22.0695 19.3338C22.9975 19.3338 23.4998 18.6044 23.4998 17.7308C23.4998 16.8402 23.006 16.0174 22.0269 16.0174Z"
        fill="#7D2B8B"
      />
    </svg>
  );
};
