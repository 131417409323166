import React from "react";
import { useTranslation } from "react-i18next";

export const ProviderProfileLanguage = ({ profile }) => {
  const { t } = useTranslation();

  return (
    <div className="Profile_complete">
      <div className="detail  additional_info no_cur">
        <div className="form_group full">
          <label>{t("Languages")}</label>
          {profile.englishlevel && (
            <div className="language">
              <strong>{t("english")}</strong>
              <ul>
                <li
                  className={profile.englishlevel == "Beginner" ? "active" : ""}
                >
                  {t("Beginner")}
                </li>
                <li
                  className={
                    profile.englishlevel == "Intermediate" ? "active" : ""
                  }
                >
                  {t("Intermediate")}
                </li>
                <li
                  className={profile.englishlevel == "Fluent" ? "active" : ""}
                >
                  {t("Fluent")}
                </li>
                <li
                  className={profile.englishlevel == "Native" ? "active" : ""}
                >
                  {t("Native")}
                </li>
              </ul>
            </div>
          )}
          {profile.frenchlevel && (
            <div className="language">
              <strong>{t("french")}</strong>
              <ul>
                <li
                  className={profile.frenchlevel == "Beginner" ? "active" : ""}
                >
                  {t("Beginner")}
                </li>
                <li
                  className={
                    profile.frenchlevel == "Intermediate" ? "active" : ""
                  }
                >
                  {t("Intermediate")}
                </li>
                <li className={profile.frenchlevel == "Fluent" ? "active" : ""}>
                  {t("Fluent")}
                </li>
                <li className={profile.frenchlevel == "Native" ? "active" : ""}>
                  {t("Native")}
                </li>
              </ul>
            </div>
          )}

          {profile.italianlevel && (
            <div className="language">
              <strong>{t("italian")}</strong>
              <ul>
                <li
                  className={profile.italianlevel == "Beginner" ? "active" : ""}
                >
                  {t("Beginner")}
                </li>
                <li
                  className={
                    profile.italianlevel == "Intermediate" ? "active" : ""
                  }
                >
                  {t("Intermediate")}
                </li>
                <li
                  className={profile.italianlevel == "Fluent" ? "active" : ""}
                >
                  {t("Fluent")}
                </li>
                <li
                  className={profile.italianlevel == "Native" ? "active" : ""}
                >
                  {t("Native")}
                </li>
              </ul>
            </div>
          )}
          {profile.germanlevel && (
            <div className="language">
              <strong>{t("german")}</strong>
              <ul>
                <li
                  className={profile.germanlevel == "Beginner" ? "active" : ""}
                >
                  {t("Beginner")}
                </li>
                <li
                  className={
                    profile.germanlevel == "Intermediate" ? "active" : ""
                  }
                >
                  {t("Intermediate")}
                </li>
                <li className={profile.germanlevel == "Fluent" ? "active" : ""}>
                  {t("Fluent")}
                </li>
                <li className={profile.germanlevel == "Native" ? "active" : ""}>
                  {t("Native")}
                </li>
              </ul>
            </div>
          )}
          {profile.spanishlevel && (
            <div className="language">
              <strong>{t("spanish")}</strong>
              <ul>
                <li
                  className={profile.spanishlevel == "Beginner" ? "active" : ""}
                >
                  {t("Beginner")}
                </li>
                <li
                  className={
                    profile.spanishlevel == "Intermediate" ? "active" : ""
                  }
                >
                  {t("Intermediate")}
                </li>
                <li
                  className={profile.spanishlevel == "Fluent" ? "active" : ""}
                >
                  {t("Fluent")}
                </li>
                <li
                  className={profile.spanishlevel == "Native" ? "active" : ""}
                >
                  {t("Native")}
                </li>
              </ul>
            </div>
          )}

          {profile.chineselevel && (
            <div className="language">
              <strong>{t("chinese")}</strong>
              <ul>
                <li
                  className={profile.chineselevel == "Beginner" ? "active" : ""}
                >
                  {t("Beginner")}
                </li>
                <li
                  className={
                    profile.chineselevel == "Intermediate" ? "active" : ""
                  }
                >
                  {t("Intermediate")}
                </li>
                <li
                  className={profile.chineselevel == "Fluent" ? "active" : ""}
                >
                  {t("Fluent")}
                </li>
                <li
                  className={profile.chineselevel == "Native" ? "active" : ""}
                >
                  {t("Native")}
                </li>
              </ul>
            </div>
          )}

          {profile.otherlevel && (
            <div className="language">
              <strong>
                {t("Other language")} :
                {profile.otherlangname ? profile.otherlangname : ""}
              </strong>
              <ul>
                <li
                  className={profile.otherlevel == "Beginner" ? "active" : ""}
                >
                  {t("Beginner")}
                </li>
                <li
                  className={
                    profile.otherlevel == "Intermediate" ? "active" : ""
                  }
                >
                  {t("Intermediate")}
                </li>
                <li className={profile.otherlevel == "Fluent" ? "active" : ""}>
                  {t("Fluent")}
                </li>
                <li className={profile.otherlevel == "Native" ? "active" : ""}>
                  {t("Native")}
                </li>
              </ul>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
