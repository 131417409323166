import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { api } from "../../urls";
import { DATA_COUNTRY } from "../../utils/data";

export function ProviderPaymentPage() {
  const { t } = useTranslation();

  const [membership] = useState(
    JSON.parse(localStorage.getItem("membership")) != null
      ? JSON.parse(localStorage.getItem("membership"))
      : ""
  );
  const [isCheck, setIsCheck] = useState(true);
  const [generalCompletePrice, setGeneralCompetePrice] = useState("");
  const [secondaryCompletePrice, setSecondaryCompletePrice] = useState("");
  const [payment, setPayment] = useState(false);
  const [chooseCountry, setChooseCountry] = useState();

  const [plan] = useState(membership.plan);
  const [month] = useState(membership.month);

  const updatemembership = () => {
    const myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );
    myHeaders.append("Content-Type", "application/json");
    const raw = JSON.stringify({
      price: generalCompletePrice,
      name: plan,
      month: month,
      iep: 1,
    });
    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(api + "/api/v1/savemembershipplan", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log(result);
        alert("Successful");
      })
      .catch((error) => console.log("error", error));
  };
  const profile_data = () => {
    const myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(api + "/api/v1/details", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log(result);
      })
      .catch((error) => console.log("error", error));
  };
  const countprice = () => {
    let planPrice =
      plan == "Hummingbird"
        ? 0
        : plan == "Swan"
        ? 11.9
        : plan == "Flamingo"
        ? 17
        : "";
    let monthPrice = planPrice * month;
    setGeneralCompetePrice(monthPrice);
  };
  const countprice2 = () => {
    let planPrice =
      plan == "Hummingbird"
        ? 0
        : plan == "Swan"
        ? 14
        : plan == "Flamingo"
        ? 20
        : "";
    let monthPrice = planPrice * month;
    setSecondaryCompletePrice(monthPrice);
  };
  useEffect(() => {
    console.log();
    if (isCheck) {
      countprice();
      countprice2();
      profile_data();
      setIsCheck(false);
    }
  }, [membership]);
  return (
    <div>
      <div>
        <div class="selected_plan">
          <div class="container">
            <div class="selected_page_content">
              <h3>
                {t("You Selected:")} <strong>{plan}</strong>
              </h3>
              <div class="change_plan_btn">
                <button
                  onClick={(e) => {
                    window.scrollTo({ top: 0 });
                  }}
                >
                  {t("Change Plan")}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="total_amnt">
          <div class="container">
            <div class="total_amnt_content">
              <h3>
                {t("Total Amount To Pay:")} <s>${secondaryCompletePrice}</s>
                <span>${generalCompletePrice}</span>
              </h3>
              <h4>
                {t("current-discount-offers")}
                {plan == "Swan" ? 15 : plan == "Flamingo" ? 20 : 100}%
                {t("now.")}
              </h4>
              <ul>
                <li>
                  {t(
                    "subscription-automatically-date"
                  )}
                </li>
                <li>
                  {t("local-taxes-currency-conversions")}
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="container">
          <div class="payment_details">
            <h3>{t("payment-details")}</h3>
            <div class="payment_cards">
              <button>
                <img src="img/mastercard.png" alt="" />
              </button>
              <button>
                <img src="img/maestro.png" alt="" />
              </button>
              <button>
                <img src="img/american_exp.png" alt="" />
              </button>
              <button>
                <img src="img/paypal.png" alt="" />
              </button>
            </div>
          </div>
          <div class="payment_form">
            <form action="">
              <div class="row">
                <div class="col">
                  <div class="form-group">
                    <label class="form-label" for="name">
                      {t("name-card")}
                    </label>
                    <input type="text" id="name" placeholder={t("text-type")} />
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <label class="form-label" for="card">
                      {t("card-number")}
                    </label>
                    <input type="tel" id="card" placeholder={t("text-type")} />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <div class="form-group">
                    <label class="form-label" for="address">
                      {t("bild-address")}
                    </label>
                    <input
                      type="text"
                      id="address"
                      placeholder={t("text-type")}
                    />
                  </div>
                </div>
                <div class="col">
                  <div class="row exp_Sec">
                    <div class="col exp_sec">
                      <div class="form-group">
                        <label class="form-label" for="date">
                          {t("expiration-date")}
                        </label>
                        <input
                          type="date"
                          id="date"
                          placeholder={t("text-type")}
                        />
                      </div>
                    </div>
                    <div class="col exp_sec">
                      <div class="form-group">
                        <label class="form-label" for="security">
                          {t("Security #")}
                        </label>
                        <input
                          type="tel"
                          id="security"
                          placeholder={t("text-type")}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <div class="row">
                    <div class="col">
                      <div class="form-group">
                        <label class="form-label" for="country">
                          {t("Country")}
                        </label>
                        <select
                          name=""
                          id=""
                          onChange={(e) => setChooseCountry(e.target.value)}
                        >
                          <option selected>{t("choose-list-up")}</option>
                          {DATA_COUNTRY.data.map((e) => {
                            return (
                              <option value={e.country}>{e.country}</option>
                            );
                          })}
                        </select>
                      </div>
                    </div>

                    <div class="col">
                      {chooseCountry != "Serbia" ? (
                        <div class="form-group">
                          <label class="form-label" for="state">
                            {t("State")}
                          </label>
                          <input
                            type="email"
                            id="email"
                            placeholder={t("text-type")}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <label class="form-label" for="email">
                      {t("email-address")}
                    </label>
                    <input
                      type="email"
                      id="email"
                      placeholder={t("text-type")}
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <div class="row city_zip">
                    <div class="col city_zip">
                      <div class="form-group">
                        <label class="form-label" for="city">
                          {t("text-city")}
                        </label>
                        <input
                          type="text"
                          id="city"
                          placeholder={t("text-type")}
                        />
                      </div>
                    </div>
                    <div class="col city_zip">
                      <div class="form-group">
                        <label class="form-label" for="zip">
                          {t("Zip Code")}
                        </label>
                        <input
                          type="tel"
                          id="zip"
                          placeholder={t("text-type")}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <div class="radio">
                      <input
                        type="checkbox"
                        checked={payment}
                        onClick={(e) => setPayment()}
                      />
                      <label>{t("save-card-future-payments")}</label>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div class="promotion">
            <div class="promotion_code">
              <label for="">
                {t("add-promotion-code")}
              </label>
              <input type="text" placeholder="ngsklf4875d" />
              <button>{t("apply-code")}</button>
            </div>
          </div>
          <div class="pay_aggrement">
            <input type="checkbox" />
            <label for="">
              {t("agree-pay")} ${generalCompletePrice / month} {t("monthly-for")}
              {month > 0 ? month : ""} {month == 1 ? "month" : "months"}
              {t("subscription.")}
            </label>
          </div>
          <div class="form_submit_button">
            <div class="button">
              <button class="can" onClick={(e) => {}}>
                {t("text-back")}
              </button>
              <button class="sub" onClick={(e) => updatemembership()}>
                {t("text-submit")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
