export const ArrowRightIcon = () => {
  return (
    <svg
      width="10"
      height="16"
      viewBox="0 0 10 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.43506 8.00421L0.628351 13.8109C0.290141 14.1261 0.150922 14.6007 0.265309 15.0486C0.379697 15.4965 0.729447 15.8463 1.17735 15.9607C1.62526 16.075 2.09988 15.9358 2.41503 15.5976L10 8.01264L8.05319 6.06583L2.38975 0.402388C2.0746 0.0641773 1.59998 -0.075042 1.15207 0.0393456C0.704162 0.153733 0.354412 0.503484 0.240024 0.95139C0.125637 1.3993 0.264857 1.87392 0.603067 2.18907L6.43506 8.00421Z"
        fill="#7F7F7F"
      />
    </svg>
  );
};
