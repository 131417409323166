import React from "react";
import { useTranslation } from "react-i18next";

import { useSearchDetailsStore } from "../../store/useSearchDetailsStore";
import { ProfessionChange } from "../../components/common/ProfessionChange";

import { ArrowRightIcon } from "../../assets/image/ArrowRightIcon";
import { StarIcon } from "../../assets/image/StarIcon";
import { WhiteStarIcon } from "../../assets/image/WhiteStarIcon";
import { PhoneIcon } from "../../assets/image/PhoneIcon";
import { ServiceIcon } from "../../assets/image/ServiceIcon";
import { ConfirmIcon } from "../../assets/image/ConfirmIcon";

export const SearchProfileDetail = () => {
  const { t } = useTranslation();

  const { profile } = useSearchDetailsStore();

  return (
    <div className="mobile_profile_box">
      <a
        href={
          localStorage.getItem("user_type") == "parents"
            ? "profile-parents/" + profile.id
            : "/profile-provider/" + profile.id
        }
      >
        <div className="profile_pic">
          <img
            src={
              "https://admin.stage.mysenscare.com/assets/images/users/" +
              profile.file_path
            }
            width="50"
            height="50"
            alt=""
          />
        </div>
      </a>
      <div className="mobile_profile_box_content1">
        <div className="profile_detail">
          <div className="profile_box_social">
            <a
              href={
                localStorage.getItem("user_type") == "parents"
                  ? "profile-parents/" + profile.id
                  : "/profile-provider/" + profile.id
              }
            >
              <div className="name_iimg">
                <p>
                  {profile.first_name + " " + profile.last_name} (
                  {profile.dob != undefined
                    ? new Date().getFullYear() -
                      parseInt(
                        profile.dob
                          .substr(profile.dob.lastIndexOf("\\") + 1)
                          .split("-")[0]
                      )
                    : ""}
                  )
                </p>
                <p>
                  {profile.reviewAvg >= 0 ? (
                    <>
                      {[...Array(profile.reviewAvg)].map((star, index) => {
                        index += 1;
                        return <StarIcon />;
                      })}
                      {[...Array(5 - profile.reviewAvg)].map((star, index) => {
                        index += 1;
                        return <WhiteStarIcon />;
                      })}
                    </>
                  ) : (
                    ""
                  )}
                  <span>({profile.reviewcount})</span>
                </p>
              </div>
            </a>
            <div className="profile_box_social_sec1">
              {profile.phoneVerifiedStatus == 1 ? (
                <img
                  src={window.location.origin + "/images/nany_phone.svg"}
                  alt=""
                />
              ) : (
                <PhoneIcon />
              )}
              <img
                src={window.location.origin + "/images/nany_msg.svg"}
                alt=""
              />
              {profile.facebookverify != null ||
              profile.linkdinverify != null ? (
                <img
                  src={window.location.origin + "/images/nany_cont.svg"}
                  alt=""
                />
              ) : (
                <ServiceIcon />
              )}
              {localStorage.getItem("user_type") == "parents" ? (
                ""
              ) : profile.docsStatus == "Yes" ? (
                <img src={window.location.origin + "/images/ok.svg"} alt="" />
              ) : (
                <ConfirmIcon />
              )}
            </div>

            {localStorage.getItem("user_type") == "parents" ? (
              <span className="addjob">
                <ProfessionChange />
              </span>
            ) : (
              <div className="profile_box_social_sec2">
                {profile.service_type &&
                profile.service_type.tab1 == "Nanny" ? (
                  <img
                    src={window.location.origin + "/images/nany_pur.svg"}
                    alt=""
                  />
                ) : (
                  ""
                )}
                {profile.service_type && profile.service_type.tab2 ? (
                  <img
                    src={
                      window.location.origin + "/images/special_education.svg"
                    }
                    alt=""
                  />
                ) : (
                  ""
                )}
                {profile.service_type && profile.service_type.tab3 ? (
                  <img
                    src={window.location.origin + "/images/professional.svg"}
                    alt=""
                  />
                ) : (
                  ""
                )}
                {profile.service_type && profile.service_type.tab4 ? (
                  <img
                    src={window.location.origin + "/images/tutorform.svg"}
                    alt=""
                  />
                ) : (
                  ""
                )}
              </div>
            )}
          </div>
        </div>
        <div className="mobile_profile_box_content2">
          <div className="profile_functions">
            <div className="funk">
              <h5>
                {new Date().getFullYear() -
                  new Date(profile.created_at).getFullYear() >
                0
                  ? new Date().getFullYear() -
                    new Date(profile.created_at).getFullYear() +
                    t(" YRS")
                  : 0 + t(" YRS")}
              </h5>
              <h6>{t("Member")}</h6>
            </div>
            <div className="vi"></div>
            {localStorage.getItem("user_type") == "parents" ? (
              <div className="funk">
                <h5>{profile.jobs}</h5>
                <h6>{t("Job posts")}</h6>
              </div>
            ) : (
              <div className="funk">
                <h5>{profile.jobApplicationcount}</h5>
                <h6>{t("text-applications")}</h6>
              </div>
            )}
            <div className="vi"></div>
            <div className="funk">
              <h5>{profile.hiringcount}</h5>
              <h6>{t("Hirings")}</h6>
            </div>
          </div>
          <button
            onClick={(e) => {
              window.scrollTo({ top: 0, behavior: "smooth" });
            }}
            style={{ float: "right" }}
          >
            <a href={"/search-providers/Profile"}>
              <ArrowRightIcon />
            </a>
          </button>
        </div>
      </div>
    </div>
  );
};
