import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { fetchAboutUs, fetchAllTeam } from "../../api/aboutServiceApi";
import { Footer } from "../../components/common/Footer";
import { Header } from "../../components/common/Header";
import { DATA_TEAM } from "../../utils/data";
import { TeamSlider } from "./TeamSlider";

import styles from "./AboutPage.module.css";


export function AboutPage() {
  const { t, i18n } = useTranslation();
  const language = i18n.language;

  const [isAbout, setIsAbout] = useState(true);
  const [isTeam, setIsTeam] = useState(true);
  const [aboutData, setAboutData] = useState({});
  const [teamData, setTeamData] = useState([]);
  const [showEmail, setShowEmail] = useState(null);

  const handleCloseEmail = () => {
    setShowEmail("");
  };
  const handleShowEmail = (e) => {
    setShowEmail(e);
  };

  useEffect(() => {
    const loadData = async () => {
      try {
        if (isAbout) {
          const aboutData = await fetchAboutUs();
          setAboutData(aboutData);
          setIsAbout(false);
        }

        if (isTeam) {
          const teamData = await fetchAllTeam();
          setTeamData(teamData);
          setIsTeam(false);
        }
      } catch (error) {
        console.error("Error loading data:", error);
      }
    };

    loadData();
  }, [isAbout, isTeam]);

  return (
    <>
      <Header />
      <div className={styles.container_fluid}>
        <div className={styles.container}>
          <div className={styles.about}>
            <div className={`${styles.content} ${styles.border}`}>
              <h2>{t("title", { defaultValue: aboutData.title })}</h2>
              <div
                dangerouslySetInnerHTML={{
                  __html: t("description", {
                    defaultValue: aboutData.description,
                  }),
                }}
              />
            </div>
            <h2>{t("meet-team")}</h2>

            <TeamSlider
              teamData={language === "en" ? teamData : DATA_TEAM}
              showEmail={showEmail}
              handleShowEmail={handleShowEmail}
              handleCloseEmail={handleCloseEmail}
            />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
