export const ThingIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM9.00147 17.9287C5.05147 17.4387 2.00146 14.0787 2.00146 9.99874C2.00146 9.37874 2.08146 8.78874 2.21146 8.20874L7.00147 12.9987V13.9987C7.00147 15.0987 7.90147 15.9987 9.00147 15.9987V17.9287ZM13.9979 14.0006C14.8979 14.0006 15.6379 14.5806 15.8979 15.3906C17.1979 13.9706 17.9979 12.0806 17.9979 10.0006C17.9979 6.65058 15.9279 3.78058 12.9979 2.59058V3.00058C12.9979 4.10058 12.0979 5.00058 10.9979 5.00058H8.99792V7.00058C8.99792 7.55058 8.54792 8.00057 7.99792 8.00057H5.99792V10.0006H11.9979C12.5479 10.0006 12.9979 10.4506 12.9979 11.0006V14.0006H13.9979Z"
        fill="#7D2B8B"
      />
    </svg>
  );
};
