import { EMAIL_REGEX } from "./variables";

export const getReceivedLanguage = (received, language) => {
  const languageMap = {
    Parents: { en: "Parents", sr: "Roditelji" },
    Provider: { en: "Provider", sr: "Kandidat" },
  };
  return languageMap[received]?.[language] || received;
};

export const saveToLocalStorage = (key, value) => {
  localStorage.setItem(key, JSON.stringify(value));
};

export const validateField = (name, value, t) => {
  let error = "";

  switch (name) {
    case "email":
      error = EMAIL_REGEX.test(value) ? "" : t("email-valid");
      break;
    case "firstName":
      error = value.length < 2 ? t("min-required") : "";
      break;
    case "lastName":
      error = value.length < 2 ? t("min-required") : "";
      break;
    case "phone":
      error = value.length < 8 ? t("required") : "";
      break;
    case "message":
      if (value.length < 2) error = t("required");
      if (value.length > 300) error = t("max-required");
      break;
    default:
      error = value.length < 2 ? t("required") : "";
      break;
  }

  return error;
};

export const validationLoginField = (
  name,
  detailParents,
  children,
  selectCat,
  childrenAge,
  nanyRate,
  generalRate,
  secondaryRate,
  qualifications,
  tutorRate,
  oralSpeak,
  habit
) => {
  const errorField = {};

  const validations = {
    about: () => (detailParents.about === "" ? "required" : ""),
    video: () => (detailParents.video !== "" ? "" : "required"),
    file_path: () => (detailParents.file_path !== "" ? "" : "required"),
    childneedcareno: () =>
      children === 0 || children === null ? "required" : "",
    childage: () => (selectCat[0] ? "" : "required"),
    childsocialneed: () =>
      detailParents.childsocialneed === "" ? "required" : "",
    yourkidallapplicable: () => (childrenAge[0] ? "" : "required"),
    childneediep: () => (detailParents.childneediep === "" ? "required" : ""),
    childmedicalcondition: () =>
      detailParents.childmedicalcondition === "" ? "required" : "",
    childmedicaldescription: () =>
      detailParents.childmedicaldescription === "" ? "required" : "",
    childanyallergies: () =>
      detailParents.childanyallergies === "" ? "required" : "",
    childallergiesdescription: () =>
      detailParents.childallergiesdescription === "" ? "required" : "",
    liveinnany: () => (detailParents.liveinnany === "" ? "required" : ""),
    nanyperhrrate: () => (nanyRate.max === 0 ? "required" : ""),
    seterhrrate: () => (generalRate.max === 0 ? "required" : ""),
    seperhrrate: () => (secondaryRate.max === 0 ? "required" : ""),
    tutorliketoteach: () =>
      qualifications.English ||
      qualifications.Serbian ||
      qualifications.Mathematics ||
      qualifications.Physics ||
      qualifications.Chemistry ||
      qualifications.Other
        ? ""
        : "required",
    tutorintrestedonlinecls: () =>
      detailParents.tutorintrestedonlinecls === "" ? "required" : "",
    tutorperhrrate: () => (tutorRate.max === 0 ? "required" : ""),
    tutorexp: () => (detailParents.tutorexp === "" ? "required" : ""),
    tutorstartdate: () =>
      detailParents.tutorstartdate === "" ? "required" : "",
    tutorintrestedin: () =>
      detailParents.tutorintrestedin === "" ? "required" : "",
    preferredageofprovider: () =>
      detailParents.preferredageofprovider === "" ? "required" : "",
    fulltime: () => (detailParents.fulltime === "" ? "required" : ""),
    beforeschool: () => (detailParents.beforeschool === "" ? "required" : ""),
    afterschool: () => (detailParents.afterschool === "" ? "required" : ""),
    weekends: () => (detailParents.weekends === "" ? "required" : ""),
    overnight: () => (detailParents.overnight === "" ? "required" : ""),
    candidatespeaks: () =>
      oralSpeak.English !== "" ||
      oralSpeak.Spanish !== "" ||
      oralSpeak.French !== "" ||
      oralSpeak.Chinese !== "" ||
      oralSpeak.German !== "" ||
      oralSpeak.Italian !== "" ||
      oralSpeak.Other !== ""
        ? ""
        : "required",
    occasionaltraveling: () => (habit.family !== "" ? "" : "required"),
    cookingforkids: () => (habit.kids === "" ? "required" : ""),
    lighthousework: () => (habit.housework === "" ? "required" : ""),
    preferredcountry: () =>
      detailParents.preferredcountry === "" ? "required" : "",
    childtransportation: () => (habit.licence === "" ? "required" : ""),
    workingabroad: () => (detailParents.workingabroad === "" ? "required" : ""),
    preferredcity: () => (detailParents.preferredcity === "" ? "required" : ""),
    phone: () => (detailParents.phone === "" ? "required" : ""),
    jobs: () => (detailParents.jobs === "" ? "required" : ""),
    jobs_description: () =>
      detailParents.jobs_description === "" ? "required" : "",
    jobs2: () => (detailParents.jobs2 === "" ? "required" : ""),
    jobs_description2: () =>
      detailParents.jobs_description2 === "" ? "required" : "",
  };

  if (validations[name]) {
    errorField[name] = validations[name]();
  }

  return errorField;
};
