import { create } from "zustand";

const initialData = {
  about: "",
  video: "",
  file_path: "",
  childneedcareno: "",
  childage: "",
  childsocialneed: "",
  yourkidallapplicable: "",
  childneediep: "",
  childmedicalcondition: "",
  childmedicaldescription: "",
  childanyallergies: "",
  childallergiesdescription: "",
  liveinnany: "",
  nanyperhrrate: "",
  tutorliketoteach: "",
  tutorintrestedonlinecls: "",
  tutorperhrrate: "",
  tutorexp: "",
  tutorstartdate: "",
  tutorintrestedin: "",
  preferredageofprovider: "",
  countrycode: "",
  fulltime: {},
  beforeschool: {},
  afterschool: {},
  weekends: {},
  overnight: {},
  candidatespeaks: "",
  occasionaltraveling: "",
  cookingforkids: "",
  lighthousework: "",
  preferredcountry: "",
  childtransportation: "",
  workingabroad: "",
  preferredcity: "",
  facebookverify: "",
  twitterverify: "",
  linkdinverify: "",
  instaverify: "",
  phone: "",
  jobs: "",
  jobs_description: "",
  jobs2: "",
  jobs_description2: "",
  seperhrrate: "",
  seterhrrate: "",
  calanderlastupdate: "",
};

export const useDetailParentsStore = create((set) => {
  let storage = initialData;

  try {
    const storedData = localStorage.getItem("parentsDetail");

    if (storedData) {
      storage = JSON.parse(storedData);
    }
  } catch (error) {
    console.log("Error parsing local storage data:", error.message);
  }

  return {
    detailParents: storage,

    setDetailParents: (newDetailParents) =>
        set((state) => {
          const updatedDetailParents = {
            ...state.detailParents,
            ...newDetailParents,
            file_path: newDetailParents.file_path || state.detailParents.file_path,
            video: newDetailParents.video || state.detailParents.video,
            fulltime: newDetailParents.fulltime || state.detailParents.fulltime,
          };

          localStorage.setItem(
              "parentsDetail",
              JSON.stringify({
                ...updatedDetailParents,

                file_path: updatedDetailParents.file_path
                    ? { name: updatedDetailParents.file_path.name }
                    : null,
                video: updatedDetailParents.video
                    ? { name: updatedDetailParents.video.name }
                    : null,
              })
          );

          return { detailParents: updatedDetailParents };
        }),
  };
});
