import React from "react";

export const AddIcon = () => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="15" cy="15" r="14.5" stroke="#A98D4B" />
      <rect
        x="14"
        y="7"
        width="2.28571"
        height="16"
        rx="1.14286"
        fill="#A98D4B"
      />
      <rect
        x="7"
        y="16"
        width="2.28571"
        height="16"
        rx="1.14286"
        transform="rotate(-90 7 16)"
        fill="#A98D4B"
      />
    </svg>
  );
};
