import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export const SafetySecondTab = ({ selectTab }) => {
  const { t } = useTranslation();

  return (
    <div className={"tab1 " + (selectTab == "tab2" ? "active" : "")}>
      <div className="provider_tabing">
        <div className="container">
          <div className="contact safety_tips parents">
            <h3>{t("Safety tips at glance")}</h3>
            <ul>
              <li>
                <img src="./images/verify.svg" />
                <h4>
                  {t("Check if the")}
                  <br /> {t("profile is")} <span>{t(" verified")}</span>
                </h4>
              </li>
              <li>
                <img src="./images/sreview.svg" />
                <h4>
                  {t("Check")} <br />
                  <span>{t("reviews")}</span>
                </h4>
              </li>
              <li>
                <img src="./images/sinterview.svg" />
                <h4>
                  {t("Schedule an online")} <span>{t("interview")}</span>
                  {t("or a meeting in a public space")}
                </h4>
              </li>
              <li>
                <img src="./images/finger.svg" />
                <h4>
                  {t("Request the")} <br />
                  <span>{t("background-check")}</span>
                </h4>
              </li>
              <li>
                <img src="./images/search_bg.svg" />
                <h4>
                  {t("Check out")} <br />
                  <span>{t("references")}</span>
                </h4>
              </li>
              <li>
                <img src="./images/probation.svg" />
                <h4>
                  {t("Consider")} <br />
                  <span>{t("probation period")}</span>
                </h4>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="more_tips contact">
          <h3>{t("See here for more details on Safety tips")}</h3>
          <ul>
            <li>
              <img src="./images/verify_pur.svg" />
              <div className="morecontent">
                <h5>
                  {t("1st-step")}
                  <span>
                    <Link
                      to="/faq"
                      onClick={(e) => {
                        window.location.hash = "parents";
                        localStorage.setItem(
                          "faq",
                          "professional-child-service-provider"
                        );
                      }}
                    >
                      {t("Selecting the right candidate")}
                    </Link>
                  </span>
                </h5>
                <p>
                  {t("The first important step in")}
                  <strong>{t("selecting the right candidate")}</strong>
                  {t("selectingCandidate")}
                  <Link
                    to="/faq"
                    onClick={(e) => {
                      window.location.hash = "parents";
                      localStorage.setItem(
                        "faq",
                        t(
                          "professional-child-service-provider"
                        )
                      );
                    }}
                  >
                    {t("invite a candidate for an interview")}
                  </Link>
                  {t("onceYouHave")}
                </p>
              </div>
            </li>
            <li>
              <img src="./images/rating_pur.svg" />
              <div className="morecontent">
                <h5>
                  {t("2st-step")} <span>{t("Check reviews")}</span>
                </h5>
                <p>{t("CheckCandidate")}</p>
              </div>
            </li>
            <li>
              <img src="./images/finger_pur.svg" />
              <div className="morecontent">
                <h5>
                  {t("3rd-step")} <span>{t("Schedule an interview")}</span>
                </h5>
                <p>
                  
                  {t("Sledece")}
                  <Link
                    to="/faq"
                    onClick={(e) => {
                      window.location.hash = "parents";
                      localStorage.setItem(
                        "faq",
                        t("request-background-check")
                      );
                    }}
                  >
                    {t("text-here")}
                  </Link>
                  {t("to learn how to request a background check and")}
                  <Link
                    to="/faq"
                    onClick={(e) => {
                      window.location.hash = "parents";
                      localStorage.setItem(
                        "faq",
                        t(
                          "request-cv-platform"
                        )
                      );
                    }}
                  >
                    {t("text-here")}
                  </Link>
                  {t("requestAdditional")}
                </p>
              </div>
            </li>
            <li>
              <img src="./images/interview_pur.svg" />
              <div className="morecontent">
                <h5>
                  {t("4th-step")}
                  <span>{t("Get to know an employer better")}</span>
                </h5>
                <p>{t("duringTheInterview")}</p>
              </div>
            </li>

            <li className="inner">
              <img src="./images/int_pup.svg" />
              <div className="morecontent">
                <h3>{t("General Interview questions")}</h3>
                <p>
                  {t("something-about-yourself")}
                  <br />
                  {t("worked-with-children")}
                  <br />
                  {t(
                    "examples-work-duties"
                  )}
                  <br />
                  {t("ellaborate")}
                  <br />
                  {t("describe-philosophy")}
                </p>
              </div>
            </li>
            <li className="inner">
              <img src="./images/int_pup.svg" />
              <div className="morecontent">
                <h3>{t("Specific Interview questions for families")}</h3>
                <p>
                  {t("child-refuses-eat")}
                  <br />
                  {t("tantrum")}
                  <br />
                  {t("support-child-with-special")}
                  <br />
                  {t("willing-travel-abroad")}
                  <br />
                  {t("provide-transportation")}
                  <br />
                  {t("lightHouse")}
                  <br />
                  {t("questions-about-family")}
                  <br />
                  {t("substance")}
                  <br />
                  {t("medical")}
                </p>
              </div>
            </li>
          </ul>
          <ul>
            <li>
              <img src="./images/interview_pur.svg" />
              <div className="morecontent">
                <h5>
                  {t("5th-step")} <span>{t("Check references")}</span>
                </h5>
                <p>{t("oneMoreStep")}</p>
              </div>
            </li>

            <li className="inner out">
              <img src="./images/flag_bro.svg" />
              <div className="morecontent">
                <p>
                  <strong>{t("Category I:")}</strong> {t("referencesWereHappy")}
                </p>
              </div>
            </li>
            <li className="inner out">
              <img src="./images/flag_pur.svg" />
              <div className="morecontent">
                <p>
                  <strong>{t("Category II:")}</strong>
                  {t("referencesWereNonResponsive")}
                </p>
              </div>
            </li>
            <li>
              <img src="./images/probation_pur.svg" />
              <div className="morecontent">
                <h5>
                  {t("6th step:")} <span>{t("Probation period")}</span>
                </h5>
                <p>
                  {t("official")}
                  <strong>{t("probation period second")}</strong>
                  {t("probationLimited")}
                </p>
                <img src="./images/thank_banner.png" />
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};
