import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";
import engUS from "./eng-US/translation.json";
import srSRB from "./srb-SRB/translation.json";

export const resources = {
  en: {
    translation: engUS,
  },
  sr: {
    translation: srSRB,
  },
};

export const detectUserLanguage = async () => {
  try {
    const response = await fetch("https://ipapi.co/json");
    const data = await response.json();
    const country = data.country_code;
    const language = country === "RS" ? "sr" : "en";

    localStorage.setItem("language", language);

    return language;
  } catch (error) {
    console.error("Error detecting user language:", error);
    return localStorage.getItem("language") || "en";
  }
};

const initializeI18n = async () => {
  let userLanguage = localStorage.getItem("language");

  if (!userLanguage) {
    userLanguage = await detectUserLanguage();
  }

  i18n
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
      resources,
      lng: userLanguage,
      fallbackLng: "en",
      debug: true,
      interpolation: {
        escapeValue: false,
      },
      detection: {
        order: ['localStorage', 'navigator'],
        caches: ['localStorage'], 
      },
    });
};

initializeI18n();

export default i18n;
