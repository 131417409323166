import React, { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { fetchSendReview } from "../../../api/reviewApi";

export function SendReview(props) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [title, setTitle] = useState("");
  const [review, setReview] = useState("");
  const [rateHover, setRateHover] = useState(0);
  const [rating, setRating] = useState(0);
  const [rateStar, setRateStar] = useState(0);
  const [countCondition, setCountCondition] = useState(0);

  const [errorFields, setErrorFields] = useState({
    rating: "",
    title: "",
    review: "",
  });

  useEffect(() => {
    if (!localStorage.getItem("token") && !localStorage.getItem("id")) {
      navigate("/login");
    }
    if (localStorage.getItem("id") == props.slugdata) {
      props.setrequest(false);
    }
  }, [rateStar, errorFields]);

  const handleSubmitReview = async () => {
    if (title && review && rateStar > 0) {
      setCountCondition(2);
      const token = localStorage.getItem("token");
      const reviewData = {
        receiver_userid: props.slugdata,
        review_title: title,
        rating: rateStar,
        message: review,
      };

      try {
        const result = await fetchSendReview(token, reviewData);
        if (result.success) {
          setCountCondition(1);
          props.setcheckdata("2");
        } else {
          props.setrequest(false);
        }
      } catch (error) {
        console.error("Error submitting review:", error);
      }
    } else {
      title == ""
        ? setErrorFields({ ...errorFields, title: "blank" })
        : setTitle(title);
      review == ""
        ? setErrorFields({ ...errorFields, review: "blank" })
        : setReview(review);
      rateStar == 0
        ? setErrorFields({ ...errorFields, rating: "blank" })
        : setRateStar(rateStar);
    }
  };

  return (
    <>
      {countCondition == 0 || countCondition == 2 ? (
        <div className="promocode_content signout invite request reviewpop">
          <h2>{t("Write Review")}</h2>
          <div className="form_groupre">
            <label className={errorFields.rating != "" ? "starred" : ""}>
              {t("Select rating")}
              <span className="star">
                {[...Array(5)].map((star, index) => {
                  index += 1;
                  return (
                    <i
                      type="button"
                      key={index}
                      className={
                        index <= (rateHover || rateStar)
                          ? "on fa-solid fa-star"
                          : "off fa-regular fa-star"
                      }
                      onClick={() => {
                        setErrorFields({ ...errorFields, rating: "" });
                        setRateStar(index);
                      }}
                      onMouseEnter={() => setRateHover(index)}
                      onMouseLeave={() => setRateHover(rating)}
                    ></i>
                  );
                })}
              </span>
            </label>
          </div>
          <div className="form_groupre">
            <label>{t("Review title")}</label>
            <input
              className={errorFields.title != "" ? "bordererror" : ""}
              type="text"
              placeholder={t("text-type")}
              onChange={(e) => {
                setErrorFields({ ...errorFields, title: "" });
                setTitle(e.target.value);
              }}
              maxLength="25"
            />
            <span>
              {t("number-characters")} {25 - title.length}
            </span>
          </div>
          <div className="form_groupre">
            <label>{t("Write Review")}</label>
            <textarea
              className={errorFields.review != "" ? "bordererror" : ""}
              placeholder={t("text-type")}
              onChange={(e) => {
                setErrorFields({ ...errorFields, review: "" });
                setReview(e.target.value);
              }}
              maxLength="300"
            ></textarea>
            <span>
              {t("number-characters")} {300 - review.length}
            </span>
          </div>
          <div class="button text-center ">
            <div class="pull-right">
              <button class="btn" onClick={(e) => props.setrequest(false)}>
                {t("text-cancel")}
              </button>
            </div>
            <div class="pull-right">
              <button
                class="btn confirm"
                onClick={handleSubmitReview}
                style={countCondition == 2 ? { opacity: "0.5" } : {}}
              >
                {t("text-submit")}
              </button>
            </div>
          </div>
        </div>
      ) : (
        <div className="promocode_content signout invite">
          <p>
            <b>
              {t("Thank you for submitting review for")}
              <span style={{ color: "#A98D4B" }}>{props.username}</span>!
            </b>
          </p>
          <p>
            {t(
              "post-part-senscare-family"
            )}
          </p>
          <div class="button text-center ">
            <div class="pull-right">
              <button class="btn" onClick={(e) => props.setrequest(false)}>
                {t("text-close-up")}
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
