import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { api } from "../../../urls";
import { FavoriteProfile } from "../FavoriteProfile";
import { StarIcon } from "../../../assets/image/StarIcon";
import { WhiteStarIcon } from "../../../assets/image/WhiteStarIcon";
import { getProfileData, getProfileDetails } from "../../../api/profileApi";

export function ProfileAll() {
  const { t } = useTranslation();

  const [initialData, setInitialData] = useState([]);
  const [profileData, setProfileData] = useState({});
  const [isCheck, setIsCheck] = useState(true);

  const [list, setList] = useState(10);
  const [resultList, setResultList] = useState(list);

  let pages = 0;
  let data_show = (resultList / list - 1) * list;

  const fetchProfileData = async (profileSearch = "provider") => {
    try {
      const result = await getProfileData(profileSearch);
      setInitialData(result.data.gatData);
    } catch (error) {
      console.error("Error fetching profile data:", error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        await fetchProfileData();
        setIsCheck(false);

        const result = await getProfileDetails();
        setProfileData(result.data);
      } catch (error) {
        console.error("Error", error);
      }

      window.scrollTo({ top: 200, behavior: "smooth" });
    };

    if (isCheck) {
      fetchData();
    }
  }, [isCheck, resultList, list]);

  return (
    <>
      <div class="main-header">
        <h2 className="border">{t("all-profiles")}</h2>
        <div class="mail-header-bar">
          <p>
            {initialData.length} {t("profile")}
          </p>
        </div>
        <div class="right_side_section">
          <div class="looking_for_candidate">
            <div class="interested_fam">
              <div class="interested_fam_boxs">
              {initialData?.map((data, index) => {
                  if (index < resultList && data_show <= index) {
                    return (
                      <div class="interested_fam_box">
                        <div class="image_sec">
                          <img
                            src={
                              data.file_path != null
                                ? api + "/assets/images/users/" + data.file_path
                                : "img/nany_img.png"
                            }
                            alt=""
                          />
                          <div class="heart_sec">
                            <FavoriteProfile
                              id={data.id}
                              heart={fetchProfileData}
                              username={data.username}
                            />
                          </div>
                        </div>
                        <div class="general_sec">
                          <h4>
                            <Link
                              to={
                                data.user_type == "parents"
                                  ? "/profile-parents/" + data.id
                                  : "/profile-provider/" + data.id
                              }
                            >
                              {data.first_name + " " + data.last_name}
                            </Link>
                          </h4>

                          <p style={{ padding: "0" }}>
                            {data.reviewAvg >= 0 ? (
                              <>
                                {[...Array(data.reviewAvg)].map(
                                  (star, index) => {
                                    index += 1;
                                    return <StarIcon />;
                                  }
                                )}
                                {[...Array(5 - data.reviewAvg)].map(
                                  (star, index) => {
                                    index += 1;
                                    return <WhiteStarIcon />;
                                  }
                                )}
                              </>
                            ) : (
                              ""
                            )}
                          </p>
                          <div class="detail_sec">
                            {JSON.parse(data.service_type) != null ? (
                              Object.values(JSON.parse(data.service_type))[0] ==
                              "Nanny" ? (
                                <img
                                  src={
                                    window.location.origin +
                                    "/images/nany_post.svg"
                                  }
                                  alt=""
                                />
                              ) : Object.keys(
                                  JSON.parse(data.service_type)
                                )[0] == "tab2" ? (
                                <img
                                  src={
                                    window.location.origin +
                                    "/images/teacher_post.svg"
                                  }
                                  alt=""
                                />
                              ) : Object.keys(
                                  JSON.parse(data.service_type)
                                )[0] == "tab3" ? (
                                <img
                                  src={
                                    window.location.origin +
                                    "/images/education_post.svg"
                                  }
                                  alt=""
                                />
                              ) : Object.keys(
                                  JSON.parse(data.service_type)
                                )[0] == "tab4" ? (
                                <img
                                  src={
                                    window.location.origin +
                                    "/images/tutor_post.svg"
                                  }
                                  alt=""
                                />
                              ) : (
                                ""
                              )
                            ) : (
                              ""
                            )}
                            <p>
                              {JSON.parse(data.service_type) != null
                                ? t(
                                    Object.values(
                                      JSON.parse(data.service_type)
                                    )[0]
                                  )
                                : ""}
                            </p>
                          </div>
                          <div class="detail_sec">
                            <img
                              src={
                                window.location.origin + "/images/post_pay.svg"
                              }
                              alt=""
                            />
                            <p>
                              {profileData &&
                              profileData.country == "Serbia" ? (
                                <>
                                  {data.nanyperhrrate != null
                                    ? data.nanyperhrrate
                                        .substr(
                                          data.nanyperhrrate.lastIndexOf("\\") +
                                            1
                                        )
                                        .split("-")[0] *
                                        100 +
                                      " - " +
                                      data.nanyperhrrate
                                        .substr(
                                          data.nanyperhrrate.lastIndexOf("\\") +
                                            1
                                        )
                                        .split("-")[1] *
                                        100
                                    : ""}
                                  {t("time-hour")}
                                </>
                              ) : (
                                <>
                                  $
                                  {data.nanyperhrrate != null
                                    ? data.nanyperhrrate
                                    : ""}
                                  {t("time-hour")}
                                </>
                              )}
                            </p>
                          </div>
                          {data.service_type != null &&
                          Object.values(JSON.parse(data.service_type))[1] ? (
                            <>
                              <div class="detail_sec">
                                {JSON.parse(data.service_type) != null ? (
                                  Object.values(
                                    JSON.parse(data.service_type)
                                  )[1] == "Nanny" ? (
                                    <img
                                      src={
                                        window.location.origin +
                                        "/images/nany_post.svg"
                                      }
                                      alt=""
                                    />
                                  ) : Object.keys(
                                      JSON.parse(data.service_type)
                                    )[1] == "tab2" ? (
                                    <img
                                      src={
                                        window.location.origin +
                                        "/images/teacher_post.svg"
                                      }
                                      alt=""
                                    />
                                  ) : Object.keys(
                                      JSON.parse(data.service_type)
                                    )[1] == "tab3" ? (
                                    <img
                                      src={
                                        window.location.origin +
                                        "/images/education_post.svg"
                                      }
                                      alt=""
                                    />
                                  ) : Object.keys(
                                      JSON.parse(data.service_type)
                                    )[1] == "tab4" ? (
                                    <img
                                      src={
                                        window.location.origin +
                                        "/images/tutor_post.svg"
                                      }
                                      alt=""
                                    />
                                  ) : (
                                    ""
                                  )
                                ) : (
                                  ""
                                )}

                                <p>
                                  {JSON.parse(data.service_type) != null
                                    ? t(
                                        Object.values(
                                          JSON.parse(data.service_type)
                                        )[1]
                                      )
                                    : ""}
                                </p>
                              </div>

                              <div class="detail_sec">
                                <img
                                  src={
                                    window.location.origin +
                                    "/images/post_pay.svg"
                                  }
                                  alt=""
                                />
                                <p>
                                  {profileData &&
                                  profileData.country == "Serbia" ? (
                                    <>
                                      {data.tutorperhrrate != null
                                        ? data.tutorperhrrate
                                            .substr(
                                              data.tutorperhrrate.lastIndexOf(
                                                "\\"
                                              ) + 1
                                            )
                                            .split("-")[0] *
                                            100 +
                                          " - " +
                                          data.tutorperhrrate
                                            .substr(
                                              data.tutorperhrrate.lastIndexOf(
                                                "\\"
                                              ) + 1
                                            )
                                            .split("-")[1] *
                                            100
                                        : ""}
                                      {t("time-hour")}
                                    </>
                                  ) : (
                                    <>
                                      $
                                      {data.tutorperhrrate != null
                                        ? data.tutorperhrrate
                                        : ""}
                                      {t("time-hour")}
                                    </>
                                  )}
                                </p>
                              </div>
                            </>
                          ) : (
                            ""
                          )}
                          <div class="detail_sec">
                            <img
                              src={
                                window.location.origin + "/images/post_rang.svg"
                              }
                              alt=""
                            />
                            <p>
                              {data.tutorexp != null
                                ? data.tutorexp
                                : data.nanyyearexp}
                              {t("years-experience")}
                            </p>
                          </div>
                          <div class="detail_sec">
                            <img
                              src={
                                window.location.origin + "/images/post_loc.svg"
                              }
                              alt=""
                            />
                            <p>{data.city + ", " + data.country}</p>
                          </div>
                        </div>
                      </div>
                    );
                  }
                })}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="footer_pagination pagi_post">
        <div class="btn-group">
          {t("result-page")}
          <select
            onChange={(e) => {
              setList(parseInt(e.target.value));
              setResultList(parseInt(e.target.value));
            }}
          >
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="30">30</option>
            <option value="40">40</option>
            <option value="50">50</option>
          </select>
        </div>
        <div className="pagination">
          <span className="curserpage" onClick={(e) => setResultList(list)}>
            {"<<"}
          </span>
          <span
            className="curserpage"
            onClick={(e) =>
              setResultList(
                initialData.length > 0 && resultList > list
                  ? resultList - list
                  : list
              )
            }
          >
            {"<"}
          </span>

          {initialData?.map((data, index) => {
            if (initialData.length > pages) {
              pages = pages + list;
              return (
                <span
                  onClick={(e) => setResultList((index + 1) * list)}
                  className={resultList == pages ? "active" : ""}
                >
                  {index + 1}
                </span>
              );
            }
          })}
          <span
            className="curserpage"
            onClick={(e) =>
              setResultList(
                initialData.length > resultList ? resultList + list : resultList
              )
            }
          >
            {">"}
          </span>
          <span className="curserpage" onClick={(e) => setResultList(pages)}>
            {">>"}
          </span>
        </div>
      </div>
    </>
  );
}
