import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export const Cookie = ({ setCookie, usesCookieText }) => {
  const { t } = useTranslation()

  return (
    <div className="cookie_pop">
      <div className="container">
        <img src={window.location.origin + "/images/cooki_icon.svg"} />

        <p>
          <span dangerouslySetInnerHTML={{ __html: usesCookieText }}></span>
          <Link to="/cookie-policy" target="_blank">
            <u> {t("learn")} </u>
          </Link>
        </p>
        <button
          onClick={(e) => {
            localStorage.setItem("cooki", false);
            setCookie(false);
          }}
        >
          {t("decline")}
        </button>
        <button
          className="accept"
          onClick={(e) => {
            localStorage.setItem("cooki", false);
            setCookie(false);
          }}
        >
          {t("accept")}
        </button>
      </div>
    </div>
  );
};
