import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { HeartIcon } from "../../../assets/image/HeartIcon";
import { saveFavoriteProfile } from "../../../api/profileApi";
import { RemoveModal } from "../RemoveModal";

export function FavoriteProfile({ heart, heart2, id, username }) {
  const navigate = useNavigate();

  const [isRemove, setIsRemove] = useState(false);
  const [removeCheck, setRemoveCheck] = useState(
    heart || heart2 == true ? true : false
  );
  const [isCheck, setIsCheck] = useState(true);

  const handleSubmitProfile = async (id) => {
    try {
      const result = await saveFavoriteProfile(id);
      heart ? heart() : console.log(result);
    } catch (error) {
      console.error("Error saving favorite profile:", error);
    }
  };

  useEffect(() => {
    if (heart2) {
      setRemoveCheck(heart2);
    }
  }, [heart2]);

  return (
    <>
      <input
        type="checkbox"
        onChange={(e) => {
          if (localStorage.getItem("token") && localStorage.getItem("id")) {
            if (e.target.checked == false) {
              setIsRemove(true);
            } else {
              handleSubmitProfile(id);
              setRemoveCheck(true);
            }
          } else {
            navigate("/login");
          }
        }}
        checked={isCheck == false ? isCheck : removeCheck}
      />
      <HeartIcon heartFill={heart} />
      {isRemove && (
        <RemoveModal
          isRemove={isRemove}
          setIsRemove={setIsRemove}
          username={username}
          handleSubmitProfile={handleSubmitProfile}
          id={id}
          setIsCheck={setIsCheck}
          setRemoveCheck={setRemoveCheck}
        />
      )}
    </>
  );
}
