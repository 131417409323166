import React from "react";
import Modal from "react-bootstrap/Modal";

import { SendReview } from "../../../components/common/SendReview";

export const ReviewModal = ({ showReviewModal, setShowReviewModal,
profile, slugdata }) => {
  return (
    <Modal show={showReviewModal} onHide={(e) => setShowReviewModal(false)}>
      <Modal.Body>
        <SendReview
          setrequest={setShowReviewModal}
          slugdata={slugdata.id}
          username={profile.first_name + " " + profile.last_name}
        />
      </Modal.Body>
    </Modal>
  );
};
