import React from "react";
import { useTranslation } from "react-i18next";
import Modal from "react-bootstrap/Modal";

export const ProfileModal = ({
  showModalProfile,
  setShowModalProfile,
  handleSearchData,
  handleSubmitData,
  error,
  shareList,
  setShareSelect,
}) => {
  const { t } = useTranslation();

  return (
    <Modal
      show={showModalProfile}
      onHide={(e) => setShowModalProfile(false)}
      className=""
    >
      <Modal.Body>
        <div className="promocode_content share_profile">
          <h2>{t("text-share-with")}</h2>
          <br />
          <div className="list_prorofile">
            <div className="search_profile">
              <input
                type={"text"}
                placeholder={t("text-type")}
                onChange={(e) => handleSearchData(e.target.value)}
              />
              <button>{t("text-search")}</button>
            </div>
            <ul>
              {shareList.map((data, index) => {
                if (data != null) {
                  return (
                    <li>
                      <img
                        src={
                          "https://admin.stage.mysenscare.com/assets/images/users/" +
                          data.file_path
                        }
                      />
                      <p>{data.first_name + " " + data.last_name}</p>
                      <input
                        type="radio"
                        name="share"
                        onChange={() => {
                          setShareSelect(data.id);
                        }}
                      />
                      <span></span>
                    </li>
                  );
                }
              })}
            </ul>
            <p style={{ color: "red", fontWeight: "600" }}>{error}</p>
            <button onClick={(e) => setShowModalProfile(false)}>
              {t("text-cancel")}
            </button>
            <button onClick={handleSubmitData}>{t("text-share")}</button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
