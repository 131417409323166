import { DEFAULT_API } from "../utils/variables";

export const getReviewData = async (type) => {
  const token = localStorage.getItem("token");
  const myHeaders = new Headers();
  myHeaders.append("Authorization", "Bearer " + token);
  myHeaders.append("Content-Type", "application/json");

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  const endpoint =
    type === 2 ? "/api/v1/givenreviews" : "/api/v1/receivedreviews";

  try {
    const response = await fetch(`${DEFAULT_API}${endpoint}`, requestOptions);
    if (!response.ok) {
      const error = await response.text();
      throw new Error(error || "Something went wrong");
    }
    return response.json();
  } catch (error) {
    console.error("Error fetching review data:", error);
    throw error;
  }
};

export const getReviewNotification = async (revId) => {
  const token = localStorage.getItem("token");
  const myHeaders = new Headers();
  myHeaders.append("Authorization", "Bearer " + token);
  myHeaders.append("Content-Type", "application/json");

  const raw = JSON.stringify({
    rev_id: revId,
  });

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  try {
    const response = await fetch(
      `${DEFAULT_API}/api/v1/reviewnotificationview`,
      requestOptions
    );
    if (!response.ok) {
      const error = await response.text();
      throw new Error(error || "Something went wrong");
    }
    return response.json();
  } catch (error) {
    console.error("Error fetching review data:", error);
    throw error;
  }
};

export const getReviewNotificationParams = async (refine) => {
  const token = localStorage.getItem("token");
  const myHeaders = new Headers();
  myHeaders.append("Authorization", "Bearer " + token);

  const queryParams = refine.threedays
    ? `threedays=${refine.threedays}`
    : refine.withinweek
    ? `withinweek=${refine.withinweek}`
    : refine.twofourday
    ? `twofourday=${refine.twofourday}`
    : refine.withinmonth
    ? `withinmonth=${refine.withinmonth}`
    : "";

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  try {
    const response = await fetch(
      `${DEFAULT_API}/api/v1/reviewnotifications?${queryParams}`,
      requestOptions
    );
    if (!response.ok) {
      const error = await response.text();
      throw new Error(error || "Something went wrong");
    }
    return response.json();
  } catch (error) {
    console.error("Error fetching review notifications:", error);
    throw error;
  }
};

export const deleteReviews = async (deleteList, userType) => {
  const token = localStorage.getItem("token");
  const myHeaders = new Headers();
  myHeaders.append("Authorization", "Bearer " + token);
  myHeaders.append("Content-Type", "application/json");

  const raw = JSON.stringify({
    ids: deleteList,
  });

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  const endpoint =
    userType === "parents"
      ? "/api/v1/deleteparentsview"
      : "/api/v1/deleteproviderreview";

  try {
    const response = await fetch(`${DEFAULT_API}${endpoint}`, requestOptions);
    if (!response.ok) {
      const error = await response.text();
      throw new Error(error || "Something went wrong");
    }
    return response.text();
  } catch (error) {
    console.error("Error deleting reviews:", error);
    throw error;
  }
};

export const postReviewData = async (revId) => {
  const token = localStorage.getItem("token");
  const myHeaders = new Headers();
  myHeaders.append("Authorization", "Bearer " + token);
  myHeaders.append("Content-Type", "application/json");

  const raw = JSON.stringify({
    rev_id: revId,
  });

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  try {
    const response = await fetch(
      `${DEFAULT_API}/api/v1/reviewnotificationview`,
      requestOptions
    );
    if (!response.ok) {
      const error = await response.text();
      throw new Error(error || "Something went wrong");
    }
    return response.json();
  } catch (error) {
    console.error("Error fetching review data:", error);
    throw error;
  }
};

export const fetchSendReview = async (token, reviewData) => {
  const myHeaders = new Headers();
  myHeaders.append("Authorization", "Bearer " + token);
  myHeaders.append("Content-Type", "application/json");

  const raw = JSON.stringify(reviewData);

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  try {
    const response = await fetch(
      `${DEFAULT_API}/api/v1/sendreview`,
      requestOptions
    );
    if (!response.ok) {
      const error = await response.json();
      throw new Error(error.message || "Something went wrong");
    }
    return response.json();
  } catch (error) {
    console.error("Error submitting review:", error);
    throw error;
  }
};
