import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Modal from "react-bootstrap/Modal";

import { DATA_COUNTRY } from "../../utils/data";
import { api } from "../../urls";

import { Footer } from "../../components/common/Footer";
import { Header } from "../../components/common/Header";
import { PayIcon } from "../../assets/image/PayIcon";

export function ParentsMembershipPage() {
  const { t } = useTranslation();

  const [isCheck, setIsCheck] = useState(true);
  const [detail, setDetail] = useState("");
  const [plan, setPlan] = useState("");
  const [iep, setIep] = useState("");
  const [planMobile, setPlanMobile] = useState("");
  const [generalCompletePrice, setGeneralCompletePrice] = useState(0);
  const [secondaryCompletePrice, setSecondaryCompletePrice] = useState(0);
  const [month, setMonth] = useState(0);
  const [step, setStep] = useState(1);
  const [isPaymentType, setIsPaymentType] = useState(false);
  const [isPayment, setIsPayment] = useState(false);
  const [country, setCountry] = useState();
  const [isCheckData, setIsCheckData] = useState({
    month: "",
    plan: "",
  });
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [errorField, setErrorField] = useState({
    month: "",
    plan: "",
  });

  useEffect(() => {
    if (isCheck) {
      fetchDetailData();
      handleMembershipPlan();
      setIsCheck(false);
    }
  }, [detail, month, generalCompletePrice, secondaryCompletePrice, isPayment, country]);

  const handleCloseLoginModal = () => {
    setShowLoginModal(false);
  };

  const handleRedirect = () => {
    window.location.href = "/search-providers";
  };

  const handleGeneralPrice = () => {
    setTimeout(() => {
      let planprice =
        plan == "Hummingbird"
          ? 0
          : plan == "Swan"
          ? 25.5
          : plan == "Flamingo"
          ? detail.country == "Serbia" || "Srbija"
            ? 40
            : 40.8
          : "";
      let iepadd = iep != "" ? 41 * month : 0;
      let monthprice = planprice * month;
      setGeneralCompletePrice(monthprice + iepadd);
    }, 500);
  };

  const handleSecondaryPrice = () => {
    setTimeout(() => {
      let planprice =
        plan == "Hummingbird"
          ? 0
          : plan == "Swan"
          ? 30
          : plan == "Flamingo"
          ? 50
          : "";
      let iepadd = iep != "" ? 41 * month : 0;
      let monthprice = planprice * month;
      setSecondaryCompletePrice(monthprice + iepadd);
    }, 500);
  };

  const fetchDetailData = () => {
    const myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(api + "/api/v1/details", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setDetail(result.data);
      })
      .catch((error) => console.log("error", error));
  };

  const handleMembershipPlan = () => {
    if (localStorage.getItem("user_type") == "parents") {
      const myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Bearer " + localStorage.getItem("token")
      );
      const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      fetch(api + "/api/v1/getmembershipplan", requestOptions)
        .then((response) => response.json())
        .then((result) => {
          if (result.data && result.data.usertype == "parents") {
            setIep(result.data.iep);
            setMonth(parseInt(result.data.month));
            setPlan(result.data.name);
            setIsCheckData({
              month: parseInt(result.data.month),
              plan: result.data.name,
            });
          }
        })
        .catch((error) => console.log("error", error));
    }
  };

  const handleUpdateMembership = () => {
    const myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );
    myHeaders.append("Content-Type", "application/json");
    const raw = JSON.stringify({
      price: generalCompletePrice,
      name: plan,
      month: month,
      iep: iep,
    });
    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(api + "/api/v1/savemembershipplan", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        alert("done");
        if (
          (isCheckData.month != "" && isCheckData.month != month) ||
          (isCheckData.plan != "" && isCheckData.plan != plan)
        ) {
          setShowLoginModal(true);
        }
      })
      .catch((error) => console.log("error", error));
  };
  return (
    <>
      <Header />
      <div class="container-fluid">
        <div
          class="container"
          style={step == 1 ? { display: "block" } : { display: "none" }}
        >
          <div class="member_ship">
            <h2>{t("Membership Plans")}</h2>
            <p>
              {t(
                "create-free-account"
              )}
            </p>
            <div
              class="time_btns"
              onClick={(e) => setErrorField({ ...errorField, month: "" })}
            >
              <button
                onClick={(e) => setMonth(1)}
                className={month == 1 ? "active" : ""}
              >
                <span className={errorField.month != "" ? "bordererror" : ""}>
                  {t("text-monthly")}
                </span>
              </button>
              <button
                onClick={(e) => setMonth(3)}
                className={month == 3 ? "active" : ""}
              >
                <span className={errorField.month != "" ? "bordererror" : ""}>
                  {t("month-3")}
                </span>
              </button>
              <button
                onClick={(e) => setMonth(6)}
                className={month == 6 ? "active" : ""}
              >
                <span className={errorField.month != "" ? "bordererror" : ""}>
                  {t("month-6")}
                </span>
              </button>
            </div>
          </div>
          <div class="member_ship_table">
            <div class="table_head">
              <p>{t("*All prices are on monthly basis.")}</p>
            </div>
            <div class="mobile_collap">
              <button
                type="button"
                className={
                  plan == "Hummingbird" ? "active collapsible" : "collapsible"
                }
                onClick={(e) => setPlanMobile("Hummingbird")}
              >
                <div class="price_content">
                  <img src="img/bird.png" alt="bird" />
                  <div class="price_under_content one">
                    <h2>{t("Hummingbird")}</h2>
                    <h3>{t("text-free")}</h3>
                  </div>
                </div>
              </button>
              <div
                class={
                  planMobile == "Hummingbird" ? "content active" : "content"
                }
              >
                <ul className={plan == "Hummingbird" ? "active" : ""}>
                  <li>{t("create-profile")}</li>
                  <li>{t("Post up to 1 photo")}</li>
                  <li>{t("browser-candidates-profile")}</li>
                  <li>{t("Post up to 1 job offer")}</li>
                  <li>
                    {t("send-inteview-request")} <span>{t("text-unlimited")}</span>
                  </li>
                  <li>
                    {t("receive-applications")} <span>{t("text-unlimited")}</span>
                  </li>
                  <li class="iep_faq">
                    <div class="iep_containter">
                      {t("IEP development")}
                      <img src="img/faq.png" alt="FAQ" />
                      <div class="iep_content">
                        <p>{t("teamExpert")}</p>
                      </div>
                    </div>
                    (
                    {t(
                      "full-report-recommendation-consulting"
                    )}
                    )
                    <div class="iep_section">
                      <div class="iep_plan">
                        <input
                          type="checkbox"
                          name="price"
                          id=""
                          checked={iep == "Hummingbird" ? true : false}
                          onClick={(e) => {
                            if (e.target.checked) {
                              setIep("Hummingbird");
                            } else {
                              setIep("");
                            }
                          }}
                        />
                        <div class="iep">
                          <p>{t("iep-development-add")}</p>
                          <p>
                            {t("Price:")}
                            <strong>
                              {detail.country == "Serbia" || "Srbija" ? (
                                <>
                                  <PayIcon width={12} height={10} />
                                  4100
                                </>
                              ) : (
                                "$41"
                              )}
                            </strong>
                          </p>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
                <div
                  class={
                    errorField.plan != ""
                      ? "borderred select_button"
                      : "select_button"
                  }
                  onClick={(e) => setErrorField({ ...errorField, plan: "" })}
                >
                  <button
                    onClick={(e) => {
                      setIep("");
                      setPlan("Hummingbird");
                    }}
                    className={plan == "Hummingbird" ? "active" : ""}
                  >
                    {t("Select")}
                  </button>
                </div>
              </div>
              <div class="choose">
                <p>{t("best choice")}</p>
                <p class="choose_save">{t("discount-procent")}</p>
              </div>
              <button
                type="button"
                className={
                  plan == "Swan" ? "active collapsible" : "collapsible"
                }
                onClick={(e) => setPlanMobile("Swan")}
              >
                <div class="price_content">
                  <img src="img/swan.png" alt="bird" />
                  <div class="price_under_content two">
                    <h2>{t("Swan")}</h2>
                    {detail.country == "Serbia" || "Srbija" ? (
                      <>
                        <h3>
                          <PayIcon width={12} height={10} color={"#fff"} />
                          2550
                        </h3>
                        <h4>
                          <PayIcon width={12} height={10} color={"#fff"} />
                          3000
                        </h4>
                      </>
                    ) : (
                      <>
                        <h3>$25.5</h3>
                        <h4>$30</h4>
                      </>
                    )}
                  </div>
                </div>
              </button>
              <div class={planMobile == "Swan" ? "content active" : "content"}>
                <ul className={plan == "Swan" ? "active" : ""}>
                  <li>{t("create-profile")}</li>
                  <li>{t("Post up to 2 photo")}</li>
                  <li>{t("browser-candidates-profile")}</li>
                  <li>{t("Post up to 5 job offers monthly")}</li>
                  <li>
                    {t("send-inteview-request")} <span>{t("text-unlimited")}</span>
                  </li>
                  <li>
                    {t("receive-applications")} <span>{t("text-unlimited")}</span>
                  </li>
                  <li>
                    {t("connect-with-candidates")} <span>{t("text-unlimited")}</span>
                  </li>
                  <li>{t("Rate and review providers")}</li>
                  <li>{t("Request background check")}</li>
                  <li>{t("Request recommendations and certificates")}</li>
                  <li>
                    {t("notification-match-candidate")}
                  </li>
                  <li class="iep_faq">
                    <div class="iep_containter">
                      {t("IEP development")}
                      <img src="img/faq.png" alt="FAQ" />
                      <div class="iep_content">
                        <p>{t("teamExpert")}</p>
                      </div>
                    </div>
                    (
                    {t(
                      "full-report-recommendation-consulting"
                    )}
                    )
                    <div class="iep_section">
                      <div class="iep_plan">
                        <input
                          type="checkbox"
                          name="price"
                          id=""
                          checked={iep == "Swan" ? true : false}
                          onClick={(e) => {
                            if (e.target.checked) {
                              setIep("Swan");
                            } else {
                              setIep("");
                            }
                          }}
                        />
                        <div class="iep">
                          <p>{t("iep-development-add")}</p>
                          <p>
                            {t("Price:")}
                            <strong>
                              {detail.country == "Serbia" || "Srbija" ? (
                                <>
                                  <PayIcon width={12} height={10} />
                                  4100
                                </>
                              ) : (
                                "$41"
                              )}
                            </strong>
                          </p>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
                <div
                  class={
                    errorField.plan != ""
                      ? "borderred select_button"
                      : "select_button"
                  }
                  onClick={(e) => setErrorField({ ...errorField, plan: "" })}
                >
                  <button
                    onClick={(e) => {
                      setIep("");
                      setPlan("Swan");
                    }}
                    className={plan == "Swan" ? "active" : ""}
                  >
                    {t("Select")}
                  </button>
                </div>
              </div>
              <div class="choose">
                <p>{t("excellent-choice")}</p>
                <p class="choose_save">{t("save-procent-20")}</p>
              </div>
              <button
                type="button"
                className={
                  plan == "Flamingo" ? "active collapsible" : "collapsible"
                }
                onClick={(e) => setPlanMobile("Flamingo")}
              >
                <div class="price_content ">
                  <img src="img/flam.png" alt="bird" />
                  <div class="price_under_content three">
                    <h2>{t("Flamingo")}</h2>
                    {detail.country == "Serbia" || "Srbija" ? (
                      <>
                        <h3>
                          <PayIcon width={12} height={10} color={"#fff"} />
                          4000
                        </h3>
                        <h4>
                          <PayIcon width={12} height={10} color={"#fff"} />
                          5000
                        </h4>
                      </>
                    ) : (
                      <>
                        <h3>$40.80</h3>
                        <h4>$50</h4>
                      </>
                    )}
                  </div>
                </div>
              </button>
              <div
                class={planMobile == "Flamingo" ? "content active" : "content"}
              >
                <ul className={plan == "Flamingo" ? "active" : ""}>
                  <li>{t("create-profile")}</li>
                  <li>{t("Post up to 2 photos and 1 video")}</li>
                  <li>{t("browser-candidates-profile")}</li>
                  <li>
                    {t("Post job offers")} <span>{t("text-unlimited")}</span>
                  </li>
                  <li>
                    {t("send-inteview-request")} <span>{t("text-unlimited")}</span>
                  </li>
                  <li>
                    {t("receive-applications")} <span>{t("text-unlimited")}</span>
                  </li>
                  <li>
                    {t("connect-with-candidates")} <span>{t("text-unlimited")}</span>
                  </li>
                  <li>{t("Rate and review providers")}</li>
                  <li>{t("Request background check")}</li>
                  <li>{t("Request recommendations and certificates")}</li>
                  <li>
                    {t("notification-match-candidate")}
                  </li>
                  <li>{t("appear-higher-results")}</li>
                  <li>{t("see-who-view-profile")}</li>
                  <li>
                    {t(
                      "senscare-selection-candidates"
                    )}
                  </li>
                  <li>
                    {t(
                      "post-consultation-therapist"
                    )}
                  </li>
                  <li class="iep_faq">
                    <div class="iep_containter">
                      {t("IEP development")}
                      <img src="img/faq.png" alt="FAQ" />
                      <div class="iep_content">
                        <p>{t("teamOfExpertwillDevelop")}</p>
                      </div>
                    </div>
                    {t(
                      "option-report-recommendation-consulting"
                    )}
                    <div class="iep_section">
                      <div class="iep_plan">
                        <input
                          type="checkbox"
                          name="price"
                          id=""
                          checked={iep == "Flamingo" ? true : false}
                          onClick={(e) => {
                            if (e.target.checked) {
                              setIep("Flamingo");
                            } else {
                              setIep("");
                            }
                          }}
                        />
                        <div class="iep">
                          <p>{t("iep-development-add")}</p>
                          <p>
                            {t("Price:")}
                            <strong>
                              {detail.country == "Serbia" || "Srbija"
                                ? (
                                    <PayIcon
                                      width={12}
                                      height={10}
                                      color={"#fff"}
                                    />
                                  ) +
                                  (
                                    <>
                                      <PayIcon width={12} height={10} />
                                      4100
                                    </>
                                  )
                                : "$41"}
                            </strong>
                          </p>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
                <div
                  class={
                    errorField.plan != ""
                      ? "borderred select_button"
                      : "select_button"
                  }
                  onClick={(e) => setErrorField({ ...errorField, plan: "" })}
                >
                  <button
                    onClick={(e) => {
                      setIep("");
                      setPlan("Flamingo");
                    }}
                    className={plan == "Flamingo" ? "active" : ""}
                  >
                    {t("Select")}
                  </button>
                </div>
              </div>
            </div>
            <table id="customer_tabel">
              <tr>
                <th class="blank"></th>
                <td class="blank"></td>
                <td class={plan == "Swan" ? "choose active" : "choose"}>
                  <p>{t("best choice")}</p>
                  <p class="choose_save">{t("discount-procent")}</p>
                </td>
                <td class={plan == "Flamingo" ? "choose active" : "choose"}>
                  <p>{t("excellent-choice")}</p>
                  <p class="choose_save">{t("save-procent-20")}</p>
                </td>
              </tr>
              <tr class="plan">
                <th class="blank"></th>
                <td class={plan == "Hummingbird" ? "price active" : "price"}>
                  <div class="price_content">
                    <img src="img/bird.png" alt="bird" />
                    <div class="price_under_content">
                      <h2>{t("Hummingbird")}</h2>
                      <h3>{t("text-free")}</h3>
                    </div>
                  </div>
                </td>
                <td class={plan == "Swan" ? "price active" : "price"}>
                  <div class="price_content">
                    <img src="img/swan.png" alt="bird" />
                    <div class="price_under_content">
                      <h2>{t("Swan")}</h2>
                      {detail.country == "Serbia" || "Srbija" ? (
                        <>
                          <h3>
                            <PayIcon width={12} height={10} color={"#fff"} />
                            2550
                          </h3>
                          <h4>
                            <PayIcon width={12} height={10} color={"#fff"} />
                            3000
                          </h4>
                        </>
                      ) : (
                        <>
                          <h3>$25.5</h3>
                          <h4>$30</h4>
                        </>
                      )}
                    </div>
                  </div>
                </td>
                <td class={plan == "Flamingo" ? "price active" : "price"}>
                  <div class="price_content">
                    <img src="img/flam.png" alt="bird" />
                    <div class="price_under_content">
                      <h2>{t("Flamingo")}</h2>
                      {detail.country == "Serbia" || "Srbija" ? (
                        <>
                          <h3>
                            <PayIcon width={12} height={10} color={"#fff"} />
                            4000
                          </h3>
                          <h4>
                            <PayIcon width={12} height={10} color={"#fff"} />
                            5000
                          </h4>
                        </>
                      ) : (
                        <>
                          <h3>$40.80</h3>
                          <h4>$50</h4>
                        </>
                      )}
                    </div>
                  </div>
                </td>
              </tr>
              <tr class="plan">
                <th>{t("create-profile")}</th>
                <td class={plan == "Hummingbird" ? " active" : ""}>
                  <img src="img/vector.png" alt="Tick" />
                </td>
                <td class={plan == "Swan" ? " active" : ""}>
                  <img src="img/vector.png" alt="Tick" />
                </td>
                <td class={plan == "Flamingo" ? " active" : ""}>
                  <img src="img/vector.png" alt="Tick" />
                </td>
              </tr>
              <tr class="plan">
                <th>{t("post-photos-videos")}</th>
                <td class={plan == "Hummingbird" ? " active" : ""}>
                  {t("up-to-1-photo")}
                </td>
                <td class={plan == "Swan" ? " active" : ""}>
                  {t("up-to-2-photos")}
                </td>
                <td class={plan == "Flamingo" ? " active" : ""}>
                  {t("up-to-2-photo-1-video")}
                </td>
              </tr>
              <tr class="plan">
                <th>{t("browser-candidates-profile")}</th>
                <td class={plan == "Hummingbird" ? " active" : ""}>
                  <img src="img/vector.png" alt="Tick" />
                </td>
                <td class={plan == "Swan" ? " active" : ""}>
                  <img src="img/vector.png" alt="Tick" />
                </td>
                <td class={plan == "Flamingo" ? " active" : ""}>
                  <img src="img/vector.png" alt="Tick" />
                </td>
              </tr>
              <tr class="plan">
                <th>{t("Post job offers")}</th>
                <td class={plan == "Hummingbird" ? " active" : ""}>
                  {t("up-to-1-photo")}
                </td>
                <td class={plan == "Swan" ? " active" : ""}>
                  {t("up-to-5-posts-monthly")}
                </td>
                <td class={plan == "Flamingo" ? " active" : ""}>
                  {t("text-unlimited")}
                </td>
              </tr>
              <tr class="plan">
                <th>{t("send-inteview-request")}</th>
                <td class={plan == "Hummingbird" ? " active" : ""}>
                  {t("text-unlimited")}
                </td>
                <td class={plan == "Swan" ? " active" : ""}>
                  {t("text-unlimited")}
                </td>
                <td class={plan == "Flamingo" ? " active" : ""}>
                  {t("text-unlimited")}
                </td>
              </tr>
              <tr class="plan">
                <th>{t("receive-applications")}</th>
                <td class={plan == "Hummingbird" ? " active" : ""}>
                  {t("text-unlimited")}
                </td>
                <td class={plan == "Swan" ? " active" : ""}>
                  {t("text-unlimited")}
                </td>
                <td class={plan == "Flamingo" ? " active" : ""}>
                  {t("text-unlimited")}
                </td>
              </tr>
              <tr class="plan">
                <th>{t("connect-with-candidates")}</th>
                <td class={plan == "Hummingbird" ? " active" : ""}></td>
                <td class={plan == "Swan" ? " active" : ""}>
                  {t("text-unlimited")}
                </td>
                <td class={plan == "Flamingo" ? " active" : ""}>
                  {t("text-unlimited")}
                </td>
              </tr>
              <tr class="plan">
                <th>{t("Rate and review providers")}</th>
                <td class={plan == "Hummingbird" ? " active" : ""}></td>
                <td class={plan == "Swan" ? " active" : ""}>
                  <img src="img/vector.png" alt="Tick" />
                </td>
                <td class={plan == "Flamingo" ? " active" : ""}>
                  <img src="img/vector.png" alt="Tick" />
                </td>
              </tr>
              <tr class="plan">
                <th>{t("Request background check")}</th>
                <td class={plan == "Hummingbird" ? " active" : ""}></td>
                <td class={plan == "Swan" ? " active" : ""}>
                  <img src="img/vector.png" alt="Tick" />
                </td>
                <td class={plan == "Flamingo" ? " active" : ""}>
                  <img src="img/vector.png" alt="Tick" />
                </td>
              </tr>
              <tr class="plan">
                <th>{t("Request recommendations and certificates")}</th>
                <td class={plan == "Hummingbird" ? " active" : ""}></td>
                <td class={plan == "Swan" ? " active" : ""}>
                  <img src="img/vector.png" alt="Tick" />
                </td>
                <td class={plan == "Flamingo" ? " active" : ""}>
                  <img src="img/vector.png" alt="Tick" />
                </td>
              </tr>
              <tr class="plan">
                <th>
                  {t("notification-match-candidate")}
                </th>
                <td class={plan == "Hummingbird" ? " active" : ""}></td>
                <td class={plan == "Swan" ? " active" : ""}>
                  <img src="img/vector.png" alt="Tick" />
                </td>
                <td class={plan == "Flamingo" ? " active" : ""}>
                  <img src="img/vector.png" alt="Tick" />
                </td>
              </tr>
              <tr class="plan">
                <th>{t("appear-higher-results")}</th>
                <td class={plan == "Hummingbird" ? " active" : ""}></td>
                <td class={plan == "Swan" ? " active" : ""}></td>
                <td class={plan == "Flamingo" ? " active" : ""}>
                  <img src="img/vector.png" alt="Tick" />
                </td>
              </tr>
              <tr class="plan">
                <th>{t("see-who-view-profile")}</th>
                <td class={plan == "Hummingbird" ? " active" : ""}></td>
                <td class={plan == "Swan" ? " active" : ""}></td>
                <td class={plan == "Flamingo" ? " active" : ""}>
                  <img src="img/vector.png" alt="Tick" />
                </td>
              </tr>
              <tr class="plan">
                <th>
                  {t("senscare-selection-candidates")}
                </th>
                <td class={plan == "Hummingbird" ? " active" : ""}></td>
                <td class={plan == "Swan" ? " active" : ""}></td>
                <td class={plan == "Flamingo" ? " active" : ""}>
                  <img src="img/vector.png" alt="Tick" />
                </td>
              </tr>
              <tr class="plan">
                <th>
                  {t(
                    "post-consultation-therapist"
                  )}
                </th>
                <td class={plan == "Hummingbird" ? " active" : ""}></td>
                <td class={plan == "Swan" ? " active" : ""}></td>
                <td class={plan == "Flamingo" ? " active" : ""}>
                  <img src="img/vector.png" alt="Tick" />
                </td>
              </tr>
              <tr class="plan">
                <th class="iep_faq">
                  <div class="iep_containter">
                    {t("IEP development")}
                    <img src="img/faq.png" alt="FAQ" />
                    <div class="iep_content">
                      <p>{t("teamOfExpertwillDevelop")}</p>
                    </div>
                  </div>
                  {t(
                    "option-report-recommendation-consulting"
                  )}
                </th>
                <td
                  class={
                    iep == "Hummingbird" ? "iep_section active" : "iep_section"
                  }
                >
                  <div class="iep_plan">
                    <input
                      type="checkbox"
                      name="price"
                      id=""
                      checked={iep == "Hummingbird" ? true : false}
                      onClick={(e) => {
                        if (e.target.checked) {
                          setIep("Hummingbird");
                        } else {
                          setIep("");
                        }
                      }}
                    />
                    <div class="iep">
                      <p>{t("iep-development-add")}</p>
                      <p>
                        {t("Price:")}
                        <strong>
                          {detail.country == "Serbia" || "Srbija" ? (
                            <>
                             <PayIcon width={12} height={10} />
                              4100
                            </>
                          ) : (
                            "$41"
                          )}
                        </strong>
                      </p>
                    </div>
                  </div>
                </td>
                <td
                  class={iep == "Swan" ? "iep_section active" : "iep_section"}
                >
                  <div class="iep_plan">
                    <input
                      type="checkbox"
                      name="price"
                      id=""
                      checked={iep == "Swan" ? true : false}
                      onClick={(e) => {
                        if (e.target.checked) {
                          setIep("Swan");
                        } else {
                          setIep("");
                        }
                      }}
                    />
                    <div class="iep">
                      <p>{t("iep-development-add")}</p>
                      <p>
                        {t("Price:")}
                        <strong>
                          {detail.country == "Serbia" || "Srbija" ? (
                            <>
                             <PayIcon width={12} height={10} />
                              4100
                            </>
                          ) : (
                            "$41"
                          )}
                        </strong>
                      </p>
                    </div>
                  </div>
                </td>
                <td
                  class={
                    iep == "Flamingo" ? "iep_section active" : "iep_section"
                  }
                >
                  <div class="iep_plan">
                    <input
                      type="checkbox"
                      name="price"
                      id=""
                      checked={iep == "Flamingo" ? true : false}
                      onClick={(e) => {
                        if (e.target.checked) {
                          setIep("Flamingo");
                        } else {
                          setIep("");
                        }
                      }}
                    />
                    <div class="iep">
                      <p>{t("iep-development-add")}</p>
                      <p>
                        {t("Price:")}
                        <strong>
                          {detail.country == "Serbia" || "Srbija" ? (
                            <>
                              <PayIcon width={12} height={10} />
                              4100
                            </>
                          ) : (
                            "$41"
                          )}
                        </strong>
                      </p>
                    </div>
                  </div>
                </td>
              </tr>
              <tr class="select_btn">
                <th></th>
                <td class={plan == "Hummingbird" ? " active" : ""}>
                  <div
                    class={
                      errorField.plan != ""
                        ? "borderred select_button"
                        : "select_button"
                    }
                    onClick={(e) => setErrorField({ ...errorField, plan: "" })}
                  >
                    <button
                      onClick={(e) => {
                        if (iep != "Hummingbird") {
                          setIep("");
                        }
                        setPlan("Hummingbird");
                      }}
                      className={plan == "Hummingbird" ? "active" : ""}
                    >
                      {t("Select")}
                    </button>
                  </div>
                </td>
                <td class={plan == "Swan" ? " active" : ""}>
                  <div
                    class={
                      errorField.plan != ""
                        ? "borderred select_button"
                        : "select_button"
                    }
                    onClick={(e) => setErrorField({ ...errorField, plan: "" })}
                  >
                    <button
                      onClick={(e) => {
                        if (iep != "Swan") {
                          setIep("");
                        }
                        setPlan("Swan");
                      }}
                      className={plan == "Swan" ? "active" : ""}
                    >
                      {t("Select")}
                    </button>
                  </div>
                </td>
                <td class={plan == "Flamingo" ? " active" : ""}>
                  <div
                    class={
                      errorField.plan != ""
                        ? "borderred select_button"
                        : "select_button"
                    }
                    onClick={(e) => setErrorField({ ...errorField, plan: "" })}
                  >
                    <button
                      onClick={(e) => {
                        if (iep != "Flamingo") {
                          setIep("");
                        }
                        setPlan("Flamingo");
                      }}
                      className={plan == "Flamingo" ? "active" : ""}
                    >
                      {t("Select")}
                    </button>
                  </div>
                </td>
              </tr>
            </table>
          </div>
          <br />
          <br />
          <div class="form_submit_button">
            <div class="button">
              <button
                class="can"
                onClick={(e) => {
                  if (plan && month) {
                    setStep(2);
                    window.scrollTo({ top: 0 });
                    handleGeneralPrice();
                    handleSecondaryPrice();
                  } else {
                    window.scrollTo({ top: 0 });
                    setErrorField({
                      month: month == "" ? "required" : "",
                      plan: plan == "" ? "required" : "",
                    });
                  }
                }}
              >
                {t("text-next")}
              </button>
            </div>
          </div>
        </div>
        <div
          className="step2"
          style={step == 2 ? { display: "block" } : { display: "none" }}
        >
          <div class="selected_plan">
            <div class="container">
              <div class="selected_page_content">
                <h3>
                  {t("You Selected:")} <strong>{plan}</strong>
                </h3>
                <div class="change_plan_btn">
                  <button
                    onClick={(e) => {
                      window.scrollTo({ top: 0 });
                      setStep(1);
                    }}
                  >
                    {t("Change Plan")}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="total_amnt">
            <div class="container">
              <div class="total_amnt_content">
                <h3>
                  {t("Total Amount To Pay:")}
                  <s>
                    {detail.country == "Serbia" || "Srbija" ? (
                      <>
                        <PayIcon width={12} height={10} />
                        {secondaryCompletePrice * 100}
                      </>
                    ) : (
                      "$" + secondaryCompletePrice.toFixed(2)
                    )}
                  </s>
                  <span>
                    {detail.country == "Serbia" || "Srbija" ? (
                      <>
                        <PayIcon width={12} height={10} />
                        {generalCompletePrice * 100}
                      </>
                    ) : (
                      "$" + generalCompletePrice.toFixed(2)
                    )}
                  </span>
                </h3>
                <h4>
                  {t("current-discount-offers")}
                  {plan == "Swan" ? 15 : plan == "Flamingo" ? 20 : 100}%
                  {t("now.")}
                </h4>
                <ul>
                  <li>
                    {t(
                      "subscription-automatically-date"
                    )}
                  </li>
                  <li>
                    {t(
                      "local-taxes-currency-conversions"
                    )}
                  </li>
                  {iep != "" ? (
                    <li>
                      * <strong>{t("IEP document")}</strong>
                      {t("development cost is included.")}
                    </li>
                  ) : (
                    ""
                  )}
                </ul>
              </div>
            </div>
          </div>
          <div class="container">
            <div class="payment_details">
              <h3>{t("payment-details")}</h3>
              {isPaymentType == false ? (
                <>
                  <div class="payment_cards">
                    <button>
                      <img src="img/mastercard.png" alt="" />
                    </button>
                    <button>
                      <img src="img/maestro.png" alt="" />
                    </button>
                    <button>
                      <img src="img/american_exp.png" alt="" />
                    </button>
                    <button>
                      <img
                        src="img/paypal.png"
                        alt=""
                        onClick={() => setIsPaymentType(!isPaymentType)}
                      />
                    </button>
                  </div>
                </>
              ) : (
                ""
              )}
            </div>
            {isPaymentType == false ? (
              <>
                <div class="payment_form">
                  <form action="">
                    <div class="row">
                      <div class="col">
                        <div class="form-group">
                          <label class="form-label" for="name">
                            {t("name-card")}
                          </label>
                          <input
                            type="text"
                            id="name"
                            placeholder={t("text-type")}
                          />
                        </div>
                      </div>
                      <div class="col">
                        <div class="form-group">
                          <label class="form-label" for="card">
                            {t("card-number")}
                          </label>
                          <input
                            type="tel"
                            id="card"
                            placeholder={t("text-type")}
                          />
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col">
                        <div class="form-group">
                          <label class="form-label" for="address">
                            {t("bild-address")}
                          </label>
                          <input
                            type="text"
                            id="address"
                            placeholder={t("text-type")}
                          />
                        </div>
                      </div>
                      <div class="col">
                        <div class="row exp_Sec">
                          <div class="col exp_sec">
                            <div class="form-group">
                              <label class="form-label" for="date">
                                {t("expiration-date")}
                              </label>
                              <input
                                type="date"
                                id="date"
                                placeholder={t("text-type")}
                              />
                            </div>
                          </div>
                          <div class="col exp_sec">
                            <div class="form-group">
                              <label class="form-label" for="security">
                                {t("Security #")}
                              </label>
                              <input
                                type="tel"
                                id="security"
                                placeholder={t("text-type")}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col">
                        <div class="row">
                          <div class="col">
                            <div class="form-group">
                              <label class="form-label" for="country">
                                {t("Country")}
                              </label>
                              <select
                                name=""
                                id=""
                                onChange={(e) => setCountry(e.target.value)}
                              >
                                <option selected>
                                  {t("choose-list-up")}
                                </option>
                                {DATA_COUNTRY.data.map((e) => {
                                  return (
                                    <option value={e.country}>
                                      {e.country}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>
                          </div>

                          <div class="col">
                            {country != "Serbia" || "Srbija" ? (
                              <div class="form-group">
                                <label class="form-label" for="state">
                                  {t("State")}
                                </label>
                                <input
                                  type="email"
                                  id="email"
                                  placeholder={t("text-type")}
                                />
              
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </div>
                      <div class="col">
                        <div class="form-group">
                          <label class="form-label" for="email">
                            {t("email-address")}
                          </label>
                          <input
                            type="email"
                            id="email"
                            placeholder={t("text-type")}
                          />
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col">
                        <div class="row city_zip">
                          <div class="col city_zip">
                            <div class="form-group">
                              <label class="form-label" for="city">
                                {t("text-city")}
                              </label>
                              <input
                                type="text"
                                id="city"
                                placeholder={t("text-type")}
                              />
                            </div>
                          </div>
                          <div class="col city_zip">
                            <div class="form-group">
                              <label class="form-label" for="zip">
                                {t("Zip Code")}
                              </label>
                              <input
                                type="tel"
                                id="zip"
                                placeholder={t("text-type")}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col">
                        <div class="form-group">
                          <div class="radio">
                            <input
                              type="checkbox"
                              checked={isPayment}
                              onClick={(e) => setIsPayment()}
                            />
                            <label>
                              {t("save-card-future-payments")}
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </>
            ) : (
              <div className="Account ">
                <div className={"personal"}>
                  <div className="cardstype" style={{ margin: "0" }}>
                    <div className="paymenttype">
                      <ul>
                        <li>
                          <input type="radio" id="a" name="a" />
                          <label for="a">{t("Pay with card")}</label>
                        </li>
                        <li>
                          <input type="radio" id="b" name="a" />
                          <label
                            for="b"
                            onClick={() => setIsPaymentType(!isPaymentType)}
                          >
                            {t("Pay with PayPal")}
                          </label>
                        </li>
                      </ul>
                    </div>
                    <h4>{t("My Cards")}</h4>
                    <div className="savecard" style={{ margin: "0" }}>
                      <ul>
                        <li>
                          <label for="c">
                            
                            <input type="radio" id="c" name="c" />
                            <span>{t("Use for payment")}</span>
                          </label>
                          <span>{t("Card name")}</span>
                          <p>{t("Bankname card ****4589")}</p>
                          <span>{t("expires-on")}</span>
                          <p>04/2022</p>
                          <button style={{ textAlign: "right" }}>
                            {t("Edit")}
                          </button>
                        </li>
                        <li>
                          <label for="d">
                            
                            <input type="radio" id="d" name="c" />
                            <span>{t("Use for payment")}</span>
                          </label>
                          <span>{t("Card name")}</span>
                          <p>{t("Bankname card ****4589")}</p>
                          <span>{t("expires-on")}</span>
                          <p>04/2022</p>
                          <button style={{ textAlign: "right" }}>
                            {t("Edit")}
                          </button>
                        </li>
                        <li>
                          <label for="e">
                            
                            <input type="radio" id="e" name="c" />
                            <span>{t("Use for payment")}</span>
                          </label>
                          <span>{t("Card name")}</span>
                          <p>{t("Bankname card ****4589")}</p>
                          <span>{t("expires-on")}</span>
                          <p>04/2022</p>
                          <button style={{ textAlign: "right" }}>
                            {t("Edit")}
                          </button>
                        </li>
                        <li>
                          <label for="e">
                            
                            <input type="radio" id="e" name="c" />
                            <span>{t("Use for payment")}</span>
                          </label>
                          <span>{t("Card name")}</span>
                          <p>{t("Bankname card ****4589")}</p>
                          <span>{t("expires-on")}</span>
                          <p>04/2022</p>
                          <button style={{ textAlign: "right" }}>
                            {t("Edit")}
                          </button>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div class="promotion">
              <div class="promotion_code">
                <label for="">{t("add-promotion-code")}</label>
                <input type="text" placeholder="ngsklf4875d" />
                <button>{t("apply-code")}</button>
              </div>
            </div>
            <div class="pay_aggrement">
              <input type="checkbox" />
              <label for="">
                {t("agree-pay")}
                {detail.country == "Serbia" || "Srbija" ? (
                  <>
                   <PayIcon width={12} height={10} />
                    {(generalCompletePrice / month).toFixed(2) * 100}
                  </>
                ) : (
                  "$" + (generalCompletePrice / month).toFixed(2)
                )}
                {t("monthly-for")} {month > 0 ? month : ""}
                {month == 1 ? "month " : "months "} {t("subscription")}.
              </label>
            </div>
            <div class="form_submit_button">
              <div class="button">
                <button
                  class="can"
                  onClick={(e) => {
                    setStep(1);
                  }}
                >
                  {t("text-back")}
                </button>
                <button class="sub" onClick={(e) => handleUpdateMembership()}>
                  {t("text-submit")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      <Modal show={showLoginModal} onHide={handleCloseLoginModal}>
        <Modal.Body>
          <div className="promocode_content login_first">
            <Link to="" onClick={handleCloseLoginModal}>
              +
            </Link>
            <h2>{t("Congratulations on your new membership plan")}</h2>
            <img src="./images/create_profile.png" />
            <p className="pro" style={{ color: "#636363" }}>
              {t("It’s time to find your perfect candidate!")}
            </p>
            <button onClick={handleRedirect}>{t("search-candidates")}</button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
