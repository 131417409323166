import React from "react";

export const HandIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="#fff"
    >
      <circle cx="8" cy="8" r="7.5" stroke="#7D2B8B" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.0224 10.8435C11.0224 11.6258 10.3824 12.2658 9.6002 12.2658H7.00464C6.62064 12.2658 6.25797 12.1129 5.99131 11.8426L3.2002 9.00531C3.2002 9.00531 3.54174 8.64978 3.66242 8.56087C3.78309 8.47195 3.83814 8.46759 3.94331 8.45776C4.04847 8.44792 4.09264 8.47909 4.15664 8.51464C4.17086 8.5182 5.68909 9.38931 5.68909 9.38931V5.15464C5.68909 4.85953 5.92731 4.62131 6.22242 4.62131C6.51753 4.62131 6.75575 4.85953 6.75575 5.15464V7.64353H7.11131V4.26576C7.11131 3.97064 7.34953 3.73242 7.64464 3.73242C7.93975 3.73242 8.17797 3.97064 8.17797 4.26576V7.64353H8.53353V4.62131C8.53353 4.3262 8.77175 4.08798 9.06686 4.08798C9.36197 4.08798 9.6002 4.3262 9.6002 4.62131V7.64353H9.95575V5.68798C9.95575 5.39287 10.194 5.15464 10.4891 5.15464C10.7842 5.15464 11.0224 5.39287 11.0224 5.68798V10.8435Z"
        fill="#7D2B8B"
      />
    </svg>
  );
};
