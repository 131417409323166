export const LocationIcon = () => {
  return (
    <svg
      width="8"
      height="10"
      viewBox="0 0 8 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0 3.59527C0 1.6076 1.6076 0 3.59527 0C5.58295 0 7.19055 1.6076 7.19055 3.59527C7.19055 5.73703 4.92039 8.69029 3.99075 9.8151C3.78531 10.0616 3.41037 10.0616 3.20493 9.8151C2.27016 8.69029 0 5.73703 0 3.59527ZM2.31125 3.59527C2.31125 4.30406 2.88649 4.8793 3.59527 4.8793C4.30406 4.8793 4.8793 4.30406 4.8793 3.59527C4.8793 2.88649 4.30406 2.31125 3.59527 2.31125C2.88649 2.31125 2.31125 2.88649 2.31125 3.59527Z"
        fill="#A98D4B"
      />
    </svg>
  );
};
