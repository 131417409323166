export const CrossIcon = () => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="7" width="2.28571" height="22" rx="1.14286" fill="#7D2B8B" />
      <rect
        y="7"
        width="2.28571"
        height="20"
        rx="1.14286"
        transform="rotate(-90 0 9)"
        fill="#7D2B8B"
      />
    </svg>
  );
};
