import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { Footer } from "../../components/common/Footer";
import { Header } from "../../components/common/Header";
import { ArrowUpIcon } from "../../assets/image/ArrowUpIcon";
import { MainDandruffIcon } from "../../assets/image/MainDandruffIcon";
import { DATA_RESOURCES } from "../../utils/data";
import { CATEGORY_RESOURCES, ENG_LANGUAGE } from "../../utils/variables";
import {
  fetchResourceCategories,
  fetchResourceCategory,
  fetchResourceContent,
} from "../../api";

export function ResourcesPage() {
  const { t, i18n } = useTranslation();
  const language = i18n.language;

  const [resourcesData, setResourcesData] = useState({});
  const [resourcesCategory, setResourcesCategory] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [display, setDisplay] = useState("none");
  const [selectList, setSelectList] = useState([]);
  const [categoriesData, setCategoriesData] = useState([]);
  const [isOpen, setIsOpen] = useState(true);
  const [faqtab, setFaqtab] = useState([]);

 console.log(resourcesData, "123123123123123")

  const handleFilterArray = (data) => {
    setFaqtab((prevFaqtab) => {
      const isInFaqtab = prevFaqtab.some((e) => e.name === data);
      const newFaqtab = isInFaqtab
        ? prevFaqtab.filter((e) => e.name !== data)
        : [...prevFaqtab, { name: data }];

      return newFaqtab;
    });
  };

  const fetchAllData = async () => {
    try {
      const result = await fetchResourceContent();
      setResourcesData(result.data);

      const resultCategories = await fetchResourceCategories();
      setCategoriesData(resultCategories.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchAllData();
    handleCategoryData();
  }, []);

  const handleCategoryData = async () => {
    setResourcesCategory([]);
    try {
      const result = await fetchResourceCategory(selectList, inputValue);
      setResourcesCategory(result.data);
    } catch (error) {
      console.error("Error fetching resource category:", error);
    }
  };

  const toggleAddCategory = (data) => {
    let sum = false;
    selectList.map((e, index) => {
      if (e.name == data) {
        sum = true;
        selectList.splice(index, 1);
      }
    });
    if (sum == false) {
      selectList.push({ name: data });
    }
    setTimeout(() => {
      setSelectList([...selectList]);
    }, 500);
  };

  const handleDisplay = () => {
    setIsOpen((prevOpen) => !prevOpen);
    setDisplay((prevDisplay) => (prevDisplay === "none" ? "block" : "none"));
  };

  const handleCloseDropdownOutside = (event) => {
    const dropdown = document.getElementById("cate");
    const svgIcon = document.getElementById("svgIcon");
    if (
      dropdown &&
      !dropdown.contains(event.target) &&
      svgIcon &&
      !svgIcon.contains(event.target)
    ) {
      setIsOpen(false);
      setDisplay("none");
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleCloseDropdownOutside);

    return () => {
      document.removeEventListener("click", handleCloseDropdownOutside);
    };
  }, []);

  return (
    <>
      <Header />
      <div className="container-fluid resource_tital">
        <div className="container">
          {resourcesData.title && (
            <h2>{t("resources.title", { defaultValue: resourcesData.title })}</h2>
          )}
          {resourcesData.description && (
            <div
              dangerouslySetInnerHTML={{
                __html: t("resources.description", {
                  defaultValue: resourcesData.description,
                }),
              }}
              className="workus"
            />
          )}
        </div>
      </div>
      <div className="container-fluid">
        <div className="container">
          <div className="resource_content">
            <div className="resource_search">
              <label>
                {t("Explore resources")}
                <Link
                  to=""
                  onClick={(e) => {
                    window.location.reload();
                  }}
                >
                  {t("Clear all filters")}
                </Link>
              </label>
              <div className="form_group">
                <div className="inp">
                  <input
                    className="keyword"
                    type="text"
                    placeholder={t("keyword")}
                    onChange={(e) => setInputValue(e.target.value)}
                    value={inputValue}
                  />
                  {inputValue.length > 2 && (
                    <span
                      onClick={(e) => {
                        setInputValue("");
                        setTimeout(() => {
                          handleCategoryData();
                        }, 2000);
                      }}
                    >
                      +
                    </span>
                  )}
                </div>

                <div className="customselect inp">
                  <input
                    className="keyword"
                    type="text"
                    placeholder={t("Category")}
                    value={selectList.map((e) => {
                      return e.name;
                    })}
                  />
                  <div
                    className={`option ${isOpen ? "open" : ""}`}
                    id="cate"
                    style={{ display: display }}
                  >
                    {language === ENG_LANGUAGE
                      ? categoriesData.map((e, index) => {
                          return (
                            <p
                              className={
                                categoriesData.length == 1 + index
                                  ? "border"
                                  : ""
                              }
                            >
                              <input
                                type="checkbox"
                                onClick={(a) => toggleAddCategory(e.category)}
                              />
                              <h3>{e.category} </h3>
                              <span></span>
                            </p>
                          );
                        })
                      : CATEGORY_RESOURCES.map((e, index) => {
                          return (
                            <p
                              className={
                                CATEGORY_RESOURCES.length == 1 + index
                                  ? "border"
                                  : ""
                              }
                            >
                              <input
                                type="checkbox"
                                onClick={(a) => toggleAddCategory(e.category)}
                              />
                              <h3>{e.category} </h3>
                              <span></span>
                            </p>
                          );
                        })}
                    <div
                      className="clr inp"
                      onClick={(e) => {
                        setSelectList([]);
                        window.location.reload();
                      }}
                    >
                      {t("Clear All")} <span>+</span>
                    </div>
                  </div>

                  <span onClick={(e) => handleDisplay("cate")} id="svgIcon">
                    <ArrowUpIcon />
                  </span>
                </div>
              </div>
              <div className="clear">
                <button
                  onClick={(e) => {
                    handleCategoryData();
                    handleDisplay("cate");
                  }}
                >
                  <MainDandruffIcon width={20} height={21} color={"#A98D4B"} />
                  {t("text-search")}
                </button>
              </div>
            </div>
            <div className="resources_tabs">
              {resourcesCategory.length >= 1 ? (
                language === "en" ? (
                  resourcesCategory.map((data, index) => {
                    return (
                      <div className="tabs">
                        <h2
                          onClick={(e) =>
                            handleFilterArray("tab" + index, "icon" + index)
                          }
                        >
                          {data.category}
                          <img
                            src="./images/down_pur.svg"
                            id={"icon" + index}
                          />
                        </h2>
                        <div className="tab_open" id={"tab" + index}>
                          {data.data.map((data2, index) => {
                            return (
                              <>
                                {data2.title ? <h3>{data2.title}</h3> : <br />}
                                <h4>{data2.subtitle}</h4>
                                <a href={data2.link} target="_blank">
                                  {data2.link}
                                </a>
                                <br />
                              </>
                            );
                          })}
                        </div>
                      </div>
                    );
                  })
                ) : (
                  DATA_RESOURCES.map((data, index) => {
                    return (
                      <div className="tabs">
                        <h2
                          onClick={(e) =>
                            handleFilterArray("tab" + index, "icon" + index)
                          }
                        >
                          {data.category}
                          <img
                            src="./images/down_pur.svg"
                            id={"icon" + index}
                          />
                        </h2>
                        <div className="tab_open" id={"tab" + index}>
                          {data.data.map((data2) => {
                            return (
                              <>
                                {data2.title ? <h3>{data2.title}</h3> : <br />}
                                <h4>{data2.subtitle}</h4>
                                <ul>
                                  <li style={{ marginBottom: "4px" }}>
                                    <a href={data2.link} target="_blank">
                                      {data2.link}
                                    </a>
                                  </li>
                                  <li>
                                    <a href={data2.linkAnother} target="_blank">
                                      {data2.linkAnother}
                                    </a>
                                  </li>
                                </ul>
                                <br />
                              </>
                            );
                          })}
                        </div>
                      </div>
                    );
                  })
                )
              ) : (
                <p>
                  {t("current-criteria-found")}
                </p>
              )}
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}
