import React, { useState } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { CustomSelect } from "../CustomSelect";
import { HeaderMenu } from "./HeaderMenu";

export function Header(props) {
  const { t } = useTranslation();

  const [menu, setMenu] = useState("close");
  const [isScroll, setIsScroll] = useState(true);

  const [logincheck, setlogincheck] = useState({
    id: localStorage.getItem("id"),
    token: localStorage.getItem("token"),
    namep: localStorage.getItem("name"),
    type: localStorage.getItem("user_type"),
  });

  const location = useLocation();

  if (location.pathname != "/faq") {
    localStorage.removeItem("faq");
    localStorage.removeItem("refine");
  }
  if (location.pathname != "/search-providers") {
    localStorage.removeItem("refine");
  }
  if (location.pathname != "/search-parents") {
    localStorage.removeItem("refine2");
  }
  if (
    location.pathname != "/search-parents" &&
    location.pathname != "/search-providers" &&
    location.pathname != "/profile-parents/:id" &&
    location.pathname != "/profile-provider/:id"
  ) {
    localStorage.removeItem("search");
    localStorage.removeItem("search2");
    localStorage.removeItem("edittime");
    localStorage.removeItem("filter");
  }
  if (
    location.pathname != "/search-parents/:id" &&
    location.pathname != "/search-providers/:id" &&
    location.pathname != "/search-parents/:id/:name" &&
    location.pathname != "/search-providers/:id/:name"
  ) {
    localStorage.removeItem("side");
  }
  if (
    location.pathname
      .substr(location.pathname.lastIndexOf("\\") + 1)
      .split("/")[1] != "profile-provider" &&
    location.pathname
      .substr(location.pathname.lastIndexOf("\\") + 1)
      .split("/")[1] != "profile-parents" &&
    location.pathname != "/search-parents" &&
    location.pathname != "/search-providers"
  ) {
    localStorage.removeItem("searchdataprovider");
    localStorage.removeItem("searchdata");
  }

  const logout = () => {
    localStorage.removeItem("id");
    localStorage.removeItem("token");
    localStorage.removeItem("search");
    localStorage.removeItem("search2");
    setTimeout(() => {
      window.location.replace("/login");
    }, 500);
  };

  if (isScroll == true) {
    window.scrollTo({ top: 0 });
    setIsScroll(false);
  }

  return (
    <>
      <div className="container-fluid border" style={{ zIndex: 11 }}>
        <div className="container">
          <div className="header">
            <div className="search">
              <button onClick={props.fil}>
                {location.pathname == "/search-providers" ||
                location.pathname == "/search-parents" ? (
                  <img src={window.location.origin + "/images/search.svg"} />
                ) : (
                  <NavLink to={"/search-providers"}>
                    <img src={window.location.origin + "/images/search.svg"} />
                  </NavLink>
                )}
              </button>
            </div>
            <div className="logo_main">
              <Link to="/">
                <img
                  src={window.location.origin + "/images/logo.svg"}
                  alt="logo"
                />
              </Link>
            </div>
            <div className="mobileicon">
              <button onClick={(e) => setMenu("open")}>
                <img src={window.location.origin + "/images/menuicon.svg"} />
              </button>
            </div>
            <div className="menu">
              <ul>
                <li>
                  <Link to="/" className={props.page == "home" ? "active" : ""}>
                    {t("text-home")}
                  </Link>
                </li>
                <li>
                  <a href="https://blog.mysenscare.com">{t("text-blog")}</a>
                </li>
                {logincheck.id && logincheck.token ? (
                  <HeaderMenu
                    props={props}
                    logincheck={logincheck}
                    logout={logout}
                  />
                ) : (
                  <>
                    <li className="signup">
                      <Link style={{ textTransform: "None" }} to="/signup">
                        {t("sign-up")}
                      </Link>
                    </li>
                    <li className="login">
                      <Link style={{ textTransform: "None" }} to="/login">
                        {t("log-in")}
                      </Link>
                    </li>
                  </>
                )}
                <li className="lang" id="google_element">
                  <CustomSelect
                    items={[
                      {
                        value: "ENG",
                        id: 1,
                        icon:
                          window.location.origin + "/images/Flag_of_the_US.svg",
                        lang: "en",
                      },
                      {
                        value: "SRB",
                        id: 2,
                        icon: window.location.origin + "/images/Serbia.png",
                        lang: "sr",
                      },
                    ]}
                  />
                </li>
              </ul>
            </div>
            <span className={"menu mobilem shadow " + menu}></span>
            <div className={"menu mobilem " + menu}>
              <button onClick={(e) => setMenu("close")}>
                <img src={window.location.origin + "/images/cross.svg"} />
              </button>
              <ul>
                <li className="border">
                  <Link to="/" className={props.page == "home" ? "active" : ""}>
                    {t("text-home")}
                  </Link>
                </li>
                <li className="border">
                  <a href="https://blog.mysenscare.com">{t("text-blog")}</a>
                </li>
                <li className="">
                  <Link to="/search-parents/Loyalty">{t("sc-referrals")}</Link>
                </li>
                {logincheck.id && logincheck.token ? (
                  <>
                    <li>
                      <Link
                        to=""
                        className={props.page == "profile" ? "active" : ""}
                      >
                        {logincheck.namep ? logincheck.namep : "username"}
                        <img
                          src={window.location.origin + "/images/done_a.svg"}
                        />
                      </Link>
                      <ul>
                        <li>
                          <Link
                            to={
                              localStorage.getItem("user_type") != "parents"
                                ? "/search-parents/Account"
                                : "/search-providers/Account"
                            }
                            onClick={() => {
                              localStorage.setItem("search2", "Account");
                            }}
                          >
                            {t("text-account")}
                          </Link>
                        </li>
                        <li>
                          <Link
                            to={
                              localStorage.getItem("user_type") != "parents"
                                ? "/search-parents/Profile"
                                : "/search-providers/Profile"
                            }
                            onClick={() => {
                              localStorage.setItem("search2", "Profile");
                            }}
                          >
                            {t("view-edit-profile")}
                          </Link>
                        </li>
                        <li onClick={logout}>{t("logout")}</li>
                      </ul>
                    </li>
                  </>
                ) : (
                  ""
                )}
                <li className="lang">
                  <CustomSelect
                    items={[
                      {
                        value: "ENG",
                        id: 1,
                        icon:
                          window.location.origin + "/images/Flag_of_the_US.svg",
                        lang: "en",
                      },
                      {
                        value: "SRB",
                        id: 2,
                        icon: window.location.origin + "/images/Serbia.png",
                        lang: "sr",
                      },
                    ]}
                  />
                </li>
                {logincheck.id && logincheck.token ? (
                  <>
                    <li className="signup">
                      <Link to={""}>{t("text-upgrade")}</Link>
                    </li>
                  </>
                ) : (
                  <>
                    <li className="signup">
                      <Link to="/signup">{t("sign-up")}</Link>
                    </li>
                    <li className="login">
                      <Link to="/login">{t("log-in")}</Link>
                    </li>
                  </>
                )}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
