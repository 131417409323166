import {RATE_NUMBERS, RATE_NUMBERS_SECOND} from "../../../utils/variables";
import React from "react";

const OldSlider = ({ rate, setRate, serbian }) => {
    return serbian ? (
        <div className="wrapper rang">
            <div className="container_slide">
                <div className="slider-track">
                    <ul>
                        {RATE_NUMBERS.map((value, index) => {
                            const style =
                                rate.min >= value ||
                                (rate.max < value && rate.max > 0)
                                    ? {backgroundColor: "#E5E5E5"}
                                    : {};
                            return <li key={index} style={style}></li>;
                        })}
                    </ul>
                </div>
                <div className="bggray_slider">
                    <ul>
                        {RATE_NUMBERS_SECOND.map((value, index) => {
                            const style =
                                rate.min >= value ||
                                (rate.max < value && rate.max > 0)
                                    ? {backgroundColor: "#E5E5E5"}
                                    : {};
                            return <li key={index} style={style}></li>;
                        })}
                    </ul>
                </div>
                <input
                    type="range"
                    min="0"
                    max="5"
                    id="slider-1"
                    value={rate.min / 10}
                    onChange={(e) => {
                        if (
                            (rate.max > 0 ? rate.max : 60) >
                            (e.target.value == 5
                                ? 60
                                : e.target.value * 10)
                        ) {
                            setRate({
                                ...rate,
                                min:
                                    e.target.value == 5
                                        ? 60
                                        : e.target.value * 10,
                            });
                        }
                    }}
                />
                <input
                    type="range"
                    min="0"
                    max="5"
                    id="slider-2"
                    value={rate.max == 0 ? 50 / 10 : rate.max / 10}
                    onChange={(e) => {
                        if (
                            rate.min <
                            (e.target.value == 5
                                ? 60
                                : e.target.value * 10)
                        ) {
                            setRate({
                                ...rate,
                                max:
                                    e.target.value == 5
                                        ? 60
                                        : e.target.value * 10,
                            });
                        }
                    }}
                />
            </div>
            <div className="slider two">
                <ul>
                    <li>0</li>
                    <li>1000</li>
                    <li>2000</li>
                    <li>3000</li>
                    <li>4000</li>
                    <li>6000+</li>
                </ul>
            </div>
        </div>
    ) : (
        <div className="wrapper rang">
            <div className="container_slide">
                <div className="slider-track">
                    <ul>
                        {RATE_NUMBERS.map((value, index) => {
                            const style =
                                rate.min >= value ||
                                (rate.max < value && rate.max > 0)
                                    ? {backgroundColor: "#E5E5E5"}
                                    : {};
                            return <li key={index} style={style}></li>;
                        })}
                    </ul>
                </div>
                <div className="bggray_slider">
                    <ul>
                        {RATE_NUMBERS_SECOND.map((value, index) => {
                            const style =
                                rate.min >= value ||
                                (rate.max < value && rate.max > 0)
                                    ? {backgroundColor: "#E5E5E5"}
                                    : {};
                            return <li key={index} style={style}></li>;
                        })}
                    </ul>
                </div>
                <input
                    type="range"
                    min="0"
                    max="5"
                    id="slider-1"
                    value={rate.min / 10}
                    onChange={(e) => {
                        if (
                            (rate.max > 0 ? rate.max : 60) >
                            (e.target.value == 5
                                ? 60
                                : e.target.value * 10)
                        ) {
                            setRate({
                                ...rate,
                                min:
                                    e.target.value == 5
                                        ? 60
                                        : e.target.value * 10,
                            });
                        }
                    }}
                />
                <input
                    type="range"
                    min="0"
                    max="5"
                    id="slider-2"
                    value={rate.max == 0 ? 50 / 10 : rate.max / 10}
                    onChange={(e) => {
                        if (
                            rate.min <
                            (e.target.value == 5
                                ? 60
                                : e.target.value * 10)
                        ) {
                            setRate({
                                ...rate,
                                max:
                                    e.target.value == 5
                                        ? 60
                                        : e.target.value * 10,
                            });
                        }
                    }}
                />
            </div>
            <div className="slider two">
                <ul>
                    <li>0</li>
                    <li>10</li>
                    <li>20</li>
                    <li>30</li>
                    <li>40</li>
                    <li>60+</li>
                </ul>
            </div>
        </div>
    );
};

export default OldSlider;
