import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Modal from "react-bootstrap/Modal";

import { api } from "../../../urls";

import { SendReview } from "../../common/SendReview";
import { FavoriteProfile } from "../../common/FavoriteProfile";
import { MessageContent } from "../../Message/MessageContent";
import { SocialFacebook } from "../../common/SocialFacebook";
import { PhoneIcon } from "../../../assets/image/PhoneIcon";
import { ServiceIcon } from "../../../assets/image/ServiceIcon";
import { StarIcon } from "../../../assets/image/StarIcon";
import { WhiteStarIcon } from "../../../assets/image/WhiteStarIcon";
import { SearchIcon } from "../../../assets/image/SearchIcon";
import { MarkRoundIcon } from "../../../assets/image/MarkRoundIcon";
import { CrownIcon } from "../../../assets/image/CrownIcon";
import { ConfirmIcon } from "../../../assets/image/ConfirmIcon";
import {
  ENG_LANGUAGE,
  MONTH_ENG_LANGUAGE,
  MONTH_SRB_LANGUAGE,
} from "../../../utils/variables";
import { InterviewItem } from "./InterviewItem";
import { InterviewSection } from "./InterviewSection";

export function InterviewAccept(props) {
  const { t, i18n } = useTranslation();
  const language = i18n.language;

  const [providerData, setProviderData] = useState([]);
  const [isCheck, setIsCheck] = useState(true);
  const [rate, setRate] = useState({});
  const [update, setUpdate] = useState(false);
  const [profile] = useState();
  const [showReviewModal, setShowReviewModal] = useState(false);
  const [view, setView] = useState("");
  const [isElement, setIsElement] = useState(true);

  const [message, setMessage] = useState("");
  const [hire, setHire] = useState("");

  const month =
    language === ENG_LANGUAGE ? MONTH_ENG_LANGUAGE : MONTH_SRB_LANGUAGE;

  const handleElementById = (e, x, z) => {
    console.log(e, x);
    document.getElementById(e).style.display = "none";
    document.getElementById(x).style.display = "block";
    if (isElement) {
      document.getElementById(z).style.display = "block";
      setIsElement(false);
    } else {
      setIsElement(true);
      document.getElementById(z).style.display = "none";
    }
  };

  const fetchProviderData = () => {
    const myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      notification_id: props.id,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(api + "/api/v1/interviewinvitesresponseprofile", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setProviderData([result.data.providerprofile]);
        setMessage(result.data.currentUserName);
        setRate(result.data);
        console.log(result);
      })
      .catch((error) => console.log("error", error));
  };

  useEffect(() => {
    if (isCheck) {
      fetchProviderData();
      setIsCheck(false);
    }
  }, [isCheck, hire]);

  const handleSubmit = (a) => {
    const myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      inv_id: props.id,
      provider_id: a,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(api + "/api/v1/providerhire", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.type == "success") {
          setHire("Hired Successfully");
        }
        console.log(result);
      })
      .catch((error) => console.log("error", error));
  };

  return (
    <>
      {providerData && providerData[0] ? (
        view == "" ? (
          <>
            <h2 className="border"></h2>
            <div className="detail_invit">
              <p>
                {providerData &&
                providerData[0] &&
                providerData[0].read_status == 0 ? (
                  <span className="new">{t("text-new-up")}</span>
                ) : (
                  ""
                )}
                {providerData &&
                providerData[0] &&
                providerData[0].profile_headline != null
                  ? providerData &&
                    providerData[0] &&
                    providerData[0].profile_headline
                  : ""}
                <span className="date">
                  {new Date(
                    providerData &&
                      providerData[0] &&
                      providerData[0].created_at
                  ).toLocaleDateString("en-US", { weekday: "short" })}
                  ,
                  {month[
                    new Date(
                      providerData &&
                        providerData[0] &&
                        providerData[0].created_at
                    ).getMonth()
                  ] +
                    " " +
                    new Date(
                      providerData &&
                        providerData[0] &&
                        providerData[0].created_at
                    ).getDate()}
                  ,
                  {new Date(
                    providerData &&
                      providerData[0] &&
                      providerData[0].created_at
                  ).getFullYear()}
                  ,
                  {new Date(
                    providerData &&
                      providerData[0] &&
                      providerData[0].created_at
                  ).toLocaleTimeString([], {
                    hour: "2-digit",
                    minute: "2-digit",
                  })}
                </span>
              </p>
              <h2>
                {t("text-dear")} <span>{message}</span>,
              </h2>
              <h2>
                {providerData && providerData[0] && providerData[0].status == 2
                  ? ""
                  : t("congratulations-with")}
                <Link
                  to={"/profile-provider/" + providerData[0]?.UID}
                  style={{
                    marginLeft:
                      providerData &&
                      providerData[0] &&
                      providerData[0].status == 2
                        ? "0"
                        : "5px",
                  }}
                >
                  
                  {providerData &&
                    providerData[0] &&
                    providerData[0].first_name}
                </Link>
                {providerData && providerData[0] && providerData[0].status == 1
                  ? t("text-accepted")
                  : providerData &&
                    providerData[0] &&
                    providerData[0].status == 2
                  ? t("text-declined")
                  : t("text-pending")}
                {t("interview-person-invite")}
              </h2>
              {providerData &&
              providerData[0] &&
              providerData[0].status == 2 ? (
                ""
              ) : (
                <p style={{ paddingTop: "15px" }}>
                  {t(
                    "next-step-interview"
                  )}
                  <Link to="/safety-center" target="_blank">
                    {t("safety-center")}
                  </Link>
                  {t("text-and")}
                  <Link to="/faq" target="_blank">
                    {t("text-faq")}
                  </Link>
                  .
                </p>
              )}
            </div>
            <div class="right_side_section jobapli">
              <div class="looking_for_candidate">
                {providerData.map((data, index) => {
                  return (
                    <div class="looking_for_candidate_boxs">
                      <div class="looking_for_candidate_box">
                        <div class="first_sec">
                          <div class="image_sec">
                            <img
                              src={
                                data.file_path != null
                                  ? api +
                                    "/assets/images/users/" +
                                    data.file_path
                                  : window.location.origin + "/img/nany_img.png"
                              }
                              alt=""
                            />
                            <div class="heart_sec">
                              <FavoriteProfile
                                id={data.provider_id}
                                username={data.first_name}
                              />
                            </div>
                          </div>
                          <div class="nany_social">
                            {data.phoneVerifiedStatus == 1 ? (
                              <img
                                src={
                                  window.location.origin +
                                  "/images/nany_phone.svg"
                                }
                                alt=""
                              />
                            ) : (
                              <PhoneIcon />
                            )}
                            <img
                              src={
                                window.location.origin + "/images/nany_msg.svg"
                              }
                              alt=""
                            />
                            {data.facebookverify != null ||
                            data.linkdinverify != null ? (
                              <img
                                src={
                                  window.location.origin +
                                  "/images/nany_cont.svg"
                                }
                                alt=""
                              />
                            ) : (
                              <ServiceIcon />
                            )}
                            {rate.docsStatus == "Yes" ? (
                              <img
                                src={window.location.origin + "/images/ok.svg"}
                                alt=""
                              />
                            ) : (
                              <ConfirmIcon />
                            )}
                          </div>
                        </div>

                        <InterviewSection
                          rate={rate}
                          data={data}
                          providerData={providerData}
                          hire={hire}
                          handleSubmit={handleSubmit}
                          setShowReviewModal={setShowReviewModal}
                          profile={profile}
                          setIsElement={setIsElement}
                          handleElementById={handleElementById}
                          index={index}
                        />

                        <div className="find_search">
                          <Link to="/search-providers">
                            <SearchIcon />
                            {t("search-similar-candidate")}
                          </Link>
                        </div>
                        {rate?.jobStatus.jobStatus != 1 ? (
                          <div class="view_profile_btn">
                            {data.status == 1 ? (
                              <Link
                                to=""
                                onClick={(e) => setView(data.provider_id)}
                              >
                                {t("send-message")}
                              </Link>
                            ) : (
                              ""
                            )}
                            <Link to={"/profile-provider/" + data.provider_id}>
                              {t("visit-profile")}
                            </Link>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>

                      <p>
                        {(data.medicalcondition != null &&
                          data.medicalcondition == "Yes") ||
                        (data.anyallergies != null &&
                          data.anyallergies == "Yes") ? (
                          <>
                            <MarkRoundIcon />
                            <strong>{t("note-with")}</strong>
                            {t(
                              "record-indicates"
                            )}
                            <br />
                          </>
                        ) : (
                          ""
                        )}
                      </p>
                    </div>
                  );
                })}
                <div className="mobile">
                  {providerData.map((data, index) => {
                    if (index <= 5) {
                      return (
                        <InterviewItem
                          data={data}
                          profile={profile}
                          index={index}
                          providerData={providerData}
                          hire={hire}
                          rate={rate}
                          setView={setView}
                          handleSubmit={handleSubmit}
                          handleElementById={handleElementById}
                        />
                      );
                    }
                  })}
                </div>
              </div>
            </div>
          </>
        ) : (
          ""
        )
      ) : (
        ""
      )}
      {view != "" ? (
        <div class="main-header">
          <MessageContent id={view} setview={setView} />
        </div>
      ) : (
        ""
      )}
      {showReviewModal ? (
        <Modal show={showReviewModal} onHide={(e) => setShowReviewModal(false)}>
          <Modal.Body>
            <SendReview
              setrequest={setShowReviewModal}
              slugdata={providerData && providerData[0] && providerData[0].UID}
              username={
                providerData &&
                providerData[0] &&
                providerData[0].first_name + " " + providerData &&
                providerData[0] &&
                providerData[0].last_name
              }
            />
          </Modal.Body>
        </Modal>
      ) : (
        ""
      )}
      <Modal show={update} onHide={(e) => setUpdate(false)}>
        <Modal.Body>
          <div className="promocode_content login_first search_pop upgd">
            <Link to="" onClick={(e) => setUpdate(false)}>
              +
            </Link>
            <h2>
              {t(
                "post-full-benefits"
              )}
            </h2>
            <img src={window.location.origin + "/images/promo.png"} />
            <p>
              {t(
                "members-message-center"
              )}
            </p>
            <button onClick={(e) => setUpdate(false)} className={"ruf"}>
              <Link to={""}> {t("text-maybe-later")}</Link>
            </button>
            <button onClick={(e) => setUpdate(false)}>
              <Link to={""}>
                <CrownIcon />
                {t("upgrade-now")}
              </Link>
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
