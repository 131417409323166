import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { api } from "../../../urls";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { JobApplicationDetail } from "../JobApplicationDetail";
import { RaffleIcon } from "../../../assets/image/RaffleIcon";
import { RebootIcon } from "../../../assets/image/RebootIcon";
import { ArrowLeftIcon } from "../../../assets/image/ArrowLeftIcon";
import { MONTH, REFINE_OPTIONS } from "../../../utils/variables";

export function JobApplicationParents() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [isCheck, setIsCheck] = useState(true);
  const [notificationData, setNotificationData] = useState([]);

  const [view, setView] = useState("");
  const [deleteList, setdeleteList] = useState([]);
  const [recived, setRecived] = useState("");
  const [isActive, setIsActive] = useState(false);
  const [refine, setRefine] = useState({
    threedays: "",
    withinweek: "",
    twofourday: "",
    withinmonth: "",
  });

  const slugdata = useParams();
  const data = slugdata.name;

  const handleNotificationData = () => {
    setNotificationData([]);
    const myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      api +
        "/api/v1/jobapplicationsnotification?" +
        (refine.threedays != ""
          ? "threedays=" + refine.threedays
          : refine.withinweek != ""
          ? "withinweek=" + refine.withinweek
          : refine.twofourday != ""
          ? "twofourdays=" + refine.twofourday
          : refine.withinmonth != ""
          ? "withinmonth=" + refine.withinmonth
          : ""),
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => setNotificationData(result.data.notifications))
      .catch((error) => console.log("error", error));
  };

  useEffect(() => {
    if (isCheck) {
      handleNotificationData();
      setIsCheck(false);
    }

    console.log(notificationData);
    setView(data ? data : "");
  }, [isCheck, notificationData, data, slugdata]);

  useEffect(() => {
    if (window.innerWidth < 768) {
      setRecived("text-refine");
    }
  }, []);

  const handleSelectElement = () => {
    const ele = document.getElementsByName("chk");
    notificationData.map((data) => {
      deleteList.push(data.id);
    });
    for (let i = 0; i < ele.length; i++) {
      if (ele[i].type == "checkbox") ele[i].checked = true;
    }
  };

  const handleDeselectElement = () => {
    const ele = document.getElementsByName("chk");
    for (let i = 0; i < ele.length; i++) {
      if (ele[i].type == "checkbox") ele[i].checked = false;
    }
    setdeleteList([]);
  };

  const handleDeleteElement = (a) => {
    let sum = false;
    deleteList.map((e) => {
      if (e == a) {
        sum = true;
        const index = deleteList.indexOf(a);
        if (index > -1) {
          deleteList.splice(index, 1);
        }
      }
    });
    if (sum == false) {
      deleteList.push(a);
    }
  };

  const handleDeleteList = () => {
    const myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      ids: deleteList,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(api + "/api/v1/multideletejobapplication", requestOptions)
      .then((response) => response.text())
      .then(() => {
        handleNotificationData();
      })
      .catch((error) => console.log("error", error));
  };

  const handleRefineDay = (e) => {
    setRefine({
      threedays: e == "3" ? true : "",
      withinweek: e == "7" ? true : "",
      twofourday: e == "24" ? true : "",
      withinmonth: e == "30" ? true : "",
    });
    setIsCheck(true);
  };

  return (
    <div>
      {view == "" ? (
        <>
          <div class="main-header">
            <h2 className="border">{t("job-applications")}</h2>
            <div class="mail-header-bar">
              <p>
                {notificationData.length} {t("job-applications")}
              </p>
              <div class="btn-group flex">
                {t("text-refine")}
                <div className="select">
                  <label onClick={(e) => setIsActive(!isActive)}>
                    {t(recived)}
                    <span>
                      <img src="/images/done_a.svg" />
                    </span>
                  </label>
                  {isActive && (
                    <ul>
                      {REFINE_OPTIONS.map((option) => (
                        <li
                          key={option.label}
                          onClick={() => {
                            setRecived(t(option.label));
                            handleRefineDay(option.value);
                            setIsActive(!isActive);
                          }}
                        >
                          {t(option.label)}
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              </div>
            </div>
            <div class="mail-option">
              <div class="chk-all">
                <input
                  type="checkbox"
                  class="mail-radio mail-group-radio"
                  name="chk"
                  id="checkbox_id"
                  onClick={(e) => {
                    if (e.target.checked) {
                      handleSelectElement();
                    } else {
                      handleDeselectElement();
                    }
                  }}
                />
                <span className="circle"></span>
                <label for="checkbox_id">{t("select-all")}</label>
              </div>
              <div class="right-side-btn">
                <div class="delete-btn" onClick={(e) => handleDeleteList()}>
                  <a href="#">
                    <RaffleIcon />
                    <span> {t("text-delete")}</span>
                  </a>
                </div>
                <div
                  class="refresh-btn"
                  onClick={(e) => handleNotificationData()}
                >
                  <a href="#">
                    <RebootIcon />
                  </a>
                  <span>{t("text-refresh")}</span>
                </div>
              </div>
            </div>
          </div>
          <table class="table table-inbox table-hover hovertable free_text">
            <tbody>
              {notificationData.map((data) => {
                return (
                  <tr
                    class={
                      data.jobStatus != 1
                        ? data.read_status == 0 ||
                          (new Date(data.created_at).getDate() >
                          new Date().getDate()
                            ? new Date(data.created_at).getMonth() >
                              new Date().getMonth()
                            : new Date(data.created_at).getDate() >=
                              new Date().getDate() - 7)
                          ? "unread"
                          : data.status == 0
                          ? "unread2"
                          : t("text-close")
                        : t("text-close")
                    }
                  >
                    <td class="view-message  dont-show">
                      <input
                        type="checkbox"
                        class="mail-radio"
                        name="chk"
                        onChange={(e) => handleDeleteElement(data.id)}
                      />
                      <span className="circle"></span>
                      <span
                        onClick={(e) => {
                          navigate(
                            "/search-parents/" + slugdata.id + "/" + data.id
                          );
                          setView(data.id);
                        }}
                      >
                        {data.jobStatus != 1 ? (
                          data.read_status == 0 ||
                          (new Date(data.created_at).getDate() >
                          new Date().getDate()
                            ? new Date(data.created_at).getMonth() >
                              new Date().getMonth()
                            : new Date(data.created_at).getDate() >=
                              new Date().getDate() - 7) ? (
                            <span class="new-tag"> {t("text-new-upper")}</span>
                          ) : data.status == 0 ? (
                            <span class="new-tag"> {t("text-active")}</span>
                          ) : data.status >= 0 ? (
                            <span class="new-tag"> {t("text-closed")}</span>
                          ) : (
                            ""
                          )
                        ) : (
                          <span class="new-tag"> {t("text-closed")}</span>
                        )}
                        <b
                          onClick={(e) => {
                            navigate(
                              "/search-parents/" + slugdata.id + "/" + data.id
                            );
                            setView(data.id);
                          }}
                        >
                          {t("text-congratulations")}
                          <span class="username-text">
                            
                            {data.Appliedusername}
                          </span>
                          {t("applied-your-job-post")}
                        </b>
                        <p> - {data.jobTitle}</p>
                      </span>
                    </td>
                    <td class="view-message  text-right">
                      {MONTH[new Date(data.created_at).getMonth()] +
                        " " +
                        new Date(data.created_at).getDate()}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </>
      ) : (
        <div class="main-header">
          <button
            onClick={(e) => {
              navigate("/search-parents/" + slugdata.id);
              setView("");
            }}
          >
            <ArrowLeftIcon />
            {t("text-back")}
          </button>
          <JobApplicationDetail id={view} />
        </div>
      )}
    </div>
  );
}
