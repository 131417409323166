import React from "react";
import { useTranslation } from "react-i18next";
import Modal from "react-bootstrap/Modal";

import { DEFAULT_API } from "../../../utils/variables";
import { DumpIcon } from "../../../assets/image/DumpIcon";
import { AddIcon } from "../../../assets/image/AddIcon";

export const PhotoUploadModal = ({
  photoUpload,
  setPhotoUpload,
  photo,
  image,
  setPhoto,
  selfProfile,
  handleImageChange,
  handleProfileUpdate,
}) => {
  const { t } = useTranslation();

  return (
    <Modal show={photoUpload} onHide={(e) => setPhotoUpload(false)}>
      <Modal.Body>
        <div className="promocode_content cancelmembership uploadphoto">
          <div className="">
            <p>
              <strong>{t("Upload profile photos")}</strong>
            </p>
            <br />
            <ul>
              <li>
                {photo != "" ? (
                  <>
                    <img src={image} alt="preview image" />
                    <button onClick={(e) => setPhoto("")}>
                      <DumpIcon />
                    </button>
                  </>
                ) : (
                  <>
                    <img
                      src={
                        DEFAULT_API +
                        "/assets/images/users/" +
                        selfProfile.file_path
                      }
                      alt="preview image"
                    />
                  </>
                )}
                <input
                  type={"file"}
                  onChange={handleImageChange}
                  accept="image/*"
                />
              </li>
              <li>
                <AddIcon />
                <button>
                  <DumpIcon />
                </button>
              </li>
            </ul>
            <ol>
              <li>
                <div className="select_photoprofile">
                  <input type="radio" id="photo" name="photo" checked="true" />
                  <span>{t("Set as profile photo")}</span>
                </div>
              </li>
              <li>
                <div className="select_photoprofile">
                  <input type="radio" id="photo" name="photo" />
                  <span>{t("Set as profile photo")}</span>
                </div>
              </li>
            </ol>
            <br />
            <p className="giveus">{t("upload-files")} </p>

            <div className="button text-center">
              <div className="pull-right">
                <button className="btn" onClick={(e) => setPhotoUpload()}>
                  {t("text-close-up")}
                </button>
              </div>
              <div className="pull-right">
                <button
                  className="btn confirm"
                  onClick={(e) => handleProfileUpdate()}
                >
                  {t("Upload photo")}
                </button>
              </div>
            </div>
            <br />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
