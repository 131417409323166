import React from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";

import { JobHistoryParents } from "../../components/Job/JobHistoryParents";
import { JobHistory } from "../../components/Job/JobHistory";
import { SquareServiceIcon } from "../../assets/image/SquareServiceIcon";
import { FavoriteProvider } from "../../components/common/FavoriteProvider";
import { NotificationTabParents } from "../../components/common/NotificationTabParents";
import { NotificationTabProvider } from "../../components/common/NotificationTabProvider";
import { NotificationCount } from "../../components/common/NotificationCount";
import { MessageCount } from "../../components/common/MessageCount";
import { MessageTab } from "../../components/Message/MessageTab";
import { DEFAULT_API } from "../../utils/variables";
import { SocialFacebook } from "../../components/common/SocialFacebook";
import { PhoneIcon } from "../../assets/image/PhoneIcon";
import { ServiceIcon } from "../../assets/image/ServiceIcon";
import { ConfirmIcon } from "../../assets/image/ConfirmIcon";
import { ProfessionChange } from "../../components/common/ProfessionChange";
import { StarIcon } from "../../assets/image/StarIcon";
import { WhiteStarIcon } from "../../assets/image/WhiteStarIcon";
import { GiftIcon } from "../../assets/image/GiftIcon";
import { ServiceMarkIcon } from "../../assets/image/ServiceMarkIcon";
import { LaunchIcon } from "../../assets/image/LaunchIcon";

export const SearchLeftSection = ({
  profile,
  setPhotoUpload,
  setIsSignoutModal,
  setProfileSection,
  subTab,
  setSubTab,
  profileSection
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <>
      <div className="profile_box">
        <div className="profile_box_social">
          <div className="profile_box_social_sec1">
            {localStorage.getItem("user_type") == "parents" ? (
              <SocialFacebook
                link={window.location.origin + "/profile-parents/" + profile.id}
              />
            ) : (
              <SocialFacebook
                link={
                  window.location.origin + "/profile-provider/" + profile.id
                }
              />
            )}
            {profile.phoneVerifiedStatus == 1 ? (
              <img
                src={window.location.origin + "/images/nany_phone.svg"}
                alt=""
              />
            ) : (
              <PhoneIcon />
            )}
            <img src={window.location.origin + "/images/nany_msg.svg"} alt="" />
            {profile.facebookverify != null || profile.linkdinverify != null ? (
              <img
                src={window.location.origin + "/images/nany_cont.svg"}
                alt=""
              />
            ) : (
              <ServiceIcon />
            )}

            {localStorage.getItem("user_type") == "parents" ? (
              ""
            ) : profile.docsStatus == "Yes" ? (
              <img src={window.location.origin + "/images/ok.svg"} alt="" />
            ) : (
              <ConfirmIcon />
            )}
          </div>
          {localStorage.getItem("user_type") == "parents" ? (
            <span className="addjob">
              <ProfessionChange />
            </span>
          ) : (
            <div className="profile_box_social_sec2">
              {profile.service_type && profile.service_type.tab1 == "Nanny" ? (
                <img
                  src={window.location.origin + "/images/nany_pur.svg"}
                  alt=""
                />
              ) : (
                ""
              )}
              {profile.service_type && profile.service_type.tab2 ? (
                <img
                  src={window.location.origin + "/images/special_education.svg"}
                  alt=""
                />
              ) : (
                ""
              )}
              {profile.service_type && profile.service_type.tab3 ? (
                <img
                  src={window.location.origin + "/images/professional.svg"}
                  alt=""
                />
              ) : (
                ""
              )}
              {profile.service_type && profile.service_type.tab4 ? (
                <img
                  src={window.location.origin + "/images/tutorform.svg"}
                  alt=""
                />
              ) : (
                ""
              )}
            </div>
          )}
        </div>
        <div className="profile_pic_sec">
          <img src="img/left_pic.png" alt="" />
          <div className="profile_pic">
            <img
              src={DEFAULT_API + "/assets/images/users/" + profile.file_path}
              width="80"
              height="80"
              alt=""
            />
            <div className="edit_icon">
              <Link to="" onClick={(e) => setPhotoUpload(true)}>
                <img src={window.location.origin + "/images/edit.svg"} alt="" />
              </Link>
            </div>
          </div>
          <img src="img/right_pic.png" alt="" />
        </div>
        <div className="profile_detail">
          <p>
            {profile.first_name + " " + profile.last_name} (
            {profile.dob != undefined
              ? new Date().getFullYear() -
                parseInt(
                  profile.dob
                    .substr(profile.dob.lastIndexOf("\\") + 1)
                    .split("-")[0]
                )
              : ""}
            )
          </p>
          <div className="profilestart_video">
            <a>
              <img src={window.location.origin + "/images/video.svg"} alt="" />
            </a>
            <span className="tooltipu">
              <strong>{t("text-upgrade")}</strong> {t("to post media.")}
            </span>
            {profile.reviewAvg >= 0 ? (
              <>
                {[...Array(profile.reviewAvg)].map((star, index) => {
                  index += 1;
                  return <StarIcon />;
                })}
                {[...Array(5 - profile.reviewAvg)].map((star, index) => {
                  index += 1;
                  return <WhiteStarIcon />;
                })}
              </>
            ) : (
              ""
            )}
            <span>({profile.reviewcount})</span>
          </div>
        </div>
        <div className="profile_functions">
          <div className="members">
            <h5>
              {new Date().getFullYear() -
                new Date(profile.created_at).getFullYear() >
              0
                ? new Date().getFullYear() -
                  new Date(profile.created_at).getFullYear() +
                  " " +
                  t(" YRS")
                : 0 + t(" YRS")}
            </h5>
            <h6>{t("Member")}</h6>
          </div>
          <div className="vi"></div>
          {localStorage.getItem("user_type") == "parents" ? (
            <div className="applications">
              <h5>{profile.jobs}</h5>
              <h6>{t("Job posts")}</h6>
            </div>
          ) : (
            <div className="applications">
              <h5>{profile.jobApplicationcount}</h5>
              <h6>{t("text-applications")}</h6>
            </div>
          )}
          <div className="vi"></div>
          <div className="hiring">
            <h5>{profile.hiringcount}</h5>
            <h6>{t("Hirings")}</h6>
          </div>
        </div>
      </div>
      <div className="silde_bar_dropdowns">
        <button
          type="button"
          className="side_drop_collapse"
          onClick={(e) =>
            setProfileSection(
              profileSection != "notifications" ? "notifications" : ""
            )
          }
        >
          <img
            src={window.location.origin + "/images/notification.svg"}
            alt=""
          />
          <h4>
            {t("Notifications")}
            <span>
              
              (<NotificationCount />)
            </span>
          </h4>
        </button>
        <div
          className="side_drop_collapse_box_content"
          style={
            profileSection == "notifications"
              ? { display: "block" }
              : { display: "none" }
          }
        >
          {localStorage.getItem("user_type") == "parents" ? (
            <NotificationTabParents setsubtab={setSubTab} subtab={subTab} />
          ) : (
            <NotificationTabProvider setsubtab={setSubTab} subtab={subTab} />
          )}
        </div>
        <button
          type="button"
          className="side_drop_collapse"
          onClick={(e) =>
            setProfileSection(profileSection != "message" ? "message" : "")
          }
        >
          <img src={window.location.origin + "/images/message.svg"} alt="" />
          <h4>
            {t("text-messages")}
            <span>
              
              (<MessageCount />)
            </span>
          </h4>
        </button>
        <div
          className="side_drop_collapse_box_content"
          style={
            profileSection == "message"
              ? { display: "block" }
              : { display: "none" }
          }
        >
          <MessageTab setsubtab={setSubTab} subtab={subTab} />
        </div>
        <button
          type="button"
          className="side_drop_collapse"
          onClick={(e) =>
            setProfileSection(
              profileSection != "Job_history" ? "Job_history" : ""
            )
          }
        >
          <img
            src={window.location.origin + "/images/jobnhistory.svg"}
            alt=""
          />
          <h4>{t("Job History and Docs")}</h4>
        </button>
        <div
          className="side_drop_collapse_box_content"
          style={
            profileSection == "Job_history"
              ? { display: "block" }
              : { display: "none" }
          }
        >
          {localStorage.getItem("user_type") == "parents" ? (
            <JobHistoryParents setsubtab={setSubTab} subtab={subTab} />
          ) : (
            <JobHistory setsubtab={setSubTab} subtab={subTab} />
          )}
        </div>
        <button
          type="button"
          className="side_drop_collapse"
          onClick={(e) =>
            setProfileSection(profileSection != "fav" ? "fav" : "")
          }
        >
          <SquareServiceIcon />
          <h4>{t("Recent profile visits")}</h4>
        </button>
        <div
          className="side_drop_collapse_box_content Document_main side_drop_collapse"
          style={
            profileSection == "fav" ? { display: "block" } : { display: "none" }
          }
        >
          <ul
            style={
              profileSection != "" ? { display: "block" } : { display: "none" }
            }
          >
            <li
              onClick={(e) => {
                navigate(
                  localStorage.getItem("user_type") == "parents"
                    ? "/search-parents/who-i-visited"
                    : "/search-providers/who-i-visited"
                );
                window.scrollTo({ top: 0, behavior: "smooth" });
                setSubTab("who-i-visited");
              }}
              className={subTab == "who-i-visited" ? "active " : ""}
            >
              {t("Who I Visited")}
            </li>
            <li
              onClick={(e) => {
                navigate(
                  localStorage.getItem("user_type") == "parents"
                    ? "/search-parents/who-visited-me"
                    : "/search-providers/who-visited-me"
                );
                window.scrollTo({ top: 0, behavior: "smooth" });
                setSubTab("who-visited-me");
              }}
              className={
                subTab == "who-visited-me" ? "active upgrade" : "upgrade"
              }
            >
              {t("Who Visited Me")}
              <span>
                <Link to={""}>{t("upgrade")}</Link>
              </span>
            </li>
          </ul>
        </div>

        {localStorage.getItem("user_type") == "parents" ? (
          <button
            type="button"
            className="side_drop_collapse arrow"
            onClick={(e) => {
              window.scrollTo({ top: 0, behavior: "smooth" });
              setSubTab("all-profile");
              navigate("/search-providers/all-profile");
            }}
          >
            <img src={window.location.origin + "/images/fav.svg"} alt="" />
            <h4>{t("Favorites")}</h4>
          </button>
        ) : (
          <button
            type="button"
            className="side_drop_collapse"
            onClick={(e) =>
              setProfileSection(
                profileSection != "Favorites" ? "Favorites" : ""
              )
            }
          >
            <img src={window.location.origin + "/images/fav.svg"} alt="" />
            <h4>{t("Favorites")}</h4>
          </button>
        )}
        <div
          className="side_drop_collapse_box_content"
          style={
            profileSection == "Favorites"
              ? { display: "block" }
              : { display: "none" }
          }
        >
          {localStorage.getItem("user_type") == "parents" ? (
            <FavoriteProvider setsubtab={setSubTab} subtab={subTab} />
          ) : (
            <FavoriteProvider setsubtab={setSubTab} subtab={subTab} />
          )}
        </div>
        <button
          className={
            subTab == "Loyalty"
              ? "active side_drop_collapse arrow"
              : "side_drop_collapse arrow"
          }
          onClick={(e) => {
            setProfileSection(profileSection != "Loyalty" ? "Loyalty" : "");
            navigate(
              localStorage.getItem("user_type") == "parents"
                ? "/search-parents/Loyalty"
                : "/search-providers/Loyalty"
            );
            window.scrollTo({ top: 0, behavior: "smooth" });
            setSubTab("Loyalty");
          }}
        >
          <GiftIcon />
          <h4>{t("senscare-loyalty")} </h4>
        </button>
        <button
          className={
            subTab == "Reviews"
              ? "active side_drop_collapse arrow"
              : "side_drop_collapse arrow"
          }
          onClick={(e) => {
            navigate(
              localStorage.getItem("user_type") == "parents"
                ? "/search-parents/Reviews"
                : "/search-providers/Reviews"
            );
            window.scrollTo({ top: 0, behavior: "smooth" });
            setSubTab("Reviews");
          }}
        >
          <img src={window.location.origin + "/images/reviewi.svg"} alt="" />
          <h4>{t("Revie")}</h4>
        </button>
        <button
          type="button"
          className="side_drop_collapse"
          onClick={(e) =>
            setProfileSection(profileSection != "setting" ? "setting" : "")
          }
        >
          <img src={window.location.origin + "/images/setting.svg"} alt="" />
          <h4>{t("Settings")}</h4>
        </button>
        <div
          className="side_drop_collapse_box_content"
          style={
            profileSection == "setting"
              ? { display: "block" }
              : { display: "none" }
          }
        >
          <ul>
            <li
              className={subTab == "Account" ? "active" : ""}
              onClick={(e) => {
                window.scrollTo({ top: 0, behavior: "smooth" });
                navigate(
                  localStorage.getItem("user_type") == "parents"
                    ? "/search-parents/Account"
                    : "/search-providers/Account"
                );
              }}
            >
              <ServiceIcon width={16} height={16} color={"#636363"} />
              <span>{t("text-account")}</span>
            </li>
            <li
              className={subTab == "Profile" ? "active" : ""}
              onClick={(e) => {
                window.scrollTo({ top: 0, behavior: "smooth" });
                navigate(
                  localStorage.getItem("user_type") == "parents"
                    ? "/search-parents/Profile"
                    : "/search-providers/Profile"
                );
              }}
            >
              <ServiceMarkIcon />
              <span>{t("view-edit-profile")}</span>
            </li>
            <li
              className={subTab == "SignOut" ? "active" : ""}
              onClick={(e) => setIsSignoutModal(true)}
            >
              <LaunchIcon />
              <span>{t("Sign Out")}</span>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};
