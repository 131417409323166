import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { api } from "../../../urls";
import { useTranslation } from "react-i18next";
import { FavoriteSelect } from "../../common/FavoriteSelect";
import { SocialFacebook } from "../../common/SocialFacebook";
import { PhoneIcon } from "../../../assets/image/PhoneIcon";
import { ServiceIcon } from "../../../assets/image/ServiceIcon";
import { JobCandidate } from "./JobCandidate";

export function JobPosts() {
  const { t } = useTranslation();
  const [favJobData, setFavJobData] = useState([]);
  const [profile] = useState();
  const [isElement, setIsElement] = useState(true);
  const [isCheck, setIsCheck] = useState(true);
  const [resultCount, setResultCount] = useState(10);
  const [generalResultCount, setGeneralResultCount] = useState(resultCount);
  const [recived, setRecived] = useState(t("active-jobs"));
  const [isActive, setIsActive] = useState(false);

  let pages = 0;
  let data_show = (generalResultCount / resultCount - 1) * resultCount;

  const handleElementById = (e, x, z) => {
    console.log(e, x);
    document.getElementById(e).style.display = "none";
    document.getElementById(x).style.display = "block";
    if (isElement) {
      document.getElementById(z).style.display = "block";
      setIsElement(false);
    } else {
      setIsElement(true);
      document.getElementById(z).style.display = "none";
    }
  };

  const handleFavJobData = (a) => {
    setFavJobData([]);
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );
    myHeaders.append("Content-Type", "application/json");

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      api + "/api/v1/favjobslist?job_search=" + (a != undefined ? a : "0"),
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        setFavJobData(result.data.gatData);
        console.log(result);
      })
      .catch((error) => console.log("error", error));
  };

  useEffect(() => {
    if (isCheck) {
      handleFavJobData();
      setIsCheck(false);
    }
    window.scrollTo({ top: 200, behavior: "smooth" });
  }, [isCheck, generalResultCount, resultCount]);

  return (
    <>
      <div class="main-header">
        <h2 className="border">{t("text-posts")}</h2>
        <div class="mail-header-bar">
          <p>
            {favJobData.length} {t("text-posts")}
          </p>
          <div class="btn-group flex">
            {t("text-refine")}
            <div className="select">
              <label onClick={(e) => setIsActive(!isActive)}>
                {t(recived)}
                <span>
                  <img src="/images/done_a.svg" />
                </span>
              </label>
              {isActive ? (
                <ul>
                  <li
                    onClick={(e) => {
                      handleFavJobData("0");
                      setRecived(t("active-jobs"));
                      setIsActive(!isActive);
                    }}
                  >
                    {t("active-jobs")}
                  </li>

                  <li
                    onClick={(e) => {
                      handleFavJobData("1");
                      setRecived(t("closed-jobs"));
                      setIsActive(!isActive);
                    }}
                  >
                    {t("closed-jobs")}
                  </li>
                </ul>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
        <div class="right_side_section">
          <div class="looking_for_candidate job_post">
            {favJobData.map((data, index) => {
              if (index < generalResultCount && data_show <= index) {
                return (
                  <div class="looking_for_candidate_boxs">
                    <div class="looking_for_candidate_box">
                      <div class="first_sec">
                        <div class="image_sec">
                          <img
                            src={
                              data.file_path != null
                                ? api + "/assets/images/users/" + data.file_path
                                : window.location.origin + "/img/nany_img.png"
                            }
                            alt=""
                          />
                          <div class="heart_sec">
                            <FavoriteSelect
                              id={data.jid}
                              heart={handleFavJobData}
                              username={data.first_name}
                            />
                          </div>
                        </div>
                        <div className="nany_social">
                          {data.phoneVerifiedStatus == 1 ? (
                            <img
                              src={
                                window.location.origin +
                                "/images/nany_phone.svg"
                              }
                              alt=""
                            />
                          ) : (
                            <PhoneIcon />
                          )}
                          <img
                            src={
                              window.location.origin + "/images/nany_msg.svg"
                            }
                            alt=""
                          />
                          {data.facebookverify != null ||
                          data.linkdinverify != null ? (
                            <img
                              src={
                                window.location.origin + "/images/nany_cont.svg"
                              }
                              alt=""
                            />
                          ) : (
                            <ServiceIcon />
                          )}
                        </div>
                      </div>
                      <div class="second_sec">
                        <div class="heading">
                          <h3>
                            <Link to={"/profile-parents/" + data.user_id}>
                              {data.title != null ? data.title : ""}
                            </Link>
                          </h3>
                          {data.plateformonsocialmedia == "Yes" ? (
                            <SocialFacebook
                              link={
                                window.location.origin +
                                "/profile-parents/" +
                                data.user_id
                              }
                            />
                          ) : (
                            ""
                          )}
                        </div>
                        <div class="post_general">
                          <h6>{data.school != null ? data.school : ""}</h6>
                          <p>
                            <img
                              src={
                                window.location.origin +
                                "/images/family_star.svg"
                              }
                              alt=""
                            />
                            <span> (3)</span>
                          </p>
                        </div>
                        <div class="post_detail">
                          <div class="post">
                            {JSON.parse(data.service_type) != null ? (
                              Object.values(JSON.parse(data.service_type))[0] ==
                              "Nanny" ? (
                                <img
                                  src={
                                    window.location.origin +
                                    "/images/nany_post.svg"
                                  }
                                  alt=""
                                />
                              ) : Object.keys(
                                  JSON.parse(data.service_type)
                                )[0] == "tab2" ? (
                                <img
                                  src={
                                    window.location.origin +
                                    "/images/teacher_post.svg"
                                  }
                                  alt=""
                                />
                              ) : Object.keys(
                                  JSON.parse(data.service_type)
                                )[0] == "tab3" ? (
                                <img
                                  src={
                                    window.location.origin +
                                    "/images/education_post.svg"
                                  }
                                  alt=""
                                />
                              ) : Object.keys(
                                  JSON.parse(data.service_type)
                                )[0] == "tab4" ? (
                                <img
                                  src={
                                    window.location.origin +
                                    "/images/tutor_post.svg"
                                  }
                                  alt=""
                                />
                              ) : (
                                ""
                              )
                            ) : (
                              ""
                            )}
                            <h5>
                              {JSON.parse(data.service_type) != null
                                ? Object.values(
                                    JSON.parse(data.service_type)
                                  )[0]
                                : ""}
                            </h5>
                          </div>
                          <div class="vi"></div>

                          <div class="post_pay">
                            <img
                              src={window.location.origin + "/img/post_pay.png"}
                              alt=""
                            />
                            {profile && profile.country == "Serbia" ? (
                              <h5>
                                {data.nanyperhrrate != null
                                  ? data.nanyperhrrate
                                      .substr(
                                        data.nanyperhrrate.lastIndexOf("\\") + 1
                                      )
                                      .split("-")[0] *
                                      100 +
                                    " - " +
                                    data.nanyperhrrate
                                      .substr(
                                        data.nanyperhrrate.lastIndexOf("\\") + 1
                                      )
                                      .split("-")[1] *
                                      100
                                  : data.tutorperhrrate
                                  ? data.tutorperhrrate
                                      .substr(
                                        data.tutorperhrrate.lastIndexOf("\\") +
                                          1
                                      )
                                      .split("-")[0] *
                                      100 +
                                    " - " +
                                    data.tutorperhrrate
                                      .substr(
                                        data.tutorperhrrate.lastIndexOf("\\") +
                                          1
                                      )
                                      .split("-")[1] *
                                      100
                                  : ""}
                                {t("time-hour")}
                              </h5>
                            ) : (
                              <h5>
                                $
                                {data.nanyperhrrate != null
                                  ? data.nanyperhrrate
                                  : data.tutorperhrrate
                                  ? data.tutorperhrrate
                                  : ""}
                                {t("time-hour")}
                              </h5>
                            )}
                          </div>

                          <div class="vi"></div>
                          <div class="post_fet">
                            <img
                              src={
                                window.location.origin + "/images/post_fet.svg"
                              }
                              alt=""
                            />
                            <h5>
                              {data.tutorintrestedin != null
                                ? data.tutorintrestedin
                                : data.nanyintrestedin}
                            </h5>
                          </div>
                          <div class="vi"></div>
                          <div class="post_cal">
                            <img
                              src={
                                window.location.origin + "/images/post_cal.svg"
                              }
                              alt=""
                            />
                            <h5>
                              {data.tutorstartdate != null
                                ? data.tutorstartdate
                                : data.nanystartdate}
                            </h5>
                          </div>
                          <div class="vi"></div>
                          <div class="post_loc">
                            <img
                              src={
                                window.location.origin + "/images/post_loc.svg"
                              }
                              alt=""
                            />
                            <h5>
                              {data.country != null ? data.country : ""} ,
                              {data.city != null ? data.city : ""}
                            </h5>
                          </div>
                        </div>
                        <p id={"half" + index}>
                          {data.description != null
                            ? data.description.substr(0, 100)
                            : ""}
                          {data.description != null &&
                          data.description.length > 100 ? (
                            <span
                              onClick={(e) =>
                                handleElementById(
                                  "half" + index,
                                  "full" + index,
                                  "plink" + index
                                )
                              }
                            >
                              {t("text-more")}
                            </span>
                          ) : (
                            ""
                          )}
                        </p>
                        <p id={"full" + index} className="hide">
                          {data.description}
                          <span
                            onClick={(e) =>
                              handleElementById(
                                "full" + index,
                                "half" + index,
                                "plink" + index
                              )
                            }
                          >
                            {t("text-less")}
                          </span>
                        </p>
                      </div>

                      <div
                        class="view_profile_btn newadd"
                        style={{ float: "right" }}
                      >
                        <Link to={"/profile-parents/" + data.user_id}>
                          {t("visit-profile")}
                        </Link>
                      </div>
                    </div>
                  </div>
                );
              }
            })}
            <div className="mobile">
              <div class="mobile_looking_for_candidate_boxs">
                {favJobData.map((data, index) => {
                  if (index < generalResultCount && data_show <= index) {
                    return (
                      <JobCandidate
                        data={data}
                        index={index}
                        profile={profile}
                        handleElementById={handleElementById}
                        handleFavJobData={handleFavJobData}
                      />
                    );
                  }
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer_pagination pagi_post">
        <div class="btn-group">
          {t("result-page")}
          <select
            onChange={(e) => {
              setResultCount(parseInt(e.target.value));
              setGeneralResultCount(parseInt(e.target.value));
            }}
          >
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="30">30</option>
            <option value="40">40</option>
            <option value="50">50</option>
          </select>
        </div>
        <div className="pagination">
          <span
            className="curserpage"
            onClick={(e) => setGeneralResultCount(resultCount)}
          >
            {"<<"}
          </span>
          <span
            className="curserpage"
            onClick={(e) =>
              setGeneralResultCount(
                favJobData.length > 0 && generalResultCount > resultCount
                  ? generalResultCount - resultCount
                  : resultCount
              )
            }
          >
            {"<"}
          </span>

          {favJobData?.map((data, index) => {
            if (favJobData.length > pages) {
              pages = pages + resultCount;
              return (
                <span
                  onClick={(e) =>
                    setGeneralResultCount((index + 1) * resultCount)
                  }
                  className={generalResultCount == pages ? "active" : ""}
                >
                  {index + 1}
                </span>
              );
            }
          })}
          <span
            className="curserpage"
            onClick={(e) =>
              setGeneralResultCount(
                favJobData.length > generalResultCount
                  ? generalResultCount + resultCount
                  : generalResultCount
              )
            }
          >
            {">"}
          </span>
          <span
            className="curserpage"
            onClick={(e) => setGeneralResultCount(pages)}
          >
            {">>"}
          </span>
        </div>
      </div>
    </>
  );
}
