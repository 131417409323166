import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export const SafetyFirstTab = ({ selectTab }) => {
  const { t } = useTranslation();

  return (
    <div className={"tab1 " + (selectTab == "tab1" ? "active" : "")}>
      <div className="provider_tabing">
        <div className="container">
          <div className="contact safety_tips">
            <h3>{t("Safety tips at glance")}</h3>
            <ul>
              <li>
                <img src="./images/verify.svg" />
                <h4>
                  {t("Check if the")} <br /> {t("profile is")}
                  <span>{t(" verified")}</span>
                </h4>
              </li>
              <li>
                <img src="./images/sreview.svg" />
                <h4>
                  {t("Check")} <br />
                  <span>{t("reviews")}</span>
                </h4>
              </li>
              <li>
                <img src="./images/sinterview.svg" />
                <h4>
                  {t("Prepare for an online")} <span>{t("interview")}</span>
                  {t("or a meeting in a public space")}
                </h4>
              </li>
              <li>
                <img src="./images/probation.svg" />
                <h4>
                  {t("Consider")} <br />
                  <span>{t("probation period")}</span>
                </h4>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="more_tips contact">
          <h3>{t("See here for more details on Safety tips")}</h3>
          <ul>
            <li>
              <img src="./images/verify_pur.svg" />
              <div className="morecontent">
                <h5>
                  {t("1st-step")}
                  <span>
                    <Link
                      to="/faq"
                      onClick={(e) => {
                        window.location.hash = "provider";
                        localStorage.setItem(
                          "faq",
                          t(
                            "I have found a great job post, how can I apply for it?"
                          )
                        );
                      }}
                    >
                      {t("Choose family/school")}
                    </Link>
                  </span>
                </h5>
                <p>
                  {t("firstImportant")}
                  <Link
                    to="/faq"
                    onClick={(e) => {
                      window.location.hash = "provider";
                      localStorage.setItem(
                        "faq",
                        t(
                          "I have found a great job post, how can I apply for it?"
                        )
                      );
                    }}
                  >
                    {t("apply for jobs")}
                  </Link>
                  {t(
                    "request-step-interview-employeer"
                  )}
                </p>
              </div>
            </li>
            <li>
              <img src="./images/rating_pur.svg" />
              <div className="morecontent">
                <h5>
                  {t("2st-step")} <span>{t("Check reviews")}</span>
                </h5>
                <p>{t("checkReviews")}</p>
              </div>
            </li>
            <li>
              <img src="./images/interview_pur.svg" />
              <div className="morecontent">
                <h5>
                  {t("3rd-step")} <span>{t("Interview steps")}</span>
                </h5>
                <p>
                  {t("nextChoose")}
                  <br />
                  {t("youCanShare")}
                  <br />
                  {t(
                    "prospective-employeer"
                  )}
                </p>
              </div>
            </li>
          </ul>
          <ul>
            <li>
              <img src="./images/finger_pur.svg" />
              <div className="morecontent">
                <h5>
                  {t("4th-step")}
                  <span>{t("Get to know an employer better.")}</span>
                </h5>
                <p>{t("getToKnow")}</p>
              </div>
            </li>

            <li className="inner">
              <img src="./images/int_pup.svg" />
              <div className="morecontent">
                <h3>{t("Interview questions")}</h3>
                <p>
                  {t("about-family-school")}
                  <br />
                  {t("specific-duties")}
                  <br />
                  {t(
                    "expectations-ideal-candidate"
                  )}
                </p>
              </div>
            </li>
            <li>
              <img src="./images/probation_pur.svg" />
              <div className="morecontent">
                <h5>
                  {t("5th-step")} <span>{t("Probation period")}</span>
                </h5>
                <p>{t("beforeOfficial")}</p>
                <img src="./images/thank_banner.png" />
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};
