import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Modal from "react-bootstrap/Modal";

import { api } from "../../urls";
import { Footer } from "../../components/common/Footer";
import { Header } from "../../components/common/Header";
import { MembershipPlans } from "./MembershipPlans";

import { useAdditionalDetailsStore } from "../../store/useAdditionalDetailsStore";
import { MembershipSelectPlan } from "./MembershipSelectPlan";

export function ProviderMembershipPage() {
  const { t } = useTranslation();

  const { step, month, setMonth, country } = useAdditionalDetailsStore();

  const [isCheck, setIsCheck] = useState(true);
  const [detail, setDetail] = useState("");
  const [membershipPlan, setMembershipPlan] = useState("");
  const [completePrice, setCompletePrice] = useState(0);
  const [generalCompletePrice, setGeneralCompletePrice] = useState(0);

  const [checkPlan, setCheckPlan] = useState({
    month: "",
    plan: "",
  });
  const [showLoginModal, setShowLoginModal] = useState(false);

  const handleCloseLoginModal = () => {
    setShowLoginModal(false);
  };
  const handleRedirectPage = () => {
    window.location.href = "/search-parents";
  };

  useEffect(() => {
    if (isCheck) {
      handleProfileData();
      setIsCheck(false);
      handleMembershipData();
    }
    console.log(country);
  }, [detail, month, country]);

  const handleCountPrice = () => {
    setTimeout(() => {
      let planprice =
        membershipPlan == "Hummingbird"
          ? 0
          : membershipPlan == "Swan"
          ? detail.country == "Serbia"
            ? 12.75
            : 11.9
          : membershipPlan == "Flamingo"
          ? 16
          : "";
      let monthprice = planprice * month;
      setCompletePrice(monthprice);
    }, 500);
  };

  const handleGeneralCountPrice = () => {
    setTimeout(() => {
      let planprice =
        membershipPlan == "Hummingbird"
          ? 0
          : membershipPlan == "Swan"
          ? 14
          : membershipPlan == "Flamingo"
          ? 20
          : "";
      let monthprice = planprice * month;
      setGeneralCompletePrice(monthprice);
    }, 500);
  };

  const handleProfileData = () => {
    const myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(api + "/api/v1/details", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setDetail(result.data);
        console.log(result);
      })
      .catch((error) => console.log("error", error));
  };

  const handleMembershipData = () => {
    if (localStorage.getItem("user_type") == "provider") {
      const myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Bearer " + localStorage.getItem("token")
      );
      const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      fetch(api + "/api/v1/getmembershipplan", requestOptions)
        .then((response) => response.json())
        .then((result) => {
          if (result.data && result.data.usertype == "provider") {
            setMonth(parseInt(result.data.month));

            setMembershipPlan(result.data.name);
            setCheckPlan({
              month: parseInt(result.data.month),
              plan: result.data.name,
            });
          }
          console.log(result);
        })
        .catch((error) => console.log("error", error));
    }
  };

  const handleMembershipUpdate = () => {
    const myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );
    myHeaders.append("Content-Type", "application/json");
    const raw = JSON.stringify({
      price: completePrice,
      name: membershipPlan,
      month: month,
      iep: 1,
    });
    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(api + "/api/v1/savemembershipplan", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        alert("done");
        console.log(result);
        if (
          (checkPlan.month != "" && checkPlan.month != month) ||
          (checkPlan.plan != "" && checkPlan.plan != membershipPlan)
        ) {
          setShowLoginModal(true);
        }
      })
      .catch((error) => console.log("error", error));
  };
  return (
    <>
      <Header />
      <div class="container-fluid">
        <MembershipPlans
          membershipPlan={membershipPlan}
          setMembershipPlan={setMembershipPlan}
          handleCountPrice={handleCountPrice}
          handleGeneralCountPrice={handleGeneralCountPrice}
          detail={detail}
        />

        {step === 2 && (
          <MembershipSelectPlan
            membershipPlan={membershipPlan}
            generalCompletePrice={generalCompletePrice}
            handleMembershipUpdate={handleMembershipUpdate}
            completePrice={completePrice}
            detail={detail}
          />
        )}
      </div>
      <Footer />
      <Modal show={showLoginModal} onHide={handleCloseLoginModal}>
        <Modal.Body>
          <div className="promocode_content login_first">
            <Link to="" onClick={handleCloseLoginModal}>
              +
            </Link>
            <h2>{t("Congratulations on your new membership plan")}</h2>
            <img src="./images/create_profile.png" />
            <p className="pro" style={{ color: "#636363" }}>
              {t("It’s time to find your perfect job!")}
            </p>
            <button onClick={handleRedirectPage}>{t("Search for jobs")}</button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
