import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Modal from "react-bootstrap/Modal";

export const DisableAccountModal = ({
  isDisableAccountModal,
  setIsDisableAccountModal,
  handleDisableAccount,
}) => {
  const { t } = useTranslation();

  return (
    <Modal
      show={isDisableAccountModal}
      onHide={(e) => setIsDisableAccountModal(false)}
    >
      <Modal.Body>
        <div className="promocode_content cancelmembership">
          <Link to="" onClick={(e) => setIsDisableAccountModal(false)}>
            +
          </Link>
          <h2>
            <img src="/images/warning.svg" />
            <br />
            {t("Disable Account")}
          </h2>
          <div className="cancelmembershipp">
            <p>
              {t(
                "profile-temporary-hold"
              )}
            </p>
            <div className="button text-center">
              <div className="pull-right">
                <button className="btn" onClick={(e) => handleDisableAccount()}>
                  {t("Disable")}
                </button>
              </div>
              <div className="pull-right">
                <button
                  className="btn confirm"
                  onClick={(e) => setIsDisableAccountModal(false)}
                >
                  {t("text-cancel")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
