import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { useAdditionalDetailsStore } from "../../store/useAdditionalDetailsStore";
import { PayIcon } from "../../assets/image/PayIcon";

export const MembershipPlans = ({
  membershipPlan,
  setMembershipPlan,
  handleCountPrice,
  handleGeneralCountPrice,
  detail,
}) => {
  const { t } = useTranslation();

  const [error, setError] = useState({
    month: "",
    plan: "",
  });
  const [mobile, setMobile] = useState("");

  const { step, setStep, month, setMonth } = useAdditionalDetailsStore();

  return (
    <div
      class="container"
      style={step == 1 ? { display: "block" } : { display: "none" }}
    >
      <div class="member_ship">
        <h2>{t("Membership Plans")}</h2>
        <p>
          {t(
            "create-free-account"
          )}
        </p>
        <div
          class="time_btns"
          onClick={(e) => setError({ ...error, month: "" })}
        >
          <button
            onClick={(e) => setMonth(1)}
            className={month == 1 ? "active" : ""}
          >
            <span className={error.month != "" ? "bordererror" : ""}>
              {t("text-monthly")}
            </span>
          </button>
          <button
            onClick={(e) => setMonth(3)}
            className={month == 3 ? "active" : ""}
          >
            <span className={error.month != "" ? "bordererror" : ""}>
              {t("month-3")}
            </span>
          </button>
          <button
            onClick={(e) => setMonth(6)}
            className={month == 6 ? "active" : ""}
          >
            <span className={error.month != "" ? "bordererror" : ""}>
              {t("month-6")}
            </span>
          </button>
        </div>
      </div>
      <div class="member_ship_table">
        <div class="table_head">
          <p>{t("*All prices are on monthly basis.")}</p>
        </div>
        <div class="mobile_collap">
          <button
            type="button"
            className={
              membershipPlan == "Hummingbird"
                ? "active collapsible"
                : "collapsible"
            }
            onClick={(e) => setMobile("Hummingbird")}
          >
            <div class="price_content">
              <img src="img/bird.png" alt="bird" />
              <div class="price_under_content one">
                <h2>{t("Hummingbird")}</h2>
                <h3>{t("text-free")}</h3>
              </div>
            </div>
          </button>
          <div class={mobile == "Hummingbird" ? "content active" : "content"}>
            <ul className={membershipPlan == "Hummingbird" ? "active" : ""}>
              <li>{t("create-profile")}</li>
              <li>{t("Post up to 1 photo")}</li>
              <li>{t("Browse job posts")}</li>
              <li>
                {t("Apply for jobs")} <span>UNLIMITED</span>
              </li>
            </ul>
            <div
              class={
                error.plan != "" ? "borderred select_button" : "select_button"
              }
              onClick={(e) => setError({ ...error, plan: "" })}
            >
              <button
                onClick={(e) => {
                  setMembershipPlan("Hummingbird");
                }}
                className={membershipPlan == "Hummingbird" ? "active" : ""}
              >
                {t("Select")}
              </button>
            </div>
          </div>
          <div class="choose">
            <p>{t("best choice")}</p>
            <p class="choose_save">{t("discount-procent")}</p>
          </div>
          <button
            type="button"
            className={
              membershipPlan == "Swan" ? "active collapsible" : "collapsible"
            }
            onClick={(e) => setMobile("Swan")}
          >
            <div class="price_content">
              <img src="img/swan.png" alt="bird" />
              <div class="price_under_content two">
                <h2>{t("Swan")}</h2>
                {detail.country == "Serbia" ? (
                  <>
                    <h3>
                      <PayIcon width={12} height={10} color={"#fff"} />
                      1275
                    </h3>
                    <h4>
                      <PayIcon width={12} height={10} color={"#fff"} />
                      1500
                    </h4>
                  </>
                ) : (
                  <>
                    <h3>$11.9</h3>
                    <h4>$14</h4>
                  </>
                )}
              </div>
            </div>
          </button>
          <div class={mobile == "Swan" ? "content active" : "content"}>
            <ul className={membershipPlan == "Swan" ? "active" : ""}>
              <li>{t("create-profile")}</li>
              <li>{t("Post up to 2 photo")}</li>
              <li>{t("Browse job posts")}</li>
              <li>
                {t("Apply for jobs")} <span>{t("text-unlimited")}</span>
              </li>
              <li>
                {t("Connect with schools/families")}
                <span>{t("text-unlimited")}</span>
              </li>
              <li>
                {t(
                  "share-documents-with-options"
                )}
              </li>
              <li>{t("Rate and review family/schools")}</li>
            </ul>
            <div
              class={
                error.plan != "" ? "borderred select_button" : "select_button"
              }
              onClick={(e) => setError({ ...error, plan: "" })}
            >
              <button
                onClick={(e) => {
                  setMembershipPlan("Swan");
                }}
                className={membershipPlan == "Swan" ? "active" : ""}
              >
                {t("Select")}
              </button>
            </div>
          </div>
          <div class="choose">
            <p>{t("excellent-choice")}</p>
            <p class="choose_save">{t("save-procent-20")}</p>
          </div>
          <button
            type="button"
            className={
              membershipPlan == "Flamingo"
                ? "active collapsible"
                : "collapsible"
            }
            onClick={(e) => setMobile("Flamingo")}
          >
            <div class="price_content ">
              <img src="img/flam.png" alt="bird" />
              <div class="price_under_content three">
                <h2>{t("Flamingo")}</h2>
                {detail.country == "Serbia" ? (
                  <>
                    <h3>
                      <PayIcon width={12} height={10} color={"#fff"} />
                      1600
                    </h3>
                    <h4>
                      <PayIcon width={12} height={10} color={"#fff"} />
                      2000
                    </h4>
                  </>
                ) : (
                  <>
                    <h3>$16</h3>
                    <h4>$20</h4>
                  </>
                )}
              </div>
            </div>
          </button>
          <div class={mobile == "Flamingo" ? "content active" : "content"}>
            <ul className={membershipPlan == "Flamingo" ? "active" : ""}>
              <li>{t("create-profile")}</li>
              <li>{t("Post up to 2 photos and 1 video")}</li>
              <li>{t("Browse job posts")}</li>
              <li>
                {t("Apply for jobs")}
                <span>{t("text-unlimited")}</span>
              </li>
              <li>
                {t("Connect with schools/families")}
                <span>{t("text-unlimited")}</span>
              </li>
              <li>
                {t(
                  "share-documents-with-options"
                )}
              </li>
              <li>{t("Rate and review family/schools")}</li>
              <li>{t("appear-higher-results")}</li>
              <li>{t("see-who-view-profile")}</li>
              <li>{t("Notifications for matching jobs in your area")}</li>
            </ul>
            <div
              class={
                error.plan != "" ? "borderred select_button" : "select_button"
              }
              onClick={(e) => setError({ ...error, plan: "" })}
            >
              <button
                onClick={(e) => {
                  setMembershipPlan("Flamingo");
                }}
                className={membershipPlan == "Flamingo" ? "active" : ""}
              >
                {t("Select")}
              </button>
            </div>
          </div>
        </div>
        <table id="customer_tabel">
          <tr>
            <th class="blank"></th>
            <td class="blank"></td>
            <td class={membershipPlan == "Swan" ? "choose active" : "choose"}>
              <p>{t("best choice")}</p>
              <p class="choose_save">{t("discount-procent")}</p>
            </td>
            <td
              class={membershipPlan == "Flamingo" ? "choose active" : "choose"}
            >
              <p>{t("excellent-choice")}</p>
              <p class="choose_save">{t("save-procent-20")}</p>
            </td>
          </tr>
          <tr class="plan">
            <th class="blank"></th>
            <td
              class={membershipPlan == "Hummingbird" ? "price active" : "price"}
            >
              <div class="price_content">
                <img src="img/bird.png" alt="bird" />
                <div class="price_under_content">
                  <h2>{t("Hummingbird")}</h2>
                  <h3>{t("text-free")}</h3>
                </div>
              </div>
            </td>
            <td class={membershipPlan == "Swan" ? "price active" : "price"}>
              <div class="price_content">
                <img src="img/swan.png" alt="bird" />
                <div class="price_under_content">
                  <h2>{t("Swan")}</h2>
                  {detail.country == "Serbia" ? (
                    <>
                      <h3>
                        <PayIcon width={12} height={10} color={"#fff"} />
                        1275
                      </h3>
                      <h4>
                        <PayIcon width={12} height={10} color={"#fff"} />
                        1500
                      </h4>
                    </>
                  ) : (
                    <>
                      <h3>$11.9</h3>
                      <h4>$14</h4>
                    </>
                  )}
                </div>
              </div>
            </td>
            <td class={membershipPlan == "Flamingo" ? "price active" : "price"}>
              <div class="price_content">
                <img src="img/flam.png" alt="bird" />
                <div class="price_under_content">
                  <h2>{t("Flamingo")}</h2>
                  {detail.country == "Serbia" ? (
                    <>
                      <h3>
                        <PayIcon width={12} height={10} color={"#fff"} />
                        1600
                      </h3>
                      <h4>
                        <PayIcon width={12} height={10} color={"#fff"} />
                        2000
                      </h4>
                    </>
                  ) : (
                    <>
                      <h3>$16</h3>
                      <h4>$20</h4>
                    </>
                  )}
                </div>
              </div>
            </td>
          </tr>
          <tr class="plan">
            <th>{t("create-profile")}</th>
            <td class={membershipPlan == "Hummingbird" ? " active" : ""}>
              <img src="img/vector.png" alt="Tick" />
            </td>
            <td class={membershipPlan == "Swan" ? " active" : ""}>
              <img src="img/vector.png" alt="Tick" />
            </td>
            <td class={membershipPlan == "Flamingo" ? " active" : ""}>
              <img src="img/vector.png" alt="Tick" />
            </td>
          </tr>
          <tr class="plan">
            <th>{t("post-photos-videos")}</th>
            <td class={membershipPlan == "Hummingbird" ? " active" : ""}>
              {t("up-to-1-photo")}
            </td>
            <td class={membershipPlan == "Swan" ? " active" : ""}>
              {t("up-to-2-photos")}
            </td>
            <td class={membershipPlan == "Flamingo" ? " active" : ""}>
              {t("up-to-2-photo-1-video")}
            </td>
          </tr>
          <tr class="plan">
            <th>{t("Browse job posts")}</th>
            <td class={membershipPlan == "Hummingbird" ? " active" : ""}>
              <img src="img/vector.png" alt="Tick" />
            </td>
            <td class={membershipPlan == "Swan" ? " active" : ""}>
              <img src="img/vector.png" alt="Tick" />
            </td>
            <td class={membershipPlan == "Flamingo" ? " active" : ""}>
              <img src="img/vector.png" alt="Tick" />
            </td>
          </tr>
          <tr class="plan">
            <th>{t("Apply for jobs")}</th>
            <td class={membershipPlan == "Hummingbird" ? " active" : ""}>
              {t("text-unlimited")}
            </td>
            <td class={membershipPlan == "Swan" ? " active" : ""}>
              {t("text-unlimited")}
            </td>
            <td class={membershipPlan == "Flamingo" ? " active" : ""}>
              {t("text-unlimited")}
            </td>
          </tr>
          <tr class="plan">
            <th>{t("Connect with schools/families")}</th>
            <td class={membershipPlan == "Hummingbird" ? " active" : ""}></td>
            <td class={membershipPlan == "Swan" ? " active" : ""}>
              {t("text-unlimited")}
            </td>
            <td class={membershipPlan == "Flamingo" ? " active" : ""}>
              {t("text-unlimited")}
            </td>
          </tr>
          <tr class="plan">
            <th>
              {t("Share your documents")} <br /> (
              {t("CV/recommendations/background check")})
            </th>
            <td class={membershipPlan == "Hummingbird" ? " active" : ""}></td>
            <td class={membershipPlan == "Swan" ? " active" : ""}>
              <img src="img/vector.png" alt="Tick" />
            </td>
            <td class={membershipPlan == "Flamingo" ? " active" : ""}>
              <img src="img/vector.png" alt="Tick" />
            </td>
          </tr>
          <tr class="plan">
            <th>{t("Rate and review family/schools")}</th>
            <td class={membershipPlan == "Hummingbird" ? " active" : ""}></td>
            <td class={membershipPlan == "Swan" ? " active" : ""}>
              <img src="img/vector.png" alt="Tick" />
            </td>
            <td class={membershipPlan == "Flamingo" ? " active" : ""}>
              <img src="img/vector.png" alt="Tick" />
            </td>
          </tr>
          <tr class="plan">
            <th>{t("appear-higher-results")}</th>
            <td class={membershipPlan == "Hummingbird" ? " active" : ""}></td>
            <td class={membershipPlan == "Swan" ? " active" : ""}></td>
            <td class={membershipPlan == "Flamingo" ? " active" : ""}>
              <img src="img/vector.png" alt="Tick" />
            </td>
          </tr>
          <tr class="plan">
            <th>{t("see-who-view-profile")}</th>
            <td class={membershipPlan == "Hummingbird" ? " active" : ""}></td>
            <td class={membershipPlan == "Swan" ? " active" : ""}></td>
            <td class={membershipPlan == "Flamingo" ? " active" : ""}>
              <img src="img/vector.png" alt="Tick" />
            </td>
          </tr>
          <tr class="plan">
            <th>{t("Notifications for matching jobs in your area")}</th>
            <td class={membershipPlan == "Hummingbird" ? " active" : ""}></td>
            <td class={membershipPlan == "Swan" ? " active" : ""}></td>
            <td class={membershipPlan == "Flamingo" ? " active" : ""}>
              <img src="img/vector.png" alt="Tick" />
            </td>
          </tr>

          <tr
            class="select_btn"
            onClick={(e) => setError({ ...error, plan: "" })}
          >
            <th></th>
            <td class={membershipPlan == "Hummingbird" ? " active" : ""}>
              <div
                class={
                  error.plan != "" ? "borderred select_button" : "select_button"
                }
              >
                <button
                  onClick={(e) => {
                    setMembershipPlan("Hummingbird");
                  }}
                  className={membershipPlan == "Hummingbird" ? "active" : ""}
                >
                  {t("Select")}
                </button>
              </div>
            </td>
            <td class={membershipPlan == "Swan" ? " active" : ""}>
              <div
                class={
                  error.plan != "" ? "borderred select_button" : "select_button"
                }
              >
                <button
                  onClick={(e) => {
                    setMembershipPlan("Swan");
                  }}
                  className={membershipPlan == "Swan" ? "active" : ""}
                >
                  {t("Select")}
                </button>
              </div>
            </td>
            <td class={membershipPlan == "Flamingo" ? " active" : ""}>
              <div
                class={
                  error.plan != "" ? "borderred select_button" : "select_button"
                }
              >
                <button
                  onClick={(e) => {
                    setMembershipPlan("Flamingo");
                  }}
                  className={membershipPlan == "Flamingo" ? "active" : ""}
                >
                  {t("Select")}
                </button>
              </div>
            </td>
          </tr>
        </table>
      </div>
      <div class="form_submit_button">
        <div class="button">
          <button
            class="can"
            onClick={(e) => {
              if (membershipPlan && month) {
                setStep(2);
                window.scrollTo({ top: 0 });
                handleCountPrice();
                handleGeneralCountPrice();
              } else {
                window.scrollTo({ top: 0 });
                setError({
                  month: month == "" ? "required" : "",
                  plan: membershipPlan == "" ? "required" : "",
                });
              }
            }}
          >
            {t("text-next")}
          </button>
        </div>
      </div>
    </div>
  );
};
