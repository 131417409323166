export const NannySecondaryIcon = () => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.62288 4.77497V0.608626C6.62288 0.241362 6.94799 -0.047633 7.30924 0.00655353C9.64527 0.337694 11.4394 2.34862 11.4394 4.77497H6.62288ZM11.4393 5.3769C11.4393 6.48471 11.06 7.50823 10.4279 8.32103C10.9818 8.81473 11.2768 9.5914 11.0661 10.4283C10.8734 11.1869 10.2472 11.789 9.48262 11.9515C8.23031 12.2164 7.10444 11.3615 6.94188 10.1935H5.68957C5.52701 11.3675 4.39512 12.2224 3.13077 11.9455C2.37216 11.7769 1.74601 11.1688 1.56538 10.4102C1.32456 9.40476 1.81223 8.46553 2.61299 8.03806C2.47451 7.82733 1.3366 5.3769 1.3366 5.3769H0.602071C0.270932 5.3769 0 5.10597 0 4.77483C0 4.4437 0.270932 4.17276 0.602071 4.17276H1.72192C1.95071 4.17276 2.16746 4.30522 2.26379 4.51594L2.6732 5.3769H11.4393ZM3.61283 10.7954C3.11311 10.7954 2.70973 10.392 2.70973 9.89226C2.70973 9.39254 3.11311 8.98915 3.61283 8.98915C4.11255 8.98915 4.51594 9.39254 4.51594 9.89226C4.51594 10.392 4.11255 10.7954 3.61283 10.7954ZM8.12738 9.89226C8.12738 10.392 8.53077 10.7954 9.03049 10.7954C9.5302 10.7954 9.93359 10.392 9.93359 9.89226C9.93359 9.39254 9.5302 8.98915 9.03049 8.98915C8.53077 8.98915 8.12738 9.39254 8.12738 9.89226Z"
        fill="#A98D4B"
      />
    </svg>
  );
};
