import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { SocialFacebook } from "../../common/SocialFacebook";
import { StarIcon } from "../../../assets/image/StarIcon";
import { WhiteStarIcon } from "../../../assets/image/WhiteStarIcon";
import { ServiceIcon } from "../../../assets/image/ServiceIcon";
import { PhoneIcon } from "../../../assets/image/PhoneIcon";
import { api } from "../../../urls";

export const JobPostCard = ({
  data,
  jobData,
  profile,
  index,
  handleElementById,
  setIsDelete,
  setIsJobPost,
}) => {
  const { t } = useTranslation();

  return (
    <div class="looking_for_candidate_boxs" style={{ display: "block" }}>
      <div class="looking_for_candidate_box">
        <div class="second_sec">
          <div class="heading">
            <h3>{data.title != null ? data.title : ""}</h3>
            {data.plateformonsocialmedia == "Yes" ? (
              <SocialFacebook
                link={
                  window.location.origin + "/profile-parents/" + data.user_id
                }
              />
            ) : (
              ""
            )}
          </div>
          <div class="post_general">
            <h6>
              <Link to={"/profile-parents/" + jobData[0]?.user_id}>
                {data.first_name != null
                  ? data.first_name + " " + data.last_name + " "
                  : ""}
              </Link>
              (
              {data.dob != undefined
                ? new Date().getFullYear() -
                  parseInt(
                    data.dob
                      .substr(data.dob.lastIndexOf("\\") + 1)
                      .split("-")[0]
                  )
                : ""}
              )
            </h6>
            <p>
              {data.reviewAvg >= 0 ? (
                <>
                  {[...Array(data.reviewAvg)].map((star, index) => {
                    index += 1;
                    return <StarIcon />;
                  })}
                  {[...Array(5 - data.reviewAvg)].map((star, index) => {
                    index += 1;
                    return <WhiteStarIcon />;
                  })}
                </>
              ) : (
                ""
              )}
              <span> ({data.reviewcount})</span>
            </p>
          </div>
          <div class="post_detail">
            <div class="post">
              {data.job_type != null ? (
                data.job_type == "Nanny" ? (
                  <img
                    src={window.location.origin + "/images/nany_post.svg"}
                    alt=""
                  />
                ) : data.job_type == "Special Education Teacher" ? (
                  <img
                    src={window.location.origin + "/images/teacher_post.svg"}
                    alt=""
                  />
                ) : data.job_type == "Special Education Paraprofessional" ? (
                  <img
                    src={window.location.origin + "/images/education_post.svg"}
                    alt=""
                  />
                ) : data.job_type == "Tutor" ? (
                  <img
                    src={window.location.origin + "/images/tutor_post.svg"}
                    alt=""
                  />
                ) : (
                  ""
                )
              ) : (
                ""
              )}
              <h5>
                {data.job_type != null
                  ? data.job_type == "Special Education Paraprofessional"
                    ? t("sped-paraprofessional")
                    : data.job_type == "Special Education Teacher"
                    ? t("sped-teacher")
                    : data.job_type
                  : ""}
              </h5>
            </div>
            <div class="vi"></div>
            <div class="post_pay">
              <img src={window.location.origin + "/img/post_pay.png"} alt="" />
              {profile && profile.country == "Serbia" ? (
                <h5>
                  {data.nanyperhrrate != null
                    ? data.nanyperhrrate
                        .substr(data.nanyperhrrate.lastIndexOf("\\") + 1)
                        .split("-")[0] *
                        100 +
                      " - " +
                      data.nanyperhrrate
                        .substr(data.nanyperhrrate.lastIndexOf("\\") + 1)
                        .split("-")[1] *
                        100
                    : data.tutorperhrrate
                    ? data.tutorperhrrate
                        .substr(data.tutorperhrrate.lastIndexOf("\\") + 1)
                        .split("-")[0] *
                        100 +
                      " - " +
                      data.tutorperhrrate
                        .substr(data.tutorperhrrate.lastIndexOf("\\") + 1)
                        .split("-")[1] *
                        100
                    : ""}
                  {t("time-hour")}
                </h5>
              ) : (
                <h5>
                  $
                  {data.nanyperhrrate != null
                    ? data.nanyperhrrate
                    : data.tutorperhrrate
                    ? data.tutorperhrrate
                    : ""}
                  {t("time-hour")}
                </h5>
              )}
            </div>
            <div class="vi"></div>
            <div class="post_fet">
              <img
                src={window.location.origin + "/images/post_fet.svg"}
                alt=""
              />
              <h5>
                {data.nanyintrestedin != null
                  ? t(data.nanyintrestedin)
                  : t("full-time")}
              </h5>
            </div>
            <div class="vi"></div>
            <div class="post_cal">
              <img
                src={window.location.origin + "/images/post_cal.svg"}
                alt=""
              />
              <h5>
                {data.nanystartdate != null
                  ? data.nanystartdate
                  : data.tutorstartdate}
              </h5>
            </div>
            <div class="vi"></div>
            <div class="post_loc">
              <img
                src={window.location.origin + "/images/post_loc.svg"}
                alt=""
              />
              <h5>
                {data.country != null ? data.country : ""} ,
                {data.city != null ? data.city : ""}
              </h5>
            </div>
          </div>
        </div>
        <div class="first_sec">
          <div class="image_sec">
            <img
              src={
                data.file_path != null
                  ? api + "/assets/images/users/" + data.file_path
                  : "img/nany_img.png"
              }
              alt=""
            />
            <div class="heart_sec">
              <img
                src={window.location.origin + "/images/img_heart.svg"}
                alt=""
              />
            </div>
          </div>
          <div class="nany_social">
            {data.phoneVerifiedStatus == 1 ? (
              <img
                src={window.location.origin + "/images/nany_phone.svg"}
                alt=""
              />
            ) : (
              <PhoneIcon />
            )}
            <img src={window.location.origin + "/images/nany_msg.svg"} alt="" />
            {data.facebookverify != null || data.linkdinverify != null ? (
              <img
                src={window.location.origin + "/images/nany_cont.svg"}
                alt=""
              />
            ) : (
              <ServiceIcon />
            )}
          </div>
        </div>
        <div class="second_sec">
          <p id={"half" + index}>
            {data.description != null ? data.description.substr(0, 100) : ""}
            {data.description != null && data.description.length > 100 ? (
              <span
                onClick={(e) =>
                  handleElementById(
                    "half" + index,
                    "full" + index,
                    "plink" + index
                  )
                }
              >
                {t("text-more")}
              </span>
            ) : (
              ""
            )}
          </p>
          <p id={"full" + index} className="hide">
            {data.description}
            <span
              onClick={(e) =>
                handleElementById(
                  "full" + index,
                  "half" + index,
                  "plink" + index
                )
              }
            >
              {t("text-less")}
            </span>
          </p>
        </div>
        <div class="view_profile_btn visiterbtn jobbtn brownntn">
          <button onClick={(e) => setIsDelete(true)}>
            {t("close-job")}
          </button>
          <button onClick={(e) => setIsJobPost(true)}>{t("edit-post")}</button>
        </div>
      </div>
    </div>
  );
};
