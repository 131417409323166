import { DEFAULT_API } from "../utils/variables";

export const fetchSafetyCenterData = async () => {
  try {
    const response = await fetch(`${DEFAULT_API}/api/safetycenter`, {
      method: "GET",
      redirect: "follow",
    });
    const result = await response.json();
    return result.data;
  } catch (error) {
    console.log("error", error);
    throw error;
  }
};


export const fetchTermsOfUse = async () => {
  const requestOptions = {
    method: "GET",
    redirect: "follow",
  };

  try {
    const response = await fetch(`${DEFAULT_API}/api/termofuse`, requestOptions);
    if (!response.ok) {
      const error = await response.json();
      throw new Error(error.message || 'Something went wrong');
    }
    return response.json();
  } catch (error) {
    console.error("Error fetching terms of use:", error);
    throw error;
  }
};

export const fetchResourceCategory = async (selectList, inputValue) => {
  const requestOptions = {
    method: "GET",
    redirect: "follow",
  };

  const query = selectList.map((e) => e.name).join(",");
  const url = `${DEFAULT_API}/api/resourcecategory?category=${query}&anysearch=${inputValue}`;

  try {
    const response = await fetch(url, requestOptions);
    if (!response.ok) {
      const error = await response.json();
      throw new Error(error.message || "Something went wrong");
    }
    return response.json();
  } catch (error) {
    console.error("Error fetching resource category:", error);
    throw error;
  }
};

export const fetchResourceContent = async () => {
  const requestOptions = {
    method: "GET",
    redirect: "follow",
  };

  try {
    const response = await fetch(
      `${DEFAULT_API}/api/resourcecontent`,
      requestOptions
    );
    if (!response.ok) {
      const error = await response.json();
      throw new Error(error.message || "Something went wrong");
    }
    return response.json();
  } catch (error) {
    console.error("Error fetching resource content:", error);
    throw error;
  }
};

export const fetchResourceCategories = async () => {
  const requestOptions = {
    method: "GET",
    redirect: "follow",
  };

  try {
    const response = await fetch(
      `${DEFAULT_API}/api/getRescategory`,
      requestOptions
    );
    if (!response.ok) {
      const error = await response.json();
      throw new Error(error.message || "Something went wrong");
    }
    return response.json();
  } catch (error) {
    console.error("Error fetching resource categories:", error);
    throw error;
  }
};

export const fetchReferralLink = async () => {
  const token = localStorage.getItem("token");
  const myHeaders = new Headers();
  myHeaders.append("Authorization", "Bearer " + token);
  myHeaders.append("Cookie", "XSRF-TOKEN=" + token);

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  try {
    const response = await fetch(
      `${DEFAULT_API}/api/v1/referrallink`,
      requestOptions
    );
    if (!response.ok) {
      const error = await response.json();
      throw new Error(error.message || "Something went wrong");
    }
    return response.json();
  } catch (error) {
    console.error("Error fetching referral link:", error);
    throw error;
  }
};

export const fetchReferralUser = async () => {
  const token = localStorage.getItem("token");
  const myHeaders = new Headers();
  myHeaders.append("Authorization", "Bearer " + token);
  myHeaders.append("Cookie", "XSRF-TOKEN=" + token);

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  try {
    const response = await fetch(
      `${DEFAULT_API}/api/v1/referraluserchekfordiscount`,
      requestOptions
    );
    if (!response.ok) {
      const error = await response.json();
      throw new Error(error.message || "Something went wrong");
    }
    return response.json();
  } catch (error) {
    console.error("Error checking referral user for discount:", error);
    throw error;
  }
};

export const fetchBlockUser = async (blockUserId) => {
  const token = localStorage.getItem("token");
  const myHeaders = new Headers();
  myHeaders.append("Authorization", "Bearer " + token);
  myHeaders.append("Content-Type", "application/json");

  const raw = JSON.stringify({
    block_user_id: blockUserId,
  });

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  try {
    const response = await fetch(
      `${DEFAULT_API}/api/v1/userblock`,
      requestOptions
    );
    if (!response.ok) {
      const error = await response.json();
      throw new Error(error.message || "Something went wrong");
    }
    return response.text();
  } catch (error) {
    console.error("Error blocking user:", error);
    throw error;
  }
};

export const fetchReportUser = async (reportUserId, reason) => {
  const token = localStorage.getItem("token");
  const myHeaders = new Headers();
  myHeaders.append("Authorization", "Bearer " + token);
  myHeaders.append("Content-Type", "application/json");

  const raw = JSON.stringify({
    report_userid: reportUserId,
    reason: reason,
    other_reason: "",
  });

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  try {
    const response = await fetch(
      `${DEFAULT_API}/api/v1/reportuser`,
      requestOptions
    );
    if (!response.ok) {
      const error = await response.json();
      throw new Error(error.message || "Something went wrong");
    }
    return response.text();
  } catch (error) {
    console.error("Error reporting user:", error);
    throw error;
  }
};

export const fetchInboxMessages = async (senderId) => {
  const token = localStorage.getItem("token");
  const myHeaders = new Headers();
  myHeaders.append("Authorization", "Bearer " + token);
  myHeaders.append("Content-Type", "application/json");

  const raw = JSON.stringify({
    sender_id: senderId,
  });

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  try {
    const response = await fetch(
      `${DEFAULT_API}/api/v1/inboxmessagesview`,
      requestOptions
    );
    if (!response.ok) {
      const error = await response.json();
      throw new Error(error.message || "Something went wrong");
    }
    return response.json();
  } catch (error) {
    console.error("Error fetching inbox messages:", error);
    throw error;
  }
};

export const fetchSendMessage = async (userId, message) => {
  const token = localStorage.getItem("token");
  const myHeaders = new Headers();
  myHeaders.append("Authorization", "Bearer " + token);
  myHeaders.append("Content-Type", "application/json");

  const raw = JSON.stringify({
    user_id: userId,
    message: message,
  });

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  try {
    const response = await fetch(
      `${DEFAULT_API}/api/v1/sendmessage`,
      requestOptions
    );
    if (!response.ok) {
      const error = await response.json();
      throw new Error(error.message || "Something went wrong");
    }
    return response.text();
  } catch (error) {
    console.error("Error sending message:", error);
    throw error;
  }
};

export const fetchSendMessageEmail = async (userId) => {
  const token = localStorage.getItem("token");
  const myHeaders = new Headers();
  myHeaders.append("Authorization", "Bearer " + token);
  myHeaders.append("Content-Type", "application/json");

  const raw = JSON.stringify({
    user_id: userId,
  });

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  try {
    const response = await fetch(
      `${DEFAULT_API}/api/v1/sendmessagemail`,
      requestOptions
    );
    if (!response.ok) {
      const error = await response.json();
      throw new Error(error.message || "Something went wrong");
    }
    return response.text();
  } catch (error) {
    console.error("Error sending message email:", error);
    throw error;
  }
};

export const fetchPrivacyPolicy = async () => {
  const requestOptions = {
    method: "GET",
    redirect: "follow",
  };

  try {
    const response = await fetch(
      `${DEFAULT_API}/api/privacypolicy`,
      requestOptions
    );
    if (!response.ok) {
      const error = await response.text();
      throw new Error(error || "Something went wrong");
    }

    return response.json();
  } catch (error) {
    console.error("Error fetching privacy policy:", error);
    throw error;
  }
};

export const exploreClickCounts = () => {
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  const raw = JSON.stringify({
    clickcount: "1",
  });

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  fetch(DEFAULT_API + "/api/exploreclickcounts", requestOptions)
    .then((response) => response.text())
    .then((result) => console.log(result))
    .catch((error) => console.log("error", error));
};

export const fetchCareerData = async () => {
  try {
    const response = await fetch(`${DEFAULT_API}/api/career`, {
      method: "GET",
      redirect: "follow",
    });
    const result = await response.json();
    return result.data;
  } catch (error) {
    console.error("Error fetching career data:", error);
    throw error;
  }
};

export const submitContactForm = async (formData) => {
  try {
    const requestOptions = {
      method: "POST",
      body: formData,
    };
    const response = await fetch(
      `${DEFAULT_API}/api/contactus`,
      requestOptions
    );
    return response.json();
  } catch (error) {
    console.error("Error submitting contact form:", error);
    throw error;
  }
};

export const fetchMessageCount = async () => {
  const myHeaders = new Headers();
  myHeaders.append("Authorization", "Bearer  " + localStorage.getItem("token"));
  myHeaders.append("Cookie", "XSRF-TOKEN= " + localStorage.getItem("token"));

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  const response = await fetch(
    `${DEFAULT_API}/api/v1/messagecount`,
    requestOptions
  );
  const result = await response.json();
  return result.data;
};

export const fetchNotificationCount = async (userType) => {
  const myHeaders = new Headers();
  myHeaders.append("Authorization", "Bearer  " + localStorage.getItem("token"));
  myHeaders.append("Cookie", "XSRF-TOKEN= " + localStorage.getItem("token"));

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  const endpoint =
    userType === "parents" ? "parentsallcounts" : "providerallcounts";
  const response = await fetch(
    `${DEFAULT_API}/api/v1/${endpoint}`,
    requestOptions
  );
  const result = await response.json();
  return result.data;
};

// src/services/interviewService.js

export const fetchInterviews = async (refine) => {
  const token = localStorage.getItem("token");
  const headers = new Headers({
    Authorization: `Bearer ${token}`,
  });

  const refineQuery = Object.keys(refine).find((key) => refine[key]) || "";
  const queryParam = refineQuery ? `${refineQuery}=${refine[refineQuery]}` : "";

  try {
    const response = await fetch(
      `${DEFAULT_API}/api/v1/parentsinterviewinvites_notification?${queryParam}`,
      {
        method: "GET",
        headers,
        redirect: "follow",
      }
    );
    const result = await response.json();
    return result.data;
  } catch (error) {
    console.error("Error fetching interviews:", error);
    throw error;
  }
};

export const deleteInterviews = async (deleteList) => {
  const token = localStorage.getItem("token");

  const headers = new Headers({
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  });

  const body = JSON.stringify({ ids: deleteList });

  try {
    const response = await fetch(
      `${DEFAULT_API}/api/v1/multideleteparentsinvitaion`,
      {
        method: "POST",
        headers,
        body,
        redirect: "follow",
      }
    );
    return await response.text();
  } catch (error) {
    console.error("Error deleting interviews:", error);
    throw error;
  }
};

export const fetchCookiePolicy = async () => {
  const requestOptions = {
    method: "GET",
    redirect: "follow",
  };

  const response = await fetch(
    `${DEFAULT_API}/api/cookiepolicy`,
    requestOptions
  );
  if (!response.ok) {
    throw new Error("Network response was not ok");
  }
  const result = await response.json();
  return result.data;
};

export const fetchFaqList = async (search) => {
  const requestOptions = {
    method: "GET",
    redirect: "follow",
  };

  try {
    const response = await fetch(
      `${DEFAULT_API}/api/faqList?search=${search}`,
      requestOptions
    );
    const result = await response.json();
    return result.data;
  } catch (error) {
    console.error("Error fetching FAQ list:", error);
    return [];
  }
};

export const fetchFaqData = async (search) => {
  const requestOptions = {
    method: "GET",
    redirect: "follow",
  };

  try {
    const response = await fetch(
      `${DEFAULT_API}/api/faqList?search=${search}`,
      requestOptions
    );
    const result = await response.json();
    return result.data;
  } catch (error) {
    console.error("Error fetching FAQ list:", error);
    throw error;
  }
};

export const fetchInvestorRelations = async () => {
  const requestOptions = {
    method: "GET",
    redirect: "follow",
  };

  try {
    const response = await fetch(
      `${DEFAULT_API}/api/investorrelations`,
      requestOptions
    );
    if (!response.ok) {
      throw new Error("Failed to fetch investor data");
    }
    const result = await response.json();
    return result.data;
  } catch (error) {
    console.error("Error fetching investor data:", error);
    throw error;
  }
};

export const fetchInvestorForm = async (formData) => {
  const requestOptions = {
    method: "POST",
    body: formData,
    redirect: "follow",
  };

  try {
    const response = await fetch(
      `${DEFAULT_API}/api/investorrelationsenquiry`,
      requestOptions
    );
    if (!response.ok) {
      throw new Error("Failed to submit investor form");
    }
    const result = await response.json();
    return result;
  } catch (error) {
    console.error("Error submitting investor form:", error);
    throw error;
  }
};

export const getMembershipPlan = async (token) => {
  const myHeaders = new Headers();
  myHeaders.append("Authorization", "Bearer " + token);

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  try {
    const response = await fetch(
      `${DEFAULT_API}/api/v1/getmembershipplan`,
      requestOptions
    );
    const result = await response.json();
    return result.data;
  } catch (error) {
    console.log("error", error);
    throw error;
  }
};

export const saveMembershipPlan = async (token, data) => {
  const myHeaders = new Headers();
  myHeaders.append("Authorization", "Bearer " + token);
  myHeaders.append("Content-Type", "application/json");
  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: JSON.stringify(data),
    redirect: "follow",
  };

  try {
    const response = await fetch(
      `${DEFAULT_API}/api/v1/savemembershipplan`,
      requestOptions
    );
    const result = await response.json();
    return result;
  } catch (error) {
    console.log("error", error);
    throw error;
  }
};

export const authLinkedin = async (accessToken) => {
  const requestOptions = {
    method: "GET",
    redirect: "follow",
  };

  try {
    const response = await fetch(
      `${DEFAULT_API}/api/authchecklinkdin/${accessToken}`,
      requestOptions
    );
    if (!response.ok) {
      const error = await response.text();
      throw new Error(error || "Something went wrong");
    }
    return response.json();
  } catch (error) {
    console.error("Error fetching profile data:", error);
    throw error;
  }
};

export const getPromoData = async () => {
  const requestOptions = {
    method: "GET",
    redirect: "follow",
  };

  try {
    const response = await fetch(
      `${DEFAULT_API}/api/getpromocode`,
      requestOptions
    );
    if (!response.ok) {
      const error = await response.text();
      throw new Error(error || "Something went wrong");
    }
    return response.json();
  } catch (error) {
    console.error("Error fetching promo data:", error);
    throw error;
  }
};
