import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { DATA_COUNTRY } from "../../utils/data";

import { FormSelect } from "../../components/common/FormSelect";
import { FormInput } from "../../components/common/FormInput";

export const SignupForm = ({
  step,
  errorField,
  validationField,
  handleSubmit,
  setStep,
}) => {
  const { t } = useTranslation();

  return (
    <div
      className={
        "describe more_about form_signup " + (step === "stap3" ? "" : "none")
      }
    >
      <img src="./images/sign_logo.png" alt="Sign Logo" />
      <h2>{t("Tell us more about your needs")}</h2>
      <form>
        <FormInput
          label={t("First Name*")}
          type="text"
          name="first_name"
          placeholder={t("text-type")}
          errorField={errorField}
          onChange={validationField}
        />
        <FormInput
          label={t("Last Name*")}
          type="text"
          name="last_name"
          placeholder={t("text-type")}
          errorField={errorField}
          onChange={validationField}
        />
        <FormInput
          label={t("Username*")}
          type="text"
          name="username"
          placeholder={t("text-type")}
          errorField={errorField}
          onChange={validationField}
        />
        <FormInput
          label={t("Email address*")}
          type="email"
          name="email"
          placeholder={t("text-type")}
          errorField={errorField}
          onChange={validationField}
        />
        <FormInput
          label={t("Password*")}
          type="password"
          name="password"
          placeholder=". . . . . . . . ."
          errorField={errorField}
          onChange={validationField}
        />
        <FormInput
          label={t("Repeat password*")}
          type="password"
          name="c_password"
          placeholder=". . . . . . . . . . "
          errorField={errorField}
          onChange={validationField}
        />
        <FormInput
          label={t("Date of birth*")}
          type="date"
          name="dob"
          placeholder={t("text-type")}
          errorField={errorField}
          onBlur={validationField}
        />
        <FormInput
            label={t("Mobile phone*")}
            type="number"
            name="phone"
            placeholder={t("text-type")}
            errorField={errorField}
            onChange={validationField}
        />
        <FormInput
            label={t("Address*")}
            type="text"
            name="address"
            placeholder={t("Street name, House number")}
            errorField={errorField}
            onChange={validationField}
        />
        <div className="form_group part2">
          <div className="citydetail">
            <FormInput
              label={t("City*")}
              type="text"
              name="city"
              placeholder={t("text-type")}
              errorField={errorField}
              onChange={validationField}
            />
          </div>
          <div className="citydetail">
            <FormInput
              label={t("Zip code*")}
              type="number"
              name="zip"
              placeholder={t("text-type")}
              errorField={errorField}
              onChange={validationField}
            />
          </div>
        </div>
        <FormSelect
          label={t("Country*")}
          name="country"
          options={DATA_COUNTRY.data.map((e) => ({
            value: e.country,
            label: e.country,
          }))}
          errorField={errorField}
          onChange={validationField}
        />
        <FormSelect
          label={t("How did you hear about us?*")}
          name="hearAboutUs"
          options={[
            { value: t("Internet browsing"), label: t("Internet browsing") },
            {
              value: t("Friend recommendation"),
              label: t("Friend recommendation"),
            },
            { value: t("Facebook"), label: t("Facebook") },
            { value: t("Our website"), label: t("Our website") },
            { value: t("text-other"), label: t("text-other") },
          ]}
          errorField={errorField}
          onChange={validationField}
        />
      </form>
      <p>
        {t("By clicking on “Sign up” you agree to our")}
        <Link to="/terms-of-use">{t("Terms of Use")} </Link> and
        <Link to="/privacy-policy">{t("Privacy Policy")} </Link>.
      </p>
      <button className="back_sign" onClick={() => setStep("stap2")}>
        {t("text-back")}
      </button>
      <button onClick={handleSubmit}>
        <Link to="#">{t("sign-up")}</Link>
      </button>
    </div>
  );
};
