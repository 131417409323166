export const CaseIcon = ({ width, height, color }) => {
  const generalWidth = width ? width : "16";
  const generalHeight = height ? height : "14";
  const generalColor = color ? color : "#636363";

  return (
    <svg
      width={generalWidth}
      height={generalHeight}
      viewBox="0 0 16 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 8H16V12.5C16 13.3284 15.3284 14 14.5 14H1.5C0.671562 14 0 13.3284 0 12.5V8H6V8.625C6 8.83209 6.16791 9 6.375 9H9.625C9.83209 9 10 8.83209 10 8.625V8ZM16 4.5V7H0V4.5C0 3.67156 0.671562 3 1.5 3H4V1.5C4 0.671562 4.67156 0 5.5 0H10.5C11.3284 0 12 0.671562 12 1.5V3H14.5C15.3284 3 16 3.67156 16 4.5ZM10 2H6V3H10V2Z"
        fill={generalColor}
      />
    </svg>
  );
};
