export const ConfirmArrowIcon = () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M1.55556 0H12.4444C13.3 0 14 0.578114 14 1.2847V9.59027C14 10.0335 13.7278 10.4253 13.3156 10.6566L7.43556 13.894C7.17111 14.0353 6.83667 14.0353 6.57222 13.894L0.684444 10.6566C0.272222 10.4253 0 10.0335 0 9.59027L0.00777778 1.2847C0.00777778 0.578114 0.7 0 1.55556 0ZM6.39963 9.96903L11.2751 5.0936C11.5256 4.84308 11.5192 4.4384 11.2686 4.18789C11.0181 3.93737 10.6134 3.93737 10.3629 4.18789L5.94356 8.60725L4.0936 6.75728C3.84308 6.50677 3.4384 6.50677 3.18789 6.75728C2.93737 7.0078 2.93737 7.41248 3.18789 7.663L5.49392 9.96903C5.74444 10.2195 6.14912 10.2195 6.39963 9.96903Z"
        fill="#636363"
      />
    </svg>
  );
};
