import { create } from "zustand";

export const useAdditionalDetailsStore = create((set) => ({
  verify: "",
  selectCat: JSON.parse(localStorage.getItem("controls"))?.selectCat || [],
  childrenAge: [],
  catOpen: true,
  setCode: true,
  isResult: "",
  month: 0,
  step: 0,
  country: "",
  
  setCountry: (newValue) => set({ country: newValue }),
  setMonth: (newValue) => set({ month: newValue }),
  setStep: (newValue) => set({ step: newValue }),
  setIsResult: (newValue) => set({ isResult: newValue }),
  setVerify: (newValue) => set({ verify: newValue }),
  setSelectCat: (newSelectCat) => {
    const storedData = JSON.parse(localStorage.getItem("controls")) || {};
    storedData.selectCat = newSelectCat;
    localStorage.setItem("controls", JSON.stringify(storedData));
    set({ selectCat: newSelectCat });
  },
  setChildrenAge: (newChildrenAge) => set({ childrenAge: newChildrenAge }),
  setCatOpen: (newCatOpen) => set({ catOpen: newCatOpen }),
  setSetCode: (newSetCode) => set({ setCode: newSetCode }),
}));
