import { create } from 'zustand';

export const useSearchDetailsStore = create((set) => ({
  search: {
    jobcategory: "",
    keyword: "",
    city: "",
    zip: "",
    tutorintrestedin: "",
    tutorintrestedin1: "",
    tutorintrestedin2: "",
    tutorworkwithnochild: "",
    tutorprefchildage: "",
    rates: "",
    workingabroad: "",
    tutorintrestedinschool: "",
    carorlicence: "",
    withinweek: "",
    withinmonth: "",
    jobposted: "",
    distance: "",
  },
  setSearch: (search) => set((state) => ({ search: { ...state.search, ...search } })),
  usd: {
    min: 0,
    max: 0,
  },
  setUsd: (usd) => set((state) => ({ usd: { ...state.usd, ...usd } })),
  profile: {},
  setProfile: (profile) => set({ profile }),
}));