import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { ReviewDetail } from "../ReviewDetail";
import { RaffleIcon } from "../../../assets/image/RaffleIcon";
import { RebootIcon } from "../../../assets/image/RebootIcon";
import {
  ENG_LANGUAGE,
  MONTH_ENG_LANGUAGE,
  MONTH_SRB_LANGUAGE,
  REFINE_OPTIONS,
} from "../../../utils/variables";
import {
  deleteReviews,
  getReviewNotificationParams,
} from "../../../api/reviewApi";
import { ReviewNotificationRow } from "./ReviewNotificationRow";
import { useInterviewStore } from "../../../store/useInterviewStore";

export function ReviewProvider() {
  const { t, i18n } = useTranslation();
  const language = i18n.language;
  const navigate = useNavigate();

  const [reviewNotification, setReviewNotification] = useState([]);

  const [deleteList, setDeleteList] = useState([]);
  const [isCheck, setIsCheck] = useState(true);
  const [isActive, setIsActive] = useState(false);

  const slugData = useParams();
  let data = slugData.name;
  const month =
    language === ENG_LANGUAGE ? MONTH_ENG_LANGUAGE : MONTH_SRB_LANGUAGE;

  const {
    recived,
    setRecived,
    view,
    setView,
    refine,
    setRefine,
    resetInterviewState,
  } = useInterviewStore();

  useEffect(() => {
    return () => {
      resetInterviewState();
    };
  }, []);

  const fetchReviewNotification = async () => {
    setReviewNotification([]);

    try {
      const result = await getReviewNotificationParams(refine);
      setReviewNotification(result.data.allreviews);
    } catch (error) {
      console.error("Error fetching review notifications:", error);
    }
  };

  useEffect(() => {
    if (isCheck) {
      fetchReviewNotification();
      setIsCheck(false);
    }

    setView(data ? data : "");
  }, [isCheck, reviewNotification, data, slugData]);
  useEffect(() => {
    if (window.innerWidth < 768) {
      setRecived("text-refine");
    }
  }, []);

  const handleSelectElement = () => {
    const ele = document.getElementsByName("chk");
    reviewNotification.map((data) => {
      deleteList.push(data.id);
    });
    for (let i = 0; i < ele.length; i++) {
      if (ele[i].type == "checkbox") ele[i].checked = true;
    }
  };

  const handleDeselectElement = () => {
    const ele = document.getElementsByName("chk");
    for (let i = 0; i < ele.length; i++) {
      if (ele[i].type == "checkbox") ele[i].checked = false;
    }
    setDeleteList([]);
  };

  const handleDeleteElement = (a) => {
    let sum = false;
    deleteList.map((e) => {
      if (e == a) {
        sum = true;
        const index = deleteList.indexOf(a);
        if (index > -1) {
          deleteList.splice(index, 1);
        }
      }
    });
    if (sum == false) {
      deleteList.push(a);
    }
  };

  const handleDeleteReviews = async () => {
    const userType = localStorage.getItem("user_type");

    try {
      await deleteReviews(deleteList, userType);
      await fetchReviewNotification();
    } catch (error) {
      console.error("Error deleting reviews:", error);
    }
  };

  const handleRefineDay = (e) => {
    setRefine({
      threedays: e == "3" ? true : "",
      withinweek: e == "7" ? true : "",
      twofourday: e == "24" ? true : "",
      withinmonth: e == "30" ? true : "",
    });
    setIsCheck(true);
  };

  return (
    <>
      {view == "" ? (
        <>
          <div class="main-header">
            <h2 className="border">{t("new-reviews")}</h2>
            <div class="mail-header-bar">
              <p>
                {reviewNotification.length} {t("new-reviews")}
              </p>
              <div class="btn-group flex">
                {t("text-refine")}
                <div className="select">
                  <label onClick={(e) => setIsActive(!isActive)}>
                    {t(recived)}
                    <span>
                      <img src="/images/done_a.svg" />
                    </span>
                  </label>
                  {isActive && (
                    <ul>
                      {REFINE_OPTIONS.map((option) => (
                        <li
                          key={option.label}
                          onClick={() => {
                            setRecived(t(option.label));
                            handleRefineDay(option.value);
                            setIsActive(!isActive);
                          }}
                        >
                          {t(option.label)}
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              </div>
            </div>
            <div class="mail-option">
              <div class="chk-all">
                <input
                  type="checkbox"
                  class="mail-radio mail-group-radio"
                  name="chk"
                  id="checkbox_id"
                  onClick={(e) => {
                    if (e.target.checked) {
                      handleSelectElement();
                    } else {
                      handleDeselectElement();
                    }
                  }}
                />
                <span className="circle"></span>
                <label for="checkbox_id">{t("select-all")}</label>
              </div>
              <div class="right-side-btn">
                <div class="delete-btn" onClick={(e) => handleDeleteReviews()}>
                  <a href="#">
                    <RaffleIcon />
                    <span> {t("text-delete")}</span>
                  </a>
                </div>
                <div
                  class="refresh-btn"
                  onClick={(e) => fetchReviewNotification()}
                >
                  <a href="#">
                    <RebootIcon />
                  </a>
                  <span>{t("text-refresh")}</span>
                </div>
              </div>
            </div>
          </div>
          <table class="table table-inbox table-hover newreview">
            <tbody>
              {reviewNotification.map((data, index) => {
                if (index <= 5) {
                  return (
                    <ReviewNotificationRow
                      key={data.id}
                      data={data}
                      handleDeleteElement={handleDeleteElement}
                      navigate={navigate}
                      slugData={slugData}
                      month={month}
                    />
                  );
                }
              })}
            </tbody>
          </table>
        </>
      ) : (
        <ReviewDetail id={view} setview={setView} />
      )}
    </>
  );
}
