import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Modal from "react-bootstrap/Modal";

export const MembershipModal = ({ setIsMembership, isMembership }) => {
  const { t } = useTranslation();

  return (
    <Modal show={isMembership} onHide={(e) => setIsMembership(false)}>
      <Modal.Body>
        <div className="promocode_content cancelmembership">
          <Link to="" onClick={(e) => setIsMembership(false)}>
            +
          </Link>
          <h2>
            <img src={window.location.origin + "/images/warning.svg"} />

            <br />
            {t("Cancel membership")}
          </h2>
          <div className="cancelmembershipp">
            <p>
              <span>{t("text-warning")}</span>
              {t(
                "cancel-loyalty-program"
              )}
            </p>
            <div className="button text-center">
              <div className="pull-right">
                <button className="btn" onClick={(e) => setIsMembership(false)}>
                  {t("Confirm")}
                </button>
              </div>
              <div className="pull-right">
                <button
                  className="btn confirm"
                  onClick={(e) => setIsMembership(false)}
                >
                  {t("text-close-up")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
