import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Modal from "react-bootstrap/Modal";

import { SendReview } from "../SendReview";
import { getReviewData } from "../../../api/reviewApi";
import { ReviewItem } from "./ReviewItem";

export function ReviewAll(props) {
  const { t } = useTranslation();

  const [reviewData, setReviewData] = useState([]);
  const [isCheckData, setIsCheckData] = useState("");

  const [recived, setRecived] = useState("Received Reviews");
  const [isActive, setIsActive] = useState(false);
  const [loadNumber, setLoadNumber] = useState(6);
  const [userId, setUserId] = useState(0);
  const [showReviewModal, setShowReviewModal] = useState(false);
  const [username, setUsername] = useState("");

  const fetchReviewData = async (type) => {
    setReviewData([]);

    try {
      const result = await getReviewData(type);
      if (type === 2) {
        setReviewData(result.data.sender_userid);
      } else {
        setReviewData(result.data.receivedreviews);
      }
    } catch (error) {
      console.error("Error fetching review data:", error);
    }
  };

  useEffect(() => {
    fetchReviewData();
  }, []);

  return (
    <>
      <div class="main-header">
        <h2 className="border"> {t("text-reviews")}</h2>
        <div class="mail-header-bar">
          <p>
            {reviewData.length} {t("text-reviews ")}
            {recived == "Received Reviews" ? t("text-earned") : t("text-given")}
          </p>
          <div class="btn-group flex">
            {t("text-refine")}
            <div className="select">
              <label onClick={(e) => setIsActive(!isActive)}>
                {t(recived)}
                <span>
                  <img src="/images/done_a.svg" />
                </span>
              </label>
              {isActive ? (
                <ul>
                  <li
                    onClick={(e) => {
                      fetchReviewData("1");
                      setRecived(t("received-reviews"));
                      setIsActive(!isActive);
                    }}
                  >
                    {t("received-reviews")}
                  </li>
                  <li
                    onClick={(e) => {
                      fetchReviewData("2");
                      setRecived(t("given-reviews"));
                      setIsActive(!isActive);
                    }}
                  >
                    {t("given-reviews")}
                  </li>
                </ul>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
        <br />
        <br />
        <div className="all_review">
          <ul>
            {reviewData?.map((data, index) => {
              if (index < loadNumber) {
                return (
                  <ReviewItem
                    key={data.id}
                    data={data}
                    recived={recived}
                    props={props}
                    setUserId={setUserId}
                    setShowReviewModal={setShowReviewModal}
                    setUsername={setUsername}
                    isCheckData={isCheckData}
                  />
                );
              }
            })}
          </ul>
          {recived != t("received-reviews") ? (
            ""
          ) : (
            <button onClick={(e) => setLoadNumber(loadNumber + 6)}>
              {t("text-load")}
            </button>
          )}
        </div>
      </div>
      {showReviewModal ? (
        <Modal show={showReviewModal} onHide={(e) => setShowReviewModal(false)}>
          <Modal.Body>
            <SendReview
              setrequest={setShowReviewModal}
              username={username}
              slugdata={userId}
              setcheckdata={setIsCheckData}
            />
          </Modal.Body>
        </Modal>
      ) : (
        ""
      )}
    </>
  );
}
