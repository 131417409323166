import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Modal from "react-bootstrap/Modal";

import styles from "./VideoModal.module.css";

export function VideoModal({ showModal, setShowModal }) {
  const { t } = useTranslation();

  return (
    <Modal
      className={styles.video_landing}
      show={showModal}
      onHide={() => setShowModal(false)}
    >
      <div className={styles.promocode_content}>
        <Link to="" onClick={() => setShowModal(false)}>
          +
        </Link>
        <video width="100%" controls autoPlay>
          <source
            src="./images/Sense_Care_Demo_Video_FINAL_WEB.mp4"
            type="video/mp4"
          />
          {t("post-html-video")}
        </video>
      </div>
    </Modal>
  );
}