import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { api } from "../../../urls";
import { AdditionalMenuIcon } from "../../../assets/image/AdditionalMenuIcon";
import { StarIcon } from "../../../assets/image/StarIcon";
import { WhiteStarIcon } from "../../../assets/image/WhiteStarIcon";
import { NannySecondaryIcon } from "../../../assets/image/NannySecondaryIcon";
import { TeacherSecondaryIcon } from "../../../assets/image/TeacherSecondaryIcon";
import { ProfessionalSecondaryIcon } from "../../../assets/image/ProfessionalIcon";
import { TutorSecondaryIcon } from "../../../assets/image/TutorSecondaryIcon";
import { PayIcon } from "../../../assets/image/PayIcon";
import { CaseIcon } from "../../../assets/image/CaseIcon";
import { DateIcon } from "../../../assets/image/DateIcon";
import { LocationIcon } from "../../../assets/image/LocationIcon";
import { HeartIcon } from "../../../assets/image/HeartIcon";
import { PhoneIcon } from "../../../assets/image/PhoneIcon";
import { MessageIcon } from "../../../assets/image/MessageIcon";
import { ServiceIcon } from "../../../assets/image/ServiceIcon";

export const JobPostMobileCard = ({
  data,
  profile,
  index,
  handleElementById,
  jobData,
}) => {
  const { t } = useTranslation();

  return (
    <div
      class="looking_for_candidate_boxs graydata"
      style={{ display: "block" }}
    >
      <div class="looking_for_candidate_box">
        <div class="second_sec">
          <div class="heading">
            <h3>{data.title != null ? data.title : ""}</h3>

            <AdditionalMenuIcon />
          </div>
          <div class="post_general">
            <h6>
              <Link to={"/profile-parents/" + jobData[0]?.user_id}>
                {data.first_name != null
                  ? data.first_name + " " + data.last_name + " "
                  : ""}
              </Link>
              (
              {data.dob != undefined
                ? new Date().getFullYear() -
                  parseInt(
                    data.dob
                      .substr(data.dob.lastIndexOf("\\") + 1)
                      .split("-")[0]
                  )
                : ""}
              )
            </h6>
            <p className="stars_profile">
              {data.reviewAvg >= 0 ? (
                <>
                  {[...Array(data.reviewAvg)].map((star, index) => {
                    index += 1;
                    return <StarIcon />;
                  })}
                  {[...Array(5 - data.reviewAvg)].map((star, index) => {
                    index += 1;
                    return <WhiteStarIcon />;
                  })}
                </>
              ) : (
                ""
              )}
              <span> ({data.reviewcount})</span>
            </p>
          </div>
          <div class="post_detail">
            <div class="post">
              {data.job_type != null ? (
                data.job_type == "Nanny" ? (
                  <NannySecondaryIcon />
                ) : data.job_type == "Special Education Teacher" ? (
                  <TeacherSecondaryIcon />
                ) : data.job_type == "Special Education Paraprofessional" ? (
                  <ProfessionalSecondaryIcon />
                ) : data.job_type == "Tutor" ? (
                  <TutorSecondaryIcon />
                ) : (
                  ""
                )
              ) : (
                ""
              )}
              <h5>
                {data.job_type != null
                  ? data.job_type == "Special Education Paraprofessional"
                    ? t("sped-paraprofessional")
                    : data.job_type == "Special Education Teacher"
                    ? t("sped-teacher")
                    : t(data.job_type)
                  : ""}
              </h5>
            </div>
            <div class="vi"></div>
            <div class="post_pay">
              <PayIcon />
              {profile && profile.country == "Serbia" ? (
                <h5>
                  {data.nanyperhrrate != null
                    ? data.nanyperhrrate
                        .substr(data.nanyperhrrate.lastIndexOf("\\") + 1)
                        .split("-")[0] *
                        100 +
                      " - " +
                      data.nanyperhrrate
                        .substr(data.nanyperhrrate.lastIndexOf("\\") + 1)
                        .split("-")[1] *
                        100
                    : data.tutorperhrrate
                    ? data.tutorperhrrate
                        .substr(data.tutorperhrrate.lastIndexOf("\\") + 1)
                        .split("-")[0] *
                        100 +
                      " - " +
                      data.tutorperhrrate
                        .substr(data.tutorperhrrate.lastIndexOf("\\") + 1)
                        .split("-")[1] *
                        100
                    : ""}
                  {t("time-hour")}
                </h5>
              ) : (
                <h5>
                  $
                  {data.nanyperhrrate != null
                    ? data.nanyperhrrate
                    : data.tutorperhrrate
                    ? data.tutorperhrrate
                    : ""}
                  {t("time-hour")}
                </h5>
              )}
            </div>
            <div class="vi"></div>
            <div class="post_fet">
              <CaseIcon width={12} height={10} color={"#A98D4B"} />
              <h5>
                {data.nanyintrestedin != null
                  ? t(data.nanyintrestedin)
                  : t("full-time")}
              </h5>
            </div>
            <div class="vi"></div>
            <div class="post_cal">
              <DateIcon width={13} height={14} />
              <h5>
                {data.nanystartdate != null
                  ? data.nanystartdate
                  : data.tutorstartdate}
              </h5>
            </div>
            <div class="vi"></div>
            <div class="post_loc">
              <LocationIcon />
              <h5>
                {data.country != null ? data.country : ""} ,
                {data.city != null ? data.city : ""}
              </h5>
            </div>
          </div>
        </div>
        <div class="first_sec">
          <div class="image_sec">
            <img
              src={
                data.file_path != null
                  ? api + "/assets/images/users/" + data.file_path
                  : "img/nany_img.png"
              }
              alt=""
            />
            <div class="heart_sec">
              <HeartIcon />
            </div>
          </div>
          <div class="nany_social">
            <PhoneIcon color={"#A98D4B"} />

            <MessageIcon width={16} height={12} color={"#A98D4B"} />

            <ServiceIcon color={"#A98D4B"} />
          </div>
        </div>
        <div class="second_sec">
          <p id={"half" + index}>
            {data.description != null ? data.description.substr(0, 100) : ""}
            {data.description != null && data.description.length > 100 ? (
              <span
                onClick={(e) =>
                  handleElementById(
                    "half" + index,
                    "full" + index,
                    "plink" + index
                  )
                }
              >
                {t("text-more")}
              </span>
            ) : (
              ""
            )}
          </p>
          <p id={"full" + index} className="hide">
            {data.description}
            <span
              onClick={(e) =>
                handleElementById(
                  "full" + index,
                  "half" + index,
                  "plink" + index
                )
              }
            >
              {t("text-less")}
            </span>
          </p>
        </div>
        <div
          class="view_profile_btn visiterbtn"
          style={{
            width: "100%",
          }}
        >
          <button
            style={{
              border: "none",
              background: "transparent",
              color: "#ccc",
              fontSize: "17px",
              textTransform: "uppercase",
            }}
          >
            {t("text-closed")}
          </button>
        </div>
      </div>
    </div>
  );
};
