import { useTranslation } from "react-i18next";

import { useProfileStore } from "../../../store/useProfileStore";
import { useRatesStore } from "../../../store/useRatesStore";
import { useErrorFieldStore } from "../../../store/useErrorsFieldsStore";
import { useDetailParentsStore } from "../../../store/useDetailParentsStore";
import React from "react";
import OldSlider from "../../../components/common/Slider/OldSlider";

export function ServiceTypeOne() {
    const { t, i18n } = useTranslation();
    const language = i18n.language;

    const [errorField, setErrorField] = useErrorFieldStore((state) => [
        state.errorField,
        state.setErrorField,
    ]);
    const { detailParents, setDetailParents } = useDetailParentsStore();

  const { detail } = useProfileStore();

  const { nanyRate, setNanyRate } = useRatesStore();

  return (
    <div className="nanny">
      <h2 className="border">
        <img src="./images/nany_pur.svg" /> {t("text-nanny")}
      </h2>
      <div className="form_group full">
        <label>
          {t("nanny-question")}
          <span className={errorField.liveinnany && errorField.liveinnany !== "" ? "starred" : ""}>
            *
          </span>
        </label>
        <div className="checkbox">
          <ul onClick={(e) => setErrorField({ liveinnany: "" })}>
            <li>
              <input
                type="radio"
                name="quality"
                onClick={(e) =>
                  setDetailParents({
                    liveinnany: "Yes",
                  })
                }
                checked={detailParents.liveinnany == "Yes" ? true : false}
              />
              <span> {t("confirm")}</span>
            </li>
            <li>
              <input
                type="radio"
                name="quality"
                onClick={(e) =>
                  setDetailParents({
                    liveinnany: "No",
                  })
                }
                checked={detailParents.liveinnany == "No" ? true : false}
              />
              <span>{t("objection")}</span>
            </li>
          </ul>
        </div>
        {/* <div className='errorfield'>{error.message}</div>*/}
      </div>
      <div className="form_group full">
        <label>
          {t("Rate per hour")} {detail.country == "Serbia" ? "(RSD)" : "(USD)"}
          <span className={errorField.nanyperhrrate && errorField.nanyperhrrate !== "" ? "starred" : ""}>
            *
          </span>
        </label>
          <OldSlider rate={nanyRate} setRate={setNanyRate} serbian={language === 'sr'}/>
          <span className="price">
          {language === 'sr'
              ? nanyRate.min * 100 + " - " + nanyRate.max * 100 + (nanyRate.max === 60 ? "+" : "")
              : nanyRate.min + " - " + nanyRate.max + (nanyRate.max === 60 ? "+" : "")}
        </span>
      </div>
    </div>
  );
}
