import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Modal from "react-bootstrap/Modal";

export const EmailModal = ({
  isEmailModal,
  handleCloseEmail,
  error,
  handleResetEmail,
  validationLogin,
}) => {
  const { t } = useTranslation();

  return (
    <Modal show={isEmailModal} onHide={handleCloseEmail}>
      <Modal.Body>
        <div className="promocode_content reset_email reset">
          <Link to="" onClick={handleCloseEmail}>
            +
          </Link>
          <h5>{t("reset-password")}</h5>
          <p>
            <span>{t("got-back")}</span>
            <br />
            {t(
              "instructions-reset-password"
            )}
          </p>
          <div className="form_group">
            <input
              type={"email"}
              className={error.resetemailadd == "" ? "" : "bordererror"}
              placeholder="example@emailprovider.com"
              name="resetemailadd"
              onChange={(e) => validationLogin(e)}
            />
            <span className="errorfield">{error.resetemailadd}</span>
          </div>
          <div className="buttons reset4">
            <button className="rest" onClick={handleResetEmail}>
              {t("text-send")}
            </button>
            <button class="rest" onClick={handleCloseEmail}>
              {t("text-cancel")}
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
