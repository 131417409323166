import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams, useNavigate } from "react-router-dom";

import { LoyaltyDetail } from "../LoyaltyDetail";
import { RaffleIcon } from "../../../assets/image/RaffleIcon";
import { RebootIcon } from "../../../assets/image/RebootIcon";
import { ArrowLeftIcon } from "../../../assets/image/ArrowLeftIcon";
import { MONTH } from "../../../utils/variables";
import {
  fetchDeleteMembershipNotifications,
  fetchMembershipNotifications,
} from "../../../api/membershipApi";

import { useInterviewStore } from "../../../store/useInterviewStore";

export function LoyaltyNotification() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [isCheck, setIsCheck] = useState(true);
  const [notificationData, setNotificationData] = useState([]);
  const [deleteList, setDeleteList] = useState([]);
  const [isActive, setIsActive] = useState(false);

  const {
    recived,
    setRecived,
    view,
    setView,
    refine,
    setRefine,
    resetInterviewState,
  } = useInterviewStore();

  useEffect(() => {
    return () => {
      resetInterviewState();
    };
  }, []);

  const slugData = useParams();
  let data = slugData.name;

  const fetchNotificationData = async () => {
    setNotificationData([]);
    try {
      const result = await fetchMembershipNotifications(refine);
      setNotificationData(result.data.getNotification);
    } catch (error) {
      console.error("Error fetching membership notifications:", error);
    }
  };

  useEffect(() => {
    if (isCheck) {
      fetchNotificationData();
      setIsCheck(false);
    }

    setView(data ? data : "");
  }, [isCheck, notificationData, deleteList, refine, data, slugData]);

  useEffect(() => {
    if (window.innerWidth < 768) {
      setRecived("text-refine");
    }
  }, []);

  const handleSelectElement = () => {
    const ele = document.getElementsByName("chk");
    notificationData.map((data) => {
      console.log(data, deleteList);
      deleteList.push(data.id);
    });
    for (let i = 0; i < ele.length; i++) {
      if (ele[i].type == "checkbox") ele[i].checked = true;
    }
  };

  const handleDeselectElement = () => {
    const ele = document.getElementsByName("chk");
    for (let i = 0; i < ele.length; i++) {
      if (ele[i].type == "checkbox") ele[i].checked = false;
    }
    setDeleteList([]);
  };

  const handleDeleteSelect = (a) => {
    let sum = false;
    deleteList.map((e) => {
      if (e == a) {
        sum = true;
        const index = deleteList.indexOf(a);
        if (index > -1) {
          deleteList.splice(index, 1);
        }
      }
    });
    if (sum == false) {
      deleteList.push(a);
    }
    setNotificationData(notificationData);
  };

  const handleSubmitSelect = async () => {
    try {
      await fetchDeleteMembershipNotifications(deleteList);
      fetchNotificationData();
      setDeleteList([]);
    } catch (error) {
      console.error("Error deleting membership notifications:", error);
    }
  };

  const handleRefineDay = (e) => {
    setRefine({
      threedays: e == "3" ? true : "",
      withinweek: e == "7" ? true : "",
      twofourday: e == "24" ? true : "",
      withinmonth: e == "30" ? true : "",
    });
    setIsCheck(true);
  };

  return (
    <div>
      {view == "" ? (
        <>
          <div class="main-header">
            <h2 className="border">{t("loyalty-program")}</h2>
            <div class="mail-header-bar">
              <p>
                {notificationData.length} {t("new-notification")}
              </p>
              <div class="btn-group flex">
                {t("text-refine")}
                <div className="select">
                  <label onClick={(e) => setIsActive(!isActive)}>
                    {t(recived)}
                    <span>
                      <img src="/images/done_a.svg" />
                    </span>
                  </label>
                  {isActive ? (
                    <ul>
                      <li
                        onClick={(e) => {
                          setRecived(t("text-all"));
                          handleRefineDay("");
                          setIsActive(!isActive);
                        }}
                      >
                        {t("text-all")}
                      </li>

                      <li
                        onClick={(e) => {
                          setRecived(t("day"));
                          handleRefineDay(24);
                          setIsActive(!isActive);
                        }}
                      >
                        {t("day")}
                      </li>
                      <li
                        onClick={(e) => {
                          setRecived(t("days-3"));
                          handleRefineDay(3);
                          setIsActive(!isActive);
                        }}
                      >
                        {t("days-3")}
                      </li>
                      <li
                        onClick={(e) => {
                          setRecived(t("days-7"));
                          handleRefineDay(7);
                          setIsActive(!isActive);
                        }}
                      >
                        {t("days-7")}
                      </li>
                      <li
                        onClick={(e) => {
                          setRecived(t("days-30"));
                          handleRefineDay(30);
                          setIsActive(!isActive);
                        }}
                      >
                        {t("days-30")}
                      </li>
                    </ul>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
            <div class="mail-option">
              <div class="chk-all">
                <input
                  type="checkbox"
                  class="mail-radio mail-group-radio"
                  name="chk"
                  id="checkbox_id"
                  onClick={(e) => {
                    if (e.target.checked) {
                      handleSelectElement();
                    } else {
                      handleDeselectElement();
                    }
                  }}
                />
                <span className="circle"></span>
                <label for="checkbox_id">{t("select-all")}</label>
              </div>
              <div class="right-side-btn">
                <div class="delete-btn" onClick={(e) => handleSubmitSelect()}>
                  <a href="#">
                    <RaffleIcon />
                    <span> {t("text-delete")}</span>
                  </a>
                </div>
                <div
                  class="refresh-btn"
                  onClick={(e) => fetchNotificationData()}
                >
                  <a href="#">
                    <RebootIcon />
                  </a>
                  <span>{t("text-refresh")}</span>
                </div>
              </div>
            </div>
          </div>
          <table class="table table-inbox table-hover">
            <tbody>
              {notificationData.map((data) => {
                return (
                  <tr class={data.read_status == 0 ? "unread" : ""}>
                    <td class="view-message  dont-show">
                      <input
                        type="checkbox"
                        class="mail-radio"
                        name="chk"
                        onChange={(e) => handleDeleteSelect(data.id)}
                      />
                      <span className="circle"></span>
                      <span
                        onClick={(e) => {
                          navigate(
                            "/search-providers/" + slugData.id + "/" + data.id
                          );
                          setView(data.id);
                        }}
                      >
                        {data.read_status == 0 ? (
                          <span class="new-tag"> {t("text-new-up")}</span>
                        ) : (
                          ""
                        )}
                        <span class="username-text"> </span>
                        {data.type != "plans"
                          ? t(
                              "post-senscare-family"
                            )
                          : t(
                              "post-discount-membership"
                            )}
                      </span>
                    </td>
                    <td class="view-message  text-right">
                      {MONTH[new Date(data.created_at).getMonth()] +
                        " " +
                        new Date(data.created_at).getDate()}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </>
      ) : (
        <div class="main-header">
          <button
            onClick={(e) => {
              navigate("/search-providers/" + slugData.id);
              setView("");
            }}
          >
            <ArrowLeftIcon />
            {t("text-back")}
          </button>
          <LoyaltyDetail id={view} />
        </div>
      )}
    </div>
  );
}
