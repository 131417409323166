import { create } from "zustand";

export const useContactJobStore = create((set) => ({
    contactCode: {
      code: "",
      flag: "",
    },
    fail: 0,
    job: {
      tital: "",
      description: "",
      id: "",
      id2: "",
      plateformonsocialmedia: "Yes",
      plateformonsocialmedia2: "Yes",
      job_type: "",
      job_type2: "",
    },
  
    setContactCode: (newContactCode) => set({ contactCode: newContactCode }),
    setFail: (newFail) => set({ fail: newFail }),
    setJob: (newJob) => set({ job: newJob }),
  }));