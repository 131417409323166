import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { FavoriteSelect } from "../FavoriteSelect";

import { PhoneIcon } from "../../../assets/image/PhoneIcon";
import { ServiceIcon } from "../../../assets/image/ServiceIcon";
import { SocialFacebook } from "../SocialFacebook";
import { StarIcon } from "../../../assets/image/StarIcon";
import { WhiteStarIcon } from "../../../assets/image/WhiteStarIcon";

export const CandidateBox = ({ data, index, profile, setShowSendModal }) => {
  const { t } = useTranslation();

  const handleElementById = (idToHide, idToShow, idToToggle) => {
    document.getElementById(idToHide).classList.add("hide");
    document.getElementById(idToShow).classList.remove("hide");
    document.getElementById(idToToggle).classList.toggle("active");
  };

  const getRate = (rate) => {
    if (rate) {
      const rateArr = rate.substr(rate.lastIndexOf("\\") + 1).split("-");
      return rateArr[0] * 100 + " - " + rateArr[1] * 100;
    }
    return "";
  };

  return (
    <div className="looking_for_candidate_boxs">
      <div className="looking_for_candidate_box">
        <div className="first_sec">
          <div className="image_sec">
            <img
              src={
                data.file_path != null
                  ? `https://admin.stage.mysenscare.com/assets/images/users/${data.file_path}`
                  : `${window.location.origin}/img/nany_img.png`
              }
              alt=""
            />
            <div className="heart_sec">
              <FavoriteSelect id={data.user_id} username={data.first_name} />
            </div>
          </div>
          <div className="nany_social">
            {data.phoneVerifiedStatus == 1 ? (
              <img
                src={`${window.location.origin}/images/nany_phone.svg`}
                alt=""
              />
            ) : (
              <PhoneIcon />
            )}
            <img src={`${window.location.origin}/images/nany_msg.svg`} alt="" />
            {data.facebookverify != null || data.linkdinverify != null ? (
              <img
                src={`${window.location.origin}/images/nany_cont.svg`}
                alt=""
              />
            ) : (
              <ServiceIcon />
            )}
          </div>
        </div>
        <div className="second_sec">
          <div className="heading">
            <h3>
              {data.jobs.length > 0 && data?.jobs[0]?.title != null
                ? data.jobs[0].title
                : ""}
            </h3>
            {data?.jobs[0]?.plateformonsocialmedia == "Yes" && (
              <SocialFacebook
                link={`${window.location.origin}/profile-parents/${data.user_id}`}
              />
            )}
          </div>
          <div className="post_general">
            <h6>
              <Link to={`/profile-parents/${data.user_id}`}>
                {data.first_name != null
                  ? `${data.first_name} ${data.last_name} `
                  : ""}
              </Link>
              (
              {data.dob != undefined
                ? new Date().getFullYear() -
                  parseInt(
                    data.dob
                      .substr(data.dob.lastIndexOf("\\") + 1)
                      .split("-")[0]
                  )
                : ""}
              )
            </h6>
            <p>
              {data.reviewAvg >= 0 && (
                <>
                  {[...Array(data.reviewAvg)].map((star, index) => (
                    <StarIcon key={index} />
                  ))}
                  {[...Array(5 - data.reviewAvg)].map((star, index) => (
                    <WhiteStarIcon key={index} />
                  ))}
                </>
              )}
              <span> ({data.reviewcount})</span>
            </p>
          </div>
          <div className="post_detail">
            <div className="post">
              {JSON.parse(data.service_type) != null &&
                (Object.values(JSON.parse(data.service_type))[0] == "Nanny" ? (
                  <img
                    src={`${window.location.origin}/images/nany_post.svg`}
                    alt=""
                  />
                ) : Object.keys(JSON.parse(data.service_type))[0] == "tab2" ? (
                  <img
                    src={`${window.location.origin}/images/teacher_post.svg`}
                    alt=""
                  />
                ) : Object.keys(JSON.parse(data.service_type))[0] == "tab3" ? (
                  <img
                    src={`${window.location.origin}/images/education_post.svg`}
                    alt=""
                  />
                ) : Object.keys(JSON.parse(data.service_type))[0] == "tab4" ? (
                  <img
                    src={`${window.location.origin}/images/tutor_post.svg`}
                    alt=""
                  />
                ) : (
                  ""
                ))}
              <h5>
                {JSON.parse(data.service_type) != null
                  ? t(Object.values(JSON.parse(data.service_type))[0])
                  : ""}
              </h5>
            </div>
            <div className="vi"></div>
            <div className="post_pay">
              <img src={`${window.location.origin}/img/post_pay.png`} alt="" />
              {profile && profile.country == "Serbia" ? (
                <h5>
                  {getRate(data.nanyperhrrate) || getRate(data.tutorperhrrate)}
                  {t("time-hour")}
                </h5>
              ) : (
                <h5>
                  $
                  {data.nanyperhrrate != null
                    ? data.nanyperhrrate
                    : data.tutorperhrrate
                    ? data.tutorperhrrate
                    : ""}
                  {t("time-hour")}
                </h5>
              )}
            </div>
            <div className="vi"></div>
            <div className="post_fet">
              <img
                src={`${window.location.origin}/images/post_fet.svg`}
                alt=""
              />
              <h5>
                {data.nanyintrestedin != null
                  ? t(data.nanyintrestedin)
                  : data.tutorintrestedin != null
                  ? t(data.tutorintrestedin)
                  : t("full-time")}
              </h5>
            </div>
            <div className="vi"></div>
            <div className="post_cal">
              <img
                src={`${window.location.origin}/images/post_cal.svg`}
                alt=""
              />
              <h5>
                {data.nanystartdate != null
                  ? data.nanystartdate
                  : data.tutorstartdate}
              </h5>
            </div>
            <div className="vi"></div>
            <div className="post_loc">
              <img
                src={`${window.location.origin}/images/post_loc.svg`}
                alt=""
              />
              <h5>
                {data.country != null ? data.country : ""} ,
                {data.city != null ? data.city : ""}
              </h5>
            </div>
          </div>
          <p id={`half${index}`}>
            {data?.jobs[0]?.description != null
              ? data?.jobs[0]?.description.substr(0, 100)
              : ""}
            {data?.jobs[0]?.description != null &&
              data?.jobs[0]?.description.length > 100 && (
                <span
                  onClick={() =>
                    handleElementById(
                      `half${index}`,
                      `full${index}`,
                      `plink${index}`
                    )
                  }
                >
                  {t("text-more")}
                </span>
              )}
          </p>
          <p id={`full${index}`} className="hide">
            {data?.jobs[0]?.description}
            <span
              onClick={() =>
                handleElementById(
                  `full${index}`,
                  `half${index}`,
                  `plink${index}`
                )
              }
            >
              {t("text-less")}
            </span>
          </p>
        </div>
        <div className="view_profile_btn newadd" style={{ float: "right" }}>
          <Link to={`/profile-parents/${data.user_id}`}>
            {t("visit-profile")}
          </Link>
          <button
            onClick={() => {
              setShowSendModal(true);
            }}
          >
            {t("send-documents")}
          </button>
        </div>
      </div>
    </div>
  );
};
