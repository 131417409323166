export const DownloadIcon = () => {
  return (
    <svg
      width="14"
      height="16"
      viewBox="0 0 14 16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0.748538 7.27273C1.16194 7.27273 1.49708 7.59834 1.49708 8V13.8182C1.49708 14.0111 1.57594 14.1961 1.71632 14.3324C1.8567 14.4688 2.04709 14.5455 2.24561 14.5455H11.2281C11.4266 14.5455 11.617 14.4688 11.7574 14.3324C11.8977 14.1961 11.9766 14.0111 11.9766 13.8182V8C11.9766 7.59834 12.3117 7.27273 12.7251 7.27273C13.1386 7.27273 13.4737 7.59834 13.4737 8V13.8182C13.4737 14.3968 13.2371 14.9518 12.816 15.361C12.3948 15.7701 11.8236 16 11.2281 16H2.24561C1.65004 16 1.07886 15.7701 0.657725 15.361C0.236591 14.9518 0 14.3968 0 13.8182V8C0 7.59834 0.335132 7.27273 0.748538 7.27273Z"
        fill="#A98D4B"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0.748538 7.27273C1.16194 7.27273 1.49708 7.59834 1.49708 8V13.8182C1.49708 14.0111 1.57594 14.1961 1.71632 14.3324C1.8567 14.4688 2.04709 14.5455 2.24561 14.5455H11.2281C11.4266 14.5455 11.617 14.4688 11.7574 14.3324C11.8977 14.1961 11.9766 14.0111 11.9766 13.8182V8C11.9766 7.59834 12.3117 7.27273 12.7251 7.27273C13.1386 7.27273 13.4737 7.59834 13.4737 8V13.8182C13.4737 14.3968 13.2371 14.9518 12.816 15.361C12.3948 15.7701 11.8236 16 11.2281 16H2.24561C1.65004 16 1.07886 15.7701 0.657725 15.361C0.236591 14.9518 0 14.3968 0 13.8182V8C0 7.59834 0.335132 7.27273 0.748538 7.27273Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.20755 0.213013C6.49987 -0.0710044 6.97382 -0.0710044 7.26614 0.213013L10.2603 3.1221C10.5526 3.40612 10.5526 3.86661 10.2603 4.15062C9.96797 4.43464 9.49402 4.43464 9.2017 4.15062L6.73684 1.75579L4.27199 4.15062C3.97966 4.43464 3.50572 4.43464 3.21339 4.15062C2.92107 3.86661 2.92107 3.40612 3.21339 3.1221L6.20755 0.213013Z"
        fill="#A98D4B"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.20755 0.213013C6.49987 -0.0710044 6.97382 -0.0710044 7.26614 0.213013L10.2603 3.1221C10.5526 3.40612 10.5526 3.86661 10.2603 4.15062C9.96797 4.43464 9.49402 4.43464 9.2017 4.15062L6.73684 1.75579L4.27199 4.15062C3.97966 4.43464 3.50572 4.43464 3.21339 4.15062C2.92107 3.86661 2.92107 3.40612 3.21339 3.1221L6.20755 0.213013Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.73684 1.08372e-08C7.15025 1.08372e-08 7.48538 0.325611 7.48538 0.727273V10.1818C7.48538 10.5835 7.15025 10.9091 6.73684 10.9091C6.32344 10.9091 5.9883 10.5835 5.9883 10.1818V0.727273C5.9883 0.325611 6.32344 1.08372e-08 6.73684 1.08372e-08Z"
        fill="#A98D4B"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.73684 1.08372e-08C7.15025 1.08372e-08 7.48538 0.325611 7.48538 0.727273V10.1818C7.48538 10.5835 7.15025 10.9091 6.73684 10.9091C6.32344 10.9091 5.9883 10.5835 5.9883 10.1818V0.727273C5.9883 0.325611 6.32344 1.08372e-08 6.73684 1.08372e-08Z"
        fill="white"
      />
    </svg>
  );
};
