import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Slider from "react-slick";

import { FacebookIcon } from "../../assets/image/FacebookIcon";
import { LinkedinIcon } from "../../assets/image/LinkedinIcon";
import { SocialFacebook } from "../../components/common/SocialFacebook";
import { StarIcon } from "../../assets/image/StarIcon";
import { WhiteStarIcon } from "../../assets/image/WhiteStarIcon";
import { FavoriteSelect } from "../../components/common/FavoriteSelect";
import { PhoneIcon } from "../../assets/image/PhoneIcon";
import { ServiceIcon } from "../../assets/image/ServiceIcon";

import { DEFAULT_API } from "../../utils/variables";

export const ProfileUsernameContent = ({
  localInfo,
  setLocalInfo,
  profile,
  selfProfile,
  setIsPerfectJobModal,
  setIsProviderModal,
  setIsJobApplicationModal,
  jobApply,
  setUserId,
  handleInformation,
  setIsOpenLink,
  sliderRef,
}) => {
  const { t } = useTranslation();

  const generalSettings = {
    dots: false,
    infinite: true,
    slidesToShow:
      profile.reviewData?.length > 2 ? 3 : profile.reviewData?.length,
    arrows: true,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 2000,
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          initialSlide: 3,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  };

  return (
    <>
      <div
        className={
          localInfo.security == "active" ? "active personal" : "personal"
        }
      >
        <h3
          onClick={(e) =>
            setLocalInfo({
              ...localInfo,
              security: localInfo.security == "" ? "active" : "",
            })
          }
        >
          {t("security-verification")}
        </h3>
        {localInfo.security == "active" && (
          <div className="editkids security mg">
            <label>
              {t("Verified accounts")}
              <span>
                <strong>
                  <div className="social_verify">
                    <FacebookIcon
                      className={
                        profile.facebookverify == "true" ? "active" : ""
                      }
                      fillColor={"#fff"}
                      color={"#B7B7B7"}
                      textColor={"#B7B7B7"}
                    />

                    <LinkedinIcon
                      className={
                        profile.linkdinverify == "true" ? "active" : ""
                      }
                      color={"#B7B7B7"}
                    />
                  </div>
                </strong>
              </span>
            </label>
          </div>
        )}
      </div>
      <div
        className={
          localInfo.Reviews == "active" ? "active personal" : "personal"
        }
      >
        <h3
          onClick={(e) =>
            setLocalInfo({
              ...localInfo,
              Reviews: localInfo.Reviews == "" ? "active" : "",
            })
          }
        >
          {t("text-reviews")}
        </h3>

        {localInfo.Reviews == "active" ? (
          profile.reviewData.length > 0 ? (
            <Slider
              ref={sliderRef}
              {...generalSettings}
              id="Slider-4"
              className="slider_test reviewshow"
            >
              {profile.reviewData?.map((data, index) => {
                if (data.status == 0) {
                  return (
                    <div className="item">
                      <div className="review_list">
                        <div className="left_img">
                          <img
                            src={
                              DEFAULT_API +
                              "/assets/images/users/" +
                              data.file_path
                            }
                          />
                        </div>
                        <div className="right_content">
                          <h2>{data.SenderName}</h2>
                          <span>
                            {[...Array(data.rating)].map((star, index) => {
                              index += 1;
                              return <StarIcon />;
                            })}
                            {[...Array(5 - data.rating)].map((star, index) => {
                              index += 1;
                              return <WhiteStarIcon />;
                            })}
                          </span>
                          <span className="date">
                            {new Date(data.updated_at).getDate()}/
                            {new Date(data.updated_at).getMonth() + 1}/
                            {new Date(data.updated_at).getFullYear()}
                          </span>
                          <h3>{data.review_title}</h3>
                          <p>{data.message}</p>
                        </div>
                      </div>
                    </div>
                  );
                }
              })}
            </Slider>
          ) : (
            <p className="no_active" style={{ margin: "25px 0" }}>
              {t("No reviews at this time")}
            </p>
          )
        ) : (
          ""
        )}
      </div>
      <div
        className={localInfo.Job == "active" ? "active personal" : "personal"}
      >
        <h3
          onClick={(e) =>
            setLocalInfo({
              ...localInfo,
              Job: localInfo.Job == "" ? "active" : "",
            })
          }
        >
          {t("job-posts")}
        </h3>
        {localInfo.Job == "active" && (
          <div className="right_side_section">
            <div className="looking_for_candidate">
              {profile.job &&
              profile.job.length > 0 &&
              profile.service_type &&
              Object.keys(profile.service_type).length > 0 ? (
                profile.job &&
                profile.job.map((data, index) => {
                  if (data.status == 0) {
                    return (
                      <div className="looking_for_candidate_boxs">
                        <div className="looking_for_candidate_box">
                          <div className="first_sec">
                            <div className="image_sec">
                              <img
                                src={
                                  profile.file_path != null
                                    ? DEFAULT_API +
                                      "/assets/images/users/" +
                                      profile.file_path
                                    : "img/nany_img.png"
                                }
                                alt=""
                              />
                              <div className="heart_sec">
                                {localStorage.getItem("user_type") ==
                                  "provider" && selfProfile.id != profile.id ? (
                                  <FavoriteSelect
                                    id={data.id}
                                    heart2={data.checkfavorNot}
                                    username={data.username}
                                  />
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                            <div className="nany_social">
                              {profile.phoneVerifiedStatus == 1 ? (
                                <img
                                  src={
                                    window.location.origin +
                                    "/images/nany_phone.svg"
                                  }
                                  alt=""
                                />
                              ) : (
                                <PhoneIcon />
                              )}
                              <img
                                src={
                                  window.location.origin +
                                  "/images/nany_msg.svg"
                                }
                                alt=""
                              />
                              {profile.facebookverify != null ||
                              profile.linkdinverify != null ? (
                                <img
                                  src={
                                    window.location.origin +
                                    "/images/nany_cont.svg"
                                  }
                                  alt=""
                                />
                              ) : (
                                <ServiceIcon />
                              )}
                            </div>
                          </div>
                          <div className="second_sec">
                            <div className="heading">
                              
                              <h3>
                                {data.title != null ? data.title : ""}
                                <div className="heart_sec">
                                  {localStorage.getItem("user_type") ==
                                    "provider" &&
                                  selfProfile.id != profile.id ? (
                                    <FavoriteSelect
                                      id={data.id}
                                      heart2={data.favorNot}
                                      username={data.username}
                                    />
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </h3>
                              {data.plateformonsocialmedia == "Yes" ? (
                                <SocialFacebook
                                  link={
                                    window.location.origin +
                                    "/profile-parents/" +
                                    profile.id
                                  }
                                />
                              ) : (
                                ""
                              )}
                            </div>
                            <div className="post_general">
                              
                              <h6>{data.school}</h6>
                              <p>
                                {profile.reviewAvg >= 0 ? (
                                  <>
                                    {[...Array(profile.reviewAvg)].map(
                                      (star, index23) => {
                                        index23 += 1;
                                        return <StarIcon />;
                                      }
                                    )}
                                    {[...Array(5 - profile.reviewAvg)].map(
                                      (star, index3) => {
                                        index3 += 1;
                                        return <WhiteStarIcon />;
                                      }
                                    )}
                                    <span>({profile.reviewcount})</span>
                                  </>
                                ) : (
                                  ""
                                )}
                              </p>
                            </div>
                            <div className="post_detail">
                              <div className="post">
                                {data.job_type != null ? (
                                  data.job_type == "Nanny" ? (
                                    <img
                                      src={
                                        window.location.origin +
                                        "/images/nany_post.svg"
                                      }
                                      alt=""
                                    />
                                  ) : data.job_type ==
                                    "Special Education Teacher" ? (
                                    <img
                                      src={
                                        window.location.origin +
                                        "/images/teacher_post.svg"
                                      }
                                      alt=""
                                    />
                                  ) : data.job_type ==
                                    "Special Education Paraprofessional" ? (
                                    <img
                                      src={
                                        window.location.origin +
                                        "/images/education_post.svg"
                                      }
                                      alt=""
                                    />
                                  ) : data.job_type == "Tutor" ? (
                                    <img
                                      src={
                                        window.location.origin +
                                        "/images/tutor_post.svg"
                                      }
                                      alt=""
                                    />
                                  ) : (
                                    ""
                                  )
                                ) : (
                                  ""
                                )}
                                <h5>
                                  {data.job_type != null
                                    ? t(data.job_type)
                                    : ""}
                                </h5>
                              </div>
                              <div className="vi"></div>
                              <div className="post_pay">
                                <img
                                  src={
                                    window.location.origin + "/img/post_pay.png"
                                  }
                                  alt=""
                                />
                                {profile && profile.country == "Serbia" ? (
                                  <h5>
                                    {profile.nanyperhrrate != null && index == 0
                                      ? profile.nanyperhrrate
                                          .substr(
                                            profile.nanyperhrrate.lastIndexOf(
                                              "\\"
                                            ) + 1
                                          )
                                          .split("-")[0] *
                                          100 +
                                        " - " +
                                        profile.nanyperhrrate
                                          .substr(
                                            profile.nanyperhrrate.lastIndexOf(
                                              "\\"
                                            ) + 1
                                          )
                                          .split("-")[1] *
                                          100
                                      : profile.tutorperhrrate
                                      ? profile.tutorperhrrate
                                          .substr(
                                            profile.tutorperhrrate.lastIndexOf(
                                              "\\"
                                            ) + 1
                                          )
                                          .split("-")[0] *
                                          100 +
                                        " - " +
                                        profile.tutorperhrrate
                                          .substr(
                                            profile.tutorperhrrate.lastIndexOf(
                                              "\\"
                                            ) + 1
                                          )
                                          .split("-")[1] *
                                          100
                                      : ""}
                                    {t("time-hour")}
                                  </h5>
                                ) : (
                                  <h5>
                                    $
                                    {profile.nanyperhrrate != null && index == 0
                                      ? profile.nanyperhrrate
                                      : profile.tutorperhrrate
                                      ? profile.tutorperhrrate
                                      : ""}
                                    {t("time-hour")}
                                  </h5>
                                )}
                              </div>
                              <div className="vi"></div>
                              <div className="post_fet">
                                <img
                                  src={
                                    window.location.origin +
                                    "/images/post_fet.svg"
                                  }
                                  alt=""
                                />
                                <h5>
                                  {profile.tutorintrestedin != null
                                    ? t(profile.tutorintrestedin)
                                    : ""}
                                </h5>
                              </div>
                              <div className="vi"></div>
                              <div className="post_cal">
                                <img
                                  src={
                                    window.location.origin +
                                    "/images/post_cal.svg"
                                  }
                                  alt=""
                                />
                                <h5>
                                  {profile.tutorstartdate != null
                                    ? profile.tutorstartdate
                                    : ""}
                                </h5>
                              </div>
                              <div className="vi"></div>
                              <div className="post_loc">
                                <img
                                  src={
                                    window.location.origin +
                                    "/images/post_loc.svg"
                                  }
                                  alt=""
                                />
                                <h5>
                                  {profile.country != null
                                    ? profile.country
                                    : ""}
                                  , {profile.city != null ? profile.city : ""}
                                </h5>
                              </div>
                            </div>
                            {data?.checkhiredorNot?.applyjob_hiredstatus == 0 ||
                            data?.checkhiredorNot?.applyjob_hiredstatus == 1 ||
                            (profile.invitationData &&
                              profile.invitationData.filter(
                                (e) => e.ProviderId == selfProfile.id
                              )[0]?.inviteStatus == 1) ? (
                              <p id={"half" + index}>
                                {data.description != null
                                  ? data.description
                                  : ""}
                              </p>
                            ) : (
                              <p id={"half" + index}>
                                {data.description != null
                                  ? data.description.substr(0, 100)
                                  : ""}
                                {data.description != null &&
                                data.description.length > 100 ? (
                                  <span
                                    onClick={(e) => {
                                      setIsOpenLink(true);
                                      handleInformation(
                                        "half" + index,
                                        "full" + index,
                                        "plink" + index
                                      );
                                    }}
                                  >
                                    {t("text-more")}
                                  </span>
                                ) : (
                                  ""
                                )}
                              </p>
                            )}
                            <p id={"full" + index} className="hide">
                              {data.description}
                              <span
                                onClick={(e) =>
                                  handleInformation(
                                    "full" + index,
                                    "half" + index,
                                    "plink" + index
                                  )
                                }
                              >
                                less
                              </span>
                            </p>
                            <div className="view_profile_btn">
                              {!localStorage.getItem("token") ||
                              !localStorage.getItem("id") ? (
                                <Link to="/signup">{t("Apply for Job")}</Link>
                              ) : jobApply &&
                                Object.values(jobApply).filter(
                                  (val) => val == data.id
                                )[0] ? (
                                <button style={{ opacity: "0.5" }}>
                                  {t("Applied")}
                                </button>
                              ) : data?.checkhiredorNot?.applyjob_hiredstatus ==
                                  0 ||
                                data?.checkhiredorNot?.applyjob_hiredstatus ==
                                  1 ||
                                (profile.invitationData &&
                                  profile.invitationData.filter(
                                    (e) => e.ProviderId == selfProfile.id
                                  )[0]?.inviteStatus == 1) ? (
                                <button style={{ opacity: "0.5" }}>
                                  {t("Applied")}
                                </button>
                              ) : (
                                <button
                                  style={
                                    selfProfile.user_type == "provider"
                                      ? {}
                                      : { opacity: "0.5" }
                                  }
                                  onClick={(e) => {
                                    selfProfile.user_type == "provider" &&
                                    selfProfile.backgroundstatus == null
                                      ? setIsPerfectJobModal(true)
                                      : selfProfile.user_type == "parents" &&
                                        !selfProfile.job[0]
                                      ? setIsProviderModal(true)
                                      : setIsJobApplicationModal(true);
                                    setUserId({
                                      id: data.id,
                                      userid: data.user_id,
                                      index: index,
                                    });
                                  }}
                                  disabled={
                                    localStorage.getItem("user_type") !=
                                    "provider"
                                      ? true
                                      : false
                                  }
                                >
                                  {t("Apply for Job")}
                                </button>
                              )}
                            </div>
                          </div>
                        </div>
                        <p>
                          {t("Please refer to our")}
                          <Link to="/faq" target="_blank">
                            {t("text-faq")}
                          </Link>
                          {t("text-and")}
                          <Link to="/safety-center" target="_blank">
                            {t("safety-center")}
                          </Link>
                          {t("read-safety-tips")}
                        </p>
                      </div>
                    );
                  }
                })
              ) : (
                <p className="no_active">
                  {t("No active job post at this time")}
                </p>
              )}
            </div>
          </div>
        )}
      </div>
      <div
        className={localInfo.About == "active" ? "active personal" : "personal"}
      >
        <h3
          onClick={(e) =>
            setLocalInfo({
              ...localInfo,
              About: localInfo.About == "" ? "active" : "",
            })
          }
        >
          {t("About")}
        </h3>
        {localInfo.About == "active" && (
          <div className="editkids aboutdetail">
            <div className="left2">
              <label>
                <span className="half">{t("First Name")} </span>
                <span>
                  <strong>{profile.first_name}</strong>
                </span>
              </label>
              <label>
                <span className="half">{t("Last Name")} </span>
                <span>
                  <strong>{profile.last_name}</strong>
                </span>
              </label>
              <label>
                <span className="half">{t("Date of birth")}</span>
                <span>
                  <strong>{profile.dob}</strong>
                </span>
              </label>
            </div>
            <div className="right2">
              <label>
                <span className="half">{t("text-city")} </span>
                <span>
                  <strong>{profile.city}</strong>
                </span>
              </label>
              <label>
                <span className="half">{t("zip-code")} </span>
                <span>
                  <strong>{profile.zip}</strong>
                </span>
              </label>
              <label>
                <span className="half">{t("Country")} </span>
                <span>
                  <strong>{profile.country}</strong>
                </span>
              </label>
            </div>
          </div>
        )}
      </div>
    </>
  );
};
