export const TutorSecondaryIcon = () => {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 70 70"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M49.154 30.3523L35.4881 24.1236C35.1302 23.9588 34.7397 23.9588 34.4143 24.1236L20.7484 30.3523C20.2928 30.55 20 31.0114 20 31.5057C20 32.0001 20.3254 32.4615 20.7809 32.6921L21.9523 33.2524V37.8663C21.9523 38.3277 22.3102 38.6902 22.7657 38.6902C23.2213 38.6902 23.5792 38.3277 23.5792 37.8663V34.1422L25.5315 35.1968V40.7993C25.5315 41.03 25.5965 41.2278 25.7267 41.4255C25.8568 41.6233 28.8178 46.1053 34.8048 46.1053C40.9544 46.1053 43.7852 41.5903 43.9154 41.4255C44.013 41.2608 44.1106 41.0301 44.1106 40.8323V35.4275L49.2842 32.7581C49.7397 32.5274 50 32.066 50 31.5716C49.9349 31.0114 49.6095 30.583 49.154 30.3523ZM41.8004 40.4369C41.1497 41.2278 38.9371 43.7984 34.8048 43.7984C30.7701 43.7984 28.4599 41.2608 27.8091 40.4369V36.3832L34.3818 39.7777C34.577 39.8766 34.7722 39.9095 34.9675 39.9095C35.1627 39.9095 35.3905 39.8766 35.5857 39.7777L41.8004 36.5809V40.4369ZM34.9675 37.1083L24.295 31.6376L34.9675 26.7601L45.6399 31.6376L34.9675 37.1083ZM23.5792 39.8436C23.8069 40.0743 23.9696 40.4369 23.9696 40.7664C23.9696 41.096 23.8395 41.4584 23.5792 41.6891C23.3514 41.9198 22.9935 42.0847 22.6681 42.0847C22.3427 42.0847 21.9848 41.9528 21.757 41.6891C21.5293 41.4584 21.3666 41.096 21.3666 40.7664C21.3666 40.4369 21.4967 40.0743 21.757 39.8436C21.9848 39.6129 22.3427 39.4482 22.6681 39.4482C22.9935 39.4482 23.3189 39.58 23.5792 39.8436Z"
        fill="white"
      />
      <path
        d="M49.154 30.3523L35.4881 24.1236C35.1302 23.9588 34.7397 23.9588 34.4143 24.1236L20.7484 30.3523C20.2928 30.55 20 31.0114 20 31.5057C20 32.0001 20.3254 32.4615 20.7809 32.6921L21.9523 33.2524V37.8663C21.9523 38.3277 22.3102 38.6902 22.7657 38.6902C23.2213 38.6902 23.5792 38.3277 23.5792 37.8663V34.1422L25.5315 35.1968V40.7993C25.5315 41.03 25.5965 41.2278 25.7267 41.4255C25.8568 41.6233 28.8178 46.1053 34.8048 46.1053C40.9544 46.1053 43.7852 41.5903 43.9154 41.4255C44.013 41.2608 44.1106 41.0301 44.1106 40.8323V35.4275L49.2842 32.7581C49.7397 32.5274 50 32.066 50 31.5716C49.9349 31.0114 49.6095 30.583 49.154 30.3523ZM41.8004 40.4369C41.1497 41.2278 38.9371 43.7984 34.8048 43.7984C30.7701 43.7984 28.4599 41.2608 27.8091 40.4369V36.3832L34.3818 39.7777C34.577 39.8766 34.7722 39.9095 34.9675 39.9095C35.1627 39.9095 35.3905 39.8766 35.5857 39.7777L41.8004 36.5809V40.4369ZM34.9675 37.1083L24.295 31.6376L34.9675 26.7601L45.6399 31.6376L34.9675 37.1083ZM23.5792 39.8436C23.8069 40.0743 23.9696 40.4369 23.9696 40.7664C23.9696 41.096 23.8395 41.4584 23.5792 41.6891C23.3514 41.9198 22.9935 42.0847 22.6681 42.0847C22.3427 42.0847 21.9848 41.9528 21.757 41.6891C21.5293 41.4584 21.3666 41.096 21.3666 40.7664C21.3666 40.4369 21.4967 40.0743 21.757 39.8436C21.9848 39.6129 22.3427 39.4482 22.6681 39.4482C22.9935 39.4482 23.3189 39.58 23.5792 39.8436Z"
        fill="#A98D4B"
      />
    </svg>
  );
};
