import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { Link } from "react-router-dom";
import Modal from "react-bootstrap/Modal";

import { api } from "../../../urls";
import { MessageContent } from "../../Message/MessageContent";
import { CrownIcon } from "../../../assets/image/CrownIcon";
import {
  ENG_LANGUAGE,
  MONTH_ENG_LANGUAGE,
  MONTH_SRB_LANGUAGE,
} from "../../../utils/variables";
import { JobCard } from "./JobCard";

export function JobApplicationDetail(props) {
  const { t, i18n } = useTranslation();
  const language = i18n.language;

  const [providerData, setProviderData] = useState([]);
  const [jobData, setJobData] = useState({});
  const [isCheck, setIsCheck] = useState(true);
  const [isUpdate, setIsUpdate] = useState(false);

  const [profile] = useState();
  const [view, setView] = useState("");
  const [isElement, setIsElement] = useState(true);
  const [hired, setHired] = useState("");
  const [message, setMessage] = useState("");

  const month =
    language === ENG_LANGUAGE ? MONTH_ENG_LANGUAGE : MONTH_SRB_LANGUAGE;

  const handleElementById = (e, x, z) => {
    console.log(e, x);
    document.getElementById(e).style.display = "none";
    document.getElementById(x).style.display = "block";
    if (isElement) {
      document.getElementById(z).style.display = "block";
      setIsElement(false);
    } else {
      setIsElement(true);
      document.getElementById(z).style.display = "none";
    }
  };

  const handleProfileData = () => {
    const myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );
    myHeaders.append("Content-Type", "application/json");
    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify({
        appjob_id: JSON.parse(props.id),
      }),
      redirect: "follow",
    };

    fetch(api + "/api/v1/jobapplicationprofileview", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setJobData(result.data);
        setProviderData([result.data.providerprofile]);
        setMessage(result.data.currentUserName);
        console.log(result, JSON.parse(props.id));
      })
      .catch((error) => console.log("error", error));
  };

  useEffect(() => {
    if (isCheck) {
      handleProfileData();
      setIsCheck(false);
    }
  }, [isCheck, hired]);
  console.log(
    new Date(providerData && providerData[0] && providerData[0].created_at),
    providerData && providerData[0] && providerData[0].created_at
  );

  const handleAcceptData = (a) => {
    const myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      Appjob_ID: a,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(api + "/api/v1/appliedproviderhire", requestOptions)
      .then((response) => response.text())
      .then((result) => {
        setHired("Hired Successfully");
        console.log(result);
      })
      .catch((error) => console.log("error", error));
  };

  return (
    <>
      {providerData && providerData[0] && view == "" && (
        <>
          <h2 className="border"></h2>
          <div className="detail_invit">
            <p>
              {providerData &&
              providerData[0] &&
              providerData[0].read_status == 0 ? (
                <span className="new">{t("text-new-up")}</span>
              ) : (
                ""
              )}
              {providerData &&
              providerData[0] &&
              providerData[0].profile_headline != null
                ? providerData &&
                  providerData[0] &&
                  providerData[0].profile_headline
                : ""}
              <span className="date">
                {new Date(
                  providerData && providerData[0] && providerData[0].created_at
                ).toLocaleDateString("en-US", { weekday: "short" })}
                ,
                {month[
                  new Date(
                    providerData &&
                      providerData[0] &&
                      providerData[0].created_at
                  ).getMonth()
                ] +
                  " " +
                  new Date(
                    providerData &&
                      providerData[0] &&
                      providerData[0].created_at
                  ).getDate()}
                ,
                {new Date(
                  providerData && providerData[0] && providerData[0].created_at
                ).getFullYear()}
                ,
                {new Date(
                  providerData && providerData[0] && providerData[0].created_at
                ).toLocaleString([], {
                  hour: "numeric",
                  minute: "numeric",
                  hour12: true,
                })}
              </span>
            </p>
            <h2>
              {t("text-dear")} <span>{message}</span>,
            </h2>
            <h2>
              {t("congratulations-with")}
              <Link to={"/profile-provider/" + providerData[0]?.user_id} style={{
                marginLeft: "3px",
                marginRight: "3px"
              }}>
                
                {providerData && providerData[0] && providerData[0].first_name}
              </Link>
              {t("applied-job-post")}
            </h2>

            <p style={{ paddingTop: "15px" }}>
              {t(
                "next-step-interview"
              )}
              <Link style={{
                marginLeft: "3px"
              }} to="/safety-center" target="_blank">
                {t("safety-center")}
              </Link>
              {t("text-and")}
              <Link to="/faq" target="_blank">
                {t("text-faq")}
              </Link>
              .
            </p>
          </div>
          <div class="right_side_section jobapli">
            <div class="looking_for_candidate">
              {providerData.map((data, index) => {
                return (
                  <JobCard
                    key={index}
                    index={index}
                    data={data}
                    jobData={jobData}
                    hired={hired}
                    handleAcceptData={handleAcceptData}
                    setView={setView}
                    profile={profile}
                    handleElementById={handleElementById}
                    providerData={providerData}
                  />
                );
              })}
              <div className="mobile">
                {providerData.map((data, index) => {
                  if (index <= 5) {
                    return (
                      <JobCard
                        key={index}
                        index={index}
                        data={data}
                        jobData={jobData}
                        hired={hired}
                        handleAcceptData={handleAcceptData}
                        setView={setView}
                        handleElementById={handleElementById}
                        profile={profile}
                        providerData={providerData}
                      />
                    );
                  }
                })}
              </div>
            </div>
          </div>
        </>
      )}

      {view != "" && (
        <div class="main-header">
          <MessageContent id={view} setview={setView} />
        </div>
      )}

      <Modal show={isUpdate} onHide={(e) => setIsUpdate(false)}>
        <Modal.Body>
          <div className="promocode_content login_first search_pop upgd">
            <Link to="" onClick={(e) => setIsUpdate(false)}>
              +
            </Link>
            <h2>
              {t(
                "post-full-benefits"
              )}
            </h2>
            <img src={window.location.origin + "/images/promo.png"} />
            <p>
              {t(
                " When you upgrade your account it will enable you to connect with other members via our message center, send and receive interview invites, apply for jobs and much more."
              )}
            </p>
            <button onClick={(e) => setIsUpdate(false)} className={"ruf"}>
              <Link to={""}> {t("text-maybe-later")}</Link>
            </button>
            <button onClick={(e) => setIsUpdate(false)}>
              <Link to={""}>
                <CrownIcon />
                {t("upgrade-now")}
              </Link>
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
