import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { MessageContent } from "../../Message/MessageContent";
import { RaffleIcon } from "../../../assets/image/RaffleIcon";
import { ArrowLeftIcon } from "../../../assets/image/ArrowLeftIcon";
import { MessageIcon } from "../../../assets/image/MessageIcon";
import {
  fetchDeleteTrashNotifications,
  fetchTrashNotifiration,
} from "../../../api/notificationApi";
import { TrashTableRow } from "./TrashTableRow";

export function TrashList() {
  const { t } = useTranslation();

  const [trashData, setTrashData] = useState([]);
  const [view, setView] = useState("");
  const [deleteList, setDeleteList] = useState([]);
  const [recived, setRecived] = useState("");
  const [isActive, setIsActive] = useState(false);
  const [isCheck, setIsCheck] = useState(true);
  const [refine, setRefine] = useState({
    weekly: "",
    monthly: "",
  });

  const slugData = useParams();
  let data = slugData.name;

  const fetchTrashData = async () => {
    setTrashData([]);
    try {
      const result = await fetchTrashNotifiration(refine);
      setTrashData(result.data.getallNotifications);
    } catch (error) {
      console.error("Error fetching trash data:", error);
    }
  };

  useEffect(() => {
    if (isCheck) {
      fetchTrashData();
      setIsCheck(false);
    }

    setView(data ? data : "");
  }, [isCheck, trashData, deleteList, refine, data, slugData]);

  useEffect(() => {
    if (window.innerWidth < 768) {
      setRecived("text-refine");
    }
  }, []);

  const handleSelectElement = () => {
    const ele = document.getElementsByName("chk");
    trashData.map((data) => {
      deleteList.push(data.id);
    });
    for (let i = 0; i < ele.length; i++) {
      if (ele[i].type == "checkbox") ele[i].checked = true;
    }
  };
  const handleDeselectElement = () => {
    const ele = document.getElementsByName("chk");
    for (let i = 0; i < ele.length; i++) {
      if (ele[i].type == "checkbox") ele[i].checked = false;
    }
    setDeleteList([]);
  };

  const handleDeleteSelect = (a) => {
    let sum = false;
    deleteList.map((e) => {
      if (e == a) {
        sum = true;
        const index = deleteList.indexOf(a);
        if (index > -1) {
          deleteList.splice(index, 1);
        }
      }
    });
    if (sum == false) {
      deleteList.push(a);
    }
    setTrashData(trashData);
  };

  const handleDeleteTrashNotification = async () => {
    try {
      await fetchDeleteTrashNotifications(deleteList);
      fetchTrashData();
      setDeleteList([]);
    } catch (error) {
      console.error("Error deleting trash notifications:", error);
    }
  };

  return (
    <div>
      {view == "" ? (
        <>
          <div class="main-header">
            <h2 className="border">{t("text-trash")}</h2>
            <div class="mail-header-bar">
              <p>
                {trashData.length} {t("text-messages")}
              </p>
              <div class="btn-group flex">
                {t("text-refine")}
                <div className="select">
                  <label onClick={(e) => setIsActive(!isActive)}>
                    {t(recived)}
                    <span>
                      <img src="/images/done_a.svg" />
                    </span>
                  </label>
                  {isActive ? (
                    <ul>
                      <li
                        onClick={(e) => {
                          setRecived(t("7 hours"));
                          setRefine({
                            weekly: true,
                            monthly: "",
                          });
                          setIsCheck(true);
                          setIsActive(!isActive);
                        }}
                      >
                        {t("days-7")}
                      </li>
                      <li
                        onClick={(e) => {
                          setRecived(t("days-30"));
                          setRefine({
                            weekly: "",
                            monthly: true,
                          });
                          setIsCheck(true);
                          setIsActive(!isActive);
                        }}
                      >
                        {t("days-30")}
                      </li>
                    </ul>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
            <div class="mail-option">
              <div class="chk-all">
                <input
                  type="checkbox"
                  class="mail-radio mail-group-radio"
                  name="chk"
                  id="checkbox_id"
                  onClick={(e) => {
                    if (e.target.checked) {
                      handleSelectElement();
                    } else {
                      handleDeselectElement();
                    }
                  }}
                />
                <span className="circle"></span>
                <label for="checkbox_id">{t("select-all")}</label>
              </div>
              <div class="right-side-btn">
                <div
                  class="delete-btn"
                  onClick={(e) => handleDeleteTrashNotification()}
                >
                  <a href="#">
                    <RaffleIcon />
                    <span> {t("text-delete")}</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
          {trashData[0] ? (
            <table class="table table-inbox table-hover hovertable message_list trash">
              <tbody>
                {trashData.map((data) => {
                  return (
                    <TrashTableRow
                      key={data.msg_id}
                      data={data}
                      handleDeleteSelect={handleDeleteSelect}
                    />
                  );
                })}
              </tbody>
            </table>
          ) : (
            <div className="noresult">
              <h3>{t("messages-display")}</h3>
              <MessageIcon />
            </div>
          )}
        </>
      ) : (
        <div class="main-header">
          <button onClick={(e) => setView("")}>
            <ArrowLeftIcon />
            {t("text-back")}
          </button>
          <MessageContent id={view} />
        </div>
      )}
    </div>
  );
}
