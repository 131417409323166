export const TeacherSecondaryIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 70 70"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M51.3966 37.3731C48.8738 37.3731 47.7812 39.2928 46.4105 39.2928C42.7662 39.2928 46.0833 28.5835 46.0833 28.5835C46.0833 28.5835 34.1561 33.4446 34.1561 28.3832C34.1561 26.2062 36.3611 25.5728 36.3611 23.1584C36.3611 21.0011 34.6527 19.8335 32.6265 19.8335C30.5208 19.8335 28.5938 20.9814 28.5938 23.2573C28.5938 25.7707 30.5207 26.8592 30.5207 28.2247C30.5208 32.4553 19.8333 29.9664 19.8333 29.9664V50.2151C19.8333 50.2151 30.6879 52.7093 30.6879 48.4735C30.6879 47.108 28.2573 46.0365 28.2573 43.523C28.2573 41.2471 30.0347 40.0992 32.1205 40.0992C34.1666 40.0992 35.875 41.2669 35.875 43.424C35.875 45.8385 33.67 46.4718 33.67 48.6488C33.67 52.3435 41.5495 50.2123 44.6822 50.2123C44.6822 50.2123 42.5722 42.9145 46.2516 42.9145C48.4368 42.9145 49.0724 45.1112 51.496 45.1112C53.6613 45.1113 54.8333 43.4093 54.8333 41.3708C54.8333 39.2928 53.6811 37.3731 51.3966 37.3731Z"
        fill="#A98D4B"
      />
    </svg>
  );
};
