import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { useSearchDetailsStore } from "../../store/useSearchDetailsStore";
import { FavoriteSelect } from "../../components/common/FavoriteSelect";

import { StarIcon } from "../../assets/image/StarIcon";
import { SocialFacebook } from "../../components/common/SocialFacebook";
import { WhiteStarIcon } from "../../assets/image/WhiteStarIcon";
import { PhoneIcon } from "../../assets/image/PhoneIcon";
import { ServiceIcon } from "../../assets/image/ServiceIcon";


export const CandidateList = ({ data, index, toggleMoreContent }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { profile } = useSearchDetailsStore();

  return (
    <div className="mobile_looking_for_candidate_box">
      <div className="head_part">
        <h3
          onClick={(e) => {
            navigate("/profile-parents/" + data.id);
          }}
        >
          {data.title != null ? data.title : ""}
        </h3>
        {data.plateformonsocialmedia == "Yes" && (
          <SocialFacebook
            link={window.location.origin + "/profile-parents/" + data.id}
          />
        )}
        
      </div>
      <div className="post_general">
        <h6
          onClick={(e) => {
            navigate("/profile-parents/" + data.id);
          }}
        >
          {data.first_name + " " + data.last_name} (
          {data.dob != undefined
            ? new Date().getFullYear() -
              parseInt(
                data.dob.substr(data.dob.lastIndexOf("\\") + 1).split("-")[0]
              )
            : ""}
          )
        </h6>
        <p>
          {data.reviewAvg >= 0 ? (
            <>
              {[...Array(data.reviewAvg)].map((star, index) => {
                index += 1;
                return <StarIcon />;
              })}
              {[...Array(5 - data.reviewAvg)].map((star, index) => {
                index += 1;
                return <WhiteStarIcon />;
              })}
            </>
          ) : (
            ""
          )}
          <span> ({data.reviewcount})</span>
        </p>
      </div>
      <div className="main_sec">
        <div className="first_sec">
          <div className="image_sec">
            <img
              onClick={(e) => {
                navigate("/profile-parents/" + data.id);
              }}
              src={
                data.file_path != null
                  ? "https://admin.stage.mysenscare.com/assets/images/users/" +
                    data.file_path
                  : window.location.origin + "/img/nany_img.png"
              }
              alt=""
            />
            <div className="heart_sec">
              {localStorage.getItem("user_type") != "parents" ? (
                <FavoriteSelect
                  id={data.jobId}
                  username={data.first_name}
                  heart2={data.favorNot}
                />
              ) : (
                ""
              )}
            </div>
            <div className="nany_social">
              {data.phoneVerifiedStatus == 1 ? (
                <img
                  src={window.location.origin + "/images/nany_phone.svg"}
                  alt=""
                />
              ) : (
                <PhoneIcon />
              )}
              <img
                src={window.location.origin + "/images/nany_msg.svg"}
                alt=""
              />
              {data.facebookverify != null || data.linkdinverify != null ? (
                <img
                  src={window.location.origin + "/images/nany_cont.svg"}
                  alt=""
                />
              ) : (
                <ServiceIcon />
              )}
            </div>
          </div>
        </div>
        <div className="second_sec">
          <div className="post">
            {data.service_type != null ? (
              Object.values(data.service_type)[0] == "Nanny" ? (
                <img
                  src={window.location.origin + "/images/nany_post.svg"}
                  alt=""
                />
              ) : Object.keys(data.service_type)[0] == "tab2" ? (
                <img
                  src={window.location.origin + "/images/teacher_post.svg"}
                  alt=""
                />
              ) : Object.keys(data.service_type)[0] == "tab3" ? (
                <img
                  src={window.location.origin + "/images/education_post.svg"}
                  alt=""
                />
              ) : Object.keys(data.service_type)[0] == "tab4" ? (
                <img
                  src={window.location.origin + "/images/tutor_post.svg"}
                  alt=""
                />
              ) : (
                ""
              )
            ) : (
              ""
            )}
            <h5>
              {data.service_type != null
                ? Object.values(data.service_type)[0] ==
                  "Special Education Paraprofessional"
                  ? t("text-paraprofessional")
                  : Object.values(data.service_type)[0] ==
                    "Special Education Teacher"
                  ? t("sped-teacher")
                  : t(Object.values(data.service_type)[0])
                : ""}
              {/* {Object.values(data.service_type) + ","} */}
            </h5>
          </div>
          <div className="post">
            <img src={window.location.origin + "/img/post_pay.png"} alt="" />
            {profile && profile.country == "Serbia" ? (
              <h5>
                {Object.keys(data.service_type)[0] == "tab1"
                  ? data.nanyperhrrate != null
                    ? data.nanyperhrrate
                        .substr(data.nanyperhrrate.lastIndexOf("\\") + 1)
                        .split("-")[0] *
                        100 +
                      " - " +
                      data.nanyperhrrate
                        .substr(data.nanyperhrrate.lastIndexOf("\\") + 1)
                        .split("-")[1] *
                        100
                    : ""
                  : Object.keys(data.service_type)[0] == "tab2"
                  ? data.seterhrrate != null
                    ? data.seterhrrate
                        .substr(data.seterhrrate.lastIndexOf("\\") + 1)
                        .split("-")[0] *
                        100 +
                      " - " +
                      data.seterhrrate
                        .substr(data.seterhrrate.lastIndexOf("\\") + 1)
                        .split("-")[1] *
                        100
                    : ""
                  : Object.keys(data.service_type)[0] == "tab3"
                  ? data.seperhrrate != null
                    ? data.seperhrrate
                        .substr(data.seperhrrate.lastIndexOf("\\") + 1)
                        .split("-")[0] *
                        100 +
                      " - " +
                      data.seperhrrate
                        .substr(data.seperhrrate.lastIndexOf("\\") + 1)
                        .split("-")[1] *
                        100
                    : ""
                  : Object.keys(data.service_type)[0] == "tab4"
                  ? data.tutorperhrrate != null
                    ? data.tutorperhrrate
                        .substr(data.tutorperhrrate.lastIndexOf("\\") + 1)
                        .split("-")[0] *
                        100 +
                      " - " +
                      data.tutorperhrrate
                        .substr(data.tutorperhrrate.lastIndexOf("\\") + 1)
                        .split("-")[1] *
                        100
                    : ""
                  : ""}
                {t("time-hour")}
              </h5>
            ) : (
              <h5>
                $
                {Object.keys(data.service_type)[0] == "tab1"
                  ? data.nanyperhrrate != null
                    ? data.nanyperhrrate
                    : ""
                  : Object.keys(data.service_type)[0] == "tab2"
                  ? data.seterhrrate
                    ? data.seterhrrate
                    : ""
                  : Object.keys(data.service_type)[0] == "tab3"
                  ? data.seperhrrate
                    ? data.seperhrrate
                    : ""
                  : Object.keys(data.service_type)[0] == "tab4"
                  ? data.tutorperhrrate
                    ? data.tutorperhrrate
                    : ""
                  : ""}
                {t("time-hour")}
              </h5>
            )}
          </div>
          {data.service_type != null && Object.values(data.service_type)[1] ? (
            <>
              <div className="post">
                {data.service_type != null ? (
                  Object.values(data.service_type)[1] == "Nanny" ? (
                    <img
                      src={window.location.origin + "/images/nany_post.svg"}
                      alt=""
                    />
                  ) : Object.keys(data.service_type)[1] == "tab2" ? (
                    <img
                      src={window.location.origin + "/images/teacher_post.svg"}
                      alt=""
                    />
                  ) : Object.keys(data.service_type)[1] == "tab3" ? (
                    <img
                      src={
                        window.location.origin + "/images/education_post.svg"
                      }
                      alt=""
                    />
                  ) : Object.keys(data.service_type)[1] == "tab4" ? (
                    <img
                      src={window.location.origin + "/images/tutor_post.svg"}
                      alt=""
                    />
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )}
                <h5>
                  {data.service_type != null
                    ? Object.values(data.service_type)[1] ==
                      "Special Education Paraprofessional"
                      ? t("text-paraprofessional")
                      : Object.values(data.service_type)[1] ==
                        "Special Education Teacher"
                      ? t("sped-teacher")
                      : t(Object.values(data.service_type)[1])
                    : ""}
                  {/* {Object.values(data.service_type) + ","} */}
                </h5>
              </div>
              <div className="post">
                <img
                  src={window.location.origin + "/img/post_pay.png"}
                  alt=""
                />
                {profile && profile.country == "Serbia" ? (
                  <h5>
                    {Object.keys(data.service_type)[1] == "tab1"
                      ? data.nanyperhrrate != null
                        ? data.nanyperhrrate
                            .substr(data.nanyperhrrate.lastIndexOf("\\") + 1)
                            .split("-")[0] *
                            100 +
                          " - " +
                          data.nanyperhrrate
                            .substr(data.nanyperhrrate.lastIndexOf("\\") + 1)
                            .split("-")[1] *
                            100
                        : ""
                      : Object.keys(data.service_type)[1] == "tab2"
                      ? data.seterhrrate != null
                        ? data.seterhrrate
                            .substr(data.seterhrrate.lastIndexOf("\\") + 1)
                            .split("-")[0] *
                            100 +
                          " - " +
                          data.seterhrrate
                            .substr(data.seterhrrate.lastIndexOf("\\") + 1)
                            .split("-")[1] *
                            100
                        : ""
                      : Object.keys(data.service_type)[1] == "tab3"
                      ? data.seperhrrate != null
                        ? data.seperhrrate
                            .substr(data.seperhrrate.lastIndexOf("\\") + 1)
                            .split("-")[0] *
                            100 +
                          " - " +
                          data.seperhrrate
                            .substr(data.seperhrrate.lastIndexOf("\\") + 1)
                            .split("-")[1] *
                            100
                        : ""
                      : Object.keys(data.service_type)[1] == "tab4"
                      ? data.tutorperhrrate != null
                        ? data.tutorperhrrate
                            .substr(data.tutorperhrrate.lastIndexOf("\\") + 1)
                            .split("-")[0] *
                            100 +
                          " - " +
                          data.tutorperhrrate
                            .substr(data.tutorperhrrate.lastIndexOf("\\") + 1)
                            .split("-")[1] *
                            100
                        : ""
                      : ""}
                    {t("time-hour")}
                  </h5>
                ) : (
                  <h5>
                    $
                    {Object.keys(data.service_type)[1] == "tab1"
                      ? data.nanyperhrrate != null
                        ? data.nanyperhrrate
                        : ""
                      : Object.keys(data.service_type)[1] == "tab2"
                      ? data.seterhrrate
                        ? data.seterhrrate
                        : ""
                      : Object.keys(data.service_type)[1] == "tab3"
                      ? data.seperhrrate
                        ? data.seperhrrate
                        : ""
                      : Object.keys(data.service_type)[1] == "tab4"
                      ? data.tutorperhrrate
                        ? data.tutorperhrrate
                        : ""
                      : ""}
                    {t("time-hour")}
                  </h5>
                )}
              </div>
            </>
          ) : (
            ""
          )}

          <div className="post">
            <img src={window.location.origin + "/images/post_fet.svg"} alt="" />
            <h5>
              {data.tutorintrestedin != null
                ? t(data.tutorintrestedin)
                : t("full-time")}
            </h5>
          </div>
          <div className="post">
            <img src={window.location.origin + "/img/post_cal.png"} alt="" />
            <h5>{data.tutorstartdate != null ? data.tutorstartdate : ""}</h5>
          </div>
          <div className="post">
            <img src={window.location.origin + "/img/post_loc.png"} alt="" />
            <h5>
              {data.country != null ? data.country : ""} ,
              {data.city != null ? data.city : ""}
            </h5>
          </div>
        </div>
      </div>
      <p id={"show" + index}>
        {data.description != null ? data.description.substr(0, 100) : ""}
        {data.description != null && data.description.length > 100 ? (
          <span
            onClick={(e) =>
              toggleMoreContent("show" + index, "hide" + index, "plink" + index)
            }
          >
            {t("text-more")}
          </span>
        ) : (
          ""
        )}
      </p>
      <p id={"hide" + index} className="hide">
        {data.description}
        <span
          onClick={(e) =>
            toggleMoreContent("hide" + index, "show" + index, "plink" + index)
          }
        >
          {t("text-less")}
        </span>
      </p>

      <div className="view_profile_btn">
        {/* {!localStorage.getItem("token") || !localStorage.getItem("id") ? <Link to="/signup">View profile</Link> : */}
        <button
          onClick={(e) => {
            navigate("/profile-parents/" + data.id);
          }}
        >
          {t("view-profile")}
        </button>
      </div>
    </div>
  );
};
