import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import {
  ENG_LANGUAGE,
  MONTH_ENG_LANGUAGE,
  MONTH_SRB_LANGUAGE,
} from "../../../utils/variables";
import { fetchMembershipData } from "../../../api/membershipApi";

export function LoyaltyDetail(props) {
  const { t, i18n } = useTranslation();
  const language = i18n.language;

  const [membershipData, setMembershipData] = useState([]);
  const [isCheck, setIsCheck] = useState(true);
  const [message, setMessage] = useState("");

  const month =
    language === ENG_LANGUAGE ? MONTH_ENG_LANGUAGE : MONTH_SRB_LANGUAGE;

  const handleMembershipData = async () => {
    try {
      const result = await fetchMembershipData(props.id);
      setMembershipData([result.data.data]);
      setMessage(result.data.currentUserName);
      console.log(result);
    } catch (error) {
      console.error("Error fetching membership data:", error);
    }
  };

  useEffect(() => {
    if (isCheck) {
      handleMembershipData();
      setIsCheck(false);
    }
  }, [isCheck]);

  return (
    <>
      {membershipData && membershipData[0] && (
        <>
          <h2 className="border"></h2>
          {membershipData &&
          membershipData[0] &&
          membershipData[0].type != "plans" ? (
            <div className="detail_invit">
              <p>
                <span className="date">
                  {new Date(
                    membershipData &&
                      membershipData[0] &&
                      membershipData[0].created_at
                  ).toLocaleDateString("en-US", { weekday: "short" })}
                  ,
                  {month[
                    new Date(
                      membershipData &&
                        membershipData[0] &&
                        membershipData[0].created_at
                    ).getMonth()
                  ] +
                    " " +
                    new Date(
                      membershipData &&
                        membershipData[0] &&
                        membershipData[0].created_at
                    ).getDate()}
                  ,
                  {new Date(
                    membershipData &&
                      membershipData[0] &&
                      membershipData[0].created_at
                  ).getFullYear()}
                  ,
                  {new Date(
                    membershipData &&
                      membershipData[0] &&
                      membershipData[0].created_at
                  ).toLocaleTimeString([], {
                    hour: "2-digit",
                    minute: "2-digit",
                  })}
                </span>
                {membershipData &&
                membershipData[0] &&
                membershipData[0].read_status == 0 ? (
                  <span className="new">{t("text-new-up")}</span>
                ) : (
                  ""
                )}
              </p>
              <h2>
                {t("text-dear")} <span>{message}</span> {t("text-congratulations")}
              </h2>
              <h2>{t("joined-senscare-family")}</h2>
              <p>
                {t(
                  "friends-on-board"
                )}
                <br />
                {t("find-more-details")}
                <Link to="/terms-of-use" style={{ textTransform: "lowercase" }}>
                  {t("text-here")}
                </Link>
                .
              </p>
            </div>
          ) : (
            <div className="detail_invit">
              <p>
                <span className="date">
                  {new Date(
                    membershipData &&
                      membershipData[0] &&
                      membershipData[0].created_at
                  ).toLocaleDateString("en-US", { weekday: "short" })}
                  ,
                  {month[
                    new Date(
                      membershipData &&
                        membershipData[0] &&
                        membershipData[0].created_at
                    ).getMonth()
                  ] +
                    " " +
                    new Date(
                      membershipData &&
                        membershipData[0] &&
                        membershipData[0].created_at
                    ).getDate()}
                  ,
                  {new Date(
                    membershipData &&
                      membershipData[0] &&
                      membershipData[0].created_at
                  ).getFullYear()}
                  ,
                  {new Date(
                    membershipData &&
                      membershipData[0] &&
                      membershipData[0].created_at
                  ).toLocaleTimeString([], {
                    hour: "2-digit",
                    minute: "2-digit",
                  })}
                </span>
                {membershipData &&
                membershipData[0] &&
                membershipData[0].read_status == 0 ? (
                  <span className="new">{t("text-new-up")}</span>
                ) : (
                  ""
                )}
              </p>
              <h2>
                {t("text-dear")} <span>{message}</span> ,
              </h2>
              <h2>
                {t(
                  "post-discount"
                )}
              </h2>
              <p>
                {t(
                  "build-senscare-community"
                )}
                <br />
                {t("follow-discount-time")}
                <Link to="/search-providers/Loyalty">
                  {t("senscare-loyalty-page")}
                </Link>
                .
              </p>
            </div>
          )}
        </>
      )}
    </>
  );
}
