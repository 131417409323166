import { DEFAULT_API } from "../utils/variables";

const fetchAboutUs = async () => {
  try {
    const response = await fetch(`${DEFAULT_API}/api/aboutus`);
    const result = await response.json();
    return result.data;
  } catch (error) {
    console.error("Error fetching about us data:", error);
    throw error;
  }
};

const fetchAllTeam = async () => {
  try {
    const response = await fetch(`${DEFAULT_API}/api/allteam`);
    const result = await response.json();
    return result.data;
  } catch (error) {
    console.error("Error fetching team data:", error);
    throw error;
  }
};

export { fetchAboutUs, fetchAllTeam };
