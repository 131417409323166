import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { DEFAULT_API } from "../../../utils/variables";
import { HeartIcon } from "../../../assets/image/HeartIcon";
import { PhoneIcon } from "../../../assets/image/PhoneIcon";
import { MessageIcon } from "../../../assets/image/MessageIcon";
import { ServiceIcon } from "../../../assets/image/ServiceIcon";
import { AdditionalMenuIcon } from "../../../assets/image/AdditionalMenuIcon";
import { StarIcon } from "../../../assets/image/StarIcon";
import { WhiteStarIcon } from "../../../assets/image/WhiteStarIcon";
import { NannySecondaryIcon } from "../../../assets/image/NannySecondaryIcon";
import { TeacherSecondaryIcon } from "../../../assets/image/TeacherSecondaryIcon";
import { ProfessionalSecondaryIcon } from "../../../assets/image/ProfessionalIcon";
import { TutorSecondaryIcon } from "../../../assets/image/TutorSecondaryIcon";
import { PayIcon } from "../../../assets/image/PayIcon";
import { CaseIcon } from "../../../assets/image/CaseIcon";
import { DateIcon } from "../../../assets/image/DateIcon";
import { LocationIcon } from "../../../assets/image/LocationIcon";

export const JobProfileMobileCard = ({
  data,
  jobData,
  profile,
  setIsElement,
  handleElementById,
  index,
}) => {
  const { t } = useTranslation();

  const calculateAge = (dob) => {
    return (
      new Date().getFullYear() -
      parseInt(dob.substr(dob.lastIndexOf("\\") + 1).split("-")[0])
    );
  };

  const calculateRate = (rate) => {
    return (
      rate.substr(rate.lastIndexOf("\\") + 1).split("-")[0] * 100 +
      " - " +
      rate.substr(rate.lastIndexOf("\\") + 1).split("-")[1] * 100
    );
  };

  return (
    <div className="looking_for_candidate_boxs graydata">
      <div className="looking_for_candidate_box">
        <div className="first_sec">
          <div className="image_sec">
            <img
              src={
                data.file_path != null
                  ? `${DEFAULT_API}/assets/images/users/${data.file_path}`
                  : "img/nany_img.png"
              }
              alt=""
            />
            <div className="heart_sec">
              <HeartIcon />
            </div>
          </div>
          <div className="nany_social">
            <PhoneIcon color={"#A98D4B"} />
            <MessageIcon width={16} height={12} color={"#A98D4B"} />
            <ServiceIcon color={"#A98D4B"} />
          </div>
        </div>
        <div className="second_sec">
          <div className="heading">
            <h3>{data.title != null ? data.title : ""}</h3>
            <AdditionalMenuIcon />
          </div>
          <div className="post_general">
            <h6>
              <Link to={`/profile-parents/${jobData[0]?.user_id}`}>
                {data.first_name != null
                  ? `${data.first_name} ${data.last_name} `
                  : ""}
              </Link>
              ({data.dob != undefined ? calculateAge(data.dob) : ""})
            </h6>
            <p className="stars_profile">
              {data.reviewAvg >= 0 && (
                <>
                  {[...Array(data.reviewAvg)].map((_, index) => (
                    <StarIcon key={index} />
                  ))}
                  {[...Array(5 - data.reviewAvg)].map((_, index) => (
                    <WhiteStarIcon key={index} />
                  ))}
                </>
              )}
              <span> ({data.reviewcount})</span>
            </p>
          </div>
          <div className="post_detail">
            <div className="post">
              {data.job_type && (
                <>
                  {data.job_type === "Nanny" && <NannySecondaryIcon />}
                  {data.job_type === "Special Education Teacher" && (
                    <TeacherSecondaryIcon />
                  )}
                  {data.job_type === "Special Education Paraprofessional" && (
                    <ProfessionalSecondaryIcon />
                  )}
                  {data.job_type === "Tutor" && <TutorSecondaryIcon />}
                  <h5>{t(data.job_type)}</h5>
                </>
              )}
            </div>
            <div className="vi"></div>
            <div className="post_pay">
              <PayIcon />
              <h5>
                {profile && profile.country === "Serbia"
                  ? calculateRate(data.nanyperhrrate || data.tutorperhrrate)
                  : `$${data.nanyperhrrate || data.tutorperhrrate}`}
                {t("time-hour")}
              </h5>
            </div>
            <div className="vi"></div>
            <div className="post_fet">
              <CaseIcon width={12} height={10} color={"#A98D4B"} />
              <h5>
                {data.tutorintrestedin != null ? t(data.tutorintrestedin) : ""}
              </h5>
            </div>
            <div className="vi"></div>
            <div className="post_cal">
              <DateIcon width={13} height={14} />
              <h5>{data.tutorstartdate != null ? data.tutorstartdate : ""}</h5>
            </div>
            <div className="vi"></div>
            <div className="post_loc">
              <LocationIcon />
              <h5>
                {data.country != null ? data.country : ""},
                {data.city != null ? data.city : ""}
              </h5>
            </div>
          </div>
          <p id={`half${index}`}>
            {data.description != null ? data.description.substr(0, 100) : ""}
            {data.description != null && data.description.length > 100 && (
              <span
                onClick={() => {
                  setIsElement(true);
                  handleElementById(
                    `half${index}`,
                    `full${index}`,
                    `plink${index}`
                  );
                }}
              >
                {t("text-more")}
              </span>
            )}
          </p>
          <p id={`full${index}`} className="hide">
            {data.description}
            <span
              onClick={() =>
                handleElementById(
                  `full${index}`,
                  `half${index}`,
                  `plink${index}`
                )
              }
            >
              {t("text-less")}
            </span>
          </p>
        </div>
        <div
          className="view_profile_btn"
          style={{
            float: "right",
            width: "100%",
            textAlign: "right",
            display: "block",
          }}
        >
          <button
            style={{
              border: "none",
              background: "transparent",
              color: "#ccc",
              fontSize: "17px",
            }}
          >
            {t("text-closed")}
          </button>
        </div>
      </div>
    </div>
  );
};
