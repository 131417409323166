import React from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";

export const LoginModal = ({
  showLoginModal,
  setShowLoginModal,
  setIsSurvey,
  slugData,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Modal show={showLoginModal} onHide={(e) => setShowLoginModal(false)}>
      <Modal.Body>
        <div className="promocode_content cancelmembership reportthanks">
          <div className="">
            <p>
              <strong>{t("thank-message")}</strong>
            </p>
            <br />
            <p className={"second"}>
              <b>
                {t(
                  "senscare-safety-seriously"
                )}
              </b>
            </p>
            <br />
            <p className="giveus">
              {t("meantime-visit-our")}
              <Link to="/safety-center" target={"_blank"}>
                {t("safety-center")}
              </Link>
              {t("text-and")}
              <Link to="/faq" target={"_blank"}>
                {t("text-faq")}
              </Link>
              {t("for-more-info")}
            </p>

            <div class="button text-center">
              <div class="pull-right">
                <button
                  class="btn"
                  onClick={(e) => {
                    setShowLoginModal();
                    navigate("/search-providers/" + slugData.id);
                    setIsSurvey(false);
                  }}
                >
                  {t("text-close-up")}
                </button>
              </div>
            </div>
            <br />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
