import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { getAllParentCounts } from "../../../api/notificationApi";
import { CommentIcon } from "../../../assets/image/CommentIcon";
import { PageIcon } from "../../../assets/image/PageIcon";
import { ListIcon } from "../../../assets/image/ListIcon";
import { PageListIcon } from "../../../assets/image/PageListIcon";
import { StarRoundIcon } from "../../../assets/image/StarRoundIcon";
import { GiftIcon } from "../../../assets/image/GiftIcon";
import { SquareServiceIcon } from "../../../assets/image/SquareServiceIcon";

export function NotificationTabParents(props) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [parentData, setParentData] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem("token");
        const counts = await getAllParentCounts(token);

        setParentData(counts);
      } catch (error) {
        console.error("Error fetching counts:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      <ul>
        <li
          className={props.subtab == "interview-invite" ? "active" : ""}
          onClick={() => {
            window.scrollTo({ top: 0, behavior: "smooth" });
            props.setsubtab("interview-invite");
            navigate("/search-parents/interview-invite");
          }}
        >
          <CommentIcon />
          {t("interview-invites")} ({parentData.invitation})
        </li>
        <li
          className={props.subtab == "job-application" ? "active" : ""}
          onClick={(e) => {
            window.scrollTo({ top: 0, behavior: "smooth" });
            props.setsubtab("job-application");
            navigate("/search-parents/job-application");
          }}
        >
          <PageIcon color={"#636363"} />
          {t("job-applications")} ({parentData.jobapplication})
        </li>
        <li
          className={props.subtab == "new-recommendation" ? "active" : ""}
          onClick={(e) => {
            window.scrollTo({ top: 0, behavior: "smooth" });
            props.setsubtab("new-recommendation");
            navigate("/search-parents/new-recommendation");
          }}
        >
          <ListIcon />
          {t("recommendation-requests")} ({parentData.recommendation})
        </li>
        <li
          className={props.subtab == "shared-document" ? "active" : ""}
          onClick={(e) => {
            window.scrollTo({ top: 0, behavior: "smooth" });
            props.setsubtab("shared-document");
            navigate("/search-parents/shared-document");
          }}
        >
          <PageListIcon />
          {t("shared-documents")} ({parentData.shareddocument})
        </li>

        <li
          className={props.subtab == "new-reviews" ? "active" : ""}
          onClick={(e) => {
            window.scrollTo({ top: 0, behavior: "smooth" });
            props.setsubtab("new-reviews");
            navigate("/search-parents/new-reviews");
          }}
        >
          <StarRoundIcon />
          {t("new-reviews")} ({parentData.review})
        </li>
        <li
          className={props.subtab == "Loyalty-notification" ? "active" : ""}
          onClick={(e) => {
            window.scrollTo({ top: 0, behavior: "smooth" });
            props.setsubtab("Loyalty-notification");
            navigate("/search-parents/Loyalty-notification");
          }}
        >
          <GiftIcon color={"#636363"} />
          {t("senscare-loyalty")} ({parentData.loyalty})
        </li>
        <li
          className={props.subtab == "recent-profile-visitor" ? "active" : ""}
          onClick={(e) => {
            window.scrollTo({ top: 0, behavior: "smooth" });
            props.setsubtab("recent-profile-visitor");
            navigate("/search-parents/recent-profile-visitor");
          }}
        >
          <SquareServiceIcon width={14} height={14} color={"#636363"} />
          {t("recent-profile-visitors")} ({parentData.visitor})
        </li>
      </ul>
    </>
  );
}
