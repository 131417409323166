import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Modal from "react-bootstrap/Modal";

import { StopHandIcon } from "../../../assets/image/StopHandIcon";

export const MembershipModal = ({
  isMembershipModal,
  setIsMembershipModal,
  report,
  setReport,
  handleReport,
}) => {
  const { t } = useTranslation();

  return (
    <Modal show={isMembershipModal} onHide={(e) => setIsMembershipModal(false)}>
      <Modal.Body>
        <div className="promocode_content cancelmembership report">
          <Link to="" onClick={(e) => setIsMembershipModal(false)}>
            +
          </Link>
          <StopHandIcon onClick={(e) => setIsMembershipModal(true)} />
          <h2>{t("report-user")}</h2>
          <div className="cancelmembershipp">
            <p>
              <b>{t("little-request-info")}</b>
            </p>
            <div className="onepxline"></div>
            <p className={report.error != "" ? "active second" : "second"}>
              {t("reasom-report-profile")}
            </p>
            <br />
            <ul>
              <li>
                <input
                  type={"radio"}
                  id="survay"
                  name="survay"
                  onClick={(e) =>
                    setReport({
                      ...report,
                      resion: "User sent an unsolicited message",
                      error: "",
                    })
                  }
                />
                <span> {t("unsolicited-message")}</span>
              </li>
              <li>
                <input
                  type={"radio"}
                  id="survay"
                  name="survay"
                  onClick={(e) =>
                    setReport({
                      ...report,
                      resion: "Security and verification issue",
                      error: "",
                    })
                  }
                />
                <span> {t("verification-message")}</span>
              </li>
            </ul>
            <p className="giveus">{t("give-more-details")}</p>
            <div className="comment">
              <textarea
                className={
                  report.resion == "other" && report.other == ""
                    ? "bordererror"
                    : ""
                }
                maxlength="70"
                placeholder={t("text-type")}
                onChange={(e) =>
                  setReport({ ...report, other: e.target.value, error: "" })
                }
              ></textarea>
              <span>
                {t("number-characters")} {70 - report.other.length}
              </span>
            </div>
            <div className="button text-center">
              <div className="pull-right">
                <button className="btn" onClick={(e) => handleReport()}>
                  {t("report-user")}
                </button>
              </div>
              <div className="pull-right">
                <button
                  className="btn confirm"
                  onClick={(e) => setIsMembershipModal(false)}
                >
                  {t("text-cancel")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
