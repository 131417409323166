import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { fetchTermsOfUse } from "../../api";
import { Footer } from "../../components/common/Footer";
import { Header } from "../../components/common/Header";

export function TermsPage() {
  const { t } = useTranslation();

  const [termsData, setTermsData] = useState({});

  const fetchTermsData = async () => {
    try {
      const result = await fetchTermsOfUse();
      setTermsData(result.data);
    } catch (error) {
      console.error("Error fetching terms of use:", error);
    }
  };

  useEffect(() => {
    fetchTermsData();
  }, []);

  return (
    <>
      <Header />
      <div className="container-fluid">
        <div className="container">
          <div className="contact privacy">
            {termsData.title && (
              <h2>{t("terms-of-use.title", { defaultValue: termsData.title })}</h2>
            )}
            {termsData.description && (
              <div
                dangerouslySetInnerHTML={{
                  __html: t("terms-of-use.description", {
                    defaultValue: termsData.description,
                  }),
                }}
                className="privacycon"
              />
            )}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
