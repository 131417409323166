import React from "react";
import { useTranslation } from "react-i18next";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";

export const RecommendationModal = ({
  isRecommendationModal,
  setIsRecommendationModal,
  profile,
  setRecommend,
  recommend,
  handleRecommendation,
}) => {
  const { t } = useTranslation();

  return (
    <Modal
      show={isRecommendationModal}
      onHide={(e) => setIsRecommendationModal(false)}
    >
      <Modal.Body>
        <div className="promocode_content signout invite request recome">
          <Link to="" onClick={(e) => setIsRecommendationModal(false)}>
            +
          </Link>
          <h2>{t("Ask for Recommendation")}</h2>
          <p>
            {t("Ask")} <span>{profile.first_name}</span>
            {t("recommendation-letter-employee")}
          </p>
          <div className="form_groupre">
            <label>{t("Write a Message")} </label>
            <textarea
              placeholder={t("text-type")}
              onChange={(e) => setRecommend(e.target.value)}
              maxLength="300"
            ></textarea>
            <span>
              {t("number-characters")} {300 - recommend.length}
            </span>
          </div>
          <div className="button text-center ">
            <div className="pull-right">
              <button
                className="btn"
                onClick={(e) => setIsRecommendationModal(false)}
              >
                {t("text-cancel")}
              </button>
            </div>
            <div className="pull-right">
              <button
                className="btn confirm"
                onClick={(e) => handleRecommendation()}
              >
                {t("Send request")}
              </button>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
