import React from "react";
import { useTranslation } from "react-i18next";
import Modal from "react-bootstrap/Modal";

import { useRatesStore } from "../../../../store/useRatesStore";
import { useProviderFieldStore } from "../../../../store/useProviderFieldStore";
import { useProfileDetailsStore } from "../../../../store/useProfileDetailsStore";
import { RATE_NUMBERS, RATE_NUMBERS_SECOND, STEP_NANNY } from "../../../../utils/variables";

import { BanSmokeIcon } from "../../../../assets/image/BanSmokeIcon";
import { BanCarIcon } from "../../../../assets/image/BanCarIcon";
import { SmokeIcon } from "../../../../assets/image/SmokeIcon";
import { CarIcon } from "../../../../assets/image/CarIcon";
import { BanCookIcon } from "../../../../assets/image/BanCookIcon";
import { CookIcon } from "../../../../assets/image/CookIcon";
import { BanHouseIcon } from "../../../../assets/image/BanHouseIcon";
import { HouseIcon } from "../../../../assets/image/HouseIcon";
import { BanPlaneIcon } from "../../../../assets/image/BanPlaneIcon";
import { PlaneIcon } from "../../../../assets/image/PlaneIcon";

export const NannyModal = ({
  showNannyModal,
  handleCloseNannyModal,
  experience,
  detail,
  setExperience,
  setCountp,
  countp,
  handleSubmitProvider,
  validateAfterLoginField,
}) => {
  const { t } = useTranslation();

  const { nanyRate: nannyRate, setNanyRate } = useRatesStore();
  const { habit, setHabit } = useProfileDetailsStore();
  const { errorField, setErrorField, detailProvider, setDetailProvider } =
    useProviderFieldStore();

  return (
    <Modal
      show={showNannyModal}
      onHide={handleCloseNannyModal}
      className="question_new"
    >
      <Modal.Body>
        <div className="younger">
          <img src="/images/sign_logo.svg" />
          <h2>{t("Complete the Profile")}</h2>
          <p>{t("You can edit your info in settings section later")}</p>
          <div className="Profile_complete">
            <div className="detail work-experience additional_info your_kids  setp3">
              <div className="nanny">
                <h2 className="border">
                  <img src="/images/nany_pur.svg" /> {t("text-nanny")}
                </h2>
                <div className="col-6">
                  <div className="iconsec">
                    <div className="form_group full">
                      <label>
                        {t("Years of work experience")}
                        <span
                          className={
                            errorField.nanyyearexp != "" ? "starred" : ""
                          }
                        >
                          *
                        </span>
                      </label>
                      <div className="rang" style={{ minWidth: "375px" }}>
                        <div
                          class="slider"
                          onClick={(w) =>
                            setErrorField({
                              ...errorField,
                              nanyyearexp: "",
                            })
                          }
                        >
                          <input
                            type="range"
                            min="0"
                            max="60"
                            onChange={(e) => {
                              setExperience(e.target.value);
                              setDetailProvider({
                                ...detailProvider,
                                nanyyearexp: parseInt(e.target.value),
                              });
                            }}
                            style={{
                              "background-size":
                                (experience * 100) / 60 + "% 100% ",
                            }}
                            value={experience}
                          />
                          <ul>
                            <li>0</li>
                            <li>15</li>
                            <li>30</li>
                            <li>45</li>
                            <li>60</li>
                          </ul>
                        </div>
                        <span id="rangeValue">{experience}</span>
                      </div>
                      {/* <div className='errorfield'>{error.message}</div>*/}
                    </div>
                  </div>
                  <div className="rightsec">
                    <div className="form_group full">
                      <label>
                        {t("Rate per hour")}
                        {detail.country == "Serbia" ? "(RSD)" : "(USD)"}
                        <span
                          className={
                            errorField.nanyperhrrate != "" ? "starred" : ""
                          }
                        >
                          *
                        </span>
                      </label>
                      {detail && detail.country == "Serbia" ? (
                        <div class="wrapper rang">
                          <div class="container_slide">
                            <div class="slider-track">
                              <ul>
                              {RATE_NUMBERS.map((value, index) => {
                                    const style =
                                      nannyRate.min >= value ||
                                      (nannyRate.max < value && nannyRate.max > 0)
                                        ? { backgroundColor: "#E5E5E5" }
                                        : {};
                                    return <li key={index} style={style}></li>;
                                  })}
                              </ul>
                            </div>
                            <div class="bggray_slider">
                              <ul>
                              {RATE_NUMBERS_SECOND.map((value, index) => {
                                    const style =
                                      nannyRate.min >= value ||
                                      (nannyRate.max < value && nannyRate.max > 0)
                                        ? { backgroundColor: "#E5E5E5" }
                                        : {};
                                    return <li key={index} style={style}></li>;
                                  })}
                              </ul>
                            </div>
                            <input
                              type="range"
                              min="0"
                              max="5"
                              id="slider-1"
                              value={nannyRate.min / 10}
                              onChange={(e) => {
                                if (
                                  (nannyRate.max > 0 ? nannyRate.max : 60) >
                                  (e.target.value == 5
                                    ? 60
                                    : e.target.value * 10)
                                ) {
                                  setNanyRate({
                                    ...nannyRate,
                                    min:
                                      e.target.value == 5
                                        ? 60
                                        : e.target.value * 10,
                                  });
                                }
                              }}
                            />
                            <input
                              type="range"
                              min="0"
                              max="5"
                              id="slider-2"
                              value={
                                nannyRate.max == 0 ? 50 / 10 : nannyRate.max / 10
                              }
                              onChange={(e) => {
                                if (
                                  nannyRate.min <
                                  (e.target.value == 5
                                    ? 60
                                    : e.target.value * 10)
                                ) {
                                  setNanyRate({
                                    ...nannyRate,
                                    max:
                                      e.target.value == 5
                                        ? 60
                                        : e.target.value * 10,
                                  });
                                }
                              }}
                            />
                          </div>
                          <div class="slider two">
                            <ul>
                              <li>0</li>
                              <li>1000</li>
                              <li>2000</li>
                              <li>3000</li>
                              <li>4000</li>
                              <li>6000+</li>
                            </ul>
                          </div>
                        </div>
                      ) : (
                        <div class="wrapper rang">
                          <div class="container_slide">
                            <div class="slider-track">
                              <ul>
                              {RATE_NUMBERS.map((value, index) => {
                                    const style =
                                      nannyRate.min >= value ||
                                      (nannyRate.max < value && nannyRate.max > 0)
                                        ? { backgroundColor: "#E5E5E5" }
                                        : {};
                                    return <li key={index} style={style}></li>;
                                  })}
                              </ul>
                            </div>
                            <div class="bggray_slider">
                              <ul>
                              {RATE_NUMBERS_SECOND.map((value, index) => {
                                    const style =
                                      nannyRate.min >= value ||
                                      (nannyRate.max < value && nannyRate.max > 0)
                                        ? { backgroundColor: "#E5E5E5" }
                                        : {};
                                    return <li key={index} style={style}></li>;
                                  })}
                              </ul>
                            </div>
                            <input
                              type="range"
                              min="0"
                              max="5"
                              id="slider-1"
                              value={nannyRate.min / 10}
                              onChange={(e) => {
                                if (
                                  (nannyRate.max > 0 ? nannyRate.max : 60) >
                                  (e.target.value == 5
                                    ? 60
                                    : e.target.value * 10)
                                ) {
                                  setNanyRate({
                                    ...nannyRate,
                                    min:
                                      e.target.value == 5
                                        ? 60
                                        : e.target.value * 10,
                                  });
                                }
                              }}
                            />
                            <input
                              type="range"
                              min="0"
                              max="5"
                              id="slider-2"
                              value={
                                nannyRate.max == 0 ? 50 / 10 : nannyRate.max / 10
                              }
                              onChange={(e) => {
                                if (
                                  nannyRate.min <
                                  (e.target.value == 5
                                    ? 60
                                    : e.target.value * 10)
                                ) {
                                  setNanyRate({
                                    ...nannyRate,
                                    max:
                                      e.target.value == 5
                                        ? 60
                                        : e.target.value * 10,
                                  });
                                }
                              }}
                            />
                          </div>
                          <div class="slider two">
                            <ul>
                              <li>0</li>
                              <li>10</li>
                              <li>20</li>
                              <li>30</li>
                              <li>40</li>
                              <li>60+</li>
                            </ul>
                          </div>
                        </div>
                      )}
                      <span class="price">
                        {detail && detail.country == "Serbia"
                          ? nannyRate.min * 100 + " - " + nannyRate.max * 100
                          : nannyRate.min + " - " + nannyRate.max}
                      </span>
                    </div>
                  </div>
                  <div className="iconsec">
                    <div className="iconsec" style={{ width: "100%" }}>
                      <br />
                      <h3>
                        {t("Additional info")}
                        <span
                          className={
                            errorField.smoke != "" ||
                            errorField.carorlicence != "" ||
                            errorField.cooking != "" ||
                            errorField.lighthousework != "" ||
                            errorField.traveling != ""
                              ? "starred"
                              : ""
                          }
                        >
                          *
                        </span>
                      </h3>
                      <br />
                      <div className="icon">
                        <ul>
                          <li
                            onClick={(e) =>
                              setErrorField({ ...errorField, smoke: "" })
                            }
                          >
                            {t("Do you smoke?")}
                            <div className="icons">
                              <BanSmokeIcon
                                className={
                                  habit.smoke == "false" ? "active" : ""
                                }
                                onClick={(e) => {
                                  setHabit({ ...habit, smoke: "false" });
                                }}
                              />

                              <SmokeIcon
                                className={
                                  habit.smoke == "true" ? "active" : ""
                                }
                                onClick={(e) =>
                                  setHabit({ ...habit, smoke: "true" })
                                }
                              />
                            </div>
                          </li>
                          <li
                            onClick={(e) =>
                              setErrorField({
                                ...errorField,
                                carorlicence: "",
                              })
                            }
                          >
                            {t("Do you own a car or licence?")}
                            <div className="icons">
                              <BanCarIcon
                                className={
                                  habit.licence == "false" ? "active" : ""
                                }
                                onClick={(e) => {
                                  setHabit({ ...habit, licence: "false" });
                                }}
                              />
                              <CarIcon
                                className={
                                  habit.licence == "true" ? "active" : ""
                                }
                                onClick={(e) =>
                                  setHabit({ ...habit, licence: "true" })
                                }
                              />
                            </div>
                          </li>
                          <li
                            onClick={(e) =>
                              setErrorField({ ...errorField, cooking: "" })
                            }
                          >
                            {t("cooking-kids")}
                            <div className="icons">
                              <BanCookIcon
                                className={
                                  habit.kids == "false" ? "active" : ""
                                }
                                onClick={(e) => {
                                  setHabit({ ...habit, kids: "false" });
                                }}
                              />

                              <CookIcon
                                className={habit.kids == "true" ? "active" : ""}
                                onClick={(e) =>
                                  setHabit({ ...habit, kids: "true" })
                                }
                              />
                            </div>
                          </li>
                          <li
                            onClick={(e) =>
                              setErrorField({
                                ...errorField,
                                lighthousework: "",
                              })
                            }
                          >
                            {t("light-housework")}
                            <div className="icons">
                              <BanHouseIcon
                                className={
                                  habit.housework == "false" ? "active" : ""
                                }
                                onClick={(e) =>
                                  setHabit({ ...habit, housework: "false" })
                                }
                              />
                              <HouseIcon
                                className={
                                  habit.housework == "true" ? "active" : ""
                                }
                                onClick={(e) =>
                                  setHabit({ ...habit, housework: "true" })
                                }
                              />
                            </div>
                          </li>
                          <li
                            onClick={(e) =>
                              setErrorField({
                                ...errorField,
                                traveling: "",
                              })
                            }
                          >
                            {t("Traveling with family")}
                            <div className="icons">
                              <BanPlaneIcon
                                className={
                                  habit.family == "false" ? "active" : ""
                                }
                                onClick={(e) => {
                                  setHabit({ ...habit, family: "false" });
                                }}
                              />

                              <PlaneIcon
                                className={
                                  habit.family == "true" ? "active" : ""
                                }
                                onClick={(e) =>
                                  setHabit({ ...habit, family: "true" })
                                }
                              />
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="rightsec">
                    <div className="form_group full space">
                      <label>
                        {t("Do you have experience with newborns?")}
                        <span
                          className={
                            errorField.nanynewbornexp != "" ? "starred" : ""
                          }
                        >
                          *
                        </span>
                      </label>
                      <div className="checkbox">
                        <ul
                          onClick={(w) =>
                            setErrorField({
                              ...errorField,
                              nanynewbornexp: "",
                            })
                          }
                        >
                          <li>
                            <input
                              type="radio"
                              name="quality"
                              checked={
                                detailProvider.nanynewbornexp == "Yes"
                                  ? true
                                  : false
                              }
                              onClick={(e) =>
                                setDetailProvider({
                                  ...detailProvider,
                                  nanynewbornexp: "Yes",
                                })
                              }
                            />
                            <span> {t("confirm")}</span>
                          </li>
                          <li>
                            <input
                              type="radio"
                              name="quality"
                              checked={
                                detailProvider.nanynewbornexp == "No"
                                  ? true
                                  : false
                              }
                              onClick={(e) =>
                                setDetailProvider({
                                  ...detailProvider,
                                  nanynewbornexp: "No",
                                })
                              }
                            />
                            <span> {t("objection")}</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="form_group full">
                      <label>
                        {t("Would you living with the family?")}
                        <span
                          className={
                            errorField.livewithfamily != "" ? "starred" : ""
                          }
                        >
                          *
                        </span>
                      </label>
                      <div className="checkbox create">
                        <ul
                          onClick={(e) =>
                            setErrorField({
                              ...errorField,
                              livewithfamily: "",
                            })
                          }
                        >
                          <li>
                            <input
                              type="radio"
                              name="family"
                              onClick={(e) =>
                                setDetailProvider({
                                  ...detailProvider,
                                  livewithfamily: "Yes",
                                })
                              }
                              checked={
                                detailProvider.livewithfamily == "Yes"
                                  ? true
                                  : false
                              }
                            />
                            <span> {t("confirm")}</span>
                          </li>
                          <li>
                            <input
                              type="radio"
                              name="family"
                              onClick={(e) =>
                                setDetailProvider({
                                  ...detailProvider,
                                  livewithfamily: "No",
                                })
                              }
                              checked={
                                detailProvider.livewithfamily == "No"
                                  ? true
                                  : false
                              }
                            />
                            <span> {t("objection")}</span>
                          </li>
                          <li>
                            <input
                              type="radio"
                              name="family"
                              onClick={(e) =>
                                setDetailProvider({
                                  ...detailProvider,
                                  livewithfamily: "Open to offers",
                                })
                              }
                              checked={
                                detailProvider.livewithfamily ==
                                "Open to offers"
                                  ? true
                                  : false
                              }
                            />
                            <span> {t("Open to offers")}</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="form_group full space">
                      <label>
                        {t("Do you have experience in working with kids with")}
                        <br />
                        {t("special needs?")}
                        <span
                          className={
                            errorField.nanyexpwithkid != "" ? "starred" : ""
                          }
                        >
                          *
                        </span>
                      </label>
                      <div className="checkbox">
                        <ul
                          onClick={(e) =>
                            setErrorField({
                              ...errorField,
                              nanyexpwithkid: "",
                            })
                          }
                        >
                          <li>
                            <input
                              type="radio"
                              name="quality2"
                              checked={
                                detailProvider.nanyexpwithkid === "Yes"
                                  ? true
                                  : false
                              }
                              onClick={(e) =>
                                setDetailProvider({
                                  ...detailProvider,
                                  nanyexpwithkid: "Yes",
                                })
                              }
                            />
                            <span> {t("confirm")}</span>
                          </li>
                          <li>
                            <input
                              type="radio"
                              name="quality2"
                              checked={
                                detailProvider.nanyexpwithkid === "No"
                                  ? true
                                  : false
                              }
                              onClick={(e) =>
                                setDetailProvider({
                                  ...detailProvider,
                                  nanyexpwithkid: "No",
                                })
                              }
                            />
                            <span> {t("objection")}</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <span>{t("required-fields")}</span>
              </div>
            </div>
          </div>
          <div className="button">
            <button
              onClick={(e) => {
                handleCloseNannyModal();
                window.scrollTo({ top: 0 });
              }}
            >
              {t("text-back")}
            </button>
            <button
              onClick={(e) => {
                if (
                  detailProvider.nanyyearexp > 0 &&
                  (nannyRate.min > 5 || nannyRate.max > 5) &&
                  habit.smoke != "" &&
                  habit.licence != "" &&
                  habit.kids != "" &&
                  habit.housework != "" &&
                  habit.family != "" &&
                  detailProvider.nanynewbornexp != "" &&
                  detailProvider.livewithfamily != "" &&
                  detailProvider.nanyexpwithkid != "" &&
                  countp <= 2
                ) {
                  window.scrollTo({ top: 0 });
                  handleCloseNannyModal();
                  handleSubmitProvider(true);
                  setCountp(countp + 1);
                } else {
                  STEP_NANNY.map((e) => {
                    validateAfterLoginField(e.name);
                  });
                  alert("failed sorry can chnage only two times ");
                }
              }}
            >
              {t("text-finish")}
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
