export const AdditionalMenuIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.7072 10.2523C11.841 10.2528 11.0214 10.6445 10.477 11.3182L5.64755 8.90347C5.77548 8.41171 5.76698 7.89438 5.62296 7.40709L10.5426 4.74641C11.5004 5.85506 13.1396 6.06415 14.3451 5.23145C15.5506 4.39876 15.9353 2.79169 15.2374 1.50341C14.5396 0.215139 12.9834 -0.340625 11.6274 0.214168C10.2714 0.768961 9.55101 2.25615 9.9563 3.6641L5.0367 6.32478C4.2458 5.41389 2.96968 5.09486 1.84317 5.5264C0.716664 5.95794 -0.0197013 7.04791 0.000401531 8.25408C0.0205043 9.46025 0.792783 10.5251 1.93305 10.9188C3.07331 11.3126 4.33809 10.9512 5.0982 10.0145L9.92761 12.4292C9.62817 13.6178 10.1167 14.8654 11.1437 15.5346C12.1707 16.2038 13.5093 16.1468 14.4758 15.3928C15.4422 14.6388 15.823 13.3542 15.4236 12.1953C15.0243 11.0364 13.9329 10.2591 12.7072 10.2605V10.2523Z"
        fill="#7D2B8B"
      />
    </svg>
  );
};
