export const PageListIcon = () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.6008 0H4.20034C3.4303 0 2.80026 0.630033 2.80026 1.40007V9.80052C2.80026 10.5706 3.4303 11.2006 4.20034 11.2006H12.6008C13.3708 11.2006 14.0009 10.5706 14.0009 9.80052V1.40007C14.0009 0.630033 13.3708 0 12.6008 0ZM0.700037 2.79913C0.315017 2.79913 0 3.11415 0 3.49917V12.5997C0 13.3697 0.630033 13.9997 1.40007 13.9997H10.5006C10.8856 13.9997 11.2006 13.6847 11.2006 13.2997C11.2006 12.9147 10.8856 12.5997 10.5006 12.5997H2.10011C1.71509 12.5997 1.40007 12.2846 1.40007 11.8996V3.49917C1.40007 3.11415 1.08506 2.79913 0.700037 2.79913ZM5.60034 6.30199H11.2006C11.5857 6.30199 11.9007 5.98697 11.9007 5.60195C11.9007 5.21693 11.5857 4.90192 11.2006 4.90192H5.60034C5.21532 4.90192 4.9003 5.21693 4.9003 5.60195C4.9003 5.98697 5.21532 6.30199 5.60034 6.30199ZM8.40049 9.10112H5.60034C5.21532 9.10112 4.9003 8.78611 4.9003 8.40109C4.9003 8.01607 5.21532 7.70105 5.60034 7.70105H8.40049C8.78551 7.70105 9.10052 8.01607 9.10052 8.40109C9.10052 8.78611 8.78551 9.10112 8.40049 9.10112ZM5.60034 3.4992H11.2006C11.5857 3.4992 11.9007 3.18418 11.9007 2.79916C11.9007 2.41414 11.5857 2.09912 11.2006 2.09912H5.60034C5.21532 2.09912 4.9003 2.41414 4.9003 2.79916C4.9003 3.18418 5.21532 3.4992 5.60034 3.4992Z"
        fill="#636363"
      />
    </svg>
  );
};
