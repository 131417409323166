export const LaptopIcon = () => {
  return (
    <svg
      width="36"
      height="24"
      viewBox="0 0 36 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M32.985 18C32.985 19.65 31.65 21 30 21H34.5C35.325 21 36 21.675 36 22.5C36 23.325 35.325 24 34.5 24H1.5C0.675 24 0 23.325 0 22.5C0 21.675 0.675 21 1.5 21H6C4.35 21 3 19.65 3 18V3C3 1.35 4.35 0 6 0H30C31.65 0 33 1.35 33 3L32.985 18ZM28.5 3H7.5C6.675 3 6 3.675 6 4.5V16.5C6 17.325 6.675 18 7.5 18H28.5C29.325 18 30 17.325 30 16.5V4.5C30 3.675 29.325 3 28.5 3Z"
        fill="#B7B7B7"
      />
    </svg>
  );
};
