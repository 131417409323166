import React from "react";
import { api } from "../../urls";
import { useTranslation } from "react-i18next";

export function TeamMember({ member, index, handleShowEmail }) {
  const { t } = useTranslation();

  const nameParts = member.name.split(" ");
  const displayName = nameParts[0] === "Dr." ? nameParts[1] : nameParts[0];

  return (
    <div>
      <li>
        <img src={api + "/assets/cms/" + member.image} alt={member.name} />
        <h3>{member.name}</h3>
        <p>{member.education}</p>
        <h4>{member.position}</h4>
        <button onClick={() => handleShowEmail(index + 1)}>
          {t("about-more")} {displayName}
        </button>
      </li>
    </div>
  );
}
