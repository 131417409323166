import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";
import { api } from "../../urls";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FacebookProvider, LoginButton } from "react-facebook";
import ReCAPTCHA from "react-google-recaptcha";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { LinkedinContent } from "../../components/common/LinkedinContent";
import { EmailModal } from "./Modals/EmailModal";
import { LoginModal } from "./Modals/LoginModal";
import { ProviderModal } from "./Modals/ProviderModal";
import { ReactivateAccountModal } from "./Modals/ReactivateAccountModal";
import { ReactivateModal } from "./Modals/ReactivateModal";

export function LoginPage() {
  const { t } = useTranslation();
  const [isLoginModal, setIsLoginModal] = useState(false);
  const [isProviderModal, setIsProviderModal] = useState(false);
  const [reactivateAccountModal, setReactivateAccountModal] = useState(false);
  const [isReactivateModal, setIsReactivateModal] = useState(false);

  const [isEmailModal, setIsEmailModal] = useState(false);
  const [isPassword, setIsPassword] = useState(false);
  const [verify, setVerify] = useState(false);
  const [link, setLink] = useState("");
  const [formLogin, setFormLogin] = useState({
    username: "",
    password: "",
    remember: false,
    resetemailadd: "",
  });
  const [error, setError] = useState({
    username: "",
    password: "",
    resetemailadd: "",
  });

  const slugData = useParams();
  const data = slugData.id;

  const handleCloseEmailModal = () => setIsEmailModal(false);
  const handleShowEmailModal = () => setIsEmailModal(true);
  const handleShowLoginModal = () => setIsLoginModal(true);
  const handleShowProviderModal = () => setIsProviderModal(true);

  const handleCloseLogin = () => {
    setIsLoginModal(false);
    window.location.replace("/search-providers");
  };
  const handleCloseLoginProvider = () => {
    setIsProviderModal(false);
    window.location.replace("/search-parents");
  };

  useEffect(() => {
    let x = localStorage.getItem("remember");
    if (x != null) {
      setFormLogin(JSON.parse(x));
    }
  }, [isLoginModal, isEmailModal, isLoginModal, isProviderModal]);

  const handleRememberMe = (e) => {
    formLogin.remember = e.target.checked;
    setFormLogin(formLogin);
    if (formLogin.remember == true) {
      localStorage.setItem("remember", JSON.stringify(formLogin));
    } else {
      localStorage.clear();
    }
  };

  const validationLogin = (e) => {
    const { name, value } = e.target;
    switch (name) {
      case "username":
        error.username = value.length > 4 ? "" : t("email-valid");
        break;
      case "password":
        error.password = value.length < 8 ? t("min-char-8") : "";
        break;
      case "resetemailadd":
        error.resetemailadd = value.length > 4 ? "" : t("email-valid");
        break;
      default:
        break;
    }

    setFormLogin({ ...formLogin, [name]: value });
    setError(error);
  };

  const handleLoginForm = () => {
    localStorage.removeItem("search");
    localStorage.removeItem("search2");
    if (formLogin.username != "" && formLogin.password != "") {
      const formdata = new FormData();
      formdata.append("email", formLogin.username.toLocaleLowerCase());
      formdata.append("password", formLogin.password);

      const requestOptions = {
        method: "POST",
        body: formdata,
        redirect: "follow",
      };

      fetch(api + "/api/login", requestOptions)
        .then((response) => response.json())
        .then((result) => {
          console.log("USERRESULT:", result);
          if (result.data == "Not verified") {
            document.getElementById("unsuccess").style.display = "block";
          } else {
            document.getElementById("unsuccess").style.display = "none";
          }
          if (result.success) {
            localStorage.setItem("id", result.data.user.id);
            localStorage.setItem("token", result.data.token);
            localStorage.setItem("user_type", result.data.user.user_type);
            setLink(result.data.user.user_type);
            localStorage.setItem(
              "name",
              result.data.user.first_name + " " + result.data.user.last_name
            );
            result.data.welcomeMessage
              ? setReactivateAccountModal(true)
              : setReactivateAccountModal(false);
            console.log(result);

            window.location.href = "/";

            if (!data) {
              !result.data.welcomeMessage ? (
                <>
                  {result.data.user &&
                  result.data.user.user_type == "provider" &&
                  result.data.user.backgroundstatus != null
                    ? window.location.replace("/search-parents")
                    : result.data.user &&
                        result.data.user.user_type == "parents" &&
                        result.data.jobExist == 1
                      ? window.location.replace("/search-providers")
                      : result.data.user &&
                          result.data.user.user_type == "parents"
                        ? setTimeout(() => {
                            handleShowLoginModal();
                          }, 500)
                        : result.data.user &&
                            result.data.user.user_type == "provider"
                          ? setTimeout(() => {
                              handleShowProviderModal();
                            }, 500)
                          : setLink(result.data.user.user_type)}
                </>
              ) : result.data.welcomeMessage ? (
                setReactivateAccountModal(true)
              ) : (
                setReactivateAccountModal(false)
              );
            } else {
              window.location.reload();
            }
          } else {
            result.message == "You are entering the wrong password."
              ? setError({ password: t("incorrect-password"), username: "" })
              : result.message == "You are entering the wrong email or username"
                ? setError({
                    username: t("Incorrect email / username"),
                    password: "",
                  })
                : setError({
                    username: "",
                    password: "",
                  });
          }
        })
        .catch((error) => {
          console.log("error", error);
        });
    } else {
      setError({
        username: "required",
        password: "required",
      });
    }
  };

  const handleResponse = (data) => {
    const formdata = new FormData();
    formdata.append("email", data.profile.email);

    const requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };

    fetch(api + "/api/facebooklogin", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log(result);
        if (result.success == false) {
          setError({ username: t("Incorrect email / username"), password: "" });
        } else {
          console.log(result);
          if (result.data == "Not verified") {
            document.getElementById("unsuccess").style.display = "block";
          } else {
            document.getElementById("unsuccess").style.display = "none";
          }
          if (result.success) {
            localStorage.setItem("id", result.data.user.id);
            localStorage.setItem("token", result.data.token);
            localStorage.setItem("user_type", result.data.user.user_type);
            setLink(result.data.user.user_type);
            localStorage.setItem(
              "name",
              result.data.user.first_name + " " + result.data.user.last_name
            );
            result.data.welcomeMessage
              ? setReactivateAccountModal(true)
              : setReactivateAccountModal(false);
            console.log(result);

            if (!data) {
              !result.data.welcomeMessage ? (
                <>
                  {result.data.user &&
                  result.data.user.user_type == "provider" &&
                  result.data.user.backgroundstatus != null
                    ? window.location.replace("/search-parents")
                    : result.data.user &&
                        result.data.user.user_type == "parents" &&
                        result.data.jobExist == 1
                      ? window.location.replace("/search-providers")
                      : result.data.user &&
                          result.data.user.user_type == "parents"
                        ? setTimeout(() => {
                            handleShowLoginModal();
                          }, 500)
                        : result.data.user &&
                            result.data.user.user_type == "provider"
                          ? setTimeout(() => {
                              handleShowProviderModal();
                            }, 500)
                          : setLink(result.data.user.user_type)}
                </>
              ) : result.data.welcomeMessage ? (
                setReactivateAccountModal(true)
              ) : (
                setReactivateAccountModal(false)
              );
            } else {
              window.location.replace("/");
            }
          }
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
    console.log(data);
    setFormLogin({ ...formLogin, username: data.profile.email });
  };

  const handleErrorMessage = (error) => {
    console.log({ error });
  };

  const handleResetEmail = () => {
    if (formLogin.resetemailadd != "") {
      const formdata = new FormData();
      formdata.append("email", formLogin.resetemailadd);
      const requestOptions = {
        method: "POST",
        body: formdata,
        redirect: "follow",
      };

      fetch(api + "/api/resetpassword", requestOptions)
        .then((response) => response.json())
        .then((result) => {
          if (result.success) {
            toast.success(result.message);
            handleCloseEmailModal();
          } else {
            setError({
              resetemailadd: t("wrong-email-username"),
            });
          }
          console.log(result);
        })
        .catch((error) => {
          console.log("error", error);
        });
    } else {
      setError({
        resetemailadd: "required",
      });
    }
  };

  const handleChange = (value) => {
    console.log("Captcha value:", value);
    setVerify(true);
  };

  return (
    <>
      <div className="container-fluid">
        <div className="container">
          <div className="signup_section loginbg">
            <div className="header_sign">
              <ul>
                <li>
                  <Link to="/">
                    <img
                      src={window.location.origin + "/images/left_arrow.svg"}
                    />
                    <span>{t("text-home")}</span>
                  </Link>
                </li>
                <li className="login">
                  <span>{t("have-account")}</span>
                  <Link to="/signup">{t("sign-up")}</Link>
                </li>
              </ul>
            </div>

            <div className={"more_about login_form"}>
              <img src={window.location.origin + "/images/sign_logo.svg"} />
              <h2>{t("login.login")}</h2>
              <span className="unsuccess" id="unsuccess">
                {t("email-not-verified")}
              </span>
              <form>
                <div className="form_group">
                  <label> {t("login.email")}</label>
                  <input
                    className={error.username == "" ? "" : "bordererror"}
                    type={"text"}
                    placeholder={t("type-placeholder")}
                    name="username"
                    onChange={(e) => validationLogin(e)}
                    defaultValue={formLogin.username}
                  />
                  <span className="errorfield">{error.username}</span>
                </div>
                <div className="form_group">
                  <label>{t("text-password")}</label>
                  <input
                    className={error.password == "" ? "" : "bordererror"}
                    type={isPassword ? "text" : "password"}
                    placeholder=". . .  . . . . . . . "
                    name="password"
                    onChange={(e) => validationLogin(e)}
                    defaultValue={formLogin.password}
                  />
                  <div className="eyes">
                    <input
                      type={"checkbox"}
                      onClick={(e) => {
                        setIsPassword(!isPassword);
                      }}
                    />
                    <i></i>
                  </div>
                  <span className="errorfield">{error.password}</span>
                </div>
                <div className="rmfog">
                  <label>
                    <input
                      type={"checkbox"}
                      onClick={(e) => {
                        handleRememberMe(e);
                      }}
                    />
                    <span>{t("remember-me")}</span>
                  </label>
                  <Link to="" onClick={handleShowEmailModal}>
                    {t("forgot-paswword")}
                  </Link>
                </div>
                <div className="captcha">
                  <ReCAPTCHA
                    sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"
                    onChange={handleChange}
                  />
                </div>
              </form>
              <button
                disabled={!verify}
                onClick={(e) => {
                  handleLoginForm();
                }}
              >
                {t("log-in")}
              </button>
              <span>
                <span></span>
                <p>{t("log in")}</p>
              </span>
              <FacebookProvider appId="587997313887434">
                <LoginButton
                  scope="email"
                  onCompleted={handleResponse}
                  onError={handleErrorMessage}
                  className="facebook"
                >
                  <span>
                    <i class="fa-brands fa-facebook-f"></i>
                    {t("log-with--facebook")}
                  </span>
                </LoginButton>
              </FacebookProvider>
              <LinkedinContent handleResponse={handleResponse} />
              <p>
                {t("have-account")}
                <Link to="/signup">{t("sign-up")} </Link>
              </p>
            </div>
          </div>
        </div>
      </div>

      {isEmailModal && (
        <EmailModal
          isEmailModal={isEmailModal}
          handleCloseEmail={handleCloseEmailModal}
          error={error}
          validationLogin={validationLogin}
          handleResetEmail={handleResetEmail}
        />
      )}

      {isLoginModal && (
        <LoginModal
          isLoginModal={isLoginModal}
          handleCloseLogin={handleCloseLogin}
        />
      )}

      {isProviderModal && (
        <ProviderModal
          isProviderModal={isProviderModal}
          handleCloseLoginProvider={handleCloseLoginProvider}
        />
      )}

      {reactivateAccountModal && (
        <ReactivateAccountModal
          reactivateAccountModal={reactivateAccountModal}
          setReactivateAccountModal={setReactivateAccountModal}
          setIsReactivateModal={setIsReactivateModal}
        />
      )}

      {isReactivateModal && (
        <ReactivateModal
          isReactivateModal={isReactivateModal}
          setIsReactivateModal={setIsReactivateModal}
          link={link}
        />
      )}
    </>
  );
}
