export const CommentIcon = () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.4999 0.699996V6.99996C10.4999 7.38496 10.1849 7.69996 9.79994 7.69996H2.79998L0 10.4999V0.699996C0 0.314998 0.314998 0 0.699996 0H9.79994C10.1849 0 10.4999 0.314998 10.4999 0.699996ZM11.9001 2.79907H13.3001C13.685 2.79907 14 3.11407 14 3.49907V13.999L11.2001 11.199H3.50011C3.11511 11.199 2.80011 10.884 2.80011 10.499V9.09904H11.9001V2.79907Z"
        fill="#636363"
      />
    </svg>
  );
};
