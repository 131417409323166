export const PayIcon = ({ width, height, color }) => {
  const generalWidth = width ? width : "14";
  const generalHeight = height ? height : "12";
  const generalColor = color ? color : "#A98D4B";

  return (
    <svg
      width={generalWidth}
      height={generalHeight}
      viewBox="0 0 14 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.3536 2.57143H2.14286C1.90607 2.57143 1.71429 2.37964 1.71429 2.14286C1.71429 1.90607 1.90607 1.71429 2.14286 1.71429H12.4286C12.6654 1.71429 12.8571 1.5225 12.8571 1.28571C12.8571 0.575625 12.2815 0 11.5714 0H1.71429C0.767411 0 0 0.767411 0 1.71429V10.2857C0 11.2326 0.767411 12 1.71429 12H12.3536C13.1041 12 13.7143 11.4233 13.7143 10.7143V3.85714C13.7143 3.14812 13.1041 2.57143 12.3536 2.57143ZM11.1429 8.14286C10.6696 8.14286 10.2857 7.75902 10.2857 7.28571C10.2857 6.81241 10.6696 6.42857 11.1429 6.42857C11.6162 6.42857 12 6.81241 12 7.28571C12 7.75902 11.6162 8.14286 11.1429 8.14286Z"
        fill={generalColor}
      />
    </svg>
  );
};
