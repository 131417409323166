export const MarkSecondaryIcon = () => {
  return (
    <svg
      width="21"
      height="18"
      viewBox="0 0 21 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.1304 0L10.5631 0.747414L19.8263 16.7474L20.2608 17.4979H19.3935H0.867224H0L0.434511 16.7474L9.69767 0.747414L10.1304 0ZM18.5263 16.4979L10.1304 1.99586L1.73445 16.4979H18.5263ZM10.9724 14.4717H9.28818V12.7875H10.9724V14.4717ZM9.28818 11.1035H10.9724V7.73506H9.28818V11.1035Z"
        fill="#7D2B8B"
      />
    </svg>
  );
};
