import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { api } from "../../../urls";
import {
  ENG_LANGUAGE,
  MONTH_ENG_LANGUAGE,
  MONTH_SRB_LANGUAGE,
} from "../../../utils/variables";

import { SendReview } from "../SendReview";
import { FavoriteSelect } from "../FavoriteSelect";
import { MessageContent } from "../../Message/MessageContent";
import { SocialFacebook } from "../SocialFacebook";
import { MarkSecondaryIcon } from "../../../assets/image/MarkSecondaryIcon";
import { PhoneIcon } from "../../../assets/image/PhoneIcon";
import { ServiceIcon } from "../../../assets/image/ServiceIcon";
import { StarIcon } from "../../../assets/image/StarIcon";
import { WhiteStarIcon } from "../../../assets/image/WhiteStarIcon";
import { SearchIcon } from "../../../assets/image/SearchIcon";
import { MarkRoundIcon } from "../../../assets/image/MarkRoundIcon";
import { SearchSecondaryIcon } from "../../../assets/image/SearchSecondaryIcon";
import { CrownIcon } from "../../../assets/image/CrownIcon";
import {
  interviewInvitations,
  sendInvitation,
} from "../../../api/invitationApi";

export function InviteDetail({ id }) {
  const { t, i18n } = useTranslation();
  const language = i18n.language;

  const [parentData, setParentData] = useState([]);
  const [jobData, setJobData] = useState([]);
  const [view, setView] = useState("");
  const [rating, setRating] = useState({});
  const [isCheck, setIsCheck] = useState(true);

  const [showModal, setShowModal] = useState(false);
  const [plink, setplink] = useState(true);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);

  const [isActive, setIsActive] = useState(false);

  const [isDeleteCondition, setIsDeleteCondition] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [message, setMessage] = useState("");
  const [status, setStatus] = useState("");
  const [profile] = useState();

  const month =
    language === ENG_LANGUAGE ? MONTH_ENG_LANGUAGE : MONTH_SRB_LANGUAGE;

  const handleElementById = (e, x, z) => {
    document.getElementById(e).style.display = "none";
    document.getElementById(x).style.display = "block";
    if (plink) {
      document.getElementById(z).style.display = "block";
      setplink(false);
    } else {
      setplink(true);
      document.getElementById(z).style.display = "none";
    }
  };

  const fetchProfileData = async () => {
    try {
      const result = await interviewInvitations(id);
      setParentData([result.data.getparentsData]);
      setJobData(result.data.jobs);
      setRating(result.data);
      setMessage(result.data.currentUserName);
    } catch (error) {
      console.error("Error fetching profile data:", error);
    }
  };

  useEffect(() => {
    if (isCheck) {
      fetchProfileData();
      setIsCheck(false);
    }
  }, [isCheck, status]);

  const handaSubmitInvation = async (status) => {
    try {
      const result = await sendInvitation(id, status);

      let updatedParentData = [...parentData];
      if (updatedParentData.length > 0) {
        updatedParentData[0].status = status;
      }
      setParentData(updatedParentData);
      setIsDelete(false);
      setIsDeleteCondition(false);
      setStatus(status);
    } catch (error) {
      console.error("Error submitting invitation response:", error);
    }
  };

  return (
    <>
      {parentData && parentData[0] ? (
        view == "" ? (
          <>
            <h2 className="border"></h2>
            <div className="detail_invit">
              <p>
                <span className="date">
                  {t(
                    new Date(
                      parentData && parentData[0] && parentData[0].created_at
                    ).toLocaleDateString("en-US", { weekday: "short" })
                  )}
                  ,
                  {month[
                    new Date(
                      parentData && parentData[0] && parentData[0].created_at
                    ).getMonth()
                  ] +
                    " " +
                    new Date(
                      parentData && parentData[0] && parentData[0].created_at
                    ).getDate()}
                  ,
                  {new Date(
                    parentData && parentData[0] && parentData[0].created_at
                  ).getFullYear()}
                  ,
                  {new Date(
                    parentData && parentData[0] && parentData[0].created_at
                  ).toLocaleTimeString([], {
                    hour: "2-digit",
                    minute: "2-digit",
                  })}
                </span>
                {parentData &&
                parentData[0] &&
                parentData[0].read_status == 0 ? (
                  <span className="new">{t("text-new-upper")}!</span>
                ) : (
                  ""
                )}
                {jobData && jobData[0] && jobData[0].title != null
                  ? jobData[0].title
                  : ""}
              </p>
              <h2>
                {t("text-dear")} <span>{message}</span> {t("text-congratulations")}
              </h2>
              <h2>
                <Link to={"/profile-parents/" + parentData[0]?.user_id}>
                  {parentData && parentData[0] && parentData[0].first_name}
                </Link>
                {t("invited-interview")}
              </h2>
              <p>
                {t(
                  "accept-invite-interest"
                )}
              </p>
            </div>
            <div class="right_side_section">
              <div class="looking_for_candidate">
                {parentData.map((data, index) => {
                  return (
                    <div class="looking_for_candidate_boxs">
                      <div class="looking_for_candidate_box">
                        <div class="first_sec">
                          <div class="image_sec">
                            <img
                              src={
                                data.file_path != null
                                  ? api +
                                    "/assets/images/users/" +
                                    data.file_path
                                  : window.location.origin +
                                    window.location.origin +
                                    "/img/nany_img.png"
                              }
                              alt=""
                            />
                            <div class="heart_sec">
                              <FavoriteSelect
                                id={data.user_id}
                                username={data.first_name}
                              />
                            </div>
                          </div>
                          <div class="nany_social">
                            {data.phoneVerifiedStatus == 1 ? (
                              <img
                                src={
                                  window.location.origin +
                                  "/images/nany_phone.svg"
                                }
                                alt=""
                              />
                            ) : (
                              <PhoneIcon />
                            )}
                            <img
                              src={
                                window.location.origin + "/images/nany_msg.svg"
                              }
                              alt=""
                            />
                            {data.facebookverify != null ||
                            data.linkdinverify != null ? (
                              <img
                                src={
                                  window.location.origin +
                                  "/images/nany_cont.svg"
                                }
                                alt=""
                              />
                            ) : (
                              <ServiceIcon />
                            )}
                          </div>
                        </div>
                        <div class="second_sec">
                          <div class="heading">
                            <h3>
                              {jobData && jobData[0] && jobData[0].title != null
                                ? jobData[0].title
                                : ""}
                            </h3>
                            {jobData &&
                            jobData[0] &&
                            jobData[0].plateformonsocialmedia == "Yes" ? (
                              <SocialFacebook
                                link={
                                  window.location.origin +
                                  "/profile-parents/" +
                                  data.user_id
                                }
                              />
                            ) : (
                              ""
                            )}
                          </div>
                          <div class="post_general">
                            <h6>
                              <Link
                                to={
                                  "/profile-parents/" + parentData[0]?.user_id
                                }
                              >
                                {data.first_name != null
                                  ? data.first_name + " " + data.last_name + " "
                                  : ""}
                              </Link>
                              (
                              {data.dob != undefined
                                ? new Date().getFullYear() -
                                  parseInt(
                                    data.dob
                                      .substr(data.dob.lastIndexOf("\\") + 1)
                                      .split("-")[0]
                                  )
                                : ""}
                              )
                            </h6>

                            <p
                              onClick={(e) => {
                                if (
                                  rating.checkreviewstatus != true ||
                                  data.checkapplyornot == true
                                ) {
                                  setShowModal(true);
                                }
                              }}
                            >
                              {rating.reviewAvg >= 0 ? (
                                <>
                                  {[...Array(rating.reviewAvg)].map(
                                    (star, index) => {
                                      index += 1;
                                      return <StarIcon />;
                                    }
                                  )}
                                  {[...Array(5 - rating.reviewAvg)].map(
                                    (star, index) => {
                                      index += 1;
                                      return <WhiteStarIcon />;
                                    }
                                  )}
                                </>
                              ) : (
                                ""
                              )}
                              <span> ({rating.reviewcount})</span>
                            </p>
                          </div>
                          <div class="post_detail">
                            <div class="post">
                              {data.jobType != null ? (
                                data.jobType == "Nanny" ? (
                                  <img
                                    src={
                                      window.location.origin +
                                      "/images/nany_post.svg"
                                    }
                                    alt=""
                                  />
                                ) : data.jobType ==
                                  "Special Education Teacher" ? (
                                  <img
                                    src={
                                      window.location.origin +
                                      "/images/teacher_post.svg"
                                    }
                                    alt=""
                                  />
                                ) : data.jobType ==
                                  "Special Education Paraprofessional" ? (
                                  <img
                                    src={
                                      window.location.origin +
                                      "/images/education_post.svg"
                                    }
                                    alt=""
                                  />
                                ) : data.jobType == "Tutor" ? (
                                  <img
                                    src={
                                      window.location.origin +
                                      "/images/tutor_post.svg"
                                    }
                                    alt=""
                                  />
                                ) : (
                                  ""
                                )
                              ) : (
                                ""
                              )}
                              <h5>
                                {data.jobType != null ? t(data.jobType) : ""}
                              </h5>
                            </div>
                            <div class="vi"></div>

                            <div class="post_pay">
                              <img
                                src={
                                  window.location.origin + "/img/post_pay.png"
                                }
                                alt=""
                              />
                              {profile && profile.country == "Serbia" ? (
                                <h5>
                                  {data.nanyperhrrate != null
                                    ? data.nanyperhrrate
                                        .substr(
                                          data.nanyperhrrate.lastIndexOf("\\") +
                                            1
                                        )
                                        .split("-")[0] *
                                        100 +
                                      " - " +
                                      data.nanyperhrrate
                                        .substr(
                                          data.nanyperhrrate.lastIndexOf("\\") +
                                            1
                                        )
                                        .split("-")[1] *
                                        100
                                    : data.tutorperhrrate
                                    ? data.tutorperhrrate
                                        .substr(
                                          data.tutorperhrrate.lastIndexOf(
                                            "\\"
                                          ) + 1
                                        )
                                        .split("-")[0] *
                                        100 +
                                      " - " +
                                      data.tutorperhrrate
                                        .substr(
                                          data.tutorperhrrate.lastIndexOf(
                                            "\\"
                                          ) + 1
                                        )
                                        .split("-")[1] *
                                        100
                                    : ""}
                                  {t("time-hour")}
                                </h5>
                              ) : (
                                <h5>
                                  $
                                  {data.nanyperhrrate != null
                                    ? data.nanyperhrrate
                                    : data.tutorperhrrate
                                    ? data.tutorperhrrate
                                    : ""}
                                  {t("time-hour")}
                                </h5>
                              )}
                            </div>

                            <div class="vi"></div>
                            <div class="post_fet">
                              <img
                                src={
                                  window.location.origin +
                                  "/images/post_fet.svg"
                                }
                                alt=""
                              />
                              <h5>
                                {data.nanyintrestedin != null
                                  ? data.nanyintrestedin
                                  : t("full-time")}
                              </h5>
                            </div>
                            <div class="vi"></div>
                            <div class="post_cal">
                              <img
                                src={
                                  window.location.origin +
                                  "/images/post_cal.svg"
                                }
                                alt=""
                              />
                              <h5>
                                {data.nanystartdate != null
                                  ? data.nanystartdate
                                  : data.tutorstartdate}
                              </h5>
                            </div>
                            <div class="vi"></div>
                            <div class="post_loc">
                              <img
                                src={
                                  window.location.origin +
                                  "/images/post_loc.svg"
                                }
                                alt=""
                              />
                              <h5>
                                {data.country != null ? data.country : ""} ,
                                {data.city != null ? data.city : ""}
                              </h5>
                            </div>
                          </div>
                          <p id={"half" + index}>
                            {jobData &&
                            jobData[0] &&
                            jobData[0].description != null
                              ? jobData &&
                                jobData[0] &&
                                jobData[0].description.substr(0, 100)
                              : ""}
                            {jobData &&
                            jobData[0] &&
                            jobData[0].description != null &&
                            jobData &&
                            jobData[0] &&
                            jobData[0].description.length > 100 ? (
                              <span
                                onClick={(e) =>
                                  handleElementById(
                                    "half" + index,
                                    "full" + index,
                                    "plink" + index
                                  )
                                }
                              >
                                {t("text-more")}
                              </span>
                            ) : (
                              ""
                            )}
                          </p>
                          <p id={"full" + index} className="hide">
                            {jobData && jobData[0] && jobData[0].description}
                            <span
                              onClick={(e) =>
                                handleElementById(
                                  "full" + index,
                                  "half" + index,
                                  "plink" + index
                                )
                              }
                            >
                              {t("text-less")}
                            </span>
                          </p>
                        </div>
                        <div className="find_search">
                          <Link to="/search-parents">
                            <SearchIcon />
                            {t("search-similar-jobs")}
                          </Link>
                        </div>
                        <div class="view_profile_btn">
                          {data.status == 1 ? (
                            <button onClick={(e) => setView(data.user_id)}>
                              {t("send-message")}
                            </button>
                          ) : (
                            <button
                              style={
                                data.status == 2 || status == 2
                                  ? { opacity: "0.5" }
                                  : {}
                              }
                              onClick={(e) => setIsDeleteCondition(true)}
                              disabled={
                                data.status == 2 || status == 2 ? true : false
                              }
                            >
                              {status == 2 || data.status == 2
                                ? t("text-declined-up")
                                : t("text-decline")}
                            </button>
                          )}
                          {data.status == 1 ? (
                            new Date(
                              parentData &&
                                parentData[0] &&
                                parentData[0].created_at
                            ).getMonth() < new Date().getMonth() ? (
                              <Link
                                to={""}
                                onClick={(e) => {
                                  setIsActive(true);
                                }}
                              >
                                {t("visit-profile")}
                              </Link>
                            ) : (
                              <Link to={"/profile-parents/" + data.user_id}>
                                {t("visit-profile")}
                              </Link>
                            )
                          ) : data.status == 2 || status == 2 ? (
                            <Link
                              to={""}
                              style={
                                data.status == 2 || status == 2
                                  ? { opacity: "0.5" }
                                  : {}
                              }
                            >
                              {t("accept-visit-profile")}
                            </Link>
                          ) : (
                            <Link
                              to={""}
                              disabled={
                                data.status == 2 || status == 2 ? true : false
                              }
                              style={
                                data.status == 2 || status == 2
                                  ? { opacity: "0.5" }
                                  : {}
                              }
                              onClick={(e) => {
                                localStorage.removeItem("search");
                                localStorage.removeItem("search2");
                                setIsDelete(true);
                              }}
                            >
                              {t("accept-visit-profile")}
                            </Link>
                          )}
                        </div>
                      </div>
                      <p>
                        {(data.childmedicalcondition != null &&
                          data.childmedicalcondition == "Yes") ||
                          (data.childanyallergies != null &&
                            data.childanyallergies == "Yes" && (
                              <>
                                <MarkRoundIcon />
                                <strong>{t("note-with")}</strong>
                              </>
                            ))}
                        {t(
                          "respond-promptly"
                        )}
                        <Link to="/faq" target="_blank">
                          {t("text-faq")}
                        </Link>
                        {t("text-and")}
                        <Link to="/safety-center" target="_blank">
                          {t("safety-center")}
                        </Link>
                        {t("read-safety-tips")}
                      </p>
                    </div>
                  );
                })}
                <div className="mobile">
                  {parentData.map((data, index) => {
                    if (index <= 5) {
                      return (
                        <>
                          <div
                            class="looking_for_candidate_boxs"
                            style={{ display: "block" }}
                          >
                            <div class="looking_for_candidate_box">
                              <div class="second_sec">
                                <div class="heading">
                                  <h3>
                                    {jobData &&
                                    jobData[0] &&
                                    jobData[0].title != null
                                      ? jobData[0].title
                                      : ""}
                                  </h3>
                                  {jobData &&
                                  jobData[0] &&
                                  jobData[0].plateformonsocialmedia == "Yes" ? (
                                    <SocialFacebook
                                      link={
                                        window.location.origin +
                                        "/profile-parents/" +
                                        data.user_id
                                      }
                                    />
                                  ) : (
                                    ""
                                  )}
                                </div>
                                <div class="post_general">
                                  <h6>
                                    {data.first_name != null
                                      ? data.first_name
                                      : ""}
                                    (
                                    {data.dob != undefined
                                      ? new Date().getFullYear() -
                                        parseInt(
                                          data.dob
                                            .substr(
                                              data.dob.lastIndexOf("\\") + 1
                                            )
                                            .split("-")[0]
                                        )
                                      : ""}
                                    )
                                  </h6>
                                  <p
                                    onClick={(e) => {
                                      if (rating.checkreviewstatus != true) {
                                        setShowModal(true);
                                      }
                                    }}
                                  >
                                    {rating.reviewAvg >= 0 ? (
                                      <>
                                        {[...Array(rating.reviewAvg)].map(
                                          (star, index) => {
                                            index += 1;
                                            return <StarIcon />;
                                          }
                                        )}
                                        {[...Array(5 - rating.reviewAvg)].map(
                                          (star, index) => {
                                            index += 1;
                                            return <WhiteStarIcon />;
                                          }
                                        )}
                                      </>
                                    ) : (
                                      ""
                                    )}
                                    <span> ({rating.reviewcount})</span>
                                  </p>
                                </div>
                                <div class="post_detail">
                                  <div class="post">
                                    {JSON.parse(data.service_type) != null ? (
                                      Object.values(
                                        JSON.parse(data.service_type)
                                      )[0] == "Nanny" ? (
                                        <img
                                          src={
                                            window.location.origin +
                                            "/images/nany_post.svg"
                                          }
                                          alt=""
                                        />
                                      ) : Object.keys(
                                          JSON.parse(data.service_type)
                                        )[0] == "tab2" ? (
                                        <img
                                          src={
                                            window.location.origin +
                                            "/images/teacher_post.svg"
                                          }
                                          alt=""
                                        />
                                      ) : Object.keys(
                                          JSON.parse(data.service_type)
                                        )[0] == "tab3" ? (
                                        <img
                                          src={
                                            window.location.origin +
                                            "/images/education_post.svg"
                                          }
                                          alt=""
                                        />
                                      ) : Object.keys(
                                          JSON.parse(data.service_type)
                                        )[0] == "tab4" ? (
                                        <img
                                          src={
                                            window.location.origin +
                                            "/images/tutor_post.svg"
                                          }
                                          alt=""
                                        />
                                      ) : (
                                        ""
                                      )
                                    ) : (
                                      ""
                                    )}
                                    <h5>
                                      {JSON.parse(data.service_type) != null
                                        ? Object.values(
                                            JSON.parse(data.service_type)
                                          )[0] ==
                                          "Special Education Paraprofessional"
                                          ? t("sped-paraprofessional")
                                          : Object.values(
                                              JSON.parse(data.service_type)
                                            )[0] == "Special Education Teacher"
                                          ? t("sped-teacher")
                                          : Object.values(
                                              JSON.parse(data.service_type)
                                            )[0]
                                        : ""}
                                    </h5>
                                  </div>
                                  <div class="vi"></div>
                                  <div class="post_pay">
                                    <img
                                      src={
                                        window.location.origin +
                                        "/img/post_pay.png"
                                      }
                                      alt=""
                                    />
                                    {profile && profile.country == "Serbia" ? (
                                      <h5>
                                        {data.nanyperhrrate != null
                                          ? data.nanyperhrrate
                                              .substr(
                                                data.nanyperhrrate.lastIndexOf(
                                                  "\\"
                                                ) + 1
                                              )
                                              .split("-")[0] *
                                              100 +
                                            " - " +
                                            data.nanyperhrrate
                                              .substr(
                                                data.nanyperhrrate.lastIndexOf(
                                                  "\\"
                                                ) + 1
                                              )
                                              .split("-")[1] *
                                              100
                                          : data.tutorperhrrate
                                          ? data.tutorperhrrate
                                              .substr(
                                                data.tutorperhrrate.lastIndexOf(
                                                  "\\"
                                                ) + 1
                                              )
                                              .split("-")[0] *
                                              100 +
                                            " - " +
                                            data.tutorperhrrate
                                              .substr(
                                                data.tutorperhrrate.lastIndexOf(
                                                  "\\"
                                                ) + 1
                                              )
                                              .split("-")[1] *
                                              100
                                          : ""}
                                        {t("time-hour")}
                                      </h5>
                                    ) : (
                                      <h5>
                                        $
                                        {data.nanyperhrrate != null
                                          ? data.nanyperhrrate
                                          : data.tutorperhrrate
                                          ? data.tutorperhrrate
                                          : ""}
                                        {t("time-hour")}
                                      </h5>
                                    )}
                                  </div>
                                  <div class="vi"></div>
                                  <div class="post_fet">
                                    <img
                                      src={
                                        window.location.origin +
                                        "/images/post_fet.svg"
                                      }
                                      alt=""
                                    />
                                    <h5>
                                      {data.nanyintrestedin != null
                                        ? data.nanyintrestedin
                                        : t("full-time")}
                                    </h5>
                                  </div>
                                  <div class="vi"></div>
                                  <div class="post_cal">
                                    <img
                                      src={
                                        window.location.origin +
                                        "/images/post_cal.svg"
                                      }
                                      alt=""
                                    />
                                    <h5>
                                      {data.nanystartdate != null
                                        ? data.nanystartdate
                                        : data.tutorstartdate}
                                    </h5>
                                  </div>
                                  <div class="vi"></div>
                                  <div class="post_loc">
                                    <img
                                      src={
                                        window.location.origin +
                                        "/images/post_loc.svg"
                                      }
                                      alt=""
                                    />
                                    <h5>
                                      {data.country != null ? data.country : ""}
                                      , {data.city != null ? data.city : ""}
                                    </h5>
                                  </div>
                                </div>
                              </div>
                              <div class="first_sec">
                                <div class="image_sec">
                                  <img
                                    src={
                                      data.file_path != null
                                        ? api +
                                          "/assets/images/users/" +
                                          data.file_path
                                        : window.location.origin +
                                          "/img/nany_img.png"
                                    }
                                    alt=""
                                  />
                                  <div class="heart_sec">
                                    <FavoriteSelect
                                      id={data.user_id}
                                      username={data.first_name}
                                    />
                                  </div>
                                </div>
                                <div class="nany_social">
                                  {data.phoneVerifiedStatus == 1 ? (
                                    <img
                                      src={
                                        window.location.origin +
                                        "/images/nany_phone.svg"
                                      }
                                      alt=""
                                    />
                                  ) : (
                                    <PhoneIcon />
                                  )}
                                  <img
                                    src={
                                      window.location.origin +
                                      "/images/nany_msg.svg"
                                    }
                                    alt=""
                                  />
                                  {data.facebookverify != null ||
                                  data.linkdinverify != null ? (
                                    <img
                                      src={
                                        window.location.origin +
                                        "/images/nany_cont.svg"
                                      }
                                      alt=""
                                    />
                                  ) : (
                                    <ServiceIcon />
                                  )}
                                </div>
                              </div>
                              <div class="second_sec">
                                <p id={"half" + index}>
                                  {jobData &&
                                  jobData[0] &&
                                  jobData[0].description != null
                                    ? jobData &&
                                      jobData[0] &&
                                      jobData[0].description.substr(0, 100)
                                    : ""}
                                  {jobData &&
                                  jobData[0] &&
                                  jobData[0].description != null &&
                                  jobData &&
                                  jobData[0] &&
                                  jobData[0].description.length > 100 ? (
                                    <span
                                      onClick={(e) =>
                                        handleElementById(
                                          "half" + index,
                                          "full" + index,
                                          "plink" + index
                                        )
                                      }
                                    >
                                      {t("text-more")}
                                    </span>
                                  ) : (
                                    ""
                                  )}
                                </p>
                                <p id={"full" + index} className="hide">
                                  {jobData &&
                                    jobData[0] &&
                                    jobData[0].description}
                                  <span
                                    onClick={(e) =>
                                      handleElementById(
                                        "full" + index,
                                        "half" + index,
                                        "plink" + index
                                      )
                                    }
                                  >
                                    {t("text-less")}
                                  </span>
                                </p>
                              </div>
                              <p className="parbot">
                                {(data.childmedicalcondition != null &&
                                  data.childmedicalcondition == "Yes") ||
                                (data.childanyallergies != null &&
                                  data.childanyallergies == "Yes") ? (
                                  <>
                                    <MarkRoundIcon />
                                    <strong>{t("note-with")}</strong>
                                    {t(
                                      "record-indicates"
                                    )}
                                    <br />
                                  </>
                                ) : (
                                  ""
                                )}
                                {t(
                                  "respond-promptly"
                                )}
                                <Link to="/faq" target="_blank">
                                  {t("text-faq")}
                                </Link>
                                {t("text-and")}
                                <Link to="/safety-center" target="_blank">
                                  {t("safety-center")}
                                </Link>
                                {t("read-safety-tips")}
                              </p>
                              <div class="view_profile_btn visiterbtn ">
                                {data.status == 1 ? (
                                  new Date(
                                    parentData &&
                                      parentData[0] &&
                                      parentData[0].created_at
                                  ).getMonth() < new Date().getMonth() ? (
                                    <Link
                                      to={""}
                                      onClick={(e) => {
                                        setIsActive(true);
                                      }}
                                    >
                                      {t("visit-profile")}
                                    </Link>
                                  ) : (
                                    <Link
                                      to={"/profile-parents/" + data.user_id}
                                    >
                                      {t("visit-profile")}
                                    </Link>
                                  )
                                ) : data.status == 2 || status == 2 ? (
                                  <Link
                                    to={""}
                                    style={
                                      data.status == 2 || status == 2
                                        ? { opacity: "0.5" }
                                        : {}
                                    }
                                  >
                                    {t("accept-visit-profile")}
                                  </Link>
                                ) : (
                                  <Link
                                    to={""}
                                    disabled={
                                      data.status == 2 || status == 2
                                        ? true
                                        : false
                                    }
                                    style={
                                      data.status == 2 || status == 2
                                        ? { opacity: "0.5" }
                                        : {}
                                    }
                                    onClick={(e) => {
                                      localStorage.removeItem("search");
                                      localStorage.removeItem("search2");
                                      setIsDelete(true);
                                    }}
                                  >
                                    {t("accept-visit-profile")}
                                  </Link>
                                )}
                                {data.status == 1 ? (
                                  <a onClick={(e) => setView(data.user_id)}>
                                    {"Send message"}
                                  </a>
                                ) : (
                                  <a
                                    style={
                                      data.status == 2 || status == 2
                                        ? { opacity: "0.5" }
                                        : {}
                                    }
                                    onClick={(e) => {
                                      data.status == 2 || status == 2
                                        ? setIsDeleteCondition(false)
                                        : setIsDeleteCondition(true);
                                    }}
                                    disabled={
                                      data.status == 2 || status == 2
                                        ? true
                                        : false
                                    }
                                  >
                                    {status == 2 || data.status == 2
                                      ? "Declined"
                                      : "Decline"}
                                  </a>
                                )}
                                <br />
                                <Link
                                  to="/search-parents"
                                  style={{
                                    border: "none",
                                    background: "transparent",
                                    color: "#7D2B8B",
                                    fontSize: "17px",
                                    width: "100%",
                                    marginLeft: "0",
                                    padding: "5px 0",
                                  }}
                                >
                                  <SearchIcon />
                                  {t("search-similar-jobs")}
                                </Link>
                              </div>
                            </div>
                          </div>
                        </>
                      );
                    }
                  })}
                </div>
              </div>
            </div>
          </>
        ) : (
          ""
        )
      ) : (
        ""
      )}
      {view != "" && (
        <div class="main-header">
          <MessageContent id={view} setview={setView} />
        </div>
      )}
      {showModal && (
        <Modal show={showModal} onHide={(e) => setShowModal(false)}>
          <Modal.Body>
            <SendReview
              setrequest={setShowModal}
              slugdata={parentData && parentData[0] && parentData[0].user_id}
              username={
                parentData &&
                parentData[0] &&
                parentData[0].first_name + " " + parentData &&
                parentData[0] &&
                parentData[0].last_name
              }
            />
          </Modal.Body>
        </Modal>
      )}

      {isDeleteCondition ? (
        <Modal
          show={isDeleteCondition}
          onHide={(e) => setIsDeleteCondition(false)}
          className=""
        >
          <Modal.Body>
            <div className="promocode_content upload_document_delete">
              <MarkSecondaryIcon />
              <h2>{t("decline-interview-invite")}</h2>

              <div className="gray">
                <p style={{ borderBottom: "1px solid #B7B7B7" }}>
                  <span>{t("text-important-mark")}</span>
                  <b>
                    {t(
                      "might-lead-fewer"
                    )}
                  </b>
                </p>
                <p>
                  {t("decline-invite-question")}
                </p>
                <button
                  onClick={(e) => {
                    setShowLoginModal(true);
                    handaSubmitInvation("2");
                  }}
                >
                  {t("confirm")}
                </button>
                <button onClick={(e) => setIsDeleteCondition(false)}>
                  {t("objection")}
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      ) : (
        ""
      )}

      {isDelete && (
        <Modal show={isDelete} onHide={(e) => setIsDelete(false)} className="">
          <Modal.Body>
            <div className="promocode_content upload_document_delete acceptinterview">
              <h2>{t("accept-interview-invite")}</h2>
              <p>{t("accept-invite-question")}</p>
              <button onClick={(e) => setIsDelete(false)}>
                {t("text-decline")}
              </button>
              <button onClick={(e) => handaSubmitInvation("1")}>
                <Link to={"/profile-parents/" + parentData[0].user_id}>
                  {t("text-accept-upper")}
                </Link>
              </button>
              <div className="gray">
                <p>
                  {t("please-visit-our")}
                  <Link to="/faq" target="_blank">
                    {t("text-faqs")}
                  </Link>
                  {t("gap")}
                  <Link to="/safety-center" target="_blank">
                    {t("safety-center")}
                  </Link>
                  {t("read-safety-tip-our")}
                </p>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      )}

      <Modal show={showLoginModal} onHide={(e) => setShowLoginModal(false)}>
        <Modal.Body>
          <div className="promocode_content login_first search_pop">
            <Link to="" onClick={(e) => setShowLoginModal(false)}>
              +
            </Link>
            <h2>{t("like-not-job")}</h2>
            <img src={window.location.origin + "/images/return.png"} />
            <p style={{ color: "#7D2B8B" }}>
              {t("post-perfect-job")}
            </p>
            <button onClick={(e) => setShowLoginModal(false)}>
              <Link to={"/search-parents"}>
                <SearchSecondaryIcon />
                {t("search-jobs")}
              </Link>
            </button>
          </div>
        </Modal.Body>
      </Modal>
      <Modal show={isUpdate} onHide={(e) => setIsUpdate(false)}>
        <Modal.Body>
          <div className="promocode_content login_first search_pop upgd">
            <Link to="" onClick={(e) => setIsUpdate(false)}>
              +
            </Link>
            <h2>
              {t(
                "post-full-benefits"
              )}
            </h2>
            <img src={window.location.origin + "/images/promo.png"} />
            <p>
              {t(
                "members-message-center"
              )}
            </p>
            <button onClick={(e) => setIsUpdate(false)} className={"ruf"}>
              <Link to={""}> {t("text-maybe-later")}</Link>
            </button>
            <button onClick={(e) => setIsUpdate(false)}>
              <Link to={""}>
                <CrownIcon />
                {t("upgrade-now")}
              </Link>
            </button>
          </div>
        </Modal.Body>
      </Modal>
      <Modal show={isActive} onHide={(e) => setIsActive(false)}>
        <Modal.Body>
          <div className="promocode_content login_first search_pop">
            <Link to="" onClick={(e) => setIsActive(false)}>
              +
            </Link>
            <h2>{t("profile-not-found")}</h2>
            <p>
              <span style={{ color: "#A98D4B" }}>
                {parentData && parentData[0] && parentData[0].first_name}
              </span>
              {t("no-longer-active-profile")}
            </p>
            <button
              style={{ padding: "10px 14px" }}
              onClick={(e) => setIsActive(false)}
            >
              <Link to={"/search-parents"}>
                <SearchSecondaryIcon />
                {t("find-similar-job")}
              </Link>
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
