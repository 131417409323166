import React from "react";
import { useTranslation } from "react-i18next";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";

import { MarkRoundIcon } from "../../../assets/image/MarkRoundIcon";

export const InterviewModal = ({
  isInterviewInvite,
  setIsIterviewInvite,
  profile,
}) => {
  const { t } = useTranslation();

  return (
    <Modal show={isInterviewInvite} onHide={(e) => setIsIterviewInvite(false)}>
      <Modal.Body>
        <div className="promocode_content signout invite">
          <Link to="" onClick={(e) => setIsIterviewInvite(false)}>
            +
          </Link>
          <h2>{t("Interview invite")}</h2>
          <p>
            {t("Are you sure you want to send an Interview invite to")}
            <span style={{ color: "#A98D4B" }}>
              {profile.first_name + " " + profile.last_name}
            </span>
            ?
          </p>
          <div className="button text-center ">
            <div className="pull-right">
              <button
                className="btn"
                onClick={(e) => setIsIterviewInvite(false)}
              >
                {t("text-cancel")}
              </button>
            </div>
            <div className="pull-right">
              <button
                className="btn confirm"
                onClick={(e) => setIsIterviewInvite(false)}
              >
                {t("Send invite")}
              </button>
            </div>
          </div>
          {profile.childmedicalcondition == "Yes" ||
          profile.childanyallergies == "Yes" ? (
            <div className="noteedit">
              <MarkRoundIcon />
              <span>
                
                <b> {t("note-with")}</b> {t("record-indicates")}
              </span>
            </div>
          ) : (
            ""
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};
