import React from "react";
import { useTranslation } from "react-i18next";
import Modal from "react-bootstrap/Modal";

import { useRatesStore } from "../../../../store/useRatesStore";
import { useProfileDetailsStore } from "../../../../store/useProfileDetailsStore";
import { useProviderFieldStore } from "../../../../store/useProviderFieldStore";

import { RATE_NUMBERS, RATE_NUMBERS_SECOND, STEP_TUTOR } from "../../../../utils/variables";

export const TutorModal = ({
  showTutorModal,
  handleCloseTutorModal,
  detail,
  countp,
  tutorExperience,
  handleSubmitProvider,
  setTutorExprerience,
  validateAfterLoginField,

}) => {
  const { t } = useTranslation();

  const { tutorRate, setTutorRate } = useRatesStore();
  const { qualifications, setQualifications } = useProfileDetailsStore();
  const { errorField, setErrorField, detailProvider, setDetailProvider } =
    useProviderFieldStore();

  return (
    <Modal
      show={showTutorModal}
      onHide={handleCloseTutorModal}
      className="question_new"
    >
      <Modal.Body>
        <div className="younger">
          <img src="/images/sign_logo.svg" />

          <h2>{t("Complete the Profile")}</h2>
          <p>{t("You can edit your info in settings section later")}</p>
          <div className="Profile_complete">
            <div className="detail work-experience  your_kids  setp3">
              <div className="nanny tutor">
                <h2 className="border">
                  <img src="/images/tutorform.svg" /> {t("text-tutor")}
                </h2>
                <div className="col-6">
                  <div className="iconsec">
                    <div className="form_group full">
                      <label>
                        {t("Years of work experience")}
                        <span
                          className={errorField.tutorexp != "" ? "starred" : ""}
                        >
                          *
                        </span>
                      </label>
                      <div className="rang" style={{ minWidth: "375px" }}>
                        <div
                          class="slider"
                          onClick={(w) =>
                            setErrorField({ ...errorField, tutorexp: "" })
                          }
                        >
                          <input
                            type="range"
                            min="0"
                            max="60"
                            onChange={(e) =>
                              setTutorExprerience(e.target.value)
                            }
                            style={{
                              "background-size":
                                (tutorExperience * 100) / 60 + "% 100% ",
                            }}
                            value={tutorExperience}
                          />
                          <ul>
                            <li>0</li>
                            <li>15</li>
                            <li>30</li>
                            <li>45</li>
                            <li>60</li>
                          </ul>
                        </div>
                        <span id="rangeValue">{tutorExperience}</span>
                      </div>
                    </div>
                  </div>
                  <div className="rightsec">
                    <div className="form_group full">
                      <label>
                        {t("Rate per hour")}
                        {detail.country == "Serbia" ? "(RSD)" : "(USD)"}
                        <span
                          className={
                            errorField.nanyperhrrate != "" ? "starred" : ""
                          }
                        >
                          *
                        </span>
                      </label>
                      {detail && detail.country == "Serbia" ? (
                        <div class="wrapper rang">
                          <div class="container_slide">
                            <div class="slider-track">
                              <ul>
                              {RATE_NUMBERS.map((value, index) => {
                                    const style =
                                      tutorRate.min >= value ||
                                      (tutorRate.max < value && tutorRate.max > 0)
                                        ? { backgroundColor: "#E5E5E5" }
                                        : {};
                                    return <li key={index} style={style}></li>;
                                  })}
                              </ul>
                            </div>
                            <div class="bggray_slider">
                              <ul>
                              {RATE_NUMBERS_SECOND.map((value, index) => {
                                    const style =
                                      tutorRate.min >= value ||
                                      (tutorRate.max < value && tutorRate.max > 0)
                                        ? { backgroundColor: "#E5E5E5" }
                                        : {};
                                    return <li key={index} style={style}></li>;
                                  })}
                              </ul>
                            </div>
                            <input
                              type="range"
                              min="0"
                              max="5"
                              id="slider-1"
                              value={tutorRate.min / 10}
                              onChange={(e) => {
                                if (
                                  (tutorRate.max > 0 ? tutorRate.max : 60) >
                                  (e.target.value == 5
                                    ? 60
                                    : e.target.value * 10)
                                ) {
                                  setTutorRate({
                                    ...tutorRate,
                                    min:
                                      e.target.value == 5
                                        ? 60
                                        : e.target.value * 10,
                                  });
                                }
                              }}
                            />
                            <input
                              type="range"
                              min="0"
                              max="5"
                              id="slider-2"
                              value={
                                tutorRate.max == 0
                                  ? 50 / 10
                                  : tutorRate.max / 10
                              }
                              onChange={(e) => {
                                if (
                                  tutorRate.min <
                                  (e.target.value == 5
                                    ? 60
                                    : e.target.value * 10)
                                ) {
                                  setTutorRate({
                                    ...tutorRate,
                                    max:
                                      e.target.value == 5
                                        ? 60
                                        : e.target.value * 10,
                                  });
                                }
                              }}
                            />
                          </div>
                          <div class="slider two">
                            <ul>
                              <li>0</li>
                              <li>1000</li>
                              <li>2000</li>
                              <li>3000</li>
                              <li>4000</li>
                              <li>6000+</li>
                            </ul>
                          </div>
                        </div>
                      ) : (
                        <div class="wrapper rang">
                          <div class="container_slide">
                            <div class="slider-track">
                              <ul>
                              {RATE_NUMBERS.map((value, index) => {
                                    const style =
                                      tutorRate.min >= value ||
                                      (tutorRate.max < value && tutorRate.max > 0)
                                        ? { backgroundColor: "#E5E5E5" }
                                        : {};
                                    return <li key={index} style={style}></li>;
                                  })}
                              </ul>
                            </div>
                            <div class="bggray_slider">
                              <ul>
                              {RATE_NUMBERS_SECOND.map((value, index) => {
                                    const style =
                                      tutorRate.min >= value ||
                                      (tutorRate.max < value && tutorRate.max > 0)
                                        ? { backgroundColor: "#E5E5E5" }
                                        : {};
                                    return <li key={index} style={style}></li>;
                                  })}
                              </ul>
                            </div>
                            <input
                              type="range"
                              min="0"
                              max="5"
                              id="slider-1"
                              value={tutorRate.min / 10}
                              onChange={(e) => {
                                if (
                                  (tutorRate.max > 0 ? tutorRate.max : 60) >
                                  (e.target.value == 5
                                    ? 60
                                    : e.target.value * 10)
                                ) {
                                  setTutorRate({
                                    ...tutorRate,
                                    min:
                                      e.target.value == 5
                                        ? 60
                                        : e.target.value * 10,
                                  });
                                }
                              }}
                            />
                            <input
                              type="range"
                              min="0"
                              max="5"
                              id="slider-2"
                              value={
                                tutorRate.max == 0
                                  ? 50 / 10
                                  : tutorRate.max / 10
                              }
                              onChange={(e) => {
                                if (
                                  tutorRate.min <
                                  (e.target.value == 5
                                    ? 60
                                    : e.target.value * 10)
                                ) {
                                  setTutorRate({
                                    ...tutorRate,
                                    max:
                                      e.target.value == 5
                                        ? 60
                                        : e.target.value * 10,
                                  });
                                }
                              }}
                            />
                          </div>
                          <div class="slider two">
                            <ul>
                              <li>0</li>
                              <li>10</li>
                              <li>20</li>
                              <li>30</li>
                              <li>40</li>
                              <li>60+</li>
                            </ul>
                          </div>
                        </div>
                      )}
                      <span class="price">
                        {detail && detail.country == "Serbia"
                          ? tutorRate.min * 100 + " - " + tutorRate.max * 100
                          : tutorRate.min + " - " + tutorRate.max}
                      </span>
                    </div>
                  </div>
                  <div className="iconsec">
                    <div className="form_group full qualification tutorteach">
                      <label>
                        {t("What classes would you teach?")}
                        <span
                          className={
                            errorField.tutorliketoteach == "" ? "" : "starred"
                          }
                        >
                          *
                        </span>
                      </label>
                      <div className="checkbox">
                        <ul
                          onClick={(e) =>
                            setErrorField({
                              ...errorField,
                              tutorliketoteach: "",
                            })
                          }
                        >
                          <li>
                            <input
                              type="checkbox"
                              name=""
                              checked={
                                qualifications.English == t("english")
                                  ? true
                                  : false
                              }
                              onClick={(e) => {
                                if (e.target.checked) {
                                  setQualifications({
                                    ...qualifications,
                                    English: t("english"),
                                  });
                                } else {
                                  setQualifications({
                                    ...qualifications,
                                    English: "",
                                  });
                                }
                              }}
                            />
                            <span> {t("english")}</span>
                          </li>

                          <li>
                            <input
                              type="checkbox"
                              name=""
                              checked={
                                qualifications.Serbian == t("serbian")
                                  ? true
                                  : false
                              }
                              onClick={(e) => {
                                if (e.target.checked) {
                                  setQualifications({
                                    ...qualifications,
                                    Serbian: t("serbian"),
                                  });
                                } else {
                                  setQualifications({
                                    ...qualifications,
                                    Serbian: "",
                                  });
                                }
                              }}
                            />
                            <span> {t("serbian")}</span>
                          </li>
                          <li>
                            <input
                              type="checkbox"
                              name=""
                              checked={
                                qualifications.Mathematics == t("mathematics")
                                  ? true
                                  : false
                              }
                              onClick={(e) => {
                                if (e.target.checked) {
                                  setQualifications({
                                    ...qualifications,
                                    Mathematics: t("mathematics"),
                                  });
                                } else {
                                  setQualifications({
                                    ...qualifications,
                                    Mathematics: "",
                                  });
                                }
                              }}
                            />
                            <span> {t("mathematics")}</span>
                          </li>
                          <li>
                            <input
                              type="checkbox"
                              name=""
                              checked={
                                qualifications.Physics == t("physics")
                                  ? true
                                  : false
                              }
                              onClick={(e) => {
                                if (e.target.checked) {
                                  setQualifications({
                                    ...qualifications,
                                    Physics: t("physics"),
                                  });
                                } else {
                                  setQualifications({
                                    ...qualifications,
                                    Physics: "",
                                  });
                                }
                              }}
                            />
                            <span> {t("physics")}</span>
                          </li>

                          <li>
                            <input
                              type="checkbox"
                              name=""
                              checked={
                                qualifications.Chemistry == t("chemistry")
                                  ? true
                                  : false
                              }
                              onClick={(e) => {
                                if (e.target.checked) {
                                  setQualifications({
                                    ...qualifications,
                                    Chemistry: t("chemistry"),
                                  });
                                } else {
                                  setQualifications({
                                    ...qualifications,
                                    Chemistry: "",
                                  });
                                }
                              }}
                            />
                            <span> {t("chemistry")}</span>
                          </li>
                          <li>
                            <input type="checkbox" name="" />
                            <span>
                              {t("text-other")}
                              <input
                                type="text"
                                placeholder={t("text-other")}
                                onChange={(e) => {
                                  setQualifications({
                                    ...qualifications,
                                    Other: e.target.value,
                                  });
                                }}
                                defaultValue={
                                  qualifications.Other
                                    ? qualifications.Other
                                    : " "
                                }
                              />
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div className="rightsec">
                    <div className="form_group full">
                      <label>
                        {t("I am interested in providing online classes")}
                        <span
                          className={
                            errorField.tutorintrestedonlinecls != ""
                              ? "starred"
                              : ""
                          }
                        >
                          *
                        </span>
                      </label>
                      <div className="checkbox create">
                        <ul
                          onClick={(e) =>
                            setErrorField({
                              ...errorField,
                              tutorintrestedonlinecls: "",
                            })
                          }
                        >
                          <li>
                            <input
                              type="radio"
                              name="yes"
                              checked={
                                detailProvider.tutorintrestedonlinecls ===
                                t("confirm")
                                  ? true
                                  : false
                              }
                              onClick={(e) =>
                                setDetailProvider({
                                  ...detailProvider,
                                  tutorintrestedonlinecls: t("confirm"),
                                })
                              }
                            />
                            <span> {t("confirm")}</span>
                          </li>
                          <li>
                            <input
                              type="radio"
                              name="yes"
                              checked={
                                detailProvider.tutorintrestedonlinecls ===
                                t("objection")
                                  ? true
                                  : false
                              }
                              onClick={(e) =>
                                setDetailProvider({
                                  ...detailProvider,
                                  tutorintrestedonlinecls: t("objection"),
                                })
                              }
                            />
                            <span> {t("objection")}</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="rightsec">
                    <div className="form_group full">
                      <label>
                        {t(
                          "experience-working-kids"
                        )}
                        <span
                          className={
                            errorField.sepexpwithkid != "" ? "starred" : ""
                          }
                        >
                          *
                        </span>
                      </label>
                      <div className="checkbox">
                        <ul
                          onClick={(e) =>
                            setErrorField({
                              ...errorField,
                              sepexpwithkid: "",
                            })
                          }
                        >
                          <li>
                            <input
                              type="radio"
                              name="quality3"
                              checked={
                                detailProvider.sepexpwithkid == t("confirm")
                                  ? true
                                  : false
                              }
                              onClick={(e) =>
                                setDetailProvider({
                                  ...detailProvider,
                                  sepexpwithkid: t("confirm"),
                                })
                              }
                            />
                            <span> {t("confirm")}</span>
                          </li>
                          <li>
                            <input
                              type="radio"
                              name="quality3"
                              checked={
                                detailProvider.sepexpwithkid == t("objection")
                                  ? true
                                  : false
                              }
                              onClick={(e) =>
                                setDetailProvider({
                                  ...detailProvider,
                                  sepexpwithkid: t("objection"),
                                })
                              }
                            />
                            <span> {t("objection")}</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <span>{t("required-fields")}</span>
              </div>
            </div>
          </div>
          <div class="button text-center">
            <div class="pull-right">
              <button class="btn" onClick={(e) => handleCloseTutorModal()}>
                {t("text-back")}
              </button>
            </div>
            <div class="pull-right">
              <button
                class="btn confirm"
                onClick={(e) => {
                  if (
                    tutorExperience > 0 &&
                    (qualifications.English ||
                      qualifications.Serbian ||
                      qualifications.Mathematics ||
                      qualifications.Physics ||
                      qualifications.Chemistry ||
                      qualifications.Other) &&
                    tutorRate.max > 5 &&
                    detailProvider.tutorintrestedonlinecls != "" &&
                    detailProvider.sepexpwithkid != "" &&
                    countp <= 2
                  ) {
                    handleCloseTutorModal();
                    handleSubmitProvider(true);
                  } else {
                    STEP_TUTOR.map((e) => {
                      validateAfterLoginField(e.name);
                    });
                  }
                }}
              >
                {t("text-finish")}
              </button>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
