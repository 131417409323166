import React from "react";

import { BrowserRouter, Route, Routes } from "react-router-dom";
import { LandingParentsPage } from "../pages/LandingParentsPage";
import { LandingProvidersPage } from "../pages/LandingProvidersPage";
import { LandingStepPage } from "../pages/LandingStepPage";
import { LinkedInCallback } from "react-linkedin-login-oauth2";
import { AboutPage } from "../pages/AboutPage";
import { CareerPage } from "../pages/CareerPage";
import { ContactPage } from "../pages/ContactPage";
import { ErrorPage } from "../pages/ErrorPage";
import { HomePage } from "../pages/HomePage";
import { ThanksPage } from "../pages/ThanksPage";
import { WelcomePage } from "../pages/WelcomePage";
import { WorkUsPage } from "../pages/WorkUsPage";
import { ThankProvidersPage } from "../pages/ThankProvidersPage";
import { ThankParentsPage } from "../pages/ThankParentsPage";
import { SearchProvidersPage } from "../pages/SearchProvidersPage";
import { ProviderProfilePage } from "../pages/ProviderProfilePage";
import { ProviderPaymentPage } from "../pages/ProviderPaymentPage.js";
import { ProviderMembershipPage } from "../pages/ProviderMembershipPage";
import { ProfilePage } from "../pages/ProfilePage";
import { ParentsMembershipPage } from "../pages/ParentsMembershipPage";
import { CreateProviderPage } from "../pages/CreateProviderPage";
import { CookiePolicyPage } from "../pages/CookiePolicyPage";
import { FaqPage } from "../pages/FaqPage";
import { LoginPage } from "../pages/LoginPage";
import { ParentsSignupPage } from "../pages/ParentsSignupPage";
import { PrivacyPolicyPage } from "../pages/PrivacyPolicyPage";
import { ProviderSignupPage } from "../pages/ProviderSignupPage";
import { ResetPasswordPage } from "../pages/ResetPasswordPage";
import { ResourcesPage } from "../pages/ResourcesPage";
import { SafetyCenterPage } from "../pages/SafetyCenterPage";
import { SignupPage } from "../pages/SignupPage";
import { TermsPage } from "../pages/TermsPage";
import { InvestorRelationsPage } from "../pages/IntestorRelationsPage";
import { CreateParentProfile } from "../pages/CreateParentProfile";
import { SearchParentsPage } from "../pages/SearchParentsPage/SearchParentsPage";
// import { EmailThankPage } from '../pages/EmailThankPage'
// import { EmailResetPage } from '../pages/EmailResetPage'
// import { AuthGuard } from './authGard'

function Routing() {
  const [storagecheck, setstoragecheck] = React.useState(
    localStorage.getItem("token")
  );

  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<HomePage />} />
          <Route exact path="/landing" element={<LandingStepPage />} />
          <Route
            exact
            path="/thanks_parents"
            element={<LandingParentsPage />}
          />
          <Route
            exact
            path="/thanks_Providers"
            element={<LandingProvidersPage />}
          />
          <Route exact path="/signup" element={<SignupPage />} />
          <Route exact path="/signup/:refral" element={<SignupPage />} />
          <Route exact path="/login" element={<LoginPage />} />
          <Route exact path="/linkedin" element={<LinkedInCallback />} />

          <Route
            exact
            path="/signup_provider"
            element={<ProviderSignupPage />}
          />
          <Route exact path="/signup_Parents" element={<ParentsSignupPage />} />
          <Route exact path="/parent_thankyou" element={<ThankParentsPage />} />
          <Route exact path="/thankyou" element={<ThanksPage />} />
          <Route
            exact
            path="/providers_thankyou"
            element={<ThankProvidersPage />}
          />
          <Route exact path="/welcome" element={<WelcomePage />} />

          {/* <Route exact path='/thank_you' element={<EmailThankPage />} />
                    <Route exact path='/reset_password_mail' element={<EmailResetPage />} /> */}

          <Route
            exact
            path="/reset-password/:token/:id"
            element={<ResetPasswordPage />}
          />
          <Route exact path="/privacy-policy" element={<PrivacyPolicyPage />} />
          <Route exact path="/terms-of-use" element={<TermsPage />} />
          <Route exact path="/about-sensCare" element={<AboutPage />} />
          <Route exact path="/contact-us" element={<ContactPage />} />
          <Route exact path="/career" element={<CareerPage />} />
          <Route
            exact
            path="/investor-relations"
            element={<InvestorRelationsPage />}
          />
          <Route exact path="/work-with-us" element={<WorkUsPage />} />
          <Route exact path="/safety-center" element={<SafetyCenterPage />} />
          <Route exact path="/cookie-policy" element={<CookiePolicyPage />} />
          <Route exact path="/resources" element={<ResourcesPage />} />
          <Route exact path="/faq" element={<FaqPage />} />
          <Route
            exact
            path="/complete-provider-profile"
            element={<CreateProviderPage />}
          />
          <Route
            exact
            path="/complete-parents-profile"
            element={<CreateParentProfile />}
          />
          <Route exact path="/search-parents" element={<SearchParentsPage />} />
          <Route
            exact
            path="/search-providers"
            element={<SearchProvidersPage />}
          />
          {/* if(storagecheck != null){ */}
          {storagecheck != null ? (
            <>
              <Route
                exact
                path="/search-parents/:id"
                element={<SearchParentsPage />}
              />
              <Route
                exact
                path="/search-providers/:id"
                element={<SearchProvidersPage />}
              />
              <Route
                exact
                path="/search-parents/:id/:name"
                element={<SearchParentsPage />}
              />
              <Route
                exact
                path="/search-providers/:id/:name"
                element={<SearchProvidersPage />}
              />
            </>
          ) : (
            <>
              <Route exact path="/search-parents/:id" element={<LoginPage />} />
              <Route
                exact
                path="/search-providers/:id"
                element={<LoginPage />}
              />
              <Route
                exact
                path="/search-parents/:id/:name"
                element={<LoginPage />}
              />
              <Route
                exact
                path="/search-providers/:id/:name"
                element={<LoginPage />}
              />
            </>
          )}
          {/* } */}

          <Route
            exact
            path="/parents-membership"
            element={<ParentsMembershipPage />}
          />
          <Route
            exact
            path="/provider-membership"
            element={<ProviderMembershipPage />}
          />
          <Route
            exact
            path="/provider-payment"
            element={<ProviderPaymentPage />}
          />
          <Route exact path="/profile-parents/:id" element={<ProfilePage />} />
          <Route
            exact
            path="/profile-provider/:id"
            element={<ProviderProfilePage />}
          />

          <Route path="*" element={<ErrorPage />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default Routing;
