import React from "react";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";
import { api } from "../../urls";
import styles from "./AboutPage.module.css";

export function TeamModal({ member, showEmail, handleCloseEmail, index }) {
  return (
    <Modal
      show={showEmail === index + 1}
      onHide={handleCloseEmail}
      className={styles.about_model}
    >
      <Modal.Body className={styles.modal_dialog}>
        <div
          className={`${styles.promocode_content} ${styles.reset_email} ${styles.reset}`}
        >
          <Link to="" onClick={handleCloseEmail}>
            +
          </Link>
          <div className={styles.about_team}>
            <img src={api + "/assets/cms/" + member.image} alt={member.name} />
            <div className={styles.named}>
              <h3>{member.name}</h3>
              <p>{member.education}</p>
              <h4>{member.position}</h4>
            </div>
            <p>{member.description.replace(/<\/?[^>]+(>|$)/g, "")}</p>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
