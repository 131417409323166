import { create } from "zustand";

export const useControlsStore = create((set) => ({
  showModal: false,
  isEditCard: false,
  refresh: "",
  children: JSON.parse(localStorage.getItem("controls"))?.children || "0",
  calendarType: 0,
  step: JSON.parse(localStorage.getItem("step")) || 1,
  process: JSON.parse(localStorage.getItem("process")) || 10,
  mobile: 0,
  membershipData: "",

  setMembershipData: (value) => set({ membershipData: value }),
  setIsEditCard: (value) => set({ isEditCard: value }),
  setShowModal: (value) => set({ showModal: value }),
  setRefresh: (value) => set({ refresh: value }),
  setChildren: (newChildren) => {
    const storedData = JSON.parse(localStorage.getItem("controls")) || {};
    storedData.children = newChildren;
    localStorage.setItem("controls", JSON.stringify(storedData));
    set({ children: newChildren });
  },
  setCalendarType: (value) => set({ calendarType: value }),
  setStep: (value) => {
    const storedData = JSON.parse(localStorage.getItem("step")) || 1;
    localStorage.setItem("step", JSON.stringify(storedData));
    set({ step: Number(value) });
  },
  setProcess: (value) => {
    const storedData = JSON.parse(localStorage.getItem("process")) || 10;
    localStorage.setItem("process", JSON.stringify(storedData));
    set({ process: Number(value) });
  },
  setMobile: (value) => set({ mobile: value }),
}));
