import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { PlusIcon } from "../../../assets/image/PlusIcon";

export const ReviewItem = ({
  data,
  recived,
  props,
  setUserId,
  setShowReviewModal,
  setUsername,
  isCheckData,
}) => {
  const { t } = useTranslation();

  return (
    <li>
      <div className="recommend">
        <div className="image">
          <img
            src={
              data.Image != null
                ? "https://admin.stage.mysenscare.com/assets/images/users/" +
                  data.Image
                : "img/nany_img.png"
            }
            alt=""
          />
        </div>
        <div className="detail">
          <h3>
            <Link
              to={
                recived === "Received Reviews"
                  ? data?.user_type !== "parents"
                    ? "/profile-provider/" + data?.sender_userid
                    : "/profile-parents/" + data?.sender_userid
                  : data?.user_type !== "parents"
                  ? "/profile-provider/" + data?.receiver_userid
                  : "/profile-parents/" + data?.receiver_userid
              }
            >
              {data.Sendername}
            </Link>
          </h3>
          <span
            className="star"
            onClick={(e) => {
              props.profile_data(data.id);
              props.setall(data.id);
            }}
          >
            {[...Array(5)].map((star, index) => {
              index += 1;
              return (
                <i
                  type="button"
                  key={index}
                  className={
                    index <= data.rating
                      ? "on fa-solid fa-star"
                      : "off fa-regular fa-star"
                  }
                ></i>
              );
            })}
          </span>
          <p
            onClick={(e) => {
              props.profile_data(data.id);
              props.setall(data.id);
            }}
            style={{ padding: "0" }}
          >
            {new Date(data.updated_at).getDate()}/
            {new Date(data.updated_at).getMonth() + 1}/
            {new Date(data.updated_at).getFullYear()}
          </p>
          <h3
            onClick={(e) => {
              props.profile_data(data.id);
              props.setall(data.id);
            }}
            style={{ fontStyle: "italic" }}
          >
            {data.review_title}
          </h3>
          <p
            onClick={(e) => {
              props.profile_data(data.id);
              props.setall(data.id);
            }}
            style={{ fontStyle: "italic" }}
          >
            {data.message.substr(0, 85)}
          </p>

          {recived === t("received-reviews") ? (
            data.reviewerData === 0 && isCheckData === "" ? (
              <button
                onClick={(e) => {
                  setUserId(data.sender_userid);
                  setShowReviewModal(true);
                  setUsername(data.Sendername);
                }}
              >
                {t("leave-review")}
                <PlusIcon color={"#7D2B8B"} />
              </button>
            ) : (
              <button style={{ color: "#B7B7B7" }}>
                {t("leave-review")}
                <PlusIcon />
              </button>
            )
          ) : (
            ""
          )}
        </div>
      </div>
    </li>
  );
};
