import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { TestmonialModal } from "../TestmonialModal";
import { fetchSurveyData } from "../../../api/surveyApi";

export function Testimonials() {
  const { t } = useTranslation();
  const sliderRef = useRef();
  const [testimonialData, setTestimonialData] = useState([]);

  const generalSettings = {
    dots: false,
    infinite: true,
    slidesToShow: 3,
    arrows: true,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 2000,
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          initialSlide: 3,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  };

  const handleSurveyData = async () => {
    try {
      const result = await fetchSurveyData();
      setTestimonialData(result.data);

    } catch (error) {
      console.error("Error fetching survey data:", error);
    }
  };

  useEffect(() => {
    handleSurveyData();
  }, []);

  const testmonialComponent = (e, index) => {
    return (
      <div className="item" key={index}>
        <div className="profile_image">
          <img
            src={
              "https://admin.stage.mysenscare.com/assets/images/users/" +
              e.image
            }
            alt="profile"
          />
        </div>
        <div className="review">
          <span className="star">
            <div className="star-rating">
              {[...Array(5)].map((star, index) => {
                index += 1;
                return (
                  <i
                    type="button"
                    key={index}
                    className={
                      index <= e.overallValue
                        ? "on fa-solid fa-star"
                        : "off fa-regular fa-star"
                    }
                  ></i>
                );
              })}
            </div>
          </span>
          <TestmonialModal name={e.username} />
          <p>{e.userReview} </p>
        </div>
      </div>
    );
  };

  const handleTestimonialData = () => {
    return testimonialData && testimonialData.map(testmonialComponent);
  };

  return (
    <>
      <div className="container-fluid bggray">
        <div className="container">
          <div className="testimonial_slid">
            <h2>{t("text-testimonials")} </h2>
            <p>{t("clients-use-platform")}</p>
            <Slider
              ref={sliderRef}
              {...generalSettings}
              id="Slider-4"
              className="slider_test"
            >
              {handleTestimonialData()}
            </Slider>
          </div>
        </div>
      </div>
    </>
  );
}
