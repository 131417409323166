export const ListIcon = () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0.7 0H13.3C13.6111 0 14 0.311111 14 0.7V13.3C14 13.6111 13.6111 14 13.3 14H0.7C0.311111 14 0 13.6111 0 13.3V0.7C0 0.311111 0.311111 0 0.7 0ZM3.11127 3.10989H4.66682V4.66545H3.11127V3.10989ZM6.22229 3.10989H10.889V4.66545H6.22229V3.10989ZM6.22229 6.21979H10.889V7.77534H6.22229V6.21979ZM10.889 9.33514H6.22229V10.8907H10.889V9.33514ZM4.66682 6.21979H3.11127V7.77534H4.66682V6.21979ZM3.11127 9.33514H4.66682V10.8907H3.11127V9.33514Z"
        fill="#636363"
      />
    </svg>
  );
};
