import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { PageIcon } from "../../../assets/image/PageIcon";
import { BoldArrowDown } from "../../../assets/image/BoldArrowDown";
import { AdditionalMenuIcon } from "../../../assets/image/AdditionalMenuIcon";
import { RaffleIcon } from "../../../assets/image/RaffleIcon";
import { AddGeneralIcon } from "../../../assets/image/AddGeneralIcon";

import { updateProfile } from "../../../api/profileApi";
import { getProviderList } from "../../../api/providerApi";
import {
  deleteDocument,
  getListDocument,
  sendDocument,
} from "../../../api/documentApi";

import { useInterviewStore } from "../../../store/useInterviewStore";
import { useQualificationStore } from "../../../store/useQualificationStore";
import { DOCUMENT_TYPES, REFINE_OPTIONS } from "../../../utils/variables";
import { DeleteModal } from "./Modals/DeleteModal";
import { ProfileModal } from "./Modals/ProfileModal";
import { DocumentModal } from "./Modals/DocumentModal";

export function Document() {
  const { t } = useTranslation();

  const [providerData, setProviderData] = useState([]);
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [showModalProfile, setShowModalProfile] = useState(false);
  const [isCheck, setIsCheck] = useState(true);
  const [message, setMessage] = useState(0);
  const [showModalDocument, setShowModalDocument] = useState(false);
  const [submit, setSubmit] = useState("");
  const [isLoader, setIsLoader] = useState(false);
  const [error, setError] = useState("");
  const [isPostSuccessfully, setIsPostSuccessfully] = useState(false);
  const [isStatus, setIsStatus] = useState("");
  const [isStatusDocument, setIsStatusDocument] = useState(false);
  const [deleteValue, setDeleteValue] = useState("");
  const [shareList, setShareList] = useState([]);
  const [documentData, setDocumentData] = useState([]);
  const [shareSelect, setShareSelect] = useState("");

  const [shareDocument, setShareDocument] = useState({});
  const [isActive, setIsActive] = useState(false);

  const { refine, setRefine, recived, setRecived, resetInterviewState } =
    useInterviewStore();

  const {
    detailProvider,
    setDetailProvider,
    certificates,
    resetQualificationState,
  } = useQualificationStore();

  useEffect(() => {
    return () => {
      resetQualificationState();
      resetInterviewState();
    };
  }, []);

  const handleUpdateProfile = async () => {
    setSubmit("h");
    setIsLoader(true);

    try {
      const result = await updateProfile(detailProvider, certificates);
      console.log(result);

      setSubmit("");
      setIsStatus("u");

      setTimeout(() => {
        setIsLoader(false);
        setIsStatus("");
      }, 3000);

      setIsCheck(false);

      fetchProviderList();
      setShowModalDocument(false);
      setDetailProvider({
        recommendationsfile: "",
        qualificationscertificatesname: "",
        cv: "",
        qualificationscertificatesdoc: "",
        backgrounddoc: "",
      });
    } catch (error) {
      setShowModalDocument(false);
      setIsLoader(false);
      setIsStatusDocument(true);
      setSubmit("");

      setTimeout(() => {
        setIsStatusDocument(false);
      }, 3000);
    }
  };

  const fetchProviderList = async () => {
    try {
      const result = await getProviderList(refine);
      setProviderData(result.data);

      const fieldsToCheck = [
        "recommendationsfile",
        "cv",
        "qualificationscertificatesdoc",
        "backgrounddoc",
      ];

      const countMessage = fieldsToCheck.reduce((count, field) => {
        return (
          count +
          (result.data[field] && result.data[`${field}_deleted_at`] === 0
            ? 1
            : 0)
        );
      }, 0);

      setMessage(countMessage);
    } catch (error) {
      console.error("Error fetching provider list:", error);
    }
  };

  useEffect(() => {
    if (isCheck) {
      fetchProviderList();
      setIsCheck(false);
      fetchListDocument();
    }
    console.log(providerData);
  }, [isCheck]);

  useEffect(() => {
    if (window.innerWidth < 768) {
      setRecived("text-refine");
    }

    setShareList(documentData);
    setShareSelect("");
  }, [showModalProfile]);

  const handleDeleteDocument = async () => {
    try {
      const result = await deleteDocument(deleteValue);
      console.log(result);
      setShowModalDelete(false);
      fetchProviderList();
    } catch (error) {
      console.error("Error deleting document:", error);
    }
  };

  const fetchListDocument = async () => {
    try {
      const result = await getListDocument();
      setShareList(result.data);
      setDocumentData(result.data);
    } catch (error) {
      console.error("Error fetching document list:", error);
    }
  };

  const handleSubmitData = async () => {
    try {
      const result = await sendDocument(shareSelect, shareDocument);
      console.log(result);

      setIsPostSuccessfully(true);
      setShowModalProfile(false);
      setTimeout(() => {
        setIsPostSuccessfully(false);
      }, 2000);
    } catch (error) {
      console.error("Error sending document:", error);
      setError(error.message);
      setTimeout(() => {
        setError("");
      }, 2000);
    }
  };

  const handleSearchData = (data) => {
    const filterBySearch = documentData.filter((item) => {
      if (item != null) {
        if (
          (item.first_name + item.last_name)
            .toLowerCase()
            .includes(data.toLowerCase())
        ) {
          return item;
        }
      }
    });
    setShareList(filterBySearch);
  };

  const handleRefineDay = (e) => {
    setRefine({
      threedays: e === "3" ? true : "",
      withinweek: e === "7" ? true : "",
      twofourday: e === "24" ? true : "",
      withinmonth: e === "30" ? true : "",
    });
    setIsCheck(true);
  };

  return (
    <>
      {isPostSuccessfully && (
        <div className="popup_status" id="popup_status">
          {t("post-successfully")}
        </div>
      )}

      {isStatus && (
        <div className="popup_status" id="popup_status">
          {t("Congratulations! You successfully Upload a document!")}
        </div>
      )}

      {isStatusDocument && (
        <div className="popup_status" id="popup_status">
          Documents Too Large !
        </div>
      )}

      <div class="main-header share_doc">
        <h2 className="border">{t("title-my-documents")}</h2>
        <div class="mail-header-bar">
          <p>
            <span>{message}</span> {t("text-documents")}
          </p>
          <div class="btn-group flex">
            {t("text-refine")}
            <div className="select">
              <label onClick={(e) => setIsActive(!isActive)}>
                {t(recived)}
                <span>
                  <img src="/images/done_a.svg" />
                </span>
              </label>
              {isActive && (
                <ul>
                  {REFINE_OPTIONS.map((option) => (
                    <li
                      key={option.label}
                      onClick={() => {
                        setRecived(t(option.label));
                        handleRefineDay(option.value);
                        setIsActive(!isActive);
                      }}
                    >
                      {t(option.label)}
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </div>
        </div>
        <ul>
          {DOCUMENT_TYPES.map((doc) => {
            if (
              providerData[doc.key] &&
              providerData[`${doc.key}_deleted_at`] === 0
            ) {
              return (
                <li key={doc.key}>
                  <span className="docic">
                    <PageIcon />
                  </span>
                  <span>
                    {t(doc.label)}
                    {providerData[doc.originalKey] || ""}
                  </span>
                  <span className="date">{providerData[doc.updateKey]}</span>

                  <button>
                    <a
                      href={`https://admin.stage.mysenscare.com/assets/images/users/${
                        providerData[doc.key]
                      }`}
                      download
                      target="_blank"
                    >
                      <BoldArrowDown />
                    </a>
                  </button>
                  <button
                    onClick={(e) => {
                      setShareDocument({
                        [`${doc.key}_doc`]: providerData[doc.key],
                      });
                      setShowModalProfile(true);
                    }}
                  >
                    <AdditionalMenuIcon />
                  </button>
                  <button
                    onClick={(e) => {
                      setShowModalDelete(true);
                      setDeleteValue(t(doc.deleteLabel));
                    }}
                  >
                    <RaffleIcon />
                  </button>
                </li>
              );
            }
            return null;
          })}
        </ul>

        <button
          className="upload_doc"
          onClick={(e) => setShowModalDocument(true)}
        >
          {t("upload-documents")}
          <AddGeneralIcon />
        </button>
      </div>

      {showModalDocument && (
        <DocumentModal
          handleUpdateProfile={handleUpdateProfile}
          submit={submit}
          setShowModalDocument={setShowModalDocument}
          showModalDocument={showModalDocument}
          isLoader={isLoader}
        />
      )}

      {showModalDelete && (
        <DeleteModal
          showModalDelete={showModalDelete}
          setShowModalDelete={setShowModalDelete}
          handleDeleteDocument={handleDeleteDocument}
        />
      )}

      {showModalProfile && (
        <ProfileModal
          showModalProfile={showModalProfile}
          setShowModalProfile={setShowModalProfile}
          handleSearchData={handleSearchData}
          shareList={shareList}
          setShareSelect={setShareSelect}
          error={error}
          handleSubmitData={handleSubmitData}
        />
      )}
    </>
  );
}
