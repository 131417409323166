import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { Link, useNavigate } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import Parents_notification_tabs from "./parents_notification_tabs";
// import Provider_notification_tabs from "../../components/common/ProviderNotificationTabs/ProviderNotificationTabs";

import { api } from "../../urls";

import { ViewEdit } from "../../components/common/ViewEdit";
import { ViewEditProvider } from "../../components/common/ViewEditProvider";
import { Calendar } from "../../components/common/Calendar";
import { FavoriteProfile } from "../../components/common/FavoriteProfile";
import { SocialFacebook } from "../../components/common/SocialFacebook";
import { Footer } from "../../components/common/Footer";
import { Header } from "../../components/common/Header";
import { PhoneIcon } from "../../assets/image/PhoneIcon";
import { ServiceIcon } from "../../assets/image/ServiceIcon";
import { StarIcon } from "../../assets/image/StarIcon";
import { WhiteStarIcon } from "../../assets/image/WhiteStarIcon";
import { LinkedinIcon } from "../../assets/image/LinkedinIcon";
import { BanCarIcon } from "../../assets/image/BanCarIcon";
import { CarIcon } from "../../assets/image/CarIcon";
import { BanHouseIcon } from "../../assets/image/BanHouseIcon";
import { HouseIcon } from "../../assets/image/HouseIcon";
import { BanPlaneIcon } from "../../assets/image/BanPlaneIcon";
import { PlaneIcon } from "../../assets/image/PlaneIcon";
import { BanCookIcon } from "../../assets/image/BanCookIcon";
import { CookIcon } from "../../assets/image/CookIcon";
import { BanSmokeIcon } from "../../assets/image/BanSmokeIcon";
import { SmokeIcon } from "../../assets/image/SmokeIcon";
import { PawsIcon } from "../../assets/image/PawsIcon";
import { DoneIcon } from "../../assets/image/DoneIcon";
import { ThingIcon } from "../../assets/image/ThingIcon";
import { FacebookIcon } from "../../assets/image/FacebookIcon";
import { ConfirmIcon } from "../../assets/image/ConfirmIcon";
import { HomeIcon } from "../../assets/image/HomeIcon";
import { RoundHandIcon } from "../../assets/image/RoundHandIcon";
import { ArrowLeftIcon } from "../../assets/image/ArrowLeftIcon";
import { ProviderSearchFilter } from "./ProviderSearchFilter";
import { PhotoUploadModal } from "../ProfilePage/Modals/PhotoUploadModal";
import { ProviderLeftSection } from "./ProviderLeftSection";
import { useQualificationStore } from "../../store/useQualificationStore";
import { useSearchDetailsStore } from "../../store/useSearchDetailsStore";
import { useProfileDetailsStore } from "../../store/useProfileDetailsStore";
import { useRatesStore } from "../../store/useRatesStore";
import { SafetyModal } from "./Modals/SafetyModal";
import { MembershipModal } from "./Modals/MembershipModal";
import { SignoutModal } from "./Modals/SignoutModal";
import { InviteModal } from "./Modals/InviteModal";
import { DocumentsModal } from "./Modals/DocumentsModal";
import { LoginModal } from "./Modals/LoginModal";
import { ProviderModal } from "./Modals/ProviderModal";
import { ReviewModal } from "./Modals/ReviewModal";
import { ProviderMobileContent } from "./ProviderMobileContent";
import { ProviderProfileLanguage } from "./Modals/ProviderProfileLanguage";
import { DEFAULT_API } from "../../utils/variables";

export function ProviderProfilePage() {
  const { t, i18n } = useTranslation();
  const language = i18n.language;
  const navigate = useNavigate();

  const { search, setSearch } = useSearchDetailsStore();
  const { certificates, setCertificates } = useQualificationStore();

  const { qualifications, setQualifications, habit, setHabit } =
    useProfileDetailsStore();

  const {
    nanyRate,
    generalRate,
    secondaryRate,
    tutorRate,
    setNanyRate,
    setGeneralRate,
    setSecondaryRate,
    setTutorRate,
  } = useRatesStore();

  const [isLoginModal, setIsLoginModal] = useState(false);
  const [showProviderModal, setIsProviderModal] = useState(false);
  const id = localStorage.getItem('id');
  const [advanceSearch, setAdvanceSearch] = useState(id === null);
  const [photoUpload, setPhotoUpload] = useState(false);
  const [image, setImage] = useState(null);
  const [photo, setPhoto] = useState("");
  const [isInviteModal, setIsInviteModal] = useState(false);
  const [isDocumentsModal, setIsDocumentsModal] = useState(false);
  const [requestNote, setRequestNote] = useState("");
  const [isReviewModal, setIsReviewModal] = useState(false);
  const [profile, setProfile] = useState({});
  const [selfProfile, setSelfProfile] = useState({});
  const [count, setcount] = useState(true);
  const [filterCheck, setFilterCheck] = useState(true);
  const [children, setChildren] = useState("");
  const [childrenAge, setChildrenAge] = useState([]);
  const [category, setCategory] = useState([]);
  const [document, setdocument] = useState([]);
  const [latLong, setLatLong] = useState({
    lat: "",
    lng: "",
  });

  const [recommend, setRecommend] = useState("");
  const [jobsListProfession, setJobsListProfession] = useState([]);
  const [isMembershipModal, setIsMembershipModal] = useState(false);
  const [inviteStatus, setInviteStatus] = useState("0");
  const [jobId, setJobID] = useState("");
  const [isOpen, setIsOpen] = useState(true);
  const [jobCategory, setJobCategory] = useState({
    data1: "",
    data2: "",
    data3: "",
    data4: "",
  });

  const [subTab, setSubTab] = useState({
    About: localStorage.getItem("edittime") ? "active" : "",
    kids: localStorage.getItem("edittime") ? "active" : "",
    job: localStorage.getItem("edittime") ? "active" : "",
    availability: localStorage.getItem("edittime") ? "active" : "",
    info: localStorage.getItem("edittime") ? "active" : "",
    security: localStorage.getItem("edittime") ? "active" : "",
    personal: localStorage.getItem("edittime") ? "active" : "",
    Reviews: localStorage.getItem("edittime") ? "active" : "",
    Job: localStorage.getItem("edittime") ? "active" : "",
  });
  const [report, setReport] = useState({
    resion: "",
    other: "",
    error: "",
  });
  const [isSafetyModal, setIsSafetyModal] = useState(false);
  const [generalStatus, setIsGeneralStatus] = useState(false);
  const [isComment, setIsComment] = useState("");

  const [status, setstatus] = useState(false);

  const [calendarType, setCalendarType] = useState(1);

  const [profileSection, setProfileSection] = useState(
    localStorage.getItem("side") && localStorage.getItem("side") != ""
      ? localStorage.getItem("side")
      : ""
  );
  const [subtab, setsubtab] = useState(
    localStorage.getItem("search") && localStorage.getItem("search") != ""
      ? localStorage.getItem("search")
      : ""
  );

  const [isSignoutModal, setIsSignoutModal] = useState(false);

  const handleSelectElement = (data) => {
    let sum = false;
    category.map((e, index) => {
      if (e.name == data) {
        sum = true;
        category.splice(index, 1);
      }
    });
    if (sum == false) {
      category.push({ name: data });
    }
    setTimeout(() => {
      setCategory([...category]);
    }, 500);
  };

  const handleChildrenAge = (data) => {
    let sum = false;
    childrenAge.map((e, index) => {
      if (e.name == data) {
        sum = true;
        childrenAge.splice(index, 1);
      }
    });
    if (sum == false) {
      childrenAge.push({ name: data });
    }
    setTimeout(() => {
      setChildrenAge([...childrenAge]);
    }, 500);
  };

  const handleShowContent = (e) => {
    if (isOpen) {
      let x = window.document.getElementById("filtern");
      x.style.display = "block";
      setIsOpen(false);
    } else {
      window.document.getElementById("filtern").style.display = "none";
      setIsOpen(true);
    }
  };

  const handleElementById = (e, x) => {
    if (isOpen) {
      document.getElementById(e).style.display = "block";
      document.getElementById(x).style.display = "block";
      setIsOpen(false);
    } else {
      document.getElementById(e).style.display = "none";
      document.getElementById(x).style.display = "none";
      setIsOpen(true);
    }
  };

  const handleShowRecommend = (e, x) => {
    if (isOpen) {
      setRecommend("open");
      setIsOpen(false);
    } else {
      setRecommend("");
      setIsOpen(true);
    }
  };

  const slugData = useParams();

  const handleProfileDetails = () => {
    const myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(DEFAULT_API + "/api/v1/details", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setAdvanceSearch(true);
        setSelfProfile(result.data);
        handVisitorData();
      })
      .catch((error) => console.log("error", error));

    const myGeneralHeaders = new Headers();
    myGeneralHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );
    myGeneralHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      provider_id: slugData.id,
    });

    const requestGeneralOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(api + "/api/v1/getinterviewstatus", requestGeneralOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.data) {
          setInviteStatus(result.data.status);
        }
      })
      .catch((error) => console.log("error", error));
  };

  const handleJobsList = () => {
    const myHeaders = new Headers();
    myHeaders.append(
        "Authorization",
        "Bearer   " + localStorage.getItem("token")
    );

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      api + "/api/v1/currentactivejobs?providerid=" + slugData.id,
      requestOptions
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((result) => {
        setJobsListProfession(result.data);
      })
      .catch((error) => console.log("error", error));
  };

  const handleProfileData = () => {
    const myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Bearer  " + localStorage.getItem("token")
    );
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      id: slugData.id,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      api +
        "/api/" +
        (localStorage.getItem("token") != null
          ? "v1/favotheruserprofileview"
          : "otheruserprofileview"),
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        setProfile(result.data);
        if (result.data.qualificationscertificatesname[0]) {
          const iterator = result.data.qualificationscertificatesname.values();
          let y = {};

          for (let elements of iterator) {
            if (elements == "First Aid") {
              y = { ...y, qualificationscertificatesname: elements };
            } else if (elements == "Montessori") {
              y = { ...y, qualificationscertificatesname2: elements };
            } else if (elements == "Board Certified Nurse") {
              y = { ...y, qualificationscertificatesname3: elements };
            } else if (
              elements != "First Aid" &&
              elements != "Montessori" &&
              elements != "Board Certified Nurse"
            ) {
              setCertificates({
                ...certificates,
                ...y,
                qualificationscertificatesname4:
                  elements != null ? elements : "",
              });
            }
          }
        }
        setTimeout(() => {
          if (result.data.calanderlastupdate != null) {
            setCalendarType(parseInt(result.data.calanderlastupdate));
          }
        }, 2000);
        setNanyRate({
          min:
            result.data.nanyperhrrate != null
              ? result.data.nanyperhrrate
                  .split("-")[0]
              : 0,
          max:
            result.data.nanyperhrrate != null
              ? result.data.nanyperhrrate
                  .split("-")[1]
              : 0,
        });
        setTutorRate({
          min:
            result.data.tutorperhrrate != null
              ? result.data.tutorperhrrate
                  .substr(result.data.tutorperhrrate.lastIndexOf("\\") + 1)
                  .split("-")[0]
              : 0,
          max:
            result.data.tutorperhrrate != null
              ? result.data.tutorperhrrate
                  .substr(result.data.tutorperhrrate.lastIndexOf("\\") + 1)
                  .split("-")[1]
              : 0,
        });
        setGeneralRate({
          min:
            result.data.seterhrrate != null
              ? result.data.seterhrrate
                  .substr(result.data.seterhrrate.lastIndexOf("\\") + 1)
                  .split("-")[0]
              : 0,
          max:
            result.data.seterhrrate != null
              ? result.data.seterhrrate
                  .substr(result.data.seterhrrate.lastIndexOf("\\") + 1)
                  .split("-")[1]
              : 0,
        });
        setSecondaryRate({
          min:
            result.data.seperhrrate != null
              ? result.data.seperhrrate
                  .substr(result.data.seperhrrate.lastIndexOf("\\") + 1)
                  .split("-")[0]
              : 0,
          max:
            result.data.seperhrrate != null
              ? result.data.seperhrrate
                  .substr(result.data.seperhrrate.lastIndexOf("\\") + 1)
                  .split("-")[1]
              : 0,
        });
        setQualifications(
          result.data.tutorliketoteach != null
            ? result.data.tutorliketoteach
            : {}
        );
        setHabit({
          smoke: result.data.smoke ? result.data.smoke : "",
          licence: result.data.carorlicence ? result.data.carorlicence : "",
          kids: result.data.cooking ? result.data.cooking : "",
          housework: result.data.lighthousework
            ? result.data.lighthousework
            : "",
          family: result.data.traveling ? result.data.traveling : "",
        });
      })
      .catch((error) => console.log("error", error));
  };
  const handleProviderList = () => {
    const myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      api +
        "/api/jobslisting?keyword=" +
        search.keyword +
        "&distance=" +
        search.distance +
        "&lat=" +
        latLong.lat +
        "&lng=" +
        latLong.lng +
        "&city=" +
        search.city +
        "&zip=" +
        search.zip +
        "&tutorintrestedin[]=" +
        search.tutorintrestedin +
        "&tutorintrestedin[]=" +
        search.tutorintrestedin1 +
        "&tutorintrestedin[]=" +
        search.tutorintrestedin2 +
        "&tutorworkwithnochild=" +
        children +
        "&rates=" +
        (nanyRate.min + "-" + nanyRate.max) +
        "&workingabroad=" +
        search.workingabroad +
        "&tutorintrestedinschool=" +
        search.tutorintrestedinschool +
        (jobCategory.data1 != ""
          ? "&jobcategory[]=" + jobCategory.data1
          : "&jobcategory[]=") +
        (jobCategory.data2 != ""
          ? "&jobcategory[]=" + jobCategory.data2
          : "&jobcategory[]=") +
        (jobCategory.data3 != ""
          ? "&jobcategory[]=" + jobCategory.data3
          : "&jobcategory[]=") +
        (jobCategory.data4 != ""
          ? "&jobcategory[]=" + jobCategory.data4
          : "&jobcategory[]=") +
        "&tutorprefchildage=" +
        search.tutorprefchildage +
        "&carorlicence=" +
        search.carorlicence +
        "&withinweek=" +
        search.withinweek +
        "&withinmonth=" +
        search.withinmonth +
        "&jobposted=" +
        search.jobposted,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {})
      .catch((error) => console.log("error", error));
  };
  const handleFilterElement = () => {
    if (filterCheck) {
      document.getElementById("filteron").style.display = "block";
      setFilterCheck(false);
    } else {
      document.getElementById("filteron").style.display = "none";
      setFilterCheck(true);
    }
  };

  const handleProfileUpdate = () => {
    const myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );

    const formdata = new FormData();
    formdata.append("user_id", localStorage.getItem("id"));
    formdata.append("file_path", photo);
    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch(api + "/api/v1/updateparents", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setPhotoUpload(false);
        window.location.reload();
      })
      .catch((error) => {
        console.log("error", error);
      });
  };
  const handleCalendarData = (name, e) => {
    console.log(name, e);
  };
  const sliderRef = useRef();

  const generalSettings = {
    dots: false,
    infinite: true,
    slidesToShow:
      profile.reviewData?.length > 2 ? 3 : profile.reviewData?.length,
    arrows: true,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 2000,
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          initialSlide: 3,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  };

  const handleReport = () => {
    if (report.resion != "") {
      setReport({ ...report, error: "" });
      const myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Bearer " + localStorage.getItem("token")
      );
      myHeaders.append("Content-Type", "application/json");

      const raw = JSON.stringify({
        report_userid: slugData.id,
        reason: report.resion,
        other_reason: report.other,
      });

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(api + "/api/v1/reportuser", requestOptions)
        .then((response) => response.json())
        .then((result) => {
          setIsMembershipModal(false);
          setIsSafetyModal(true);
        })
        .catch((error) => console.log("error", error));
    } else {
      setReport({ ...report, error: "error" });
    }
  };

  const handVisitorData = () => {
    const myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      userid: slugData.id,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(api + "/api/v1/visitorsadd", requestOptions)
      .then((response) => response.text())
      .then((result) => console.log(result))
      .catch((error) => console.log("error", error));

    const myHeadersRequest = new Headers();
    myHeadersRequest.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );
    myHeadersRequest.append("Content-Type", "application/json");

    const raw2 = JSON.stringify({
      visitor_userid: slugData.id,
    });

    const requestGeneralOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw2,
      redirect: "follow",
    };

    fetch(api + "/api/v1/providerprofilecheck", requestGeneralOptions)
      .then((response) => response.json())
      .then((result) => console.log(result))
      .catch((error) => console.log("error", error));
  };

  const handleDocumentSelect = (a) => {
    let sum = false;
    category.map((e) => {
      if (e == a) {
        sum = true;
        const index = category.indexOf(a);
        if (index > -1) {
          category.splice(index, 1);
        }
      }
    });
    if (sum == false) {
      category.push(a);
    }
  };

  const handleImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      setImage(URL.createObjectURL(event.target.files[0]));
      setPhoto(event.target.files[0]);
    }
  };

  useEffect(() => {
    if (count) {
      handleProviderList();
      handleProfileData();
      handleJobsList();
      handleProfileDetails();
      setcount(false);
    }
    localStorage.setItem("side", profileSection);
    localStorage.setItem("back", localStorage.getItem("back"));
  }, [profile, category, profileSection]);

  const handleRecommendations = () => {
    const myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Cookie", "XSRF-TOKEN=" + localStorage.getItem("token"));

    const raw = JSON.stringify({
      provider_id: profile.id,
      documment_name: category,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(api + "/api/v1/documentrequestforinterview", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (
          result?.message != "You cannot send document request to this user"
        ) {
          setIsDocumentsModal(false);
          setIsGeneralStatus(true);
          setTimeout(() => {
            setIsGeneralStatus(false);
          }, 4000);
        } else {
          setIsDocumentsModal(false);
          setIsGeneralStatus(true);
          setTimeout(() => {
            setIsGeneralStatus(false);
          }, 4000);
          setRequestNote("You Already Send This Request!");
        }
      })
      .catch((error) => console.log("error", error));
  };

  const handleInterviewInvite = () => {
    const myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      provider_id: profile.id,
      job_id: jobId,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(api + "/api/v1/sendinterviewrequest", requestOptions)
      .then((response) => response.text())
      .then((result) => {
        setIsInviteModal(false);
        setstatus(true);
        setIsComment("1");
        setTimeout(() => {
          setstatus(false);
        }, 4000);
      })
      .catch((error) => console.log("error", error));
  };

  return (
    <>
      <Header fil={handleFilterElement} />

      {status && (
        <div className="popup_status" id="popup_status">
          {t("post-successfully-interview")}
        </div>
      )}

      {generalStatus && (
        <div className="popup_status" id="popup_status">
          {requestNote != "" ? t(requestNote) : t("post-successfully-document")}
        </div>
      )}

      <div className="container-fluid">
        <div className="filter_header" id="">
          <div className="container">
            <div
              className="filter_box"
              onKeyPress={(e) => {
                if (
                  e.key == "Enter" &&
                  localStorage.getItem("token") &&
                  localStorage.getItem("id")
                ) {
                  handleProviderList();
                }
              }}
            >
              <ProviderSearchFilter
                rate={nanyRate}
                set={setNanyRate}
                search={search}
                setSearch={setSearch}
                profile={profile}
                setChildren={setChildren}
                handleChildrenAge={handleChildrenAge}
                recommend={recommend}
                handleShowRecommend={handleShowRecommend}
                handleShowContent={handleShowContent}
                handleSelectElement={handleSelectElement}
                handleElementById={handleElementById}
                handleProviderList={handleProviderList}
                childrenAge={childrenAge}
                isOpen={isOpen}
                advanceSearch={advanceSearch}
              />
              <div className="search_box">
                <div className="search_input">
                  <button
                    id="my-fil-search"
                    type="submit"
                    onClick={(e) => {
                      localStorage.setItem(
                        "filter",
                        JSON.stringify({
                          category: category,
                          distance: search.distance,
                          zip: search.zip,
                          city: search.city,
                          cat: jobCategory,
                        })
                      );
                      handleProviderList();
                      navigate("/search-providers");
                    }}
                  >
                    {t("text-search")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        {subtab == "Profile" && (
          <>
            {localStorage.getItem("user_type") == "parents" ? (
              <div className="mobile profile">
                <ViewEdit />
              </div>
            ) : (
              <div className="mobile profile">
                <ViewEditProvider />
              </div>
            )}
          </>
        )}

        <div className="container" style={{ overflow: "hidden" }}>
          <div
            className="body_section searchbody "
            style={
              subtab != ""
                ? {
                    minHeight: "auto",
                    marginBottom: "100px",
                    float: "left",
                    width: "100%",
                  }
                : {}
            }
          >
            <div className="left_side_section" id={!isOpen ? "leftdata" : ""}>
              {selfProfile && selfProfile.first_name  && (
                  <ProviderLeftSection
                      selfProfile={selfProfile}
                      setsubtab={setsubtab}
                      subtab={subtab}
                      profileSection={profileSection}
                      setProfileSection={setProfileSection}
                      setPhotoUpload={setPhotoUpload}
                      setSignout={setIsSignoutModal}
                      setIsReviewModal={setIsReviewModal}
                  />
              )}
            </div>
            
            {subtab == "" && profile?.username ? (
              <div
                className="Account  editview profileshow"
                style={{ width: "calc(100% - 22.5%)" }}
              >
                <h2>
                  {t("Profile Overview")}
                  <Link
                    to={
                      localStorage.getItem("back")
                        ? "/search-providers/" + localStorage.getItem("back")
                        : "/search-providers"
                    }
                  >
                    <ArrowLeftIcon />
                    {t("text-back")}
                  </Link>
                </h2>
                <div className="about_edit">
                  <div className="profile_full left_side_section ">
                    <div className="profile_box">
                      <div className="profile_box_social">
                        <div className="profile_box_social_sec1">
                          {profile.plateformsocialmedia == "Yes" ? (
                            <SocialFacebook
                              link={
                                window.location.origin +
                                "/profile-provider/" +
                                profile.id
                              }
                            />
                          ) : (
                            ""
                          )}
                          {profile.phoneVerifiedStatus == 1 ? (
                            <img
                              src={
                                window.location.origin +
                                "/images/nany_phone.svg"
                              }
                              alt=""
                            />
                          ) : (
                            <PhoneIcon />
                          )}
                          <img
                            src={
                              window.location.origin + "/images/nany_msg.svg"
                            }
                            alt=""
                          />
                          {profile.facebookverify != null ||
                          profile.linkdinverify != null ? (
                            <img
                              src={
                                window.location.origin + "/images/nany_cont.svg"
                              }
                              alt=""
                            />
                          ) : (
                            <ServiceIcon />
                          )}
                          {profile.docsStatus == "Yes" ? (
                            <img
                              src={window.location.origin + "/images/ok.svg"}
                              alt=""
                            />
                          ) : (
                            <ConfirmIcon />
                          )}
                        </div>
                        <div className="profile_box_social_sec2">
                          <div className="profile_box_social_sec2">
                            {profile.service_type &&
                            profile.service_type.tab1 == "Nanny" ? (
                              <img
                                src={
                                  window.location.origin +
                                  "/images/nany_pur.svg"
                                }
                                alt=""
                              />
                            ) : (
                              ""
                            )}
                            {profile.service_type &&
                            profile.service_type.tab2 ? (
                              <img
                                src={
                                  window.location.origin +
                                  "/images/special_education.svg"
                                }
                                alt=""
                              />
                            ) : (
                              ""
                            )}
                            {profile.service_type &&
                            profile.service_type.tab3 ? (
                              <img
                                src={
                                  window.location.origin +
                                  "/images/professional.svg"
                                }
                                alt=""
                              />
                            ) : (
                              ""
                            )}
                            {profile.service_type &&
                            profile.service_type.tab4 ? (
                              <img
                                src={
                                  window.location.origin +
                                  "/images/tutorform.svg"
                                }
                                alt=""
                              />
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </div>
                      {selfProfile.id == profile.id ? (
                        ""
                      ) : (
                        <RoundHandIcon
                          onClick={(e) => setIsMembershipModal(true)}
                        />
                      )}
                      <div className="profile_pic_sec">
                        <img
                          src={window.location.origin + "/img/left_pic.png"}
                          alt=""
                        />
                        <div className="profile_pic">
                          <img
                            src={
                              api + "/assets/images/users/" + profile.file_path
                            }
                            width="80"
                            height="80"
                            alt=""
                          />
                        </div>
                        <img
                          src={window.location.origin + "/img/right_pic.png"}
                          alt=""
                        />
                      </div>
                      <div className="profile_detail">
                        <p>
                          {profile.first_name + " " + profile.last_name} (
                          {profile.dob != undefined
                            ? new Date().getFullYear() -
                              parseInt(
                                profile.dob
                                  .substr(profile.dob.lastIndexOf("\\") + 1)
                                  .split("-")[0]
                              )
                            : ""}
                          )
                          <a className="heart_sec">
                            {localStorage.getItem("user_type") == "parents" &&
                            selfProfile.id != profile.id ? (
                              <FavoriteProfile
                                id={profile.id}
                                username={profile.username}
                                heart2={profile.favornotprofile}
                              />
                            ) : (
                              ""
                            )}
                          </a>
                        </p>
                        <div className="profilestart_video">
                          <a>
                            <img
                              src={window.location.origin + "/images/video.svg"}
                              alt=""
                            />
                          </a>
                          {profile.reviewAvg >= 0 ? (
                            (profile.invitationData &&
                              [profile.invitationData].filter(
                                (e) => e.ParentId == selfProfile.id
                              )[0]?.inviteStatus == 1 &&
                              profile.checkreviewstatus != true) ||
                            profile.checkapplyornot == true ? (
                              <>
                                {[...Array(profile.reviewAvg)].map(
                                  (star, index) => {
                                    index += 1;
                                    return (
                                      <StarIcon
                                        onClick={(e) => setIsReviewModal(true)}
                                      />
                                    );
                                  }
                                )}
                                {[...Array(5 - profile.reviewAvg)].map(
                                  (star, index) => {
                                    index += 1;
                                    return (
                                      <WhiteStarIcon
                                        onClick={(e) => setIsReviewModal(true)}
                                      />
                                    );
                                  }
                                )}
                              </>
                            ) : (
                              <>
                                {[...Array(profile.reviewAvg)].map(
                                  (star, index) => {
                                    index += 1;
                                    return (
                                      <StarIcon
                                        onClick={(e) => setIsReviewModal(true)}
                                      />
                                    );
                                  }
                                )}
                                {[...Array(5 - profile.reviewAvg)].map(
                                  (star, index) => {
                                    index += 1;
                                    return <WhiteStarIcon />;
                                  }
                                )}
                              </>
                            )
                          ) : (
                            ""
                          )}
                          <span>({profile.reviewcount})</span>
                        </div>
                      </div>
                      <div className="profile_functions">
                        <div className="members">
                          <h5>
                            {new Date().getFullYear() -
                              new Date(profile.created_at).getFullYear() >
                            0
                              ? new Date().getFullYear() -
                                new Date(profile.created_at).getFullYear() +
                                t(" YRS")
                              : 0 + t(" YRS")}
                          </h5>
                          <h6>{t("Member")}</h6>
                        </div>
                        <div className="vi"></div>

                        <div className="applications">
                          <h5>{profile.jobApplicationcount}</h5>
                          <h6>{t("text-applications")}</h6>
                        </div>
                        <div className="vi"></div>
                        <div className="hiring">
                          <h5>{profile.hiringcount}</h5>
                          <h6>{t("Hirings")}</h6>
                        </div>
                      </div>
                    </div>
                    <div className="quarybtn">
                      {!localStorage.getItem("token") ||
                      !localStorage.getItem("id") ? (
                        <Link to="/signup" className="message">
                          {t("Interview invite")}
                        </Link>
                      ) : (profile.invitationData &&
                          [profile.invitationData].filter(
                            (e) => e.ParentId == selfProfile.id
                          )[0]?.inviteStatus == 1) ||
                        profile.checkapplyornot == true ? (
                        <button
                          className="message"
                          onClick={(e) => {
                            navigate(
                              "/search-providers/message-inbox/" + profile.id
                            );
                            localStorage.setItem("message", slugData.id);
                          }}
                        >
                          {t("send-message")}
                        </button>
                      ) : (profile.invitationData &&
                          [profile.invitationData].filter(
                            (e) => e.ParentId == selfProfile.id
                          )[0]?.inviteStatus == 0) ||
                        isComment != "" ? (
                        <button
                          className={"message"}
                          style={{ opacity: "0.5" }}
                        >
                          {t("Interview Requested")}
                        </button>
                      ) : profile.invitationData &&
                        [profile.invitationData].filter(
                          (e) => e.ParentId == selfProfile.id
                        )[0]?.inviteStatus == 2 ? (
                        <button className="message">
                          {t("Interview Declined")}
                        </button>
                      ) : (
                        <button
                          className={"message"}
                          onClick={() => {
                            selfProfile.user_type === "parents" && !selfProfile.job[0]
                                ? setIsInviteModal(true)
                                : setIsProviderModal(true);
                          }}
                          disabled={
                            selfProfile.id == profile.id ||
                            selfProfile.user_type == "provider"
                              ? true
                              : false
                          }
                          style={
                            selfProfile.id == profile.id ||
                            selfProfile.user_type == "provider"
                              ? { opacity: "0.5" }
                              : {}
                          }
                        >
                          {t("Interview invite")}
                        </button>
                      )}
                      <button
                        style={
                          selfProfile.id == profile.id ||
                          selfProfile.user_type == "provider" ||
                          profile.checkdocreqstatus
                            ? { opacity: "0.5" }
                            : {}
                        }
                        className="ask"
                        onClick={(e) => {
                          selfProfile.user_type == "parents" &&
                          !selfProfile.job[0]
                            ? setIsProviderModal(true)
                            : setIsDocumentsModal(true);
                        }}
                        disabled={
                          selfProfile.id == profile.id ||
                          selfProfile.user_type == "provider" ||
                          profile.checkdocreqstatus
                            ? true
                            : false
                        }
                      >
                        {t("Request documents")}
                      </button>
                    </div>
                  </div>
                  <p>
                    <h4>{t("About me")} </h4>
                    <PawsIcon />

                    <span>{profile.about}</span>
                    <div className="quarybtn">
                      <div className="interview_box">
                        {!localStorage.getItem("token") ||
                        !localStorage.getItem("id") ? (
                          <Link to="/signup" className="message">
                            {t("Interview invite")}
                          </Link>
                        ) : (profile.invitationData &&
                            [profile.invitationData].filter(
                              (e) => e.ParentId == selfProfile.id
                            )[0]?.inviteStatus == 1) ||
                          profile.checkapplyornot == true ? (
                          <button
                            className="message"
                            onClick={(e) => {
                              navigate(
                                "/search-providers/message-inbox/" + profile.id
                              );
                              localStorage.setItem("message", slugData.id);
                            }}
                          >
                            {t("send-message")}
                          </button>
                        ) : (profile.invitationData &&
                            [profile.invitationData].filter(
                              (e) => e.ParentId == selfProfile.id
                            )[0]?.inviteStatus == 0) ||
                          isComment != "" ? (
                          <button
                            className="message"
                            style={{ opacity: "0.5" }}
                          >
                            {t("Interview Requested")}
                          </button>
                        ) : profile.invitationData &&
                          [profile.invitationData].filter(
                            (e) => e.ParentId == selfProfile.id
                          )[0]?.inviteStatus == 2 ? (
                          <button
                            className="message"
                            style={{ opacity: "0.5" }}
                          >
                            {t("Interview Declined")}
                          </button>
                        ) : (
                          <button
                            className={"message"}
                            onClick={(e) => {
                              selfProfile.user_type === "parents" && !selfProfile.job[0]
                                  ? setIsInviteModal(true)
                                  : setIsProviderModal(true);
                            }}
                            disabled={
                              selfProfile.id == profile.id ||
                              selfProfile.user_type == "provider"
                                ? true
                                : false
                            }
                            style={
                              selfProfile.id == profile.id ||
                              selfProfile.user_type == "provider"
                                ? { opacity: "0.5" }
                                : {}
                            }
                          >
                            {t("Interview invite")}
                          </button>
                        )}
                        <button
                          style={
                            selfProfile.id == profile.id ||
                            selfProfile.user_type == "provider" ||
                            profile.checkdocreqstatus
                              ? { opacity: "0.5" }
                              : {}
                          }
                          className="ask"
                          onClick={(e) => setIsDocumentsModal(true)}
                          disabled={
                            selfProfile.id == profile.id ||
                            selfProfile.user_type == "provider" ||
                            profile.checkdocreqstatus
                              ? true
                              : false
                          }
                        >
                          {t("Request documents")}
                        </button>
                      </div>
                    </div>
                  </p>
                </div>

                <div
                  className={
                    subTab.security == "active" ? "active personal" : "personal"
                  }
                >
                  <h3
                    onClick={(e) =>
                      setSubTab({
                        ...subTab,
                        security: subTab.security == "" ? "active" : "",
                      })
                    }
                  >
                    {t("security-verification")}
                  </h3>
                  {subTab.security == "active" && (
                    <div className="editkids security mg">
                      <label style={{ float: "left", width: "50%" }}>
                        {t("Verified accounts")}
                        <span>
                          <strong>
                            <div className="social_verify">
                              <FacebookIcon
                                className={
                                  profile.facebookverify == "true"
                                    ? "active"
                                    : ""
                                }
                                fiiColor={"#fff"}
                                color={"#B7B7B7"}
                                textColor={"#B7B7B7"}
                              />
                              <LinkedinIcon
                                className={
                                  profile.linkdinverify == "true"
                                    ? "active"
                                    : ""
                                }
                                color={"#B7B7B7"}
                              />
                            </div>
                          </strong>
                        </span>
                      </label>
                      {profile.backgroundstatus == "Yes" ? (
                        <label
                          style={{
                            float: "left",
                            width: "50%",
                            clear: "none",
                            marginTop: "18px",
                          }}
                        >
                          <span>
                            <strong>
                              <DoneIcon />
                              {t("background-check")}
                            </strong>
                          </span>
                        </label>
                      ) : (
                        ""
                      )}
                    </div>
                  )}
                </div>
                <div
                  className={
                    subTab.Reviews == "active" ? "active personal" : "personal"
                  }
                >
                  <h3
                    onClick={(e) =>
                      setSubTab({
                        ...subTab,
                        Reviews: subTab.Reviews == "" ? "active" : "",
                      })
                    }
                  >
                    {t("Revie")}
                  </h3>
                  {subTab.Reviews == "active" &&
                    (profile.reviewData.length > 0 ? (
                      <Slider
                        ref={sliderRef}
                        {...generalSettings}
                        id="Slider-4"
                        className="slider_test reviewshow"
                      >
                        {profile.reviewData?.map((data, index) => {
                          return (
                            <div className="item">
                              <div className="review_list">
                                <div className="left_img">
                                  <img
                                    src={
                                      api +
                                      "/assets/images/users/" +
                                      data.file_path
                                    }
                                  />
                                </div>
                                <div className="right_content">
                                  <h2>{data.SenderName}</h2>
                                  <span>
                                    {[...Array(data.rating)].map(
                                      (star, index) => {
                                        index += 1;
                                        return <StarIcon />;
                                      }
                                    )}

                                    {[...Array(5 - data.rating)].map(
                                      (star, index) => {
                                        index += 1;
                                        return <WhiteStarIcon />;
                                      }
                                    )}
                                  </span>
                                  <span className="date">
                                    {new Date(data.updated_at).getDate()}/
                                    {new Date(data.updated_at).getMonth() + 1}/
                                    {new Date(data.updated_at).getFullYear()}
                                  </span>
                                  <h3>{data.review_title}</h3>
                                  <p>{data.message}</p>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </Slider>
                    ) : (
                      <p className="no_active" style={{ margin: "25px 0" }}>
                        {t("No reviews at this time")}
                      </p>
                    ))}
                </div>

                <div
                  className={
                    subTab.About == "active" ? "active personal" : "personal"
                  }
                >
                  <h3
                    onClick={(e) =>
                      setSubTab({
                        ...subTab,
                        About: subTab.About == "" ? "active" : "",
                      })
                    }
                  >
                    {t("About provider")}
                  </h3>
                  {subTab.About == "active" ? (
                    <div className="editkids aboutdetail">
                      <div className="left2">
                        <label>
                          <span className="half"> {t("First Name")} </span>
                          <span>
                            <strong>{profile.first_name}</strong>
                          </span>
                        </label>
                        <label>
                          <span className="half"> {t("Last Name")} </span>
                          <span>
                            <strong>{profile.last_name}</strong>
                          </span>
                        </label>
                        <label>
                          <span className="half"> {t("Date of birth")}</span>
                          <span>
                            <strong>{profile.dob}</strong>
                          </span>
                        </label>
                      </div>
                      <div className="right2">
                        <label>
                          <span className="half"> {t("text-city")} </span>
                          <span>
                            <strong>{profile.city}</strong>
                          </span>
                        </label>
                        <label>
                          <span className="half"> {t("zip-code")} </span>
                          <span>
                            <strong>{profile.zip}</strong>
                          </span>
                        </label>
                        <label>
                          <span className="half"> {t("Country")} </span>
                          <span>
                            <strong>{profile.country}</strong>
                          </span>
                        </label>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div
                  className={
                    subTab.Job == "active" ? "active personal" : "personal"
                  }
                >
                  <h3
                    onClick={(e) =>
                      setSubTab({
                        ...subTab,
                        Job: subTab.Job == "" ? "active" : "",
                      })
                    }
                  >
                    {t("Education")}
                  </h3>
                  {subTab.Job == "active" && (
                    <div className="editkids ">
                      <div className="educationun">
                        <div className="left2">
                          <label>
                            <span className="half">{t("Education Level")}</span>
                            <span className="">
                              <strong>{t(profile.educationlevel)}</strong>
                            </span>
                          </label>
                          <label>
                            <span className="half">
                              {t("School/ university")}
                            </span>
                            <span>
                              <strong>{t(profile.school)}</strong>
                            </span>
                          </label>
                          <label>
                            <span className="half">{t("Year awarded")} </span>
                            <span>
                              <strong>{t(profile.yearawarded)}</strong>
                            </span>
                          </label>
                        </div>
                        <div className="right2">
                          <label>{t("Qualifications and certificates")} </label>
                          <label>
                            <br />
                            <span>
                              {certificates.qualificationscertificatesname ==
                              "First Aid" ? (
                                <strong>
                                  <DoneIcon />
                                  {t(certificates.qualificationscertificatesname)}
                                </strong>
                              ) : (
                                ""
                              )}
                              {certificates.qualificationscertificatesname3 ==
                              "Board Certified Nurse" ? (
                                <strong>
                                  <DoneIcon />
                                  {t(certificates.qualificationscertificatesname3)}
                                </strong>
                              ) : (
                                ""
                              )}
                              {certificates.qualificationscertificatesname2 ==
                              "Montessori" ? (
                                <strong>
                                  <DoneIcon />
                                  {t(certificates.qualificationscertificatesname2)}
                                </strong>
                              ) : (
                                ""
                              )}
                              {certificates.qualificationscertificatesname4 ? (
                                <strong>
                                  <DoneIcon />
                                  {t(certificates.qualificationscertificatesname4)}
                                </strong>
                              ) : (
                                ""
                              )}
                            </span>
                          </label>
                        </div>
                      </div>
                      <br />
                    </div>
                  )}
                </div>
                <div
                  className={
                    subTab.kids == "active" ? "active personal" : "personal"
                  }
                >
                  <h3
                    onClick={(e) =>
                      setSubTab({
                        ...subTab,
                        kids: subTab.kids == "" ? "active" : "",
                      })
                    }
                  >
                    {t("Work Experience")}
                  </h3>
                  {subTab.kids == "active" && (
                    <div className="editkids editabout">
                      {profile.service_type && profile.service_type.tab1 ? (
                        <div className="nannyediy">
                          <h2 className="border">
                            <img
                              src={
                                window.location.origin + "/images/nany_pur.svg"
                              }
                            />
                            {t("text-nanny")}
                          </h2>
                          {profile.nanynewbornexp == "Yes" ? (
                            <label>
                              <span>
                                <strong>
                                  <DoneIcon />
                                  {t("Experience with newborns")}
                                </strong>
                              </span>
                            </label>
                          ) : (
                            ""
                          )}
                          <label>
                            {t("Years of work experience")}
                            <span className="cir">{profile.nanyyearexp}</span>
                          </label>
                          <br />
                          <br />
                        </div>
                      ) : (
                        ""
                      )}
                      {profile.service_type && profile.service_type.tab2 ? (
                        <div className="nannyediy">
                          <h2 className="border">
                            <img
                              src={
                                window.location.origin +
                                "/images/special_education.svg"
                              }
                            />
                            {t("spec-education-teacher")}
                          </h2>
                          <label>
                            {t("Experience in Special Education Field")}
                          </label>
                          <label>
                            <br />
                            <span>
                              {profile.setallapplicable.map((e) => {
                                return (
                                  <strong>
                                    <DoneIcon />
                                    {t(e.name)}
                                  </strong>
                                );
                              })}
                            </span>
                          </label>
                          <label>
                            {t("Years of work experience")}
                            <span className="cir">{profile.setyearexp}</span>
                          </label>
                          <br />
                          <br />
                        </div>
                      ) : (
                        ""
                      )}

                      {profile.service_type && profile.service_type.tab3 ? (
                        <div className="nannyediy">
                          <h2 className="border">
                            <img
                              src={
                                window.location.origin +
                                "/images/professional.svg"
                              }
                            />
                            {t("spec-education-paraprofessional")}
                          </h2>
                          <label>
                            {t("Experience in Special Education Field")}
                          </label>
                          <label>
                            <br />
                            <span>
                              {profile.sepallapplicable != null &&
                                profile.sepallapplicable.map((e) => {
                                  return (
                                    <strong>
                                      <DoneIcon />
                                      {t(e.name)}
                                    </strong>
                                  );
                                })}
                            </span>
                          </label>
                          <label>
                            {t("Years of work experience")}
                            <span className="cir">{profile.sepworkexp}</span>
                          </label>
                          <br />
                          <br />
                        </div>
                      ) : (
                        ""
                      )}

                      {profile.service_type && profile.service_type.tab4 ? (
                        <div className="nannyediy">
                          <h2 className="border">
                            <img
                              src={window.location.origin + "/images/tuter.svg"}
                            />
                            {t("text-tutor")}
                          </h2>
                          <label>
                            {t("Years of work experience")}
                            <span className="cir">{profile.tutorexp}</span>
                          </label>
                          <br />
                          <br />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  )}
                </div>
                <div
                  className={
                    subTab.job == "active" ? "active personal" : "personal"
                  }
                >
                  <h3
                    onClick={(e) =>
                      setSubTab({
                        ...subTab,
                        job: subTab.job == "" ? "active" : "",
                      })
                    }
                  >
                    {t("job-preferences")}
                  </h3>
                  {subTab.job == "active" ? (
                    <div className="editkids editabout">
                      {profile.service_type && profile.service_type.tab1 ? (
                        <div className="nannyediy">
                          <h2 className="border">
                            <img
                              src={
                                window.location.origin + "/images/nany_pur.svg"
                              }
                            />
                            {t("text-nanny")}
                          </h2>
                          <label>
                            {t("Rate per hour")}
                            <span>
                              <strong>
                                <b>
                                  {profile.country == "Serbia"
                                    ? nanyRate.min * 100 +
                                      " - " +
                                      nanyRate.max * 100 +
                                      " " +
                                      "RSD"
                                    : "$" + nanyRate.min + " - " + nanyRate.max}
                                </b>
                              </strong>
                            </span>
                          </label>
                          <br />
                          <br />
                        </div>
                      ) : (
                        ""
                      )}
                      {profile.service_type && profile.service_type.tab2 ? (
                        <div className="nannyediy">
                          <h2 className="border">
                            <img
                              alt="special-teacher"
                              src={
                                window.location.origin +
                                "/images/special_education.svg"
                              }
                            />
                            {t("spec-education-teacher")}
                          </h2>
                          <label>
                            {t("Rate per hour")}
                            <span>
                              <strong>
                                <b>
                                  {profile.country == "Serbia"
                                    ? generalRate.min * 100 +
                                      " - " +
                                      generalRate.max * 100 +
                                      " " +
                                      "RSD"
                                    : "$" +
                                      generalRate.min +
                                      " - " +
                                      generalRate.max}
                                </b>
                              </strong>
                            </span>
                          </label>
                          <br />
                          <br />
                        </div>
                      ) : (
                        ""
                      )}

                      {profile.service_type && profile.service_type.tab3 ? (
                        <div className="nannyediy">
                          <h2 className="border">
                            <img
                              src={
                                window.location.origin +
                                "/images/professional.svg"
                              }
                            />
                            {t("spec-education-paraprofessional")}
                          </h2>
                          <label>
                            {t("Rate per hour")}
                            <span>
                              <strong>
                                <b>
                                  {profile.country == "Serbia"
                                    ? secondaryRate.min * 100 +
                                      " - " +
                                      secondaryRate.max * 100 +
                                      " " +
                                      "RSD"
                                    : "$" +
                                      secondaryRate.min +
                                      " - " +
                                      secondaryRate.max}
                                </b>
                              </strong>
                            </span>
                          </label>
                          <br />
                          <br />
                        </div>
                      ) : (
                        ""
                      )}

                      {profile.service_type && profile.service_type.tab4 ? (
                        <div className="nannyediy">
                          <h2 className="border">
                            <img
                              src={window.location.origin + "/images/tuter.svg"}
                            />
                            {t("text-tutor")}
                          </h2>
                          <label>
                            {t("Rate per hour")}
                            <span>
                              <strong>
                                <b>
                                  {profile.country == "Serbia"
                                    ? tutorRate.min * 100 +
                                      " - " +
                                      tutorRate.max * 100 +
                                      " " +
                                      "RSD"
                                    : "$" +
                                      tutorRate.min +
                                      " - " +
                                      tutorRate.max}
                                </b>
                              </strong>
                            </span>
                          </label>
                          <h4>{t("Experience in Tutoring")}</h4>
                          <label>
                            {qualifications.English &&
                            qualifications.English != "" ? (
                              <span>
                                <strong>
                                  <DoneIcon />
                                  {t("english")}
                                </strong>
                              </span>
                            ) : (
                              ""
                            )}
                            {qualifications.Serbian &&
                            qualifications.Serbian != "" ? (
                              <span>
                                <strong>
                                  <DoneIcon />
                                  {t("serbian")}
                                </strong>
                              </span>
                            ) : (
                              ""
                            )}
                            {qualifications.Mathematics &&
                            qualifications.Mathematics != "" ? (
                              <span>
                                <strong>
                                  <DoneIcon />
                                  {t("mathematics")}
                                </strong>
                              </span>
                            ) : (
                              ""
                            )}
                            {qualifications.Physics &&
                            qualifications.Physics != "" ? (
                              <span>
                                <strong>
                                  <DoneIcon />
                                  {t("physics")}
                                </strong>
                              </span>
                            ) : (
                              ""
                            )}
                            {qualifications.Chemistry &&
                            qualifications.Chemistry != "" ? (
                              <span>
                                <strong>
                                  <DoneIcon />
                                  {t("chemistry")}
                                </strong>
                              </span>
                            ) : (
                              ""
                            )}
                            {qualifications.Other &&
                            qualifications.Other != "" ? (
                              <span>
                                <strong>
                                  <DoneIcon />
                                  {t("text-other")}
                                </strong>
                              </span>
                            ) : (
                              ""
                            )}
                          </label>
                          <br />

                          <label>
                            {t("Providing online classes")}
                            <span>
                              <strong>
                                <b>
                                  <DoneIcon />
                                  {profile.tutorintrestedonlinecls
                                    ? t(profile.tutorintrestedonlinecls)
                                    : ""}
                                </b>
                              </strong>
                            </span>
                          </label>
                          <br />
                          <br />
                        </div>
                      ) : (
                        ""
                      )}
                      <div className="preferred_school_jobs tutor">
                        <h2 className="border">{t("preffer-jobs")}</h2>
                        <label>
                          <span>
                            {profile.tutorallapplicable != null &&
                              profile.tutorallapplicable.map((e) => {
                                return (
                                  <>
                                    <strong style={{ marginBottom: "8px" }}>
                                      <DoneIcon />
                                      {t(e.name)}
                                    </strong>
                                  </>
                                );
                              })}
                          </span>
                        </label>
                        <label>
                          {t("Years of experience as a teacher")}
                          <span className="cir">
                            {profile.yearofexpasteacher || 0}
                          </span>
                        </label>
                        <br />
                        <br />
                      </div>
                      <div className="gernaledt">
                        <h2 className="border ">{t("general-info")}</h2>
                        <div className="left2">
                          <label>
                            {t("Preferred number of children to work with")}
                            <span
                              className={`cir ${
                                  (profile.nanyworkwithnochild || profile.tutorworkwithnochild) === "twins" &&
                                language === "sr"
                                  ? "twins"
                                  : ""
                              }`}
                            >
                              {t(profile.nanyworkwithnochild ?? profile.tutorworkwithnochild)}
                            </span>
                          </label>
                          <label>
                            {t("Preferred child’s age")}
                            <span>
                              <strong>
                                <b>
                                  {(profile.nanyprefchildage && profile.nanyprefchildage.length > 0) ?
                                    profile.nanyprefchildage.map((e, index) => {
                                      const modifiedName =
                                        language === "sr"
                                          ? e.name.includes("years")
                                            ? e.name.replace("years", "godina")
                                            : e.name
                                          : e.name.includes("godina")
                                            ? e.name.replace("godina", "years")
                                            : e.name;
                                      return (
                                        modifiedName +
                                        (profile.nanyprefchildage.length - 1 >=
                                        index + 1
                                          ? ", "
                                          : "")
                                      );
                                    }) : profile.tutorprefchildage && profile.tutorprefchildage.length > 0 &&
                                      profile.tutorprefchildage.map((e, index) => {
                                        const modifiedName =
                                            language === "sr"
                                                ? e.name.includes("years")
                                                    ? e.name.replace("years", "godina")
                                                    : e.name
                                                : e.name.includes("godina")
                                                    ? e.name.replace("godina", "years")
                                                    : e.name;
                                        return (
                                            modifiedName +
                                            (profile.tutorprefchildage.length - 1 >=
                                            index + 1
                                                ? ", "
                                                : "")
                                        );
                                      })
                                  }
                                </b>
                              </strong>
                            </span>
                          </label>
                        </div>
                        <div className="right2">
                          <label>
                            {t("start-date")}
                            <span>
                              <strong>
                                <b>{profile.nanystartdate}</b>
                              </strong>
                            </span>
                          </label>
                          <label>
                            {t("text-frequency")}
                            <span>
                              <strong>
                                <b>{t(profile.nanyintrestedin)}</b>
                              </strong>
                            </span>
                          </label>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div
                  className={
                    subTab.availability == "active"
                      ? "active personal"
                      : "personal"
                  }
                >
                  <h3
                    onClick={(e) =>
                      setSubTab({
                        ...subTab,
                        availability: subTab.availability == "" ? "active" : "",
                      })
                    }
                  >
                    {t("availability")}
                  </h3>
                  {subTab.availability == "active" ? (
                    <div className="calendershow">
                      <div className="calander">
                        <div className="form_group full">
                          <label>{t("Availability and working hours")}</label>
                        </div>
                        <div className="legend">
                          <div className="form_group full">
                            <label className="border">{t("text-legend")}</label>
                            <ul>
                              <li>
                                <strong>{t("full-time")}</strong>
                                <br />
                                {t("mon-fri-9-6")}
                              </li>
                              <li>
                                <strong>{t("before-school")}</strong>
                                <br />
                                {t("mon-fri-6-9")}
                              </li>
                              <li>
                                <strong>{t("after-school")}</strong>
                                <br />
                                {t("mon-fri-3-9")}
                              </li>
                              <li>
                                <strong>{t("text-overnight")}</strong>
                                <br />
                                {t("mon-fri-9-6-am")}
                              </li>
                              <li>
                                <strong>{t("text-weekends")}</strong>
                                <br />
                                {t("saturday-sunday")}
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="calanderfull progilecl">
                          {calendarType == 1 ? (
                            <Calendar
                              data={handleCalendarData}
                              data1={profile.fulltime}
                              data2={"fulltime"}
                              profile={"profile"}
                            />
                          ) : (
                            ""
                          )}
                          {calendarType == 2 ? (
                            <Calendar
                              data={handleCalendarData}
                              data1={profile.beforeschool}
                              data2={"beforeschool"}
                              profile={"profile"}
                            />
                          ) : (
                            ""
                          )}
                          {calendarType == 3 ? (
                            <Calendar
                              data={handleCalendarData}
                              data1={profile.afterschool}
                              data2={"afterschool"}
                              profile={"profile"}
                            />
                          ) : (
                            ""
                          )}
                          {calendarType == 4 ? (
                            <Calendar
                              data={handleCalendarData}
                              data1={profile.overnight}
                              data2={"overnight"}
                              profile={"profile"}
                            />
                          ) : (
                            ""
                          )}
                          {calendarType == 5 ? (
                            <Calendar
                              data={handleCalendarData}
                              data1={profile.weekends}
                              data2={"weekends"}
                              profile={"profile"}
                            />
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div
                  className={
                    subTab.info == "active" ? "active personal" : "personal"
                  }
                >
                  <h3
                    onClick={(e) =>
                      setSubTab({
                        ...subTab,
                        info: subTab.info == "" ? "active" : "",
                      })
                    }
                  >
                    {t("additional-info")}
                  </h3>
                  {subTab.info == "active" ? (
                    <div className="editkids editabout editinfo providerprofile">
                      <ProviderProfileLanguage profile={profile} />
                      {profile.service_type && profile.service_type.tab1 && (
                        <div className="icon">
                          <ul>
                            <li>
                              <span>{t("Smoking")}</span>
                              <div className="icons">
                                {habit.smoke == "false" ? (
                                  <BanSmokeIcon
                                    color={"#7D2B8B"}
                                    colorCircle={"#7D2B8B"}
                                    colorPath={"#fff"}
                                  />
                                ) : (
                                  ""
                                )}
                                {habit.smoke == "true" ? (
                                  <SmokeIcon
                                    color={"#7D2B8B"}
                                    colorCircle={"#7D2B8B"}
                                    colorPath={"#fff"}
                                  />
                                ) : (
                                  ""
                                )}
                              </div>
                            </li>
                            <li>
                              <span>{t("cooking-kids")}</span>
                              <div className="icons">
                                {habit.kids == "false" ? (
                                  <BanCookIcon
                                    color={"#7D2B8B"}
                                    colorCircle={"#7D2B8B"}
                                    colorPath={"#fff"}
                                  />
                                ) : (
                                  <CookIcon
                                    color={"#7D2B8B"}
                                    colorCircle={"#7D2B8B"}
                                    colorPath={"#fff"}
                                  />
                                )}
                              </div>
                            </li>
                            <li>
                              <span>{t("light-housework")}</span>
                              <div className="icons">
                                {habit.housework == "false" ? (
                                  <BanHouseIcon
                                    color={"#7D2B8B"}
                                    colorPath={"#fff"}
                                    colorCircle={"#7D2B8B"}
                                  />
                                ) : (
                                  <HouseIcon
                                    color={"#7D2B8B"}
                                    colorPath={"#fff"}
                                    colorCircle={"#7D2B8B"}
                                  />
                                )}
                              </div>
                            </li>
                            <li>
                              <span>{t("Car or licence")}</span>
                              <div className="icons">
                                {habit.licence == "false" ? (
                                  <BanCarIcon
                                    color={"#7D2B8B"}
                                    colorCircle={"#7D2B8B"}
                                    colorPath={"#fff"}
                                  />
                                ) : (
                                  <CarIcon
                                    color={"#7D2B8B"}
                                    colorCircle={"#7D2B8B"}
                                    colorPath={"#fff"}
                                  />
                                )}
                              </div>
                            </li>
                            <li>
                              <span>{t("Traveling with family")}</span>
                              <div className="icons">
                                {habit.family == "false" ? (
                                  <BanPlaneIcon
                                    color={"#7D2B8B"}
                                    colorCircle={"#7D2B8B"}
                                    colorPath={"#fff"}
                                  />
                                ) : (
                                  <PlaneIcon
                                    color={"#7D2B8B"}
                                    colorCircle={"#7D2B8B"}
                                    colorPath={"#fff"}
                                  />
                                )}
                              </div>
                            </li>
                          </ul>
                        </div>
                      )}
                      <div className="nannyediy">
                        {profile.service_type &&
                        profile.service_type.tab1 &&
                        profile.livewithfamily ? (
                          <div className="abrod">
                            <label className="abrodd">
                              <HomeIcon />
                              {t("Living with the family")}
                              <span>{t(profile.livewithfamily)}</span>
                            </label>
                            <br />
                          </div>
                        ) : (
                          ""
                        )}
                        <div className="abrod">
                          <label className="abrodd">
                            <ThingIcon />
                            {t("Work abroad")}
                            <span>{t(profile.workingabroad)}</span>
                          </label>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            ) : (
              ""
            )}
            {subtab == "Profile" ? (
              <>
                {localStorage.getItem("user_type") == "parents" ? (
                  <ViewEdit />
                ) : (
                  <ViewEditProvider />
                )}
              </>
            ) : (
              ""
            )}
          </div>
        </div>

        <ProviderMobileContent
          profileSection={profileSection}
          subtab={subtab}
          setProfileSection={setProfileSection}
          setsubtab={setsubtab}
        />
      </div>

      {isSafetyModal && (
        <SafetyModal
          isSafetyModal={isSafetyModal}
          setIsSafetyModal={setIsSafetyModal}
          setIsMembershipModal={setIsMembershipModal}
        />
      )}

      {isMembershipModal && (
        <MembershipModal
          isMembershipModal={isMembershipModal}
          setIsMembershipModal={setIsMembershipModal}
          report={report}
          setReport={setReport}
          handleReport={handleReport}
        />
      )}

      {isSignoutModal && (
        <SignoutModal
          isSignoutModal={isSignoutModal}
          setIsSignoutModal={setIsSignoutModal}
        />
      )}

      {isInviteModal && (
        <InviteModal
          isInviteModal={isInviteModal}
          setIsInviteModal={setIsInviteModal}
          jobsListProfession={profile}
          setJobID={setJobID}
          handleInterviewInvite={handleInterviewInvite}
        />
      )}

      {isDocumentsModal && (
        <DocumentsModal
          isDocumentsModal={isDocumentsModal}
          setIsDocumentsModal={setIsDocumentsModal}
          handleDocumentSelect={handleDocumentSelect}
          handleRecommendations={handleRecommendations}
        />
      )}

      {isLoginModal && (
        <LoginModal
          isLoginModal={isLoginModal}
          setIsLoginModal={setIsLoginModal}
        />
      )}

      {showProviderModal && (
        <ProviderModal
          showProviderModal={showProviderModal}
          setIsProviderModal={setIsProviderModal}
        />
      )}

      {photoUpload && (
        <PhotoUploadModal
          photoUpload={photoUpload}
          photo={photo}
          image={image}
          selfProfile={selfProfile}
          setPhotoUpload={setPhotoUpload}
          setPhoto={setPhoto}
          handleImageChange={handleImageChange}
          handleProfileUpdate={handleProfileUpdate}
        />
      )}

      {isReviewModal && (
        <ReviewModal
          isReviewModal={isReviewModal}
          slugData={slugData}
          profile={profile}
          setIsReviewModal={setIsReviewModal}
        />
      )}
      <Footer />
    </>
  );
}
