import React from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { DumpSecondaryIcon } from "../../../assets/image/DumpSecondaryIcon";
import { AutoIcon } from "../../../assets/image/AutoIcon";

export function MessageTab(props) {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <>
      <ul>
        <li
          className={props.subtab == "message-inbox" ? "active" : ""}
          onClick={(e) => {
            window.scrollTo({ top: 0, behavior: "smooth" });
            props.setsubtab("message-inbox");
            navigate("/search-parents/message-inbox");
          }}
        >
          <AutoIcon />
          {t("text-inbox-up")}
        </li>
        <li
          className={props.subtab == "Trash" ? "active" : ""}
          onClick={(e) => {
            window.scrollTo({ top: 0, behavior: "smooth" });
            props.setsubtab("text-trash");
            navigate("/search-parents/Trash");
          }}
        >
          <DumpSecondaryIcon />
          {t("text-trash")}
        </li>
      </ul>
    </>
  );
}
