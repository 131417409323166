import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { getCountryDetails } from "../../../api/countryApi";

export function CustomSelect(props) {
  const { i18n } = useTranslation();

  const [showItems, setShowItems] = useState(false);
  const [dataCountry, setDataCountry] = useState("");
  const [isCountry, setIsCountry] = useState(true);
  const [selectedItem, setSelectedItem] = useState(() => {

    const storedLang = localStorage.getItem("language");
    return storedLang
      ? props.items.find((item) => item.lang === storedLang) || props.items[0]
      : props.items[0];
  });

  const fetchCountryData = async () => {
    try {
      const result = await getCountryDetails();
      setDataCountry(result.data.country);
    } catch (error) {
      console.error("Error fetching country details:", error);
    }
  };

  useEffect(() => {
    fetchCountryData();
  }, []);

  const handleDropDown = () => {
    setShowItems(!showItems);
  };

  const handleSelectItem = (item) => {
    setSelectedItem(item);
    setShowItems(false);
    localStorage.setItem("language", item.lang);
    i18n.changeLanguage(item.lang);
  };

  useEffect(() => {
    const storedLang = localStorage.getItem("language");
    const resultLang = props.items.find((item) => item.lang === storedLang) || props.items[0]

      setSelectedItem(resultLang);
   
  }, [dataCountry, props.items]);

  return (
    <div className="select-box--box">
      <div className="select-box--container">
        <div className="select-box--selected-item" onClick={handleDropDown}>
          {selectedItem.value} <img src={selectedItem.icon} />
        </div>
        <div
          style={{ display: showItems ? "block" : "none" }}
          className={"select-box--items"}
        >
          <div className="drop">
            {props.items.map((item) => (
              <div
                key={item.id}
                onClick={() => handleSelectItem(item)}
                className={selectedItem === item ? "selected" : ""}
              >
                {item.value} <img src={item.icon} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
