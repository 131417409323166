import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Modal from "react-bootstrap/Modal";

import { useQualificationStore } from "../../../../store/useQualificationStore";
import { PageIcon } from "../../../../assets/image/PageIcon";
import { DownloadIcon } from "../../../../assets/image/DownloadIcon";

export const DocumentModal = ({
  handleUpdateProfile,
  showModalDocument,
  setShowModalDocument,
  submit,
  isLoader,
}) => {
  const { t } = useTranslation();

  const {
    detailProvider,
    setDetailProvider,
    setCertificates,
    errorField,
    setErrorField,
  } = useQualificationStore();

  return (
    <Modal
      show={showModalDocument}
      onHide={(e) => setShowModalDocument(false)}
      className="upload_doc_modul"
    >
      <Modal.Body>
        <div className="promocode_content upload_document">
          <Link to="" onClick={(e) => setShowModalDocument(false)}>
            +
          </Link>
          <h2>{t("text-upload-document")}</h2>
          <p>{t("choose-document-upload")}</p>
          <ul>
            <li>
              <span className="docic">
                <PageIcon />
              </span>
              <span className="named">{t("text-recommendation")}</span>
              <div className="upload_field">
                <input
                  type="file"
                  placeholder={t("choose-file")}
                  onChange={(e) => {
                    if (
                      e.target.files[0].name !=
                      "The upload does not work properly.docx"
                    ) {
                      setErrorField({
                        ...errorField,
                        recommendationsfile: "",
                      });
                      setDetailProvider({
                        ...detailProvider,
                        recommendationsfile: e.target.files[0],
                      });
                    }
                  }}
                  accept=".doc, .docx,.ppt, .pptx,.txt,.pdf"
                />
                <span>
                  {detailProvider.recommendationsfile != ""
                    ? detailProvider.recommendationsfile.name
                      ? detailProvider.recommendationsfile.name
                      : detailProvider.recommendationsfile
                    : t("choose-file")}
                </span>
                <button>
                  <DownloadIcon />
                </button>
              </div>
            </li>
            <li>
              <span className="docic">
                <PageIcon />
              </span>
              <span className="named">{t("text-cv-up")}</span>
              <div className="upload_field">
                <span>
                  {detailProvider.cv != ""
                    ? detailProvider.cv.name
                      ? detailProvider.cv.name
                      : detailProvider.cv
                    : t("choose-file")}
                </span>
                <input
                  className={errorField.cv != "" ? "bordererror" : ""}
                  type="file"
                  placeholder={t("choose-file")}
                  onChange={(e) => {
                    if (
                      e.target.files[0].name !=
                      "The upload does not work properly.docx"
                    ) {
                      setErrorField({ ...errorField, cv: "" });
                      setDetailProvider({
                        ...detailProvider,
                        cv: e.target.files[0],
                      });
                    }
                  }}
                  accept=".doc, .docx,.ppt, .pptx,.txt,.pdf"
                />
                <button>
                  <DownloadIcon />
                </button>
              </div>
            </li>
            <li>
              <span className="docic">
                <PageIcon />
              </span>
              <span className="named">{t("text-certificate")}</span>
              <div className="uploadcert">
                <div className="upload_field">
                  <input
                    type="file"
                    placeholder={t("choose-file")}
                    onChange={(e) => {
                      if (
                        e.target.files[0].name !=
                        "The upload does not work properly.docx"
                      ) {
                        setErrorField({
                          ...errorField,
                          qualificationscertificatesdoc: "",
                        });
                        setDetailProvider({
                          ...detailProvider,
                          qualificationscertificatesdoc: e.target.files[0],
                        });
                      }
                    }}
                    accept=".doc, .docx,.ppt, .pptx,.txt,.pdf"
                    id=""
                  />
                  <span>
                    {detailProvider.qualificationscertificatesdoc != ""
                      ? detailProvider.qualificationscertificatesdoc.name
                        ? detailProvider.qualificationscertificatesdoc.name
                        : detailProvider.qualificationscertificatesdoc
                      : t("choose-file")}
                  </span>
                  <button>
                    <DownloadIcon />
                  </button>
                </div>
              </div>
            </li>
            <li>
              <span className="docic">
                <PageIcon />
              </span>
              <span className="named">{t("background-check")}</span>
              <div className="upload_field">
                <input
                  type="file"
                  placeholder={t("choose-file-upload")}
                  onChange={(e) => {
                    if (
                      e.target.files[0].name !=
                      "The upload does not work properly.docx"
                    ) {
                      setErrorField({
                        ...errorField,
                        backgroundstatus: "",
                        backgrounddoc: "",
                      });
                      setDetailProvider({
                        ...detailProvider,
                        backgrounddoc: e.target.files[0],
                      });
                    }
                  }}
                  accept=".doc, .docx,.ppt, .pptx,.txt,.pdf"
                  id="Background"
                />
                <span>
                  {detailProvider.backgrounddoc != ""
                    ? detailProvider.backgrounddoc.name
                      ? detailProvider.backgrounddoc.name
                      : detailProvider.backgrounddoc
                    : t("choose-file")}
                </span>
                <button>
                  <DownloadIcon />
                </button>
              </div>
            </li>
          </ul>
          <button
            onClick={(e) => {
              setDetailProvider({
                recommendationsfile: "",
                qualificationscertificatesname: "",
                cv: "",
                qualificationscertificatesdoc: "",
                backgrounddoc: "",
              });
              setCertificates({
                qualificationscertificatesname: "",
                qualificationscertificatesname2: "",
                qualificationscertificatesname3: "",
                qualificationscertificatesname4: "",
              });
              setShowModalDocument(false);
            }}
          >
            {t("text-cancel")}
          </button>
          <button
            style={submit != "" ? { opacity: "0.5" } : {}}
            onClick={(e) => handleUpdateProfile()}
          >
            {submit == "" ? t("text-submits") : t("Submitting")}
          </button>
          {isLoader && (
            <Modal show={true} className="upload_doc_modul">
              <div className="loder_back">
                <div class="loader"></div>
              </div>
            </Modal>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};
