import { DEFAULT_API } from "../utils/variables";

export const getProviderList = async (refine) => {
  const myHeaders = new Headers();
  myHeaders.append("Authorization", "Bearer " + localStorage.getItem("token"));
  myHeaders.append("Content-Type", "application/json");

  const queryParams = refine.threedays
    ? `threedays=${refine.threedays}`
    : refine.withinweek
    ? `withinweek=${refine.withinweek}`
    : refine.twofourday
    ? `twofourday=${refine.twofourday}`
    : refine.withinmonth
    ? `withinmonth=${refine.withinmonth}`
    : "";

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  try {
    const response = await fetch(
      `${DEFAULT_API}/api/v1/providermydocumentslist?${queryParams}`,
      requestOptions
    );

    if (!response.ok) {
      const error = await response.json();
      throw new Error(error.message || "Something went wrong");
    }

    return response.json();
  } catch (error) {
    console.error("Error fetching provider list:", error);
    throw error;
  }
};
