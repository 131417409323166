export const MailIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="27"
      viewBox="0 0 16 17"
      fill="none"
    >
      <path d="M14.8763 0.100971L0.390353 8.45812C-0.175333 8.78315 -0.10345 9.57074 0.45911 9.80826L3.78134 11.2022L12.7604 3.28881C12.9323 3.13567 13.1761 3.37007 13.0292 3.54822L5.50028 12.7211V15.237C5.50028 15.9745 6.391 16.2652 6.82854 15.7308L8.81313 13.3149L12.7073 14.9463C13.1511 15.1338 13.6574 14.8557 13.7387 14.3775L15.9889 0.876054C16.0952 0.244736 15.417 -0.211562 14.8763 0.100971Z" />
    </svg>
  );
};
