import React from "react";
import { useTranslation } from "react-i18next";
import Modal from "react-bootstrap/Modal";

export const ReviewModal = ({
  showReviewModal,
  setShowReviewModal,
  handleDeleteMessageNotification,
  setIsActive,
  isActive,
}) => {
  const { t } = useTranslation();

  return (
    <Modal show={showReviewModal} onHide={(e) => setShowReviewModal(false)}>
      <Modal.Body>
        <div className="promocode_content signout invite">
          <p>
            <b>{t("delete-message-question")}</b>
          </p>
          <div class="button text-center ">
            <div class="pull-right">
              <button
                class="btn"
                onClick={(e) => {
                  setShowReviewModal(false);
                }}
              >
                {t("objection")}
              </button>
              <button
                class="btn"
                onClick={(e) => {
                  setIsActive(!isActive);
                  handleDeleteMessageNotification();
                  setShowReviewModal(false);
                }}
                style={{ marginLeft: "20px" }}
              >
                {t("confirm")}
              </button>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
