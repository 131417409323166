import { create } from 'zustand'

export const useRatesStore = create((set) => ({
    nanyRate: {
      min: 0,
      max: 0,
    },
    generalRate: {
      min: 0,
      max: 0,
    },
    secondaryRate: {
      min: 0,
      max: 0,
    },
    tutorRate: {
      min: 0,
      max: 0,
    },

    setNanyRate: (newRate) => set({ nanyRate: newRate }),
    setGeneralRate: (newRate) => set({ generalRate: newRate }),
    setSecondaryRate: (newRate) => set({ secondaryRate: newRate }),
    setTutorRate: (newRate) => set({ tutorRate: newRate }),
  }));