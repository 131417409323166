import React from "react";
import { useTranslation } from "react-i18next";
import Modal from "react-bootstrap/Modal";

import { useRatesStore } from "../../../../store/useRatesStore";
import { useProviderFieldStore } from "../../../../store/useProviderFieldStore";
import { RATE_NUMBERS, RATE_NUMBERS_SECOND, STEP_RATE } from "../../../../utils/variables";

export const ProfessionalModal = ({
  showProfessionalModal,
  handleCloseProfessionModal,
  workExperience,
  detail,
  handleSubmitProvider,
  validateAfterLoginField,
  countp,
  setWorkExperience,
}) => {
  const { t } = useTranslation();

  const { errorField, setErrorField, detailProvider, setDetailProvider } =
    useProviderFieldStore();

  const { generalRate, setGeneralRate } = useRatesStore();

  return (
    <Modal
      show={showProfessionalModal}
      onHide={handleCloseProfessionModal}
      className="question_new"
    >
      <Modal.Body>
        <div className="younger">
          <img src="/images/sign_logo.svg" />
          <h2>{t("Complete the Profile")}</h2>
          <p>{t("You can edit your info in settings section later")}</p>
          <div className="Profile_complete">
            <div className="special_education">
              <div className="detail work-experience your_kids  setp3">
                <h2 className="border">
                  <img src="/images/professional.svg" />
                  {t("education-paraprofessional")}
                </h2>
                <div className="col-6">
                  <div className="iconsec">
                    <div className="form_group full">
                      <label>
                        {t("Years of work experience")}
                        <span
                          className={
                            errorField.sepworkexp != "" ? "starred" : ""
                          }
                        >
                          *
                        </span>
                      </label>
                      <div className="rang" style={{ minWidth: "375px" }}>
                        <div
                          class="slider"
                          onClick={(w) =>
                            setErrorField({ ...errorField, sepworkexp: "" })
                          }
                        >
                          <input
                            type="range"
                            min="0"
                            max="60"
                            onChange={(e) => setWorkExperience(e.target.value)}
                            style={{
                              "background-size":
                                (workExperience * 100) / 60 + "% 100% ",
                            }}
                            value={workExperience}
                          />
                          <ul>
                            <li>0</li>
                            <li>15</li>
                            <li>30</li>
                            <li>45</li>
                            <li>60</li>
                          </ul>
                        </div>
                        <span id="rangeValue">{workExperience}</span>
                      </div>
                    </div>
                  </div>
                  <div className="rightsec">
                    <div className="form_group full">
                      <label>
                        {t("Rate per hour")}
                        {detail.country == "Serbia" ? "(RSD)" : "(USD)"}
                        <span
                          className={
                            errorField.nanyperhrrate != "" ? "starred" : ""
                          }
                        >
                          *
                        </span>
                      </label>
                      {detail && detail.country == "Serbia" ? (
                        <div class="wrapper rang">
                          <div class="container_slide">
                            <div class="slider-track">
                              <ul>
                              {RATE_NUMBERS.map((value, index) => {
                                    const style =
                                      generalRate.min >= value ||
                                      (generalRate.max < value && generalRate.max > 0)
                                        ? { backgroundColor: "#E5E5E5" }
                                        : {};
                                    return <li key={index} style={style}></li>;
                                  })}
                              </ul>
                            </div>
                            <div class="bggray_slider">
                              <ul>
                              {RATE_NUMBERS_SECOND.map((value, index) => {
                                    const style =
                                      generalRate.min >= value ||
                                      (generalRate.max < value && generalRate.max > 0)
                                        ? { backgroundColor: "#E5E5E5" }
                                        : {};
                                    return <li key={index} style={style}></li>;
                                  })}
                              </ul>
                            </div>
                            <input
                              type="range"
                              min="0"
                              max="5"
                              id="slider-1"
                              value={generalRate.min / 10}
                              onChange={(e) => {
                                if (
                                  (generalRate.max > 0 ? generalRate.max : 60) >
                                  (e.target.value == 5
                                    ? 60
                                    : e.target.value * 10)
                                ) {
                                  setGeneralRate({
                                    ...generalRate,
                                    min:
                                      e.target.value == 5
                                        ? 60
                                        : e.target.value * 10,
                                  });
                                }
                              }}
                            />
                            <input
                              type="range"
                              min="0"
                              max="5"
                              id="slider-2"
                              value={
                                generalRate.max == 0
                                  ? 50 / 10
                                  : generalRate.max / 10
                              }
                              onChange={(e) => {
                                if (
                                  generalRate.min <
                                  (e.target.value == 5
                                    ? 60
                                    : e.target.value * 10)
                                ) {
                                  setGeneralRate({
                                    ...generalRate,
                                    max:
                                      e.target.value == 5
                                        ? 60
                                        : e.target.value * 10,
                                  });
                                }
                              }}
                            />
                          </div>
                          <div class="slider two">
                            <ul>
                              <li>0</li>
                              <li>1000</li>
                              <li>2000</li>
                              <li>3000</li>
                              <li>4000</li>
                              <li>6000+</li>
                            </ul>
                          </div>
                        </div>
                      ) : (
                        <div class="wrapper rang">
                          <div class="container_slide">
                            <div class="slider-track">
                              <ul>
                              {RATE_NUMBERS.map((value, index) => {
                                    const style =
                                      generalRate.min >= value ||
                                      (generalRate.max < value && generalRate.max > 0)
                                        ? { backgroundColor: "#E5E5E5" }
                                        : {};
                                    return <li key={index} style={style}></li>;
                                  })}
                              </ul>
                            </div>
                            <div class="bggray_slider">
                              <ul>
                              {RATE_NUMBERS_SECOND.map((value, index) => {
                                    const style =
                                      generalRate.min >= value ||
                                      (generalRate.max < value && generalRate.max > 0)
                                        ? { backgroundColor: "#E5E5E5" }
                                        : {};
                                    return <li key={index} style={style}></li>;
                                  })}
                              </ul>
                            </div>
                            <input
                              type="range"
                              min="0"
                              max="5"
                              id="slider-1"
                              value={generalRate.min / 10}
                              onChange={(e) => {
                                if (
                                  (generalRate.max > 0 ? generalRate.max : 60) >
                                  (e.target.value == 5
                                    ? 60
                                    : e.target.value * 10)
                                ) {
                                  setGeneralRate({
                                    ...generalRate,
                                    min:
                                      e.target.value == 5
                                        ? 60
                                        : e.target.value * 10,
                                  });
                                }
                              }}
                            />
                            <input
                              type="range"
                              min="0"
                              max="5"
                              id="slider-2"
                              value={
                                generalRate.max == 0
                                  ? 50 / 10
                                  : generalRate.max / 10
                              }
                              onChange={(e) => {
                                if (
                                  generalRate.min <
                                  (e.target.value == 5
                                    ? 60
                                    : e.target.value * 10)
                                ) {
                                  setGeneralRate({
                                    ...generalRate,
                                    max:
                                      e.target.value == 5
                                        ? 60
                                        : e.target.value * 10,
                                  });
                                }
                              }}
                            />
                          </div>
                          <div class="slider two">
                            <ul>
                              <li>0</li>
                              <li>10</li>
                              <li>20</li>
                              <li>30</li>
                              <li>40</li>
                              <li>60+</li>
                            </ul>
                          </div>
                        </div>
                      )}
                      <span class="price">
                        {detail && detail.country == "Serbia"
                          ? generalRate.min * 100 +
                            " - " +
                            generalRate.max * 100
                          : generalRate.min + " - " + generalRate.max}
                      </span>
                    </div>
                  </div>
                  <div className="iconsec space">
                    <div className="form_group full">
                      <label>
                        {t("Experience with following methods")}
                        <span
                          className={
                            errorField.sepexpmethods != "" ? "starred" : ""
                          }
                        >
                          *
                        </span>
                      </label>
                      <div className="checkbox">
                        <ul
                          onClick={(e) =>
                            setErrorField({
                              ...errorField,
                              sepexpmethods: "",
                            })
                          }
                        >
                          <li>
                            <input
                              type="radio"
                              name="quality4"
                              checked={
                                detailProvider.sepexpmethods &&
                                detailProvider.sepexpmethods == "ABA"
                                  ? true
                                  : false
                              }
                              onClick={(e) =>
                                setDetailProvider({
                                  ...detailProvider,
                                  sepexpmethods: "ABA",
                                })
                              }
                            />
                            <span> {t("ABA")}</span>
                          </li>
                          <li>
                            <input
                              type="radio"
                              name="quality4"
                              checked={
                                detailProvider.sepexpmethods &&
                                detailProvider.sepexpmethods == "PECS"
                                  ? true
                                  : false
                              }
                              onClick={(e) =>
                                setDetailProvider({
                                  ...detailProvider,
                                  sepexpmethods: "PECS",
                                })
                              }
                            />
                            <span> {t("PECS")}</span>
                          </li>
                          <li>
                            <input
                              type="radio"
                              name="quality4"
                              checked={
                                detailProvider.sepexpmethods &&
                                detailProvider.sepexpmethods == "Floortime"
                                  ? true
                                  : false
                              }
                              onClick={(e) =>
                                setDetailProvider({
                                  ...detailProvider,
                                  sepexpmethods: "Floortime",
                                })
                              }
                            />
                            <span> {t("Floortime")}</span>
                          </li>
                          <li>
                            <input
                              type="radio"
                              name="quality4"
                              checked={
                                detailProvider.sepexpmethods &&
                                detailProvider.sepexpmethods == "None"
                                  ? true
                                  : false
                              }
                              onClick={(e) =>
                                setDetailProvider({
                                  ...detailProvider,
                                  sepexpmethods: "None",
                                })
                              }
                            />
                            <span> {t("None")}</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="rightsec">
                    <div className="form_group full">
                      <label>
                        {t(
                          "experience-working-kids"
                        )}
                        <span
                          className={
                            errorField.sepexpwithkid != "" ? "starred" : ""
                          }
                        >
                          *
                        </span>
                      </label>
                      <div className="checkbox">
                        <ul
                          onClick={(e) =>
                            setErrorField({
                              ...errorField,
                              sepexpwithkid: "",
                            })
                          }
                        >
                          <li>
                            <input
                              type="radio"
                              name="quality3"
                              checked={
                                detailProvider.sepexpwithkid == "Yes"
                                  ? true
                                  : false
                              }
                              onClick={(e) =>
                                setDetailProvider({
                                  ...detailProvider,
                                  sepexpwithkid: "Yes",
                                })
                              }
                            />
                            <span> {t("confirm")}</span>
                          </li>
                          <li>
                            <input
                              type="radio"
                              name="quality3"
                              checked={
                                detailProvider.sepexpwithkid == "No"
                                  ? true
                                  : false
                              }
                              onClick={(e) =>
                                setDetailProvider({
                                  ...detailProvider,
                                  sepexpwithkid: "No",
                                })
                              }
                            />
                            <span> {t("objection")}</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <span>{t("required-fields")}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="button text-center">
            <div class="pull-right">
              <button class="btn" onClick={(e) => handleCloseProfessionModal()}>
                {t("text-back")}
              </button>
            </div>
            <div class="pull-right">
              <button
                class="btn confirm"
                onClick={(e) => {
                  if (
                    workExperience > 0 &&
                    detailProvider.sepexpmethods != "" &&
                    generalRate.max > 5 &&
                    detailProvider.sepexpwithkid != "" &&
                    countp <= 2
                  ) {
                    handleCloseProfessionModal();
                    window.scrollTo({ top: 0 });
                    handleSubmitProvider(true);
                  } else {
                    STEP_RATE.map((e) => {
                      validateAfterLoginField(e.name);
                    });
                  }
                }}
              >
                {t("text-finish")}
              </button>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
