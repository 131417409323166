import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export const HeaderMenu = ({ props, logincheck, logout }) => {
  const { t } = useTranslation();

  const userType = localStorage.getItem("user_type");
  const accountLink =
    userType !== "parents"
      ? "/search-parents/Account"
      : "/search-providers/Account";

  return (
    <>
      <li className="signup">
        <Link to="">{t("upgrade")}</Link>
      </li>
      <li>
        <Link to="" className={props.page === "profile" ? "active" : ""}>
          {logincheck.namep ? logincheck.namep : "username"}
          <img src={window.location.origin + "/images/done_a.svg"} alt="done" />
        </Link>
        <ul>
          <li>
            <Link
              to={accountLink}
              onClick={() => {
                localStorage.setItem("search2", "setting");
              }}
            >
              {t("my-profile")}
            </Link>
          </li>
          <li onClick={logout}>{t("logout")}</li>
        </ul>
      </li>
    </>
  );
};
