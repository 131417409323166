import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { api } from "../../../urls";
import { SocialFacebook } from "../../common/SocialFacebook";
import { FavoriteSelect } from "../../common/FavoriteSelect";

import { PhoneIcon } from "../../../assets/image/PhoneIcon";
import { ServiceIcon } from "../../../assets/image/ServiceIcon";


export const JobCandidate = ({
  data,
  index,
  handleElementById,
  profile,
  handleFavJobData,
}) => {
  const { t } = useTranslation();

  return (
    <div class="mobile_looking_for_candidate_box">
      <div class="head_part">
        <h3>{data.title != null ? data.title : ""}</h3>
        {data.plateformonsocialmedia == "Yes" && (
          <SocialFacebook
            link={window.location.origin + "/profile-parents/" + data.user_id}
          />
        )}
      </div>
      <div class="post_general">
        <h6>{data.school != null ? data.school : ""}</h6>
        <p>
          <img
            src={window.location.origin + "/images/family_star.svg"}
            alt=""
          />
          <span> (3)</span>
        </p>
      </div>
      <div class="main_sec">
        <div class="first_sec">
          <div class="image_sec">
            <img
              src={
                data.file_path != null
                  ? api + "/assets/images/users/" + data.file_path
                  : "img/nany_img.png"
              }
              alt=""
            />
            <div class="heart_sec">
              <FavoriteSelect
                id={data.jid}
                heart={handleFavJobData}
                username={data.first_name}
              />
            </div>
            <div class="nany_social">
              {data.phoneVerifiedStatus == 1 ? (
                <img
                  src={window.location.origin + "/images/nany_phone.svg"}
                  alt=""
                />
              ) : (
                <PhoneIcon />
              )}
              <img
                src={window.location.origin + "/images/nany_msg.svg"}
                alt=""
              />
              {data.facebookverify != null || data.linkdinverify != null ? (
                <img
                  src={window.location.origin + "/images/nany_cont.svg"}
                  alt=""
                />
              ) : (
                <ServiceIcon />
              )}
            </div>
          </div>
        </div>
        <div class="second_sec">
          <div class="post">
            {data.service_type != null ? (
              Object.values(JSON.parse(data.service_type))[0] == "Nanny" ? (
                <img
                  src={window.location.origin + "/images/nany_post.svg"}
                  alt=""
                />
              ) : Object.keys(JSON.parse(data.service_type))[0] == "tab2" ? (
                <img
                  src={window.location.origin + "/images/teacher_post.svg"}
                  alt=""
                />
              ) : Object.keys(JSON.parse(data.service_type))[0] == "tab3" ? (
                <img
                  src={window.location.origin + "/images/education_post.svg"}
                  alt=""
                />
              ) : Object.keys(JSON.parse(data.service_type))[0] == "tab4" ? (
                <img
                  src={window.location.origin + "/images/tutor_post.svg"}
                  alt=""
                />
              ) : (
                ""
              )
            ) : (
              ""
            )}
            <h5>
              {data.service_type != null
                ? Object.values(JSON.parse(data.service_type))[0] ==
                  "Special Education Paraprofessional"
                  ? t("text-paraprofessional")
                  : Object.values(JSON.parse(data.service_type))[0] ==
                    "Special Education Teacher"
                  ? t("sped-teacher")
                  : Object.values(JSON.parse(data.service_type))[0]
                : ""}
            </h5>
          </div>
          <div class="post">
            <img src={window.location.origin + "/img/post_pay.png"} alt="" />
            {profile && profile.country == "Serbia" ? (
              <h5>
                {data.nanyperhrrate != null
                  ? data.nanyperhrrate
                      .substr(data.nanyperhrrate.lastIndexOf("\\") + 1)
                      .split("-")[0] *
                      100 +
                    " - " +
                    data.nanyperhrrate
                      .substr(data.nanyperhrrate.lastIndexOf("\\") + 1)
                      .split("-")[1] *
                      100
                  : data.tutorperhrrate
                  ? data.tutorperhrrate
                      .substr(data.tutorperhrrate.lastIndexOf("\\") + 1)
                      .split("-")[0] *
                      100 +
                    " - " +
                    data.tutorperhrrate
                      .substr(data.tutorperhrrate.lastIndexOf("\\") + 1)
                      .split("-")[1] *
                      100
                  : ""}
                {t("time-hour")}
              </h5>
            ) : (
              <h5>
                $
                {data.nanyperhrrate != null
                  ? data.nanyperhrrate
                  : data.tutorperhrrate
                  ? data.tutorperhrrate
                  : ""}
                {t("time-hour")}
              </h5>
            )}
          </div>

          <div class="post_fet">
            <img src={window.location.origin + "/images/post_fet.svg"} alt="" />
            <h5>
              {data.tutorintrestedin != null
                ? data.tutorintrestedin
                : data.nanyintrestedin}
            </h5>
          </div>
          <div class="post_cal">
            <img src={window.location.origin + "/images/post_cal.svg"} alt="" />
            <h5>
              {data.tutorstartdate != null
                ? data.tutorstartdate
                : data.nanystartdate}
            </h5>
          </div>
          <div class="post_loc">
            <img src={window.location.origin + "/images/post_loc.svg"} alt="" />
            <h5>
              {data.country != null ? data.country : ""} ,
              {data.city != null ? data.city : ""}
            </h5>
          </div>
        </div>
      </div>
      <p id={"show" + index}>
        {data.description != null ? data.description.substr(0, 100) : ""}
        {data.description != null && data.description.length > 100 ? (
          <span
            onClick={(e) =>
              handleElementById("show" + index, "hide" + index, "plink" + index)
            }
          >
            {t("text-more")}
          </span>
        ) : (
          ""
        )}
      </p>
      <p id={"hide" + index} className="hide">
        {data.description}
        <span
          onClick={(e) =>
            handleElementById("hide" + index, "show" + index, "plink" + index)
          }
        >
          {t("text-less")}
        </span>
      </p>

      <div class="view_profile_btn newadd" style={{ float: "right" }}>
        <Link to={"/profile-parents/" + data.user_id}>
          {t("visit-profile")}
        </Link>
      </div>
    </div>
  );
};
