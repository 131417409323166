export const ParaprofessionalIcon = () => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="15" cy="15" r="15" fill="#fff" />
      <circle cx="15" cy="15" r="15" stroke="#fff" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.9361 7.71484C14.2024 7.71484 13.6075 8.30966 13.6075 9.04341C13.6075 9.77715 14.2024 10.372 14.9361 10.372C15.6699 10.372 16.2647 9.77715 16.2647 9.04341C16.2647 8.30966 15.6699 7.71484 14.9361 7.71484ZM19.5861 14.2911C19.5861 13.9656 19.3469 13.6999 19.0281 13.6468C18.1977 13.5073 17.4139 13.0622 16.8825 12.4777L16.0255 11.5277C15.9126 11.4015 15.7731 11.3019 15.6203 11.2288C15.617 11.2288 15.6153 11.2271 15.6137 11.2255C15.612 11.2238 15.6104 11.2222 15.607 11.2222H15.6004C15.3546 11.0827 15.0823 11.0162 14.77 11.0561C14.0925 11.1358 13.6075 11.7469 13.6075 12.4312V16.3504C13.6075 17.0811 14.2054 17.679 14.9361 17.679H18.2575V20.3361C18.2575 20.7015 18.5564 21.0004 18.9218 21.0004C19.2872 21.0004 19.5861 20.7015 19.5861 20.3361V17.3468C19.5861 16.6161 18.9882 16.0183 18.2575 16.0183H16.2647V13.7265C16.929 14.2779 17.859 14.7495 18.7889 14.9355C19.2008 15.0219 19.5861 14.7096 19.5861 14.2911ZM13.6075 19.6721C14.4778 19.6721 15.2151 19.1141 15.4875 18.3435H16.8625C16.557 19.8581 15.2151 21.0006 13.6075 21.0006C11.7741 21.0006 10.2861 19.5126 10.2861 17.6792C10.2861 16.0717 11.4287 14.7298 12.9433 14.4242V15.7993C12.1727 16.0783 11.6147 16.809 11.6147 17.6792C11.6147 18.7819 12.5048 19.6721 13.6075 19.6721Z"
        fill="#7D2B8B"
      />
    </svg>
  );
};

