import { create } from 'zustand'

export const useProfileStore = create((set) => ({
    detail: {},
    error: {
      phone: "",
    },
    isButton: false,
    otpVerify: "",
    isUserType: true,
    edit: '',

    setEdit: (newValue) => set({edit: newValue}),
    setDetail: (newDetail) => set({ detail: newDetail }),
    setError: (newError) => set({ error: newError }),
    setIsButton: (newValue) => set({ isButton: newValue }),
    setOtpVerify: (newValue) => set({ otpVerify: newValue }),
    setIsUserType: (newValue) => set({ isUserType: newValue }),
  }));