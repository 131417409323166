import React from "react";
import Modal from "react-bootstrap/Modal";
import { useTranslation } from "react-i18next";

export const DeletePostModal = ({ handleAccept, isDelete, setIsDelete }) => {
  const { t } = useTranslation();

  return (
    <Modal show={isDelete} onHide={(e) => setIsDelete(false)} className="">
      <Modal.Body>
        <div className="promocode_content changestatus">
          <h2>{t("close-job-post")}</h2>
          <p>
            <b>
              {t(
                "deactivate-profession-profile"
              )}
            </b>
          </p>
          <p>{t("continue-question")}</p>
          <button onClick={(e) => handleAccept()}>{t("confirm")}</button>
          <button onClick={(e) => setIsDelete(false)}>{t("objection")}</button>
        </div>
      </Modal.Body>
    </Modal>
  );
};
