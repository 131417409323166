import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { GUIDE_ITEMS } from "../../../utils/variables";
import { ServiceSection } from "../ServiceSection";


export const GuidSection = () => {
  const { t } = useTranslation();

  return (
    <>
      <div className="process_guid dask bgnon">
        <ul>
          {GUIDE_ITEMS.map((item, index) => (
            <li key={index}>
              <Link to="#">
                <img src={item.img} alt={t(item.text)} />
                <span>{t(item.text)}</span>
              </Link>
            </li>
          ))}
        </ul>
      </div>

      <ServiceSection />

      <div className="process_guid mobilenone mobile">
        <ul>
          {GUIDE_ITEMS.map((item, index) => (
            <li key={index}>
              <Link to="#">
                <img src={item.img} alt={t(item.text)} />
                <span>{t(item.text)}</span>
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </>
  );
};
