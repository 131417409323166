import React from "react";

export const LaunchIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.4444 1.71746C14.4444 3.14603 15.746 5.48253 15.746 8.12697C15.746 12.4698 12.2286 15.9905 7.88889 16C3.55556 16.0095 0.00635499 12.4762 5.79416e-06 8.13967C-0.00316881 5.49523 1.29842 3.15238 3.29524 1.72063C3.66667 1.45714 4.18413 1.56825 4.40635 1.96508L4.90794 2.85714C5.09524 3.19047 5.00635 3.61269 4.69841 3.84127C3.38095 4.81904 2.53969 6.36825 2.53969 8.1238C2.53651 11.054 4.90476 13.4603 7.87301 13.4603C10.7809 13.4603 13.2254 11.1047 13.2063 8.09205C13.1968 6.44761 12.4222 4.86031 11.0444 3.83809C10.7365 3.60952 10.6508 3.1873 10.8381 2.85714L11.3397 1.96508C11.5619 1.57143 12.0762 1.45397 12.4444 1.71746ZM9.14285 8.38094V0.761904C9.14285 0.339682 8.80317 0 8.38095 0H7.36508C6.94285 0 6.60317 0.339682 6.60317 0.761904V8.38094C6.60317 8.80316 6.94285 9.14285 7.36508 9.14285H8.38095C8.80317 9.14285 9.14285 8.80316 9.14285 8.38094Z"
        fill="#636363"
      />
    </svg>
  );
};
