import React, {useEffect} from "react";
import {Link, useLocation} from "react-router-dom";

import { useTranslation } from "react-i18next";
import { Footer } from "../../components/common/Footer";
import { Header } from "../../components/common/Header";

export function WelcomePage() {
  const useQuery = () => new URLSearchParams(useLocation().search);

  const { t} = useTranslation();

  return (
    <>
      <Header login={"true"} />
      <div className="container-fluid ">
        <div className="container">
          <div className="thank_page welcome">
            <h2>
              <span>{t("Success!")}</span> {t("Welcome On Board.")}
            </h2>
            <div className="thanks">
              <p>
                {t("You have successfully activated your account.")}
                <br />
                {t(
                  "fill-more-detail"
                )}
              </p>
              <Link to="/login">{t("log-in")} </Link>
            </div>
            <img src="./images/thank_banner.png" />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
