import create from "zustand";

export const useProviderFieldStore = create((set) => ({
  errorField: {
    nanyyearexp: "",
    smoke: "",
    carorlicence: "",
    cooking: "",
    lighthousework: "",
    traveling: "",
    workingabroad: "",
    nanyperhrrate: "",
    nanynewbornexp: "",
    livewithfamily: "",

    setyearexp: "",
    setexpmethods: "",
    seterhrrate: "",
    setexpiep: "",

    seperhrrate: "",
    sepexpmethods: "",
    sepworkexp: "",

    tutorexp: "",
    tutorperhrrate: "",
    tutorliketoteach: "",
    tutorintrestedonlinecls: "",

    nanyexpwithkid: "",
    sepexpwithkid: "",
    setallapplicable: "",
  },
  setErrorField: (field, value) =>
    set((state) => ({
      errorField: { ...state.errorField, [field]: value },
    })),

  detailProvider: {
    nanyyearexp: "",
    smoke: "",
    carorlicence: "",
    cooking: "",
    lighthousework: "",
    traveling: "",
    workingabroad: "",
    nanyperhrrate: "",
    nanynewbornexp: "",
    livewithfamily: "",

    setyearexp: "",
    setexpmethods: "",
    seterhrrate: "",
    setexpiep: "",

    seperhrrate: "",
    sepexpmethods: "",
    sepworkexp: "",

    tutorexp: "",
    tutorperhrrate: "",
    tutorliketoteach: "",
    tutorintrestedonlinecls: "",

    nanyexpwithkid: "",
    sepexpwithkid: "",
  },
  setDetailProvider: (field, value) =>
    set((state) => ({
      detailProvider: { ...state.detailProvider, [field]: value },
    })),
}));
