import React from "react";

export const FormSelect = ({ label, name, options, errorField, onChange }) => (
  <div className="form_group">
    <label>{label}</label>
    <select
      name={name}
      onChange={onChange}
      className={errorField[name] === "" ? "" : "bordererror"}
    >
      <option></option>
      {options.map((option, index) => (
        <option key={index} value={option.value}>
          {option.label}
        </option>
      ))}
    </select>
    <span className="errorfield">{errorField[name]}</span>
  </div>
);
