export const PersonIcon = () => {
  return (
    <svg viewBox="0 0 20 22" fill="" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.0049 0C12.7715 0 15.0049 2.23335 15.0049 5.00003C15.0049 7.76672 12.7715 10.0001 10.0049 10.0001C7.23822 10.0001 5.00488 7.76672 5.00488 5.00003C5.00488 2.23335 7.23822 0 10.0049 0Z"
        fill=""
      />
      <path
        d="M10 22.0009C5.83333 22.0009 2.15 19.8676 0 16.6343C0.05 13.3176 6.66667 11.501 10 11.501C13.3167 11.501 19.95 13.3176 20 16.6343C17.85 19.8676 14.1667 22.0009 10 22.0009Z"
        fill=""
      />
    </svg>
  );
};
