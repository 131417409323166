import React, {useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { getAllProviderCounts } from "../../../api/notificationApi";
import { CommentIcon } from "../../../assets/image/CommentIcon";
import { ListIcon } from "../../../assets/image/ListIcon";
import { PageListIcon } from "../../../assets/image/PageListIcon";
import { StarRoundIcon } from "../../../assets/image/StarRoundIcon";
import { GiftIcon } from "../../../assets/image/GiftIcon";
import { SquareServiceIcon } from "../../../assets/image/SquareServiceIcon";
import { ConfirmArrowIcon } from "../../../assets/image/ConfirmArrowIcon";

export function NotificationTabProvider(props) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [providerData, setProviderData] = useState({});

  useEffect(() => {
    const fetchProviderData = async () => {
      try {
        const token = localStorage.getItem("token");
        const result = await getAllProviderCounts(token);

        setProviderData(result);
      } catch (error) {
        console.error("Error fetching counts:", error);
      }
    };

    fetchProviderData();
  }, []);
  
  return (
    <>
      <ul>
        <li
          className={props.subtab == "interview-invite" ? "active" : ""}
          onClick={(e) => {
            window.scrollTo({ top: 0, behavior: "smooth" });
            props.setsubtab("interview-invite");
            navigate("/search-providers/interview-invite");
          }}
        >
          <CommentIcon />
          {t("interview-invites")} ({providerData.invitation})
        </li>
        <li
          className={props.subtab == "new-recommendation" ? "active" : ""}
          onClick={(e) => {
            window.scrollTo({ top: 0, behavior: "smooth" });
            props.setsubtab("new-recommendation");
            navigate("/search-providers/new-recommendation");
          }}
        >
           <ListIcon />
          {t("new-recommendations")} ({providerData.recommendation})
        </li>
        <li
          className={props.subtab == "document-request" ? "active" : ""}
          onClick={(e) => {
            window.scrollTo({ top: 0, behavior: "smooth" });
            props.setsubtab("document-request");
            navigate("/search-providers/document-request");
          }}
        >
          <PageListIcon />
          {t("document-requests")} ({providerData.documentrequest})
        </li>
        <li
          className={props.subtab == "hirings" ? "active" : ""}
          onClick={(e) => {
            window.scrollTo({ top: 0, behavior: "smooth" });
            props.setsubtab("hirings");
            navigate("/search-providers/hirings");
          }}
        >
          <ConfirmArrowIcon />
          {t("new-hirings")} ({providerData.hiring})
        </li>
        <li
          className={props.subtab == "new-reviews" ? "active" : ""}
          onClick={(e) => {
            window.scrollTo({ top: 0, behavior: "smooth" });
            props.setsubtab("new-reviews");
            navigate("/search-providers/new-reviews");
          }}
        >
          <StarRoundIcon />
          {t("new-reviews")} ({providerData.review})
        </li>
        <li
          className={props.subtab == "Loyalty-notification" ? "active" : ""}
          onClick={(e) => {
            window.scrollTo({ top: 0, behavior: "smooth" });
            props.setsubtab("Loyalty-notification");
            navigate("/search-providers/Loyalty-notification");
          }}
        >
          <GiftIcon color={"#636363"} />
          {t("senscare-loyalty")} ({providerData.loyalty})
        </li>
        <li
          className={props.subtab == "recent-profile-visitor" ? "active" : ""}
          onClick={(e) => {
            window.scrollTo({ top: 0, behavior: "smooth" });
            props.setsubtab("recent-profile-visitor");
            navigate("/search-providers/recent-profile-visitor");
          }}
        >
          <SquareServiceIcon width={14} height={14} color={"#636363"} />
          {t("recent-profile-visitors")} ({providerData.visitor})
        </li>
      </ul>
    </>
  );
}