import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";

import { useTranslation } from "react-i18next";
import { DATA_COUNTRY } from "../../utils/data";
import { submitContactForm } from "../../api";
import { validateField } from "../../utils/helperFunctions";

import { Footer } from "../../components/common/Footer";
import { Header } from "../../components/common/Header";

import styles from "./ContactPage.module.css";

export function ContactPage() {
  const { t } = useTranslation();

  const [showModal, setShowModal] = useState(false);
  const [countryNumber, setCountryNumber] = useState("");
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [formLogin, setFormLogin] = useState({
    email: "",
    firstName: "",
    lastName: "",
    phone: "",
    gender: "General",
    message: "",
  });
  const [errors, setErrors] = useState({
    email: "",
    firstName: "",
    lastName: "",
    phone: "",
    gender: "",
    message: "",
  });

  const handleFieldChange = (e) => {
    const { name, value } = e.target;
    const error = validateField(name, value, t);
    setErrors((prevErrors) => ({ ...prevErrors, [name]: error }));
    setFormLogin({ ...formLogin, [name]: value });
  };

  const handleCloseModal = () => {
    setShowModal(false);
    window.location.reload();
  };

  const handleSubmit = async () => {
    setButtonDisabled(true);

    const isValid =
      Object.values(errors).some((error) => error === "") &&
      Object.values(formLogin).every((value) => value !== "");
      
    if (isValid) {
      const formData = new FormData();
      formData.append("first_name", formLogin.firstName);
      formData.append("last_name", formLogin.lastName);
      formData.append("email", formLogin.email);
      formData.append("phone", countryNumber + formLogin.phone);
      formData.append("related_question", formLogin.gender);
      formData.append("message", formLogin.message);

      try {
        const result = await submitContactForm(formData);

        if (result.success) {
          setShowModal(true);
        } else {
          handleCloseModal();
        }
      } catch (error) {
        console.log("Error submitting form", error);
      } finally {
        setButtonDisabled(false);
      }
    } else {
      Object.keys(formLogin).forEach((key) => {
        const error = validateField(key, formLogin[key], t);
        setErrors((prevErrors) => ({ ...prevErrors, [key]: error }));
      });

      setButtonDisabled(false);
    }
  };

  return (
    <>
      <Header />
      <div className={styles.container_fluid}>
        <div className={styles.container}>
          <div className={styles.contact}>
            <h2>{t("contact-us-page.title")}</h2>
            <p>{t("contact-us-page.create")}</p>
            <h3>{t("greeting")}</h3>
            <div className={`${styles.form} ${styles.border}`}>
              <form>
                <div className={styles.form_group}>
                  <label>
                    {t("first-name")}
                    <span>*</span>
                  </label>
                  <input
                    type="text"
                    placeholder={t("type-placeholder")}
                    name="firstName"
                    onChange={handleFieldChange}
                    className={errors.firstName && styles.bordererror}
                  />
                  <span className={styles.errorfield}>{errors.firstName}</span>
                </div>
                <div className={styles.form_group}>
                  <label>
                    {t("last-name")}
                    <span>*</span>
                  </label>
                  <input
                    type="text"
                    placeholder={t("type-placeholder")}
                    name="lastName"
                    onChange={handleFieldChange}
                    className={errors.lastName && styles.bordererror}
                  />
                  <span className={styles.errorfield}>{errors.lastName}</span>
                </div>
                <div className={styles.form_group}>
                  <label>
                    {t("contact-us-page.email")}
                    <span>*</span>
                  </label>
                  <input
                    type="email"
                    placeholder={t("type-placeholder")}
                    name="email"
                    onChange={handleFieldChange}
                    className={errors.email && styles.bordererror}
                  />
                  <span className={styles.errorfield}>{errors.email}</span>
                </div>
                <div className={`${styles.form_group} ${styles.part2}`}>
                  <label>{t("telephone")}</label>
                  <div className={`${styles.citydetail} ${styles.small}`}>
                    <select onChange={(e) => setCountryNumber(e.target.value)}>
                      <option selected></option>
                      {DATA_COUNTRY.data.map((e) => (
                        <option value={e.dial_code}>{e.country}</option>
                      ))}
                    </select>
                  </div>
                  <div className={styles.citydetail}>
                    <input
                      type="number"
                      placeholder={t("type-placeholder")}
                      name="phone"
                      onChange={handleFieldChange}
                      className={errors.phone && styles.bordererror}
                      style={{ paddingLeft: countryNumber ? "32px" : "20px" }}
                    />
                    <div className={styles.errorfield}>{errors.phone}</div>
                    <span>{countryNumber}</span>
                  </div>
                </div>
                <div className={styles.form_group2}>
                  <label>{t("ask")}</label>
                  <select
                    name="gender"
                    onChange={handleFieldChange}
                    className={errors.gender && styles.bordererror}
                  >
                    <option>{t("general")}</option>
                    <option>{t("parents")}</option>
                    <option>{t("providers")}</option>
                    <option>{t("tech")}</option>
                  </select>
                  <span className={styles.errorfield}>{errors.gender}</span>
                </div>
                <div className={`${styles.form_group} ${styles.full}`}>
                  <label>
                    {t("contact-us-page.message")}
                    <span>*</span>
                  </label>
                  <textarea
                    rows={2}
                    placeholder={t("type-placeholder")}
                    maxLength="300"
                    name="message"
                    onChange={handleFieldChange}
                    className={errors.message && styles.bordererror}
                  ></textarea>
                  <div className={styles.errorfield}>{errors.message}</div>
                  <span>{t("number")}</span>
                </div>
              </form>
              <button
                type="submit"
                onClick={handleSubmit}
                disabled={buttonDisabled}
              >
                {t("submit")}
              </button>
            </div>
          </div>
        </div>
      </div>
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Dialog className={styles.modal_dialog}>
          <Modal.Body>
            <div
              className={`${styles.promocode_content} ${styles.younger} ${styles.contactpop}`}
            >
              <Link to="" onClick={handleCloseModal}>
                +
              </Link>
              <h5>{t("contact-us-thank")}</h5>
              <p>
                {t("message-senscare")}
                <br />
                {t("allow-up-24")}
              </p>
              <button onClick={handleCloseModal}>{t("text-ok")}</button>
            </div>
          </Modal.Body>
        </Modal.Dialog>
      </Modal>
      <Footer />
    </>
  );
}
