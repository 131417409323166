import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { useRatesStore } from "../../store/useRatesStore";

import { NannyIcon } from "../../assets/image/NannyIcon";
import { TeacherIcon } from "../../assets/image/TeacherIcon";
import { ParaprofessionalIcon } from "../../assets/image/ParaprofessionalIcon";
import { TutorIcon } from "../../assets/image/TutorIcon";
import { ArrowUpIcon } from "../../assets/image/ArrowUpIcon";

export const ProfileFilter = ({
  profile,
  handleSelectOption,
  handleDeleteOption,
  setChildren,
  selfProfile,
  category,
  handleElementDisplay,
  handleElementById,
  childrenAge,
  advanceSearch,
  isOpen,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [dataCategory, setDataCategory] = useState({
    data1: "",
    data2: "",
    data3: "",
    data4: "",
  });
  const [generalCategory, setGeneralCategory] = useState({
    data1: "",
    data2: "",
    data3: "",
    data4: "",
  });

  const [search, setsearch] = useState({
    jobcategory: "",
    keyword: "",
    city: "",
    zip: "",
    tutorintrestedin: "",
    tutorintrestedin1: "",
    tutorintrestedin2: "",
    tutorworkwithnochild: "",
    tutorprefchildage: "",
    rates: "",
    workingabroad: "",
    tutorintrestedinschool: "",
    carorlicence: "",
    withinweek: "",
    withinmonth: "",
    jobposted: "",
    distance: "",
  });

  const { nanyRate, setNanyRate } = useRatesStore();

  return (
    <div className="filter_box">
      <div className="filter_search">
        <div className="advance_search_filter">
          <div
            className={
              "advance_search_filter_input Profile_complete " +
              (!isOpen ? "op" : "")
            }
          >
            <span
              id="my-adv-filter"
              onClick={(e) => handleElementById("filterdata")}
            >
              {t("Advanced search")}
            </span>
            {advanceSearch != false ? (
              <div
                className="filters_search detail work-experience"
                id="filterdata"
                style={
                  selfProfile.about == "" ||
                  selfProfile.about == null ||
                  !localStorage.getItem("token") ||
                  !localStorage.getItem("id") ||
                  localStorage.getItem("refine2")
                    ? { display: "block" }
                    : { display: "none" }
                }
              >
                <div className="form_group full border qualification">
                  <label>{t("text-frequency")}</label>
                  <div className="checkbox create">
                    <ul>
                      <li>
                        <input
                          type="checkbox"
                          name=""
                          onClick={(e) => {
                            if (e.target.checked) {
                              setsearch({
                                ...search,
                                tutorintrestedin: "Full time",
                              });
                            } else {
                              setsearch({
                                ...search,
                                tutorintrestedin: "",
                              });
                            }
                          }}
                        />
                        <span> {t("full-time")}</span>
                      </li>
                      <li>
                        <input
                          type="checkbox"
                          name=""
                          onClick={(e) => {
                            if (e.target.checked) {
                              setsearch({
                                ...search,
                                tutorintrestedin1: "Part time",
                              });
                            } else {
                              setsearch({
                                ...search,
                                tutorintrestedin1: "",
                              });
                            }
                          }}
                        />
                        <span> {t("part-time")}</span>
                      </li>
                      <li>
                        <input
                          type="checkbox"
                          name=""
                          onClick={(e) => {
                            if (e.target.checked) {
                              setsearch({
                                ...search,
                                tutorintrestedin2: "Occasionally",
                              });
                            } else {
                              setsearch({
                                ...search,
                                tutorintrestedin2: "",
                              });
                            }
                          }}
                        />
                        <span> {t("occasionally")}</span>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="form_group full border">
                  <label>
                    {t("Hourly rate")}
                    {profile && profile.country == "Serbia" ? "(RSD)" : "(USD)"}
                  </label>
                  {profile && profile.country == "Serbia" ? (
                    <div className="wrapper">
                      <div className="container_slide">
                        <div className="slider-track"></div>
                        <input
                          type="range"
                          min="0"
                          max="5"
                          id="slider-1"
                          value={nanyRate.min / 10}
                          onChange={(e) => {
                            if (
                              (nanyRate.max > 0 ? nanyRate.max : 60) >
                              (e.target.value == 5 ? 60 : e.target.value * 10)
                            ) {
                              setNanyRate({
                                ...nanyRate,
                                min:
                                  e.target.value == 5
                                    ? 60
                                    : e.target.value * 10,
                              });
                            }
                          }}
                        />
                        <input
                          type="range"
                          min="0"
                          max="5"
                          id="slider-2"
                          value={
                            nanyRate.max == 0 ? 50 / 10 : nanyRate.max / 10
                          }
                          onChange={(e) => {
                            if (
                              nanyRate.min <
                              (e.target.value == 5 ? 60 : e.target.value * 10)
                            ) {
                              setNanyRate({
                                ...nanyRate,
                                max:
                                  e.target.value == 5
                                    ? 60
                                    : e.target.value * 10,
                              });
                            }
                          }}
                        />
                      </div>
                      <div className="slider two">
                        <ul>
                          <li>0</li>
                          <li>1000</li>
                          <li>2000</li>
                          <li>3000</li>
                          <li>4000</li>
                          <li>6000+</li>
                        </ul>
                      </div>
                    </div>
                  ) : (
                    <div className="wrapper">
                      <div className="container_slide">
                        <div className="slider-track"></div>
                        <input
                          type="range"
                          min="0"
                          max="5"
                          id="slider-1"
                          value={nanyRate.min / 10}
                          onChange={(e) => {
                            if (
                              (nanyRate.max > 0 ? nanyRate.max : 60) >
                              (e.target.value == 5 ? 60 : e.target.value * 10)
                            ) {
                              setNanyRate({
                                ...nanyRate,
                                min:
                                  e.target.value == 5
                                    ? 60
                                    : e.target.value * 10,
                              });
                            }
                          }}
                        />
                        <input
                          type="range"
                          min="0"
                          max="5"
                          id="slider-2"
                          value={
                            nanyRate.max == 0 ? 50 / 10 : nanyRate.max / 10
                          }
                          onChange={(e) => {
                            if (
                              nanyRate.min <
                              (e.target.value == 5 ? 60 : e.target.value * 10)
                            ) {
                              setNanyRate({
                                ...nanyRate,
                                max:
                                  e.target.value == 5
                                    ? 60
                                    : e.target.value * 10,
                              });
                            }
                          }}
                        />
                      </div>
                      <div className="slider two">
                        <ul>
                          <li>0</li>
                          <li>10</li>
                          <li>20</li>
                          <li>30</li>
                          <li>40</li>
                          <li>60+</li>
                        </ul>
                      </div>
                    </div>
                  )}
                  <span className="price">
                    {profile && profile.country == "Serbia"
                      ? nanyRate.min * 100 + " - " + nanyRate.max * 100
                      : nanyRate.min + " - " + nanyRate.max}
                  </span>
                  {/* <div className='errorfield'>{error.message}</div> */}
                </div>

                <div className="form_group full border">
                  <label>{t("Number of children")}</label>
                  <div className="children_number">
                    <ul>
                      <li
                        onClick={(e) => {
                          if (generalCategory.data1 == "") {
                            setGeneralCategory({
                              ...generalCategory,
                              data1: 1,
                            });
                            setChildren(1);
                          } else {
                            setGeneralCategory({
                              ...generalCategory,
                              data1: "",
                            });
                            setChildren("");
                          }
                        }}
                        className={generalCategory.data1 == 1 ? "active" : ""}
                      >
                        1
                      </li>
                      <li
                        onClick={(e) => {
                          if (generalCategory.data2 == "") {
                            setGeneralCategory({
                              ...generalCategory,
                              data2: 2,
                            });
                            setChildren(2);
                          } else {
                            setGeneralCategory({
                              ...generalCategory,
                              data2: "",
                            });
                            setChildren("");
                          }
                        }}
                        className={generalCategory.data2 == 2 ? "active" : ""}
                      >
                        2
                      </li>
                      <li
                        onClick={(e) => {
                          if (generalCategory.data3 == "") {
                            setGeneralCategory({
                              ...generalCategory,
                              data3: "twins",
                            });
                            setChildren("twins");
                          } else {
                            setGeneralCategory({
                              ...generalCategory,
                              data3: "",
                            });
                            setChildren("");
                          }
                        }}
                        className={
                          generalCategory.data3 == "twins" ? "active" : ""
                        }
                      >
                        {t("text-twins")}
                      </li>
                      <li
                        onClick={(e) => {
                          if (generalCategory.data4 == "") {
                            setGeneralCategory({
                              ...generalCategory,
                              data4: "3",
                            });
                            setChildren(3);
                          } else {
                            setGeneralCategory({
                              ...generalCategory,
                              data4: "",
                            });
                            setChildren("");
                          }
                        }}
                        className={generalCategory.data4 == 3 ? "active" : ""}
                      >
                        3+
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="job_performance">
                  <div className="form_group   full border">
                    <label>Child’s age</label>
                    <div className="customselect inp">
                      <input
                        className="keyword"
                        type="text"
                        placeholder={t("choose-list")}
                        value={childrenAge.map((e) => {
                          return e.name;
                        })}
                      />
                      <div
                        className="overflow"
                        id="over2"
                        onClick={(e) => handleElementDisplay("cate5", "over2")}
                      ></div>
                      <div className="option" id="cate5">
                        <p>
                          <input
                            type="checkbox"
                            onClick={(a) => handleDeleteOption(t("years-0-1"))}
                          />
                          <h3>{t("years-0-1")} </h3>
                          <span></span>
                        </p>
                        <p>
                          <input
                            type="checkbox"
                            onClick={(a) => handleDeleteOption(t("years-4-7"))}
                          />
                          <h3>{t("years-4-7")} </h3>
                          <span></span>
                        </p>
                        <p>
                          <input
                            type="checkbox"
                            onClick={(a) => handleDeleteOption(t("years-8-10"))}
                          />
                          <h3>{t("years-8-10")} </h3>
                          <span></span>
                        </p>
                        <p>
                          <input
                            type="checkbox"
                            onClick={(a) =>
                              handleDeleteOption(t("years-11-15"))
                            }
                          />
                          <h3>{t("years-11-15")} </h3>
                          <span></span>
                        </p>
                        <p>
                          <input
                            type="checkbox"
                            onClick={(a) => handleDeleteOption(t("years-16"))}
                          />
                          <h3>{t("years-16")} </h3>
                          <span></span>
                        </p>
                      </div>
                      <span
                        onClick={(e) => handleElementDisplay("cate5", "over2")}
                      >
                        <ArrowUpIcon />
                      </span>
                    </div>
            
                  </div>
                </div>

                <div className="form_group full border qualification">
                  <label>{t("start-date")}</label>
                  <div className="checkbox create">
                    <ul>
                      <li>
                        <input
                          type="checkbox"
                          name="b"
                          onClick={(e) => {
                            setsearch({
                              ...search,
                              withinweek: e.target.checked,
                            });
                          }}
                        />
                        <span> {t("Within a week")}</span>
                      </li>
                      <li>
                        <input
                          type="checkbox"
                          name="b"
                          onClick={(e) => {
                            setsearch({
                              ...search,
                              withinmonth: e.target.checked,
                            });
                          }}
                        />
                        <span> {t("Within a month")}</span>
                      </li>
                    </ul>
                  </div>
                  {/* <div className='errorfield'>{error.message}</div> */}
                </div>
                <div className="form_group full qualification">
                  <label>{t("Other options")}</label>
                  <div className="checkbox create">
                    <ul>
                      <li>
                        <input
                          type="checkbox"
                          name="b"
                          onClick={(e) => {
                            setsearch({
                              ...search,
                              carorlicence: e.target.checked,
                            });
                          }}
                        />
                        <span> {t("Without transportation")}</span>
                      </li>
                      <li>
                        <input
                          type="checkbox"
                          name="b"
                          onClick={(e) => {
                            setsearch({
                              ...search,
                              jobposted: e.target.checked,
                            });
                          }}
                        />
                        <span> {t("Jobs posted within a week")}</span>
                      </li>
                      <li>
                        <input
                          type="checkbox"
                          name="b"
                          onClick={(e) => {
                            if (e.target.checked) {
                              setsearch({
                                ...search,
                                workingabroad: "Yes",
                              });
                            } else {
                              setsearch({
                                ...search,
                                workingabroad: "",
                              });
                            }
                          }}
                        />
                        <span> {t("Work abroad")}</span>
                      </li>
                      <li>
                        <input
                          type="checkbox"
                          name="b"
                          onClick={(e) => {
                            if (e.target.checked) {
                              setsearch({
                                ...search,
                                tutorintrestedinschool: "Yes",
                              });
                            } else {
                              setsearch({
                                ...search,
                                tutorintrestedinschool: "",
                              });
                            }
                          }}
                        />
                        <span> {t("School jobs")}</span>
                      </li>
                    </ul>
                  </div>
                  {/* <div className='errorfield'>{error.message}</div> */}
                </div>
                <button
                  onClick={(e) => {
                    localStorage.setItem("refine2", "true");
                    setTimeout(() => {
                      window.location.reload();
                    }, 300);
                  }}
                >
                  {t("Refine result")}
                </button>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
        <div className="filter_category Profile_complete ">
          <div className="filter_category_select detail work-experience">
            <div className="job_performance">
              <div className="form_group   full">
                <div className="customselect inp">
                  <input
                    id="my-fil-cat"
                    className="keyword"
                    type="text"
                    placeholder={t("Select job category")}
                    value={category.map((e) => {
                      return e.name;
                    })}
                  />
                  <div
                    className="overflow"
                    id="over3"
                    onClick={(e) => handleElementDisplay("cate6", "over3")}
                  ></div>
                  <div className="option" id="cate6">
                    <p>
                      <input
                        type="checkbox"
                        onClick={(a) => {
                          handleSelectOption("Nanny");
                          if (a.target.checked) {
                            setDataCategory({
                              ...dataCategory,
                              data1: "Nanny",
                            });
                          } else {
                            setDataCategory({
                              ...dataCategory,
                              data1: "",
                            });
                          }
                        }}
                      />
                      <h3>
                        <NannyIcon />
                        {t("text-nanny")}
                      </h3>
                      <span></span>
                    </p>
                    <p>
                      <input
                        type="checkbox"
                        onClick={(a) => {
                          handleSelectOption("Special education teacher");
                          if (a.target.checked) {
                            setDataCategory({
                              ...dataCategory,
                              data2: "Special education teacher",
                            });
                          } else {
                            setDataCategory({
                              ...dataCategory,
                              data2: "",
                            });
                          }
                        }}
                      />
                      <h3>
                        <TeacherIcon />
                        {t("spec-education-teacher")}
                      </h3>
                      <span></span>
                    </p>
                    <p>
                      <input
                        type="checkbox"
                        onClick={(a) => {
                          handleSelectOption(
                            "Special education paraprofessional"
                          );
                          if (a.target.checked) {
                            setDataCategory({
                              ...dataCategory,
                              data3: "Special education paraprofessional",
                            });
                          } else {
                            setDataCategory({
                              ...dataCategory,
                              data3: "",
                            });
                          }
                        }}
                      />
                      <h3>
                        <ParaprofessionalIcon />
                        {t("spec-education-paraprofessional")}
                      </h3>
                      <span></span>
                    </p>
                    <p>
                      <input
                        type="checkbox"
                        onClick={(a) => {
                          handleSelectOption("Tutor");
                          if (a.target.checked) {
                            setDataCategory({
                              ...dataCategory,
                              data4: "Tutor",
                            });
                          } else {
                            setDataCategory({
                              ...dataCategory,
                              data4: "",
                            });
                          }
                        }}
                      />
                      <h3>
                        <TutorIcon />
                        {t("text-tutor")}
                      </h3>
                      <span></span>
                    </p>
                  </div>
                  <span onClick={(e) => handleElementDisplay("cate6", "over3")}>
                    <ArrowUpIcon color={"#7D2B8B"} />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="city">
          <input
            type="text"
            placeholder={t("text-city")}
            id="my-fil-city"
            onChange={(e) => setsearch({ ...search, city: e.target.value })}
          />
        </div>
        <div className="zip code">
          <input
            type="text"
            placeholder={t("zip-code")}
            id="my-fil-zip"
            onChange={(e) => setsearch({ ...search, zip: e.target.value })}
          />
        </div>
        <div className="distance">
          <div className="distance_input">
            <select
              name=""
              id="my-fil-dis"
              onChange={(e) =>
                setsearch({ ...search, distance: e.target.value })
              }
            >
              <option value="" selected>
                {t("Distance")}
              </option>
              <option value={5}>5 {t("Miles")}</option>
              <option value={10}>10 {t("Miles")}</option>
              <option value={15}>15 {t("Miles")}</option>
              <option value={20}>20 {t("Miles")}</option>
              <option value={25}>25 {t("Miles")}</option>
              <option value={30}>30 {t("Miles")}</option>
              <option value={35}>35 {t("Miles")}</option>
              <option value={40}>40 {t("Miles")}</option>
              <option value={45}>45 {t("Miles")}</option>
              <option value={50}>50 {t("Miles")}</option>
            </select>
          </div>
        </div>
      </div>
      <div className="search_box">
        <div className="search_input">
          <button
            id="my-fil-search"
            type="submit"
            onClick={(e) => {
              navigate("/search-parents");
              localStorage.removeItem("search");
              localStorage.removeItem("search2");
              localStorage.setItem(
                "filter",
                JSON.stringify({
                  category: category,
                  distance: search.distance,
                  zip: search.zip,
                  city: search.city,
                  cat: dataCategory,
                })
              );
            }}
          >
            {t("text-search")}
          </button>
        </div>
      </div>
    </div>
  );
};
