import { useTranslation } from "react-i18next";

import { useErrorFieldStore } from "../../store/useErrorsFieldsStore";
import { useDetailParentsStore } from "../../store/useDetailParentsStore";
import { useProfileStore } from "../../store/useProfileStore";
import { UploadIcon } from "../../assets/image/UploadIcon";
import { useControlsStore } from "../../store/useControlsStore";
import { ABOUT_FIELDS } from "../../utils/variables";
import { FormGroup } from "../../components/common/FormGroup";
import { saveToLocalStorage } from "../../utils/helperFunctions";

export function StepOneContent({ updateProfile, afterLoginsField }) {
  const { t } = useTranslation();
  const [setMobile, setStep, setProcess] = useControlsStore((state) => [
    state.setMobile,
    state.setStep,
    state.setProcess
  ]);
  const [errorField, setErrorField] = useErrorFieldStore((state) => [
    state.errorField,
    state.setErrorField,
  ]);
  const [detailParents, setDetailParents] = useDetailParentsStore((state) => [
    state.detailParents,
    state.setDetailParents,
  ]);

  const handleDetailParents = (e) => {
      setDetailParents({
          file_path: e.target.files[0],
      });
  }

  const detail = useProfileStore((state) => state.detail);

  return (
    <div className="detail parents1 stap1">
      <h2 className="border">
        {t("text-welcome")}
        <span>{detail.first_name + " " + detail.last_name}</span>,
        {" " + t("complete-profile")}
      </h2>
      <span>{t("required-fields")}</span>
      <FormGroup
        label={t("about-yourself")}
        required
        isError={errorField.about && errorField.about !== ""}
        className={"full"}
      >
        <textarea
          className={errorField.about && errorField.about !== "" ? "bordererror" : ""}
          rows={2}
          placeholder={t("post-activities")}
          maxlength="150"
          name="message"
          defaultValue={detailParents.about}
          onChange={(e) => {
            setDetailParents({
              about: e.target.value,
            });
            setErrorField({ about: "" });
          }}
        />
        <span>
          {t("number-characters")} {150 - detailParents?.about?.length}
        </span>
      </FormGroup>

      <FormGroup
        className={"second"}
        label={
          <>
            {t("upload-profile")} <a href="#blank">{t("picture")} </a>
            <span className="smallpop">
              <strong>{t("text-premium")}</strong> {t("upload-photo")}
            </span>
          </>
        }
        isError={errorField.file_path && errorField.file_path !== ""}
        required
      >
        <div className="upload">
          <input
            type="file"
            placeholder={t("choose-file")}
            onChange={(e) => {
              setErrorField({ file_path: "" });
              handleDetailParents(e)
            }}
            accept="image/*"
          />
          <span className={errorField.file_path && errorField.file_path !== "" ? "bordererror" : ""}>
              {
                  typeof detailParents.file_path === 'object' ?
                      detailParents.file_path.name :
                      (detailParents.file_path != "" ? detailParents.file_path : t("choose-file"))
              }
          </span>
          <button>
            <UploadIcon />
          </button>
        </div>
      </FormGroup>

      <FormGroup
        className={"third"}
        label={
          <>
            {t("upload-short")} <a href="#blank">{t("text-video")} </a>
            <span className="smallpop">
              {t("feature-available")} <strong>{t("text-premium ")}</strong>
              {t("members")}.
            </span>
            {t("family-yourself")}
          </>
        }
      >
        <div className="upload">
          <input
            type="file"
            placeholder={t("choose-file")}
            onChange={(e) =>
              setDetailParents({
                video: e.target.files[0],
              })
            }
            accept="video/*"
          />
          <span>
            {detailParents.video && detailParents.video.name
              ? detailParents.video.name
              : t("choose-file")}
          </span>
          <button>
            <UploadIcon />
          </button>
        </div>
      </FormGroup>

      <div className="button">
        <button
          onClick={(e) => {
            if (detailParents.about !== "" && detailParents.file_path !== "") {
              setMobile(-12);
              setStep(2);
              updateProfile(false);
              setProcess(26);
              saveToLocalStorage("parentsDetail", detailParents);
              window.scrollTo({ top: 0 });
            } else {
              ABOUT_FIELDS.forEach((e) => {
                afterLoginsField(e.name);
              });
            }
          }}
        >
          {t("text-next")}
        </button>
      </div>
    </div>
  );
}
