import React from "react";
import { useTranslation } from "react-i18next";

import { LandingListItem } from "../../components/landingPage/LandingListItem";
import { Header } from "../../components/landingPage/header";
import { Footer } from "../../components/landingPage/footer";

import { PARENT_ITEMS } from "../../utils/variables";

import styles from "./LandingParentsPage.module.css";

export function LandingParentsPage() {
  const { t } = useTranslation();

  return (
    <>
      <Header />
      <div className={`${styles.container_fluid} ${styles.border}`}>
        <div className={styles.container}>
          <div className={styles.thank_page}>
            <h2>{t("post-thanks-join")} </h2>

            <div className={styles.thanks}>
              <h4>{t("together-create-future")}</h4>

              <ul>
                {PARENT_ITEMS.map((item, index) => {
                  const hasParentheses = item.includes("(");
                  if (hasParentheses) {
                    const parts = item.split("(");

                    return (
                      <LandingListItem
                        key={index}
                        text={parts[0]}
                        secondaryText={parts[1]}
                      />
                    );
                  }

                  return <LandingListItem key={index} text={item} />;
                })}
              </ul>

              <h3>{t("post-coming-soon")}</h3>
            </div>
            <img src="./images/thank_banner.png" />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
