import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { useAdditionalDetailsStore } from "../../store/useAdditionalDetailsStore";
import { DATA_COUNTRY } from "../../utils/data";
import { PayIcon } from "../../assets/image/PayIcon";

export const MembershipSelectPlan = ({
  generalCompletePrice,
  membershipPlan,
  handleMembershipUpdate,
  completePrice,
  detail,
}) => {
  const { t } = useTranslation();

  const [paymentType, setPaymentType] = useState(false);
  const [savePayment, setSavePayment] = useState(false);

  const { setStep, month, country, setCountry } = useAdditionalDetailsStore();

  return (
    <div className="step2">
      <div class="selected_plan">
        <div class="container">
          <div class="selected_page_content">
            <h3>
              {t("You Selected:")} <strong>{membershipPlan}</strong>
            </h3>
            <div class="change_plan_btn">
              <button
                onClick={(e) => {
                  window.scrollTo({ top: 0 });
                  setStep(1);
                }}
              >
                {t("Change Plan")}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="total_amnt">
        <div class="container">
          <div class="total_amnt_content">
            <h3>
              {t("Total Amount To Pay:")}
              <s>
                {detail.country == "Serbia" ? (
                  <>
                    <PayIcon width={12} height={10} />
                    {generalCompletePrice * 100}
                  </>
                ) : (
                  "$" + generalCompletePrice.toFixed(2)
                )}
              </s>
              <span>
                {detail.country == "Serbia" ? (
                  <>
                    <PayIcon width={12} height={10} />
                    {completePrice * 100}
                  </>
                ) : (
                  "$" + completePrice.toFixed(2)
                )}
              </span>
            </h3>
            <h4>
              {t("current-discount-offers")}
              {membershipPlan == "Swan"
                ? 15
                : membershipPlan == "Flamingo"
                ? 20
                : 100}
              % {t("now")}.
            </h4>
            <ul>
              <li>
                {t(
                  "subscription-automatically-date"
                )}
              </li>
              <li>
                {t("local-taxes-currency-conversions")}
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="container">
        <div class="payment_details">
          <h3>{t("payment-details")}</h3>
          {paymentType == false ? (
            <>
              <div class="payment_cards">
                <button>
                  <img src="img/mastercard.png" alt="" />
                </button>
                <button>
                  <img src="img/maestro.png" alt="" />
                </button>
                <button>
                  <img src="img/american_exp.png" alt="" />
                </button>
                <button>
                  <img
                    src="img/paypal.png"
                    alt=""
                    onClick={() => setPaymentType(!paymentType)}
                  />
                </button>
              </div>
            </>
          ) : (
            ""
          )}
        </div>
        {paymentType == false ? (
          <>
            <div class="payment_form">
              <form action="">
                <div class="row">
                  <div class="col">
                    <div class="form-group">
                      <label class="form-label" for="name">
                        {t("name-card")}
                      </label>
                      <input
                        type="text"
                        id="name"
                        placeholder={t("text-type")}
                      />
                    </div>
                  </div>
                  <div class="col">
                    <div class="form-group">
                      <label class="form-label" for="card">
                        {t("card-number")}
                      </label>
                      <input
                        type="tel"
                        id="card"
                        placeholder={t("text-type")}
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <div class="form-group">
                      <label class="form-label" for="address">
                        {t("bild-address")}
                      </label>
                      <input
                        type="text"
                        id="address"
                        placeholder={t("text-type")}
                      />
                    </div>
                  </div>
                  <div class="col">
                    <div class="row exp_Sec">
                      <div class="col exp_sec">
                        <div class="form-group">
                          <label class="form-label" for="date">
                            {t("expiration-date")}
                          </label>
                          <input
                            type="date"
                            id="date"
                            placeholder={t("text-type")}
                          />
                        </div>
                      </div>
                      <div class="col exp_sec">
                        <div class="form-group">
                          <label class="form-label" for="security">
                            {t("Security #")}
                          </label>
                          <input
                            type="tel"
                            id="security"
                            placeholder={t("text-type")}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <div class="row">
                      <div class="col">
                        <div class="form-group">
                          <label class="form-label" for="country">
                            {t("Country")}
                          </label>
                          <select
                            name=""
                            id=""
                            onChange={(e) => setCountry(e.target.value)}
                          >
                            <option selected>Choose from list</option>
                            {DATA_COUNTRY.data.map((e) => {
                              return (
                                <option value={e.country}>{e.country}</option>
                              );
                            })}
                          </select>
                        </div>
                      </div>

                      <div class="col">
                        {country != "Serbia" ? (
                          <div class="form-group">
                            <label class="form-label" for="state">
                              {t("State")}
                            </label>
                            <input
                              type="email"
                              id="email"
                              placeholder={t("text-type")}
                            />
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                  <div class="col">
                    <div class="form-group">
                      <label class="form-label" for="email">
                        {t("email-address")}
                      </label>
                      <input
                        type="email"
                        id="email"
                        placeholder={t("text-type")}
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <div class="row city_zip">
                      <div class="col city_zip">
                        <div class="form-group">
                          <label class="form-label" for="city">
                            {t("text-city")}
                          </label>
                          <input
                            type="text"
                            id="city"
                            placeholder={t("text-type")}
                          />
                        </div>
                      </div>
                      <div class="col city_zip">
                        <div class="form-group">
                          <label class="form-label" for="zip">
                            {t("Zip Code")}
                          </label>
                          <input
                            type="tel"
                            id="zip"
                            placeholder={t("text-type")}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col">
                    <div class="form-group">
                      <div class="radio">
                        <input
                          type="checkbox"
                          checked={savePayment}
                          onClick={(e) => setSavePayment()}
                        />
                        <label>{t("save-card-future-payments")}</label>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </>
        ) : (
          <div className="Account ">
            <div className={"personal"}>
              <div className="cardstype" style={{ margin: "0" }}>
                <div className="paymenttype">
                  <ul>
                    <li>
                      <input type="radio" id="a" name="a" />
                      <label for="a">{t("Pay with card")}</label>
                    </li>
                    <li>
                      <input type="radio" id="b" name="a" />
                      <label
                        for="b"
                        onClick={() => setPaymentType(!paymentType)}
                      >
                        {t("Pay with PayPal")}
                      </label>
                    </li>
                  </ul>
                </div>
                <h4>{t("My Cards")}</h4>
                <div className="savecard" style={{ margin: "0" }}>
                  <ul>
                    <li>
                      <label for="c">
                        
                        <input type="radio" id="c" name="c" />
                        <span>{t("Use for payment")}</span>
                      </label>
                      <span>{t("Card name")}</span>
                      <p>{t("Bankname card ****4589")}</p>
                      <span>{t("expires-on")}</span>
                      <p>04/2022</p>
                      <button style={{ textAlign: "right" }}>
                        {t("Edit")}
                      </button>
                    </li>
                    <li>
                      <label for="d">
                        
                        <input type="radio" id="d" name="c" />
                        <span>{t("Use for payment")}</span>
                      </label>
                      <span>{t("Card name")}</span>
                      <p>{t("Bankname card ****4589")}</p>
                      <span>{t("expires-on")}</span>
                      <p>04/2022</p>
                      <button style={{ textAlign: "right" }}>
                        {t("Edit")}
                      </button>
                    </li>
                    <li>
                      <label for="e">
                        
                        <input type="radio" id="e" name="c" />
                        <span>{t("Use for payment")}</span>
                      </label>
                      <span>{t("Card name")}</span>
                      <p>{t("Bankname card ****4589")}</p>
                      <span>{t("expires-on")}</span>
                      <p>04/2022</p>
                      <button style={{ textAlign: "right" }}>
                        {t("Edit")}
                      </button>
                    </li>
                    <li>
                      <label for="e">
                        
                        <input type="radio" id="e" name="c" />
                        <span>{t("Use for payment")}</span>
                      </label>
                      <span>{t("Card name")}</span>
                      <p>{t("Bankname card ****4589")}</p>
                      <span>{t("expires-on")}</span>
                      <p>04/2022</p>
                      <button style={{ textAlign: "right" }}>Edit</button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        )}
        <div class="promotion">
          <div class="promotion_code">
            <label for="">{t("add-promotion-code")}</label>
            <input type="text" placeholder="ngsklf4875d" />
            <button>{t("apply-code")}</button>
          </div>
        </div>
        <div class="pay_aggrement">
          <input type="checkbox" />
          <label for="">
            {t("agree-pay")}
            {detail.country == "Serbia" ? (
              <>
                <PayIcon width={12} height={10} />
                {(completePrice / month).toFixed(2) * 100}
              </>
            ) : (
              "$" + (completePrice / month).toFixed(2)
            )}
            {t("monthly-for")} {month > 0 ? month : ""}
            {month == 1 ? "month " : "months "}
            {t("subscription")}.
          </label>
        </div>
        <div class="form_submit_button">
          <div class="button">
            <button
              class="can"
              onClick={(e) => {
                setStep(1);
              }}
            >
              {t("text-back")}
            </button>
            <button class="sub" onClick={(e) => handleMembershipUpdate()}>
              {t("text-submit")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
