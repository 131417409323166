export const PowerIcon = () => {
  return (
    <svg
      width="30"
      height="21"
      viewBox="0 0 30 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M30 12.8897V17.9073C30 19.2929 28.8807 20.4161 27.5 20.4161H2.5C1.11927 20.4161 0 19.2929 0 17.9073V12.8897C0 11.5041 1.11927 10.3809 2.5 10.3809H27.5C28.8807 10.3809 30 11.5041 30 12.8897ZM27.5 8.70838C28.0503 8.7079 28.5953 8.81743 29.103 9.0306L24.0755 1.46285C23.8472 1.11921 23.5379 0.837442 23.175 0.642561C22.8121 0.447679 22.4069 0.34571 21.9954 0.345703H8.00463C7.59307 0.345704 7.18787 0.447672 6.82501 0.642554C6.46214 0.837437 6.15283 1.11921 5.92453 1.46285L0.897031 9.0306C1.40473 8.81743 1.94966 8.7079 2.5 8.70838H27.5ZM25 13.726C24.0795 13.726 23.3333 14.4748 23.3333 15.3985C23.3333 16.3222 24.0795 17.0711 25 17.0711C25.9205 17.0711 26.6667 16.3222 26.6667 15.3985C26.6667 14.4748 25.9205 13.726 25 13.726ZM20 13.726C19.0795 13.726 18.3333 14.4748 18.3333 15.3985C18.3333 16.3222 19.0795 17.0711 20 17.0711C20.9205 17.0711 21.6667 16.3222 21.6667 15.3985C21.6667 14.4748 20.9205 13.726 20 13.726Z"
        fill="#B7B7B7"
      />
    </svg>
  );
};
