import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { useCategoryStore } from "../../store/useCategoryStore";

import { NannyIcon } from "../../assets/image/NannyIcon";
import { TeacherIcon } from "../../assets/image/TeacherIcon";
import { ParaprofessionalIcon } from "../../assets/image/ParaprofessionalIcon";
import { TutorIcon } from "../../assets/image/TutorIcon";
import { ArrowUpIcon } from "../../assets/image/ArrowUpIcon";

export const PerfectJob = ({
  handleElementDisplay,
  handleProviderData,
  handleSelectCategory,
  handleShowProviderModal,
  setType,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { selectCategory, setSelectCategory, category } = useCategoryStore();

  return (
    <div className="monile_search">
      <h2>{t("Find perfect job")}</h2>
      <div className="filter_category Profile_complete ">
        <div className="filter_category_select detail work-experience">
          <div className="job_performance">
            <div className="form_group   full">
              <div className="customselect inp">
                <input
                  id="my-fil-cat"
                  className="keyword"
                  type="text"
                  placeholder={t("select")}
                  value={category.map((e) => {
                    return e.name;
                  })}
                />
                <div
                  className="overflow"
                  id="over4"
                  onClick={(e) => handleElementDisplay("cate7", "over4")}
                ></div>
                <div className="option" id="cate7">
                  <p>
                    <input
                      type="checkbox"
                      onClick={(a) => {
                        handleSelectCategory("Nanny");
                        if (a.target.checked) {
                          setSelectCategory({
                            ...selectCategory,
                            data1: "Nanny",
                          });
                        } else {
                          setSelectCategory({
                            ...selectCategory,
                            data1: "",
                          });
                        }
                      }}
                    />
                    <h3>
                      <NannyIcon />
                      {t("text-nanny")}
                    </h3>
                    <span></span>
                  </p>
                  <p>
                    <input
                      type="checkbox"
                      onClick={(a) => {
                        handleSelectCategory("Special education teacher");
                        if (a.target.checked) {
                          setSelectCategory({
                            ...selectCategory,
                            data2: "Special education teacher",
                          });
                        } else {
                          setSelectCategory({
                            ...selectCategory,
                            data2: "",
                          });
                        }
                      }}
                    />
                    <h3>
                      <TeacherIcon />
                      {t("spec-education-teacher")}
                    </h3>
                    <span></span>
                  </p>
                  <p>
                    <input
                      type="checkbox"
                      onClick={(a) => {
                        handleSelectCategory(
                          "Special education paraprofessional"
                        );
                        if (a.target.checked) {
                          setSelectCategory({
                            ...selectCategory,
                            data3: "Special education paraprofessional",
                          });
                        } else {
                          setSelectCategory({
                            ...selectCategory,
                            data3: "",
                          });
                        }
                      }}
                    />
                    <h3>
                      <ParaprofessionalIcon />
                      {t("spec-education-paraprofessional")}
                    </h3>
                    <span></span>
                  </p>
                  <p>
                    <input
                      type="checkbox"
                      onClick={(a) => {
                        handleSelectCategory("Tutor");
                        if (a.target.checked) {
                          setSelectCategory({
                            ...selectCategory,
                            data4: "Tutor",
                          });
                        } else {
                          setSelectCategory({
                            ...selectCategory,
                            data4: "",
                          });
                        }
                      }}
                    />
                    <h3>
                      <TutorIcon />
                      {t("text-tutor")}
                    </h3>
                    <span></span>
                  </p>
                </div>
                <span onClick={(e) => handleElementDisplay("cate7", "over4")}>
                  <ArrowUpIcon color={"#7D2B8B"} />
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="searchbutton">
        <button onClick={(e) => handleShowProviderModal()}>
          <img src="/images/filter_icon.svg" />
          {t("Advanced search")}
        </button>

        <button
          onClick={(e) => {
            localStorage.getItem("user_type") == "parents"
              ? navigate("/search-providers")
              : navigate("/search-parents");
            setType("");
            localStorage.removeItem("searchdataprovider");
            handleProviderData();
          }}
        >
          <img src="/images/filter_search_icon.svg" />
          {t("text-search")}
        </button>
      </div>
    </div>
  );
};
