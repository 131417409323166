export const DumpSecondaryIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="16"
      viewBox="0 0 14 16"
      fill="none"
    >
      <path
        d="M0 2.625V1.75C0 1.33438 0.334375 1 0.75 1H4.25L4.54375 0.415625C4.66875 0.159375 4.92812 0 5.2125 0H8.78437C9.06875 0 9.32812 0.159375 9.45625 0.415625L9.75 1H13.25C13.6656 1 14 1.33438 14 1.75V2.625C14 2.83125 13.8312 3 13.625 3H0.375C0.16875 3 0 2.83125 0 2.625ZM12.975 4.39687L12.3375 14.5938C12.2875 15.3844 11.6312 16 10.8406 16H3.15937C2.36875 16 1.7125 15.3844 1.6625 14.5938L1.025 4.39687C1.0125 4.18125 1.18437 4 1.4 4H12.6031C12.8156 4 12.9875 4.18125 12.975 4.39687Z"
        fill="#636363"
      />
    </svg>
  );
};
