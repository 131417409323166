import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

import {
  LINKS,
  SOCIAL_LINKS,
  SOCIAL_MEDIA_ICONS,
} from "../../../utils/variables";
import { Cookie } from "./Cookie";

export function Footer() {
  const { t } = useTranslation();

  const [menu, setMenu] = useState();
  const [isWideScreen, setIsWideScreen] = useState(false);
  const [cookie, setCookie] = useState(
    localStorage.getItem("cooki")
      ? JSON.parse(localStorage.getItem("cooki"))
      : true
  );

  const location = useLocation();

  useEffect(() => {
    const checkScreenWidth = () => {
      setIsWideScreen(window.innerWidth >= 1920);
    };
    checkScreenWidth();
    window.addEventListener("resize", checkScreenWidth);

    return () => {
      window.removeEventListener("resize", checkScreenWidth);
    };
  }, []);

  const usesCookieText = t("uses-cookie").replace(
    "{@br}",
    isWideScreen ? "<br>" : ""
  );

  return (
    <>
      <div className="container-fluid dask">
        <div className="footer">
          <div className="head">
            <div className="container">
              <ul>
                <li>{t("text-contact")}</li>
                <li>{t("about")}</li>
                <li>{t("text-resources").split(' ')[0]}</li>
                <li>{t("legal")}</li>
              </ul>
            </div>
          </div>
          <div className="container">
            <div className="footer_menu">
              <ul>
                {LINKS.map((link) => (
                  <li key={link.to}>
                    <Link to={link.to}>{t(link.label)}</Link>
                  </li>
                ))}
              </ul>
              <ul>
                <li>
                  <Link to="/about-sensCare">{t("about-senscare")}</Link>
                </li>
                <li>
                  <Link to="/faq">{t("text-faq")}</Link>
                </li>
              </ul>
              <ul>
                <li>
                  <Link to="/resources">{t("text-resources")}</Link>
                </li>
                <li>
                  <Link to="/safety-center">{t("safety-center")}</Link>
                </li>
              </ul>
              <ul>
                <li>
                  <Link to="/terms-of-use">{t("title-terms-of-use")}</Link>
                </li>
                <li>
                  <Link to="/privacy-policy">{t("title-privacy-policy")}</Link>
                </li>
                <li>
                  <Link to="/cookie-policy">{t("cookies-policy")}</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="bottom">
            <ul>
              <li>©{t("senscare-2023")}</li>
              <li>
                <ul>
                  {SOCIAL_LINKS.map((link) => (
                    <li key={link.href}>
                      <a href={link.href} target="_blank">
                        <img src={link.imgSrc} alt={link.href} />
                      </a>
                    </li>
                  ))}
                </ul>
              </li>
              <li>
                <Link to="#">
                  <img src={window.location.origin + "/images/app_store.svg"} />
                </Link>
              </li>
              <li>
                <Link to="/">
                  <img src={window.location.origin + "/images/logo.svg"} />
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      {location.pathname == "*" && (
        <div className="container-fluid mobilef">
          <div className="footer">
            <div className="container">
              <div className="footer_menu">
                <h4
                  id="fm1"
                  onClick={(a) => {
                    menu ? setMenu() : setMenu("fm1");
                  }}
                >
                  {t("text-contact")}
                  <img
                    src={window.location.origin + "/images/down_arrow.svg"}
                  />
                </h4>
                <ul className={menu == "fm1" ? "show" : "hide"}>
                  <li>
                    <Link to="/contact-us">{t("title-contact")}</Link>
                  </li>
                  <li>
                    <Link to="/work-with-us">{t("work")}</Link>
                  </li>
                  <li>
                    <Link to="/career">{t("career")}</Link>
                  </li>
                  <li>
                    <Link to="/investor-relations">
                      {t("investor-relation")}
                    </Link>
                  </li>
                </ul>
                <h4
                  id="fm2"
                  onClick={(a) => {
                    menu ? setMenu() : setMenu("fm2");
                  }}
                >
                  {t("about")}
                  <img
                    src={window.location.origin + "/images/down_arrow.svg"}
                  />
                </h4>
                <ul className={menu == "fm2" ? "show" : "hide"}>
                  <li>
                    <Link to="/about-sensCare">{t("about-senscare")}</Link>
                  </li>
                  <li>
                    <Link to="/faq">{t("text-faq")}</Link>
                  </li>
                </ul>
                <h4
                  id="fm3"
                  onClick={(a) => {
                    menu ? setMenu() : setMenu("fm3");
                  }}
                >
                  {t("footer-resources")}
                  <img
                    src={window.location.origin + "/images/down_arrow.svg"}
                  />
                </h4>
                <ul className={menu == "fm3" ? "show" : "hide"}>
                  <li>
                    <Link to="/resources">{t("text-resources")}</Link>
                  </li>
                  <li>
                    <Link to="/safety-center">{t("safety-center")}</Link>
                  </li>
                </ul>
                <h4
                  id="fm4"
                  onClick={(a) => {
                    menu ? setMenu() : setMenu("fm4");
                  }}
                >
                  {t("legal")}
                  <img
                    src={window.location.origin + "/images/down_arrow.svg"}
                  />
                </h4>
                <ul className={menu == "fm4" ? "show" : "hide"}>
                  <li>
                    <Link to="/terms-of-use">{t("footer-terms")}</Link>
                  </li>
                  <li>
                    <Link to="/privacy-policy">{t("privacy-policy")}</Link>
                  </li>
                  <li>
                    <Link to="/cookie-policy">{t("cookies-policy")}</Link>
                  </li>
                </ul>
              </div>
              <div className="bottom2 ">
                <ul>
                  <li>
                    <Link to="/">
                      <img
                        src={window.location.origin + "/images/white_logo.svg"}
                      />
                    </Link>
                  </li>
                  <li>
                    <ul>
                      <li>
                        <Link to="#">
                          <img
                            src={window.location.origin + "/images/whitefb.svg"}
                          />
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          <img
                            src={window.location.origin + "/images/whitein.svg"}
                          />
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          <img
                            src={
                              window.location.origin + "/images/whitetwiter.svg"
                            }
                          />
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <Link to="#">
                      <img
                        src={window.location.origin + "/images/app_store.svg"}
                      />
                    </Link>
                  </li>
                  <li>©{t("senscare-2023")}</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="container-fluid mobilef">
        <div className="footer">
          <div className="container">
            <div className="footer_menu">
              <h4
                id="fm1"
                onClick={(a) => {
                  menu ? setMenu() : setMenu("fm1");
                }}
              >
                Contact
                <img src={window.location.origin + "/images/down_arrow.svg"} />
              </h4>
              <ul className={menu == "fm1" ? "show" : "hide"}>
                <li>
                  <Link to="/contact-us">Contact us </Link>
                </li>
                <li>
                  <Link to="/work-with-us">Work with us</Link>
                </li>
                <li>
                  <Link to="/career">Career</Link>
                </li>
                <li>
                  <Link to="/investor-relations">Investor Relation</Link>
                </li>
              </ul>
              <h4
                id="fm2"
                onClick={(a) => {
                  menu ? setMenu() : setMenu("fm2");
                }}
              >
                About
                <img src={window.location.origin + "/images/down_arrow.svg"} />
              </h4>
              <ul className={menu == "fm2" ? "show" : "hide"}>
                <li>
                  <Link to="/about-sensCare">About SensCare</Link>
                </li>
                <li>
                  <Link to="/faq">FAQ</Link>
                </li>
              </ul>
              <h4
                id="fm3"
                onClick={(a) => {
                  menu ? setMenu() : setMenu("fm3");
                }}
              >
                Resources
                <img src={window.location.origin + "/images/down_arrow.svg"} />
              </h4>
              <ul className={menu == "fm3" ? "show" : "hide"}>
                <li>
                  <Link to="/resources">Resources for parents</Link>
                </li>
                <li>
                  <Link to="/safety-center">Safety center</Link>
                </li>
              </ul>
              <h4
                id="fm4"
                onClick={(a) => {
                  menu ? setMenu() : setMenu("fm4");
                }}
              >
                Legal
                <img src={window.location.origin + "/images/down_arrow.svg"} />
              </h4>
              <ul className={menu == "fm4" ? "show" : "hide"}>
                <li>
                  <Link to="/terms-of-use">Terms of use</Link>
                </li>
                <li>
                  <Link to="/privacy-policy">Privacy policy</Link>
                </li>
                <li>
                  <Link to="/cookies-policy">Cookies policy</Link>
                </li>
              </ul>
            </div>
            <div className="bottom2 ">
              <ul>
                <li>
                  <Link to="/">
                    <img
                      src={window.location.origin + "/images/white_logo.svg"}
                    />
                  </Link>
                </li>
                <li>
                  <ul>
                    {SOCIAL_MEDIA_ICONS.map((icon, index) => (
                      <li key={index}>
                        <Link to="#">
                          <img
                            src={window.location.origin + icon.src}
                            alt={icon.alt}
                          />
                        </Link>
                      </li>
                    ))}
                  </ul>
                </li>
                <li>
                  <Link to="#">
                    <img
                      src={window.location.origin + "/images/app_store.svg"}
                    />
                  </Link>
                </li>
                <li>© 2024 SensCare. All Rights Reserved. </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      {cookie && (
        <Cookie usesCookieText={usesCookieText} setCookie={setCookie} />
      )}
    </>
  );
}
