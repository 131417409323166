export const ArrowScrollIcon = ({ onClick }) => {
  return (
    <svg
      onClick={onClick}
      width="90"
      height="90"
      viewBox="0 0 90 90"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.8" filter="url(#filter0_d_302_10697)">
        <circle cx="49" cy="34" r="25" fill="#7D2B8B" />
      </g>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M49.0042 32.5649L54.8109 38.3716C55.1261 38.7099 55.6007 38.8491 56.0486 38.7347C56.4965 38.6203 56.8463 38.2706 56.9607 37.8226C57.075 37.3747 56.9358 36.9001 56.5976 36.585L49.0126 29L47.0658 30.9468L41.4024 36.6102C41.0642 36.9254 40.925 37.4 41.0393 37.8479C41.1537 38.2958 41.5035 38.6456 41.9514 38.76C42.3993 38.8744 42.8739 38.7351 43.1891 38.3969L49.0042 32.5649Z"
        fill="white"
      />
      <defs>
        <filter
          id="filter0_d_302_10697"
          x="0"
          y="0"
          width="90"
          height="90"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="-4" dy="11" />
          <feGaussianBlur stdDeviation="10" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_302_10697"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_302_10697"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};
