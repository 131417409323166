export const PawsIcon = () => {
  return (
    <svg
      width="40"
      height="29"
      viewBox="0 0 40 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.0698 9.06977C19.0698 3.95348 14.8837 0 9.76744 0C4.65116 0 0.348837 3.95348 0.348837 9.06977C0.348837 12.907 2.09302 15.5814 5.69767 17.2093C6.04651 17.907 6.27907 18.6047 6.27907 19.4186C6.27907 22.093 4.18605 24.4186 0 26.2791L1.97674 28.8372C11.2791 25.1163 19.0698 16.3953 19.0698 9.06977ZM40 9.06977C40 3.95348 35.6977 0 30.6977 0C25.5814 0 21.2791 3.95348 21.2791 9.06977C21.2791 12.907 23.0233 15.5814 26.6279 17.2093C26.9767 17.907 27.2093 18.6047 27.2093 19.4186C27.2093 22.093 25.1163 24.4186 20.9302 26.2791L22.907 28.8372C32.2093 25.1163 40 16.3953 40 9.06977Z"
        fill="#7D2B8B"
      />
    </svg>
  );
};
