import React from "react";
import { useTranslation } from "react-i18next";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";

export const SuccessModal = ({
    isSuccessfullyModal,
    setIsSuccessfullyModal,
    generalJobApply
}) => {
  const { t } = useTranslation();

  return (
    <Modal
      show={isSuccessfullyModal}
      onHide={() => setIsSuccessfullyModal(false)}
    >
      <Modal.Header>
        {/* Adding an empty title ensures the header gets space */}
        <Modal.Title style={{visibility: 'hidden'}}>Close</Modal.Title>
        <button
            type="button"
            className="close"
            onClick={() => setIsSuccessfullyModal(false)}
            style={{
              position: 'absolute',
              top: '10px',
              right: '10px',
              border: 'none',
              background: 'transparent',
              padding: '5px',
              fontSize: '1.5rem',
              color: '#000', // Change color as needed
              cursor: 'pointer',
              zIndex: "500",
            }}
        >
          &times;
        </button>
      </Modal.Header>
      <Modal.Body>
        <div className="promocode_content login_first">
          <h2>{t("text-congratulations")}</h2>
          {generalJobApply == t("post-apply-job") ? (
              ""
          ) : (
              <img src={window.location.origin + "/images/activate.png"}/>
          )}
          <p>
            {t("post-apply-job")}
          </p>

          {generalJobApply == t("post-apply-job") ? (
              <button onClick={(e) => setIsSuccessfullyModal(false)}>
              {t("text-close-up")}
            </button>
          ) : (
            <button>
              <Link to={"/search-parents"}>{t("apply-similar-jobs")}</Link>
            </button>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};
