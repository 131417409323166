import { DEFAULT_API } from "../utils/variables";
import {useTranslation} from "react-i18next";

export const fetchRegisterUser = async (
  formLogin,
  contactCode,
  category,
  describe,
  language = 'en',
  latLong
) => {
  const formdata = new FormData();
  formdata.append("countrycode", contactCode.code );
  formdata.append("username", formLogin.username.toLocaleLowerCase());
  formdata.append("email", formLogin.email.toLocaleLowerCase());
  formdata.append("password", formLogin.password);
  formdata.append("c_password", formLogin.c_password);
  formdata.append("service_type", JSON.stringify(describe));
  formdata.append("first_name", formLogin.first_name);
  formdata.append("last_name", formLogin.last_name);
  formdata.append("dob", formLogin.dob);
  formdata.append("phone", formLogin.phone);
  formdata.append("address", formLogin.address);
  formdata.append("city", formLogin.city);
  formdata.append("zip", formLogin.zip);
  formdata.append("country", formLogin.country);
  formdata.append("hearAboutUs", formLogin.hearAboutUs);
  formdata.append("user_type", category);
  formdata.append("lat", latLong.lat || 0);
  formdata.append("lng", latLong.lng || 0);
  formdata.append(
    "referralId",
    "eyJpdiI6IlNaODJ2dG1hOEsrbVdjdDhHR1BhY1E9PSIsInZhbHVlIjoicE1yVVpyeWNNZ1VxYkY5cDJJNjJZUT09IiwibWFjIjoiYTc5ODdjZDM1NTc2N2MxMzY2NjEzZTRkMDlkZTZmM2Y4N2M2NGE3YmJhY2Q5YjA0YmFhNDRhODE0ZWM1OThjMSJ9"
  );
  formdata.append('lang', language);
  const requestOptions = {
    method: "POST",
    body: formdata,
    redirect: "follow",
  };

  try {
    const response = await fetch(`${DEFAULT_API}/api/register`, requestOptions);
    if (!response.ok) {
      const error = await response.text();
      throw new Error(error || "Something went wrong");
    }
    return response.json();
  } catch (error) {
    console.error("Error registering user:", error);
    throw error;
  }
};
