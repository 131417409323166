import React from "react";
import { useTranslation } from "react-i18next";

export const ReviewNotificationItem = ({
  data,
  setIsCheck,
  setUserId,
  setShowReviewModal,
  setUsername,
}) => {
  const { t } = useTranslation();

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
  };

  return (
    <div className="recommend">
      <div className="image">
        <img
          src={
            data.SenderImage != null
              ? "https://admin.stage.mysenscare.com/assets/images/users/" +
                data.SenderImage
              : window.location.origin + "/img/nany_img.png"
          }
          alt=""
        />
      </div>
      <div className="detail">
        <h3>{data.SenderName}</h3>
        <span className="star">
          {[...Array(5)].map((star, index) => {
            index += 1;
            return (
              <i
                type="button"
                key={index}
                className={
                  index <= data.rating
                    ? "on fa-solid fa-star"
                    : "off fa-regular fa-star"
                }
              ></i>
            );
          })}
        </span>
        <p style={{ padding: "0" }}>{formatDate(data.created_at)}</p>
        <h3 style={{ fontStyle: "italic" }}>{data.review_title}</h3>
        <p style={{ fontStyle: "italic" }}>{data.message}</p>
        <div className="buttonrec">
          <button
            style={{ marginLeft: "10px" }}
            onClick={(e) => setIsCheck(true)}
          >
            {t("see-reviews")}
          </button>

          {data.reviewerData === 0 && data.invitationstatus?.status > 0 ? (
            <button
              onClick={(e) => {
                setUserId(data.sender_userid);
                setShowReviewModal(true);
                setUsername(data.Sendername);
              }}
            >
              {t("leave-review")}
            </button>
          ) : (
            <button style={{ color: "#B7B7B7", borderColor: "#B7B7B7" }}>
              {t("leave-review")}
            </button>
          )}
        </div>
      </div>
      <p>{t("future-providers")}</p>
    </div>
  );
};
