import { create } from "zustand";

const initialFormLogin = {
  username: "",
  email: "",
  password: "",
  c_password: "",
  service_type: "",
  first_name: "",
  last_name: "",
  dob: "",
  phone: "",
  address: "",
  city: "",
  zip: "",
  country: "",
  hearAboutUs: "",
  user_type: "",
};

const initialErrorField = {
  username: "",
  email: "",
  password: "",
  c_password: "",
  service_type: "",
  first_name: "",
  last_name: "",
  dob: "",
  phone: "",
  address: "",
  city: "",
  zip: "",
  country: "",
  hearAboutUs: "",
};

export const useFormStore = create((set) => ({
  formLogin: { ...initialFormLogin },
  setFormLogin: (update) =>
    set((state) => {
      const newFormLogin =
        typeof update === "function" ? update(state.formLogin) : update;
      return { formLogin: { ...state.formLogin, ...newFormLogin } };
    }),

  errorField: { ...initialErrorField },
  setErrorField: (update) =>
    set((state) => {
      const newErrorField =
        typeof update === "function" ? update(state.errorField) : update;
      return { errorField: { ...state.errorField, ...newErrorField } };
    }),

  resetFormLogin: () => set({ formLogin: { ...initialFormLogin } }),
  resetErrorField: () => set({ errorField: { ...initialErrorField } }),
}));
