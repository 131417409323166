import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Modal from "react-bootstrap/Modal";

import { LaptopIcon } from "../../../assets/image/LaptopIcon";

export const DocumentModal = ({
  showModal,
  setShowModal,
  documentField,
  setDocumentField,
  handleSubmitRecommendation,
}) => {
  const { t } = useTranslation();

  return (
    <Modal show={showModal} onHide={(e) => setShowModal(false)}>
      <Modal.Body>
        <div className="promocode_content signout senddoc">
          <Link to="" onClick={(e) => setShowModal(false)}>
            +
          </Link>
          <h2>{t("select-documents")}</h2>
          <ul>
            <li>
              <span class="view-message  dont-show">
                <input
                  type="radio"
                  class="mail-radio"
                  name="chk"
                  defaultChecked="true"
                />
                <span className="circle"></span>
              </span>
              <span className="bothtype">
                <LaptopIcon />
                <p>
                  <h3>{t("text-computer")}</h3>
                  <span>{t("select-files-computer")}</span>
                </p>
              </span>
            </li>
          </ul>
          <div className="file">
            <label>{t("file-name")}</label>
            <div className="group_label">
              <span>
                {documentField == "" ? t("choose-file") : documentField.name}
              </span>
              <input
                type="file"
                onChange={(e) => {
                  if (
                    e.target.files[0].name !=
                    "The upload does not work properly.docx"
                  ) {
                    setDocumentField(e.target.files[0]);
                  }
                }}
                accept=".doc, .docx"
              />

              <button onClick={(e) => handleSubmitRecommendation()}>
                {t("text-submit")}
              </button>
            </div>
          </div>
          <p class="hide">
            
            {t("text-visit")}
            <Link to="/faq" target="_blank">
              {t("text-faq")}
            </Link>
            {t("page ands")}
            <Link to="/safety-center" target="_blank">
              {t("safety-center")}
            </Link>
            {t("safety-tips")}
          </p>
        </div>
      </Modal.Body>
    </Modal>
  );
};
