import React from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { CaseIcon } from "../../../assets/image/CaseIcon";
import { CommentIcon } from "../../../assets/image/CommentIcon";
import { PageIcon } from "../../../assets/image/PageIcon";
import { PageListIcon } from "../../../assets/image/PageListIcon";
import { JobListIcon } from "../../../assets/image/JobListIcon";

export function JobHistoryParents(props) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <>
      <ul>
        <li
          className={props.subtab == "job-post" ? "active" : ""}
          onClick={(e) => {
            window.scrollTo({ top: 0, behavior: "smooth" });
            props.setsubtab("job-post");
            navigate("/search-parents/job-post");
          }}
        >
          <CaseIcon />
          {t("job-posts")}
        </li>
        <li
          className={props.subtab == "job-applications" ? "active" : ""}
          onClick={(e) => {
            window.scrollTo({ top: 0, behavior: "smooth" });
            props.setsubtab("job-applications");
            navigate("/search-parents/job-applications");
          }}
        >
          <PageIcon color={"#636363"} />
          {t("job-applications")}
        </li>
        <li
          className={props.subtab == "interview-invites" ? "active" : ""}
          onClick={(e) => {
            window.scrollTo({ top: 0, behavior: "smooth" });
            props.setsubtab("interview-invites");
            navigate("/search-parents/interview-invites");
          }}
        >
          <CommentIcon />
          {t("interview-invites")}
        </li>
        <li
          className={props.subtab == "recommendation-request" ? "active" : ""}
          onClick={(e) => {
            window.scrollTo({ top: 0, behavior: "smooth" });
            props.setsubtab("recommendation-request");
            navigate("/search-parents/recommendation-request");
          }}
        >
          <PageListIcon />
          {t("recommendation-requests")}
        </li>
        <li
          className={props.subtab == "shared-documents" ? "active" : ""}
          onClick={(e) => {
            window.scrollTo({ top: 0, behavior: "smooth" });
            props.setsubtab("shared-documents");
            navigate("/search-parents/shared-documents");
          }}
        >
          <JobListIcon />
          {t("shared-documents")}
        </li>
      </ul>
    </>
  );
}
