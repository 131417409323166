import React from "react";
import { useTranslation } from "react-i18next";

import styles from "./Footer.module.css";

export function Footer() {
  const { t } = useTranslation();
  
  return (
    <div>
      <div className={styles.container_fluid}>
        <div className={styles.container}>
          <div className={styles.footer}>
            <div className={styles.social_media}>
              <p>{t("follow-us")}</p>
              <ul>
                <li>
                  <a
                    href="https://www.facebook.com/people/SensCare/100066554561840/"
                    target="_blank"
                  >
                    <img src="./images/facebook.svg" />
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.linkedin.com/company/sens-care/"
                    target="_blank"
                  >
                    <img src="./images/indi.svg" />
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.instagram.com/mysenscare/"
                    target="_blank"
                  >
                    <img src="./images/twiter.svg" />
                  </a>
                </li>
              </ul>
              <p>{t("@2024 SensCare. All Rights Reserved.")}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
