import { EMAIL_REGEX, SMALL_LETTERS, VALID_PASSWORD } from "./variables";

export const validateFieldAfterLogin = (name, formLogin, contactCode, t) => {
  let error = "";
  switch (name) {
    case "username":
      error = formLogin.username === "" ? t("required") : "";
      break;
    case "email":
      error = formLogin.email !== "" ? "" : t("required");
      break;
    case "password":
      error = formLogin.password !== "" ? "" : t("required");
      break;
    case "c_password":
      error = formLogin.c_password === "" ? t("required") : "";
      break;
    case "first_name":
      error = formLogin.first_name === "" ? t("required") : "";
      break;
    case "last_name":
      error = formLogin.last_name === "" ? t("required") : "";
      break;
    case "dob":
      error = formLogin.dob === "" ? t("required") : "";
      break;
    case "phone":
      error = formLogin.phone === "" ? t("required") : "";
      error = contactCode.code === "" ? t("required") : "";
      break;
    case "address":
      error = formLogin.address === "" ? t("required") : "";
      break;
    case "city":
      error = formLogin.city === "" ? t("required") : "";
      break;
    case "zip":
      error = formLogin.zip === "" ? t("required") : "";
      break;
    case "country":
      error = formLogin.country === "" ? t("required") : "";
      break;
    case "hearAboutUs":
      error = formLogin.hearAboutUs === "" ? t("required") : "";
      break;
    default:
      break;
  }
  return error;
};


export const validateFieldLogin = (name, value, formLogin, t, handleShowModal) => {
  let error = "";
  switch (name) {
    case "username":
      error = value.length < 2 ? t("Minimum 2 characters required") : "";
      break;
    case "email":
      error = EMAIL_REGEX.test(value) ? "" : t("email-valid");
      break;
    case "password":
      error = VALID_PASSWORD.test(value) ? "" : "Demo@123";
      break;
    case "c_password":
      error = value !== formLogin.password ? t("Password not match.") : "";
      break;
    case "service_type":
      error = value.length < 2 ? t("Minimum 2 characters required") : "";
      break;
    case "first_name":
      error = SMALL_LETTERS.test(value)
        ? (value.length < 2 ? t("Minimum 2 characters required") : "")
        : t("Only letters are allowed ");
      break;
    case "last_name":
      error = SMALL_LETTERS.test(value)
        ? (value.length < 2 ? t("Minimum 2 characters required") : "")
        : t("Only letters are allowed ");
      break;
    case "dob":
      error = value.length < 2 ? t("required") : "";
      if (new Date().getFullYear() - parseInt(value) <= 16) {
        handleShowModal();
      }
      break;
    case "phone":
      error = value.length < 8 ? t("required") : "";
      break;
    case "address":
      error = value.length < 2 ? t("required") : "";
      break;
    case "city":
      error = value.length < 2 ? t("required") : "";
      break;
    case "zip":
      error = value.length < 2 ? t("required") : "";
      break;
    case "country":
      error = value.length < 2 ? t("required") : "";
      break;
    case "hearAboutUs":
      error = value.length < 2 ? t("required") : "";
      break;
    default:
      break;
  }
  return error;
};

export const validateField = (name, value, formLogin, handleShowModal) => {
  const errors = {};
  switch (name) {
    case "username":
      errors.username = value.length < 2 ? "Minimum 2 characters required" : "";
      break;
    case "email":
      errors.email = EMAIL_REGEX.test(value) ? "" : "Invalid email address";
      break;
    case "password":
      errors.password = VALID_PASSWORD.test(value)
        ? ""
        : "Invalid password format";
      errors.c_password =
        value !== formLogin.c_password ? "Passwords do not match" : "";
      break;
    case "c_password":
      errors.c_password =
        value !== formLogin.password ? "Passwords do not match" : "";
      break;
    case "service_type":
      errors.service_type =
        value.length < 2 ? "Minimum 2 characters required" : "";
      break;
    case "first_name":
      errors.first_name =
        value.length < 2 ? "Minimum 2 characters required" : "";
      break;
    case "last_name":
      errors.last_name =
        value.length < 2 ? "Minimum 2 characters required" : "";
      break;
    case "dob":
      errors.dob = value.length < 2 ? "Required" : "";
      if (new Date().getFullYear() - parseInt(value) <= 16) handleShowModal();
      break;
    case "phone":
      errors.phone = value.length < 8 ? "Required" : "";
      break;
    case "address":
      errors.address = value.length < 2 ? "Required" : "";
      break;
    case "city":
      errors.city = value.length < 2 ? "Required" : "";
      break;
    case "zip":
      errors.zip = value.length < 2 ? "Required" : "";
      break;
    case "country":
      errors.country = value.length < 2 ? "Required" : "";
      break;
    case "hearAboutUs":
      errors.hearAboutUs = value.length < 2 ? "Required" : "";
      break;
    default:
      break;
  }
  return errors;
};
