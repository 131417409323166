import { DEFAULT_API } from "../utils/variables";

export const fetchMembershipData = async (id) => {
  const token = localStorage.getItem("token");
  const myHeaders = new Headers();
  myHeaders.append("Authorization", "Bearer " + token);
  myHeaders.append("Content-Type", "application/json");

  const raw = JSON.stringify({ id });

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  try {
    const response = await fetch(
      `${DEFAULT_API}/api/v1/membershipnotificationsview`,
      requestOptions
    );
    if (!response.ok) {
      const error = await response.json();
      throw new Error(error.message || "Something went wrong");
    }
    return response.json();
  } catch (error) {
    console.error("Error fetching membership data:", error);
    throw error;
  }
};

export const fetchMembershipNotifications = async (refine) => {
  const token = localStorage.getItem("token");
  const myHeaders = new Headers();
  myHeaders.append("Authorization", "Bearer " + token);

  const queryParams = refine.threedays
    ? `threedays=${refine.threedays}`
    : refine.withinweek
    ? `withinweek=${refine.withinweek}`
    : refine.twofourday
    ? `twofourday=${refine.twofourday}`
    : refine.withinmonth
    ? `withinmonth=${refine.withinmonth}`
    : "";

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  try {
    const response = await fetch(
      `${DEFAULT_API}/api/v1/membershipnotifications?${queryParams}`,
      requestOptions
    );
    if (!response.ok) {
      const error = await response.json();
      throw new Error(error.message || "Something went wrong");
    }
    return response.json();
  } catch (error) {
    console.error("Error fetching membership notifications:", error);
    throw error;
  }
};

export const fetchDeleteMembershipNotifications = async (ids) => {
  const token = localStorage.getItem("token");
  const myHeaders = new Headers();
  myHeaders.append("Authorization", "Bearer " + token);
  myHeaders.append("Content-Type", "application/json");

  const raw = JSON.stringify({ ids });

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  try {
    const response = await fetch(
      `${DEFAULT_API}/api/v1/membershipnotificationsdelete`,
      requestOptions
    );
    if (!response.ok) {
      const error = await response.json();
      throw new Error(error.message || "Something went wrong");
    }
    return response.text();
  } catch (error) {
    console.error("Error deleting membership notifications:", error);
    throw error;
  }
};
