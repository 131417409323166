export const MainDandruffIcon = ({ width, height, color }) => {
  const generalWidth = width ? width : "15";
  const generalHeight = height ? height : "16";
  const generalColor = color ? color : "#fff";

  return (
    <svg
      width={generalWidth}
      height={generalHeight}
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.00005 2.20704C5.13404 2.20704 2.00001 5.34107 2.00001 9.20708C2.00001 13.0731 5.13404 16.2071 9.00005 16.2071C12.8661 16.2071 16.0001 13.0731 16.0001 9.20708C16.0001 5.34107 12.8661 2.20704 9.00005 2.20704ZM0 9.20708C0 4.23649 4.02946 0.207031 9.00005 0.207031C13.9706 0.207031 18.0001 4.23649 18.0001 9.20708C18.0001 14.1777 13.9706 18.2071 9.00005 18.2071C4.02946 18.2071 0 14.1777 0 9.20708Z"
        fill={generalColor}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.9433 14.1503C14.3338 13.7598 14.967 13.7598 15.3575 14.1503L19.7075 18.5003C20.0981 18.8909 20.0981 19.524 19.7075 19.9146C19.317 20.3051 18.6838 20.3051 18.2933 19.9146L13.9433 15.5645C13.5528 15.174 13.5528 14.5408 13.9433 14.1503Z"
        fill={generalColor}
      />
    </svg>
  );
};
