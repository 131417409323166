export const StopHandIcon = ({ onClick }) => {
  return (
    <svg
      onClick={onClick}
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{cursor: "pointer"}}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M20.6667 20.3333C20.6667 21.8 19.4667 23 18 23H13.1333C12.4133 23 11.7333 22.7133 11.2333 22.2067L6 16.8867C6 16.8867 6.6404 16.2201 6.86667 16.0533C7.09293 15.8866 7.19614 15.8784 7.39333 15.86C7.59052 15.8416 7.67333 15.9 7.79333 15.9667C7.82 15.9733 10.6667 17.6067 10.6667 17.6067V9.66667C10.6667 9.11333 11.1133 8.66667 11.6667 8.66667C12.22 8.66667 12.6667 9.11333 12.6667 9.66667V14.3333H13.3333V8C13.3333 7.44667 13.78 7 14.3333 7C14.8867 7 15.3333 7.44667 15.3333 8V14.3333H16V8.66667C16 8.11333 16.4467 7.66667 17 7.66667C17.5533 7.66667 18 8.11333 18 8.66667V14.3333H18.6667V10.6667C18.6667 10.1133 19.1133 9.66667 19.6667 9.66667C20.22 9.66667 20.6667 10.1133 20.6667 10.6667V20.3333Z"
        fill="#7D2B8B"
      />
    </svg>
  );
};
