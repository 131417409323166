import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Modal from "react-bootstrap/Modal";

export const SignoutModal = ({isSignoutModal, setIsSignoutModal}) => {
  const { t } = useTranslation();

  return (
    <Modal show={isSignoutModal} onHide={(e) => setIsSignoutModal(false)}>
      <Modal.Body>
        <div className="promocode_content signout">
          <Link to="" onClick={(e) => setIsSignoutModal(false)}>
            +
          </Link>
          <h2>{t("Sign out")}</h2>
          <p>{t("Are you sure you want to Sign out?")} </p>
          <div className="button text-center">
            <div className="pull-right">
              <button className="btn" onClick={(e) => setIsSignoutModal(false)}>
                {t("objection")}
              </button>
            </div>
            <div className="pull-right">
              <button
                className="btn confirm"
                onClick={(e) => {
                  localStorage.removeItem("id");
                  localStorage.removeItem("token");
                  setTimeout(() => {
                    window.location.replace("/login");
                  }, 500);
                }}
              >
                {t("confirm")}
              </button>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
