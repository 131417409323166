import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Modal from "react-bootstrap/Modal";

export const DocumentsModal = ({
  isDocumentsModal,
  setIsDocumentsModal,
  handleDocumentSelect,
  handleRecommendations,
}) => {
  const { t } = useTranslation();

  return (
    <Modal show={isDocumentsModal} onHide={(e) => setIsDocumentsModal(false)}>
      <Modal.Body>
        <div className="promocode_content signout invite request">
          <Link to="" onClick={(e) => setIsDocumentsModal(false)}>
            +
          </Link>
          <h2>{t("Request Documents")}</h2>
          <p>{t("request-additional-documents")} </p>
          <ul>
            <li>
              <input
                type="checkbox"
                name=""
                onClick={(e) => handleDocumentSelect("CV")}
              />
              <span>{t("text-cv-up")}</span>
            </li>
            <li>
              <input
                type="checkbox"
                name=""
                onClick={(e) => handleDocumentSelect("Background check")}
              />
              <span>{t("background-check")}</span>
            </li>
            <li>
              <input
                type="checkbox"
                name=""
                onClick={(e) => handleDocumentSelect("Recommendations")}
              />
              <span>{t("text-recommendations")}</span>
            </li>
            <li>
              <input
                type="checkbox"
                name=""
                onClick={(e) => handleDocumentSelect("Certificates")}
              />
              <span>{t("text-certificates")}</span>
            </li>
          </ul>
          <div className="button text-center ">
            <div className="pull-right">
              <button
                className="btn"
                onClick={(e) => setIsDocumentsModal(false)}
              >
                {t("text-cancel")}
              </button>
            </div>
            <div className="pull-right">
              <button
                className="btn confirm"
                onClick={(e) => handleRecommendations()}
              >
                {t("text-send")}
              </button>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
