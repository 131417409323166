import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Modal from "react-bootstrap/Modal";

export const ReactivateModal = ({
  isReactivateModal,
  setIsReactivateModal,
  link
}) => {
  const { t } = useTranslation();

  return (
    <Modal show={isReactivateModal} onHide={(e) => setIsReactivateModal(false)}>
      <Modal.Body>
        <div className="promocode_content reactiveask">
          <Link to="" onClick={(e) => setIsReactivateModal(false)}>
            +
          </Link>
          <h2>{t("reactivate-account")}</h2>
          <div className="cancelmembershipp">
            <p>{t("update-profile-information")} </p>
            <div class="button text-center">
              <div class="pull-right">
                <button
                  class="btn"
                  onClick={(e) => setIsReactivateModal(false)}
                >
                  
                  <Link
                    to={
                      link != "parents"
                        ? "/search-parents"
                        : "/search-providers"
                    }
                  >
                    {t("objection")}
                  </Link>
                </button>
              </div>
              <div class="pull-right">
                <button class="btnconfirm">
                  
                  <Link
                    to={
                      link == "parents"
                        ? "/complete-parents-profile"
                        : "/complete-provider-profile"
                    }
                  >
                    {t("confirm")}
                  </Link>
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
