import { DEFAULT_API } from "../utils/variables";

export const getProfileData = async (profileSearch) => {
  const token = localStorage.getItem("token");
  const myHeaders = new Headers();
  myHeaders.append("Authorization", "Bearer " + token);
  myHeaders.append("Content-Type", "application/json");

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  try {
    const response = await fetch(
      `${DEFAULT_API}/api/v1/favprofilelist?profile_search=${profileSearch}`,
      requestOptions
    );
    if (!response.ok) {
      const error = await response.text();
      throw new Error(error || "Something went wrong");
    }
    return response.json();
  } catch (error) {
    console.error("Error fetching profile data:", error);
    throw error;
  }
};

export const saveFavoriteProfile = async (userId) => {
  const token = localStorage.getItem("token");
  const myHeaders = new Headers();
  myHeaders.append("Authorization", "Bearer " + token);
  myHeaders.append("Content-Type", "application/json");

  const raw = JSON.stringify({
    fav_userid: userId,
  });

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  try {
    const response = await fetch(
      `${DEFAULT_API}/api/v1/savefavprofile`,
      requestOptions
    );

    if (!response.ok) {
      const error = await response.text();
      throw new Error(error || "Something went wrong");
    }
    return response.json();
  } catch (error) {
    console.error("Error saving favorite profile:", error);
    throw error;
  }
};

export const updateProfile = async (detailProvider, certificates) => {
  const myHeaders = new Headers();
  myHeaders.append("Authorization", "Bearer " + localStorage.getItem("token"));

  const formdata = new FormData();
  formdata.append("user_id", localStorage.getItem("id"));

  if (detailProvider.cv.name) {
    formdata.append("cv", detailProvider.cv);
  }

  if (detailProvider.qualificationscertificatesdoc.name) {
    formdata.append(
      "qualificationscertificatesdoc[]",
      detailProvider.qualificationscertificatesdoc
    );
  }

  if (detailProvider.backgrounddoc.name) {
    formdata.append("backgrounddoc", detailProvider.backgrounddoc);
  }

  if (detailProvider.recommendationsfile.name) {
    formdata.append("recommendationsfile", detailProvider.recommendationsfile);
  }

  formdata.append(
    "qualificationscertificatesname[]",
    certificates.qualificationscertificatesname
  );
  formdata.append(
    "qualificationscertificatesname[]",
    certificates.qualificationscertificatesname2
  );
  formdata.append(
    "qualificationscertificatesname[]",
    certificates.qualificationscertificatesname3
  );
  formdata.append(
    "qualificationscertificatesname[]",
    certificates.qualificationscertificatesname4
  );

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: formdata,
    redirect: "follow",
  };

  try {
    const response = await fetch(
      `${DEFAULT_API}/api/v1/updateprovider`,
      requestOptions
    );

    if (!response.ok) {
      const error = await response.json();
      throw new Error(error.message || "Something went wrong");
    }

    return response.json();
  } catch (error) {
    console.error("Error updating profile:", error);
    throw error;
  }
};

export const getProfileDetails = async (token) => {
  const myHeaders = new Headers();
  myHeaders.append("Authorization", "Bearer " + token);
  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  try {
    const response = await fetch(
      `${DEFAULT_API}/api/v1/details`,
      requestOptions
    );
    const result = await response.json();
    return result.data;
  } catch (error) {
    console.log("error", error);
    throw error;
  }
};
