import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { FormInput } from "../../components/common/FormInput";
import { useFormStore } from "../../store/useFormStore";
import { LocationContent } from "../../components/common/LocationContent";

import {
  CAPITAL_LETTERS,
  NUMBERS,
  SPECIAL_CHARACTERS,
} from "../../utils/variables";
import { DATA_COUNTRY } from "../../utils/data";
import { validateFieldLogin } from "../../utils/helperValidations";
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from "dayjs";


export const ProviderSignupForm = ({
  today,
  handleShowModal,
  handleCodeSelect,
  contactCode,
  code,
  setContactCode,
  setLangLong
}) => {
  const { t } = useTranslation();

  const [isNewPassword, setIsNewPassword] = useState(false);
  const [isPassword, setIsPassword] = useState(false);

  const { formLogin, setFormLogin, errorField, setErrorField } = useFormStore();

    const handleDateChange = (date) => {
        if (date) {
            setErrorField({ ...errorField, dob: "" });

            // Use moment's year method to get the year from the moment date object
            const age = today.getFullYear() - date.year();

            if (age > 16) {
                setFormLogin({
                    ...formLogin,
                    dob: date.format('YYYY-MM-DD'), // Format as YYYY-MM-DD
                });
            } else {
                handleShowModal();
            }
        }
    };

  const getLatLong = (latlong2, e, addresss) => {
      if (e instanceof Promise) {
          e.then(function (result) {
              setLangLong(result);
          });
      } else {
          setLangLong(e);
      }

    latlong2.address_components.map((e) => {
      e.types.map((a) => {
        if (a == "country") {
          setErrorField((prevErrorField) => ({
            ...prevErrorField,
            country: "",
            address: "",
          }));

          setFormLogin((prevFormLogin) => ({
            ...prevFormLogin,
            country: e.long_name,
            address: latlong2.formatted_address,
          }));
        }
      });
    });
  };

  const handleChange = (e) => validateLoginField(e);

  const validateLoginField = (e) => {
    const { name, value } = e.target;
    const error = validateFieldLogin(
      name,
      value,
      formLogin,
      t,
      handleShowModal
    );

    setErrorField((prevErrorField) => ({
      ...prevErrorField,
      [name]: error,
    }));

    setFormLogin((prevFormLogin) => ({
      ...prevFormLogin,
      [name]: value,
    }));
  };

  return (
      <LocalizationProvider dateAdapter={AdapterDayjs}>

      <form>
          <FormInput
              label={t("First Name*")}
              type="text"
              placeholder={t("text-type")}
              name="first_name"
              errorField={errorField}
              onChange={handleChange}
          />
          <FormInput
              label={t("Last Name*")}
              type="text"
              placeholder={t("text-type")}
              name="last_name"
              errorField={errorField}
              onChange={handleChange}
          />
          <FormInput
              label={t("Username*")}
              type="text"
              placeholder={t("text-type")}
              name="username"
              errorField={errorField}
              onChange={handleChange}
          />
          <FormInput
              label={t("Email address*")}
              type="email"
              placeholder={t("text-type")}
              name="email"
              errorField={errorField}
              onChange={handleChange}
          />

          <div className="form_group">
              <label>{t("Password*")}</label>
              <input
                  type={isPassword ? "text" : "password"}
                  placeholder=". . . . . . . . ."
                  name="password"
                  onChange={(e) => validateLoginField(e)}
                  className={errorField.password == "" ? "" : "bordererror"}
              />
              <div className="eyes">
                  <input
                      type={"checkbox"}
                      onClick={(e) => {
                          setIsPassword(!isPassword);
                      }}
                  />
                  <i></i>
              </div>
              {errorField.password || errorField.password ? (
                  <div className="password_rule errorfield signuppassword">
                      <p>
                          {t("password-bw-8-15-char")}
                          <br/>
                          {CAPITAL_LETTERS.test(formLogin.password)
                              ? ""
                              : t("- at least one uppercase letter")}
                          <br/>
                          {NUMBERS.test(formLogin.password)
                              ? ""
                              : t(t("- at least one number digit"))}
                          <br/>
                          {SPECIAL_CHARACTERS.test(formLogin.password)
                              ? ""
                              : t("- at least one special character -for example:  #, @, !")}
                      </p>
                  </div>
              ) : (
                  ""
              )}
          </div>

          <div className="form_group">
              <label>{t("Repeat password*")}</label>
              <input
                  type={isNewPassword ? "text" : "password"}
                  placeholder=". . . . . . . . . . "
                  name="c_password"
                  onChange={(e) => validateLoginField(e)}
                  className={errorField.c_password == "" ? "" : "bordererror"}
              />
              <div className="eyes">
                  <input
                      type={"checkbox"}
                      onClick={(e) => {
                          setIsNewPassword(!isNewPassword);
                      }}
                  />
                  <i></i>
              </div>
              <span className="errorfield">{errorField.c_password}</span>
          </div>

          <div className="form_group" style={{
              marginBottom: "0px"
          }}>
              <label>{t("Date of birth*")}</label>
              <DatePicker
                  value={formLogin.dob ? dayjs(formLogin.dob) : null} // Convert to Day.js object
                  onChange={handleDateChange}
                  maxDate={dayjs(today)} // Convert `today` if it's not already a Day.js object
                  format="YYYY-MM-DD"
                  slotProps={{
                      textField: {
                          placeholder: t("text-type"),
                          error: !!errorField.dob, // Boolean for error state
                          helperText: errorField.dob, // Display error message
                          InputProps: {
                              className: errorField.dob ? "bordererror" : "", // Apply error class if needed
                          },
                      },
                  }}
                  className="custom-date-picker"
              />
          </div>

          <div className="form_group number">
              <label>{t("Mobile phone*")}</label>
              <input
                  type="number"
                  placeholder={t("text-type")}
                  name="phone"
                  onChange={(e) => {
                      validateLoginField(e);
                      setFormLogin({
                          ...formLogin,
                          phone:
                              e.target.value.length <= 10 ? e.target.value : formLogin.phone,
                      });
                  }}
                  className={errorField.phone == "" ? "" : "bordererror"}
                  maxLength="10"
                  value={formLogin.phone}
              />
              <div
                  className={
                      errorField.phone == "" ? "country_flag" : "bordererror country_flag"
                  }
                  onClick={(e) => handleCodeSelect()}
              >
                  <img src={contactCode.flag}/> {contactCode.code}
              </div>
              <ul style={code ? {display: "none"} : {}}>
                  {DATA_COUNTRY.data.map((e) => {
                      return (
                          <li
                              onClick={(a) => {
                                  handleCodeSelect();
                                  setContactCode({
                                      flag: e.flag,
                                      code: e.dial_code,
                                  });
                              }}
                          >
                              <img src={e.flag}/> {e.country + " " + " " + e.dial_code}
                          </li>
                      );
                  })}
              </ul>
              <span className="errorfield">{errorField.phone}</span>
          </div>

          <div className="form_group">
              <label>{t("Address*")}</label>
              <div className={errorField.address == "" ? "" : "bordererror"}>
                  <LocationContent let={getLatLong}/>
              </div>
              <span className="errorfield">{errorField.address}</span>
          </div>
          <div className="form_group part2">
              <div className="citydetail">
                  <label>{t("City*")}</label>
                  <input
                      type="text"
                      placeholder={t("text-type")}
                      name="city"
                      onChange={(e) => validateLoginField(e)}
                      className={errorField.city == "" ? "" : "bordererror"}
                  />
                  <span className="errorfield">{errorField.city}</span>
              </div>
              <div className="citydetail">
                  <label>{t("Zip code*")}</label>
                  <input
                      type="number"
                      placeholder={t("text-type")}
                      name="zip"
                      onChange={(e) => validateLoginField(e)}
                      className={errorField.zip == "" ? "" : "bordererror"}
                  />
                  <span className="errorfield">{errorField.zip}</span>
              </div>
          </div>
          <div className="form_group">
              <label>{t("Country*")}</label>
              <input
                  type="text"
                  name="country"
                  onChange={(e) => validateLoginField(e)}
                  value={formLogin.country}
                  className={errorField.country == "" ? "" : "bordererror"}
              />
              <span className="errorfield">{errorField.country}</span>
          </div>
          <div className="form_group">
              <label>{t("How did you hear about us?*")}</label>
              <select
                  name="hearAboutUs"
                  onChange={(e) => validateLoginField(e)}
                  className={
                      (!errorField.hearAboutUs || errorField.hearAboutUs === "") ? "" : "bordererror"
                  }
              >
                  <option value="" selected={true} disabled={true}>{t("choose-list")}</option>
                  <option value={"Internet browsing "}>
                  {t("Internet browsing")}
                  </option>
                  <option value={"Friend recommendation"}>
                      {t("Friend recommendation")}
                  </option>
                  <option value={"Facebook"}>{t("Facebook")}</option>
                  <option value={"Our website"}>{t("Our website")} </option>
                  <option value={"Other"}>{t("text-other")}</option>
              </select>
              <span className="errorfield">{errorField.hearAboutUs}</span>
          </div>
      </form>
      </LocalizationProvider>
  );
};
