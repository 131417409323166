export const BanIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8 0C3.584 0 0 3.584 0 8C0 12.416 3.584 16 8 16C12.416 16 16 12.416 16 8C16 3.584 12.416 0 8 0ZM1.6 8C1.6 4.464 4.464 1.6 8 1.6C9.48 1.6 10.84 2.104 11.92 2.952L2.952 11.92C2.104 10.84 1.6 9.48 1.6 8ZM4.08021 13.0482C5.16021 13.8962 6.52021 14.4002 8.00021 14.4002C11.5362 14.4002 14.4002 11.5362 14.4002 8.00021C14.4002 6.52021 13.8962 5.16021 13.0482 4.08021L4.08021 13.0482Z"
        fill="#636363"
      />
    </svg>
  );
};
