import React from "react";
import { useTranslation } from "react-i18next";
import Modal from "react-bootstrap/Modal";

import { MarkSecondaryIcon } from "../../../../assets/image/MarkSecondaryIcon";

export const DeleteModal = ({
  handleDeleteDocument,
  setShowModalDelete,
  showModalDelete,
}) => {
  const { t } = useTranslation();

  return (
    <Modal
      show={showModalDelete}
      onHide={(e) => setShowModalDelete(false)}
      className=""
    >
      <Modal.Body>
        <div className="promocode_content upload_document_delete">
          <MarkSecondaryIcon />
          <h2>{t("delete-document")}</h2>

          <div className="gray">
            <p>
              <span>{t("text-warning")}</span> {t("document-delete-question")}
              <br />
              <span>{t("action-undone")}</span>
            </p>
            <button onClick={(e) => handleDeleteDocument()}>
              {t("text-delete")}
            </button>
            <button onClick={(e) => setShowModalDelete(false)}>
              {t("text-cancel")}
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
